import React, { useEffect, useState } from "react";
import CardLayout from "../common/CardLayout";
import { useIntl } from "react-intl";
import { Header, Grid, Label, Table } from "semantic-ui-react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const styles = {
  gridHeader: {
    fontWeight: "bold",
    backgroundColor: "#F2F2F2",
  },
  gridColumn: {
    overflowWrap: "break-word",
  },
};

const SYSTEMIC_REVIEW_STATUS = {
  Y: "มี",
  N: "ไม่มี",
  X: "N/A",
};

const CARD_ADMISSION_NOTE_VIEW = "CardAdmissionNoteView";

const NewLinetext = (props) => {
  const text = props.text;
  return text.split("\n").map((str) => <p>{str}</p>);
};

const CardAdmissionNoteView = (props) => {
  const intl = useIntl();

  const { admissionNote } = props;

  const [familyHistory, setFamilyHistory] = useState("");
  const [socialCondition, setSocialCondition] = useState("");
  const [psychologicalIssue, setPsychologicalIssue] = useState("");
  const [sensitiveNote, setSensitiveNote] = useState("");

  useEffect(() => {
    props.getAdmissionNote();
  }, []);

  useEffect(() => {
    if (props.admissionNote) {
      console.log(props.admissionNote.admission_form);
      if (props.admissionNote.admission_form != undefined) {
        if (props.admissionNote.admission_form.data) {
          // Admission Note V2
          if (props.admissionNote.admission_form.data.family_history_checked) {
            setFamilyHistory(admissionNote.admission_form.data.family_history || "Yes");
          } else {
            setFamilyHistory("No");
          }
          if (props.admissionNote.admission_form.data.social_and_economic_issue_checked) {
            setSocialCondition(
              admissionNote.admission_form.data.social_and_economic_issue || "Yes"
            );
          } else {
            setSocialCondition("No");
          }
          if (props.admissionNote.admission_form.data.psychological_issue_checked) {
            setPsychologicalIssue(admissionNote.admission_form.data.psychological_issue || "Yes");
          } else {
            setPsychologicalIssue("No");
          }
          if (props.admissionNote.admission_form.data.sensitive_note_checked) {
            setSensitiveNote(props.admissionNote.admission_form.data.sensitive_note || "Yes");
          } else {
            setSensitiveNote("No");
          }
        } else {
          // Admission Note V1
          setFamilyHistory(admissionNote.admission_form.family_history);
          setSocialCondition(admissionNote.admission_form.social_condition);
          setPsychologicalIssue(admissionNote.admission_form.psychological_issue);
          setSensitiveNote(props.admissionNote.admission_form.sensitive_note);
        }
      }
    }
  }, [props.admissionNote]);

  //PrintData
  const handlePrintData = () => {
    props.runSequence({
      sequence: "PhysicalExam",
      action: "printAdmission",
      emrId: props.emrId,
      encounterId: props.encounterId,
      patientData: props.patientData,
      buttonLoadKey: `${CARD_ADMISSION_NOTE_VIEW}_PRINT`,
    });
  };

  const generateData = () => {
    //Object
    if (Object.keys(admissionNote).length === 0 && admissionNote.constructor === Object) {
      return "Encounter นี้ไม่สามารถดูข้อมูล Admission Note ได้";
    }

    // array
    if (Object.keys(admissionNote).length === 0) {
      return "No Data";
    }

    return (
      <Grid celled>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Informant
          </Grid.Column>
          <Grid.Column width={5} style={styles.gridColumn}>
            {admissionNote.admission_form ? admissionNote.admission_form.information : ""}
          </Grid.Column>
          <Grid.Column width={3} style={styles.gridHeader}>
            ความน่าเชื่อถือได้
          </Grid.Column>
          <Grid.Column width={5} style={styles.gridColumn}>
            {admissionNote.admission_form ? admissionNote.admission_form.info_reliability : ""}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Chief Complaint
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            {admissionNote.admission_form ? admissionNote.admission_form.chief_complaint : ""}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Present Illness
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            {admissionNote.admission_form ? admissionNote.admission_form.present_illness : ""}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Past Illness
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            <NewLinetext
              text={admissionNote.admission_form ? admissionNote.admission_form.pass_illness : ""}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Personal History
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            <NewLinetext
              text={
                admissionNote.admission_form ? admissionNote.admission_form.personal_history : ""
              }
            />
          </Grid.Column>
        </Grid.Row>
        {admissionNote.admission_form.version === "1.0" && (
          <Grid.Row>
            <Grid.Column width={3} style={styles.gridHeader}>
              Immunization
            </Grid.Column>
            <Grid.Column width={13} style={styles.gridColumn}>
              {admissionNote.admission_form ? admissionNote.admission_form.immunization : ""}
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Family History
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            {familyHistory}
            {/* {admissionNote.admission_form ? admissionNote.admission_form.family_history : ''} */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Social Condition
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            {socialCondition}
            {/* {admissionNote.admission_form ? admissionNote.admission_form.social_condition : ''} */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Psychological Issue
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            {psychologicalIssue}
            {/* {admissionNote?.admission_form?.psychological_issue || ''} */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Sensitive Note
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            {sensitiveNote}
            {/* {admissionNote.admission_form ? admissionNote.admission_form.sensitive_note : ''} */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Systemic review
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            <Grid celled>
              {generateSystemicReview(
                admissionNote.systemic_review ? admissionNote.systemic_review.items : []
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Physical Examination
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            <Grid celled>
              {generatePhysicalExamination(
                admissionNote.physical_examination ? admissionNote.physical_examination.items : []
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Investigation Results
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            {generateInitialInvestigation(admissionNote.initial_investigation)}
            {/* {admissionNote.assessment_plan ? admissionNote.assessment_plan.investigation_plan : ''} */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Problem List
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            {admissionNote.problem_list ? admissionNote.problem_list.problem_info : ""}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Provisional Diagnosis
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            <Header as="h4">Primary</Header>
            icd10_code:{" "}
            {admissionNote.provisional_diagnosis && admissionNote.provisional_diagnosis.primary
              ? admissionNote.provisional_diagnosis.primary.icd10_code
              : ""}
            <br />
            icd10_term:{" "}
            {admissionNote.provisional_diagnosis && admissionNote.provisional_diagnosis.primary
              ? admissionNote.provisional_diagnosis.primary.icd10_term
              : ""}
            <br />
            medical_description:{" "}
            {admissionNote.provisional_diagnosis && admissionNote.provisional_diagnosis.primary
              ? admissionNote.provisional_diagnosis.primary.medical_description
              : ""}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} style={styles.gridHeader}>
            Assessment and plan
          </Grid.Column>
          <Grid.Column width={13} style={styles.gridColumn}>
            <Grid celled>
              <Grid.Row>
                <Grid.Column style={styles.gridHeader} width={3}>
                  Investigation plan
                </Grid.Column>
                <Grid.Column width={13} style={styles.gridColumn}>
                  {admissionNote.assessment_plan
                    ? admissionNote.assessment_plan.investigation_plan
                    : ""}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={styles.gridHeader} width={3}>
                  Treatment plan
                </Grid.Column>
                <Grid.Column width={13} style={styles.gridColumn}>
                  {admissionNote.assessment_plan
                    ? admissionNote.assessment_plan.treatment_plan
                    : ""}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={styles.gridHeader} width={3}>
                  Patient Education
                </Grid.Column>
                <Grid.Column width={13} style={styles.gridColumn}>
                  {admissionNote.assessment_plan
                    ? admissionNote.assessment_plan.patient_education
                    : ""}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const generateInitialInvestigation = (data) => {
    if (!data) {
      return;
    }

    if (data.version !== 1) {
      return <p>{data.investigation_detail}</p>;
    }

    return (
      <Table celled>
        <Table.Header style={{ ...styles.gridHeader, ...styles.gridColumn }}>
          <Table.Cell>Lab Code</Table.Cell>
          <Table.Cell>Lab Name</Table.Cell>
          <Table.Cell>Lab Value(Lab Reference)</Table.Cell>
        </Table.Header>
        <Table.Body>
          {data.items.map((dataItem) => {
            return dataItem.items.map((items, index) => (
              <Table.Row key={index}>
                <Table.Cell>{items.lab_code}</Table.Cell>
                <Table.Cell>{items.lab}</Table.Cell>
                <Table.Cell>
                  {items.value}
                  {items.reference_value ? "(" + items.reference_value + ")" : ""}
                </Table.Cell>
              </Table.Row>
            ));
          })}
        </Table.Body>
      </Table>
    );
  };

  const generateSystemicReview = (data) => {
    return data.map((items, index) => {
      let choice = null;
      if (items.organ_choice_items.length > 0) {
        choice = <Grid>{genarateOrganChoiceItem(items.organ_choice_items)}</Grid>;
      }
      return (
        <Grid.Row key={index}>
          <Grid.Column style={styles.gridHeader} width={3}>
            {items.organ}
          </Grid.Column>
          <Grid.Column width={13}>{choice}</Grid.Column>
        </Grid.Row>
      );
    });
  };

  const genarateOrganChoiceItem = (choiceList) => {
    return choiceList.map((items, index) => (
      <Grid.Row key={index}>
        <Grid.Column width={4}>{items.choice_name}</Grid.Column>
        <Grid.Column width={2}>{SYSTEMIC_REVIEW_STATUS[items.status]}</Grid.Column>
        <Grid.Column width={10}>{items.description}</Grid.Column>
      </Grid.Row>
    ));
  };

  const generatePhysicalExamination = (data) => {
    return data.map((items, index) => (
      <Grid.Row key={index}>
        <Grid.Column style={styles.gridHeader} width={3}>
          {items.organ_name}
        </Grid.Column>
        <Grid.Column width={3}>{items.status_full}</Grid.Column>
        <Grid.Column width={10}>{items.description}</Grid.Column>
      </Grid.Row>
    ));
  };

  let titleContent = null;
  if (props.admissionNote) {
    titleContent = <Label style={{ color: "black" }} content={props.admissionNote.latest_edited} />;
  }

  return (
    <CardLayout
      titleText="Admission Note"
      headerColor="red"
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      titleContent={titleContent}
      loading={props.admissionNoteLoading}
      enableMargin={props.enableMargin}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ width: "20%" }}>
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => handlePrintData()}
            // data
            paramKey={`${CARD_ADMISSION_NOTE_VIEW}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_ADMISSION_NOTE_VIEW}_PRINT`]}
            // config
            name="PRINT"
            style={{ width: "100%" }}
            title="พิมพ์ใบ Admission Form"
            color="blue"
          />
        </div>
      </div>
      <div style={{ maxHeight: 480, overflowY: "auto" }}>{generateData()}</div>
    </CardLayout>
  );
};

CardAdmissionNoteView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  admissionNote: {},
  admissionNoteLoading: false,
  getAdmissionNote: () => {},
  enableMargin: true,
};

export default CardAdmissionNoteView;
