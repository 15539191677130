import React, { useState, useEffect, useImperativeHandle } from "react";
import { Dropdown, Form, Modal } from "semantic-ui-react";

// UX
import CardDrugClaimQAUX from "./CardDrugClaimQAUX";
import SubQAField from "./SubQAField";

// Interface
import { ESTIMATE_MAP } from "./TPDInterface";
import { useIntl } from "react-intl";

const CardDrugClaimQA: React.FunctionComponent<any> = (props) => {
  const intl = useIntl();
  const [currentSubIndex, setCurrentSubIndex] = useState<number | null>(null);
  const [payloadDetail, setPayloadDetail] = useState<Record<string, any>>({});
  const [drugOrderItems, setDrugOrderItems] = useState<Record<string, any>>({});
  const [payloads, setPayloads] = useState<any[]>([]);

  console.log("CardDrugClaimQA props: ", props);
  // คำนวน quantity
  useEffect(() => {
    const items = (props.estimate?.items || []).map((item: any) => ({
      ...item,
      // quantity ได้ NaN 
      quantity: parseFloat(item.quantity) * parseFloat(item.dispense_quantity),
      dispense_quantity: 1,
    }));

    setDrugOrderItems(items);
  }, [props.estimate?.items]);

  //
  useEffect(() => {
    if (Object.keys(props.estimate || {}).length) {
      const { index } = getAnswered();
      console.log("CardDrugClaimQA useEffect index: ", index);
      setCurrentSubIndex(index);
      setPayloads(props.estimate.payloads);
    }
  }, [props.estimate]);

  useEffect(() => {
    if (Object.keys(props.estimate || {}).length) {
      console.log("handleSetPayload called", currentSubIndex || 0);
      handleSetPayload(currentSubIndex || 0);
    }
  }, [currentSubIndex]);

  const getAnswered = () => {
    console.log("CardDrugClaimQA getAnswered: ");
    let index = 0;
    console.log("handleSetPayload called");
    const detail: any = handleSetPayload(index, true);
    console.log("CardDrugClaimQA getAnswered detail: ", detail);
    const answerDict = detail.currentPayload?.answer_dict;
    console.log("CardDrugClaimQA getAnswered answerDict: ", answerDict);

    for (const item of detail.subTab) {
      const answered = item.questions.every((item: any) => item.id in answerDict);
      // console.log("getAnswered: ", index, answerDict, answered)
      if (answered) {
        index += 1;
      }
    }

    return {
      index: index > detail.subTab.length - 1 ? index - 1 : index,
      // index
    };
  };

  const getTabModel = (payload: any) => {
    let flags: any = {};
    let tabModel = payload["questions"].filter((value: any) => {
      if (flags[value.tab_name]) {
        return false;
      }
      flags[value.tab_name] = true;
      return true;
    });

    return tabModel.map((q: any) => {
      return {
        tab_name: q.tab_name,
        questions: payload["questions"].filter((value: any) => {
          return q.tab_name === value.tab_name;
        }),
      };
    });
  };

  const handleSetPayload = (subIndex: number, isReturn?: boolean) => {

    console.groupCollapsed("handleSetPayload")
    const estimate = props.estimate;
    const payloads: any[] = estimate.payloads;
    let productId: number | null = null;

    console.log("handleSetPayload subIndex: ", subIndex);
    console.log("handleSetPayload estimate: ", estimate);
    console.log("handleSetPayload payloads: ", payloads);

    let productItems: any[] = estimate.items;
    let currentPayload: any = null;
    let currentPayloadIndex: any = subIndex;
    let haveQuestion = false;
    let title: string =intl.formatMessage({ id: "รายการคำถาม Claim" });
    let subTab: any[] = [];
    //  check cause payload item !== GetAnswer index back
    // index = estimate.index === index ? index : estimate.index
    
    console.log("handleSetPayload productItems: ", productItems);
    console.log("handleSetPayload currentPayload: ", currentPayload);
    console.log("handleSetPayload currentPayloadIndex: ", currentPayloadIndex);

    if (subIndex >= payloads?.length) {
      subIndex = payloads.length - 1;
    }

    console.log(
      "handleSetPayload estimate?.original?.claim_payload?.have_question: ",
      estimate?.original?.claim_payload?.have_question
    );
    if (estimate?.original?.claim_payload?.have_question && !estimate?.product) {
      // ถ้ามีคำถาม ให้ไปวนหา
      productId = null;
    } else if (estimate?.original?.claim_payload?.have_question && estimate.product) {
      // เปิดดูจากตาราง
      productId = estimate.product;
    } else if (estimate.drug) {
      // กดเปิดจาก contine 
      productId = estimate.drug;
    } else {
      productId = estimate.items?.[0]?.product || null;
    }
    console.log("handleSetPayload productId: ", productId);
    // console.log("handleSetPayload1: ", productId, payloads, index)
    // this always set the latest unanswer question
    let isMatched = false;
    for (const idx in payloads) {
      console.log("handleSetPayload loop item: ", idx);
      // Check is matched depend on productId

      if (!productId) {
        // Select first payload that have_question

        isMatched = payloads[idx]["have_question"] 
        if (!isMatched) {
          let questionLength = payloads[idx]?.questions?.length || 0
          let ansLength = Object.keys(payloads[idx]?.answer_dict).length || 0
          isMatched = ansLength < questionLength
        }
      } else {
        // Select payload that match product_id
        isMatched = payloads[idx]["product_id"] === productId;
      }

      console.log("handleSetPayload isMatched? ", isMatched);
      console.log("handleSetPayload payloads[subIndex]: ", payloads[subIndex]);
      if (isMatched) {
        console.log('handleSetPayload isMatched: ', isMatched);
        currentPayload = payloads[idx];
        currentPayloadIndex = idx;
        subTab = getTabModel(currentPayload);
        title = currentPayload["label"];
        productItems = props.estimate.items;
        haveQuestion = true;
        break;
      }
    }

    console.log("handleSetPayload isMatched haveQuestion: ", haveQuestion);
    console.log("handleSetPayload isMatched productItems: ", productItems);
    console.log("handleSetPayload isMatched subTab: ", subTab);
    console.log("handleSetPayload isMatched currentPayloadIndex: ", currentPayloadIndex);
    console.log("handleSetPayload isMatched currentPayload: ", currentPayload);

    const detail = {
      haveQuestion,
      currentPayload,
      currentPayloadIndex,
      productItems,
      title,
      subTab,
      // productId,
      index: currentSubIndex,
    };
    console.log("CardDrugClaimQA handleSetPayload2: ", detail, isReturn);
    console.groupEnd();
    if (isReturn) {
      return detail;
    } else {
      setPayloadDetail(detail);
    }
  };

  const handleSave = () => {
    let isAnswerCompleted = true;
    console.groupCollapsed("handleSave")
    // const currentPayload = payloadDetail.currentPayload;
    console.log("handleSave payloadDetail: ", payloadDetail);
    // console.log("handleSave currentPayload: ", currentPayload);

    console.log("handleSave payloads: ", payloads);
    payloads.forEach((item: any) => {
      item.questions.forEach((question: any) => {
        console.log("questions", question.id, item.answer_dict, question.id in item.answer_dict);
        if (!(question.id in item.answer_dict)) {
          isAnswerCompleted = false;
          console.log(" question id ", question.id, "not answser in ", item.answer_dict);
          return;
        }

        // currentPayload["answer_dict"][question.id] =
        //   item.answer_dict[question.id];
      });
    });

    if (isAnswerCompleted) {
      console.log("CardDrugClaimQA handleSave: isAnswerCompleted t ", isAnswerCompleted);
      console.log(
        "CardDrugClaimQA handleSave payloadDetail.productItems: ",
        payloadDetail.productItems
      );
      console.groupEnd()
      estimate({}, true);
    } else {
      console.log("CardDrugClaimQA handleSave: isAnswerCompleted f ", isAnswerCompleted);
      console.groupEnd()
      estimate({});
    }
    setCurrentSubIndex(null);
  };

  const estimate = (rows: any, hideQuestion?: boolean) => {
    console.groupCollapsed("CardDrugClaimQA estimate")
    console.log("CardDrugClaimQA estimate props ", props)
    console.log("CardDrugClaimQA estimate hideQuestion:", hideQuestion);
    console.log(
      "CardDrugClaimQA estimate props.estimate.original.claim_payload: ",
      props.estimate?.original?.claim_payload
    );
    console.log("CardDrugClaimQA estimate payloads: ", payloads);
    console.groupEnd()
    if (props.onSave) {
      return props.onSave(
        {
          ...props.estimate?.original?.claim_payload,
          payloads,
        },
        props.estimate.index
      );
    }

    const data = {
      claim_payload: {
        ...props.estimate?.original?.claim_payload,
        payloads,
      },
      drug_type: ESTIMATE_MAP[props.orderType],
      emr: props.selectedEmr?.id || null,
      encounter: props.selectedEncounter?.id || null,
      hideQuestion: hideQuestion,
      items: drugOrderItems,
      progression_cycle: props.selectedProgressCycle?.id || null,
    };

    console.log("CardDrugClaimQA estimate data !! : ", data);
    console.log("CardDrugClaimQA estimate props.estimate: ", props.estimate);
    // props.onEvent({
    //   message: "HandleSavePostponeAnswer",
    //   params: {
    //     data,
    //     index: props.estimate.index,
    //     isAdd: props.estimate.isAdd,
    //     stateKey: props.stateKey || "drugOrder",
    //   },
    // });

    if (props.stateKey === "drugResuscitation") {
      props.onSaveQA(data);
    } else {
      props.onEvent({
        message: "HandleSavePostponeAnswer",
        params: {
          data,
          index: props.estimate.index,
          isAdd: props.estimate.isAdd,
          stateKey: props.stateKey || "drugOrder",
        },
      });
    }
  };

  const handleChangeAnswerDict = (answer: any) => {
    console.log("handleChangeAnswerDict 1 2answer: ", answer);
    console.log("handleChangeAnswerDict 1 payloads: ", payloads);

    let payloadIdx = Number(payloadDetail?.currentPayloadIndex);
    payloads[payloadIdx].answer_dict = {
      ...payloads[payloadIdx].answer_dict,
      ...answer,
    };

    console.log("handleChangeAnswerDict 2 answer: ", answer);
    console.log("handleChangeAnswerDict 2 payloads: ", payloads);

    if (!props.notSetAnswer) {
      props.onEvent({
        message: "HandleSetAnswerDrugOrderItem",
        params: {
          index: props.estimate.index, // this only index of items not payloads
          answer,
          stateKey: props.stateKey || "drugOrder",
        },
      });
    }

    setPayloads(payloads);
  };

  // const handleClose = () => {
  //   setCurrentIndex(null)
  //   props.onEvent({
  //     message: "HandleCloseDrugOrderEstimate",
  //     params: {
  //       stateKey: props.stateKey || "drugOrder",
  //     },
  //   });
  // };

  const handleClose = () => {
    console.log('handleClose: ');
    setCurrentSubIndex(null);
    if (props.onClose) {
      props.onClose() // DrugContinuePlan
      return 
    } else if (props.stateKey === "drugResuscitation") {
      props.onCloseQA?.();
      return
    } else {
      props.onEvent({
        message: "HandleCloseDrugOrderEstimate",
        params: {
          stateKey: props.stateKey || "drugOrder",
          index: props.estimate.index,
        },
      });
    }
  };

  console.log("CardDrugClaimQA payloadDetail: ", payloadDetail);
  console.log('CardDrugClaimQA props: ', props);

  return (
    <Modal open={!!Object.keys(props.estimate || {}).length}>
      <div>
        <CardDrugClaimQAUX
          hideClose={props.hideClose}
          title={payloadDetail.title}
          subQATab={(payloadDetail.subTab || [])?.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                padding: "5px",
                ...(index === payloadDetail.index
                  ? { backgroundColor: "rgb(32, 131, 193)", color: "white" }
                  : {}),
              }}
              onClick={() => {
                console.log("onClick ", index);
                setCurrentSubIndex(index);
              }}
            >
              {item.tab_name}
            </div>
          ))}
          subQAField={
            <SubQAField
              questions={payloadDetail.subTab?.[currentSubIndex || 0]?.questions || []}
              currentPayload={payloadDetail.currentPayload}
              onChangeAnswerDict={handleChangeAnswerDict}
              statusRequest={props.statusRequest}
              languageUX={props.languageUX}
            />
          }
          
          onSave={handleSave}
          onClose={handleClose}
          statusRequest={props.statusRequest}
          languageUX={props.languageUX}
        />
      </div>
    </Modal>
  );
};

CardDrugClaimQA.displayName = "CardDrugClaimQA";
export default React.memo(CardDrugClaimQA);

// const SubQAField = React.forwardRef<any, any>((props, ref) => {
//   const [answerDict, setAnswerDict] = useState<Record<string, any>>({});

//   useEffect(() => {
//     setAnswerDict(props.currentPayload?.["answer_dict"] || {});
//   }, [props.currentPayload?.["answer_dict"]]);

//   const handleChange = (e: any, v: any) => {
//     let checked: string = v.checked?.toString();
//     if (typeof v.checked === "boolean") {
//       checked = `${checked[0].toUpperCase()}${checked.slice(1)}`;
//     }

//     const dict = {
//       ...answerDict,
//       [v.name]: typeof v.checked === "boolean" ? checked : v.value,
//     };

//     setAnswerDict(dict);
//     props.onChangeAnswerDict?.(dict);
//   };

//   return (
//     <Form>
//       {props.questions.map((item: any) => (
//         <SubQAType
//           label={item.label}
//           type={item.type}
//           answer={answerDict[item.id]}
//           choices={item.choices}
//           dataValidateKey={item.id}
//           questionId={item.id}
//           default_answer={item.default_answer}
//           statusRequest={props.statusRequest}
//           onChange={handleChange}
//         />
//       ))}
//     </Form>
//   );
// });

// const CHECK_PARSE: Record<string, boolean> = {
//   True: true,
//   False: false,
// };

// const SubQAType: React.FunctionComponent<any> = (props) => {
//   const mapOptions = (list: any[]) => {
//     return list.map((item: any) => ({
//       key: item.id,
//       text: item.name,
//       value: item.id,
//     }));
//   };

//   return (
//     <>
//       {props.type === "CHOICE" ? (
//         <Form.Dropdown
//           value={
//             typeof props.choices?.[0]?.id === "number"
//               ? Number(props.answer)
//               : props.answer
//           }
//           label={props.label}
//           placeholder="กรุณาระบุ"
//           options={mapOptions(props.choices)}
//           selection
//           style={{ width: "auto" }}
//           name={props.dataValidateKey}
//           onChange={props.onChange}
//           disabled={props.statusRequest ? true : false}
//         />
//       ) : props.type === "BOOLEAN" ? (
//         <Form.Checkbox
//           checked={CHECK_PARSE[props.answer]}
//           label={props.label}
//           name={props.dataValidateKey}
//           onChange={props.onChange}
//           readOnly={props.statusRequest ? true : false}
//         />
//       ) : props.type === "DECIMAL" ? (
//         <Form.Input
//           value={props.answer || ""}
//           type="number"
//           name={props.dataValidateKey}
//           onChange={props.onChange}
//           readOnly={props.statusRequest ? true : false}
//         />
//       ) : props.type === "TEXT" ? (
//         <Form.Input
//           value={props.answer || ""}
//           name={props.dataValidateKey}
//           onChange={props.onChange}
//           readOnly={props.statusRequest ? true : false}
//         />
//       ) : null}
//     </>
//   );
// };
