import WasmController from "react-lib/frameworks/WasmController";

// APIS
import LoadDivisionHasUserAPIView from "issara-sdk/apis/LoadDivisionHasUserAPIView_coreM";
import LoadDoctorAPIView from "issara-sdk/apis/LoadDoctorAPIView_coreM";
import LoadDrugAPIView from "issara-sdk/apis/LoadDrugAPIView_coreM";

import { SetErrorMessage } from "react-lib/apps/HISV3/common/CommonInterface";

export type State = {
  buttonLoadCheck?: Record<string, any>;
  successMessage?: Record<string, any>;
};

export const StateInitial: State = {};

export type Event = { message: "HandleUploadTemplateExcel"; params: { file: File } };

export type Data = {};

export type UploadKeys = keyof typeof UPLOAD_TYPES;

export const DataInitial = {};

export const UPLOAD_TYPES = {
  DIVISION_HAS_USER: "DIVISION_HAS_USER",
  DOCTOR: "DOCTOR",
  DRUG: "DRUG",
} as const;

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

const UPLOAD_APIS = {
  [UPLOAD_TYPES.DIVISION_HAS_USER]: LoadDivisionHasUserAPIView.post,
  [UPLOAD_TYPES.DOCTOR]: LoadDoctorAPIView.post,
  [UPLOAD_TYPES.DRUG]: LoadDrugAPIView.post,
};

export const HandleUploadTemplateExcel: Handler = async (controller, params) => {
  const state = controller.getState();
  const type = params.type as UploadKeys;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "LOADING" },
  });

  const [result, error] = await UPLOAD_APIS[type]({
    apiToken: controller.apiToken,
    data: { file: params.file },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: null, error });

    return;
  }

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: null },
    successMessage: { ...state.successMessage, [params.btnAction]: result },
  });

  params.onSuccess?.()
};
