import React  from "react"
// Common
import SearchBoxDropdown, { SelectedItemHandler } from "react-lib/appcon/common/SearchBoxDropdown";
import { useIntl } from "react-intl";
import {SubAssistantsInputProps} from "react-lib/apps/HISV3/ORM/SubAssistantsInput"

/* ------------------------------------------------------ */

/*                    SearchBoxWithKey;                   */

/* ------------------------------------------------------ */
type SearchBoxWithKeyProps = {
  id: string;
  disabled?: boolean;
  selectedItem?: any;
  defaultText?: string;
  // callback
  setSelected?: SelectedItemHandler;
  mapOptions?: any;
  placeholder?: any;
  inline?: boolean;
  width?: string;
  valueKey?: string;
} & Pick<
  SubAssistantsInputProps,
  "onEvent" | "type" | "role" | "searchedItemListWithKey" | "isDetail"
>;

const SearchBoxWithKey = (props: SearchBoxWithKeyProps) => {
  const intl = useIntl();
  const handleSelect: SelectedItemHandler = (value, key, data) => {
    if (props.isDetail) {
      const key = `${props.type}_${props.id}`;
      const detail = props.searchedItemListWithKey?.[key]?.find((item: any) => item.id === value);

      value = detail
        ? {
            id: detail.id,
            name_code: detail.name_code,
          }
        : null;
    }

    return props.setSelected?.(value, key, data);
  };

  return (
    <SearchBoxDropdown
      type={props.type}
      id={props.id?.toString()}
      style={{ width: props.width ? props.width : "100%" }}
      boxStyle={{ width: props.width ? props.width : "100%" }}
      fluid={true}
      label=""
      role={props.role}
      disabled={props.disabled}
      icon="search"
      inline={props.inline}
      defaultText={props.defaultText}
      // disabled={selectedManagePatient !== null}
      onEvent={props.onEvent}
      // searchedItemList={props.searchedItemList}
      searchedItemListWithKey={props.searchedItemListWithKey}
      selectedItem={props.selectedItem}
      setSelectedItem={handleSelect}
      mapOptions={props.mapOptions}
      placeholder={props.placeholder}
      useWithKey={true}
      valueKey={props.valueKey}
    />
  );
};

SearchBoxWithKey.displayName = "SearchBoxWithKey";

export default React.memo(SearchBoxWithKey);
