import WasmController from "react-lib/frameworks/WasmController";
import { State as MainState } from "../../MainHISInterface";
import moment from "moment";

// APIs
import QueueWardList from "issara-sdk/apis/QueueWardList_apps_ADM";
import AdmitOrderRoomItemDetail from "issara-sdk/apis/AdmitOrderRoomItemDetail_apps_ADM";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import IntraHospitalTransferFormDefaultInfo from "issara-sdk/apis/IntraHospitalTransferFormDefaultInfo_apps_PTM";
import IntraHospitalTransferFormChoice from "issara-sdk/apis/IntraHospitalTransferFormChoice_apps_PTM";
import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import IntraHospitalTransferFormList from "issara-sdk/apis/IntraHospitalTransferFormList_apps_PTM";
import IntraHospitalTransferFormDetail from "issara-sdk/apis/IntraHospitalTransferFormDetail_apps_PTM";
import IntraHospitalTransferFormPrint from "issara-sdk/apis/IntraHospitalTransferFormPrint_apps_PTM";
import AdmitOrderDetail from "issara-sdk/apis/AdmitOrderDetail_apps_ADM";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";

// Interface
import { HandleGetEmployeeTokenization } from "../../TPD/TPDInterface";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import getHtmlToPdfmake from "react-lib/appcon/common/getHtmlToPdfmake";

// Form Print
import FormWardQueue from "react-lib/apps/HISV3/ADM/FormWardQueue";

export type State = Partial<{
  loadingStatus?: any;
  selectedPatient?: any;
  errorMessage?: any;
  django?: any;
  WardQueueSequence: {
    sequenceIndex: string | null;
    filterQueue: Partial<{
      loading: boolean;
      division: number | null;
      status: number | null;
      start: string | null;
      end: string | null;
      roomNO: any;
      doctor: any;
    }>;
    selectedAdmitRoomItem: any;
    selectedAdmitEncounter: any;
    wardQueueList: any[];
    permission: Record<string, any>;
    moveOut?: {
      open?: boolean;
      cleaningChoise?: any;
      error?: any;
    } | null;
  } | null;
  modAdmit: {
    open: boolean;
  };
  intraTransferForm: {
    id?: number | null;
    show: boolean;
    items?: any[];
    from_div?: number;
    to_div?: number | null;
    chief_complaint?: string;
    vital_bp?: string;
    vital_body_temp?: string;
    vital_o2_sat?: string;
    vital_heart_rate?: string;
    vital_respiratory_rate?: string;
    allergy?: string;
    reason_to_transfer?: any;
    receiving_personnel?: string;
    transferring_personnel?: string;
    transferring_personnel_date?: string;
    receivingTokenize?: Record<string, any>;
    transferringTokenize?: Record<string, any>;
  };
  intraHospitalTransferOptions: any;
}>;

type Picked = Partial<
  Pick<
    MainState,
    | "selectedEmr"
    | "selectedEncounter"
    | "errorMessage"
    | "successMessage"
    | "loadingStatus"
    | "buttonLoadCheck"
  >
>;

export const StateInitial: State = {
  WardQueueSequence: null,
  modAdmit: {
    open: false,
  },
  intraTransferForm: {
    show: false,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  console.log("WardQueue Start: ");
  const state = controller.getState();

  if (!state.WardQueueSequence) return;

  if (params.nextSequenceIndex) {
    return IntraTransferInitial(controller, params);
  }

  controller.setState({
    WardQueueSequence: {
      ...state.WardQueueSequence,
      filterQueue: {
        loading: true,
        //
      },
      moveOut: { open: false, cleaningChoise: null },
    },
  });
  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["divisionIpd", {}],
        ["patientIPDStatus", {}],
        ["cleaningChoice", {}],
        ["cleaningByName", {}],
      ],
    },
  });

  let defaultStatus = undefined;
  console.log("params.isReport: ", params.isReport);
  console.log("state?.masterOptions: ", state?.masterOptions);
  if (params.isReport) {
    defaultStatus = state?.masterOptions?.patientIPDStatus?.find(
      (item: any) => item.text === "Ward Admitted"
    )?.value;
  }
  console.log("defaultStatus: ", defaultStatus);

  const getQueueWard = QueueWardList.get({
    apiToken: controller.apiToken,
    params: {
      exclude_estimate_field: true,
      division: controller.data.division,
      limit: 20,
      offset: 0,
      ...(defaultStatus !== undefined && { room_item_status: defaultStatus }),
    },
    extra: {
      device: (controller.data as any).device,
      division: controller.data.division,
    },
  });

  const getUserPermission = UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      action_ADM_AdmitOrderRoomItem_TRANSFER: "",
      action_ADM_AdmitOrderRoomItem_CHANGE_BED: "",
      action_ADM_AdmitOrderRoomItem_REVERSE_ADMIT: "",
      action_ADM_AdmitOrderRoomItem_REVERSE_WARD: "",
      action_ADM_AdmitOrderRoomItem_WARD: "",
    },
  });

  const [ward, permission] = await Promise.all([
    getQueueWard,
    getUserPermission,
  ]);

  if (ward[1]) {
    console.log("Queue ward error: ", ward[1]);
    if (params.card) {
      controller.setState({
        WardQueueSequence: {
          ...state.WardQueueSequence,
          filterQueue: {
            loading: false,
          },
          permission: permission[0],
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: ward[1] },
        },
      });
    }
    return;
  }

  controller.setState({
    WardQueueSequence: {
      ...state.WardQueueSequence,
      sequenceIndex: "FilterQueue",
      selectedAdmitRoomItem: null,
      selectedAdmitEncounter: null,
      wardQueueList: ward[0]?.items || [],
      filterQueue: {
        loading: false,
        division: controller.data.division,
        status: null,
        ...(defaultStatus !== undefined && { status: defaultStatus }),
        start: null,
        end: null,
        roomNO: {},
        doctor: {},
      },
      permission: permission[0],
    },
  });
};

const IntraTransferInitial: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.WardQueueSequence) {
    return;
  }
  // Master data
  if (!params.update) {
    await controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [["division", {}]],
      },
    });
  }

  const [choice, intra] = await Promise.all([
    IntraHospitalTransferFormChoice.get({
      apiToken: controller.apiToken,
    }),
    IntraHospitalTransferFormList.list({
      apiToken: controller.apiToken,
      params: {
        patient: state.selectedPatient?.id || params?.patientId,
        limit: 100,
        offset: 0,
      },
    }),
  ]);

  const options: Record<string, any> = {};

  for (const key in choice[0] || {}) {
    options[key] = (choice[0][key] || []).map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.text,
    }));
  }

  return controller.setState(
    {
      WardQueueSequence: {
        ...state.WardQueueSequence,
        sequenceIndex: params.nextSequenceIndex
          ? params.nextSequenceIndex
          : state.WardQueueSequence.sequenceIndex,
      },
      intraTransferForm: {
        ...((state.intraTransferForm || {}) as any),
        items: intra[0]?.items || [],
      },
      intraHospitalTransferOptions: options,
    },
    () => controller.handleEvent({ message: "RunSequence", params })
  );
};

export const FilterQueue: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.WardQueueSequence) return;

  if (params.action && ["search", "barcode_search"].includes(params.action)) {
    controller.setState({
      WardQueueSequence: {
        ...state.WardQueueSequence,
        filterQueue: { ...state.WardQueueSequence.filterQueue, loading: true, },
      },
    });

    let sendParams = params.action === "search" ? {
      division: state.WardQueueSequence?.filterQueue?.division || null,
      start_warded: state.WardQueueSequence?.filterQueue?.start || null,
      end_warded: state.WardQueueSequence?.filterQueue?.end || null,
      room_item_status: state.WardQueueSequence?.filterQueue?.status || null,
      room_no: state.WardQueueSequence?.filterQueue?.roomNO?.id || null,
      doctor_id: state.WardQueueSequence?.filterQueue?.doctor || null,
    } : {
      barcode_or_an_or_hn: params?.barcode || "",
    }

    const [r, e, n] = await QueueWardList.get({
      apiToken: controller.apiToken,
      params: {
        ...sendParams,
        exclude_estimate_field: true,
      },
      extra: {
        device: (controller.data as any).device,
        division:
          state.WardQueueSequence?.filterQueue?.division ||
          controller.data.division,
      },
    });

    if (e) {
      console.warn("FilterQueue error: ", e);
      if (params.card) {
        controller.setState({
          WardQueueSequence: {
            ...state.WardQueueSequence,
            filterQueue: { ...state.WardQueueSequence.filterQueue, loading: false, },
          },
          errorMessage: { ...state.errorMessage, [params.card]: { error: e } },
        });
      }
      return;
    }

    const items: any[] = r?.items || [];

    const selected = items.find(
      (item) => item.id === state.WardQueueSequence?.selectedAdmitRoomItem?.id
    );

    if (params.action === "barcode_search" && !items?.length) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [`${params.card}_barcode`]: "NOT_FOUND"
        },
      });
    }

    controller.setState(
      {
        WardQueueSequence: {
          ...state.WardQueueSequence,
          filterQueue: {
            ...state.WardQueueSequence.filterQueue,
            loading: false,
          },
          wardQueueList: items,
          selectedAdmitRoomItem: !!selected ? selected : null,
        },
      },
      () => {
        if (params.withPrint) {
          FilterQueue(controller, { ...params, action: "printWardQueueList" });
        }
      }
    );
  } else if (params?.action === "select_admit_room") {
    controller.setState({
      WardQueueSequence: {
        ...state.WardQueueSequence,
        selectedAdmitRoomItem: params.admitRoom,
      },
    });
  } else if (params?.action === "printWardQueueList") {
    const state = controller.getState();
    if (!state.WardQueueSequence) return;

    console.log("printWardQueueList params: ", params);
    // const patient = state?.selectedPatient;

    const data = Object.assign({
      time: moment().format("HH:mm") || "",
      date: formatDate(moment()) || "",
      // patient: patient || {},
      // parentLabID: params.parentLabID || [],
      // labResultList: params.groupByParentLab || [],
      // doctorRecommends: params.doctorRecommends,
      start: params?.start,
      end: params?.end,
      wardName:
        (state.masterOptions?.division || []).find(
          (item: any) => item.value === params.division
        )?.text || "",
      data: state?.WardQueueSequence?.wardQueueList,
    });

    let docDef: any = { content: [] };

    let htmlToPdfmake = await getHtmlToPdfmake();
    docDef = await FormWardQueue(data, htmlToPdfmake?.default);
    // console.log('docDef: ', docDef);

    const pdfMake = (await getPdfMake()).createPdf(docDef);
    pdfMake.open();
    return;
  } else if (params?.action === "open_receive_admit") {
    const [resp, err, netw] = await EncounterDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state.WardQueueSequence?.selectedAdmitRoomItem?.encounter?.id,
    });
    // load data intra form
    controller.setState(
      {
        WardQueueSequence: {
          ...state.WardQueueSequence,
          sequenceIndex: "AdmitAction",
          selectedAdmitEncounter: err ? null : resp,
        },
        modAdmit: {
          open: true,
        },
        selectedEncounter: state.WardQueueSequence?.selectedAdmitRoomItem?.encounter
      },
      () =>
        IntraTransferInitial(controller, {
          ...params,
          patientId:
            state.WardQueueSequence?.selectedAdmitRoomItem?.encounter?.patient,
        })
    );
  } else if (params.action === "cancel_admit") {
    const cancelAdmit = await AdmitOrderDetail.update({
      pk: state.WardQueueSequence?.selectedAdmitRoomItem?.admit_order,
      data: {
        action: "REVERSE_FINISH",
        cancel_reason: params.note,
      },
      apiToken: controller.apiToken,
    });

    if (!cancelAdmit[1]) {
      controller.setState({
        WardQueueSequence: {
          ...state.WardQueueSequence,
          selectedAdmitRoomItem: null,
        },
        successMessage: { ...state.successMessage, [params.card]: true },
      });
    } else {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: cancelAdmit[1] },
      });
    }

    await controller.handleEvent({
      message: "RunSequence",
      params: { ...params, action: "search" },
    });
  } else if (params.action === "change_bed") {
    // const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card]: "LOADING",
      },
    });

    const result = await AdmitOrderRoomItemDetail.update({
      pk: params.admitOrderRoomItemId,
      apiToken: controller.apiToken,
      data: params.data,
      extra: {
        device: controller.data.device,
        division: params.division,
      },
    });

    if (result[1]) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: result[1],
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card]: "ERROR",
        },
      });
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: null,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card]: "SUCCESS",
        },
      });

      await controller.handleEvent({
        message: "RunSequence",
        params: { ...params, action: "search" },
      });
    }
  } else if (params.action === "move_out") {
    if (state.WardQueueSequence?.selectedAdmitRoomItem?.room_item_id) {
      if (state.WardQueueSequence?.moveOut?.error) {
        controller.setState({
          WardQueueSequence: {
            ...state.WardQueueSequence,
            moveOut: {
              ...state.WardQueueSequence?.moveOut,
              error: null,
            },
          },
        });
      }

      let res = await AdmitOrderRoomItemDetail.update({
        pk: state.WardQueueSequence?.selectedAdmitRoomItem?.room_item_id,
        apiToken: controller.apiToken,
        extra: {
          device: controller.data.device,
          division: params.division,
        },
        data: {
          action: "TRANSFER",
          cancel_reason: "",
          cleaning: state.WardQueueSequence.moveOut.cleaningChoise,
        },
      });
      state = controller.getState();
      if (res[1]) {
        // let roomItem = state.WardQueueSequence?.selectedAdmitRoomItem
        controller.setState({
          WardQueueSequence: {
            ...state.WardQueueSequence,
            moveOut: {
              ...state.WardQueueSequence?.moveOut,
              error: res[1],
            },
          },
        });

        return;
      }
      if (res[0]) {
        let roomItem = state.WardQueueSequence?.selectedAdmitRoomItem;
        controller.setState({
          WardQueueSequence: {
            ...state.WardQueueSequence,
            moveOut: { open: false, cleaningChoise: null, error: null },
          },
          successMessage: {
            ...state.successMessage,
            [params.card]: `ย้ายผู้ป่วย ${roomItem?.patient_name} (hn: ${roomItem?.hn}) ออกจาก RoomNo: ${roomItem?.room_no} (Ward: ${roomItem?.ward_name}) สำเร็จ`,
          },
        });

        if (params.barcode) {
          await FilterQueue(controller, {
            action: "barcode_search",
            card: params.card,
            barcode: params.barcode,
          });
        } else {
          await FilterQueue(controller, {
            action: "search",
            card: params.card,
          });
        }
      }
    }
  }
};

export const AdmitAction: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.WardQueueSequence) {
    return;
  }

  if (params?.action === "new_form") {
    const [intraRes] = await IntraHospitalTransferFormDefaultInfo.get({
      apiToken: controller.apiToken,
      patient: state.selectedPatient?.id || params.patientId,
    });

    const [adrRes] = await AdverseReactionList.list({
      apiToken: controller.apiToken,
      params: {
        patient: state.selectedPatient?.id || params.patientId,
        exclude_unused: true,
      },
    });

    let adr = "";
    adrRes.items.forEach((object: any) => {
      if (object.name === "") {
        adr += object.note;
      } else {
        adr += object.name;
      }
      adr += "\n";
    });

    // load
    controller.setState({
      intraTransferForm: {
        items: state.intraTransferForm?.items || [],
        id: null,
        show: true,
        from_div: state.django.division,
        to_div: null,
        chief_complaint: intraRes.chief_complaint || "",
        vital_bp: intraRes.BP || "",
        vital_body_temp: intraRes.BT || "",
        vital_o2_sat: intraRes.O2Sat || "",
        vital_heart_rate: intraRes.PR || "",
        vital_respiratory_rate: intraRes.RR || "",
        allergy: adr,
        transferring_personnel_date: formatDate(moment()),
      },
    });
  } else if (params?.action === "ward") {
    const ward = await AdmitOrderRoomItemDetail.update({
      pk: params.admitRoomItemId,
      apiToken: controller.apiToken,
      data: {
        action: "WARD",
      },
      extra: {
        device: controller.data.device,
        division:
          state.WardQueueSequence?.filterQueue?.division ||
          controller.data.division,
      },
    });

    if (ward[1]) {
      console.log("Can not admit cause: ", ward[1]);
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: ward[1] },
          },
        });
      }
      return;
    } else {
      return controller.setState(
        {
          successMessage: params.card
            ? { ...state.successMessage, [params.card]: "รับเข้าสำเร็จ" }
            : "รับเข้าสำเร็จ",
          WardQueueSequence: {
            ...state.WardQueueSequence,
            sequenceIndex: "FilterQueue",
          },
          modAdmit: {
            open: false,
          },
          intraTransferForm: {
            show: false,
          },
          selectedEncounter: null
        },
        async () => await FilterQueue(controller, { action: "search" })
      );
    }
  } else if (params?.action === "close_modal") {
    return controller.setState({
      WardQueueSequence: {
        ...state.WardQueueSequence,
        sequenceIndex: "FilterQueue",
      },
      modAdmit: {
        open: false,
      },
      intraTransferForm: {
        show: false,
      },
      selectedEncounter: null
    });
  } else if (params?.action === "hide_form") {
    return controller.setState({
      intraTransferForm: {
        show: false,
      },
    });
  } else if (params.action === "tokenize") {
    if (!state.intraTransferForm) {
      return;
    }

    controller.setState({
      intraTransferForm: {
        ...state.intraTransferForm,
        [params.key]: {
          ...((state.intraTransferForm as any)[params.key] || {}),
          loading: true,
        },
      },
    });

    const tokenize = await HandleGetEmployeeTokenization(controller as any, {
      code: params.code,
    });

    if (tokenize[1]) {
      return controller.setState({
        intraTransferForm: {
          ...state.intraTransferForm,
          [params.key]: {
            ...((state.intraTransferForm as any)[params.key] || {}),
            loading: false,
            error: true,
          },
        },
      });
    }

    return controller.setState({
      intraTransferForm: {
        ...state.intraTransferForm,
        [params.key]: {
          ...((state.intraTransferForm as any)[params.key] || {}),
          loading: false,
          error: false,
          name:
            decodeURIComponent(atob(tokenize[0]?.token.split(".")[1])) || "",
          token: tokenize[0]?.token,
        },
      },
    });
  } else if (params.action === "save_intra") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });

    const {
      id,
      show,
      from_div,
      to_div,
      receivingTokenize,
      transferringTokenize,
      items,
      ...res
    }: Record<string, any> = params.deleteItem
        ? formatIntraTransfer(
          params.deleteItem,
          state.intraHospitalTransferOptions
        )
        : state.intraTransferForm || {};

    const getText = (optionKey: string, valueKey: string, multiple = false) => {
      const options: any[] =
        state.intraHospitalTransferOptions?.[optionKey] || [];
      if (!multiple) {
        return (
          options?.find((item: any) => item.value === res?.[valueKey])?.text ||
          ""
        );
      } else {
        return (res?.[valueKey] || [])
          .map(
            (id: number) =>
              options.find((acc: any) => acc.value === id)?.text || ""
          )
          .join(", ");
      }
    };

    const reason_to_transfer = getText(
      "reason_to_transfer_choices",
      "reason_to_transfer"
    );
    const mode_of_transfer = getText(
      "mode_of_transfer_choices",
      "mode_of_transfer"
    );
    const isolation_precaution = getText(
      "isolation_precaution_choices",
      "isolation_precaution",
      true
    );
    const type_of_transfer = getText(
      "type_of_transfer_choices",
      "type_of_transfer"
    );
    const conciousness = getText("conciousness_level_choices", "conciousness");
    const protection_detail = getText(
      "protection_choices",
      "protection_detail",
      true
    );
    const special_care = getText("special_care_choices", "special_care", true);

    const data = {
      from_div,
      data: {
        chief_complaint: "",
        allergy: "",
        vital_bp: "",
        vital_heart_rate: "",
        vital_respiratory_rate: "",
        vital_body_temp: "",
        vital_o2_sat: "",
        oxygen_in_use: false,
        bleeding_precaution: false,
        bleeding_precaution_inr: "",
        bleeding_precaution_plt: "",
        bleeding_precaution_hct: "",
        bleeding_precaution_hb: "",
        protection: false,
        fall_risk: false,
        pain: false,
        pain_score: "",
        intervention: false,
        intervention_detail: "",
        medication: "",
        special_equipment_come_with_patient: "",
        special_equipment_to_be_prepared: "",
        valuable: "",
        other: "",
        post_transfer_assessment: false,
        post_transfer_assessment_detail: "",
        receiving_personnel: "",
        receiving_personnel_time: "",
        receiving_personnel_date: "",
        transferring_personnel: "",
        transferring_personnel_time: "",
        transferring_personnel_date: "",
        doctor: null,
        doctor_name_code: "",
        ...res,
        reason_to_transfer,
        mode_of_transfer,
        isolation_precaution,
        type_of_transfer,
        conciousness,
        protection_detail,
        special_care,
      },
      id: id || null,
      patient: state.selectedPatient?.id || params.patientId || null,
      to_div,
    };

    let intra: any = [null, null, null];

    if (!id) {
      intra = await IntraHospitalTransferFormList.create({
        apiToken: controller.apiToken,
        data: {
          action: "SAVE",
          ...data,
        },
      });
    } else if (params.deleteItem) {
      intra = await IntraHospitalTransferFormDetail.patch({
        apiToken: controller.apiToken,
        pk: id,
        data: {
          action: "CANCEL",
          ...data,
        },
      });
    } else {
      intra = await IntraHospitalTransferFormDetail.update({
        apiToken: controller.apiToken,
        pk: id,
        data: {
          action: "EDIT",
          ...data,
        },
      });
    }

    if (intra[1]) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: intra[1] },
        loadingStatus: { ...state.loadingStatus, [params.card]: undefined },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: null },
        loadingStatus: { ...state.loadingStatus, [params.card]: null },
      });

      IntraTransferInitial(controller, {
        sequence: "WardQueue",
        nextSequenceIndex: "AdmitAction",
        action:
          params.deleteItem?.id === state.intraTransferForm?.id
            ? "new_form"
            : "selected",
        update: true,
        item: intra[0],
        patientId: params.patientId,
      });
    }
  } else if (params.action === "selected") {
    if (state.intraTransferForm?.id === params.item.id && !params.update) {
      return controller.setState({
        intraTransferForm: {
          items: state.intraTransferForm?.items || [],
          id: null,
          show: false,
        },
      });
    }

    const [res] = await IntraHospitalTransferFormDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.item?.id,
    });

    controller.setState({
      intraTransferForm: {
        items: state.intraTransferForm?.items || [],
        ...formatIntraTransfer(res, state.intraHospitalTransferOptions),
      },
    });
  } else if (params.action === "print") {
    const result = await IntraHospitalTransferFormPrint.retrieve({
      apiToken: controller.apiToken,
      pk: state.intraTransferForm?.id,
      extra: {
        division: controller.data.division,
        device: controller.data.device,
      },
    });
  }
};

const formatIntraTransfer = (detail: any = {}, intraOptions: any = {}) => {
  const getValue = (optionKey: string, valueKey: string, multiple = false) => {
    const options: any[] = intraOptions?.[optionKey] || [];

    if (!multiple) {
      return (
        options?.find((item: any) => item.text === detail?.data?.[valueKey])
          ?.value || ""
      );
    } else {
      return detail?.data?.[valueKey]
        ?.split(",")
        .map(
          (text: string) =>
            options.find((acc: any) => acc.text === text.trim())?.value || ""
        );
    }
  };

  const reason_to_transfer = getValue(
    "reason_to_transfer_choices",
    "reason_to_transfer"
  );
  const mode_of_transfer = getValue(
    "mode_of_transfer_choices",
    "mode_of_transfer"
  );
  const isolation_precaution = getValue(
    "isolation_precaution_choices",
    "isolation_precaution",
    true
  );
  const type_of_transfer = getValue(
    "type_of_transfer_choices",
    "type_of_transfer"
  );
  const conciousness = getValue("conciousness_level_choices", "conciousness");
  const protection_detail = getValue(
    "protection_choices",
    "protection_detail",
    true
  );
  const special_care = getValue("special_care_choices", "special_care", true);

  return {
    id: detail?.id,
    show: true,
    from_div: detail.from_div,
    to_div: detail.to_div,
    ...(detail.data || {}),
    reason_to_transfer,
    mode_of_transfer,
    isolation_precaution,
    type_of_transfer,
    conciousness,
    protection_detail,
    special_care,
    receivingTokenize: {
      name: detail.data.receiving_personnel
        ? decodeURIComponent(
          atob(detail.data.receiving_personnel.split(".")[1])
        ) || ""
        : "",
      token: detail.data.receiving_personnel,
    },
    transferringTokenize: {
      name: detail.data.transferring_personnel
        ? decodeURIComponent(
          atob(detail.data.transferring_personnel.split(".")[1])
        )
        : "",
      token: detail.data.transferring_personnel,
    },
  };
};
