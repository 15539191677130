import WasmController from "react-lib/frameworks/WasmController";

// APIs

// seq
import * as UnderlyingDiseaseI from "./sequence/UnderlyingDisease";
import * as DrugDiseaseInteractionI from "./sequence/DrugDiseaseInteraction";
import * as HealthMemberI from "./sequence/HealthMember";

// Utils

export type State = {} & UnderlyingDiseaseI.State & HealthMemberI.State;

export const StateInitial: State = {
  // ...UnderlyingDiseaseI.StateInitial,
  // ...DrugDiseaseInteractionI.StateInitial,
  // ...HealthMemberI.StateInitial,
};

export type Event =
  | { message: "HandleGetSubSegmentList"; params: { id?: number } }
  | UnderlyingDiseaseI.Event
  | DrugDiseaseInteractionI.Event
  | HealthMemberI.Event;

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {
  ...UnderlyingDiseaseI.DataInitial,
  ...DrugDiseaseInteractionI.DataInitial,
  ...HealthMemberI.DataInitial,
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;
