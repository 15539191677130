import { AxiosResponse } from 'axios';

import _ from '../compat/lodashplus';

export * from './dateUtils';

export const downloadFile = (response: AxiosResponse): void => {
  // from: https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
  let disposition: string = response.headers['content-disposition'] || "";

  const encodedText = /=\?utf-8\?b\?(.*)\?=/g.exec(disposition)?.[1] || "";

  if (encodedText) {
    disposition = base64DecodeUnicode(encodedText);
  }

  const filename = decodeURI(/filename="?([^"\s]+)"?/.exec(disposition)?.[1] || "");
  const blob = new Blob([response.data], {type: 'application/octet-stream'});
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');

  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  document.body.append(tempLink);
  tempLink.click();
  tempLink.remove();
  window.URL.revokeObjectURL(blobURL);
};

const base64DecodeUnicode = (str: string) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    [...atob(str)]
      .map(function (c) {
        const codePoint = c.codePointAt(0)?.toString(16);
        const paddedCodePoint = `00${codePoint}`.slice(-2);
        return `%${paddedCodePoint}`;
      })
      .join("")
  );
};