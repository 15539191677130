import React, { SyntheticEvent, useEffect, useState } from "react";
import CardRehabExaminationUX from "./CardRehabExaminationUX";
import { TextAreaProps } from "semantic-ui-react";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";
import TableTreatmentPlan from "react-lib/apps/HISV3/RHB/TableTreatmentPlan";
import { RehabPlanType } from "./RHBInterface";

type CardRehabExaminationProps = {
  onEvent: any;
  masterOptions: any;
  controller: any;
  searchedItemListWithKey: any;
  rehabExamPlan: RehabPlanType; 
  setProp: any;
};

const CardRehabExamination = (props: CardRehabExaminationProps) => {
  // const [problem, setProblem] = useState("");
  // const [goal, setGoal] = useState("");
  // const [precaution, setPrecaution] = useState([]);
  const [order, setOrder] = useState<null | Record<string, any>>(null);
  const [department, setDepartment] = useState("");
  const [tableData, setTableData] = useState<any[]>([]);

  // Options
  const [rehabDivisions, setRehabDivisions] = useState<any[]>([]);

  useEffect(() => {
    props?.onEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["rhb_precaution", {}],
          ["RehabilitationList", {}]
      ],
      },
    });

    props?.onEvent({
      message: "getRhbExamPlan",
      params: {},
    });

    return (() => {
      props?.onEvent({
        message: "clearRhbExamPlan",
        params: {},
      }); 
    })
  }, []);

  useEffect(() => {
    let rehabilitationList = props.controller.data?.masterData?.RehabilitationList 
    console.log('CardRehabExamination rehabilitationList: ', rehabilitationList);
    console.log('CardRehabExamination props.rehabExamPlan?.items: ', props.rehabExamPlan?.items);
 
    let items = props.rehabExamPlan?.items || []
    if (props.rehabExamPlan?.items?.length > 0 && props.controller.data?.masterData?.RehabilitationList) { 
      /// add treatmentName from product 
      items = props.rehabExamPlan?.items?.map((item: any) => ({
       ...item,
       productName: rehabilitationList?.find((rehab : any) => item.product === rehab.id)?.name 
      })) 
    }
    console.log('CardRehabExamination items: ', items);
    setTableData(items)

  }, [props.rehabExamPlan?.items, props.controller.data?.masterData?.RehabilitationList])


  // console.log("CardRehabExamination props: ", props)

  // GENERAL = 1, 'ทั่วไป'
  // BILLING = 2, 'การเงิน'
  // DRUG = 3, 'ห้องยา'
  // SUPPLY = 4, 'ห้องเวชภัณฑ์'
  // SPECIMEN_COLLECTOR = 6, 'แผนกเก็บ Specimen'
  // CLINIC = 7, 'หน่วยตรวจ'
  // PREMIUM_CLINIC = 8, 'คลินิกพรีเมี่ยม'
  // WARD = 9, 'หอผู้ป่วย'
  // ZONE = 10, 'หน่วยย่อย'
  // SHORTSTAY = 11, 'short stay'
  // WARD_PREMIUM = 12, 'หอผู้ป่วยพรีเมี่ยม'
  // OPERATING_ROOM = 16, 'ห้องผ่าตัด'
  // BACK_OFFICE = 17, 'backoffice'

  useEffect(() => {
    console.log("rehabDivs props?.controller?.data?.allDivisionList: ", props?.controller?.data?.allDivisionList);
    let rehabDivs =
      props?.controller?.data?.allDivisionList
        ?.filter((i: any) => i.is_active && [7, 9, 12].includes(i.type))
        ?.map((i: any) => ({ key: i.id, text: i.name, value: i.id })) || [];
    console.log("rehabDivs: ", rehabDivs);
    setRehabDivisions(rehabDivs);
  }, [props?.controller?.data?.allDivisionList]);

  return (
    <CardRehabExaminationUX
      problem={props.rehabExamPlan?.problem}
      onChangeProblem={(event: SyntheticEvent, data: TextAreaProps) => props.setProp("rehabExamPlan.problem", data.value as string)}
      goal={props.rehabExamPlan?.goal}
      onChangeGoal={(event: SyntheticEvent, data: TextAreaProps) => props.setProp("rehabExamPlan.goal", data.value as string)}
      precautionOptions={props.masterOptions?.rhb_precaution || []}
      precaution={props.rehabExamPlan?.precaution}
      onChangePrecaution={(event: SyntheticEvent, data: TextAreaProps) => props.setProp("rehabExamPlan.precaution", data.value )} 
      searchOrder={
        <SearchBoxWithKey
          // callback
          onEvent={props.onEvent}
          type="RehabTreatment"
          id={"RehabTreatment_1"}
          searchedItemListWithKey={props.searchedItemListWithKey}
          selectedItem={order?.value}
          setSelected={(value, key, data, action) => {
            if (value) {
              setOrder({ value, key, text: data?.name });
            } else {
              setOrder(null);
            }
          }}
        />
      }
      // order={order}
      // onChangeOrder={(event: SyntheticEvent, data: TextAreaProps) => setOrder(data.value as string)}

      departmentOptions={rehabDivisions}
      department={department}
      onChangeDepartment={(event: SyntheticEvent, data: any) => {
        setDepartment(data?.value);
        console.log("setDepartment data: ", data);
      }}
      disabledAddBtn={!department || !order}
      disableSave={!tableData?.some((i: any) => i.icd9_type === "PRIMARY")}
      onSave={() => {
        // Check icd9_type === "PRIMARY"
        let isSelectPrimary = tableData?.some((i: any) => i.icd9_type === "PRIMARY");
        if (!isSelectPrimary) {
          alert(" No select primary");
          return;
        }

        let noTotalInRows = tableData?.some((i: any) => !i.total);

        if (noTotalInRows) {
          alert(" No Total in some rows");
          return;
        }

        props.onEvent({
          message: "createUpdateRhbExamPlan",
          params: {
            problem: props.rehabExamPlan?.problem,
            goal: props.rehabExamPlan?.goal,
            precaution: props.rehabExamPlan?.precaution?.map(
              (value: any) => props.masterOptions?.rhb_precaution?.find((r: any) => r.value === value)?.text
            )?.filter(Boolean),
            id: props.rehabExamPlan?.id,
            items: tableData,
          },
        });
      }}
      onClickAdd={() => {
        tableData.push({
          to_department: department,
          product: order?.key,
          productName: order?.text,
          icd9_type: "SECONDARY",
          detail: "",
          frequency: 0,
          duration: 0,
          total: 0,
          // icd9Name: "",
          icd9Data: null,
        });
        setTableData([...tableData]);
        setOrder(null);
      }}
      table={
        <TableTreatmentPlan
          // icd9Table={props.rehabExamPlan?.icd9Table}
          data={tableData}
          deleteDataIdx={(idx: number) => {
            tableData.splice(idx, 1);
            setTableData([...tableData]);
          }}
          onChangeTableData={(idx, key, value) => {
            let data = [...tableData];
            if (key === "icd9_type" && value === "PRIMARY") {
              data = data?.map((i: any) => ({ ...i, icd9_type: "SECONDARY" }));
            }
            data[idx][key] = value;
            setTableData([...data]);
          }}
          departmentOptions={props?.masterOptions?.division || []}
          onEvent={props.onEvent}
          searchedItemListWithKey={props.searchedItemListWithKey}
        />
      }
    />
  );
};

export default CardRehabExamination;
