import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Menu, Input, Button, Label, Message, Modal, Checkbox, RadioProps } from "semantic-ui-react";
import CardAdverseReactionNoteUX from "./CardAdverseReactionNoteUX";
import CardNaranjoAlgorithmUX from "./CardNaranjoAlgorithmUX";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModInfoError from "react-lib/appcon/common/ModInfo";

// import {
//   Table
// } from 'react-lib/frameworks/Table'

import "./CardAdverseReactionNote.scss";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import moment from "moment";
import { useIntl } from "react-intl";

const CARD_ALLERGY = "CardAllergy";

type CardAdverseReactionNoteProps = {
  onCancelNewADRNote: any;
  onSaveNewADRNote: any;
  onSaveCommonADRNote?: any;
  AllergySequence: any;
  onEvent: any;
  adrSelected: any;
  masterOptions: any;
  searchedItemList: any;
  setProp: any;
  onEdit?: any;
  rolePharma?: any;
};

const CardAdverseReactionNote = (props: CardAdverseReactionNoteProps) => {
  const intl = useIntl();
  const [showNaranjo, setShowNaranjo] = useState<boolean>(false);
  const [showPharmacist, setShowPharmacist] = useState(false);
  const [openTextAlert, setOpenTextAlert] = useState<string>("");

  // const [selectedAdversary, setSelectedAdversary] = useState<any>(null);
  // const [selectedAdversaryId, setSelectedAdversaryId] = useState<number | null >(null);

  // useEffect(() => {
  //   if (props.AllergySequence?.selectedAdversaryName !== null) {
  //     props.onEvent({
  //       message: "ItemSearch",
  //       params: { action: "clear" },
  //     });
  //     props.setProp("AllergySequence.selectedAdversaryName", null)
  //   }
  // }, [props.AllergySequence?.activeADRMenu]);

  useEffect(() => {
    if (props.rolePharma) {
      setShowPharmacist(true);
    } else {
      setShowPharmacist(false);
    }
  }, [props.AllergySequence?.adrList]);

  const adrSymptomsOption = useMemo(() => {
    return [
      ...(props.masterOptions?.ADRSymptoms || []),
      // { key: "Other", value: "Other", text: "Other" },
    ];
  }, [props.masterOptions?.ADRSymptoms?.other_symptom]);

  var today = new Date();
  var getdate = today.getDate() + "/" + (today.getMonth() + 1) + "/" + (today.getFullYear() + 543);
  const dayStart = moment(getdate, "DD/MM/YYYY").lang("th").format("DD/MM/YYYY");

  const handleItemClick = (e: any, data: any) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "selectedADRType",
        activeADRMenu: data.name,
      },
    });
  };

  const handleItemClickFreeText = (e: any, data: any) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "selectedADRType",
        activeADRMenu: data.name,
      },
    });
  };

  const handleItemClickND = (e: any, data: any) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "selectedADRType",
        activeADRMenu: data.name,
      },
    });
  };

  const handleItemClickCH = (e: any, data: any) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "selectedADRType",
        activeADRMenu: data.name,
      },
    });
  };

  const handleNaranjoClick = () => {
    setShowNaranjo(true);
  };

  // useEffect(() => {
  //   setShowDrugForm(true);
  // }, [props.AllergySequence]);

  const affectTypeOptions = useMemo(
    () => [
      {
        key: "1",
        text: "แพ้ยา",
        value: "ALLERGY",
      },
      {
        key: "2",
        text: "มีอาการข้างเคียง",
        value: "SIDE_EFFECT",
      },
    ],
    []
  );
  const MenuADR = useMemo(
    () => (
      <div>
        <div className="card-adverse-reaction-note-menu">
          <div className="card-adverse-reaction-note-menu-button">
            <Message
              size="massive"
              color="blue"
              tertiary
              style={{
                paddingLeft: "0.5em",
                paddingRight: "0.5em",
                width: "max-content",
              }}
            >
              <Label className="floating-label" size="medium" basic color="blue">
                ยา[Drug]
              </Label>
              <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <Menu>
                  <Menu.Item
                    name="product"
                    active={props.AllergySequence?.activeADRMenu === "product"}
                    content="Trade name"
                    onClick={handleItemClick}
                  />
                  <Menu.Item
                    name="ingredient"
                    active={props.AllergySequence?.activeADRMenu === "ingredient"}
                    content="Generic name"
                    onClick={handleItemClick}
                  />
                  <Menu.Item
                    name="mims"
                    active={props.AllergySequence?.activeADRMenu === "mims"}
                    content="MIMS Group"
                    onClick={handleItemClick}
                  />
                  <Menu.Item
                    name="atc"
                    active={props.AllergySequence?.activeADRMenu === "atc"}
                    content="ATC Group"
                    onClick={handleItemClick}
                  />
                  <Menu.Item
                    name="freetext"
                    active={props.AllergySequence?.activeADRMenu === "freetext"}
                    content="FreeText"
                    onClick={handleItemClickFreeText}
                  />
                </Menu>
              </div>
            </Message>
          </div>
          <div style={{ marginLeft: "40px" }}>
            <div className="card-adverse-reaction-note-menu-button">
              <Message
                size="massive"
                color="blue"
                tertiary
                style={{
                  paddingLeft: "0.5em",
                  paddingRight: "0.5em",
                  width: "max-content",
                }}
              >
                <Label className="floating-label" size="medium" basic color="blue">
                  อื่นๆ[Other]
                </Label>
                <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                  <Menu>
                    <Menu.Item
                      name="food"
                      active={props.AllergySequence?.activeADRMenu === "food"}
                      content={intl.formatMessage({ id: "แพ้อาหาร" })}
                      onClick={handleItemClickND}
                    />
                    <Menu.Item
                      name="other"
                      active={props.AllergySequence?.activeADRMenu === "other"}
                      content={intl.formatMessage({ id: "แพ้สารอื่นๆ" })}
                      onClick={handleItemClickND}
                    />
                  </Menu>
                </div>
              </Message>
            </div>
          </div>
          <div
            style={{
              marginLeft: "40px",
              display: "flex",
              alignItems: "center",
              fontSize: "10px",
            }}
          >
            <div className="card-adverse-reaction-note-menu-button">
              <Button name="group" color="blue" size="large" onClick={handleItemClickCH}>
                เลือกจากข้อมูลที่พบบ่อย
              </Button>
            </div>
          </div>
        </div>
      </div>
    ),
    [props.AllergySequence?.activeADRMenu]
  );

  const handleOnChangeADR = (e: any, v: any) => {
    if (v.name === "symptoms" && !v.value.includes("Other")) {
      props.setProp("AllergySequence.adrSelected", {
        ...props.AllergySequence.adrSelected,
        other_symptom: "",
        [v.name]: v.type === "checkbox" ? v.checked : v.value,
      });
    } else {
      props.setProp(
        `AllergySequence.adrSelected.${v.name}`,
        v.type === "checkbox" ? v.checked : v.value
      );
    }
  };

  const handleOnChangeInactiveDate = (date: string) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "editADR",
        field: "inactive_start_date",
        value: date,
      },
    });
  };

  const handleonChangeDateSymptoms = (date: string) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "editADR",
        field: "issue_date",
        value: date,
      },
    });
  };

  const handleOnChangeTargetAction = (e: any, data: RadioProps) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "editADR",
        ...(data.value === "is_inactive"
          ? {
              field: "is_inactive",
              value: !data.checked,
            }
          : {
              field: "targetAction",
              value: data.value,
            }),
      },
    });
  };

  const handleChangeType = (e: any, { value }: any) => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "Allergy", action: "editADR", field: "type", value },
    });
  };

  const handleOnChangeNaranjoChecked = (e: any, v: any) => {
    props.setProp("AllergySequence.adrSelected.naranjoChecked", v.checked);

    if (!v.checked) {
      props.setProp("AllergySequence.adrSelected.naranjo_data", {});
      props.setProp("AllergySequence.adrSelected.score", {});
    } else {
      props.setProp("AllergySequence.adrSelected.probability", "");
    }
  };

  const handleChangeNaranjo = (e: any, v: any) => {
    props.setProp(`AllergySequence.adrSelected.naranjo_data.${v.name}`, v.value);

    if (["1", "3", "7", "8", "9", "10"].includes(v.name)) {
      if (v.value === "YES") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 1);
      } else if (v.value === "NO") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 0);
      } else if (v.value === "NA") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 0);
      }
    } else if (["2", "4"].includes(v.name)) {
      if (v.value === "YES") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 2);
      } else if (v.value === "NO") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, -1);
      } else if (v.value === "NA") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 0);
      }
    } else if (v.name === "5") {
      if (v.value === "YES") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, -1);
      } else if (v.value === "NO") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 2);
      } else if (v.value === "NA") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 0);
      }
    } else if (v.name === "6") {
      if (v.value === "YES") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, -1);
      } else if (v.value === "NO") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 1);
      } else if (v.value === "NA") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 0);
      }
    }

    let totalScore = 0;
    for (let i = 0; i <= 10; i++) {
      if (props.AllergySequence.adrSelected.score?.[`${i}`] !== undefined) {
        totalScore = totalScore + props.AllergySequence.adrSelected.score?.[`${i}`];
      }
    }
    props.setProp("AllergySequence.adrSelected.naranjo_data.score", totalScore);

    if (totalScore > 8) {
      props.setProp(
        "AllergySequence.adrSelected.naranjo_data.status",
        "Definite [ใช่แน่] (Naranjo’s >8)"
      );
    } else if (totalScore <= 8 && totalScore >= 5) {
      props.setProp(
        "AllergySequence.adrSelected.naranjo_data.status",
        "Probable [น่าจะใช่] (Naranjo’s 5-8)"
      );
    } else if (totalScore <= 4 && totalScore >= 1) {
      props.setProp(
        "AllergySequence.adrSelected.naranjo_data.status",
        "Possible [อาจจะใช่] (Naranjo’s 1-4)"
      );
    } else if (totalScore < 1) {
      props.setProp(
        "AllergySequence.adrSelected.naranjo_data.status",
        "Doubtful [น่าสงสัย] (Naranjo’s <1)"
      );
    }
  };

  const handleSetProbability = () => {
    var score = props.AllergySequence?.adrSelected?.naranjo_data?.score;
    if (score === 0) {
      props.setProp("AllergySequence.adrSelected.probability", 1);
    } else if (score >= 1 && score <= 4) {
      props.setProp("AllergySequence.adrSelected.probability", 2);
    } else if (score >= 5 && score <= 8) {
      props.setProp("AllergySequence.adrSelected.probability", 3);
    } else if (score >= 9) {
      props.setProp("AllergySequence.adrSelected.probability", 4);
    }
    setShowNaranjo(false);
  };

  const mapNameCode = (items: any) =>
    items.map((item: any) => ({
      key: item.id,
      value: item.name_code,
      text: item.name_code,
      content: (
        <div style={{ lineHeight: "1.6" }}>
          {" "}
          <span style={{ fontWeight: "bold" }}> {item.name_code} </span> <br />{" "}
          {item.drug_name_from_format}{" "}
        </div>
      ), // (<div style={{lineHeight: "1.6"}}> <span style={{fontWeight: "bold"}}> {item.name_code} </span> <br/> {item.full_name} </div>)
    }));

  const mapIngredient = (items: any) =>
    items.map((item: any) => ({
      key: item.id,
      value: item.name,
      text: item.name,
    }));

  const mapMims = (items: any) =>
    items.map((item: any) => ({
      key: item.id,
      value: item.name_desc,
      text: item.name_desc,
    }));

  const mapAtc = (items: any) =>
    items.map((item: any) => ({
      key: item.id,
      value: item.name_desc,
      text: item.name_desc,
    }));

  const setSelectedAdversary = useCallback(
    (name: string, id: number) => {
      props.setProp("AllergySequence", {
        ...props.AllergySequence,
        selectedAdversaryName: name,
        selectedAdversaryId: id,
      });
    },
    [props.AllergySequence, props.setProp]
  );

  const searchAdversary = useMemo(() => {
    let searchBox: any;

    switch (props.AllergySequence?.activeADRMenu) {
      case "product":
        searchBox = (
          <SearchBoxDropdown
            type={"ADR" + "product"}
            style={{ width: "100%" }}
            fluid={true}
            startSearchLength={3}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.AllergySequence?.selectedAdversaryName}
            setSelectedItem={setSelectedAdversary}
            mapOptions={mapNameCode}
          />
        );
        break;
      case "ingredient":
        searchBox = (
          <SearchBoxDropdown
            type={"ADR" + "ingredient"}
            style={{ width: "100%" }}
            fluid={true}
            startSearchLength={1}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.AllergySequence?.selectedAdversaryName}
            setSelectedItem={setSelectedAdversary}
            mapOptions={mapIngredient}
          />
        );

        break;
      case "mims":
        searchBox = (
          <SearchBoxDropdown
            type={"ADR" + "mims"}
            style={{ width: "100%" }}
            fluid={true}
            startSearchLength={1}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.AllergySequence?.selectedAdversaryName}
            setSelectedItem={setSelectedAdversary}
            mapOptions={mapMims}
          />
        );
        break;
      case "atc":
        searchBox = (
          <SearchBoxDropdown
            type={"ADR" + "atc"}
            style={{ width: "100%" }}
            fluid={true}
            startSearchLength={1}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.AllergySequence?.selectedAdversaryName}
            setSelectedItem={setSelectedAdversary}
            mapOptions={mapAtc}
          />
        );
        break;
      case "freetext":
        searchBox = (
          <Input
            fluid={true}
            onChange={handleOnChangeADR}
            name="name"
            value={props.AllergySequence?.adrSelected?.name}
          />
        );
        break;
      case "food":
        searchBox = (
          <Input
            fluid={true}
            onChange={handleOnChangeADR}
            name="other_adversary"
            value={props.AllergySequence?.adrSelected?.other_adversary}
          />
        );
        break;

      default:
        break;
    }

    return searchBox;
  }, [
    props.AllergySequence?.activeADRMenu,
    props.searchedItemList,
    props.AllergySequence?.selectedAdversaryName,
    props.onEvent,
    props.AllergySequence?.adrSelected?.name,
    setSelectedAdversary,
  ]);

  const handleSaveCommonADRNote = () => {
    let adrSelected = props.AllergySequence?.adrSelected;
    let textAlert = "";

    if (["product", "ingredient", "mims", "atc"].includes(props.AllergySequence?.activeADRMenu)) {
      if (!props.AllergySequence?.selectedAdversaryName) {
        textAlert = `${textAlert} สารก่อการแพ้, `;
      }
      if (!adrSelected?.adversary_symptom_type) {
        textAlert = `${textAlert} ประเภท, `;
      }
      if (!adrSelected?.note) {
        textAlert = `${textAlert} ข้อความแจ้งเตือน`;
      }
    } else if (props.AllergySequence?.activeADRMenu === "freetext") {
      if (!adrSelected?.note) {
        textAlert = `${textAlert} รายละเอียดการแพ้ยา`;
      }
    } else if (["food", "other"].includes(props.AllergySequence?.activeADRMenu)) {
      if (!adrSelected?.name) {
        textAlert = `${textAlert} รายละเอียด, `;
      }
      if (!adrSelected?.note) {
        textAlert = `${textAlert} อาการแพ้`;
      }
    }

    if (textAlert) {
      setOpenTextAlert(`กรอกข้อมูลที่จำเป็น ${textAlert}`);
    } else if (textAlert === "" && adrSelected) {
      props.onSaveNewADRNote();
    }
  };

  return (
    <>
      <CardAdverseReactionNoteUX
        onCancelNewADRNote={props.onCancelNewADRNote}
        onSaveNewADRNote={handleSaveCommonADRNote}
        AllergySequence={props.AllergySequence}
        naranjoChecked={props.AllergySequence?.adrSelected?.naranjoChecked}
        naranjo={props.AllergySequence?.adrSelected?.naranjo_data}
        adrSelected={props.adrSelected}
        adrSymptomsOptions={adrSymptomsOption}
        adrSeverityOptions={props.masterOptions?.ADRSeverity || []}
        adrProbabilityOptions={props.masterOptions?.ADRProbability || []}
        searchAdversary={searchAdversary}
        // new ADR
        adversaryText={props.AllergySequence?.adrSelected?.name}
        symptoms={props.AllergySequence?.adrSelected?.symptoms || []}
        // TODO: ซ่อนไปก่อน เพราะ ยังไม่ได้ทำหลังบ้าน
        affectTypeOptions={affectTypeOptions} //
        affectType={props.AllergySequence?.adrSelected?.adversary_symptom_type} // ตัว type คือ DRUG, OTHER คนละตัว กับตัว affectType (new)
        severity={props.AllergySequence?.adrSelected?.severity}
        probability={props.AllergySequence?.adrSelected?.probability}
        note={props.AllergySequence?.adrSelected?.note}
        dateSymptoms={props.AllergySequence?.adrSelected?.issue_date}
        onChangeADR={handleOnChangeADR}
        onChangeNaranjoChecked={handleOnChangeNaranjoChecked}
        onChangeType={handleChangeType}
        verifyChecked={props?.AllergySequence.adrSelected?.verifyChecked}
        onChangeTargetAction={handleOnChangeInactiveDate}
        onChangeDateSymptoms={handleonChangeDateSymptoms}
        menu={MenuADR}
        drugForm={["product", "ingredient", "mims", "atc"].includes(
          props?.AllergySequence.activeADRMenu
        )}
        otherForm={["food", "other"].includes(props?.AllergySequence.activeADRMenu)}
        showPharma={showPharmacist ? "inline-block" : "none"}
        typePharma={showPharmacist ? "flex" : "none"}
        drugFreeText={props?.AllergySequence.activeADRMenu === "freetext"}
        groupForm={props.AllergySequence?.activeADRMenu === "group"}
        onClickNaranjo={handleNaranjoClick}
        pharmacyAction={props.AllergySequence?.adrSelected?.targetAction}
        onChangePharmacyAction={handleOnChangeTargetAction}
        adrActive={!props.AllergySequence?.adrSelected?.is_inactive}
        inactiveDate={
          props.AllergySequence?.adrSelected?.is_inactive && (
            <DateTextBox
              maxDate={dayStart}
              value={props?.AllergySequence?.adrSelected?.inactive_start_date}
              onChange={handleOnChangeInactiveDate}
            />
          )
        }
        modalNaranjo={
          <Modal
            open={showNaranjo}
            onClose={() => setShowNaranjo(false)}
            onOpen={() => setShowNaranjo(true)}
            size="large"
          >
            <CardNaranjoAlgorithmUX
              naranjo={props.AllergySequence?.adrSelected?.naranjo_data}
              score={props.AllergySequence?.adrSelected?.score}
              onChangeNaranjo={handleChangeNaranjo}
              onSave={handleSetProbability}
              onCencel={() => {
                props.setProp(`AllergySequence.adrSelected.naranjo_data`, {});
                props.setProp(`AllergySequence.adrSelected.score`, {});
                setShowNaranjo(false);
              }}
              languageUX={props.languageUX}
            />
          </Modal>
        }
        ingredientList={
          <>
            {props.AllergySequence.ingredientList?.map((item: any, number: any) => {
              return (
                <div
                  style={{
                    display: "flex",
                    margin: "20px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "5%" }}>
                    <Checkbox
                      checked={props.AllergySequence?.ingredientData?.[number]?.checked || false}
                      onChange={(e: any, v: any) => {
                        props.setProp(`AllergySequence.ingredientData.${number}`, {
                          ...item,
                          ...props.AllergySequence?.ingredientData?.[number],
                          checked: v.checked,
                        });
                      }}
                    />
                  </div>
                  <div style={{ width: "20%", margin: "0px 20px" }}>{item.name}</div>
                  <div style={{ width: "75%" }}>
                    <Input
                      fluid={true}
                      value={props.AllergySequence?.ingredientData?.[number]?.note}
                      onChange={(e: any, v: any) => {
                        props.setProp(`AllergySequence.ingredientData.${number}.note`, v.value);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        }
        drugGroupList={
          <>
            {props.AllergySequence.drugGroupList?.map((item: any, number: any) => {
              return (
                <div
                  style={{
                    display: "flex",
                    margin: "20px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "5%" }}>
                    <Checkbox
                      checked={props.AllergySequence?.drugGroupData?.[number]?.checked || false}
                      onChange={(e: any, v: any) => {
                        props.setProp(`AllergySequence.drugGroupData.${number}`, {
                          ...item,
                          ...props.AllergySequence?.drugGroupData?.[number],
                          checked: v.checked,
                        });
                      }}
                    />
                  </div>
                  <div style={{ width: "20%", margin: "0px 20px" }}>{item.name}</div>
                  <div style={{ width: "75%" }}>
                    <Input
                      fluid={true}
                      value={props.AllergySequence?.drugGroupData?.[number]?.note}
                      onChange={(e: any, v: any) => {
                        props.setProp(`AllergySequence.drugGroupData.${number}.note`, v.value);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </>
        }
        onSaveCommonADRNote={props.onSaveCommonADRNote}
        languageUX={props.languageUX}
      />

      <ModInfoError
        titleColor="red"
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}
        btnText={intl.formatMessage({ id: "ตกลง" })}
        size="mini"
        open={openTextAlert !== ""}
        alertText={openTextAlert}
        onApprove={() => setOpenTextAlert("")}
      />

      <ModConfirm
        openModal={!!props.AllergySequence?.modConfirmInactive?.open}
        titleName={intl.formatMessage({ id: "ข้อความแจ้งเตือน" })}
        titleColor={"red"}
        content={
          <div
            style={{
              display: "flex",
              margin: "-1rem 0px -1rem",
              flexDirection: "column",
            }}
          >
            <div style={{ paddingBottom: "10px" }}>
              กรุณาใส่เหตุผล เพื่อยืนยันการ Inactive ประวัติ ADR
            </div>
            <Input
              value={props.AllergySequence?.modConfirmInactive?.confirmNote}
              onChange={(e: any, data: any) => {
                props.setProp("AllergySequence.modConfirmInactive.confirmNote", data.value);
              }}
            />
          </div>
        }
        onApprove={props.onSaveNewADRNote}
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        approveButtonText={intl.formatMessage({ id: "ยืนยัน" })}
        approveButtonColor="green"
        basic={false}
        onDeny={() => {
          props.setProp("AllergySequence.modConfirmInactive.open", false);
        }}
      />
    </>
  );
};

export default React.memo(CardAdverseReactionNote);
