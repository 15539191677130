import * as I from "./AppInitialize";
import * as ZoneLogI from "./ZoneLog";
import * as RejectOrderI from"./RejectOrder";
import * as OrderSetTemplateI from "./OrderSetTemplate";

const SequencePattern: { [name: string]: { [flavor: string] : { [func: string]: any }}} = {
  AppInitialize: { 
    default: {
      START: I.First,
      Login: I.Login,
      GetUserInfo: I.GetUserInfo,
      LoginFirebase: I.LoginFirebase,
      Finish: I.Finish,
    }    
  },
  ZoneLog:{
    default:{
      START: ZoneLogI.Start,
    }
  },
  RejectOrder:{
    default:{
      START: RejectOrderI.Start,
      SearchAndAction: RejectOrderI.SearchAndAction
    }
  },
  OrderSetTemplate:{
    default:{
      START: OrderSetTemplateI.Start,
      SearchAndAction: OrderSetTemplateI.SearchAndAction
    }
  },
};

export default SequencePattern;
