import React, { useState, useMemo } from "react";

// Utils
import { Button, Form, FormGroup, Dropdown, Radio } from "semantic-ui-react";
import moment from "moment";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

// Const
const ACCOUNT_TYPE = [
  { key: "4053000003", value: "4053000003", text: "รายได้ค่ารักษา" },
  { key: "4992030002", value: "4992030002", text: "ค่าทำสำเนาเวชระเบียน" },
  { key: "4999000019", value: "4999000019", text: "ค่าขายของเบ็ดเตล็ด" },
  { key: "4999000031", value: "4999000031", text: "ค่าทำบัตรเวชระเบียน" },
];

const COST_CENTER = [{ key: "ALL", value: "ALL", text: "-ALL-" }];

const CURRENT_YEAR = Number(moment().format("YYYY")) + 543;

const CardSapMedExpenseCostCenterFilter = (props: any) => {
  const intl = useIntl();
  // useState
  const [selectedAccountType, setSelectedAccountType] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(false);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(false);

  // UseMemo
  const coseCenterOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "-ALL-" },
      ...(props.SapAccountingReportSequence?.costCenterOption || []),
    ];
  }, [props.SapAccountingReportSequence?.costCenterOption]);

  const coverageOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "ทั้งหมด" },
      ...(props.masterOptions?.coverage || []),
    ];
  }, [props.masterOptions?.coverage]);

  const stationOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "ทั้งหมด" },
      ...(props.masterOptions?.bilStation || []),
    ];
  }, [props.masterOptions?.bilStation]);

  const accountTypeOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "ALL" },
      ...(ACCOUNT_TYPE || []),
    ];
  }, [ACCOUNT_TYPE]);

  const fiscalYearOptions = useMemo(() => {
    const years = Array(51)
      .fill("")
      .map((_, index) => CURRENT_YEAR - 30 + index);

    return years.map((year) => ({ key: year, value: year, text: year }));
  }, []);

  const monthYearOptions = useMemo(() => {
    const months = Array.from({ length: 12 }, (_, i) => {
      return moment(`1997-${i + 1}-1`)
        .locale("th")
        .format("MMMM");
    });

    // เดือน + ปีที่เลือก
    return months.map((month, index) => ({
      key: month,
      value: `${index + 1} ${
        props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedFiscalYear
      }`,
      text: `${month} ${props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedFiscalYear}`,
    }));

    // เดือน + ปีปัจจุบัน
    // return months.map((month, index) => ({
    //   key: month,
    //   value: `${index + 1} ${CURRENT_YEAR}`,
    //   text: `${month} ${CURRENT_YEAR}`,
    // }));
  }, [props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedFiscalYear]);

  const alertMessage = (message: string) => {
    alert(message);
  };

  const setStar = (condition: boolean) => {
    if (!condition) {
      return null;
    }
    return <span style={{ color: "red" }}>*</span>;
  };

  const handleDisable = (condition: boolean) => {
    if (!condition) {
      return true; // true คือ disable
    }
  };

  const handleRequiredCheck = (reportType: string) => {
    switch (reportType) {
      case "monthly":
        if (!selectedFiscalYear) {
          return "โปรดเลือกปีงบประมาณ";
        }
        if (!selectedMonth) {
          return "โปรดเลือกเดือน";
        }
        break;
      case "yearly":
        if (!selectedFiscalYear) {
          return "โปรดเลือกปีงบประมาณ";
        }
        break;
      default:
        return null;
    }
    return null;
  };

  const handleSelectRadio = (optionSelected: any) => {
    switch (optionSelected) {
      case "daily":
      case "monthly":
      case "yearly":
        return (
          props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType ===
          `${optionSelected}`
        );
      default:
        console.warn("Option Doesn't Exist!!");
        break;
    }
  };

  const handleChangeRadio = (e: any, { value }: { value: string }) => {
    props.setProp(`SapAccountingReportSequence.sapMedExpenseCostCenter.reportType`, value);
  };

  const handleExportButton = () => {
    const requiredMessage = handleRequiredCheck(
      props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType
    );

    if (requiredMessage) {
      alertMessage(requiredMessage);
      return;
    }

    if (!selectedAccountType) {
      alert("โปรดเลือกประเภทบัญชี");
      return;
    }

    props.runSequence({
      sequence: "SapAccountingReport",
      action: "EXPORT_REPORT",
      menu: "รายงานสรุปรายได้ตามศูนย์ต้นทุน",
    });
  };

  return (
    <Form>
      <FormGroup className="ui form" style={{ gap: "1em", padding: "0em 0em 0em 1em" }}>
        <label style={{ display: "flex", alignItems: "center" }}>
          ประเภทรายงาน<span style={{ color: "red" }}>*</span>
        </label>
        <Radio
          label={intl.formatMessage({ id: "ประจำวัน" })}
          checked={handleSelectRadio("daily")}
          onChange={handleChangeRadio}
          value="daily"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <Radio
          label={intl.formatMessage({ id: "จ่ายประจำเดือน" })}
          checked={handleSelectRadio("monthly")}
          onChange={handleChangeRadio}
          value="monthly"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <Radio
          label={intl.formatMessage({ id: "ประจำปี" })}
          checked={handleSelectRadio("yearly")}
          onChange={handleChangeRadio}
          value="yearly"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <label style={{ display: "flex", alignItems: "center", padding: "0em 0em 0em 2em" }}>
          ประเภทบัญชี<span style={{ color: "red" }}>*</span>
        </label>
        <Dropdown
          selection={true}
          placeholder={intl.formatMessage({ id: "เลือกประเภทบัญชี" })}
          options={accountTypeOptions}
          style={{ minWidth: "15em" }}
          onChange={(e: any, data: any) => {
            props.setProp(
              `SapAccountingReportSequence.sapMedExpenseCostCenter.selectedAccountType`,
              data.value
            );
            setSelectedAccountType(true);
          }}
          value={
            props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedAccountType || ""
          }
        ></Dropdown>
        <label style={{ display: "flex", alignItems: "center", padding: "0em 1.5em 0em 0em" }}>
          จุดเก็บเงิน
        </label>
        <Dropdown
          selection={true}
          placeholder={intl.formatMessage({ id: "เลือกจุดเก็บเงิน" })}
          options={stationOptions}
          style={{ minWidth: "15em" }}
          onChange={(e: any, data: any) => {
            props.setProp(
              `SapAccountingReportSequence.sapMedExpenseCostCenter.selectedStation`,
              data.value
            );
          }}
          value={props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedStation || ""}
        ></Dropdown>
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1em", padding: "0em 0em 0em 1em" }}>
        <label style={{ display: "flex", alignItems: "center", padding: "0em 4em 0em 0em" }}>
          สิทธิ
        </label>
        <Dropdown
          selection={true}
          placeholder={intl.formatMessage({ id: "เลือกสิทธิ" })}
          options={coverageOptions}
          style={{ minWidth: "15em" }}
          onChange={(e: any, data: any) => {
            props.setProp(
              `SapAccountingReportSequence.sapMedExpenseCostCenter.selectedCoverage`,
              data.value
            );
          }}
          value={props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedCoverage || ""}
        ></Dropdown>
        <label style={{ display: "flex", alignItems: "center", padding: "0em 1.3em 0em 6.9em" }}>
          ศูนย์ต้นทุน
        </label>
        <Dropdown
          // disabled={true}
          selection={true}
          placeholder="-ALL-"
          options={coseCenterOptions}
          style={{ minWidth: "15em" }}
          onChange={(e: any, data: any) => {
            props.setProp(
              `SapAccountingReportSequence.sapMedExpenseCostCenter.selectedCostCenter`,
              data.value
            );
          }}
          value={
            props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedCostCenter || "ALL"
          }
        ></Dropdown>
        <label style={{ display: "flex", alignItems: "center" }}>
          ปีงบประมาณ
          {setStar(
            props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType === "monthly" ||
              props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType === "yearly"
          )}
        </label>
        <Dropdown
          disabled={handleDisable(
            props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType === "monthly" ||
              props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType === "yearly"
          )}
          selection={true}
          placeholder={CURRENT_YEAR.toString()}
          options={fiscalYearOptions}
          style={{ minWidth: "8em" }}
          onChange={(e: any, data: any) => {
            props.setProp(
              `SapAccountingReportSequence.sapMedExpenseCostCenter.selectedFiscalYear`,
              data.value
            );
            setSelectedFiscalYear(true);
          }}
          value={
            props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedFiscalYear || ""
          }
        ></Dropdown>
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1em", padding: "0em 0em 0em 1em" }}>
        <label style={{ display: "flex", alignItems: "center" }}>
          วันที่นำส่งเงิน
          {setStar(
            props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType === "daily"
          )}
        </label>
        <DateTextBox
          disabled={handleDisable(
            props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType === "daily"
          )}
          onChange={(date: string) => {
            props.setProp(
              `SapAccountingReportSequence.sapMedExpenseCostCenter.sendMoneyDate`,
              date
            );
          }}
          value={props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.sendMoneyDate || ""}
          style={{ width: "11em" }}
        ></DateTextBox>
        <label style={{ display: "flex", alignItems: "center", padding: "0em 3em 0em 11em" }}>
          เดือน
          {setStar(
            props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType === "monthly"
          )}
        </label>
        <Dropdown
          disabled={handleDisable(
            props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType === "monthly"
          )}
          selection={true}
          placeholder={`มกราคม ${CURRENT_YEAR}`}
          options={monthYearOptions}
          style={{ minWidth: "15em" }}
          onChange={(e: any, data: any) => {
            props.setProp(
              `SapAccountingReportSequence.sapMedExpenseCostCenter.selectedMonth`,
              data.value
            );
            setSelectedMonth(true);
          }}
          value={props.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedMonth || ""}
        ></Dropdown>
        <label
          style={{ display: "flex", alignItems: "center", padding: "0em 11.4em 0em 0em" }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handleExportButton}
          paramKey={`SapAccountingReport_EXPORT_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`SapAccountingReport_EXPORT_REPORT`]}
          color="blue"
          style={{ width: "9em" }}
          title="Export"
        />
      </FormGroup>
    </Form>
  );
};

export default CardSapMedExpenseCostCenterFilter;
