import React, { useMemo } from "react";
import { FormGroup, FormField, Button } from "semantic-ui-react";
import { DateTextBox } from "react-lib/apps/common";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import { useIntl } from "react-intl";

// Common
// import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";

// Types
export type SubAssistantsInputProps = {
  onEvent: (e: any) => any;
  // data
  index: number;
  type: string;
  field?: string;
  role?: string;
  hideDateTime?: boolean;
  items: (number | null | Record<string, any>)[];
  isDetail?: boolean;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  // callback
  setSelected?: (index: number, value: any) => any;
  onClick?: (index: number, action: string) => any;
  onUpdated?: (items: any[]) => any;
  isReadOnly?: boolean;
};

const SubAssistantsInput = (props: SubAssistantsInputProps) => {
  const field = useMemo(() => {
    return props.field ? props.field + "_" : "";
  }, [props.field]);

  const handleSelected = (index: number) => (value: any, key: any, data: any) => {
    // let result: any = { ...props.items?.[index], ...value };
    // if (!value?.name_code && data?.full_name_code) {
    //   result = { ...result, id:data.id, name_code: data.full_name_code };
    // }

    // props.setSelected?.(index, result);

    // handleUpdated(index, result);
    props.setSelected?.(index, value);

    handleUpdated(index, value);
  };

  const handleDatetimeChange = (index: number, name: string, value: string) => {
    let result = { ...props.items?.[index] };

    if (result.detail === undefined) {
      result.detail = {};
    }
    result.detail[name] = value;

    props.setSelected?.(index, result);

    handleUpdated(index, result);
  };

  const handleClick = (action: string, index: number) => (e: any) => {
    props.onClick?.(index, action);

    handleUpdated(index, action);
  };

  const handleUpdated = (cIndex: number, value: any) => {
    const items = props.items || [];

    if (value === "add") {
      if (items.every((item: any) => item)) {
        items.push(null);
      } else {
        return;
      }
    } else if (value === "remove") {
      items.splice(cIndex, 1);
    } else {
      items[cIndex] = value;
    }

    props.onUpdated?.([...items]);
  };

  return (
    <>
      {(props.items?.length ? props.items : [null]).map((item: any, cIndex: number) => (
        <>
          <FormGroup key={`assistant-${props.index}-` + cIndex} inline={true}>
            <FormField inline={true} width={10}>
              <SearchBoxWithKey
                // callback
                onEvent={props.onEvent}
                // data
                type={props.type}
                id={`${field}${props.index + 1}_${cIndex + 1}`}
                role={props.role}
                isDetail={props.isDetail}
                defaultText={item?.name_code || item?.full_name_code || ""}
                searchedItemListWithKey={props.searchedItemListWithKey}
                selectedItem={props.isDetail ? item?.id : item}
                setSelected={handleSelected(cIndex)}
                disabled={props.isReadOnly}
              />
            </FormField>
            <FormField inline={true}>
              {cIndex !== 0 && (
                <Button
                  color={"red"}
                  icon="minus"
                  onClick={handleClick("remove", cIndex)}
                  disabled={props.isReadOnly}
                ></Button>
              )}
              <Button
                color={"green"}
                icon="plus"
                onClick={handleClick("add", cIndex)}
                disabled={props.isReadOnly}
                style={{ display: props.hideDateTime ? "" : "none" }}
              ></Button>
            </FormField>
          </FormGroup>
          <FormGroup
            key={`assistant-date-${props.index}-` + cIndex}
            inline={true}
            style={{ display: props.hideDateTime ? "none" : "" }}
          >
            <FormField
              inline={true}
              width={5}
              style={{ margin: "0px 5px 0px 0px", padding: "0px" }}
            >
              <DateTextBox
                style={{ width: "100%" }}
                onChange={(date: any) => {
                  handleDatetimeChange(cIndex, "from_date", date);
                }}
                value={item?.detail?.from_date || ""}
              ></DateTextBox>
            </FormField>
            <FormField
              inline={true}
              width={3}
              style={{ margin: "0px 5px 0px 0px", padding: "0px" }}
            >
              <TimeComboBox
                fluid={true}
                style={{ width: "100%" }}
                defaultValue={item?.detail?.from_time || ""}
                onTextChange={(time) => {
                  handleDatetimeChange(cIndex, "from_time", time);
                }}
              />
            </FormField>
            ถึง
            <FormField inline={true} width={5} style={{ margin: "0px 5px 0px 5px", padding: "0px" }}>
              <DateTextBox
                style={{ width: "100%" }}
                onChange={(date: any) => {
                  handleDatetimeChange(cIndex, "to_date", date);
                }}
                value={item?.detail?.to_date || ""}
              ></DateTextBox>
            </FormField>
            <FormField
              inline={true}
              width={3}
              style={{ margin: "0px", padding: "0px" }}
            >
              <TimeComboBox
                fluid={true}
                style={{ width: "100%" }}
                defaultValue={item?.detail?.to_time || ""}
                onTextChange={(time) => {
                  handleDatetimeChange(cIndex, "to_time", time);
                }}
              />
            </FormField>
          </FormGroup>
        </>
      ))}
    </>
  );
};

SubAssistantsInput.displayName = "SubAssistantsInput";

export default React.memo(SubAssistantsInput);
