import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Input,
  Dropdown,
  TextArea
} from 'semantic-ui-react'

const CardAnesRecord02UX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardAnesRecord02"
        style={{ width: "100%", height: "100%", padding: "10px", margin: "0px 0px 25px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            className="ui form"
            style={{ margin: "20px 0px" }}>
            
            <FormGroup
              style={{marginLeft: "5px",}}>
              <div
                style={{ width: "100%" }}>
                
                <FormField>
                  <label
                    style={{ color: "#0147A3"}}>
                    Anesthetic Technique
                  </label>
                </FormField>
                <FormGroup
                  inline={true}
                  style={{ alignItems: "center" }}>
                  <FormField
                    style={{ height: "100%" }}
                    width={5}>
                    <div
                      style={{ backgroundColor: "#D7E8F1", padding: "10px", width: "100%" }}>
                      
                      <FormGroup
                        inline={true}
                        widths={16}>
                        <FormField
                          style={{ marginLeft: "10px" }}>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsGeneralAnesthesia}
                            label="General anesthesia (GA)"
                            name="IsGeneralAnesthesia"
                            onChange={props.onChangeData}
                            style={{ fontWeight: "bold" }}>
                          </Checkbox>
                        </FormField>
                      </FormGroup>
                      <FormGroup
                        inline={true}
                        style={{ marginLeft: "35px" }}
                        widths={16}>
                        <FormField
                          width={5}>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsBalanced}
                            disabled={props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                            label="Balanced"
                            name="IsBalanced"
                            onChange={props.onChangeData}>
                          </Checkbox>
                        </FormField>
                        <FormField>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsNitrousOxide}
                            disabled={props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                            label="Nitrous Oxide"
                            name="IsNitrousOxide"
                            onChange={props.onChangeData}>
                          </Checkbox>
                        </FormField>
                      </FormGroup>
                      <FormGroup
                        inline={true}
                        style={{ marginLeft: "35px" }}
                        widths={16}>
                        <FormField
                          width={5}>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsInhalation}
                            disabled={props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                            label="Inhalation"
                            name="IsInhalation"
                            onChange={props.onChangeData}>
                          </Checkbox>
                        </FormField>
                        <FormField>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsGeneralAnesthesiaOthers}
                            disabled={props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                            label="Others"
                            name="IsGeneralAnesthesiaOthers"
                            onChange={props.onChangeData}>
                          </Checkbox>
                        </FormField>
                      </FormGroup>
                      <FormGroup
                        inline={true}
                        style={{ marginLeft: "35px", alignItems: "baseline" }}
                        widths={16}>
                        <FormField
                          width={5}>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsTIVA}
                            disabled={props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                            label="TIVA"
                            name="IsTIVA"
                            onChange={props.onChangeData}>
                          </Checkbox>
                        </FormField>
                        <FormField
                          width={10}>
                          <TextArea
                            disabled={props.AnesRecordSequence?.IsGeneralAnesthesiaOthers !== true || props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                            name="IsGeneralAnesthesiaOthersRemark"
                            onChange={props.onChangeData}
                            rows="6"
                            style={{ resize: "none" }}
                            value={props.AnesRecordSequence?.IsGeneralAnesthesiaOthersRemark}>
                          </TextArea>
                        </FormField>
                      </FormGroup>
                    </div>
                  </FormField>
                  <FormField
                    width={5}>
                    <div
                      style={{ backgroundColor: "#D7E8F1", padding: "10px", width: "100%" }}>
                      
                      <FormGroup
                        inline={true}
                        widths={16}>
                        <FormField
                          style={{ marginLeft: "10px" }}>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsRegionalAnesthesia}
                            label="Regional anesthesia (RA)"
                            name="IsRegionalAnesthesia"
                            onChange={props.onChangeData}
                            style={{ fontWeight: "bold" }}>
                          </Checkbox>
                        </FormField>
                      </FormGroup>
                      <FormGroup
                        inline={true}
                        style={{ marginLeft: "35px" }}
                        widths={16}>
                        <FormField
                          width={5}>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsSpinalBlock}
                            disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                            label="Spinal"
                            name="IsSpinalBlock"
                            onChange={props.onChangeData}>
                          </Checkbox>
                        </FormField>
                        <FormField>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsBrachialPlexusBlock}
                            disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                            label="Brachial plexus"
                            name="IsBrachialPlexusBlock"
                            onChange={props.onChangeData}>
                          </Checkbox>
                        </FormField>
                      </FormGroup>
                      <FormGroup
                        inline={true}
                        style={{ marginLeft: "35px" }}
                        widths={16}>
                        <FormField
                          width={5}>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsEpiduralBlock}
                            disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                            label="Epidural"
                            name="IsEpiduralBlock"
                            onChange={props.onChangeData}>
                          </Checkbox>
                        </FormField>
                        <FormField>
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsRegionalAnesthesiaOthers}
                            disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                            label="Others"
                            name="IsRegionalAnesthesiaOthers"
                            onChange={props.onChangeData}>
                          </Checkbox>
                        </FormField>
                      </FormGroup>
                      <FormGroup
                        style={{ marginLeft: "35px", alignItems: "baseline" }}>
                        <FormField
                          width={5}>
                          <div>
                            
                            <Checkbox
                              checked={props.AnesRecordSequence?.IsCombinedSpinalAndEpiduralBlock}
                              disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                              label="CSE"
                              name="IsCombinedSpinalAndEpiduralBlock"
                              onChange={props.onChangeData}
                              style={{ width: "100%" }}>
                            </Checkbox>
                            <Checkbox
                              checked={props.AnesRecordSequence?.IsCaudalblock}
                              disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                              label="Caudal"
                              name="IsCaudalblock"
                              onChange={props.onChangeData}
                              style={{ width: "100%", marginTop: "15px" }}>
                            </Checkbox>
                          </div>
                        </FormField>
                        <FormField
                          width="10">
                          <TextArea
                            disabled={props.AnesRecordSequence?.IsRegionalAnesthesiaOthers !== true || props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                            name="IsRegionalAnesthesiaOthersRemark"
                            onChange={props.onChangeData}
                            rows="6"
                            style={{ resize: "none" }}
                            value={props.AnesRecordSequence?.IsRegionalAnesthesiaOthersRemark}>
                          </TextArea>
                        </FormField>
                      </FormGroup>
                    </div>
                  </FormField>
                  <FormField
                    width={6}>
                    <div
                      style={{ width: "100%" }}>
                      
                      <div
                        style={{ backgroundColor: "#D7E8F1", padding: "10px", width: "100%" }}>
                        
                        <div
                          style={{ marginLeft: "10px" }}>
                          
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsMAC}
                            label="Monitored anesthesia care (MAC)"
                            name="IsMAC"
                            onChange={props.onChangeData}
                            style={{ fontWeight: "bold" }}>
                          </Checkbox>
                        </div>
                        <div
                          style={{ margin: "5px 10px" }}>
                          
                          <TextArea
                            disabled={props.AnesRecordSequence?.IsMAC !== true}
                            name="IsMACRemark"
                            onChange={props.onChangeData}
                            style={{ resize: "none" }}
                            value={props.AnesRecordSequence?.IsMACRemark}>
                          </TextArea>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "10px", backgroundColor: "#D7E8F1", padding: "10px", width: "100%" }}>
                        
                        <div
                          style={{ marginLeft: "10px" }}>
                          
                          <Checkbox
                            checked={props.AnesRecordSequence?.IsCombined}
                            label="Combined GA & RA"
                            name="IsCombined"
                            onChange={props.onChangeData}
                            style={{ fontWeight: "bold" }}>
                          </Checkbox>
                        </div>
                        <div
                          style={{ display: "flex", margin: "5px 10px" }}>
                          
                          <div
                            style={{marginRight: "1rem"}}>
                            Specify
                          </div>
                          <TextArea
                            disabled={props.AnesRecordSequence?.IsCombined !== true}
                            name="IsCombinedRemark"
                            onChange={props.onChangeData}
                            style={{ resize: "none" }}
                            value={props.AnesRecordSequence?.IsCombinedRemark}>
                          </TextArea>
                        </div>
                      </div>
                    </div>
                  </FormField>
                </FormGroup>
              </div>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "none" }}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Preinduction assessment
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{ display: "flex",alignItems:"center", width: "100%" }}>
                  
                  <Checkbox
                    checked={props.AnesRecordSequence?.IsIdentified}
                    label="Identified"
                    name="IsIdentified"
                    onChange={props.onChangeData}>
                  </Checkbox>
                  <Checkbox
                    checked={props.AnesRecordSequence?.IsConsentPage}
                    label="Consent Page"
                    name="IsConsentPage"
                    onChange={props.onChangeData}
                    style={{ margin: "0px 20px"}}>
                  </Checkbox>
                  <Input
                    name="ConsentPageRemark"
                    onChange={props.onChangeData}
                    value={props.AnesRecordSequence?.ConsentPageRemark}>
                  </Input>
                </div>
              </FormField>
              <FormField
                inline={true}>
              </FormField>
              <FormField
                inline={true}>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "none" }}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  NPO
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{ display: "flex",alignItems:"center", width: "100%" }}>
                  
                  <Input
                    name="NPO"
                    onChange={props.onChangeData}
                    style={{ width: "55%"}}
                    value={props.AnesRecordSequence?.NPO}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  ASA-PS
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{ display: "flex",alignItems:"center", width: "100%" }}>
                  
                  <div>
                    
                    <Dropdown
                      clearable={true}
                      name="ASAPS"
                      onChange={props.onChangeData}
                      options={props.masterOptions?.anes_asa_ps}
                      selection={true}
                      value={props.AnesRecordSequence?.ASAPS}>
                    </Dropdown>
                  </div>
                  <Checkbox
                    checked={props.AnesRecordSequence?.IsEmergency}
                    label="Emergency"
                    name="IsEmergency"
                    onChange={props.onChangeData}
                    style={{ margin: "0px 20px"}}>
                  </Checkbox>
                </div>
              </FormField>
            </FormGroup>
            <div
              style={{ marginTop: "50px"}}>
              {props.premedoication}
            </div>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Status
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{ width: "55%"}}>
                  
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    name="Status"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.ansrec_preind_status}
                    selection={true}
                    value={props.AnesRecordSequence?.Status}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Problems
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <Input
                  name="Problems"
                  onChange={props.onChangeData}
                  style={{ width: "55%"}}
                  value={props.AnesRecordSequence?.Problems}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ marginTop: "50px"}}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Position
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{ width: "55%"}}>
                  
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    name="Position"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.ansrec_position}
                    selection={true}
                    value={props.AnesRecordSequence?.Position}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{ marginLeft: "5px"}}>
                  Monitors
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{ width: "55%"}}>
                  
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    multiple={true}
                    name="Monitors"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.ansrec_monitors}
                    search={true}
                    selection={true}
                    value={props.AnesRecordSequence?.Monitors}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{ marginLeft: "5px"}}>
                  Other
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{ width: "55%"}}>
                  
                  <TextArea
                    name="MonitorsRemark"
                    onChange={props.onChangeData}
                    value={props.AnesRecordSequence?.MonitorsRemark}>
                  </TextArea>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Emergence
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{ width: "55%"}}>
                  
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    name="Emergency"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.ansrec_emergence}
                    selection={true}
                    value={props.AnesRecordSequence?.Emergency}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{ marginLeft: "5px"}}>
                  Before extubation
                </label>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsVerbalCommand}
                  label="Verbal command"
                  name="IsVerbalCommand"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsEffecttiveCough}
                  label="Effecttive cough"
                  name="IsEffecttiveCough"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsSwallowingGag}
                  label="Swallowing gag"
                  name="IsSwallowingGag"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
                <Checkbox
                  checked={props.AnesRecordSequence?.AdequateSpontaneousVentilation}
                  label="Adequate spontaneous ventilation"
                  name="AdequateSpontaneousVentilation"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsHandGripHeadLift}
                  label="Hand grip / Head lift"
                  name="IsHandGripHeadLift"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsStableHemodynamic}
                  label="Stable hemodynamic"
                  name="IsStableHemodynamic"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  After extubation
                </label>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsNoneAfterExtubation}
                  label="None"
                  name="IsNoneAfterExtubation"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsNoneAfterExtubation !== true ? props.AnesRecordSequence?.UpperAirwayObstruction : false}
                  disabled={props.AnesRecordSequence?.IsNoneAfterExtubation === true}
                  label="Upper airway obstruction"
                  name="UpperAirwayObstruction"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsNoneAfterExtubation !== true ? props.AnesRecordSequence?.Cyanosis : false}
                  disabled={props.AnesRecordSequence?.IsNoneAfterExtubation === true}
                  label="Cyanosis"
                  name="Cyanosis"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsNoneAfterExtubation !== true ? props.AnesRecordSequence?.Wheezing : false}
                  disabled={props.AnesRecordSequence?.IsNoneAfterExtubation === true}
                  label="Wheezing"
                  name="Wheezing"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsNoneAfterExtubation !== true ? props.AnesRecordSequence?.Dyspnea : false}
                  disabled={props.AnesRecordSequence?.IsNoneAfterExtubation === true}
                  label="Dyspnea"
                  name="Dyspnea"
                  onChange={props.onChangeData}
                  style={{ marginRight: "1rem"}}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "none" }}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Technique
                </label>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <div
                  className="ui form"
                  style={{ width: "100%" }}>
                  
                  <FormGroup
                    inline={true}>
                    <FormField
                      inline={true}
                      width={3}>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsGeneralAnesthesia}
                        label="General anesthesia"
                        name="IsGeneralAnesthesia"
                        onChange={props.onChangeData}>
                      </Checkbox>
                    </FormField>
                    <FormField
                      inline={true}
                      width={13}>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsGeneralAnesthesia === true ? props.AnesRecordSequence?.IsUnderMask :  false}
                        disabled={props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                        label="Under mask"
                        name="IsUnderMask"
                        onChange={props.onChangeData}
                        style={{ marginRight: "20px"}}>
                      </Checkbox>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsGeneralAnesthesia === true ? props.AnesRecordSequence?.IsLMA : false}
                        disabled={props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                        label="LMA"
                        name="IsLMA"
                        onChange={props.onChangeData}
                        style={{ marginRight: "20px"}}>
                      </Checkbox>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsGeneralAnesthesia === true ? props.AnesRecordSequence?.IsEndotrachealTube : false}
                        disabled={props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                        label="Endotracheal tube"
                        name="IsEndotrachealTube"
                        onChange={props.onChangeData}
                        style={{ marginRight: "20px"}}>
                      </Checkbox>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsGeneralAnesthesia === true ? props.AnesRecordSequence?.IsTracheostomyTube : false}
                        disabled={props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                        label="Tracheostomy tube"
                        name="IsTracheostomyTube"
                        onChange={props.onChangeData}
                        style={{ marginRight: "20px"}}>
                      </Checkbox>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsGeneralAnesthesia === true ? props.AnesRecordSequence?.IsTIVA : false}
                        disabled={props.AnesRecordSequence?.IsGeneralAnesthesia !== true}
                        label="TIVA"
                        name="IsTIVA"
                        onChange={props.onChangeData}
                        style={{ marginRight: "20px"}}>
                      </Checkbox>
                    </FormField>
                  </FormGroup>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "none" }}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <div
                  className="ui form"
                  style={{ width: "100%" }}>
                  
                  <FormGroup
                    inline={true}>
                    <FormField
                      inline={true}
                      width={3}>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsRegionalAnesthesia}
                        label="Regional anesthesia"
                        name="IsRegionalAnesthesia"
                        onChange={props.onChangeData}>
                      </Checkbox>
                    </FormField>
                    <FormField
                      inline={true}
                      width={13}>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsRegionalAnesthesia === true ?  props.AnesRecordSequence?.IsSpinalBlock : false}
                        disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                        label="Spinal block"
                        name="IsSpinalBlock"
                        onChange={props.onChangeData}
                        style={{ marginRight: "20px"}}>
                      </Checkbox>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsRegionalAnesthesia === true ?  props.AnesRecordSequence?.IsEpiduralBlock : false}
                        disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                        label="Epidural block"
                        name="IsEpiduralBlock"
                        onChange={props.onChangeData}
                        style={{ marginRight: "20px"}}>
                      </Checkbox>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsRegionalAnesthesia === true ?   props.AnesRecordSequence?.IsCombinedSpinalAndEpiduralBlock : false}
                        disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                        label="Combined spinal and epidural block"
                        name="IsCombinedSpinalAndEpiduralBlock"
                        onChange={props.onChangeData}
                        style={{ marginRight: "20px"}}>
                      </Checkbox>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsRegionalAnesthesia === true ?  props.AnesRecordSequence?.IsCaudalblock : false}
                        disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                        label="Caudal block"
                        name="IsCaudalblock"
                        onChange={props.onChangeData}
                        style={{ marginRight: "20px"}}>
                      </Checkbox>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsRegionalAnesthesia === true ?   props.AnesRecordSequence?.IsBrachialPlexusBlock : false}
                        disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                        label="Brachial plexus block"
                        name="IsBrachialPlexusBlock"
                        onChange={props.onChangeData}
                        style={{ marginRight: "20px"}}>
                      </Checkbox>
                    </FormField>
                  </FormGroup>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "none" }}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <div
                  className="ui form"
                  style={{ width: "100%" }}>
                  
                  <FormGroup
                    inline={true}>
                    <FormField
                      inline={true}
                      width={3}>
                    </FormField>
                    <FormField
                      inline={true}
                      width={13}>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsRegionalAnesthesia === true ?  props.AnesRecordSequence?.IsOthers : false}
                        disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                        label="Others"
                        name="IsOthers"
                        onChange={props.onChangeData}>
                      </Checkbox>
                      <Input
                        disabled={props.AnesRecordSequence?.IsRegionalAnesthesia !== true}
                        name="IsOthersRemark"
                        onChange={props.onChangeData}
                        style={{ width: "40%"}}
                        value={props.AnesRecordSequence?.IsRegionalAnesthesia === true  && props.AnesRecordSequence?.IsOthers === true ? props.AnesRecordSequence?.IsOthersRemark :  ""}>
                      </Input>
                    </FormField>
                  </FormGroup>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "none" }}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  className="ui form"
                  style={{ width: "100%" }}>
                  
                  <FormGroup
                    inline={true}>
                    <FormField
                      inline={true}
                      width={3}>
                      <Checkbox
                        checked={props.AnesRecordSequence?.IsMAC}
                        label="MAC"
                        name="IsMAC"
                        onChange={props.onChangeData}>
                      </Checkbox>
                    </FormField>
                    <FormField
                      inline={true}
                      width={13}>
                    </FormField>
                  </FormGroup>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              className="--override-disabled --grey"
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Approach & Site
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <Input
                  disabled={props.readOnlyGAMAC}
                  name="ApproachAndSite"
                  onChange={props.onChangeData}
                  style={{ width: "55%"}}
                  value={props.AnesRecordSequence?.ApproachAndSite}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              className="--override-disabled --grey"
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Agent & Conc
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <Input
                  disabled={props.readOnlyGAMAC}
                  name="AgentAndConc"
                  onChange={props.onChangeData}
                  style={{ width: "55%"}}
                  value={props.AnesRecordSequence?.AgentAndConc}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              className="--override-disabled --grey"
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Bevel :
                </label>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <div
                  className="ui form"
                  style={{width: "100%"}}>
                  
                  <FormGroup
                    inline={true}>
                    <FormField
                      inline={true}
                      width={8}>
                      <div
                        style={{width: "100%"}}>
                        
                        <Dropdown
                          clearable={true}
                          disabled={props.readOnlyGAMAC}
                          fluid={true}
                          name="Bevel"
                          onChange={props.onChangeData}
                          options={props.masterOptions?.ansrec_bevel}
                          selection={true}
                          style={{ width: "100%"}}
                          value={props.AnesRecordSequence?.Bevel}>
                        </Dropdown>
                      </div>
                      <label
                        style={{marginRight: "1rem",  minWidth: "max-content"}}>
                        Needle No.
                      </label>
                      <Input
                        disabled={props.readOnlyGAMAC}
                        fluid={true}
                        name="NeedleNo"
                        onChange={props.onChangeData}
                        style={{width: "100%"}}
                        value={props.AnesRecordSequence?.NeedleNo}>
                      </Input>
                      <label
                        style={{marginLeft: "1rem"}}>
                        G.
                      </label>
                    </FormField>
                    <FormField
                      inline={true}
                      width={8}>
                      <label>
                        Attempt
                      </label>
                      <Input
                        disabled={props.readOnlyGAMAC}
                        name="Attempts"
                        onChange={props.onChangeData}
                        value={props.AnesRecordSequence?.Attempts}>
                      </Input>
                      <label
                        style={{ margin: "10px",width: "100%", maxWidth: "max-content" }}>
                        Analgesic level
                      </label>
                      <Input
                        disabled={props.readOnlyGAMAC}
                        name="Analgesiclevel"
                        onChange={props.onChangeData}
                        value={props.AnesRecordSequence?.Analgesiclevel}>
                      </Input>
                    </FormField>
                  </FormGroup>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              className="--override-disabled --grey"
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Skin to epidural /Subarahnoid depth
                </label>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <div
                  className="ui form"
                  style={{ width: "100%" }}>
                  
                  <FormGroup
                    inline={true}>
                    <FormField
                      inline={true}
                      width={8}>
                      <Input
                        disabled={props.readOnlyGAMAC}
                        fluid={true}
                        name="SkinToEpiduralSubarahnoidDepth"
                        onChange={props.onChangeData}
                        value={props.AnesRecordSequence?.SkinToEpiduralSubarahnoidDepth}>
                      </Input>
                      <label
                        style={{marginRight: "1rem"}}>
                        cm
                      </label>
                      <label
                        style={{width: "100%", maxWidth: "max-content",marginRight: "1rem"}}>
                        Catheter No.
                      </label>
                      <Input
                        disabled={props.readOnlyGAMAC}
                        fluid={true}
                        name="CatheterNo"
                        onChange={props.onChangeData}
                        value={props.AnesRecordSequence?.CatheterNo}>
                      </Input>
                      <label
                        style={{marginLeft: "1rem"}}>
                        G.
                      </label>
                    </FormField>
                    <FormField
                      inline={true}
                      style={{ display: "flex", justifyContent: "flex-end"}}
                      width={8}>
                      <label
                        style={{width: "100%", maxWidth: "max-content"}}>
                        Catheter length in epidural space
                      </label>
                      <Input
                        disabled={props.readOnlyGAMAC}
                        fluid={true}
                        name="CatheterLengthInEpiduralSpace"
                        onChange={props.onChangeData}
                        value={props.AnesRecordSequence?.CatheterLengthInEpiduralSpace}>
                      </Input>
                    </FormField>
                  </FormGroup>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              className="--override-disabled --grey"
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <Checkbox
                  checked={props.AnesRecordSequence?.IsCombinedGeneralAndRegionalAnesthesia}
                  disabled={props.readOnlyGAMAC}
                  label=" Combined general and regional anesthesia"
                  name="IsCombinedGeneralAndRegionalAnesthesia"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <FormGroup
              className="--override-disabled --grey"
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <label>
                  due to
                </label>
                <div>
                  
                  <Dropdown
                    clearable={true}
                    disabled={props.readOnlyGAMAC}
                    name="DutTo"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.ansrec_cmbi_gl_rl}
                    selection={true}
                    value={props.AnesRecordSequence?.DutTo}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Airway Management
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <Checkbox
                  checked={props.AnesRecordSequence?.AirwayManagementIsNone}
                  label="None"
                  name="AirwayManagementIsNone"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <label>
                  Tube
                </label>
                <Checkbox
                  checked={props.AnesRecordSequence?.AirwayManagementAreaIs2}
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  name="AirwayManagementAreaIs2"
                  onChange={props.onChangeData}
                  style={{ marginRight: "10px", display: "none" }}>
                </Checkbox>
                <div
                  style={{marginRight: "1rem", width: "100%"}}>
                  
                  <Dropdown
                    clearable={true}
                    disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                    fluid={true}
                    name="AirwayManagementAreaDropdown2"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.ansrec_airman_chkb1}
                    selection={true}
                    style={{ marginRight: "10px" , width: "100%"}}
                    value={props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaDropdown2 : ""}>
                  </Dropdown>
                </div>
                <label
                  style={{ marginRight: "10px" }}>
                  No.
                </label>
                <Input
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  fluid={true}
                  name="AirwayManagementAreaNo2"
                  onChange={props.onChangeData}
                  style={{marginRight: "1rem"}}
                  value={props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaNo2 : ""}>
                </Input>
                <label
                  style={{marginRight: "1rem", width: "100%", maxWidth: "max-content"}}>
                  Cuff/non-cuff
                </label>
                <Checkbox
                  checked={props.AnesRecordSequence?.AirwayManagementAreaIs3}
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  name="AirwayManagementAreaIs3"
                  onChange={props.onChangeData}
                  style={{ marginRight: "10px", display: "none" }}>
                </Checkbox>
                <div
                  style={{marginRight: "1rem", width: "100%"}}>
                  
                  <Dropdown
                    clearable={true}
                    disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                    fluid={true}
                    name="AirwayManagementAreaDropdown3"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.ansrec_airman_chkb2}
                    selection={true}
                    style={{ marginRight: "10px", width: "100%" }}
                    value={props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaDropdown3 : ""}>
                  </Dropdown>
                </div>
                <label
                  style={{marginRight: "1rem", width: "100%", maxWidth: "max-content"}}>
                  Depth (cm)
                </label>
                <Input
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  fluid={true}
                  name="AirwayManagementAreaNo3"
                  onChange={props.onChangeData}
                  value={props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaNo3 : ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
              </FormField>
              <FormField
                inline={true}
                width={11}>
                <label
                  style={{marginRight: "1rem", width: "100%", maxWidth: "max-content"}}>
                  Blade
                </label>
                <Checkbox
                  checked={props.AnesRecordSequence?.AirwayManagementAreaIs4}
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  name="AirwayManagementAreaIs4"
                  onChange={props.onChangeData}
                  style={{ marginRight: "10px", display: "none" }}>
                </Checkbox>
                <Dropdown
                  clearable={true}
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  fluid={true}
                  name="AirwayManagementAreaDropdown4"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.ansrec_airman_chkb3}
                  selection={true}
                  style={{marginRight: "1rem", width: "100%"}}
                  value={props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaDropdown4 : ""}>
                </Dropdown>
                <label
                  style={{marginRight: "1rem", width: "100%", maxWidth: "max-content"}}>
                  No. (Blade)
                </label>
                <Input
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  fluid={true}
                  name="AirwayManagementAreaNo4"
                  onChange={props.onChangeData}
                  style={{marginRight: "1rem"}}
                  value={props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaNo4 : ""}>
                </Input>
                <label
                  style={{marginRight: "1rem", width: "100%", maxWidth: "max-content"}}>
                  Oral/Nasal
                </label>
                <Checkbox
                  checked={props.AnesRecordSequence?.AirwayManagementAreaIs5}
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  name="AirwayManagementAreaIs5"
                  onChange={props.onChangeData}
                  style={{ marginRight: "10px", display: "none" }}>
                </Checkbox>
                <Dropdown
                  clearable={true}
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  fluid={true}
                  name="AirwayManagementAreaDropdown5"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.ansrec_airman_chkb5}
                  selection={true}
                  style={{marginRight: "1rem", width: "100%"}}
                  value={props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaDropdown5 : ""}>
                </Dropdown>
                <Checkbox
                  checked={props.AnesRecordSequence?.AirwayManagementAreaIs6}
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  name="AirwayManagementAreaIs6"
                  onChange={props.onChangeData}
                  style={{ marginRight: "10px", display: "none" }}>
                </Checkbox>
                <Dropdown
                  clearable={true}
                  disabled={props.AnesRecordSequence?.AirwayManagementIsNone === true}
                  fluid={true}
                  name="AirwayManagementAreaDropdown6"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.ansrec_airman_chkb5}
                  selection={true}
                  style={{marginRight: "1rem", width: "100%", display: "none"}}
                  value={props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaDropdown6 : ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <label>
                  Attempts
                </label>
                <Input
                  fluid={true}
                  name="AirwayManagementAttempts"
                  onChange={props.onChangeData}
                  style={{marginRight: "1rem", width: "100%"}}
                  value={props.AnesRecordSequence?.AirwayManagementAttempts}>
                </Input>
                <label
                  style={{marginRight: "1rem", width: "100%", maxWidth: "max-content"}}>
                  Laryngoscope view
                </label>
                <Input
                  fluid={true}
                  name="LaryngoscopeView"
                  onChange={props.onChangeData}
                  style={{marginRight: "1rem", width: "100%"}}
                  value={props.AnesRecordSequence?.LaryngoscopeView}>
                </Input>
                <label
                  style={{ marginRight: "10px", width: "100%", maxWidth: "max-content" }}>
                  Intubation time
                </label>
                <Input
                  fluid={true}
                  name="IntubationTime"
                  onChange={props.onChangeData}
                  style={{marginRight: "1rem", width: "100%"}}
                  value={props.AnesRecordSequence?.IntubationTime}>
                </Input>
                <label
                  style={{ marginRight: "10px" }}>
                  sec
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <label
                  style={{marginRight: "1rem", width: "100%", maxWidth: "max-content"}}>
                  Bag-mask ventilation
                </label>
                <Dropdown
                  fluid={true}
                  name="AirwayManagementAreaIs1"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.ansrec_airman_chkb4}
                  selection={true}
                  style={{marginRight: "1rem", width: "100%", maxWidth: "16rem"}}
                  value={props.AnesRecordSequence?.AirwayManagementAreaIs1}>
                </Dropdown>
                <label
                  style={{ marginRight: "10px" }}>
                  Note
                </label>
                <Input
                  fluid={true}
                  name="AirwayManagementAreaNo1"
                  onChange={props.onChangeData}
                  style={{width: "100%"}}
                  value={props.AnesRecordSequence?.AirwayManagementAreaNo1}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
              </FormField>
              <FormField
                inline={true}
                width={13}>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Adjunct
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <Dropdown
                  clearable={true}
                  name="Adjunct"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.ansrec_airman_tecint}
                  selection={true}
                  style={{ width: "55%",}}
                  value={props.AnesRecordSequence?.Adjunct}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Induction :
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <Dropdown
                  clearable={true}
                  name="AirwayManagementInduction"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.ansrec_airman_induct}
                  selection={true}
                  style={{ width: "55%",}}
                  value={props.AnesRecordSequence?.AirwayManagementInduction}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Ventilation :
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <Dropdown
                  clearable={true}
                  name="AirwayManagementVentilation"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.ansrec_airman_ventil}
                  selection={true}
                  style={{ width: "55%",}}
                  value={props.AnesRecordSequence?.AirwayManagementVentilation}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{minWidth: "140px", maxWidth: "140px"}}>
                <label
                  style={{marginLeft: "5px"}}>
                  Transfer to
                </label>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <Input
                  name="TransferTo"
                  onChange={props.onChangeData}
                  style={{ width: "55%",}}
                  value={props.AnesRecordSequence?.TransferTo}>
                </Input>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default CardAnesRecord02UX

export const screenPropsDefault = {}

/* Date Time : Wed Nov 27 2024 11:59:12 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAnesRecord02"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", padding: \"10px\", margin: \"0px 0px 25px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 404,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"20px 0px\" }"
        }
      },
      "seq": 404,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 405,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 406,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 406,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 407,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 407,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 432,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 408,
      "name": "FormField",
      "parent": 405,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 408,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 409,
      "name": "FormField",
      "parent": 405,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 409,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 410,
      "name": "FormField",
      "parent": 405,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 410,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 411,
      "name": "FormField",
      "parent": 405,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 411,
      "void": false
    },
    {
      "from": null,
      "id": 412,
      "name": "label",
      "parent": 408,
      "props": {
        "children": {
          "type": "value",
          "value": "Preinduction assessment"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 412,
      "void": false
    },
    {
      "from": null,
      "id": 413,
      "name": "div",
      "parent": 409,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",alignItems:\"center\", width: \"100%\" }"
        }
      },
      "seq": 413,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 416,
      "name": "Checkbox",
      "parent": 413,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsIdentified"
        },
        "label": {
          "type": "value",
          "value": "Identified"
        },
        "name": {
          "type": "value",
          "value": "IsIdentified"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 416,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 417,
      "name": "Checkbox",
      "parent": 413,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsConsentPage"
        },
        "label": {
          "type": "value",
          "value": "Consent Page"
        },
        "name": {
          "type": "value",
          "value": "IsConsentPage"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 20px\"}"
        }
      },
      "seq": 417,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 418,
      "name": "Input",
      "parent": 413,
      "props": {
        "name": {
          "type": "value",
          "value": "ConsentPageRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.ConsentPageRemark"
        }
      },
      "seq": 418,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 419,
      "name": "FormField",
      "parent": 406,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 419,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 420,
      "name": "FormField",
      "parent": 406,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 420,
      "void": false
    },
    {
      "from": null,
      "id": 421,
      "name": "div",
      "parent": 420,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",alignItems:\"center\", width: \"100%\" }"
        }
      },
      "seq": 421,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 422,
      "name": "Input",
      "parent": 421,
      "props": {
        "name": {
          "type": "value",
          "value": "NPO"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.NPO"
        }
      },
      "seq": 422,
      "void": false
    },
    {
      "from": null,
      "id": 423,
      "name": "label",
      "parent": 419,
      "props": {
        "children": {
          "type": "value",
          "value": "NPO"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 423,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 424,
      "name": "FormField",
      "parent": 407,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 424,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 425,
      "name": "FormField",
      "parent": 407,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 425,
      "void": false
    },
    {
      "from": null,
      "id": 426,
      "name": "label",
      "parent": 424,
      "props": {
        "children": {
          "type": "value",
          "value": "ASA-PS"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 426,
      "void": false
    },
    {
      "from": null,
      "id": 427,
      "name": "div",
      "parent": 425,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",alignItems:\"center\", width: \"100%\" }"
        }
      },
      "seq": 427,
      "void": false
    },
    {
      "from": null,
      "id": 429,
      "name": "div",
      "parent": 427,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 429,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 430,
      "name": "Dropdown",
      "parent": 429,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ASAPS"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.anes_asa_ps"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.ASAPS"
        }
      },
      "seq": 430,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 431,
      "name": "Checkbox",
      "parent": 427,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsEmergency"
        },
        "label": {
          "type": "value",
          "value": "Emergency"
        },
        "name": {
          "type": "value",
          "value": "IsEmergency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 20px\"}"
        }
      },
      "seq": 431,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 433,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 435,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 434,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 436,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 435,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"50px\"}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 437,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 436,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 438,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 437,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 439,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 438,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 440,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 439,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 441,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 440,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 442,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 441,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 443,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 442,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 568,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 443,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 577,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 444,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 578,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 447,
      "name": "FormField",
      "parent": 433,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 447,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 448,
      "name": "FormField",
      "parent": 433,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 448,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 449,
      "name": "FormField",
      "parent": 434,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 449,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 450,
      "name": "FormField",
      "parent": 434,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 450,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 451,
      "name": "FormField",
      "parent": 435,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 451,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 452,
      "name": "FormField",
      "parent": 435,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 452,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 453,
      "name": "FormField",
      "parent": 436,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 453,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 454,
      "name": "FormField",
      "parent": 436,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 454,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 455,
      "name": "FormField",
      "parent": 437,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 455,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 456,
      "name": "FormField",
      "parent": 437,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 456,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 457,
      "name": "FormField",
      "parent": 438,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 457,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 458,
      "name": "FormField",
      "parent": 438,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 458,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 459,
      "name": "FormField",
      "parent": 439,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 459,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 460,
      "name": "FormField",
      "parent": 439,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 460,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 461,
      "name": "FormField",
      "parent": 440,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 461,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 462,
      "name": "FormField",
      "parent": 440,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 462,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 463,
      "name": "FormField",
      "parent": 441,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 463,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 464,
      "name": "FormField",
      "parent": 441,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 464,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 465,
      "name": "FormField",
      "parent": 442,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 465,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 466,
      "name": "FormField",
      "parent": 442,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 466,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 467,
      "name": "FormField",
      "parent": 443,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 467,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 468,
      "name": "FormField",
      "parent": 443,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 468,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 469,
      "name": "FormField",
      "parent": 444,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 469,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 470,
      "name": "FormField",
      "parent": 444,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 470,
      "void": false
    },
    {
      "from": null,
      "id": 472,
      "name": "label",
      "parent": 447,
      "props": {
        "children": {
          "type": "value",
          "value": "Status"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 472,
      "void": false
    },
    {
      "from": null,
      "id": 473,
      "name": "label",
      "parent": 449,
      "props": {
        "children": {
          "type": "value",
          "value": "Problems"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 473,
      "void": false
    },
    {
      "from": null,
      "id": 474,
      "name": "label",
      "parent": 451,
      "props": {
        "children": {
          "type": "value",
          "value": "Position"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 474,
      "void": false
    },
    {
      "from": null,
      "id": 475,
      "name": "label",
      "parent": 453,
      "props": {
        "children": {
          "type": "value",
          "value": "Monitors"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"5px\"}"
        }
      },
      "seq": 475,
      "void": false
    },
    {
      "from": null,
      "id": 476,
      "name": "label",
      "parent": 455,
      "props": {
        "children": {
          "type": "value",
          "value": "Other"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"5px\"}"
        }
      },
      "seq": 476,
      "void": false
    },
    {
      "from": null,
      "id": 477,
      "name": "label",
      "parent": 457,
      "props": {
        "children": {
          "type": "value",
          "value": "Emergence"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 477,
      "void": false
    },
    {
      "from": null,
      "id": 478,
      "name": "label",
      "parent": 459,
      "props": {
        "children": {
          "type": "value",
          "value": "Before extubation"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"5px\"}"
        }
      },
      "seq": 478,
      "void": false
    },
    {
      "from": null,
      "id": 479,
      "name": "label",
      "parent": 461,
      "props": {
        "children": {
          "type": "value",
          "value": "After extubation"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 479,
      "void": false
    },
    {
      "from": null,
      "id": 480,
      "name": "label",
      "parent": 463,
      "props": {
        "children": {
          "type": "value",
          "value": "Technique"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 480,
      "void": false
    },
    {
      "from": null,
      "id": 481,
      "name": "label",
      "parent": 465,
      "props": {
        "children": {
          "type": "value",
          "value": "Approach & Site"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 481,
      "void": false
    },
    {
      "from": null,
      "id": 482,
      "name": "label",
      "parent": 467,
      "props": {
        "children": {
          "type": "value",
          "value": "Agent & Conc"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 482,
      "void": false
    },
    {
      "from": null,
      "id": 484,
      "name": "label",
      "parent": 469,
      "props": {
        "children": {
          "type": "value",
          "value": "Bevel :"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 484,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 486,
      "name": "Input",
      "parent": 450,
      "props": {
        "name": {
          "type": "value",
          "value": "Problems"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onFocus": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.Problems"
        }
      },
      "seq": 486,
      "void": false
    },
    {
      "from": null,
      "id": 487,
      "name": "div",
      "parent": 448,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\"}"
        }
      },
      "seq": 487,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 488,
      "name": "Dropdown",
      "parent": 487,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_preind_status"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.Status"
        }
      },
      "seq": 488,
      "void": false
    },
    {
      "from": null,
      "id": 489,
      "name": "div",
      "parent": 456,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\"}"
        }
      },
      "seq": 489,
      "void": false
    },
    {
      "from": null,
      "id": 490,
      "name": "div",
      "parent": 454,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\"}"
        }
      },
      "seq": 490,
      "void": false
    },
    {
      "from": null,
      "id": 491,
      "name": "div",
      "parent": 452,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\"}"
        }
      },
      "seq": 491,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 492,
      "name": "Dropdown",
      "parent": 490,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Monitors"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_monitors"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.Monitors"
        }
      },
      "seq": 492,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 493,
      "name": "Dropdown",
      "parent": 491,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Position"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_position"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.Position"
        }
      },
      "seq": 493,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 494,
      "name": "TextArea",
      "parent": 489,
      "props": {
        "name": {
          "type": "value",
          "value": "MonitorsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.MonitorsRemark"
        }
      },
      "seq": 494,
      "void": true
    },
    {
      "from": null,
      "id": 495,
      "name": "div",
      "parent": 458,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\"}"
        }
      },
      "seq": 495,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 496,
      "name": "Dropdown",
      "parent": 495,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Emergency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_emergence"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.Emergency"
        }
      },
      "seq": 496,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 497,
      "name": "Checkbox",
      "parent": 460,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsVerbalCommand"
        },
        "label": {
          "type": "value",
          "value": "Verbal command"
        },
        "name": {
          "type": "value",
          "value": "IsVerbalCommand"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 497,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 498,
      "name": "Checkbox",
      "parent": 460,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsEffecttiveCough"
        },
        "label": {
          "type": "value",
          "value": "Effecttive cough"
        },
        "name": {
          "type": "value",
          "value": "IsEffecttiveCough"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 498,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 499,
      "name": "Checkbox",
      "parent": 460,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsSwallowingGag"
        },
        "label": {
          "type": "value",
          "value": "Swallowing gag"
        },
        "name": {
          "type": "value",
          "value": "IsSwallowingGag"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 499,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 500,
      "name": "Checkbox",
      "parent": 460,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AdequateSpontaneousVentilation"
        },
        "label": {
          "type": "value",
          "value": "Adequate spontaneous ventilation"
        },
        "name": {
          "type": "value",
          "value": "AdequateSpontaneousVentilation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 500,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 501,
      "name": "Checkbox",
      "parent": 460,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsHandGripHeadLift"
        },
        "label": {
          "type": "value",
          "value": "Hand grip / Head lift"
        },
        "name": {
          "type": "value",
          "value": "IsHandGripHeadLift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 501,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 502,
      "name": "Checkbox",
      "parent": 460,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsStableHemodynamic"
        },
        "label": {
          "type": "value",
          "value": "Stable hemodynamic"
        },
        "name": {
          "type": "value",
          "value": "IsStableHemodynamic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 502,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 503,
      "name": "Checkbox",
      "parent": 462,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsNoneAfterExtubation"
        },
        "label": {
          "type": "value",
          "value": "None"
        },
        "name": {
          "type": "value",
          "value": "IsNoneAfterExtubation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 503,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 504,
      "name": "Checkbox",
      "parent": 462,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsNoneAfterExtubation !== true ? props.AnesRecordSequence?.UpperAirwayObstruction : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsNoneAfterExtubation === true"
        },
        "label": {
          "type": "value",
          "value": "Upper airway obstruction"
        },
        "name": {
          "type": "value",
          "value": "UpperAirwayObstruction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 504,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 505,
      "name": "Checkbox",
      "parent": 462,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsNoneAfterExtubation !== true ? props.AnesRecordSequence?.Cyanosis : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsNoneAfterExtubation === true"
        },
        "label": {
          "type": "value",
          "value": "Cyanosis"
        },
        "name": {
          "type": "value",
          "value": "Cyanosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 505,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 506,
      "name": "Checkbox",
      "parent": 462,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsNoneAfterExtubation !== true ? props.AnesRecordSequence?.Wheezing : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsNoneAfterExtubation === true"
        },
        "label": {
          "type": "value",
          "value": "Wheezing"
        },
        "name": {
          "type": "value",
          "value": "Wheezing"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 506,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 507,
      "name": "Checkbox",
      "parent": 462,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsNoneAfterExtubation !== true ? props.AnesRecordSequence?.Dyspnea : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsNoneAfterExtubation === true"
        },
        "label": {
          "type": "value",
          "value": "Dyspnea"
        },
        "name": {
          "type": "value",
          "value": "Dyspnea"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"1rem\"}"
        }
      },
      "seq": 507,
      "void": false
    },
    {
      "from": null,
      "id": 508,
      "name": "div",
      "parent": 464,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 508,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 509,
      "name": "FormGroup",
      "parent": 508,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 509,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 513,
      "name": "FormField",
      "parent": 509,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 513,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 516,
      "name": "FormField",
      "parent": 509,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 516,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 518,
      "name": "Checkbox",
      "parent": 513,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia"
        },
        "label": {
          "type": "value",
          "value": "General anesthesia"
        },
        "name": {
          "type": "value",
          "value": "IsGeneralAnesthesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 518,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 519,
      "name": "Checkbox",
      "parent": 516,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia === true ? props.AnesRecordSequence?.IsUnderMask :  false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Under mask"
        },
        "name": {
          "type": "value",
          "value": "IsUnderMask"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 519,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 520,
      "name": "Checkbox",
      "parent": 516,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia === true ? props.AnesRecordSequence?.IsLMA : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "LMA"
        },
        "name": {
          "type": "value",
          "value": "IsLMA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 520,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 521,
      "name": "Checkbox",
      "parent": 516,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia === true ? props.AnesRecordSequence?.IsEndotrachealTube : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Endotracheal tube"
        },
        "name": {
          "type": "value",
          "value": "IsEndotrachealTube"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 521,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 522,
      "name": "Checkbox",
      "parent": 516,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia === true ? props.AnesRecordSequence?.IsTracheostomyTube : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Tracheostomy tube"
        },
        "name": {
          "type": "value",
          "value": "IsTracheostomyTube"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 522,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 523,
      "name": "Checkbox",
      "parent": 516,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia === true ? props.AnesRecordSequence?.IsTIVA : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "TIVA"
        },
        "name": {
          "type": "value",
          "value": "IsTIVA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 523,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 536,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 579,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 537,
      "name": "FormField",
      "parent": 536,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 537,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 538,
      "name": "FormField",
      "parent": 536,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 538,
      "void": false
    },
    {
      "from": null,
      "id": 539,
      "name": "label",
      "parent": 537,
      "props": {
        "children": {
          "type": "value",
          "value": "Skin to epidural /Subarahnoid depth"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 539,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 540,
      "name": "Input",
      "parent": 466,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "name": {
          "type": "value",
          "value": "ApproachAndSite"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.ApproachAndSite"
        }
      },
      "seq": 540,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 541,
      "name": "Input",
      "parent": 468,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "name": {
          "type": "value",
          "value": "AgentAndConc"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AgentAndConc"
        }
      },
      "seq": 541,
      "void": false
    },
    {
      "from": null,
      "id": 542,
      "name": "div",
      "parent": 470,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 542,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 543,
      "name": "FormGroup",
      "parent": 542,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 543,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 544,
      "name": "FormField",
      "parent": 543,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 544,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 545,
      "name": "FormField",
      "parent": 543,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 545,
      "void": false
    },
    {
      "from": null,
      "id": 546,
      "name": "div",
      "parent": 544,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 546,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 547,
      "name": "Dropdown",
      "parent": 546,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Bevel"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_bevel"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.Bevel"
        }
      },
      "seq": 547,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 548,
      "name": "Input",
      "parent": 544,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "NeedleNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.NeedleNo"
        }
      },
      "seq": 549,
      "void": false
    },
    {
      "from": null,
      "id": 549,
      "name": "label",
      "parent": 544,
      "props": {
        "children": {
          "type": "value",
          "value": "Needle No."
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\",  minWidth: \"max-content\"}"
        }
      },
      "seq": 548,
      "void": false
    },
    {
      "from": null,
      "id": 550,
      "name": "label",
      "parent": 544,
      "props": {
        "children": {
          "type": "value",
          "value": "G."
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 550,
      "void": false
    },
    {
      "from": null,
      "id": 551,
      "name": "label",
      "parent": 545,
      "props": {
        "children": {
          "type": "value",
          "value": "Attempt"
        }
      },
      "seq": 551,
      "void": false
    },
    {
      "from": null,
      "id": 552,
      "name": "label",
      "parent": 545,
      "props": {
        "children": {
          "type": "value",
          "value": "Analgesic level"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px\",width: \"100%\", maxWidth: \"max-content\" }"
        }
      },
      "seq": 553,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 553,
      "name": "Input",
      "parent": 545,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "name": {
          "type": "value",
          "value": "Attempts"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.Attempts"
        }
      },
      "seq": 552,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 554,
      "name": "Input",
      "parent": 545,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "name": {
          "type": "value",
          "value": "Analgesiclevel"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.Analgesiclevel"
        }
      },
      "seq": 554,
      "void": false
    },
    {
      "from": null,
      "id": 555,
      "name": "div",
      "parent": 538,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 555,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 556,
      "name": "FormGroup",
      "parent": 555,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 556,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 558,
      "name": "FormField",
      "parent": 556,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 558,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 559,
      "name": "FormField",
      "parent": 556,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\"}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 559,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 561,
      "name": "Input",
      "parent": 558,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "CatheterNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.CatheterNo"
        }
      },
      "seq": 678,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 562,
      "name": "Input",
      "parent": 559,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "CatheterLengthInEpiduralSpace"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.CatheterLengthInEpiduralSpace"
        }
      },
      "seq": 564,
      "void": false
    },
    {
      "from": null,
      "id": 564,
      "name": "label",
      "parent": 559,
      "props": {
        "children": {
          "type": "value",
          "value": "Catheter length in epidural space"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 562,
      "void": false
    },
    {
      "from": null,
      "id": 565,
      "name": "label",
      "parent": 558,
      "props": {
        "children": {
          "type": "value",
          "value": "Catheter No."
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\",marginRight: \"1rem\"}"
        }
      },
      "seq": 566,
      "void": false
    },
    {
      "from": null,
      "id": 566,
      "name": "label",
      "parent": 558,
      "props": {
        "children": {
          "type": "value",
          "value": "G."
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 679,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 567,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 610,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 568,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 611,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 569,
      "name": "FormField",
      "parent": 567,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 569,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 570,
      "name": "FormField",
      "parent": 567,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 570,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 571,
      "name": "FormField",
      "parent": 568,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 571,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 572,
      "name": "FormField",
      "parent": 568,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 572,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 573,
      "name": "Checkbox",
      "parent": 570,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsCombinedGeneralAndRegionalAnesthesia"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "label": {
          "type": "value",
          "value": " Combined general and regional anesthesia"
        },
        "name": {
          "type": "value",
          "value": "IsCombinedGeneralAndRegionalAnesthesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 573,
      "void": false
    },
    {
      "from": null,
      "id": 574,
      "name": "label",
      "parent": 572,
      "props": {
        "children": {
          "type": "value",
          "value": "due to"
        }
      },
      "seq": 574,
      "void": false
    },
    {
      "from": null,
      "id": 575,
      "name": "div",
      "parent": 572,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 575,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 576,
      "name": "Dropdown",
      "parent": 575,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "DutTo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_cmbi_gl_rl"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.DutTo"
        }
      },
      "seq": 576,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 577,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 444,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 578,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 536,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 579,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 567,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 583,
      "name": "FormField",
      "parent": 577,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 583,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 584,
      "name": "FormField",
      "parent": 577,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 584,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 585,
      "name": "FormField",
      "parent": 578,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 585,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 586,
      "name": "FormField",
      "parent": 578,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 586,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 587,
      "name": "FormField",
      "parent": 579,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 587,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 588,
      "name": "FormField",
      "parent": 579,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 588,
      "void": false
    },
    {
      "from": null,
      "id": 589,
      "name": "div",
      "parent": 584,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 589,
      "void": false
    },
    {
      "from": null,
      "id": 590,
      "name": "div",
      "parent": 586,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 590,
      "void": false
    },
    {
      "from": null,
      "id": 591,
      "name": "div",
      "parent": 588,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 591,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 592,
      "name": "FormGroup",
      "parent": 589,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 592,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 593,
      "name": "FormGroup",
      "parent": 590,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 593,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 594,
      "name": "FormGroup",
      "parent": 591,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 594,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 595,
      "name": "FormField",
      "parent": 592,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 595,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 596,
      "name": "FormField",
      "parent": 592,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 596,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 597,
      "name": "FormField",
      "parent": 593,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 597,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 598,
      "name": "FormField",
      "parent": 593,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 598,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 599,
      "name": "FormField",
      "parent": 594,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 599,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 600,
      "name": "FormField",
      "parent": 594,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 600,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 601,
      "name": "Checkbox",
      "parent": 595,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia"
        },
        "label": {
          "type": "value",
          "value": "Regional anesthesia"
        },
        "name": {
          "type": "value",
          "value": "IsRegionalAnesthesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 601,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 602,
      "name": "Checkbox",
      "parent": 596,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia === true ?  props.AnesRecordSequence?.IsSpinalBlock : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Spinal block"
        },
        "name": {
          "type": "value",
          "value": "IsSpinalBlock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 602,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 603,
      "name": "Checkbox",
      "parent": 596,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia === true ?  props.AnesRecordSequence?.IsEpiduralBlock : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Epidural block"
        },
        "name": {
          "type": "value",
          "value": "IsEpiduralBlock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 603,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 604,
      "name": "Checkbox",
      "parent": 596,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia === true ?   props.AnesRecordSequence?.IsCombinedSpinalAndEpiduralBlock : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Combined spinal and epidural block"
        },
        "name": {
          "type": "value",
          "value": "IsCombinedSpinalAndEpiduralBlock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 604,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 605,
      "name": "Checkbox",
      "parent": 596,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia === true ?  props.AnesRecordSequence?.IsCaudalblock : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Caudal block"
        },
        "name": {
          "type": "value",
          "value": "IsCaudalblock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 605,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 606,
      "name": "Checkbox",
      "parent": 596,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia === true ?   props.AnesRecordSequence?.IsBrachialPlexusBlock : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Brachial plexus block"
        },
        "name": {
          "type": "value",
          "value": "IsBrachialPlexusBlock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 606,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 607,
      "name": "Checkbox",
      "parent": 599,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsMAC"
        },
        "label": {
          "type": "value",
          "value": "MAC"
        },
        "name": {
          "type": "value",
          "value": "IsMAC"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 607,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 608,
      "name": "Checkbox",
      "parent": 598,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia === true ?  props.AnesRecordSequence?.IsOthers : false"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Others"
        },
        "name": {
          "type": "value",
          "value": "IsOthers"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 608,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 609,
      "name": "Input",
      "parent": 598,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "name": {
          "type": "value",
          "value": "IsOthersRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"40%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia === true  && props.AnesRecordSequence?.IsOthers === true ? props.AnesRecordSequence?.IsOthersRemark :  \"\""
        }
      },
      "seq": 609,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 610,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 612,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 611,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 613,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 612,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 614,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 613,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 616,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 614,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 615,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 615,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 617,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 616,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 618,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 617,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 619,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 618,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 677,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 619,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 680,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 620,
      "name": "FormField",
      "parent": 610,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 620,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 621,
      "name": "FormField",
      "parent": 610,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 621,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 622,
      "name": "FormField",
      "parent": 611,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 622,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 623,
      "name": "FormField",
      "parent": 611,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 623,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 624,
      "name": "FormField",
      "parent": 612,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 624,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 625,
      "name": "FormField",
      "parent": 612,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 625,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 626,
      "name": "FormField",
      "parent": 613,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 626,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 627,
      "name": "FormField",
      "parent": 613,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 627,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 628,
      "name": "FormField",
      "parent": 618,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 628,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 629,
      "name": "FormField",
      "parent": 618,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 629,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 630,
      "name": "FormField",
      "parent": 619,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 630,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 631,
      "name": "FormField",
      "parent": 619,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 631,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 632,
      "name": "FormField",
      "parent": 617,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 632,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 633,
      "name": "FormField",
      "parent": 617,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 633,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 634,
      "name": "FormField",
      "parent": 616,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 634,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 635,
      "name": "FormField",
      "parent": 616,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 635,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 636,
      "name": "FormField",
      "parent": 615,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 636,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 637,
      "name": "FormField",
      "parent": 615,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 637,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 638,
      "name": "FormField",
      "parent": 614,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\", maxWidth: \"140px\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 638,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 639,
      "name": "FormField",
      "parent": 614,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 639,
      "void": false
    },
    {
      "from": null,
      "id": 640,
      "name": "label",
      "parent": 630,
      "props": {
        "children": {
          "type": "value",
          "value": "Transfer to"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 640,
      "void": false
    },
    {
      "from": null,
      "id": 641,
      "name": "label",
      "parent": 628,
      "props": {
        "children": {
          "type": "value",
          "value": "Ventilation :"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 641,
      "void": false
    },
    {
      "from": null,
      "id": 642,
      "name": "label",
      "parent": 632,
      "props": {
        "children": {
          "type": "value",
          "value": "Induction :"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 642,
      "void": false
    },
    {
      "from": null,
      "id": 643,
      "name": "label",
      "parent": 634,
      "props": {
        "children": {
          "type": "value",
          "value": "Adjunct"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 643,
      "void": false
    },
    {
      "from": null,
      "id": 644,
      "name": "label",
      "parent": 620,
      "props": {
        "children": {
          "type": "value",
          "value": "Airway Management"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 644,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 645,
      "name": "Checkbox",
      "parent": 621,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone"
        },
        "label": {
          "type": "value",
          "value": "None"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementIsNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 645,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 646,
      "name": "Checkbox",
      "parent": 623,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementAreaIs2"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaIs2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", display: \"none\" }"
        }
      },
      "seq": 647,
      "void": false
    },
    {
      "from": null,
      "id": 647,
      "name": "div",
      "parent": 623,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\"}"
        }
      },
      "seq": 649,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 648,
      "name": "Dropdown",
      "parent": 647,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaDropdown2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_airman_chkb1"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" , width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaDropdown2 : \"\""
        }
      },
      "seq": 648,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 649,
      "name": "Checkbox",
      "parent": 623,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementAreaIs3"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaIs3"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", display: \"none\" }"
        }
      },
      "seq": 654,
      "void": false
    },
    {
      "from": null,
      "id": 650,
      "name": "div",
      "parent": 623,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\"}"
        }
      },
      "seq": 655,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 651,
      "name": "Dropdown",
      "parent": 650,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaDropdown3"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_airman_chkb2"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaDropdown3 : \"\""
        }
      },
      "seq": 651,
      "void": false
    },
    {
      "from": null,
      "id": 652,
      "name": "label",
      "parent": 623,
      "props": {
        "children": {
          "type": "value",
          "value": "No."
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 650,
      "void": false
    },
    {
      "from": null,
      "id": 653,
      "name": "label",
      "parent": 623,
      "props": {
        "children": {
          "type": "value",
          "value": "Depth (cm)"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 746,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 654,
      "name": "Input",
      "parent": 623,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaNo2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaNo2 : \"\""
        }
      },
      "seq": 652,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 655,
      "name": "Input",
      "parent": 623,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaNo3"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaNo3 : \"\""
        }
      },
      "seq": 747,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 656,
      "name": "Checkbox",
      "parent": 625,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementAreaIs4"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaIs4"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", display: \"none\" }"
        }
      },
      "seq": 657,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 657,
      "name": "Checkbox",
      "parent": 625,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementAreaIs5"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaIs5"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", display: \"none\" }"
        }
      },
      "seq": 748,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 658,
      "name": "Checkbox",
      "parent": 625,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementAreaIs6"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaIs6"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", display: \"none\" }"
        }
      },
      "seq": 750,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 659,
      "name": "Dropdown",
      "parent": 625,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaDropdown4"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_airman_chkb3"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaDropdown4 : \"\""
        }
      },
      "seq": 658,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 660,
      "name": "Dropdown",
      "parent": 625,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaDropdown6"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_airman_chkb5"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\", display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaDropdown6 : \"\""
        }
      },
      "seq": 751,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 661,
      "name": "Dropdown",
      "parent": 625,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaDropdown5"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_airman_chkb5"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaDropdown5 : \"\""
        }
      },
      "seq": 749,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 662,
      "name": "Dropdown",
      "parent": 627,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaIs1"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_airman_chkb4"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\", maxWidth: \"16rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementAreaIs1"
        }
      },
      "seq": 663,
      "void": false
    },
    {
      "from": null,
      "id": 663,
      "name": "label",
      "parent": 627,
      "props": {
        "children": {
          "type": "value",
          "value": "Note"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 664,
      "void": false
    },
    {
      "from": null,
      "id": 664,
      "name": "label",
      "parent": 639,
      "props": {
        "children": {
          "type": "value",
          "value": "Intubation time"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"100%\", maxWidth: \"max-content\" }"
        }
      },
      "seq": 670,
      "void": false
    },
    {
      "from": null,
      "id": 665,
      "name": "label",
      "parent": 639,
      "props": {
        "children": {
          "type": "value",
          "value": "sec"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 672,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 667,
      "name": "Input",
      "parent": 627,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaNo1"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementAreaNo1"
        }
      },
      "seq": 752,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 668,
      "name": "Input",
      "parent": 639,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "IntubationTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IntubationTime"
        }
      },
      "seq": 671,
      "void": false
    },
    {
      "from": null,
      "id": 669,
      "name": "label",
      "parent": 639,
      "props": {
        "children": {
          "type": "value",
          "value": "Attempts"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 665,
      "void": false
    },
    {
      "from": null,
      "id": 670,
      "name": "label",
      "parent": 639,
      "props": {
        "children": {
          "type": "value",
          "value": "Laryngoscope view"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 668,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 671,
      "name": "Input",
      "parent": 639,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAttempts"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementAttempts"
        }
      },
      "seq": 667,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 672,
      "name": "Input",
      "parent": 639,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "LaryngoscopeView"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.LaryngoscopeView"
        }
      },
      "seq": 669,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 673,
      "name": "Dropdown",
      "parent": 635,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "Adjunct"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_airman_tecint"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\",}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.Adjunct"
        }
      },
      "seq": 673,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 674,
      "name": "Dropdown",
      "parent": 633,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementInduction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_airman_induct"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\",}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementInduction"
        }
      },
      "seq": 674,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 675,
      "name": "Dropdown",
      "parent": 629,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementVentilation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.ansrec_airman_ventil"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\",}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementVentilation"
        }
      },
      "seq": 675,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 676,
      "name": "Input",
      "parent": 631,
      "props": {
        "name": {
          "type": "value",
          "value": "TransferTo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\",}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.TransferTo"
        }
      },
      "seq": 676,
      "void": false
    },
    {
      "from": null,
      "id": 677,
      "name": "div",
      "parent": 404,
      "props": {
        "children": {
          "type": "code",
          "value": "props.premedoication"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"50px\"}"
        }
      },
      "seq": 434,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 678,
      "name": "Input",
      "parent": 558,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnlyGAMAC"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "SkinToEpiduralSubarahnoidDepth"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.SkinToEpiduralSubarahnoidDepth"
        }
      },
      "seq": 561,
      "void": false
    },
    {
      "from": null,
      "id": 679,
      "name": "label",
      "parent": 558,
      "props": {
        "children": {
          "type": "value",
          "value": "cm"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        }
      },
      "seq": 565,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 680,
      "name": "FormGroup",
      "parent": 404,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\",}"
        }
      },
      "seq": 405,
      "void": false
    },
    {
      "from": null,
      "id": 681,
      "name": "div",
      "parent": 680,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 681,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 683,
      "name": "FormField",
      "parent": 681,
      "props": {
      },
      "seq": 683,
      "void": false
    },
    {
      "from": null,
      "id": 684,
      "name": "label",
      "parent": 683,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic Technique"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"#0147A3\"}"
        }
      },
      "seq": 684,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 685,
      "name": "FormGroup",
      "parent": 681,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"center\" }"
        }
      },
      "seq": 685,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 686,
      "name": "FormField",
      "parent": 685,
      "props": {
        "style": {
          "type": "code",
          "value": "{ height: \"100%\" }"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 686,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 687,
      "name": "FormField",
      "parent": 685,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 687,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 688,
      "name": "FormField",
      "parent": 685,
      "props": {
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 688,
      "void": false
    },
    {
      "from": null,
      "id": 689,
      "name": "div",
      "parent": 688,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 689,
      "void": false
    },
    {
      "from": null,
      "id": 690,
      "name": "div",
      "parent": 687,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#D7E8F1\", padding: \"10px\", width: \"100%\" }"
        }
      },
      "seq": 690,
      "void": false
    },
    {
      "from": null,
      "id": 691,
      "name": "div",
      "parent": 686,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#D7E8F1\", padding: \"10px\", width: \"100%\" }"
        }
      },
      "seq": 691,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 692,
      "name": "FormGroup",
      "parent": 691,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 692,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 693,
      "name": "FormGroup",
      "parent": 691,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"35px\" }"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 693,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 694,
      "name": "FormGroup",
      "parent": 691,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"35px\" }"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 694,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 695,
      "name": "FormGroup",
      "parent": 691,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"35px\", alignItems: \"baseline\" }"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 695,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 696,
      "name": "FormField",
      "parent": 695,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 696,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 697,
      "name": "FormField",
      "parent": 695,
      "props": {
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 697,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 698,
      "name": "FormField",
      "parent": 694,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 698,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 699,
      "name": "FormField",
      "parent": 694,
      "props": {
      },
      "seq": 699,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 700,
      "name": "FormField",
      "parent": 693,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 700,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 701,
      "name": "FormField",
      "parent": 693,
      "props": {
      },
      "seq": 701,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 702,
      "name": "FormField",
      "parent": 692,
      "props": {
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\" }"
        }
      },
      "seq": 702,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 703,
      "name": "Checkbox",
      "parent": 696,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsTIVA"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "TIVA"
        },
        "name": {
          "type": "value",
          "value": "IsTIVA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 703,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 704,
      "name": "Checkbox",
      "parent": 699,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesiaOthers"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Others"
        },
        "name": {
          "type": "value",
          "value": "IsGeneralAnesthesiaOthers"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 704,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 705,
      "name": "Checkbox",
      "parent": 698,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsInhalation"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Inhalation"
        },
        "name": {
          "type": "value",
          "value": "IsInhalation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 705,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 706,
      "name": "Checkbox",
      "parent": 701,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsNitrousOxide"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Nitrous Oxide"
        },
        "name": {
          "type": "value",
          "value": "IsNitrousOxide"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 706,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 707,
      "name": "Checkbox",
      "parent": 700,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsBalanced"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Balanced"
        },
        "name": {
          "type": "value",
          "value": "IsBalanced"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 707,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 708,
      "name": "Checkbox",
      "parent": 702,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesia"
        },
        "label": {
          "type": "value",
          "value": "General anesthesia (GA)"
        },
        "name": {
          "type": "value",
          "value": "IsGeneralAnesthesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 708,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 709,
      "name": "TextArea",
      "parent": 697,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesiaOthers !== true || props.AnesRecordSequence?.IsGeneralAnesthesia !== true"
        },
        "name": {
          "type": "value",
          "value": "IsGeneralAnesthesiaOthersRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "value",
          "value": "6"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsGeneralAnesthesiaOthersRemark"
        }
      },
      "seq": 709,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 710,
      "name": "FormGroup",
      "parent": 690,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 710,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 711,
      "name": "FormGroup",
      "parent": 690,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"35px\" }"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 711,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 712,
      "name": "FormGroup",
      "parent": 690,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"35px\" }"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 712,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 713,
      "name": "FormGroup",
      "parent": 690,
      "props": {
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"35px\", alignItems: \"baseline\" }"
        }
      },
      "seq": 713,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 714,
      "name": "FormField",
      "parent": 710,
      "props": {
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\" }"
        }
      },
      "seq": 714,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 715,
      "name": "FormField",
      "parent": 711,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 715,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 716,
      "name": "FormField",
      "parent": 711,
      "props": {
      },
      "seq": 716,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 717,
      "name": "FormField",
      "parent": 712,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 717,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 718,
      "name": "FormField",
      "parent": 712,
      "props": {
      },
      "seq": 718,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 719,
      "name": "FormField",
      "parent": 713,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 719,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 720,
      "name": "FormField",
      "parent": 713,
      "props": {
        "width": {
          "type": "value",
          "value": "10"
        }
      },
      "seq": 720,
      "void": false
    },
    {
      "from": null,
      "id": 721,
      "name": "div",
      "parent": 719,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 721,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 724,
      "name": "Checkbox",
      "parent": 714,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia"
        },
        "label": {
          "type": "value",
          "value": "Regional anesthesia (RA)"
        },
        "name": {
          "type": "value",
          "value": "IsRegionalAnesthesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 724,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 725,
      "name": "Checkbox",
      "parent": 715,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsSpinalBlock"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Spinal"
        },
        "name": {
          "type": "value",
          "value": "IsSpinalBlock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 725,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 726,
      "name": "Checkbox",
      "parent": 716,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsBrachialPlexusBlock"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Brachial plexus"
        },
        "name": {
          "type": "value",
          "value": "IsBrachialPlexusBlock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 726,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 727,
      "name": "Checkbox",
      "parent": 717,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsEpiduralBlock"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Epidural"
        },
        "name": {
          "type": "value",
          "value": "IsEpiduralBlock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 727,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 728,
      "name": "Checkbox",
      "parent": 718,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesiaOthers"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Others"
        },
        "name": {
          "type": "value",
          "value": "IsRegionalAnesthesiaOthers"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 728,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 729,
      "name": "Checkbox",
      "parent": 721,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsCombinedSpinalAndEpiduralBlock"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "CSE"
        },
        "name": {
          "type": "value",
          "value": "IsCombinedSpinalAndEpiduralBlock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 729,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 732,
      "name": "TextArea",
      "parent": 720,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesiaOthers !== true || props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "name": {
          "type": "value",
          "value": "IsRegionalAnesthesiaOthersRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "value",
          "value": "6"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesiaOthersRemark"
        }
      },
      "seq": 732,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 733,
      "name": "Checkbox",
      "parent": 721,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsCaudalblock"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsRegionalAnesthesia !== true"
        },
        "label": {
          "type": "value",
          "value": "Caudal"
        },
        "name": {
          "type": "value",
          "value": "IsCaudalblock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", marginTop: \"15px\" }"
        }
      },
      "seq": 733,
      "void": false
    },
    {
      "from": null,
      "id": 735,
      "name": "div",
      "parent": 689,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#D7E8F1\", padding: \"10px\", width: \"100%\" }"
        }
      },
      "seq": 735,
      "void": false
    },
    {
      "from": null,
      "id": 736,
      "name": "div",
      "parent": 689,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\", backgroundColor: \"#D7E8F1\", padding: \"10px\", width: \"100%\" }"
        }
      },
      "seq": 736,
      "void": false
    },
    {
      "from": null,
      "id": 737,
      "name": "div",
      "parent": 735,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\" }"
        }
      },
      "seq": 737,
      "void": false
    },
    {
      "from": null,
      "id": 738,
      "name": "div",
      "parent": 735,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px 10px\" }"
        }
      },
      "seq": 738,
      "void": false
    },
    {
      "from": null,
      "id": 739,
      "name": "div",
      "parent": 736,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\" }"
        }
      },
      "seq": 739,
      "void": false
    },
    {
      "from": null,
      "id": 740,
      "name": "div",
      "parent": 736,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", margin: \"5px 10px\" }"
        }
      },
      "seq": 740,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 741,
      "name": "Checkbox",
      "parent": 737,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsMAC"
        },
        "label": {
          "type": "value",
          "value": "Monitored anesthesia care (MAC)"
        },
        "name": {
          "type": "value",
          "value": "IsMAC"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 741,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 742,
      "name": "Checkbox",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsCombined"
        },
        "label": {
          "type": "value",
          "value": "Combined GA & RA"
        },
        "name": {
          "type": "value",
          "value": "IsCombined"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 742,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 743,
      "name": "TextArea",
      "parent": 740,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsCombined !== true"
        },
        "name": {
          "type": "value",
          "value": "IsCombinedRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsCombinedRemark"
        }
      },
      "seq": 745,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 744,
      "name": "TextArea",
      "parent": 738,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsMAC !== true"
        },
        "name": {
          "type": "value",
          "value": "IsMACRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.IsMACRemark"
        }
      },
      "seq": 744,
      "void": true
    },
    {
      "from": null,
      "id": 745,
      "name": "div",
      "parent": 740,
      "props": {
        "children": {
          "type": "value",
          "value": "Specify"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        }
      },
      "seq": 743,
      "void": false
    },
    {
      "from": null,
      "id": 746,
      "name": "label",
      "parent": 623,
      "props": {
        "children": {
          "type": "value",
          "value": "Tube"
        }
      },
      "seq": 646,
      "void": false
    },
    {
      "from": null,
      "id": 747,
      "name": "label",
      "parent": 623,
      "props": {
        "children": {
          "type": "value",
          "value": "Cuff/non-cuff"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 653,
      "void": false
    },
    {
      "from": null,
      "id": 748,
      "name": "label",
      "parent": 625,
      "props": {
        "children": {
          "type": "value",
          "value": "Blade"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 656,
      "void": false
    },
    {
      "from": null,
      "id": 749,
      "name": "label",
      "parent": 625,
      "props": {
        "children": {
          "type": "value",
          "value": "No. (Blade)"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 659,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 750,
      "name": "Input",
      "parent": 625,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AirwayManagementAreaNo4"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AirwayManagementIsNone === false ? props.AnesRecordSequence?.AirwayManagementAreaNo4 : \"\""
        }
      },
      "seq": 660,
      "void": false
    },
    {
      "from": null,
      "id": 751,
      "name": "label",
      "parent": 625,
      "props": {
        "children": {
          "type": "value",
          "value": "Oral/Nasal"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 661,
      "void": false
    },
    {
      "from": null,
      "id": 752,
      "name": "label",
      "parent": 627,
      "props": {
        "children": {
          "type": "value",
          "value": "Bag-mask ventilation"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 662,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": true,
  "memo": false,
  "name": "CardAnesRecord02UX",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
