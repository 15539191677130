import React, { useEffect } from "react";
import { Modal } from "semantic-ui-react";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModPlanning from "../common/ModPlanning";
import SearchBox from "react-lib/appcon/common/SearchBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
// ui DPO
import CardDoctorNoteOrderUX from "./CardDoctorNoteOrderUX";
import { useIntl } from "react-intl";

const CARD_DOCTOR_NOTE_OREDER_ID: string = "CardDoctorNoteOrder";

type CardDoctorNoteOrderProps = {
  // function
  onEvent: any;
  setProp: any;

  // permission
  drNoteOrderPermission?: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // seq
  runSequence: any;
  DoctorNoteOrderSequence?: any;

  // options
  drNoteDivisionOptions?: any[];

  // data
  DJANGO?: any;
  hideButtonSave?: boolean;

  // modPlanning
  modPlanningConfig?: any;
  planningData?: any;

  //MedReconcile
  medReconcileCheck?: any;
  divisionType?: any;
  medReconcileIndex?: number;
  django?: any;
  selectedEncounter?: any;
};

const CardDoctorNoteOrderInitial: CardDoctorNoteOrderProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // permission
  drNoteOrderPermission: null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // seq
  runSequence: null,
  DoctorNoteOrderSequence: {},

  // option
  drNoteDivisionOptions: [],

  // data
  DJANGO: {},
  hideButtonSave: false,

  // modPlanning
  modPlanningConfig: {},
  planningData: {},

  //MedReconcile
  medReconcileCheck: null,
  divisionType: null,
  medReconcileIndex: -1,
  django: null,
  selectedEncounter: {},
};

const CardDoctorNoteOrder: React.FC<CardDoctorNoteOrderProps> = (
  props: any
) => {
  // console.log('CardDoctorNoteOrder props: ', props);
  const intl = useIntl();
  useEffect(() => {
    props.runSequence({ sequence: "DoctorNoteOrder", restart: true });

    return () => {
      const clear = () => {
        props.setProp("planningData", {});

        props.runSequence({ sequence: "DoctorNoteOrder", clear: true });
      };

      clear();
    };
  }, []);

  useEffect(() => {
    if (props.doctorNoteOrderEditId) {
      props.runSequence({
        sequence: "DoctorNoteOrder",
        action: "FETCH",
        pk: props.doctorNoteOrderEditId,
      });
    }
  }, [props.doctorNoteOrderEditId]);

  useEffect(() => {
    if (
      props.DoctorNoteOrderSequence?.sequenceIndex &&
      props.modPlanningConfig &&
      !props.modPlanningConfig?.open &&
      Object.keys(props.planningData || {}).length > 0
    ) {
      props.setProp("DoctorNoteOrderSequence.plan_item", props.planningData);
    }
  }, [props.modPlanningConfig, props.DoctorNoteOrderSequence?.sequenceIndex]);

  useEffect(() => {
    if (props.DoctorNoteOrderSequence?.selected_out_perform_div?.id) {
      props.setProp(
        "DoctorNoteOrderSequence.out_perform_div",
        props.DoctorNoteOrderSequence?.selected_out_perform_div?.id
      );
    }
  }, [props.DoctorNoteOrderSequence?.selected_out_perform_div]);

  const handleChangeDoctorNoteOrderData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`DoctorNoteOrderSequence.${data.name}`, data.checked);
    } else {
      props.setProp(`DoctorNoteOrderSequence.${data.name}`, data.value);
    }
  };

  const handleChangeChkCont = async (_event: any, data: any) => {
    await props.setProp(
      "DoctorNoteOrderSequence.chkCont.checked",
      data.checked
    );

    if (!data.checked) {
      props.setProp("DoctorNoteOrderSequence.plan_item", null);
    } else {
      props.onEvent({ message: "handleSetDefaultPlanningData" });

      props.onEvent({
        message: "handleSetModPlanningConfig",
        params: { name: "open", value: false },
      });
    }
  };

  const handleSave = () => {
    if (props.DoctorNoteOrderSequence?.id) {
      props.runSequence({
        sequence: "DoctorNoteOrder",
        action: "EDIT",
        card: CARD_DOCTOR_NOTE_OREDER_ID,
      });
    } else {
      props.runSequence({
        sequence: "DoctorNoteOrder",
        action: "REQUEST",
        card: CARD_DOCTOR_NOTE_OREDER_ID,
      });
    }
  };

  // Mod Planning
  const handleOpenModPlanning = () => {
    // props.onEvent({ message: "handleSetDefaultPlanningData" });

    props.onEvent({
      message: "handleSetModPlanningConfig",
      params: { name: "open", value: true },
    });
  };

  const handleCloseModPlanning = () => {
    props.onEvent({
      message: "handleSetModPlanningConfig",
      params: { name: "open", value: false },
    });
  };

  return (
    <>
      {(props.errorMessage?.[CARD_DOCTOR_NOTE_OREDER_ID] ||
        props.successMessage?.[CARD_DOCTOR_NOTE_OREDER_ID]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_DOCTOR_NOTE_OREDER_ID}`, null);
            props.setProp(`successMessage.${CARD_DOCTOR_NOTE_OREDER_ID}`, null);
          }}
          error={props.errorMessage?.[CARD_DOCTOR_NOTE_OREDER_ID]}
          success={props.successMessage?.[CARD_DOCTOR_NOTE_OREDER_ID]}
          languageUX={props.languageUX}
        />
      )}

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`modPlanningConfig.error`, null);
        }}
        error={props.modPlanningConfig?.error}
        success={null}
        languageUX={props.languageUX}
      />

      <CardDoctorNoteOrderUX
        // function
        onChangeData={handleChangeDoctorNoteOrderData}
        onChangeChkCont={handleChangeChkCont}
        onOpenModPlanning={handleOpenModPlanning}
        onSave={handleSave}
        // permission
        drNoteOrderPermission={props.drNoteOrderPermission}
        roleTypes={props.DJANGO?.user?.role_types || []}
        showContinuousCommand={props.selectedEncounter?.type === "OPD"}
        // seq
        DoctorNoteOrderSequence={props.DoctorNoteOrderSequence}
        hideButtonSave={props.hideButtonSave}
        // component
        divisionSearchBox={
          <div style={{ display: "flex", alignItems: "center" }}>
            <SearchBox
              onEvent={props.onEvent}
              searchedItemList={props.drNoteDivisionOptions}
              selectedItem={props.DoctorNoteOrderSequence?.selected_out_perform_div}
              setSelectedItem={(item: any) => {
                props.setProp("DoctorNoteOrderSequence.selected_out_perform_div", item);
              }}
              type="Division"
              label={intl.formatMessage({ id: "แผนก" })}
              toDisplay={(item: any) => `${item.name_code}`}
              fluid={true}
              style={{ width: "100%" }}
            />
          </div>
        }
        buttonAction={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={CARD_DOCTOR_NOTE_OREDER_ID}
            buttonLoadCheck={props.buttonLoadCheck?.[CARD_DOCTOR_NOTE_OREDER_ID]}
            // config
            color={props.DoctorNoteOrderSequence?.id ? "yellow" : "green"}
            disabled={props.DoctorNoteOrderSequence?.readOnly}
            size="small"
            style={{
              width: "100%",
              minWidth: "max-content",
            }}
            title={props.DoctorNoteOrderSequence?.id ? "EDIT" : "SAVE"}
          />
        }
        languageUX={props.languageUX}
      />

      {/* Planning */}
      <Modal
        closeIcon
        open={props.modPlanningConfig?.open}
        closeOnDimmerClick={true}
        onClose={handleCloseModPlanning}
      >
        <ModPlanning
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          // selected data
          selectedPatient={props.selectedPatient}
          selectedDrug={props.selectedDrug}
          // planning
          modPlanningConfig={props.modPlanningConfig}
          planningData={props.planningData}
          isDoctor={props.django?.user?.role_types?.includes("DOCTOR")}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModMedReconcileAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        divisionType={props.divisionType}
        django={props.django}
        medReconcileCheck={props.medReconcileCheck}
        medReconcileIndex={props.medReconcileIndex}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        selectedEncounter={props.selectedEncounter}
        languageUX={props.languageUX}
      />
    </>
  );
};

CardDoctorNoteOrder.defaultProps = CardDoctorNoteOrderInitial;

export default React.memo(CardDoctorNoteOrder);
