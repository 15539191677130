import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Input,
  TextArea,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardPreOPVisitUX = (props: any) => {
    return(
      <div
        id="CardPreOPVisit"
        style={{ margin: "15px", padding: "20px", backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            Pre-OP Visit
          </label>
          <Label
            style={{ float: "right", display: props.PreOperationSequence?.preOPVisitData?.status ? "" : "none" }}>
            {props.PreOperationSequence?.preOPVisitData?.status}
          </Label>
          <hr>
          </hr>
        </div>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={7}>
              <label
                style={{minWidth: "125px", maxWidth: "125px"}}>
                Date of visit
              </label>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onChangeDate}
                  value={props.PreOperationSequence?.preOPVisitData?.data?.date_of_visit || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label>
                Location
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                name="location"
                onChange={props.onChangeData}
                options={props.masterOptions?.division || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.preOPVisitData?.data?.location || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Checkbox
                checked={props.PreOperationSequence?.preOPVisitData?.data?.has_no_visit_reason || false}
                label="No Visit Reason"
                name="has_no_visit_reason"
                onChange={props.onChangeData}
                style={{width: "100%", maxWidth: "max-content", fontWeight: "bold"}}>
              </Checkbox>
              <Dropdown
                clearable={true}
                disabled={!props.PreOperationSequence?.preOPVisitData?.data?.has_no_visit_reason}
                fluid={true}
                name="no_visit_reason"
                onChange={props.onChangeData}
                options={props.masterOptions?.noVisitReason || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.preOPVisitData?.data?.no_visit_reason || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={11}>
              <label
                style={{minWidth: "125px", maxWidth: "125px"}}>
                History illness
              </label>
              <Input
                name="history_illness"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.preOPVisitData?.data?.history_illness || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{alignItems: "flex-start"}}
              width={11}>
              <label
                style={{minWidth: "125px", maxWidth: "125px"}}>
                Previous Operating
              </label>
              <label
                style={{display: "none"}}>
                {props.PreOperationSequence?.preOPVisitData?.data?.previous_operation || "\u00a0"}
              </label>
              <TextArea
                name="previous_operation"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.preOPVisitData?.data?.previous_operation || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={7}>
              <label
                style={{minWidth: "125px", maxWidth: "125px"}}>
                Status of Conscious
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                name="status_of_consciousness"
                onChange={props.onChangeData}
                options={props.masterOptions?.statusOfConscious || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.preOPVisitData?.data?.status_of_consciousness || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={7}>
              <label
                style={{minWidth: "125px", maxWidth: "125px"}}>
                Psychological Status
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                name="psychological_status"
                onChange={props.onChangeData}
                options={props.masterOptions?.psychologicalStatus || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.preOPVisitData?.data?.psychological_status || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={7}>
              <label
                style={{minWidth: "125px", maxWidth: "125px"}}>
                Respiratory Status
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                name="respiratory_status"
                onChange={props.onChangeData}
                options={props.masterOptions?.respiratoryStatus || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.preOPVisitData?.data?.respiratory_status || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={7}>
              <label
                style={{minWidth: "125px", maxWidth: "125px"}}>
                Limitation
              </label>
              <Dropdown
                allowAdditions={true}
                clearable={true}
                fluid={true}
                name="limitation"
                onAddItem={props.onAddLimitation}
                onChange={props.onChangeData}
                options={props.masterOptions?.limitation || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.preOPVisitData?.data?.limitation || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{alignItems: "baseline"}}
              width={11}>
              <label
                style={{minWidth: "125px", maxWidth: "125px"}}>
                Previous Implant
              </label>
              <TextArea
                name="pre_implant"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.preOPVisitData?.data?.pre_implant || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "125px", maxWidth: "125px"}}>
                Ambulatory case
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.preOPVisitData?.data?.ambulatory_case || false}
                label={props.PreOperationSequence?.preOPVisitData?.data?.ambulatory_case ? "YES" : "NO"}
                name="ambulatory_case"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              style={{...(!props.PreOperationSequence?.preOPVisitData?.data?.ambulatory_case && { display: "none" }) }}>
              <label>
                มีบุคลที่นำผู้ป่วยกลับบ้าน
              </label>
              <Checkbox
                checked={props.PreOperationSequence?.preOPVisitData?.data?.take_home || false}
                label={props.PreOperationSequence?.preOPVisitData?.data?.take_home ? "YES" : "NO"}
                name="take_home"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              style={{ ...(!props.PreOperationSequence?.preOPVisitData?.data?.take_home && { display: "none" })}}
              width={8}>
              <Input
                name="take_home_detail"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.preOPVisitData?.data?.take_home_detail || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              style={{alignItems: "baseline"}}
              width={11}>
              <label
                style={{minWidth: "125px", maxWidth: "125px"}}>
                Remark
              </label>
              <TextArea
                name="remark"
                onChange={props.onChangeData}
                rows={5}
                value={props.PreOperationSequence?.preOPVisitData?.data?.remark || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginTop: "2rem"}}>
            <FormField
              inline={true}
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonSave}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonConfirm}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonUnconfirm}
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardPreOPVisitUX

export const screenPropsDefault = {}

/* Date Time : Thu Dec 12 2024 10:44:41 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreOPVisit"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\", padding: \"20px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre-OP Visit"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.status"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", display: props.PreOperationSequence?.preOPVisitData?.status ? \"\" : \"none\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "Date of visit"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\", maxWidth: \"125px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Dropdown",
      "parent": 72,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "location"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.location || \"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Dropdown",
      "parent": 73,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.PreOperationSequence?.preOPVisitData?.data?.has_no_visit_reason"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "no_visit_reason"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.noVisitReason || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.no_visit_reason || \"\""
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 81,
      "name": "DateTextBox",
      "parent": 80,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.date_of_visit || \"\""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 70,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "label",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "History illness"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\", maxWidth: \"125px\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Input",
      "parent": 82,
      "props": {
        "name": {
          "type": "value",
          "value": "history_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.history_illness || \"\""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 85,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"flex-start\"}"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 86,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 89,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Previous Operating"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\", maxWidth: \"125px\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.previous_operation || \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "Status of Conscious"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\", maxWidth: \"125px\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological Status"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\", maxWidth: \"125px\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory Status"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\", maxWidth: \"125px\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "Limitation"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\", maxWidth: \"125px\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Dropdown",
      "parent": 104,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "limitation"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddLimitation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.limitation || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.limitation || \"\""
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Dropdown",
      "parent": 103,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "respiratory_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.respiratoryStatus || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.respiratory_status || \"\""
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Dropdown",
      "parent": 102,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "psychological_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.psychologicalStatus || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.psychological_status || \"\""
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Dropdown",
      "parent": 101,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status_of_consciousness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.statusOfConscious || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.status_of_consciousness || \"\""
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "label",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": "Previous Implant"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\", maxWidth: \"125px\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.PreOperationSequence?.preOPVisitData?.data?.ambulatory_case && { display: \"none\" }) }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.PreOperationSequence?.preOPVisitData?.data?.take_home && { display: \"none\" })}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "Ambulatory case"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\", maxWidth: \"125px\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "มีบุคลที่นำผู้ป่วยกลับบ้าน"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "Input",
      "parent": 123,
      "props": {
        "name": {
          "type": "value",
          "value": "take_home_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.take_home_detail || \"\""
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "FormField",
      "parent": 129,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\", maxWidth: \"125px\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "TextArea",
      "parent": 130,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.remark || \"\""
        }
      },
      "seq": 132,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 68,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Checkbox",
      "parent": 73,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.has_no_visit_reason || false"
        },
        "label": {
          "type": "value",
          "value": "No Visit Reason"
        },
        "name": {
          "type": "value",
          "value": "has_no_visit_reason"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\", fontWeight: \"bold\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 135,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 137,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "Checkbox",
      "parent": 121,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.ambulatory_case || false"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.ambulatory_case ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "ambulatory_case"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Checkbox",
      "parent": 122,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.take_home || false"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.take_home ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "take_home"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "TextArea",
      "parent": 116,
      "props": {
        "name": {
          "type": "value",
          "value": "pre_implant"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.pre_implant || \"\""
        }
      },
      "seq": 144,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "TextArea",
      "parent": 100,
      "props": {
        "name": {
          "type": "value",
          "value": "previous_operation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.previous_operation || \"\""
        }
      },
      "seq": 145,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardPreOPVisitUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
