export const ResetSequence = {
  AllergySequence: null,
  SetPatientSequence: null,
  SetCoverageSequence: null,
  CreateUpdateEncounterSequence: null,
  TriageSequence: null,
  AssessmentSequence: null,
  SetAppointmentSequence: null,
  SetProgressCycleSequence: null,
  ConsultationListSequence: null,
  PhysicalExamSequence: null,
  TimeTrackingSequence: null,
  OrderCentralLabSequence: null,
  HistoryCentralLabSequence: null,
  OrderTreatmentSequence: null,
  treatmentOrderList: null,
  procedureItems: null,
  secondaryItems: null,
  treatmentTemplateList: null,
  OrderSupplySequence: null,
  SupplyDeliverySequence: null,
  RefillDivisionSequence: null,
  DoctorNoteOrderSequence: null,
  FoodListSequence: null,
  FoodRequestSequence: null,
  DrugSelectSequence: null,
  DrugOrderActionSequence: null,
  SetDoctorFeeSequence: null,
  SetBedSequence: null,
  SetDrugOrderQueueSequence: null,
  DrugOrderHistorySequence: null,
  MedErrorListSequence: null,
  BillPaymentSequence: null,
  errorMessage: null,
  successMessage: null,
  DrugTransferRequestSequence: null,
  DischargeSummarySequence: null,
  InpatientDiagnosisSequence: null,
  wardQueueSequence: null,
  PreAssessmentSequence: null,
  ReAssessmentSequence: null,
  ReportPatientIPDListSequence: null,
  openDischargeReassessment: null,
  ReceiveOrderSequence: null,
  EncounterReceive: null,
  ReturnOrderSequence: null,
  OrderReturnList: null,
  DrugReturnList: null,
  OrderHistory: null,
  OrderReturnLog: null,
  DischaegeNurseSequence: null,
  FormDataDischargePlanning: null,
  FormDataDischargeTeaching: null,
  FormDataDischargeNursing: null,
  ClinicalTermPlanning: null,
  ClinicalTermTeaching: null,
  ClinicalTermNursing: null,
  NursingDiagnosisSequence: null,
  DoctorPendingTaskSequence: null,
  typeOptionsMethod: null,
  typeOptionsRelative: null,
  typeOptionKnowledge: null,
  TreatmentResultSequence: null,
  TreatmentResultList: null,
  treatmentToken: null,
  DrugTransferRequestHistorySequence: null,
  DrugTransferRequestHistoryList: null,
  DrugReturnRequestHistorySequence: null,
  DrugReturnRequestHistoryList: null,
  AppointmentTelepharSequence: null,
  AppointmentTelepharList: null,
  ReturnSupplySequence: null,
  ZoneLogSequence: null,
  ORQueueSequence: null,
  ORCountsSequence: null,
  PreOperationSequence: null,
  ORPostOperationSequence: null,
  ORRequestSequence: null,
  PerioperativeNursingSequence: null,
  ORHistorySequence: null,
  ORCancelListSequence: null,
  ORCaseListSequence: null,
  operatingDateTimeSequence: null,
  ManageORSequence: null,
  MedRequestReturnSequence: null,
  SupplyOrderHistorySequence: null,
  SupplyDeliveryHistorySequence: null,
  MedicalFeeHistorySequence: null,
  InvoicesSummarySequence: null,
  ORServiceRefundSequence: null,
  OPDLabSummarySequence: null,
  RejectOrderSequence: null,
  LabReportSequence: null,
  PreAnestheticSequence: null,
  PACURecordSequence: null,
  ARInvoiceGroupSequence: null,
  PeriDataSheetSequence: null,
  AnesComplicationSequence: null,
  AnesRecordSequence: null,
  AnesBillSequence: null,
  AnesTemplateSequence: null,
  AnesDoctorFeeListSequence: null,
  FinancialAmountSetSequence: null,
  DrugResuscitationSequence: null,
  UnderlyingDiseaseSequence: null,
  DrugDiseaseInteractionSequence: null,
  HealthMemberSequence: null,
  StockManagementSequence: null,
  ReportDrugUseRecordSequence: null,
  StockTransferSequence: null,
  DrugRefillSequence: null,
  ExportEClaimFileSequence: null,
  DownloadSentClaimFileSequence: null,
  DrugOrderPendingList: null,
  DrugOrderTemplateSequence: null,
  DrugCounsellingSequence: null,
  TelePharmacySequence: null,
  ReportPatientListSequence: null,
  SettingPackageSequence: null,
  PackagePurchaseSequence: null,
  ImagingOrderSequence: null,
  ImagingResultSequence: null,
  SettingQueueSequence: null,
  DoctorCertificateSequence: null,
  BillingDepositSequence: null,
  IntraTelepharQueueSequence: null,
  BillingDepositHistoryISequence: null,
  ApplyPolicyPackageSequence: null,
  PersonnelInformationSequence: null,
  AnesthesiaStatReportSequence: null,
  AddCoPhysicianSequence: null,
  SupplyServiceCheckingSequence: null,
  OrderSetTemplateSequence: null,
};
