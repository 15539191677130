import moment from "moment";

import { HeaderLogoWithAddress } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

const FORM_NAME = "FormHomeMed";

// #const getBase64ImageFromURL = (url: string) => {
//   // create an off-screen canvas
//   const canvas = document.createElement("canvas");
//   const ctx = canvas.getContext("2d");

//   // set its dimension to target size
//   canvas.width = 150;
//   canvas.height = 150;

//   const image = new Image();
//   image.src = url;

//   // draw source image into the off-screen canvas:
//   ctx?.drawImage(image, 0, 0);

//   // encode image to data-uri with base64 version of the image
//   return canvas.toDataURL("image/png;base64");
// };

const FormHomeMed = async (props: any) => {
  const headerForm = await HeaderLogoWithAddress({
    font: "THSarabunNew",
    form: FORM_NAME,
    logoHeight: 50,
    maxWidth: 250,
    pageMargins: [15, 190, 0, 50],
    styles: {
      title: {
        alignment: "center",
        bold: true,
        fontSize: 20,
      },
      header: {
        fontSize: 18,
      },
    },
    titleContent: [
      {
        style: "title",
        text: "ใบแสดงรายการยากลับบ้าน",
      },
      {
        stack: [
          {
            table: {
              widths: ["25%", "40%", "*"],
              body: [
                [
                  {
                    text: [
                      {
                        bold: true,
                        style: "header",
                        text: "HN: ",
                      },
                      {
                        style: "header",
                        text: props.selectedPatient?.hn || "",
                      },
                    ],
                  },
                  {
                    text: [
                      {
                        bold: true,
                        style: "header",
                        text: "ชื่อ-สกุล: ",
                      },
                      {
                        style: "header",
                        text: props.selectedPatient?.full_name || "",
                      },
                    ],
                  },
                  {
                    text: [
                      {
                        bold: true,
                        style: "header",
                        text: "วัน/เดือน/ปีเกิด: ",
                      },
                      {
                        style: "header",
                        text: props.selectedPatient?.birthdate || "",
                      },
                    ],
                  },
                ],
                [
                  {
                    text: [
                      {
                        bold: true,
                        style: "header",
                        text: "เพศ: ",
                      },
                      {
                        style: "header",
                        text: props.selectedPatient?.gender_name || "",
                      },
                    ],
                  },
                  {
                    text: [
                      {
                        bold: true,
                        style: "header",
                        text: "อายุ: ",
                      },
                      {
                        style: "header",
                        text: `${props.selectedPatient?.age ?? ""} ปี`,
                      },
                    ],
                  },
                  {
                    text: [
                      {
                        bold: true,
                        style: "header",
                        text: "แพทย์: ",
                      },
                      {
                        style: "header",
                        text: props.selectedDrugOrder?.requested?.name || "-",
                      },
                    ],
                  },
                ],
              ],
            },
            layout: "noBorders",
          },
        ],
      },
      {
        text: [
          {
            bold: true,
            style: "header",
            text: "ข้อมูลการแพ้: ",
          },
          {
            // color: props.selectedPatient?.allergy ? "red" : "black",
            style: "header",
            text: props.adrText,
          },
        ],
      },
    ],
  });

  const { font, images, styles } = headerForm;
  const items: any[] = props?.selectedDrugOrder?.items || [];

  console.log("FormHomeMed", props)

  return {
    defaultStyle: {
      font,
      fontSize: 12,
      lineHeight: 1,
    },
    pageSize: "A4",
    ...headerForm,
    images: {
      ...images,
    },
    // header: {
    //   margin: [40, 20, 20, 20],
    //   stack: [
    //     {
    //       columns: [
    //         !CONFIG.HIDE_COMPANY_LOGO_PRINT
    //           ? {
    //               width: "50%",
    //               stack: [{ image: "logo", width: 150 }],
    //             }
    //           : null,
    //         !CONFIG.HIDE_COMPANY_LOGO_PRINT
    //           ? {
    //               width: "50%",
    //               stack: [
    //                 {
    //                   text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
    //                   style: "addressRightStyle",
    //                 },
    //                 {
    //                   text: [
    //                     {
    //                       text: "ที่อยู่ : ",
    //                       style: "addressRightStyle",
    //                       bold: true,
    //                     },
    //                     {
    //                       text: "34  ถนนอังรีดูนังต์  แขวงวังใหม่  เขตปทุมวัน",
    //                       style: "addressRightStyle",
    //                     },
    //                   ],
    //                 },
    //                 {
    //                   text: "กรุงเทพมหานคร  10330  โทร 0 2218 8635",
    //                   style: "addressRightStyle",
    //                 },
    //               ],
    //             }
    //           : null,
    //       ],
    //       // ...companyLogoForm,
    //     },

    //   ],
    styles: {
      ...styles,
      addressLeftStyle: {
        alignment: "left",
        fontSize: 12,
      },
      addressRightStyle: {
        alignment: "right",
        fontSize: 12,
      },
      drugDetail: {
        fontSize: 14,
      },
      drugHeader: {
        fontSize: 16,
      },
    },
    // },
    content: [
      {
        margin: [20, 0, 10, 10],
        table: {
          dontBreakRows: true,
          widths: ["30%", "*"],
          body: items.map((item) => {
            const label: string = item.label || "";

            return [
              item.drug_image
                ? {
                    alignment: "center",
                    image: item.drug_image,
                    width: 150,
                  }
                : {
                    text: "",
                  },
              {
                stack: [
                  {
                    columns: [
                      {
                        alignment: "left",
                        bold: true,
                        style: "drugHeader",
                        text: item.drug_name_from_format || "",
                        width: "*",
                      },
                      {
                        alignment: "right",
                        bold: true,
                        style: "drugHeader",
                        text: `จำนวน ${item.drug_quantity || ""} ${item.stock_unit_name || ""}`,
                        width: "30%",
                      },
                    ],
                  },
                  {
                    style: "drugHeader",
                    text: label.replace("\n", " ") || "",
                  },
                  {
                    style: "drugDetail",
                    text: item.drug_indication || "",
                  },
                  {
                    style: "drugDetail",
                    text: item.helper_label || "",
                  },
                ],
              },
            ];
          }),
        },
      },
    ],
    footer(currentPage: number, pageCount: number) {
      const pageText = `หน้าที่ ${currentPage.toString()}/${pageCount}`;
      const printDateMM = moment(props?.selectedDrugOrder?.printed?.datetime);
      const printDate = `วันเวลาที่พิมพ์ ${printDateMM.format("DD/MM/YYYY")} [${printDateMM.format(
        "HH:mm"
      )}]`;

      return {
        stack: [
          {
            columns: [
              {
                stack: [
                  {
                    style: "addressLeftStyle",
                    text: printDate,
                  },
                  {
                    style: "addressLeftStyle",
                    text: props?.selectedDrugOrder?.printed?.name || "",
                  },
                ],
                width: "50%",
              },
              {
                stack: [
                  {
                    style: "addressRightStyle",
                    text: pageText,
                  },
                ],
                width: "50%",
              },
            ],
            margin: [40, 0, 20, 0],
          },
        ],
      };
    },
  };
};

export default FormHomeMed;
