import React, { CSSProperties, useState } from "react";
import { Accordion, Icon, Popup, SemanticCOLORS } from "semantic-ui-react";
import "../../css/ExpandingMenuArrow.css";
import { useIntl } from "react-intl";

type ExpandingMenuProps = {
  color?: SemanticCOLORS; // Accordion Color
  padding?: string; // Accordion Padding
  key?: string; // Accordian Key
  name: string; // Title Name
  titleSize?: CSSProperties["fontSize"]; // Title Font Size
  isPopup?: boolean; // Need Popup ?
  popupContent?: JSX.Element | string; // Popup Text
  content?: JSX.Element; // Main Content
  style?: CSSProperties; // Addition Title Style
};

const ExpandingMenu = (props: ExpandingMenuProps) => {
  const intl = useIntl();
  //* Need Expand as Default set 0 - Need Collapse as Default set null
  const [activeIndex, setActiveIndex] = useState<any>(0);

  const defaultPopup = props.isPopup || false;

  // Custom Title Style
  const titleStyle = {
    background: props.color || "rgba(161, 221, 230, 0.49)",
    padding: props.padding || "15px",
    ...props.style
  };

  const titleContent = (
    <div className="title-content">
      <label style={{ fontSize: props.titleSize || "17px" }}>
        {props.name || intl.formatMessage({id: "ทดสอบรายการ"})}
      </label>

      {defaultPopup ? (
        <Popup
          trigger={
            <Icon
              name="info circle"
              size="large"
              color="blue"
              style={{ padding: "0px 0px 0px 10px" }}
            />
          }
          content={props.popupContent || "Test Tooltip"}
          size="large"
          position="bottom left"
        />
      ) : (
        <></>
      )}
    </div>
  );

  const testContent = (
    // <Form style={{ width: "100%" }}>
    //   <FormGroup grouped>
    //     <FormRadio label="One" name="One" value="One" />
    //     <FormRadio label="Two" name="Two" value="Two" />
    //     <FormRadio label="Three" name="Three" value="Three" />
    //   </FormGroup>
    // </Form>
    <div style={{ fontSize: "15px" }}>
      Content Example
    </div>
  );

  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? null : index;

    setActiveIndex(newIndex);
  };

  return (
  <div className="custom-expanding-menu">
    <Accordion
      vertical={true}
      fluid={true}
      panels={[
        {
          key: props.key || "expandingMenu-Panel",
          title: {
            content: titleContent,
            style: titleStyle,
          },
          content: { content: props.content || testContent },
        },
      ]}
      activeIndex={activeIndex}
      onTitleClick={handleClick}
      styled
    />
  </div>
  );
};

export default ExpandingMenu;
