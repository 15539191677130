import CONFIG from "config/config";

// Utils
import moment from "moment";

// Common
import { HeaderLogoWithPublishDetail } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

const FORM_NAME = "FormOperativeSchedule"; // New Name

const SvgCheck = (
  check?: boolean
) => `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
<path d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z"></path>
</svg>`;

const FormOperativeSchedule = async (props: any) => {

  const momentDate = moment();

  let year = parseInt(momentDate.format("YYYY")) + 543;
  let startDate = moment(props.startDate, "DD-MM-YYYY").locale("th").format("DD MMMM YYYY");
  let endDate = moment(props.endDate, "DD-MM-YYYY").locale("th").format("DD MMMM YYYY");

  let labRequestList = props.data.map((item: any) => {
    let estimate_time = item.total_estimate_operation_time;
    let hours = estimate_time / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    let get_time = rhours + ":" + rminutes;
    let total_operation_time = moment(get_time, "hh:mm").format("HH:mm");

    let start_time = item.start_time;
    let start_time_format = moment(start_time, "hh:mm:ss").format("HH:mm");
    //_s from start_time
    let hours_s = start_time_format.split(":")[0]; //Split returns an array
    let minutes_s = start_time_format.split(":")[1];
    let time_s = +hours_s * 60 + +minutes_s;

    let total_time = +estimate_time + +time_s;
    //_t from total_time
    let hours_t = total_time / 60;
    let rhours_t = Math.floor(hours_t);
    let minutes_t = (hours_t - rhours_t) * 60;
    let rminutes_t = Math.round(minutes_t);
    let get_time_t = rhours_t + ":" + rminutes_t;
    let total_endtime = moment(get_time_t, "hh:mm").format("HH:mm");

    let ipd_case = item?.case?.is_ipd_case;
    let admit_order = item?.admit_order;

    let blood_component = item?.blood_request?.blood_component;
    let sp_blood_component = blood_component?.split(" ");

    const teamIndex = item.teams.findIndex((team: any) => team.is_main);
    const team = item.teams[teamIndex];

    const operatingTreatments: Record<string, any>[] =
      team?.pre_operating_order_item?.operating_treatments || [];

    const otherTreatment = team?.pre_operating_order_item?.other_treatment || "";

    const implant = team?.implant || "";

    const operationTreatment = operatingTreatments
      .map((treatment) => `${treatment.name_code}`)
      .join(", ");

    function printBlood(sp_blood_component: any) {
      let quote = "";
      for (let i = 0; i < sp_blood_component?.length; i++) {
        quote += sp_blood_component?.[i] + " " + "(" + sp_blood_component?.[i + 1] + ")" + "\n";
        i = i + 2;
      }
      return quote;
    }

    function printBloodComponent(sp_blood_component: any) {
      let result;
      if (sp_blood_component === undefined) {
        result = "-";
      } else {
        result = printBlood(sp_blood_component);
      }
      return result;
    }

    var operation_summary = item?.pre_operation_summary;

    function printOperaion(operation_summary: any) {
      let input = operation_summary?.split("\n");
      if (input?.length > 2) {
        return operation_summary;
      } else {
        let line = input
          ?.find((item: any) => item) // Get First
          ?.split("1. ")
          ?.filter((item: any) => item) // remove ""
          ?.find((item: any) => item); // Get First
        //   ?.split("-")
        // line[0] = "[" + line?.[0] + "]"
        // line = line.join(" ")

        return line;
      }
    }

    return [
      {
        stack: [
          {
            text: `${item?.start_date}`,
            alignment: "center",
          },
          {
            text: `${item?.operating_room_no}`,
            alignment: "center",
            style: {
              bold: true,
            },
          },
          {
            text: `${moment(item.start_time, "HH:mm:ss").format("HH:mm")} - ${moment(
              item.end_date_time
            ).format("HH:mm")}`,
            alignment: "center",
          },
          {
            text: `${
              item?.estimate_operation_hour > 0 ? `${item?.estimate_operation_hour} ชม.` : ""
            } ${
              item?.estimate_operation_minute > 0 ? `${item?.estimate_operation_minute} นาที` : ""
            }`,
            alignment: "center",
          },
        ],
      },
      {
        stack: [
          {
            columns: [
              {
                width: "*",
                text: `${item?.hn}`,
                style: {
                  bold: true,
                },
              },
              {
                width: 30,
                text: `${item?.typeLabel}`,
                style: {
                  bold: true,
                  alignment: "center",
                },
              },
            ],
          },
          {
            text: `${item?.patient_name}`,
          },
          {
            text: `อายุ ${item?.patient_age} ปี`,
          },
          {
            text: `${
              item?.patient_tel !== null
                ? `${item?.patient_tel?.slice(0, 3)}-${item?.patient_tel?.slice(
                    3,
                    6
                  )}-${item?.patient_tel?.slice(6, 10)}`
                : ""
            }`,
          },
        ],
      },
      {
        stack: [
          {
            text: `${item?.preoperative_diagnosis_summary}`,
          },
          {
            text: `${item?.teams?.[0]?.diagnosis_remark}`,
          },
        ],
      },
      {
        stack: [
          {
            text: [operationTreatment, otherTreatment],
          },
          {
            text: implant,
          },
        ],
      },
      {
        stack: [
          {
            table: {
              widths: ["100%"],
              body: (item?.team_detail || [])?.map((team: any, index: number) => {
                return [
                  {
                    text: team.member,
                    border: [false, !team.is_main || false, false, false],
                  },
                ];
              }),
            },
          },
        ],
      },
      {
        stack: [
          {
            text: `${item?.special_equipments_text}`,
          },
        ],
      },
      {
        stack: [
          {
            text: `${item?.anesthesia_method_name}`,
          },
        ],
      },
      {
        stack: [
          {
            text: `${item?.blood_request?.blood_component || "-"}`,
          },
        ],
      },
      // {
      //   ...(item?.admit_order === null
      //     ? { text: "-", alignment: "center" }
      //     : { svg: SvgCheck(), alignment: "center", width: 10 }),
      // },
      // {
      //   text: [
      //     { text: item?.start_date, style: "fieldTableData" },
      //     { text: "\n", style: "fieldTableData" },
      //     { text: item?.operating_room_no, style: "fieldTable" },
      //     { text: "\n", style: "fieldTableData" },
      //     { text: start_time_format, style: "fieldTableData" },
      //     { text: " - ", style: "fieldTableData" },
      //     { text: total_endtime, style: "fieldTableData" },
      //   ],
      // },
      // {
      //   text: item?.anesthesia_method_name,
      //   style: "fieldTableData",
      // },
      // {
      //   text: [
      //     { text: item?.hn, style: "fieldTableData" },
      //     { text: "\n", style: "fieldTableData" },
      //     { text: item?.patient_name, style: "fieldTableData" },
      //     { text: "\n", style: "fieldTableData" },
      //     { text: "อายุ ", style: "fieldTableData" },
      //     { text: item?.patient_age, style: "fieldTableData" },
      //     { text: " ปี", style: "fieldTableData" },
      //   ],
      // },

      // {
      //   ...(ipd_case && admit_order != null
      //     ? {
      //         image: "checkmark",
      //         width: 13,
      //         alignment: "center",
      //       }
      //     : {
      //         text: "-",
      //         style: "fieldTableData",
      //         alignment: "center",
      //       }),
      // },

      // {
      //   ...(item?.teams[0]?.pre_principal_diagnosis[0]?.icd_code
      //     ? {
      //         text: [
      //           { text: "[", style: "fieldTableData" },
      //           {
      //             text: item?.teams[0]?.pre_principal_diagnosis[0]?.icd_code,
      //             style: "fieldTableData",
      //           },
      //           { text: "]", style: "fieldTableData" },
      //           { text: " ", style: "fieldTableData" },
      //           {
      //             text: item?.teams[0]?.pre_principal_diagnosis[0]?.icd_term,
      //             style: "fieldTableData",
      //           },
      //         ],
      //       }
      //     : {
      //         text: "-",
      //         style: "fieldTableData",
      //       }),
      // },
      // {
      //   ...(item?.pre_operation_summary
      //     ? {
      //         text: printOperaion(operation_summary),
      //         style: "fieldTableData",
      //       }
      //     : {
      //         text: "-",
      //         style: "fieldTableData",
      //       }),
      // },

      // {
      //   ...(item?.teams[0]?.pre_operating_order_item?.procedures[0]?.icd9cm_code
      //     ? {
      //         text: [
      //           { text: "[", style: "fieldTableData" },
      //           {
      //             text: item?.teams[0]?.pre_operating_order_item?.procedures[0]?.icd9cm_code,
      //             style: "fieldTableData",
      //           },
      //           { text: "]", style: "fieldTableData" },
      //           { text: " ", style: "fieldTableData" },
      //           {
      //             text: item?.teams[0]?.pre_operating_order_item?.procedures[0]?.icd9cm_term,
      //             style: "fieldTableData",
      //           },
      //         ],
      //       }
      //     : {
      //         text: "-",
      //         style: "fieldTableData",
      //       }),
      // },
      // {
      //   text: [
      //     { text: item?.primary_doctor_name, style: "fieldTableData" },
      //     { text: "\n", style: "fieldTableData" },
      //     {
      //       text: item?.assistant_primary_doctor_name,
      //       style: "fieldTableData",
      //     },
      //   ],
      //   style: "fieldTableData",
      // },
      // {
      //   text: [
      //     {
      //       text: total_operation_time,
      //       style: "fieldTableData",
      //     },
      //   ],
      // },
      // {
      //   text: printBloodComponent(sp_blood_component),
      //   style: "fieldTableData",
      // },
    ];
  });

  const headerForm = await HeaderLogoWithPublishDetail({
    form: FORM_NAME,
    font: "THSarabunNew",
    logoHeight: 30,
    haveBorder: true,
    isHospitalVertical: true,
    pageMargins: [0, 100, 0, 15],
    headerMargins: [7, 8, 8, 15],
    styles: {
      fieldHead: {
        bold: true,
        fontSize: 20,
      },
      fieldOPHead: {
        bold: true,
        fontSize: 16,
      },
      fieldTable: {
        bold: true,
        fontSize: 16,
      },
      fieldTableData: {
        fontSize: 16,
      },
      fieldPrintDate: {
        fontSize: 16,
      },
      tableHeader: {
        fillColor: "white",
      },
      tableNormal: {
        margin: [0, 5, 0, 5],
      },
      tableExample: {
        margin: [0, 0, 0, 0],
      },
    },
    titleContent: [
      {
        text: `ตารางนัดหมายผ่าตัด (Operative Schuedule)`,
        alignment: "center",
        style: {
          fontSize: 20,
          bold: true,
        },
      },
      {
        text: `วันที่ผ่าตัด ${startDate} ถึง ${endDate}`,
        alignment: "center",
        style: {
          fontSize: 20,
          bold: true,
        },
      },
      {
        text: ` `,
        preserveLeadingSpaces: true,
        alignment: "center",
        style: {
          fontSize: 20,
          bold: true,
        },
      },
    ],
    publishContent: [
      {
        text: [
          { text: "ผู้พิมพ์: ", bold: true, preserveLeadingSpaces: true },
          { text: props.user || " " },
        ],
        alignment: "right",
        fontSize: 12,
      },
      {
        text: [
          { text: "วันที่พิมพ์: ", bold: true, preserveLeadingSpaces: true },
          { text: `${momentDate.format("DD/MM")}/${year} [${momentDate.format("HH:mm")}]` },
        ],
        alignment: "right",
        fontSize: 12,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  return {
    defaultStyle: {
      font: font,
    },
    pageSize: "A4",
    pageOrientation: "landscape",
    ...styles,
    ...headerForm,
    images: {
      ...images,
      queue: origin + "/static/images/menus/queue.png",
      checkmark: origin + "/static/images/checkmark.png",
      //style: "header",
    },

    content: [
      // { stack: [...columns] },
      {
        margin: [7, 0, 8, 0],
        table: {
          headerRows: 1,
          widths: [75, 85, "*", "*", 160, "*", 73, 35],
          body: [
            [
              { text: "Operative \nDate / Room", alignment: "center", style: "fieldTable" },
              { text: "Patient", alignment: "center", style: "fieldTable" },
              { text: "Pre-Op Diagnosis", alignment: "center", style: "fieldTable" },
              { text: "Operation / Implant", alignment: "center", style: "fieldTable" },
              { text: "Surgeon / Assistant + Anes", alignment: "center", style: "fieldTable" },
              { text: "Special Equipment", alignment: "center", style: "fieldTable" },
              { text: "Anesthesia", alignment: "center", style: "fieldTable" },
              { text: "Blood", alignment: "center", style: "fieldTable" },
            ],
            ...labRequestList,
          ],
        },
      },
    ],
  };
};

export default FormOperativeSchedule;

// export const createPDFLabRequest = async (props: any) => {
//   console.log("createPDFLabRequest props", props);

//   const companyLogoForm = await CompanyLogoForm({
//     font: "THSarabunNew",
//     height: 25.25,
//     form: FORM_NAME,
//   });
//   const { images } = companyLogoForm;

//   import("pdfmake/build/pdfmake").then((make) => {
//     import("react-lib/assets/fonts/pdfFonts").then(async (font) => {
//       let pdfMake = make.default;
//       pdfMake.vfs = font.default;
//       pdfMake.fonts = {
//         THSarabunNew: {
//           normal: "THSarabunNew.ttf",
//           bold: "THSarabunNew-Bold.ttf",
//           italics: "THSarabunNew-Italic.ttf",
//           bolditalics: "THSarabunNew-BoldItalic.ttf",
//         },
//         Roboto: {
//           normal: "Roboto-Regular.ttf",
//           bold: "Roboto-Medium.ttf",
//           italics: "Roboto-Italic.ttf",
//           bolditalics: "Roboto-MediumItalic.ttf",
//         },
//       };

//       var origin = window?.location?.origin;

//       const momentDate = moment();

//       let startDate = moment(props.startDate, "DD-MM-YYYY").locale("th").format("DD MMMM YYYY");
//       let endDate = moment(props.endDate, "DD-MM-YYYY").locale("th").format("DD MMMM YYYY");

//       let labRequestList = props.data.map((item: any) => {
//         let estimate_time = item.total_estimate_operation_time;
//         let hours = estimate_time / 60;
//         let rhours = Math.floor(hours);
//         let minutes = (hours - rhours) * 60;
//         let rminutes = Math.round(minutes);
//         let get_time = rhours + ":" + rminutes;
//         let total_operation_time = moment(get_time, "hh:mm").format("HH:mm");

//         let start_time = item.start_time;
//         let start_time_format = moment(start_time, "hh:mm:ss").format("HH:mm");
//         //_s from start_time
//         let hours_s = start_time_format.split(":")[0]; //Split returns an array
//         let minutes_s = start_time_format.split(":")[1];
//         let time_s = +hours_s * 60 + +minutes_s;

//         let total_time = +estimate_time + +time_s;
//         //_t from total_time
//         let hours_t = total_time / 60;
//         let rhours_t = Math.floor(hours_t);
//         let minutes_t = (hours_t - rhours_t) * 60;
//         let rminutes_t = Math.round(minutes_t);
//         let get_time_t = rhours_t + ":" + rminutes_t;
//         let total_endtime = moment(get_time_t, "hh:mm").format("HH:mm");

//         let ipd_case = item?.case?.is_ipd_case;
//         let admit_order = item?.admit_order;

//         let blood_component = item?.blood_request?.blood_component;
//         let sp_blood_component = blood_component?.split(" ");

//         function printBlood(sp_blood_component: any) {
//           let quote = "";
//           for (let i = 0; i < sp_blood_component?.length; i++) {
//             quote += sp_blood_component?.[i] + " " + "(" + sp_blood_component?.[i + 1] + ")" + "\n";
//             i = i + 2;
//           }
//           return quote;
//         }

//         function printBloodComponent(sp_blood_component: any) {
//           let result;
//           if (sp_blood_component === undefined) {
//             result = "-";
//           } else {
//             result = printBlood(sp_blood_component);
//           }
//           return result;
//         }

//         var operation_summary = item?.pre_operation_summary;

//         function printOperaion(operation_summary: any) {
//           let input = operation_summary?.split("\n");
//           if (input?.length > 2) {
//             return operation_summary;
//           } else {
//             let line = input
//               ?.find((item: any) => item) // Get First
//               ?.split("1. ")
//               ?.filter((item: any) => item) // remove ""
//               ?.find((item: any) => item); // Get First
//             //   ?.split("-")
//             // line[0] = "[" + line?.[0] + "]"
//             // line = line.join(" ")

//             return line;
//           }
//         }

//         return [
//           {
//             text: [
//               { text: item?.start_date, style: "fieldTableData" },
//               { text: "\n", style: "fieldTableData" },
//               { text: item?.operating_room_no, style: "fieldTable" },
//               { text: "\n", style: "fieldTableData" },
//               { text: start_time_format, style: "fieldTableData" },
//               { text: " - ", style: "fieldTableData" },
//               { text: total_endtime, style: "fieldTableData" },
//             ],
//           },
//           {
//             text: item?.anesthesia_method_name,
//             style: "fieldTableData",
//           },
//           {
//             text: [
//               { text: item?.hn, style: "fieldTableData" },
//               { text: "\n", style: "fieldTableData" },
//               { text: item?.patient_name, style: "fieldTableData" },
//               { text: "\n", style: "fieldTableData" },
//               { text: "อายุ ", style: "fieldTableData" },
//               { text: item?.patient_age, style: "fieldTableData" },
//               { text: " ปี", style: "fieldTableData" },
//             ],
//           },

//           {
//             ...(ipd_case && admit_order != null
//               ? {
//                   image: "checkmark",
//                   width: 13,
//                   alignment: "center",
//                 }
//               : {
//                   text: "-",
//                   style: "fieldTableData",
//                   alignment: "center",
//                 }),
//           },

//           {
//             ...(item?.teams[0]?.pre_principal_diagnosis[0]?.icd_code
//               ? {
//                   text: [
//                     { text: "[", style: "fieldTableData" },
//                     {
//                       text: item?.teams[0]?.pre_principal_diagnosis[0]?.icd_code,
//                       style: "fieldTableData",
//                     },
//                     { text: "]", style: "fieldTableData" },
//                     { text: " ", style: "fieldTableData" },
//                     {
//                       text: item?.teams[0]?.pre_principal_diagnosis[0]?.icd_term,
//                       style: "fieldTableData",
//                     },
//                   ],
//                 }
//               : {
//                   text: "-",
//                   style: "fieldTableData",
//                 }),
//           },
//           {
//             ...(item?.pre_operation_summary
//               ? {
//                   text: printOperaion(operation_summary),
//                   style: "fieldTableData",
//                 }
//               : {
//                   text: "-",
//                   style: "fieldTableData",
//                 }),
//           },

//           {
//             ...(item?.teams[0]?.pre_operating_order_item?.procedures[0]?.icd9cm_code
//               ? {
//                   text: [
//                     { text: "[", style: "fieldTableData" },
//                     {
//                       text: item?.teams[0]?.pre_operating_order_item?.procedures[0]?.icd9cm_code,
//                       style: "fieldTableData",
//                     },
//                     { text: "]", style: "fieldTableData" },
//                     { text: " ", style: "fieldTableData" },
//                     {
//                       text: item?.teams[0]?.pre_operating_order_item?.procedures[0]?.icd9cm_term,
//                       style: "fieldTableData",
//                     },
//                   ],
//                 }
//               : {
//                   text: "-",
//                   style: "fieldTableData",
//                 }),
//           },
//           {
//             text: [
//               { text: item?.primary_doctor_name, style: "fieldTableData" },
//               { text: "\n", style: "fieldTableData" },
//               {
//                 text: item?.assistant_primary_doctor_name,
//                 style: "fieldTableData",
//               },
//             ],
//             style: "fieldTableData",
//           },
//           {
//             text: [
//               {
//                 text: total_operation_time,
//                 style: "fieldTableData",
//               },
//             ],
//           },
//           {
//             text: printBloodComponent(sp_blood_component),
//             style: "fieldTableData",
//           },
//         ];
//       });

//       var docDefinition = {
//         defaultStyle: {
//           font: "THSarabunNew",
//         },
//         pageMargins: [0, 75, 0, 50],
//         pageSize: "A4",
//         pageOrientation: "landscape",
//         styles: {
//           fieldHead: {
//             bold: true,
//             fontSize: 20,
//           },
//           fieldOPHead: {
//             bold: true,
//             fontSize: 16,
//           },
//           fieldTable: {
//             bold: true,
//             fontSize: 16,
//           },
//           fieldTableData: {
//             fontSize: 16,
//           },
//           fieldPrintDate: {
//             fontSize: 16,
//           },
//           tableHeader: {
//             fillColor: "white",
//           },
//           tableNormal: {
//             margin: [0, 5, 0, 5],
//           },

//           tableExample: {
//             margin: [0, 0, 0, 0],
//           },
//         },
//         header: {
//           margin: [0, 8, 10, 0],

//           stack: [
//             {
//               columns: [
//                 {
//                   image: "logo",
//                   width: 75,
//                   margin: [8, 0, 0, 0],
//                   // absolutePosition: { x: 3, y: 1.2 },
//                 },
//                 {
//                   text: "โรงพยาบาลคณะทันตแพทย์ศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
//                   style: "fieldHead",
//                   margin: [13, 0, 0, 0],
//                 },
//                 {
//                   text: [
//                     { text: "วันที่พิมพ์:", style: "fieldPrintDate" },
//                     { text: ` ${formatDate(momentDate)} `, style: "fieldPrintDate" },
//                     { text: "เวลา:", style: "fieldPrintDate" },
//                     { text: ` ${momentDate.format("HH:mm")} `, style: "fieldPrintDate" },
//                   ],
//                   absolutePosition: { x: 670, y: 11.4 },
//                   //alignment: "right",
//                 },
//               ],
//               lineHeight: 1,
//             },
//             {
//               columns: [
//                 {
//                   canvas: [
//                     {
//                       type: "line",
//                       lineColor: "#E25F9E",
//                       x1: 0,
//                       y1: 5,
//                       x2: 890,
//                       y2: 5,
//                       lineWidth: 2,
//                     },
//                   ],
//                 },
//               ],
//             },
//             {
//               columns: [
//                 {
//                   text: [
//                     {
//                       text: "OPERATIVE SCHEDULE    ",
//                       style: "fieldOPHead",
//                     },
//                     //{ text: "             ", style: "fieldOPHead" },
//                     { text: " ประจำวันที่", style: "fieldPrintDate" },
//                     { text: ` ${startDate} `, style: "fieldPrintDate" },
//                     { text: " - ", style: "fieldPrintDate" },
//                     { text: ` ${endDate} `, style: "fieldPrintDate" },
//                   ],
//                   alignment: "center",
//                   relativePosition: { x: 0, y: 5 },
//                 },
//               ],
//             },
//           ],
//         },

//         images: {
//           ...images,
//           queue: origin + "/static/images/menus/queue.png",
//           checkmark: origin + "/static/images/checkmark.png",
//           //style: "header",
//         },

//         content: [
//           // { stack: [...columns] },
//           {
//             columns: [
//               { width: "*", text: "" },
//               {
//                 style: "tableExample",
//                 absolutePosition: { x: 7, y: 75 },
//                 table: {
//                   headerRows: 1,
//                   widths: ["9%", "4%", "13%", "4%", "12%", "16.5%", "15%", "12.5%", "6%", "7%"],
//                   body: [
//                     [
//                       {
//                         text: [
//                           { text: "วันที่ผ่าตัด/\n", style: "fieldTable" },
//                           { text: "Room", style: "fieldTable" },
//                         ],
//                       },

//                       { text: "ANES", style: "fieldTable" },
//                       { text: "ชื่อ-สกุล", style: "fieldTable" },
//                       { text: "ADM", style: "fieldTable" },
//                       {
//                         text: [
//                           { text: "Principal\n", style: "fieldTable" },
//                           { text: "Diagnosis", style: "fieldTable" },
//                         ],
//                       },
//                       { text: "Operation", style: "fieldTable" },
//                       { text: "ICD9", style: "fieldTable" },
//                       {
//                         text: [
//                           { text: "Staff/\n", style: "fieldTable" },
//                           { text: "Assistant", style: "fieldTable" },
//                         ],
//                       },
//                       {
//                         text: [
//                           { text: "เวลาที่ใช้\n", style: "fieldTable" },
//                           { text: "[HH:MM]", style: "fieldTable" },
//                         ],
//                       },
//                       { text: "Blood Request", style: "fieldTable" },
//                     ],
//                     ...labRequestList,
//                   ],
//                 },
//               },

//               { width: "*", text: "" },
//             ],
//           },
//         ],
//         footer: function (currentPage: number, pageCount: number) {
//           return {
//             margin: [12, 20, 5, 20],
//             columns: [
//               {
//                 width: 775,
//                 text: [
//                   {
//                     text: [
//                       { text: " วันที่พิมพ์:", style: "fieldPrintDate" },
//                       { text: ` ${formatDate(momentDate)} `, style: "fieldPrintDate" },
//                       { text: "[", style: "fieldPrintDate" },
//                       { text: `${momentDate.format("HH:mm")}`, style: "fieldPrintDate" },
//                       { text: "]", style: "fieldPrintDate" },
//                       { text: "         ", style: "fieldPrintDate" },
//                     ],
//                     alignment: "right",
//                     //absolutePosition: { x: 331, y: 9.7 },
//                     //alignment: "right",
//                   },
//                 ],
//               },
//               {
//                 text: [
//                   {
//                     text: [
//                       {
//                         text: " หน้าที่" + currentPage.toString() + "/" + pageCount,
//                         style: "fieldPrintDate",
//                       },
//                     ],
//                     alignment: "left",
//                   },
//                 ],
//               },
//             ],
//           };
//         },
//       };

//       pdfMake.createPdf(docDefinition as any).open();
//     });
//   });
// };
