import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  TextArea,
  Dropdown,
  Button,
  Input
} from 'semantic-ui-react'

const ModProgressNoteFormUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%", padding: "5px" , backgroundColor: "#CCE6F8"}}>
        <div
          style={{display: "flex", justifyContent: "flex-end"}}>
          {props.closeIcon}
        </div>
        <div
          style={{  padding: "15px",  display: "block", marginBottom: "5px" }}>
          <div
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>

            <div
              style={{ color: "#2362B2", fontWeight: "bold" }}>
              บันทึกข้อความทางการพยาบาล
            </div>
            <div
              style={{ ...(props.isOPDEncounter && { display: "none" }) }}>

              <Button
                color="yellow"
                onClick={props.onOpenSelectDoctorOrder}
                style={{display: "none"}}>
                เลือกรายการคำสั่งแพทย์
              </Button>
            </div>
          </div>
          <div
            style={{ display: "flex", marginBottom: "15px", ...(props.isOPDEncounter && { display: "none" }) }}>

            <div
              style={{ flex: 1.2, display: "flex", flexDirection: "column", justifyContent: "center" }}>

              <label
                style={{ textAlign: "left", marginRight: "15px",}}>
                เวร
              </label>
            </div>
            <div
              style={{ flex: 6 }}>

              <Input
                fluid={true}
                onChange={(e, v) =>  props.setProp("selectedNurseNote.working_shift", v.value)}
                value={props.selectedNurseNote?.working_shift}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "none", marginBottom: "15px", ...(props.isOPDEncounter && { display: "none" }) }}>

            <div
              style={{ flex: 1.2, display: "flex", flexDirection: "column", justifyContent: "center" }}>

              <label
                style={{ textAlign: "left", marginRight: "15px",}}>
                คำสั่งแพทย์อื่นๆ
              </label>
            </div>
            <div
              style={{ flex: 6 }}>

              <Input
                fluid={true}
                onChange={(e, v) =>  props.setProp("selectedNurseNote.doctor_note_order", v.value)}
                value={props.selectedNurseNote?.doctor_note_order}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", marginBottom: "15px" }}>
            {}
            <div
              style={{ flex: 1.2, display: "flex", flexDirection: "column", justifyContent: "center" }}>

              <label
                style={{ textAlign: "left", marginRight: "15px",}}>
                Focus
              </label>
            </div>
            <div
              style={{ flex: 6 }}>

              <TextArea
                onChange={(e, v) =>  props.setProp("selectedNurseNote.focus", v.value)}
                rows="5"
                style={{ width:"100%", display:"none" }}
                value={props.selectedNurseNote?.focus}>
              </TextArea>
              <Dropdown
                allowAdditions={true}
                onAddItem={props.handleAddNurseNoteFocusOptions}
                onChange={props.onChangeDropdown}
                options={props.nurseNoteFocusOptions}
                search={true}
                selection={true}
                style={{width:"100%"}}
                value={props.selectedNurseNote?.focus}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", marginBottom: "15px" }}>

            <div
              style={{ flex: 1.2, display: "flex", flexDirection: "column", justifyContent: "center" }}>

              <label
                style={{ marginBottom: "10px" }}>
                ข้อมูล/อาการ/ปัญหา
              </label>
              <div
                style={{ textAlign: "left", marginRight: "15px",}}>

                <label
                  style={{ fontWeight: "bold" }}>
                  A
                </label>
                <label>
                  ssessment
                </label>
              </div>
            </div>
            <div
              className="ui form"
              style={{ flex: 6 }}>

              <TextArea
                onChange={(e, v) =>  props.setProp("selectedNurseNote.diagnosis", v.value)}
                rows="8"
                style={{ width:"100%" }}
                value={props.selectedNurseNote?.diagnosis}>
              </TextArea>
            </div>
          </div>
          <div
            style={{ display: "flex", marginBottom: "15px" }}>

            <div
              style={{ flex: 1.2, display: "flex", flexDirection: "column", justifyContent: "center" }}>

              <label
                style={{ marginBottom: "10px" }}>
                การพยาบาล
              </label>
              <div>

                <label
                  style={{ fontWeight: "bold" }}>
                  I
                </label>
                <label>
                  ntervention
                </label>
              </div>
            </div>
            <div
              className="ui form"
              style={{ flex: 6 }}>

              <TextArea
                onChange={(e, v) =>  props.setProp("selectedNurseNote.plan", v.value)}
                rows="10"
                style={{ width:"100%" }}
                value={props.selectedNurseNote?.plan}>
              </TextArea>
            </div>
          </div>
          <div
            style={{ display: "flex", marginBottom: "15px" }}>

            <div
              style={{ flex: 1.2, display: "flex", flexDirection: "column", justifyContent: "center" }}>

              <label
                style={{ marginBottom: "10px" }}>
                ผลการพยาบาล
              </label>
              <div>

                <label
                  style={{ fontWeight: "bold" }}>
                  E
                </label>
                <label>
                  avluation
                </label>
              </div>
            </div>
            <div
              className="ui form"
              style={{ flex: 6 }}>

              <TextArea
                onChange={(e, v) =>  props.setProp("selectedNurseNote.goal", v.value)}
                rows="8"
                style={{ width:"100%" }}
                value={props.selectedNurseNote?.goal}>
              </TextArea>
            </div>
          </div>
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>

            <div
              style={{padding: "0px 15px", display: "none"}}>
              Username
            </div>
            <div
              style={{padding: "0px 15px", display: "none"}}>

              <Input
                onChange={props.onChangeUsername}
                placeholder="Username"
                value={props.username}>
              </Input>
            </div>
            <div
              style={{padding: "0px 15px", display: "none"}}>
              Password
            </div>
            <div
              style={{padding: "0px 15px", display: "none"}}>

              <Input
                onChange={props.onChangePassword}
                placeholder="Password"
                type="password"
                value={props.password}>
              </Input>
            </div>
            <div
              style={{ ...(!props.selectedNurseNote?.edit_user && { display: "none" }) }}>
              {`ผู้บันทึก/แก้ไข : ${props.selectedNurseNote?.edit_user}`}
            </div>
            <div
              style={{padding: "0px 15px"}}>

              <Button
                color="green"
                fluid={true}
                onClick={props.handleSaveNurseNote}>
                บันทึก
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}


export default ModProgressNoteFormUX

export const screenPropsDefault = {}

/* Date Time : Mon Dec 02 2024 12:15:15 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", padding: \"5px\" , backgroundColor: \"#CCE6F8\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{  padding: \"15px\",  display: \"block\", marginBottom: \"5px\" }"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"15px\" }"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1.2, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ flex: 6 }"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "TextArea",
      "parent": 99,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) =>  props.setProp(\"selectedNurseNote.diagnosis\", v.value)"
        },
        "rows": {
          "type": "value",
          "value": "8"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.diagnosis"
        }
      },
      "seq": 101,
      "void": true
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"15px\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"15px\" }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1.2, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ flex: 6 }"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1.2, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ flex: 6 }"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "การพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: \"10px\" }"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "label",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": "ผลการพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: \"10px\" }"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "TextArea",
      "parent": 106,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) =>  props.setProp(\"selectedNurseNote.plan\", v.value)"
        },
        "rows": {
          "type": "value",
          "value": "10"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.plan"
        }
      },
      "seq": 117,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "TextArea",
      "parent": 108,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) =>  props.setProp(\"selectedNurseNote.goal\", v.value)"
        },
        "rows": {
          "type": "value",
          "value": "8"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.goal"
        }
      },
      "seq": 118,
      "void": true
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"15px\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1.2, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 6 }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "Focus"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"left\", marginRight: \"15px\",}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "TextArea",
      "parent": 125,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) =>  props.setProp(\"selectedNurseNote.focus\", v.value)"
        },
        "rows": {
          "type": "value",
          "value": "5"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\", display:\"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.focus"
        }
      },
      "seq": 127,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "Dropdown",
      "parent": 125,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddNurseNoteFocusOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDropdown"
        },
        "options": {
          "type": "code",
          "value": "props.nurseNoteFocusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.focus"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.closeIcon"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Button",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleSaveNurseNote"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "Username"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\", display: \"none\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\", display: \"none\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "Password"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\", display: \"none\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\", display: \"none\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Input",
      "parent": 140,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeUsername"
        },
        "placeholder": {
          "type": "value",
          "value": "Username"
        },
        "value": {
          "type": "code",
          "value": "props.username"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Input",
      "parent": 142,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangePassword"
        },
        "placeholder": {
          "type": "value",
          "value": "Password"
        },
        "type": {
          "type": "value",
          "value": "password"
        },
        "value": {
          "type": "code",
          "value": "props.password"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\", marginBottom: \"15px\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึกข้อความทางการพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"#2362B2\", fontWeight: \"bold\" }"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isOPDEncounter && { display: \"none\" }) }"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "Button",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกรายการคำสั่งแพทย์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenSelectDoctorOrder"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"15px\", ...(props.isOPDEncounter && { display: \"none\" }) }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\", marginBottom: \"15px\", ...(props.isOPDEncounter && { display: \"none\" }) }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1.2, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 6 }"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1.2, display: \"flex\", flexDirection: \"column\", justifyContent: \"center\" }"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 6 }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "เวร"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"left\", marginRight: \"15px\",}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "คำสั่งแพทย์อื่นๆ"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"left\", marginRight: \"15px\",}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "Input",
      "parent": 152,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "(e, v) =>  props.setProp(\"selectedNurseNote.doctor_note_order\", v.value)"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.doctor_note_order"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Input",
      "parent": 154,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "(e, v) =>  props.setProp(\"selectedNurseNote.working_shift\", v.value)"
        },
        "value": {
          "type": "code",
          "value": "props.selectedNurseNote?.working_shift"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อมูล/อาการ/ปัญหา"
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: \"10px\" }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"left\", marginRight: \"15px\",}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "label",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "A"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "label",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "ssessment"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "I"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "ntervention"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "label",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "E"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "label",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "avluation"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "code",
          "value": "`ผู้บันทึก/แก้ไข : ${props.selectedNurseNote?.edit_user}`"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.selectedNurseNote?.edit_user && { display: \"none\" }) }"
        }
      },
      "seq": 142,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 55,
  "isMounted": true,
  "memo": false,
  "name": "ModProgressNoteFormUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 55
}

*********************************************************************************** */
