import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Button,
  Divider
} from 'semantic-ui-react'

const CardRehabHistoryUX = (props: any) => {
    return(
      <div
        style={{margin:"0px 6px"}}>
        <div
          style={{fontWeight:"bold",fontSize:"large",color:"blue",margin:"10px 0px"}}>
          Treatment History List
        </div>
        <div
          style={{display:"flex",margin: "10px 0px",alignItems:"center"}}>
          
          <div
            style={{fontWeight:"bolder",fontSize:"larger"}}>
            Perform date: 
          </div>
          <div>
            {props.startDate}
          </div>
          <div
            style={{fontWeight:"bolder",fontSize:"larger"}}>
            to
          </div>
          <div>
            {props.endDate}
          </div>
          <div
            style={{display:"flex",alignItems:"center", gap:"10px",margin:"0px 10px"}}>
            
            <div
              style={{fontWeight:"bolder",fontSize:"larger"}}>
              Episode: 
            </div>
            <Dropdown
              selection={true}>
            </Dropdown>
          </div>
          <div>
            
            <Button
              color="blue"
              size="mini">
              SEARCH
            </Button>
          </div>
        </div>
        <div>
          {props.table}
        </div>
        <Divider>
        </Divider>
        <div>
          
          <div
            style={{fontWeight:"bold",fontSize:"large",margin:"10px 0px",color:"blue"}}>
            Treatment History
          </div>
          <div
            style={{fontWeight:"bolder",fontSize:"larger",margin:"10px 0px"}}>
            Treatment order:
          </div>
          <div
            style={{marginBottom:"6px"}}>
            {props.treatmentOrder}
          </div>
          <div
            style={{fontWeight:"bolder",fontSize:"larger",margin:"10px 0px"}}>
            Procedure code
          </div>
          <div
            style={{marginBottom:"6px"}}>
            {props.procedureCode}
          </div>
          <div
            style={{display:"flex",alignItems:"center",marginBottom:"6px"}}>
            
            <div>
              
              <div
                style={{fontWeight:"bolder",fontSize:"larger"}}>
                Performed date: 
              </div>
              <div>
                {props.preformedDate}
              </div>
            </div>
            <div>
              
              <div
                style={{fontWeight:"bolder",fontSize:"larger"}}>
                Performed date: 
              </div>
              <div>
                {props.preformedTime}
              </div>
            </div>
            <div>
              
              <div
                style={{fontWeight:"bolder",fontSize:"larger"}}>
                Performed by:  
              </div>
              <div>
                {props.preformedBy}
              </div>
            </div>
          </div>
          <div
            style={{fontWeight:"bolder",fontSize:"larger",margin:"10px 0px"}}>
            Document Scan: 
          </div>
          <div
            style={{marginBottom:"6px"}}>
            {props.documentScan}
          </div>
          <div>
            
            <div
              style={{textDecoration:"underline",fontWeight:"bold",fontSize:"large",margin:"10px 0xp"}}>
              Examination
            </div>
            <div
              style={{display:"flex",alignItems:"center",marginBottom:"6px"}}>
              
              <div
                style={{fontWeight:"bolder",fontSize:"larger"}}>
                Subjective: 
              </div>
              <div>
                {props.subjective}
              </div>
            </div>
            <div
              style={{display:"flex",alignItems:"center",marginBottom:"6px"}}>
              
              <div
                style={{fontWeight:"bolder",fontSize:"larger"}}>
                Objective: 
              </div>
              <div>
                {props.objective}
              </div>
            </div>
            <div
              style={{display:"flex",alignItems:"center",marginBottom:"6px"}}>
              
              <div
                style={{fontWeight:"bolder",fontSize:"larger"}}>
                Assessment:
              </div>
              <div>
                {props.assessment}
              </div>
            </div>
            <div
              style={{display:"flex",alignItems:"center",marginBottom:"6px"}}>
              
              <div
                style={{fontWeight:"bolder",fontSize:"larger"}}>
                Goal: 
              </div>
              <div>
                {props.goal}
              </div>
            </div>
            <div
              style={{display:"flex",alignItems:"center",marginBottom:"6px"}}>
              
              <div
                style={{fontWeight:"bolder",fontSize:"larger"}}>
                Other Examination:
              </div>
              <div>
                {props.otherExamination}
              </div>
            </div>
          </div>
          <div
            style={{fontWeight:"bold",fontSize:"large",textDecoration:"underline",margin:"10px 0px"}}>
            Treatment Details
          </div>
          <div
            style={{marginBottom:"6px"}}>
            {props.treatmentDetails}
          </div>
          <div
            style={{fontWeight:"bold",fontSize:"large",textDecoration:"underline",margin:"10px 0px"}}>
            Post-Treatment Evaluation
          </div>
          <div
            style={{marginBottom:"6px"}}>
            {props.postTreatmentEvaluation}
          </div>
          <div
            style={{fontWeight:"bold",fontSize:"large",textDecoration:"underline",margin:"10px 0px"}}>
            Recommendation & Patient Education
          </div>
          <div
            style={{marginBottom:"6px"}}>
            {props.recommend}
          </div>
          <div
            style={{fontWeight:"bold",fontSize:"large",textDecoration:"underline",margin:"10px 0px"}}>
            Medication:
          </div>
          <div
            style={{marginBottom:"6px"}}>
            {props.medication}
          </div>
        </div>
      </div>
    )
}


export default CardRehabHistoryUX

export const screenPropsDefault = {}

/* Date Time : Wed Nov 06 2024 10:41:43 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin:\"0px 6px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment History List"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\",fontSize:\"large\",color:\"blue\",margin:\"10px 0px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",margin: \"10px 0px\",alignItems:\"center\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Perform date: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "to"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\", gap:\"10px\",margin:\"0px 10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.table"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Episode: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Dropdown",
      "parent": 7,
      "props": {
        "selection": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "SEARCH"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment History"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\",fontSize:\"large\",margin:\"10px 0px\",color:\"blue\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment order:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\",margin:\"10px 0px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure code"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\",margin:\"10px 0px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",marginBottom:\"6px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Document Scan: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\",margin:\"10px 0px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment Details"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\",fontSize:\"large\",textDecoration:\"underline\",margin:\"10px 0px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Post-Treatment Evaluation"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\",fontSize:\"large\",textDecoration:\"underline\",margin:\"10px 0px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Recommendation & Patient Education"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\",fontSize:\"large\",textDecoration:\"underline\",margin:\"10px 0px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\",fontSize:\"large\",textDecoration:\"underline\",margin:\"10px 0px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.treatmentOrder"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom:\"6px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.procedureCode"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom:\"6px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.documentScan"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom:\"6px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "Subjective: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "Objective: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "Assessment:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "Goal: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "Other Examination:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.treatmentDetails"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom:\"6px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.postTreatmentEvaluation"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom:\"6px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.recommend"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom:\"6px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medication"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom:\"6px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "Performed date: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "Performed date: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "Performed by:  "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.preformedDate"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.preformedTime"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": "props.preformedBy"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "code",
          "value": "props.subjective"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.objective"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.assessment"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.goal"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.otherExamination"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Divider",
      "parent": 0,
      "props": {
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "Examination"
        },
        "style": {
          "type": "code",
          "value": "{textDecoration:\"underline\",fontWeight:\"bold\",fontSize:\"large\",margin:\"10px 0xp\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",marginBottom:\"6px\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",marginBottom:\"6px\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",marginBottom:\"6px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",marginBottom:\"6px\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",marginBottom:\"6px\"}"
        }
      },
      "seq": 62,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardRehabHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
