import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { drawTextOnPage } from "./StaticPdfUtils";

// PDF Path
const PDF = "/static/pdf/printList/cons_cervi_joint_th.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormConsentCerviJointTH = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const fontSize = 15; //*

    // Common Text
    const commonTextCoordinates = [
      { text: props.items?.formatPatient, x: 406, y: 744 },
      { text: props.items?.hn, x: 406, y: 730 },
      { text: props.items?.patientEncounter, x: 539, y: 730},
      { text: props.items?.birthdate, x: 420, y: 716 },
      { text: props.items?.age.toString(), x: 530, y: 716 },
      { text: props.items?.formatGender, x: 414, y: 700 },
    ];

    // Page 1
    const firstTextCoordinates = [
      { text: props.items?.formatPatient, x: 145, y: 581 },
      { text: props.items?.formatDoctor || "-", x: 253, y: 558 },
    ];

    // Page 3
    const thirdTextCoordinates = [
      { text: props.items?.patientFullName, x: 68, y: 555 },
      { text: props.items?.doctorFullName || "", x: 68, y: 486 },
    ];

    const thirdDateTimeTextCoordinates = [
      { text: props.items?.signedDate, x: 80, y: 440 },
      { text: props.items?.printTime, x: 146, y: 440 },
    ];

    drawTextOnPage(pages[0], [...commonTextCoordinates, ...firstTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[1], commonTextCoordinates, customFont, fontSize);
    drawTextOnPage(pages[2], [...commonTextCoordinates, ...thirdTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[2], thirdDateTimeTextCoordinates, customFont, 10);

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }

  } catch (error) {
    console.error("Error modifying PDF: ", error);
    return {};
  }
};

export default FormConsentCerviJointTH;