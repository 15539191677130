import { TDocumentDefinitions } from "pdfmake/interfaces";

import { splitStringNewLine } from "react-lib/apps/HISV3/common/CommonInterface";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

const FormPatientPaidServicesReport = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Props form PatientPaidServicesReport:", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.reportMoReData?.params,
    pageMarginTop: 120,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: "รายงานผู้ป่วยที่ชำระค่าบริการ",
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `ประเภท : ${props.reportMoReData?.params?.encounter_type_label}`,
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `คลินิก: ${props.reportMoReData?.params?.division}`,
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `วันที่รับการรักษา ตั้งแต่ ${props.items?.filterStartDate} ถึง ${props.items?.filterEndDate}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const generateTableBody = (data: any, columns: any) => {
    let body = [];

    console.log("Patient Paid Report Row Used: ", props.reportMoReData?.fields[0]);
    // Header of the Table
    body.push([
      { text: "HN", bold: true, alignment: "center", border: [true, true, false, true] },
      {
        text: "VN/วันเวลาที่รักษา",
        bold: true,
        alignment: "center",
        border: [false, true, false, true],
      },
      { text: "ชื่อ-สกุล", bold: true, alignment: "center", border: [false, true, false, true] },
      { text: "สิทธิ", bold: true, alignment: "center", border: [false, true, false, true] },
      {
        text: "แพทย์เจ้าของไข้",
        bold: true,
        alignment: "center",
        border: [false, true, false, true],
      },
      { text: "รายละเอียด", bold: true, alignment: "center", border: [false, true, false, true] },
      { text: "วิธีรับชำระ", bold: true, alignment: "center", border: [false, true, false, true] },
      { text: "ส่งเบิก", bold: true, alignment: "center", border: [false, true, false, true] },
      { text: "รับชำระ", bold: true, alignment: "center", border: [false, true, false, true] },
      { text: "ค้างจ่าย", bold: true, alignment: "center", border: [false, true, false, true] },
      { text: "ยอดรวม", bold: true, alignment: "center", border: [false, true, true, true] },
    ]);

    if (data?.length > 0) {
      data.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            const descList = row["description_list"].map((text: string) => {
              return splitStringNewLine(text, { fontSize: 12, width: 305 }); // if width of column set as % => (% * 100) + 5
            });
            const sendClaimList = descList.map((texts: string[], index: number) => {
              return [row["send_claim_list"][index], ...Array(texts.length - 1).fill("\u00A0")];
            });
            const paidList = descList.map((texts: string[], index: number) => {
              return [row["paid_list"][index], ...Array(texts.length - 1).fill("\u00A0")];
            });
            const overdueList = descList.map((texts: string[], index: number) => {
              return [row["overdue_list"][index], ...Array(texts.length - 1).fill("\u00A0")];
            });
            const priceList = descList.map((texts: string[], index: number) => {
              return [row["price_list"][index], ...Array(texts.length - 1).fill("\u00A0")];
            });
            switch (column) {
              case "hn":
                dataRow.push({ text: row[column], border: [true, false, false, false] });
                break;
              case "encounter_number":
                dataRow.push({
                  text: row[column] + "\n" + row["performed_date"],
                  border: [false, false, false, false],
                });
                break;
              case "encounter_payment":
                dataRow.push({ text: "", border: [false, false, false, false] });
                break;
              case "description_list":
                dataRow.push({
                  stack: descList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text };
                      }),
                    };
                  }),
                  border: [false, false, false, false],
                });
                break;
              case "send_claim_list":
                dataRow.push({
                  stack: sendClaimList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text, alignment: "right" };
                      }),
                    };
                  }),
                  border: [false, false, false, false],
                });
                break;
              case "paid_list":
                dataRow.push({
                  stack: paidList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text, alignment: "right" };
                      }),
                    };
                  }),
                  border: [false, false, false, false],
                });
                break;
              case "overdue_list":
                dataRow.push({
                  stack: overdueList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text, alignment: "right" };
                      }),
                    };
                  }),
                  border: [false, false, false, false],
                });
                break;
              case "price_list":
                dataRow.push({
                  stack: priceList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text, alignment: "right" };
                      }),
                    };
                  }),
                  border: [false, false, true, false],
                });
                break;
              default:
                dataRow.push({
                  text: row[column].toString(),
                  border: [false, false, false, false],
                });
                break;
            }
          } else {
            dataRow.push({ text: "-", border: [false, false, false, false] });
          }
        });

        body.push(dataRow);

        // Total Row
        body.push([
          { text: "", colSpan: 5, border: [true, false, false, true] },
          {},
          {},
          {},
          {},
          { text: "รวม", bold: true, border: [false, false, false, true] },
          { text: row["encounter_payment"], border: [false, false, false, true] },
          {
            text: row["total_send_claim"],
            alignment: "right",
            border: [false, false, false, true],
          },
          { text: row["total_paid"], alignment: "right", border: [false, false, false, true] },
          { text: row["total_overdue"], alignment: "right", border: [false, false, false, true] },
          { text: row["total_price"], alignment: "right", border: [false, false, true, true] },
        ]);
      });
    }

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["auto", "auto", "*", "19%", "*", "30%", "auto", "auto", "auto", "auto", "auto"], // ["auto", "auto", "*", "auto", "*", "30%", "auto", "auto", "auto", "auto", "auto"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
    };
  };

  const generateTotalTableBody = (data: any, columns: any) => {
    let body = [];

    console.log("Patient Paid Report Row Used: ", props.reportMoReData?.fields[1]);

    // Header of the Table
    body.push([
      { text: "สิทธิ", bold: true, alignment: "center", border: [true, true, false, true] },
      { text: "จำนวน", bold: true, alignment: "center", border: [false, true, false, true] },
      { text: "ยอดส่งเบิก", bold: true, alignment: "center", border: [false, true, false, true] },
      { text: "ยอดรับชำระ", bold: true, alignment: "center", border: [false, true, false, true] },
      { text: "ค้างจ่าย", bold: true, alignment: "center", border: [false, true, false, true] },
      { text: "รวม", bold: true, alignment: "center", border: [false, true, true, true] },
    ]);

    if (data?.length > 0) {
      data.forEach((row: any, index: number) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            let cell: any = {};

            switch (column) {
              case "coverage_count":
              case "total_send_claim":
              case "total_paid":
              case "total_overdue":
              case "total_price":
                cell = { text: row[column], alignment: "right" };
                break;
              default:
                cell = { text: row[column].toString() };
                break;
            }

            // Set last of row get bold
            if (index === data?.length - 1) {
              cell.bold = true;
            }

            dataRow.push(cell);
          } else {
            dataRow.push({ text: "-" });
          }
        });

        body.push(dataRow);
      });
    }

    return body;
  };

  const tableTotalReport = (data: any, columns: any) => {
    return {
      unbreakable: true,
      table: {
        widths: ["20%", "auto", "auto", "auto", "auto", "auto"],
        headerRows: 1,
        body: generateTotalTableBody(data, columns),
      },
      layout: {
        hLineWidth: (i: number, node: any) => {
          return i === 0 || i === 1 || i === node.table.body.length ? 1 : 0;
        },
        vLineWidth: (i: number, node: any) => {
          return i === 0 || i === node.table.widths.length ? 1 : 0;
        },
        hLineColor: (i: number, node: any) => {
          return "black";
        },
        vLineColor: (i: number, node: any) => {
          return "black";
        },
      },
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.reportMoReData?.fields[0]?.items || [], [
        "hn",
        "encounter_number",
        "patient_name",
        "coverage",
        "doctor_name",
        "description_list",
        "encounter_payment",
        "send_claim_list",
        "paid_list",
        "overdue_list",
        "price_list",
      ]),
      { text: " ", preserveLeadingSpaces: true },
      tableTotalReport(props.reportMoReData?.fields[1]?.sub_totals || [], [
        "coverage_name",
        "coverage_count",
        "total_send_claim",
        "total_paid",
        "total_overdue",
        "total_price",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: `A4`,
  };
};

export default FormPatientPaidServicesReport;
