import React, { useEffect, useMemo, useState } from "react";
import { Image, Modal } from "semantic-ui-react";
import { mapOptions } from "react-lib/apps/HISV3/common/CommonInterface";
import { makeStyles } from "@mui/styles";
import CardSystemicEvaluationUX from "react-lib/apps/HISV3/ANE/CardSystemicEvaluationUX";
import CardAirwayTeethHeadNeckUX from "react-lib/apps/HISV3/ANE/CardAirwayTeethHeadNeckUX";
import CardHistoryCentralLab from "react-lib/apps/HISV3/LAB/CardHistoryCentralLab";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

const optionsDetail = [
  { key: "respiratoryDetail", option: "preanes_respiratory" },
  { key: "endrocrineDetail", option: "preanes_Endocrine" },
  { key: "cardiovascularDetail", option: "preanes_cardiovascul" },
  { key: "neurologicDetail", option: "preanes_neurologic" },
  { key: "eentDetail", option: "preanes_EENT" },
  { key: "giDetail", option: "preanes_gi" },
  { key: "genitourinaryDetail", option: "preanes_genitourinar" },
  { key: "skinDetail", option: "preanes_skin_breast" },
  { key: "immunologyDetail", option: "preanes_Immunology" },
];

const useStyles = makeStyles(() => ({
  bgCard: {
    backgroundColor: "#EDF6F9",
    width: "100%",
    padding: "20px 10px 10px",
    borderRadius: 3,
    border: "solid 0.5px ",
    borderColor: "#5DBCD2",
    overflow: "auto",
  },
  labIcon: {
    width: "31px",
    height: "28px",
    borderRadius: "3px",
    border: "0.5px solid #BED0E8",
    background: "#56B3EF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  drugIcon: {
    width: "60px",
    height: "31px",
    flexShrink: "0",
    borderRadius: "7px",
    border: "1px solid #F3C7F4",
    background: "#C66ACE",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const IMAGES = {
  ClassI: "/static/images/mallampati/mallampatiClass1.png",
  ClassII: "/static/images/mallampati/mallampatiClass2.png",
  ClassIII: "/static/images/mallampati/mallampatiClass3.png",
  ClassIV: "/static/images/mallampati/mallampatiClass4.png",
  lab: "/static/images/mallampati/icomoon-free_lab.png",
  drugs: "/static/images/mallampati/mdi_drugs.png",
};

type CardPhysicianEvaluationProps = {
  // function
  onEvent: any;
  setProp: any;
  runSequence: any;
  buttonLoadCheck: any;
  HistoryCentralLabSequence?: any;
  selectedOrOrder?: any;
  selectedEncounter?: any;

  // options
  masterOptions?: any;
  masterData?: any;
  PreAnestheticSequence?: any;
};

const CardPhysicianEvaluation = (props: CardPhysicianEvaluationProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const [endrocrineOptions, setEndrocrineOptions] = useState<any[]>([]);
  const [respiratoryOptions, setRespiratoryOptions] = useState<any[]>([]);
  const [cardiovascularOptions, setCardiovascularOptions] = useState<any[]>([]);
  const [neurologicOptions, setNeurologicOptions] = useState<any[]>([]);
  const [eentOptions, setEentOptions] = useState<any[]>([]);
  const [giOptions, setGiOptions] = useState<any[]>([]);
  const [genitourinaryOptions, setGenitourinaryOptions] = useState<any[]>([]);
  const [skinOptions, setSkinOptions] = useState<any[]>([]);
  const [immunologyOptions, setImmunologyOptions] = useState<any[]>([]);
  const [openModHistoryCentralLab, setOpenModHistoryCentralLab] = useState<boolean>(false);

  const dataOptions = {
    respiratoryDetail: { setData: setRespiratoryOptions, data: respiratoryOptions },
    endrocrineDetail: { setData: setEndrocrineOptions, data: endrocrineOptions },
    cardiovascularDetail: { setData: setCardiovascularOptions, data: cardiovascularOptions },
    neurologicDetail: { setData: setNeurologicOptions, data: neurologicOptions },
    eentDetail: { setData: setEentOptions, data: eentOptions },
    giDetail: { setData: setGiOptions, data: giOptions },
    genitourinaryDetail: { setData: setGenitourinaryOptions, data: genitourinaryOptions },
    skinDetail: { setData: setSkinOptions, data: skinOptions },
    immunologyDetail: { setData: setImmunologyOptions, data: immunologyOptions },
  };

  // #useEffect(() => {
  //   props.runSequence({
  //     sequence: "PreAnesthetic",
  //     action: "getPhysicianEvaluation",
  //   });
  // }, []);

  useEffect(() => {
    for (let item of optionsDetail) {
      let options = (props.PreAnestheticSequence?.anesPhysicianEvaluation?.[item?.key] || []).map(
        (items: any) => {
          return { key: items, text: items, value: items };
        }
      );

      dataOptions?.[item?.key]?.setData([
        ...options,
        ...(props.masterOptions?.[item?.option] || []),
      ]);
    }
  }, [props.PreAnestheticSequence?.anesPhysicianEvaluation, props.masterOptions]);

  const asaClassOptions = useMemo(() => {
    return props.masterOptions?.asa_class || [];
  }, [props.masterOptions?.asa_class]);

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      if (systemicEvaluationCheck.includes(data?.name) && !data.checked) {
        let name = data?.name.split("Check");
        props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation`, {
          ...props.PreAnestheticSequence?.anesPhysicianEvaluation,
          [`${data.name}`]: data.checked,
          [`${name?.[0]}Detail`]: "",
          ...(["xRayCheck", "imagingCheck", "ekgCheck", "otherCheck"].includes(data.name) && {
            [`${data.name.replaceAll("Check", "Date")}`]: "",
          }),
        });
      } else {
        props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation.${data.name}`, data.checked);
      }
    } else {
      if (data.name === "systemicEvaluationType" && data.value === "Normal") {
        props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation`, {
          ...props.PreAnestheticSequence?.anesPhysicianEvaluation,
          [`${data.name}`]: data.value,
          ...systemicEvaluationReset,
        });
      } else {
        props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation.${data.name}`, data.value);
      }
    }
  };

  const handleAddOptions = (e: any, v: any) => {
    let value = v.value;
    dataOptions?.[v.name]?.setData([
      ...new Map(
        [{ key: value, text: value, value: value }, ...dataOptions?.[v.name]?.data].map((item) => [
          item.text,
          item,
        ])
      ).values(),
    ]);
  };

  const handleChangeDate = (key: string, date: any) => {
    props.setProp(`PreAnestheticSequence.anesPhysicianEvaluation.${key}`, date);
  };

  return (
    <div
      id="CardPreAnestheticAirwayEvaluation"
      style={{ width: "100%", height: "100%", padding: "10px" }}
    >
      <div className={classes.bgCard}>
        <CardSystemicEvaluationUX
          systemicEvaluation={props.PreAnestheticSequence?.anesPhysicianEvaluation}
          handleChangeData={handleChangeData}
          handleChangeLabDate={(date: any) => handleChangeDate("labDataDate", date)}
          handleChangeXRayDate={(date: any) => handleChangeDate("xRayDate", date)}
          handleChangeImagingDate={(date: any) => handleChangeDate("imagingDate", date)}
          handleChangeEKGDate={(date: any) => handleChangeDate("ekgDate", date)}
          handleChangeOtherDate={(date: any) => handleChangeDate("otherDate", date)}
          handleAddOptions={handleAddOptions}
          respiratoryOptions={respiratoryOptions}
          endrocrineOptions={endrocrineOptions}
          cardiovascularOptions={cardiovascularOptions}
          neurologicOptions={neurologicOptions}
          eentOptions={eentOptions}
          giOptions={giOptions}
          genitourinaryOptions={genitourinaryOptions}
          skinOptions={skinOptions}
          immunologyOptions={immunologyOptions}
          systemicEvaluationOptions={[
            { key: "Normal", text: "Normal", value: "Normal" },
            { key: "Abnormal", text: "Abnormal", value: "Abnormal" },
          ]}
          logoLab={
            <div className={classes.labIcon} onClick={() => setOpenModHistoryCentralLab(true)}>
              <div>
                <Image height="100%" src={IMAGES?.lab} style={{ filter: "invert(1)" }} />
              </div>
            </div>
          }
          buttonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "PreAnesthetic",
                  action: "saveAnesPhysicianEvaluation",
                  cardKey: "CardPreAnestheticReEvaluation",
                });
              }}
              // data
              paramKey={`CardPreAnestheticReEvaluation_saveAnesPhysicianEvaluation`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`CardPreAnestheticReEvaluation_saveAnesPhysicianEvaluation`]
              }
              // config
              color={
                props.PreAnestheticSequence?.anesPhysicianEvaluation?.status === "DRAFT"
                  ? "orange"
                  : "green"
              }
              size="medium"
              style={{ width: "120px" }}
              title={
                props.PreAnestheticSequence?.anesPhysicianEvaluation?.status === "DRAFT"
                  ? "Save Draft"
                  : "Save"
              }
            />
          }
          languageUX={props.languageUX}
        />
        <CardAirwayTeethHeadNeckUX
          masterOptions={props.masterOptions}
          systemicEvaluation={props.PreAnestheticSequence?.anesPhysicianEvaluation}
          handleChangeData={handleChangeData}
          asaClassOptions={asaClassOptions}
          dentalOptions={[
            { key: 1, text: "Yes", value: "Yes" },
            { key: 2, text: "No", value: "No" },
          ]}
          mallampatiGradeOptions={[
            {
              key: 1,
              text: "Class I: Soft palate, uvala, fauces, pillars visible.",
              value: "ClassI",
            },
            {
              key: 2,
              text: "Class II: Soft palate, uvula, fauces visible.",
              value: "ClassII",
            },
            {
              key: 3,
              text: "Class III: Soft palate, base of uvula visible.",
              value: "ClassIII",
            },
            {
              key: 4,
              text: "Class IV:Only hard palate visible.",
              value: "ClassIV",
            },
            {
              key: 5,
              text: "Cannot be evaluated",
              value: "cannotBeEvaluated",
            },
          ]}
          mallampatiImg={
            <Image
              height="100%"
              src={
                IMAGES?.[props.PreAnestheticSequence?.anesPhysicianEvaluation?.mallampatiGradeType]
              }
            />
          }
          medication={
            <div className={classes.drugIcon}>
              <div>
                <Image height="100%" src={IMAGES?.drugs} style={{ filter: "invert(1)" }} />
              </div>
            </div>
          }
          languageUX={props.languageUX}
        />
      </div>

      {openModHistoryCentralLab && (
        <Modal
          size="large"
          closeIcon
          open={openModHistoryCentralLab}
          onClose={() => setOpenModHistoryCentralLab(false)}
        >
          <CardHistoryCentralLab
            // function
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            HistoryCentralLabSequence={props.HistoryCentralLabSequence}
            // data
            buttonLoadCheck={props.buttonLoadCheck}
            patientId={props.selectedOrOrder?.patient_id}
            selectedEncounter={props.selectedEncounter}
            hidePrintButton={true}
            languageUX={props.languageUX}
          />
        </Modal>
      )}
    </div>
  );
};

export default React.memo(CardPhysicianEvaluation);

const systemicEvaluationReset = {
  respiratoryCheck: false,
  cardiovascularCheck: false,
  neurologicCheck: false,
  eentCheck: false,
  giCheck: false,
  genitourinaryCheck: false,
  endrocrineCheck: false,
  skinCheck: false,
  immunologyCheck: false,
  respiratoryDetail: "",
  cardiovascularDetail: "",
  neurologicDetail: "",
  eentDetail: "",
  giDetail: "",
  genitourinaryDetail: "",
  endrocrineDetail: "",
  skinDetail: "",
  immunologyDetail: "",
};

const systemicEvaluationCheck = [
  "respiratoryCheck",
  "cardiovascularCheck",
  "neurologicCheck",
  "eentCheck",
  "giCheck",
  "genitourinaryCheck",
  "endrocrineCheck",
  "skinCheck",
  "immunologyCheck",
  // Other Investigations
  "xRayCheck",
  "imagingCheck",
  "ekgCheck",
  "otherCheck",
  // Planned anesthesia
  "plannedOtherCheck",
  "airwayOtherCheck",
  "intubationOtherCheck",
  "emergencyCheck",
];
