import React, {
  ReactElement,
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  MutableRefObject,
  KeyboardEvent,
  SyntheticEvent,
  CSSProperties,
  useMemo,
} from "react";
import {
  Icon,
  Popup,
  SemanticCOLORS,
  Input,
  Button,
  InputProps,
  Label,
  Form,
} from "semantic-ui-react";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";

import CardDrugClaimQA from "./CardDrugClaimQA";
import DrugIconPopup from "./DrugIconPopup";
import { adToBe, beToAd, formatDate } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

const ICON_MARGIN = "0 0.275rem";

const ICONS = {
  duplicate: "images/duplicate.png",
  document_regular: "images/document-regular.png",
  group_message: "images/group-message.png",
  pregnant: "images/pregnant.png",
  alert_shield: "images/alert_shield.png",
  home_di: "images/home_di_icon.png",
};

const LabelStyle = {
  margin: ICON_MARGIN,
  fontWeight: "bold",
  fontSize: ".75285714rem",
  cursor: "pointer",
} as CSSProperties;

const ICONStyle = {
  height: "1.35rem",
  margin: ICON_MARGIN,
  cursor: "pointer",
} as CSSProperties;

const PREGCAT_ALERT = {
  99: "NA",
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "X",
  6: "P1D",
  7: "P3D",
};

const SubDrugOrderStatusAlert: React.FunctionComponent<any> = (props) => {
  const [openNote, setOpenNote] = useState<boolean>(false);
  const [openDI, setOpenDI] = useState<boolean>(false);
  const [openClaimQA, setOpenClaimQA] = useState<boolean>(false);
  const [openModInfo, setOpenModInfo] = useState<{
    content: string;
    color: SemanticCOLORS;
    align?: CSSProperties["textAlign"];
    size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
  } | null>(null);

  const pregcat = useMemo(() => {
    let pregcat: string = props.item.pregcat;
    if (props.item?.pregcat_alert) {
      pregcat = props.item.pregcat_alert.match(/:(.*)]/)?.[1];
    }

    if (typeof pregcat === "number") {
      pregcat = PREGCAT_ALERT[pregcat];
    } else if (pregcat === undefined) {
      pregcat = props.item.pregcat_alert;
    }

    return pregcat;
  }, [props.item.pregcat_alert, props.item.pregcat]);

  const alerts = useMemo(() => {
    const alerts = props.item.alerts;
    return {
      ...alerts,
      I: alerts?.I || alerts?.interaction,
      H: alerts?.H || alerts?.highalert,
      L: alerts?.L || alerts?.lasa,
      D: alerts?.D || alerts?.duplication,
      O: alerts?.O || alerts?.overdoses,
    };
  }, [props.item.alerts]);

  const handleOpenNote = (e: SyntheticEvent) => {
    stopPropagation(e);
    if (props.onSaveNote) {
      setOpenNote(true);
    }
  };

  const handleOpenDI = (e: SyntheticEvent) => {
    stopPropagation(e);
    if (props.onSaveNote) {
      setOpenDI(true);
    }
  };

  const handleOpenDuplicate = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenModInfo({
      content: alerts["D"]?.details || "",
      color: "orange",
      size: "small",
    });
  };

  const handleOpenPregnant = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenModInfo({
      content: `Pregnancy Category: ${pregcat}`,
      color: "pink",
      align: "center",
    });
  };

  const handleOpenOrderNotes = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenModInfo({ content: alerts["N"], color: "blue" });
  };

  const handleOpenRejectNotes = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenModInfo({ content: alerts["X"], color: "black" });
  };

  const handleCloseDuplicate = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenModInfo(null);
  };

  const handleOpenDrugClaim = (e: SyntheticEvent) => {
    console.log("handleOpenDrugClaim: ");
    stopPropagation(e);

    if (!props.useOutSideCardQAClaim) {
      setOpenClaimQA(true);
    }

    props.onDrugClaim?.({
      item: props.item,
      questionIndex: 0,
      haveQuestion: true,
      itemIndex: props.index,
    });
  };

  const stopPropagation = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSave = (data: any) => {
    props.onSaveNote?.(data, props.index);
    handlePopupOpen();
  };

  const handlePopupOpen = () => {
    setOpenNote(false);
    setOpenDI(false);
    setOpenClaimQA(false);
  };

  const handleChangeDate = (name: string, date: string) => {
    const parseDate = beToAd(date);

    if (name === "TAPER") {
      props.onEvent({
        message: "AddToOrder",
        params: {
          editItem: {
            start_date: parseDate?.format("YYYY-MM-DD"),
            end_date: parseDate
              ?.add(props.item.duration ? props.item.duration - 1 : 0)
              .format("YYYY-MM-DD"),
          },
          drugIndex: props.index,
          orderType: props.orderType,
          // card: CARD_DRUG_ORDER_ID,
        },
      });
    } else {
      props.onEvent({
        message: "AddToOrder",
        params: {
          editItem: { start_date: parseDate },
          drugIndex: props.index,
          orderType: props.orderType,
          // card: CARD_DRUG_ORDER_ID,
        },
      });
    }
  };

  // console.log("SubDrugOrderStatusAlert props: ", props);
  // console.log("SubDrugOrderStatusAlert props.item: ", props.item);

  /*
    adr_info,  props.item.note_adverse_reaction คนไข้ แพ้ยา
    alerts?.I (Drug Interaction),  props.item.note_drug_interaction
      - drug_interaction_in
    alerts?.H (High Alert Drug), ไม่มีเปิด note
    alerts?.L (LASA), ไมมีเปิด note
    alerts?.D (Duplicate), แสดง alerts["D"]?.details
    props.item.claim_payload?.payloads > 0 , แสดง QA status
    pregcat ประกอบ ด้วย props.item.pregcat_alert กับ props.item.pregcat แสดง level (A,B,C,D)
    alerts?.N (บันทึกข้อความ ) แสดง alerts["N"]
    alerts?.X (ข้อมูลการปฏิเสธการสั่งยา ) แสดง alerts["X"]
    alerts?.O (Overdose) แสดง Icon
    openClaimQA state เปิด CardDrugClaimQA
    props.item.note_drug_lab_interaction
    props.item.note_drug_disease
    props.item.is_syringe_need
    props.item.start_date
  */

  // Issue 64665
  // ขาดทดสอบ DI , Duplicate, N,X,O, note_drug_lab_interaction, start_date, claim_payload

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          rowGap: "5px",
        }}
      >
        {/*  */}
        {props.item.adr_info && (
          <DrugIconPopup
            open={!openNote ? undefined : openNote}
            textContent={
              openNote ? (
                <NotePopup
                  value={props.item.note_adverse_reaction}
                  onSave={(note: string) => {
                    handleSave({
                      ...props.item,
                      note_adverse_reaction: note,
                      key: "note_adverse_reaction",
                    });
                  }}
                  onClose={() => setOpenNote(false)}
                />
              ) : (
                <div>
                  {props.item.adr_info?.split("\n")?.map((note: any) => (
                    <div>{note}</div>
                  ))}
                  <div> Doctor note: {props.item.note_adverse_reaction}</div>
                </div>
              )
            }
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <Label
              color="red"
              size="mini"
              style={{ margin: ICON_MARGIN, cursor: "pointer" }}
              onClick={handleOpenNote}
            >
              A
            </Label>
          </DrugIconPopup>
        )}
        {alerts["I"] && (
          <DrugIconPopup
            open={!openDI ? undefined : openDI}
            textContent={
              // openDI ? (
              //   <NotePopup
              //     value={props.item.note_drug_interaction}
              //     onSave={(note: string) => {
              //       handleSave({
              //         ...props.item,
              //         note_drug_interaction: note,
              //         key: "note_drug_interaction",
              //       });
              //     }}
              //     onClose={() => setOpenDI(false)}
              //   />
              // ) : (
              <div>
                <div>Drug Interaction</div>
                {alerts["I"]?.info
                  .split("\n")
                  .map((text: string, index: number) =>
                    index !== 0 ? <div>{text}</div> : <div />
                  )}
                <div>Doctor note: {props.item.note_drug_interaction || "-"}</div>
              </div>
              // )
            }
            config={{ offset: -5 }}
            // onOpen={handlePopupOpen}
          >
            {props.item.drug_interaction_in === 2 || props.item.drug_interaction_in === "TRIAGE" ? (
              <img
                src={ICONS.home_di}
                style={ICONStyle}
                onClick={() => {
                  props.onEvent({
                    message: "HandleModDrugInteraction",
                    params: {
                      action: "open",
                      targetStateOrder: props.targetStateOrder,
                      data: { ...props.item, drugIndex: props.index },
                    },
                  });
                }}
                alt="home_di.icon"
              />
            ) : (
              <Label
                color="yellow"
                size="mini"
                style={{ margin: ICON_MARGIN, cursor: "pointer" }}
                // onClick={handleOpenDI}
                onClick={() => {
                  props.onEvent({
                    message: "HandleModDrugInteraction",
                    params: {
                      action: "open",
                      targetStateOrder: props.targetStateOrder,
                      data: { ...props.item, drugIndex: props.index },
                    },
                  });
                }}
              >
                DI
              </Label>
            )}
          </DrugIconPopup>
        )}

        {alerts["H"] && (
          <DrugIconPopup
            textContent={"High Alert Drug"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <label
              style={{
                ...LabelStyle,
                color: "#db2828",
              }}
            >
              HAD
            </label>
          </DrugIconPopup>
        )}

        {alerts["L"] && (
          <DrugIconPopup
            textContent={"Look-Alike, Sound-Alike Drug"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <label
              style={{
                ...LabelStyle,
                color: "#000000",
              }}
            >
              LASA
            </label>
          </DrugIconPopup>
        )}

        {alerts["D"] && (
          <DrugIconPopup
            textContent={"Duplicate"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <img
              src={ICONS.duplicate}
              style={ICONStyle}
              onClick={handleOpenDuplicate}
              alt="duplicate.icon"
            />
          </DrugIconPopup>
        )}

        {!!props.item.claim_payload?.payloads?.find(
          (payload: any) => payload.product_id === props.item.product
        ) && (
          <DrugIconPopup
            textContent={"ข้อมูลการตอบคำถาม"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <QAStatus item={props.item} onClick={handleOpenDrugClaim} />
          </DrugIconPopup>
        )}

        {pregcat && pregcat !== "NA" && props.isFemale && (
          <DrugIconPopup
            textContent={`Pregnancy Category: ${pregcat}`}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <img
              src={ICONS.pregnant}
              style={ICONStyle}
              onClick={handleOpenPregnant}
              alt="pregnant.icon"
            />
          </DrugIconPopup>
        )}

        {alerts["N"] && (
          <DrugIconPopup
            textContent={"ข้อมูลบันทึกข้อความ"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <img
              src={ICONS.group_message}
              style={ICONStyle}
              onClick={handleOpenOrderNotes}
              alt="group_message.icon"
            />
          </DrugIconPopup>
        )}

        {alerts["X"] && (
          <DrugIconPopup
            textContent={"ข้อมูลการปฏิเสธการสั่งยา"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <img
              src={ICONS.group_message}
              style={ICONStyle}
              onClick={handleOpenRejectNotes}
              alt="group_message.icon"
            />
          </DrugIconPopup>
        )}

        {alerts["O"] && (
          <DrugIconPopup textContent={"Overdose"} config={{ offset: -10 }} onOpen={handlePopupOpen}>
            <Label color="orange" size="mini" style={{ margin: ICON_MARGIN }}>
              <Icon name="angle double up" style={{ margin: "0px 0.142857em" }} />
            </Label>
          </DrugIconPopup>
        )}

        {props.item.limited_age === "H" && (
          <Popup
            content={
              <div>
                อายุสูงกว่าช่วงที่กำหนด
                <br />
                เหตุผลแพทย์: {props.item.note_limited_age}
              </div>
            }
            trigger={
              <svg
                width="40"
                height="20"
                viewBox="0 0 68 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M56.5004 2.875L63.9754 10.35H59.9504V19.55H53.0504V10.35H49.0254L56.5004 2.875Z"
                  fill="#F2711C"
                />
                <path
                  d="M14.4785 7.48633L10.8066 19H7.4082L12.6719 4.78125H14.8203L14.4785 7.48633ZM17.5254 19L13.8438 7.48633L13.4629 4.78125H15.6406L20.9238 19H17.5254ZM17.3691 13.707V16.168H9.95703V13.707H17.3691ZM33.3652 11.4902V17.2227C33.1309 17.4896 32.7728 17.776 32.291 18.082C31.8158 18.388 31.207 18.6517 30.4648 18.873C29.7227 19.0879 28.8372 19.1953 27.8086 19.1953C26.8776 19.1953 26.0312 19.0456 25.2695 18.7461C24.5078 18.4401 23.8535 17.9941 23.3066 17.4082C22.7598 16.8158 22.3366 16.0931 22.0371 15.2402C21.7441 14.3874 21.5977 13.4108 21.5977 12.3105V11.4707C21.5977 10.3704 21.7441 9.39388 22.0371 8.54102C22.3301 7.68815 22.7435 6.96875 23.2773 6.38281C23.8112 5.79036 24.446 5.34115 25.1816 5.03516C25.9173 4.72917 26.7311 4.57617 27.623 4.57617C28.8926 4.57617 29.931 4.78125 30.7383 5.19141C31.5456 5.59505 32.1576 6.15495 32.5742 6.87109C32.9974 7.58724 33.2578 8.41081 33.3555 9.3418H30.2891C30.224 8.86654 30.0938 8.45638 29.8984 8.11133C29.7096 7.76628 29.4395 7.5026 29.0879 7.32031C28.7363 7.13151 28.2806 7.03711 27.7207 7.03711C27.2715 7.03711 26.8646 7.13151 26.5 7.32031C26.1419 7.5026 25.8392 7.7793 25.5918 8.15039C25.3444 8.52148 25.1556 8.98372 25.0254 9.53711C24.8952 10.084 24.8301 10.722 24.8301 11.4512V12.3105C24.8301 13.0397 24.8952 13.681 25.0254 14.2344C25.1621 14.7878 25.3607 15.25 25.6211 15.6211C25.8815 15.9857 26.2103 16.2624 26.6074 16.4512C27.0111 16.64 27.4798 16.7344 28.0137 16.7344C28.4303 16.7344 28.7819 16.7018 29.0684 16.6367C29.3548 16.5651 29.5892 16.4772 29.7715 16.373C29.9538 16.2624 30.0905 16.1615 30.1816 16.0703V13.707H27.6523V11.4902H33.3652ZM45.2109 16.5391V19H37.6426V16.5391H45.2109ZM38.7266 4.78125V19H35.543V4.78125H38.7266ZM44.2344 10.5039V12.8867H37.6426V10.5039H44.2344ZM45.2207 4.78125V7.25195H37.6426V4.78125H45.2207Z"
                  fill="#F2711C"
                />
              </svg>
            }
          />
        )}
        {props.item.limited_age === "L" && (
          <Popup
            content={
              <div>
                อายุต่ำกว่าช่วงที่กำหนด
                <br />
                เหตุผลแพทย์: {props.item.note_limited_age}
              </div>
            }
            trigger={
              <svg
                width="40"
                height="20"
                viewBox="0 0 68 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M56.5001 20.125L49.0251 12.65H53.0501V3.45H59.9501V12.65H63.9751L56.5001 20.125Z"
                  fill="#F2711C"
                />
                <path
                  d="M14.4785 7.48633L10.8066 19H7.4082L12.6719 4.78125H14.8203L14.4785 7.48633ZM17.5254 19L13.8438 7.48633L13.4629 4.78125H15.6406L20.9238 19H17.5254ZM17.3691 13.707V16.168H9.95703V13.707H17.3691ZM33.3652 11.4902V17.2227C33.1309 17.4896 32.7728 17.776 32.291 18.082C31.8158 18.388 31.207 18.6517 30.4648 18.873C29.7227 19.0879 28.8372 19.1953 27.8086 19.1953C26.8776 19.1953 26.0312 19.0456 25.2695 18.7461C24.5078 18.4401 23.8535 17.9941 23.3066 17.4082C22.7598 16.8158 22.3366 16.0931 22.0371 15.2402C21.7441 14.3874 21.5977 13.4108 21.5977 12.3105V11.4707C21.5977 10.3704 21.7441 9.39388 22.0371 8.54102C22.3301 7.68815 22.7435 6.96875 23.2773 6.38281C23.8112 5.79036 24.446 5.34115 25.1816 5.03516C25.9173 4.72917 26.7311 4.57617 27.623 4.57617C28.8926 4.57617 29.931 4.78125 30.7383 5.19141C31.5456 5.59505 32.1576 6.15495 32.5742 6.87109C32.9974 7.58724 33.2578 8.41081 33.3555 9.3418H30.2891C30.224 8.86654 30.0938 8.45638 29.8984 8.11133C29.7096 7.76628 29.4395 7.5026 29.0879 7.32031C28.7363 7.13151 28.2806 7.03711 27.7207 7.03711C27.2715 7.03711 26.8646 7.13151 26.5 7.32031C26.1419 7.5026 25.8392 7.7793 25.5918 8.15039C25.3444 8.52148 25.1556 8.98372 25.0254 9.53711C24.8952 10.084 24.8301 10.722 24.8301 11.4512V12.3105C24.8301 13.0397 24.8952 13.681 25.0254 14.2344C25.1621 14.7878 25.3607 15.25 25.6211 15.6211C25.8815 15.9857 26.2103 16.2624 26.6074 16.4512C27.0111 16.64 27.4798 16.7344 28.0137 16.7344C28.4303 16.7344 28.7819 16.7018 29.0684 16.6367C29.3548 16.5651 29.5892 16.4772 29.7715 16.373C29.9538 16.2624 30.0905 16.1615 30.1816 16.0703V13.707H27.6523V11.4902H33.3652ZM45.2109 16.5391V19H37.6426V16.5391H45.2109ZM38.7266 4.78125V19H35.543V4.78125H38.7266ZM44.2344 10.5039V12.8867H37.6426V10.5039H44.2344ZM45.2207 4.78125V7.25195H37.6426V4.78125H45.2207Z"
                  fill="#F2711C"
                />
              </svg>
            }
          />
        )}

        {openClaimQA && (
          <CardDrugClaimQA
            // data
            estimate={props.estimate}
            stateKey={props.stateKey}
            orderType={props.orderType}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            selectedProgressCycle={props.selectedProgressCycle}
            statusRequest={props.statusRequest}
            // callback
            onCloseQA={props.stateKey === "drugResuscitation" ? () => props.onCloseQA() : ""}
            onSaveQA={props.stateKey === "drugResuscitation" ? (e: any) => props.onSaveQA(e) : ""}
            onEvent={props.onEvent}
            onSave={
              props.saveClaimByEvent
                ? null
                : (claim_payload: any, index: number) =>
                    props.onDrugClaim?.({
                      item: { ...props.item, claim_payload },
                      questionIndex: index,
                      itemIndex: props.index,
                      isEdit: true,
                    })
            }
            languageUX={props.languageUX}
          />
        )}
        {/* Drug Lab Interaction */}
        {props.item.note_drug_lab_interaction && (
          <DrugIconPopup
            open={!openNote ? undefined : openNote}
            textContent={
              <>
                <div>Drug-Lab interaction</div>
                <div>Doctor note: {props.item.note_drug_lab_interaction}</div>
              </>
            }
            config={{ offset: -10 }}
            // onOpen={handlePopupOpen}
          >
            <div>
              <Icon
                color="green"
                name="flask"
                style={{ ...ICONStyle, height: "1.35rem", fontSize: "1.15rem" }}
                onClick={() => {
                  props.onEvent({
                    message: "HandleModDrugLabInteraction",
                    params: {
                      action: "open",
                      data: { ...props.item, drugIndex: props.index },
                    },
                  });
                }}
              />
            </div>
          </DrugIconPopup>
        )}

        {/* Drug Disease */}
        {props.item.note_drug_disease && (
          <DrugIconPopup
            open={!openNote ? undefined : openNote}
            textContent={
              <>
                <div>Drug-Disease interaction</div>
                <div>Doctor note: {props.item.note_drug_disease}</div>
              </>
            }
            config={{ offset: -10 }}
            // onOpen={handlePopupOpen}
          >
            <img
              src={ICONS.alert_shield}
              style={ICONStyle}
              onClick={() => {
                props.onEvent({
                  message: "HandleModDrugDisease",
                  params: {
                    action: "open",
                    data: { ...props.item, drugIndex: props.index },
                  },
                });
              }}
              alt="alert_shield.icon"
            />
          </DrugIconPopup>
        )}

        {/* Syringe need */}
        {props.item.is_syringe_need && (
          <Icon
            color="green"
            inverted
            circular
            name="syringe"
            style={{ margin: "0px 0.142857em", fontSize: "0.75rem" }}
          />
        )}

        {/* Taper drug */}
        {props.item.start_date && (
          <Popup
            content={
              <TaperPopup
                startDate={adToBe(props.item.start_date, "YYYY-MM-DD")}
                duration={props.item.duration}
                readOnly={props.stateKey !== "drugOrder"}
                // callback
                onChangeDate={(date: string) => {
                  handleChangeDate("TAPER", date);
                }}
              />
            }
            on="click"
            position="bottom left"
            pinned
            trigger={
              <img
                src={"/static/images/taper-schedule.png"}
                style={{
                  width: "1.5rem",
                  cursor: "pointer",
                  margin: "0px 0.142857em",
                }}
                alt="taper-schedule.icon"
              />
            }
          />
        )}

        <ModInfo
          open={!!openModInfo}
          titleName={"แจ้งเตือน!!!"}
          size={openModInfo?.size ?? "mini"}
          color={openModInfo?.color}
          // @ts-ignore
          onApprove={handleCloseDuplicate}
        >
          <div
            className="ui"
            style={{
              whiteSpace: "pre-wrap",
              lineHeight: 1.75,
              textAlign: openModInfo?.align ?? "left",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: openModInfo?.content || "" }} />
          </div>
        </ModInfo>
      </div>
    </>
  );
};

const NotePopup: React.FunctionComponent<any> = (props) => {
  const intl = useIntl();
  const [note, setNote] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const inputRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    setNote(props.value);

    setTimeout(() => {
      inputRef.current.inputRef.current.focus();
    }, 100);
  }, []);

  const handleChangeValue = (e: any, v: InputProps) => {
    setNote(v.value);
  };

  const handleSave = () => {
    if (note) {
      props.onSave?.(note);
    } else {
      setErrorMessage("กรุณาระบุข้อมูลใน หมายเหตุการใช้ยา");
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Icon
          name="close"
          color="red"
          style={{ margin: "-8px -8px 8px", cursor: "pointer" }}
          onClick={props.onClose}
        />
      </div>
      <ErrorMessage error={errorMessage} style={{ marginTop: "5px" }} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <label>{intl.formatMessage({ id: "หมายเหตุ" })}</label>
        <Input
          ref={inputRef}
          style={{ margin: "0 1rem" }}
          value={note}
          onChange={handleChangeValue}
          error={!!errorMessage}
          onKeyDown={handleKeyDown}
        />
        <Button color="green" onClick={handleSave}>{intl.formatMessage({ id: "บันทึก" })}</Button>
      </div>
    </>
  );
};

const CLAIM_RESULT_ICON: Record<number, string> = {
  1: "check green", // เบิกได้'
  2: "warning sign yellow", // เบิกไม่ได้ เนื่องจากตอบคำถามไม่ครบ'
  3: "remove red", // เบิกไม่ได้ เนื่องจากตอบคำถามไม่ตรงตามเงื่อนไข'
  4: "minus gray", // เบิกไม่ได้ โดยตัวผลิตภัณฑ์'
};

const QAStatus: React.FunctionComponent<any> = (props) => {
  const warningIcon = useMemo(() => {
    let warningIcon = "";
    // if (props.item["drug_list"]) {
    if (CLAIM_RESULT_ICON[props.item["claim_result"]]) {
      const claimIcon = CLAIM_RESULT_ICON[props.item["claim_result"]];
      warningIcon = `corner ${claimIcon} icon`;
    } else {
      warningIcon = "corner minus gray icon";
    }
    // }
    // console.log("props.item", props.item, warningIcon);

    return warningIcon;
  }, [props.item]);

  const handleClick = (e: SyntheticEvent) => {
    props.onClick?.(e);
  };

  return (
    <div
      style={{ position: "relative", width: "fit-content", cursor: "pointer" }}
      onClick={handleClick}
    >
      <Icon name="file outline" color="blue" style={{ fontSize: "1.175rem" }} />
      <div style={{ position: "absolute", top: "0", right: "0" }}>
        <Icon className={warningIcon} style={{ fontSize: "0.575rem" }} />
      </div>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                       TaperPopup;                      */

/* ------------------------------------------------------ */
// Types
type TaperPopupProps = {
  // data
  startDate?: string;
  duration?: string | number;
  // config
  readOnly?: boolean;
  // callback
  onChangeDate?: (date: string) => any;
};
export const TaperPopup = (props: TaperPopupProps) => {
  return (
    <div
      style={{
        transform: "scale(0.95)",
        margin: "-0.15rem -1.5rem -0.15rem -0.5rem",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {/* @ts-ignore */}
      <Form>
        <Form.Group className="noMargin" inline>
          <Form.Field inline>From</Form.Field>
          <Form.Field inline>
            <DateTextBox
              inputRef={(instance: any) =>
                instance &&
                props.readOnly &&
                ((instance.ref.inputRef.current.style.backgroundColor = "rgba(131,129,129,0.45)"),
                (instance.ref.inputRef.current.style.opacity = "1"),
                (instance.ref.inputRef.current.style.fontWeight = "bold"))
              }
              value={props.startDate}
              disabled={props.readOnly}
              style={{ width: "135px" }}
              inputStyle={{ width: "135px" }}
              onChange={props.onChangeDate}
            />
          </Form.Field>
          <Form.Field inline>To</Form.Field>
          <Form.Field inline>
            <DateTextBox
              inputRef={(instance: any) =>
                instance &&
                ((instance.ref.inputRef.current.style.backgroundColor = "rgba(131,129,129,0.45)"),
                (instance.ref.inputRef.current.style.opacity = "1"),
                (instance.ref.inputRef.current.style.fontWeight = "bold"))
              }
              value={
                props.startDate
                  ? formatDate(
                      beToAd(props.startDate)?.add(
                        props.duration ? Number(props.duration) - 1 : 0,
                        "days"
                      )
                    )
                  : ""
              }
              disabled={true}
              style={{ width: "135px" }}
              inputStyle={{ width: "135px" }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
};

export default SubDrugOrderStatusAlert;
