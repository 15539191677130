import React from 'react'
import CardRehabExamination from "react-lib/apps/HISV3/RHB/CardRehabExamination";

type CardRehabExamProps = {
  onEvent: any;
  masterOptions: any;
  controller: any;
  searchedItemListWithKey: any;
  rehabExamPlan: any;
  setProp: any;
}


const CardRehabExam = (props: CardRehabExamProps) => {
  console.log("CardRehabExam props.controller : ", props.controller );
  return (
    <CardRehabExamination 
      onEvent={props.onEvent}
      masterOptions={props.masterOptions}
      searchedItemListWithKey={props.searchedItemListWithKey}
      controller={props.controller}
      rehabExamPlan={props.rehabExamPlan}
      setProp={props.setProp}
    />
  )
}

export default CardRehabExam