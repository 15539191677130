import React, {
  CSSProperties,
  ChangeEvent,
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { ButtonProps, Menu } from "semantic-ui-react";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModInfo from "react-lib/apps/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import CardUploadPatientCoverageUX from "./CardUploadPatientCoverageUX";
import TableUploadExcel from "./TableUploadExcel";
import { useIntl } from "react-intl";

// Interface
import {
  ACTIONS,
  BTN_ACTS,
  CURRENT_YEAR,
  CoverageGroupKeys,
  DetailType,
  PickedProps,
  RunSequence,
  SetProp,
  State,
  TABLE_HEADERS,
  TAB_KEYS,
  TAB_MENUS,
  TabMenuKeys,
} from "./sequence/UploadPatientCoverage";

// Types
type CardUploadPatientCoverageProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  UploadPatientCoverageSequence: State["UploadPatientCoverageSequence"];
} & PickedProps;

// Const
const styles = {
  badgeCount: {
    alignItems: "center",
    color: "white",
    display: "flex",
    fontSize: "1.55rem",
    fontWeight: "bold",
    height: "100%",
    margin: "0 -1.05rem 0 1rem",
    padding: "0.4rem",
  } as CSSProperties,
  downloadBtn: {
    position: "absolute",
    right: "1rem",
    top: 0,
  } as CSSProperties,
};

// Const
const CARD_UPLOAD_PATIENT_COVERAGE = "CardUploadPatientCoverage";

const CardUploadPatientCoverage = (props: CardUploadPatientCoverageProps) => {
  const intl = useIntl();
  // Ref
  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "UploadPatientCoverage", restart: true });

    return () => {
      props.runSequence({ sequence: "UploadPatientCoverage", clear: true });

      props.setProp(`errorMessage.${CARD_UPLOAD_PATIENT_COVERAGE}`, null);
    };
  }, []);

  // Use Memo
  const activeTab = useMemo(
    () => props.UploadPatientCoverageSequence?.activeTab,
    [props.UploadPatientCoverageSequence?.activeTab]
  );

  const coverageGroup = useMemo(
    () => props.UploadPatientCoverageSequence?.coverageGroup,
    [props.UploadPatientCoverageSequence?.coverageGroup]
  );

  const yearOptions = useMemo(() => {
    const years = Array.from({ length: 111 })
      .fill("")
      .map((_, index) => CURRENT_YEAR - 100 + index);

    return years.map((year) => ({ key: year, text: year, value: year }));
  }, []);

  // Handler
  const handleChangeTab = (tab: TabMenuKeys) => () => {
    props.setProp("UploadPatientCoverageSequence.activeTab", tab);
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (!file) {
      console.warn("errorMessage", "Please select a file.");

      return;
    }

    props.runSequence({ sequence: "UploadPatientCoverage", action: ACTIONS.CHANGE_FILE, file });

    fileRef.current.value = "";
  };

  const handleChooseFile = () => {
    fileRef.current.click();
  };

  const handleClearFile = () => {
    props.runSequence({
      sequence: "UploadPatientCoverage",
      action: ACTIONS.CLEAR_FILE,
    });
  };

  const handleUpload = () => {
    props.runSequence({
      sequence: "UploadPatientCoverage",
      action: ACTIONS.UPLOAD,
      card: CARD_UPLOAD_PATIENT_COVERAGE,
    });
  };

  const handleChangeCoverage = (
    e: SyntheticEvent,
    data: { name: CoverageGroupKeys } & ButtonProps
  ) => {
    props.runSequence({
      sequence: "UploadPatientCoverage",
      action: ACTIONS.CHANGE_COVERAGE,
      name: data.name,
    });
  };

  const handleChangeValue = (e: SyntheticEvent, data: { name: keyof DetailType; value: any }) => {
    const { name, value } = data;
    const detail = { ...props.UploadPatientCoverageSequence };

    detail[name] = value;

    props.setProp("UploadPatientCoverageSequence", { ...detail });
  };

  const handleDownload = () => {
    props.runSequence({
      sequence: "UploadPatientCoverage",
      action: ACTIONS.DOWNLOAD_TEMPLATE,
      card: CARD_UPLOAD_PATIENT_COVERAGE,
    });
  };

  const handleCloseModError = ()=> {
    props.setProp("UploadPatientCoverageSequence.error_summary_list", []);
  }

  // #console.log("CardUploadPatientCoverage", props);

  return (
    <div style={{ width: "calc(100vw - 150px)" }}>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_UPLOAD_PATIENT_COVERAGE]}
        success={null}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_UPLOAD_PATIENT_COVERAGE}`, null);
        }}
        languageUX={props.languageUX}
      />

      <CardUploadPatientCoverageUX
        // data
        activeTab={activeTab}
        coverageGroup={coverageGroup}
        fileName={props.UploadPatientCoverageSequence?.excelFile?.name}
        fiscalYearId={props.UploadPatientCoverageSequence?.fiscalYearId}
        maxReimb={props.UploadPatientCoverageSequence?.maxReimb}
        // options
        yearOptions={yearOptions}
        // callback
        onChangeCoverage={handleChangeCoverage}
        onChangeValue={handleChangeValue}
        onChooseFile={handleChooseFile}
        onClearFile={handleClearFile}
        onUpload={handleUpload}
        // Element
        buttonDownload={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"blue"}
            iconRight="download"
            name={ACTIONS.DOWNLOAD_TEMPLATE}
            // data
            paramKey={BTN_ACTS.DOWNLOAD_TEMPLATE}
            size="medium"
            style={styles.downloadBtn}
            title="Download Template"
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DOWNLOAD_TEMPLATE]}
            onClick={handleDownload}
          />
        }
        tabPans={
          <Menu style={{ height: "40px", marginBottom: "1rem" }} tabular>
            {[TAB_KEYS.UPLOAD, TAB_KEYS.SUCCESS, TAB_KEYS.FAIL].map((key, index) => {
              const menu = TAB_MENUS[key];

              return (
                <Menu.Item
                  key={`menu-${key}`}
                  active={activeTab === key}
                  onClick={handleChangeTab(key as TabMenuKeys)}
                  style={{
                    ...(activeTab === key && {
                      backgroundColor: "#2185D0",
                      color: "white",
                    }),
                    padding: "0 1.5em",
                  }}
                >
                  {menu.title}{" "}
                  {menu.bg && !!props.UploadPatientCoverageSequence?.[menu.key]?.length && (
                    <div
                      style={{
                        backgroundColor: menu.bg,
                        ...styles.badgeCount,
                      }}
                    >
                      {props.UploadPatientCoverageSequence[menu.key]?.length}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </Menu>
        }
        languageUX={props.languageUX}
      />

      <input ref={fileRef} accept=".xlsx" type="file" hidden onChange={handleChangeFile} />

      <div style={{ margin: "-1rem 1rem 0" }}>
        {coverageGroup && (
          <TableUploadExcel
            displayError={activeTab === TAB_KEYS.FAIL}
            headers={TABLE_HEADERS[coverageGroup] as any}
            data={
              activeTab ? props.UploadPatientCoverageSequence?.[TAB_MENUS[activeTab].key] || [] : []
            }
            style={{
              height:
                activeTab === TAB_KEYS.UPLOAD
                  ? "calc(100dvh - 17.25rem)"
                  : "calc(100dvh - 13.5rem)",
            }}
            languageUX={props.languageUX}
          />
        )}
      </div>

      <ModInfo
        buttonStyle={{ display: "none" }}
        open={props.buttonLoadCheck?.[BTN_ACTS.UPLOAD]}
        style={{ minWidth: "37rem" }}
        titleColor={"red"}
      >
        <div style={{ fontWeight: "bold", lineHeight: 2, padding: "2rem 0" }}>
          <div>{intl.formatMessage({ id: "กรุณารอสักครู่ระบบกำลังอัพโหลดข้อมูล" })}</div>
          <div>{intl.formatMessage({ id: "ห้ามปิดหน้าต่างจนกว่าระบบจะประมวลผลเสร็จสิ้น" })}</div>
        </div>
      </ModInfo>

      <ModInfo
        btnText="ปิดหน้าต่าง"
        open={!!props.UploadPatientCoverageSequence?.error_summary_list?.length}
        style={{ minWidth: "38rem" }}
        titleColor={"red"}
        titleName="แจ้งเตือน! พบรายการที่ Import ไม่ผ่าน"
        closeOnDimmerClick
        onApprove={handleCloseModError}
        onClose={handleCloseModError}
      >
        <div style={{ lineHeight: 2, padding: "1rem 0" }}>
          {props.UploadPatientCoverageSequence?.error_summary_list?.map((message) => (
            <div key={message}>{message}</div>
          ))}
        </div>
      </ModInfo>
    </div>
  );
};

CardUploadPatientCoverage.displayName = "CardUploadPatientCoverage";

export default React.memo(CardUploadPatientCoverage);
