import {
  HeaderPatientDetail,
  HeaderPrintList,
} from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

const color = {
  greyHeader: "#D9D9D9",
  greySummary: "#E8E8E8",
  greyBorder: "#9F9F9F",
};

type FormAdmissionProps = {
  detail?: any;
  admission_form?: any;
  systemicReview?: any;
  physicalExamination?: any;
  initial_investigation?: any;
  problemList?: any;
  provisional_diagnosis?: any;
  assessment_plan?: any;
  //
  arriveStatus?: string;
  chiefComplaint?: string;
  triageLevel?: string;
  pregnancyStatue?: string;
  underlyingDisease?: string;
  allergy?: string;
  vitalSign?: any;
  bodyTemperature?: any;
  painScore?: any;
  tobacco?: string;
  alcohol?: string;
  curentMedication?: string;
  infoReliability?: string;
  //
  printDate?: string;
  userName?: string;
};

const FORM_NAME = `FormAdmission`;
const LOGO_HEIGHT = 30;
const FONT_SIZE = 14;

const FormAdmission = async (props: FormAdmissionProps) => {
  const headerForm = await HeaderPrintList({
    font: "THSarabunNew",
    form: FORM_NAME,
    formName: "ADMISSION NOTE",
    logoHeight: 38,
    pageMargins: [10, 108.5, 10, 25],
    titleContent: [],
    content: [],
    detail: { ...props.detail, divisionName: "" },
  });

  const { font, fonts, fontSizes, images, lineHeights, styles } = headerForm;

  return {
    pageSize: "A4",
    ...headerForm,
    defaultStyle: {
      font,
      fontSize: fontSizes[FONT_SIZE],
      lineHeight: lineHeights[1],
    },
    styles: {
      ...styles,
      facultyHeader: {
        fontSize: "16",
        bold: true,
      },
    },
    content: [
      {
        margin: [0, 10, 0, 0],
        stack: [
          {
            margin: [25, 0, 15, 0],
            columns: [
              {
                width: "33.33%",
                columns: [
                  {
                    width: "50%",
                    text: "Arrival Status: ",
                    bold: true,
                  },
                  {
                    width: "30%",
                    text: props.arriveStatus,
                    alignment: "center",
                  },
                ],
              },
              {
                width: "33.33%",
                columns: [
                  {
                    width: "50%",
                    text: "Triage Level: ",
                    bold: true,
                    alignment: "center",
                  },
                  {
                    width: "30%",
                    text: props.triageLevel,
                    alignment: "center",
                  },
                ],
              },
              {
                width: "33.33%",
                columns: [
                  {
                    width: "50%",
                    text: "Pregancy: ",
                    bold: true,
                    alignment: "center",
                  },
                  {
                    width: "30%",
                    text: props.pregnancyStatue,
                    alignment: "center",
                  },
                ],
              },
            ],
          },
          {
            margin: [25, 0, 15, 0],
            columns: [
              {
                width: "18%",
                text: "Underlying disease: ",
                bold: true,
              },
              {
                width: "82%",
                text: props.underlyingDisease,
              },
            ],
          },
          {
            margin: [25, 0, 15, 0],
            columns: [
              {
                width: "8%",
                text: "Allergy: ",
                bold: true,
              },
              {
                width: "92%",
                text: props.allergy,
              },
            ],
          },
          {
            margin: [25, 0, 15, 0],
            text: [
              {
                text: [
                  {
                    text: "BW - ",
                    bold: true,
                  },
                  {
                    text: `${props.vitalSign?.BodyWeight || "-"} Kg. `,
                  },
                ],
              },
              {
                text: [
                  {
                    text: "HT - ",
                    bold: true,
                  },
                  {
                    text: `${props.vitalSign?.Height} cm. `,
                  },
                ],
              },
              {
                text: [
                  {
                    text: "BMI - ",
                    bold: true,
                  },
                  {
                    text: `${props.vitalSign?.BMI}`,
                  },
                ],
              },
              {
                text: [
                  {
                    text: "T - ",
                    bold: true,
                  },
                  {
                    text: `${props.bodyTemperature?.result} C `,
                  },
                ],
              },
              {
                text: [
                  {
                    text: "BP - ",
                    bold: true,
                  },
                  {
                    text: `${props.vitalSign?.BP} mmHg `,
                  },
                ],
              },
              {
                text: [
                  {
                    text: "PR - ",
                    bold: true,
                  },
                  {
                    text: `${props.vitalSign?.Pulse}/min `,
                  },
                ],
              },
              {
                text: [
                  {
                    text: "RR - ",
                    bold: true,
                  },
                  {
                    text: `${props.vitalSign?.RR}/min `,
                  },
                ],
              },
              {
                text: [
                  {
                    text: "O2 - ",
                    bold: true,
                  },
                  {
                    text: `${props.vitalSign?.O2SatRA} `,
                  },
                ],
              },
              {
                text: [
                  {
                    text: "Pain score - ",
                    bold: true,
                  },
                  {
                    text: `${props.painScore?.result} `,
                  },
                ],
              },
            ],
          },
          {
            margin: [25, 0, 15, 0],
            columns: [
              {
                width: "8%",
                text: "Smoking: ",
                bold: true,
              },
              {
                width: "10%",
                text: props.tobacco,
              },
              {
                width: "8%",
                text: "Alcohol: ",
                bold: true,
              },
              {
                width: "10%",
                text: props.alcohol,
              },
            ],
          },
          {
            margin: [25, 0, 15, 0],
            columns: [
              {
                width: "18%",
                text: "Current Medication: ",
                bold: true,
              },
              {
                width: "82%",
                text: props.curentMedication,
              },
            ],
          },
          {
            margin: [5, 0, 5, 0],
            marginTop: 5,
            table: {
              widths: ["100%"],
              body: [[{ border: [false, true, false, false], text: "" }]],
            },
            layout: {
              hLineColor: () => "#606060",
              hLineWidth: () => 0.25,
              vLineWidth: () => 0,
              hLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
              vLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
            },
          },
          {
            margin: [25, 0, 15, 0],
            text: "Physician Note",
            style: "facultyHeader",
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Information:",
              },
              {
                width: "84%",
                text: props.admission_form?.information,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "ความน่าเชื่อถือได้:",
              },
              {
                width: "84%",
                text: props.infoReliability,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Chief Complaint:",
              },
              {
                width: "84%",
                text: props.admission_form?.chief_complaint,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Present Illness:",
              },
              {
                width: "84%",
                text: props.admission_form?.present_illness,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Pass Illness:",
              },
              {
                width: "84%",
                text: props.admission_form?.pass_illness,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Personal History:",
              },
              {
                width: "84%",
                text: props.admission_form?.personal_history,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Immunization:",
              },
              {
                width: "84%",
                text: props.admission_form?.immunization,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Family History:",
              },
              {
                width: "84%",
                text: props.admission_form?.family_history,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Social Condition:",
              },
              {
                width: "84%",
                text: props.admission_form?.social_condition,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Psychological Issue:",
              },
              {
                width: "84%",
                text: props.admission_form?.psychological_issue,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Sensitive Note:",
              },
              {
                width: "84%",
                text: props.admission_form?.sensitive_note,
              },
            ],
          },
          {
            margin: [5, 0, 5, 0],
            marginTop: 5,
            table: {
              widths: ["100%"],
              body: [[{ border: [false, true, false, false], text: "" }]],
            },
            layout: {
              hLineColor: () => "#606060",
              hLineWidth: () => 0.25,
              vLineWidth: () => 0,
              hLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
              vLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
            },
          },
          {
            stack: [
              {
                margin: [25, 0, 15, 0],
                text: "Systemic Review",
                style: "facultyHeader",
              },
              {
                margin: [35, 0, 15, 0],
                ul: [
                  props.systemicReview?.[0]?.[0],
                  props.systemicReview?.[0]?.[1],
                  props.systemicReview?.[1]?.[0],
                ],
              },
            ],
          },
          {
            margin: [5, 0, 5, 0],
            marginTop: 5,
            table: {
              widths: ["100%"],
              body: [[{ border: [false, true, false, false], text: "" }]],
            },
            layout: {
              hLineColor: () => "#606060",
              hLineWidth: () => 0.25,
              vLineWidth: () => 0,
              hLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
              vLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
            },
          },
          {
            stack: [
              {
                margin: [25, 0, 15, 0],
                text: "Physical Examination",
                style: "facultyHeader",
              },
              {
                margin: [35, 0, 15, 0],
                ul: props.physicalExamination.map((item: any) => {
                  return {
                    columns: [
                      {
                        width: "16%",
                        text: `${item.organName}: `,
                      },
                      {
                        width: "84%",
                        text: item.description,
                      },
                    ],
                  };
                }),
              },
            ],
          },
          {
            margin: [5, 0, 5, 0],
            marginTop: 5,
            table: {
              widths: ["100%"],
              body: [[{ border: [false, true, false, false], text: "" }]],
            },
            layout: {
              hLineColor: () => "#606060",
              hLineWidth: () => 0.25,
              vLineWidth: () => 0,
              hLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
              vLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
            },
          },
          {
            stack: [
              {
                margin: [25, 0, 15, 0],
                text: "Initial Investigation",
                style: "facultyHeader",
              },
              {
                margin: [35, 0, 15, 0],
                text: props.initial_investigation?.investigation_detail,
              },
            ],
          },
          {
            margin: [5, 0, 5, 0],
            marginTop: 5,
            table: {
              widths: ["100%"],
              body: [[{ border: [false, true, false, false], text: "" }]],
            },
            layout: {
              hLineColor: () => "#606060",
              hLineWidth: () => 0.25,
              vLineWidth: () => 0,
              hLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
              vLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
            },
          },
          {
            stack: [
              {
                margin: [25, 0, 15, 0],
                text: "Problem List",
                style: "facultyHeader",
              },
              {
                margin: [35, 0, 15, 0],
                ol: props.problemList,
              },
            ],
          },
          {
            margin: [5, 0, 5, 0],
            marginTop: 5,
            table: {
              widths: ["100%"],
              body: [[{ border: [false, true, false, false], text: "" }]],
            },
            layout: {
              hLineColor: () => "#606060",
              hLineWidth: () => 0.25,
              vLineWidth: () => 0,
              hLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
              vLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
            },
          },
          {
            stack: [
              {
                margin: [25, 0, 15, 0],
                text: "Provisional Diagnosis",
                style: "facultyHeader",
              },
              {
                margin: [35, 0, 15, 0],
                text: "Primary Diagnosis",
              },
              {
                margin: [45, 0, 15, 0],
                text: `${props.provisional_diagnosis?.primary?.icd10_code} ${props.provisional_diagnosis?.primary?.icd10_term}`,
              },
              {
                margin: [35, 0, 15, 0],
                text: "Secondary Diagnosis",
              },
              {
                margin: [45, 0, 15, 0],
                ul: props.provisional_diagnosis?.secondary.map((item: any) => {
                  return {
                    text: `${item?.icd10_code} ${item?.icd10_term}`,
                  };
                }),
              },
            ],
          },
          {
            margin: [5, 0, 5, 0],
            marginTop: 5,
            table: {
              widths: ["100%"],
              body: [[{ border: [false, true, false, false], text: "" }]],
            },
            layout: {
              hLineColor: () => "#606060",
              hLineWidth: () => 0.25,
              vLineWidth: () => 0,
              hLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
              vLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
            },
          },
          {
            margin: [25, 0, 15, 0],
            text: "Assessment Plan",
            style: "facultyHeader",
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Investigation Plan:",
              },
              {
                width: "84%",
                text: props.assessment_plan?.investigation_plan,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Treatment Plan:",
              },
              {
                width: "84%",
                text: props.assessment_plan?.treatment_plan,
              },
            ],
          },
          {
            margin: [35, 0, 25, 0],
            columns: [
              {
                width: "16%",
                text: "Patient Education:",
              },
              {
                width: "84%",
                text: props.assessment_plan?.patient_education,
              },
            ],
          },
        ],
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [0, 0, 0, 0],
        stack: [
          {
            table: {
              widths: ["100%"],
              body: [
                [
                  {
                    columns: [
                      {
                        width: "80%",
                        margin: [10, 0, 0, 0],
                        alignment: "left",
                        text: [
                          {
                            text: "วันเวลาที่พิมพ์เอกสาร ",
                          },
                          {
                            text: props.printDate,
                            italics: true,
                          },
                          {
                            text: " โดย ",
                          },
                          {
                            text: props.userName,
                          },
                        ],
                      },
                      {
                        width: "20%",
                        margin: [0, 0, 10, 0],
                        alignment: "right",
                        text: `หน้า ${currentPage.toString()}/${pageCount.toString()}`,
                      },
                    ],
                    border: [false, true, false, false],
                  },
                ],
              ],
            },
            layout: {
              hLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
              vLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
            },
          },
        ],
      };
    },
    images: {
      ...images,
    },
  };
};

export default FormAdmission;
