import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Checkbox,
  TextArea
} from 'semantic-ui-react'

const ModReprintDrugOrderUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{ backgroundColor: "#2185D0", color: "white", fontSize: "1.2rem", padding: "10px"}}>
          ยืนยัน Reprint ?
        </div>
        <div
          style={{padding: "10px", display: "flex", flexDirection: "column"}}>

          <div
            style={{ display: "flex",  flexDirection: "column", justifyContent: "center"}}>

            <Checkbox
              checked={props.printMedicine}
              label="ใบรับยาสำหรับพยาบาล"
              onChange={props?.handleChangeChecked("printMedicine")}>
            </Checkbox>
            <Checkbox
              checked={props?.printPharma}
              label="ใบสรุปรายการสั่งยาของเภสัชกร"
              onChange={props?.handleChangeChecked("printPharma")}
              style={{marginTop: "5px"}}>
            </Checkbox>
            <Checkbox
              checked={props?.printPatient}
              label="ใบสรุปรายการสั่งยาของผู้ป่วย"
              onChange={props?.handleChangeChecked("printPatient")}
              style={{marginTop: "5px"}}>
            </Checkbox>
            <Checkbox
              checked={props?.printLabel}
              label="ฉลากยา"
              onChange={props?.handleChangeChecked("printLabel")}
              style={{marginTop: "5px"}}>
            </Checkbox>
            <Checkbox
              checked={props?.printMedReport}
              label="ใบแสดงรายการยากลับบ้าน"
              onChange={props?.handleChangeChecked("printMedReport")}
              style={{marginTop: "5px", display: props.isHomeMed ? "" : "none" }}>
            </Checkbox>
          </div>
          <div
            style={{marginTop:"10px"}}>

            <div>
              Note
            </div>
            <div
              style={{marginTop:"5px",marginBottom:"5px"}}>

              <TextArea
                onChange={props?.handleChangeValue("noteReprint")}
                style={{width:"100%"}}
                value={props?.noteReprint}>
              </TextArea>
            </div>
          </div>
          <div
            style={{display : "flex"}}>

            <div
              style={{marginTop:"10px"}}>
              ฉลากยาภาษา
            </div>
            <div
              style={{marginLeft :"10px"}}>
              {props?. language}
            </div>
          </div>
          <div
            style={{padding: "20px", display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>

            <Button
              className="basic"
              color="green"
              onClick={props.onConfirm}
              style={{width:"100px"}}>
              ใช่
            </Button>
            <Button
              className="basic"
              color="red"
              onClick={props.onCancel}
              style={{width:"100px"}}>
              ไม่
            </Button>
          </div>
          <div>
            {props?.loading}
          </div>
        </div>
      </div>
    )
}


export default ModReprintDrugOrderUX

export const screenPropsDefault = {"diDetail":"ทดสอบ","needNote":true}

/* Date Time : Wed Dec 25 2024 11:03:07 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยัน Reprint ?"
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#2185D0\", color: \"white\", fontSize: \"1.2rem\", padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", flexDirection: \"column\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  flexDirection: \"column\", justifyContent: \"center\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px\", display: \"flex\", alignItems: \"center\", justifyContent: \"space-evenly\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ใช่"
        },
        "className": {
          "type": "value",
          "value": "basic"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirm"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่"
        },
        "className": {
          "type": "value",
          "value": "basic"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.printPharma"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "ใบสรุปรายการสั่งยาของเภสัชกร"
        },
        "onChange": {
          "type": "code",
          "value": "props?.handleChangeChecked(\"printPharma\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"5px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.printPatient"
        },
        "label": {
          "type": "value",
          "value": "ใบสรุปรายการสั่งยาของผู้ป่วย"
        },
        "onChange": {
          "type": "code",
          "value": "props?.handleChangeChecked(\"printPatient\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"5px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.printLabel"
        },
        "label": {
          "type": "value",
          "value": "ฉลากยา"
        },
        "onChange": {
          "type": "code",
          "value": "props?.handleChangeChecked(\"printLabel\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"5px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "Note"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\",marginBottom:\"5px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "TextArea",
      "parent": 23,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props?.handleChangeValue(\"noteReprint\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.noteReprint"
        }
      },
      "seq": 24,
      "void": true
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display : \"flex\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ฉลากยาภาษา "
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "props?. language"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft :\"10px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.loading"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.printMedReport"
        },
        "label": {
          "type": "value",
          "value": "ใบแสดงรายการยากลับบ้าน"
        },
        "onChange": {
          "type": "code",
          "value": "props?.handleChangeChecked(\"printMedReport\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"5px\", display: props.isHomeMed ? \"\" : \"none\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.printMedicine"
        },
        "label": {
          "type": "value",
          "value": "ใบรับยาสำหรับพยาบาล"
        },
        "onChange": {
          "type": "code",
          "value": "props?.handleChangeChecked(\"printMedicine\")"
        }
      },
      "seq": 18,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 45,
  "isMounted": true,
  "memo": false,
  "name": "ModReprintDrugOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "diDetail": "ทดสอบ",
    "needNote": true
  },
  "width": 30
}

*********************************************************************************** */
