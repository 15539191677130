import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Icon,
  Button,
  Input,
  Dropdown,
  Checkbox,
  Popup,
  TextArea,
  Form,
} from "semantic-ui-react";
import "./TableTreatmentPlan.scss";
import { useState } from "react";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";

type TableTreatmentPlanProps = {
  departmentOptions: any[];
  data: any[];
  deleteDataIdx: (idx: number) => void;
  onChangeTableData: (idx: number, key: string, value: any) => void;
  onEvent: any;
  searchedItemListWithKey: any;
};

const TableTreatmentPlan = (props: TableTreatmentPlanProps) => {
  const [edit, setEdit] = useState<{ row: number; key: string } | null>(null);

  // console.log(" TableTreatmentPlan props: ", props)
  return (
    <Form
      onSubmit={(e) => {
        alert("onSubmit");
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Table id="TableTreatmentPlan" compact celled structured>
        <TableHeader className="rehab-treatment">
          <TableRow>
            <TableHeaderCell width={3} textAlign="center" rowSpan="2">
              Treatment
            </TableHeaderCell>
            <TableHeaderCell width={3} textAlign="center" rowSpan="2">
              Detail
            </TableHeaderCell>
            <TableHeaderCell width={1} textAlign="center" rowSpan="2">
              Image
            </TableHeaderCell>
            <TableHeaderCell width={4} textAlign="center" rowSpan="2">
              To Department <span style={{ color: "red" }}>*</span>
            </TableHeaderCell>
            <TableHeaderCell width={5} textAlign="center" rowSpan="2">
              ICD-09
            </TableHeaderCell>
            <TableHeaderCell width={1} textAlign="center" colSpan="2">
              {"Frequency (time/day)"}&nbsp;&nbsp;&nbsp;
              <Popup
                content="If you would like to set it independently, please specify in the detail field"
                trigger={<Icon name="info circle" color="blue" />}
              />
            </TableHeaderCell>
            <TableHeaderCell width={1} textAlign="center" rowSpan="2">
              {"Total (Time(s))"}
            </TableHeaderCell>
            <TableHeaderCell width={1} textAlign="center" rowSpan="2"></TableHeaderCell>
          </TableRow>
          <TableRow>
            <TableHeaderCell textAlign="center">{"Time(s)/week"}</TableHeaderCell>
            <TableHeaderCell textAlign="center">{"Duration(Week(s))"}</TableHeaderCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {props.data?.length === 0 || props.data === undefined ? (
            <TableCell textAlign="center" colSpan="9" style={{ color: "grey" }}>
              Please add treatment plan
            </TableCell>
          ) : null}
          {(props.data || []).map((item: any, idx: number) => (
            <TableRow key={idx}>
              <TableCell verticalAlign="top">{item?.productName}</TableCell>
              <TableCell
                verticalAlign="top"
                onClick={() => {
                  setEdit({ row: idx, key: "detail" });
                }}
                onBlur={() => {
                  setEdit(null);
                }}
              >
                {edit?.row === idx && edit?.key === "detail" ? (
                  <TextArea
                    rows={item?.detail?.split(/\r\n|\r|\n/)?.length}
                    fluid
                    value={item?.detail}
                    onChange={(e, data) => props.onChangeTableData(idx, "detail", data.value)}
                  />
                ) : (
                  <div style={{ whiteSpace: "pre" }}> {item?.detail} </div>
                )}
              </TableCell>
              <TableCell textAlign="center" verticalAlign="top">
                <Icon color="blue" size="large" name="image outline" onClick={() => {}} style={{ cursor: "pointer" }} />{" "}
              </TableCell>
              <TableCell verticalAlign="top">
                <Dropdown
                  selection
                  fluid
                  value={item?.to_department}
                  options={props.departmentOptions}
                  onChange={(e, data) => props.onChangeTableData(idx, "to_department", data.value)}
                />{" "}
              </TableCell>
              <TableCell verticalAlign="top">
                <div style={{ display: "flex", alignItems: "flex-end", gap: "1em", flexDirection:"column" }}>
                  { false ? `[${item.icd9Data.icdcode}] ${item.icd9Data.icdterm}`
                  : 
                   <SearchBoxWithKey
                   // callback
                   onEvent={props.onEvent}
                   type="ICD9CMCodeTermMedTerm"
                   id={`ICD9CMCodeTermMedTerm_${idx}`}
                   searchedItemListWithKey={props.searchedItemListWithKey}
                   selectedItem={item?.icd9Data?.icd10_id}
                  //  defaultText={item?.icd9Data?.icd10_id}
                   defaultText={ `${item?.icd9Data?.icd10_id ? `[${item?.icd9Data?.icdcode}]` : "" }${item?.icd9Data?.icdterm ? ` ${item?.icd9Data?.icdterm}` : ""}`}
                   setSelected={(value, key, data, action) => {
                     if (data) { 
                      //  props.onChangeTableData(idx, "icd9Name", value);
                       props.onChangeTableData(idx, "icd9Data", data);
                     } else {
                      //  props.onChangeTableData(idx, "icd9Name", "");
                       props.onChangeTableData(idx, "icd9Data", null);
                     }
           

                   }}
                   valueKey="icd10_id"
                   mapOptions={(items: any[]) => {
                     return items.filter(Boolean).map((item) => ({
                       key: item["icd10_id"],
                       value: item["icd10_id"],
                       text: `[${item.icdcode}] ${item.icdterm}`,
                     }));
                   }}
                 />
                  }
                 

                  <Checkbox
                    style={{ width: "fit-content" }}
                    label="is primary"
                    checked={item.icd9_type === "PRIMARY"}
                    disabled={!item?.icd9cm && !item?.icd9Data}
                    onChange={(e, data) => { 
                      props.onChangeTableData(idx, "icd9_type", data?.checked ? "PRIMARY" : "SECONDARY")} 
                    }
                  />
                </div>
              </TableCell>
              <TableCell textAlign="center" verticalAlign="top">
                <Input
                  style={{ width: "70px" }}
                  type="number"
                  min={0}
                  value={item?.frequency}
                  onChange={(e, data) => { 
                    if (item?.duration) {
                      let total = Number(data?.value) * Number(item?.duration)
                      props.onChangeTableData(idx, "total", Number(total))
                    }
                    props.onChangeTableData(idx, "frequency", Number(data?.value))
                  }}
                />
              </TableCell>
              <TableCell textAlign="center" verticalAlign="top">
                <Input
                  style={{ width: "70px" }}
                  type="number"
                  min={0}
                  value={item?.duration}
                  onChange={(e, data) => { 
                    if (item?.frequency) {
                      let total = Number(data?.value) * Number(item?.frequency)
                      props.onChangeTableData(idx, "total", Number(total))
                    }
                    props.onChangeTableData(idx, "duration", Number(data?.value))
                  }}
                />
              </TableCell>
              <TableCell textAlign="center" verticalAlign="top">
                <Input
                  style={{ width: "70px" }}
                  type="number"
                  min={0}
                  value={item?.total}
                  onChange={(e, data) => props.onChangeTableData(idx, "total", Number(data?.value))}
                />
              </TableCell>
              <TableCell textAlign="center" verticalAlign="top">
                {
                  <Button
                    type="button"
                    size="mini"
                    icon
                    color="red"
                    onClick={() => {
                      props.deleteDataIdx(idx);
                      console.log("Delete !!! ");
                    }}
                  >
                    <Icon name="minus" />
                  </Button>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Form>
  );
};

export default TableTreatmentPlan;
