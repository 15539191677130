import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Label,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Dropdown,
  Input
} from 'semantic-ui-react'

const CardPayingUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%", padding: "10px", marginTop:"-1rem" }}>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","rowGap":"5px","gridTemplateColumns":"repeat(8, 1fr)"}}>
          <div
            style={{ padding: "10px", gridRow: "1/2", gridColumn: "1/3",display: "none"  }}>
            <Label
              className="ribbon large title blue">
              {props.patientNameAndHN}
            </Label>
          </div>
          <div
            style={{ padding: "10px", gridRow: "1/2", gridColumn: "3/6",display: "none" }}>
            <Label
              className="large tag blue">
              {`${props.citizenID || "-"}`}
            </Label>
          </div>
          <div
            style={{ gridRow: "1/2", gridColumn: "7/9",display: "none"}}>
            <div
              style={{ fontSize: "1.2rem", paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
              รับชำระเงิน (Paying)
            </div>
          </div>
          <div
            style={{ gridRow: "3/4", gridColumn: "1/9", padding: "10px", display: "flex", margin: "0.5rem 0" }}>
            
            <Label
              className="ribbon large title blue">
              {props.patientNameAndHN}
            </Label>
            <Label
              className="large tag blue"
              style={{marginLeft: "5rem"}}>
              {`${props.citizenID || "-"}`}
            </Label>
            <div
              style={{flex:1}}>
              
            </div>
            <div
              style={{ fontSize: "1.2rem", paddingRight: "10px",  height: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
              รับชำระเงิน (Paying)
            </div>
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "1/2",  }}>
            
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold" }}>
              ค่าใช้จ่ายทั้งหมด
            </div>
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "2/4", margin: "2.5px 0"  }}>
            <div
              className="ui label large fluid"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              {props.sumAmount.price}
            </div>
          </div>
          <div
            style={{ gridRow: "5/6", gridColumn: "1/2",  }}>
            
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold"}}>
              เบิกได้
            </div>
          </div>
          <div
            style={{ gridRow: "5/6", gridColumn: "2/4", margin: "2.5px 0"  }}>
            <div
              className="ui label large fluid"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {props.sumAmount.reimbursable}
            </div>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "1/2",  }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold" }}>
              เบิกไม่ได้
            </div>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "2/4", margin: "2.5px 0"  }}>
            <div
              className="ui label large fluid"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {(props.sumAmount.cashonly || 0)}
            </div>
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "1/2",  }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold"}}>
              ส่วนลด
            </div>
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "2/4", margin: "2.5px 0"  }}>
            <div
              className="ui label large fluid"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {(props.sumAmount.discount || 0)}
            </div>
          </div>
          <div
            style={{ gridRow: "8/9", gridColumn: "1/2", }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold" }}>
              ต้องชำระ
            </div>
          </div>
          <div
            style={{ gridRow: "8/9", gridColumn: "2/4", margin: "2.5px 0"  }}>
            <div
              className="ui label large fluid"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {(props.sumAmount.payable || 0)}
            </div>
          </div>
          <div
            style={{ gridRow: "9/10", gridColumn: "1/2",  }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontWeight: "bold"}}>
              ค้างชำระ
            </div>
          </div>
          <div
            style={{ gridRow: "9/10", gridColumn: "2/4", margin: "2.5px 0" }}>
            <div
              className={`ui label large fluid ${Number(props.rawOweText) > Number(props.totalText) ? "red" : "green"}`}
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {props.oweText}
            </div>
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "4/5", }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              เงินสด
            </div>
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "5/7",  }}>
            <div
              className="ui input"
              style={{ width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              
              <input
                id="CardPaying-Input-CashAmount"
                onBlur={props.onBlurCashAmountText}
                onChange={props.onChangeCashAmountText}
                onKeyUp={props.onEnterCashAmountText}
                style={{  textAlign: "right" }}
                value={props.cashAmountText}>
              </input>
            </div>
          </div>
          <div
            style={{ gridRow: "5/6", gridColumn: "4/5", }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              เงินโอน/QR Code
            </div>
          </div>
          <div
            style={{ gridRow: "5/6", gridColumn: "5/7", margin: "2.5px 0" }}>
            <div
              className="ui label large fluid"
              id="txt-cashTransfer"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center",}}>
              {props.transferText}
            </div>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "4/5",  }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              บัตรเดบิต/บัตรเครดิต
            </div>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "5/7", margin: "2.5px 0"  }}>
            <div
              className="ui label large fluid"
              id="txt-cashCreditCard"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {props.cardText}
            </div>
          </div>
          <div
            style={{ gridRow: "8/9", gridColumn: "4/5", ...((props.isDeposit || props.isCudent) && {gridRow: "7/8"})}}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              แคชเชียร์เช็ค
            </div>
          </div>
          <div
            style={{ gridRow: "8/9", gridColumn: "5/7", margin: "2.5px 0", ...((props.isDeposit || props.isCudent) && {gridRow: "7/8"})  }}>
            <div
              className="ui label large fluid"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {props.chequeText}
            </div>
          </div>
          <div
            style={{ gridRow: "9/10", gridColumn: "4/5", ...((props.isDeposit || props.isCudent) && {gridRow: "8/9"})  }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              คูปองเงินสด
            </div>
          </div>
          <div
            style={{ gridRow: "9/10", gridColumn: "5/7", margin: "2.5px 0" , ...((props.isDeposit || props.isCudent) && {gridRow: "8/9"}) }}>
            <div
              className="ui label large fluid"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {props.couponText}
            </div>
          </div>
          <div
            style={{ gridRow: "11/12", gridColumn: "4/5", ...(props.isCudent && {gridRow: "9/10"}), ...(props.isDeposit && {gridRow: "10/11"})  }}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              รวม
            </div>
          </div>
          <div
            className="ui label large fluid"
            style={{ gridRow: "11/12", gridColumn: "5/7", ...(props.isCudent && {gridRow: "9/10"}), ...(props.isDeposit && {gridRow: "10/11"})}}>
            {props.totalText}
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "7/9", display: "flex", alignItems: "center" }}>
            <Button
              className="circular"
              color="green"
              icon="plus"
              size="mini"
              style={{opacity: 0, margin: "3px 10px", pointerEvents: "none"}}>
            </Button>
            <label
              style={{fontWeight: "bold"}}>
              ทอน
            </label>
            <div
              className="ui label large fluid"
              style={{marginLeft: "15px", textAlign: "right"}}>
              {props.changeText }
            </div>
          </div>
          <div
            style={{ gridArea: "5 / 7 / 6 / 9", display: "flex", alignItems: "center"}}>
            <Button
              className="circular"
              color="green"
              icon="plus"
              id="CardPaying-Button-AddPaymentMethod-2"
              onClick={()=> props.onAddPaymentMethod(2)}
              size="mini"
              style={{margin: "3px 10px"}}>
            </Button>
            <label
              style={{fontWeight: "bold", minWidth: "max-content"}}>
              ลดเศษสตางค์
            </label>
            <div
              className="ui label large fluid"
              style={{marginLeft: "15px", textAlign: "right"}}>
              {props.roundOffText || "\u00a0"}
            </div>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "7/8" }}>
            <Button
              className="circular"
              color="green"
              icon="plus"
              id="CardPaying-Button-AddPaymentMethod-3"
              onClick={()=> props.onAddPaymentMethod(3)}
              size="mini"
              style={{margin: "3px 10px"}}>
            </Button>
          </div>
          <div
            style={{ gridRow: "8/9", gridColumn: "7/8", ...((props.isDeposit || props.isCudent) && {gridRow: "7/8"}) }}>
            <Button
              className="circular"
              color="green"
              icon="plus"
              id="CardPaying-Button-AddPaymentMethod-4"
              onClick={()=> props.onAddPaymentMethod(4)}
              size="mini"
              style={{margin: "3px 10px"}}>
              
            </Button>
          </div>
          <div
            style={{ gridRow: "9/10", gridColumn: "7/8", ...((props.isDeposit || props.isCudent) && {gridRow: "8/9"})}}>
            <Button
              className="circular"
              color="green"
              icon="plus"
              id="CardPaying-Button-AddPaymentMethod-5"
              onClick={()=> props.onAddPaymentMethod(5)}
              size="mini"
              style={{margin: "3px 10px"}}>
            </Button>
          </div>
          <div
            style={{ gridArea: "3 / 1 / 4/ 9" , margin: "20px 0" }}>
            {props.ErrorMessage}
          </div>
          <div
            style={{ gridArea: "12 / 1 / 12/ 9" , margin: "10px 0" , padding: "0 2rem"}}>
            {props.SubRepeaterPayMethod}
          </div>
          <div
            id="CardPaying-Div-PayingHeader"
            style={{ gridArea: "3 / 1 / 4/ 9" , margin: "20px 0" }}>
            {props.Loader}
          </div>
          <div
            style={{ gridRow: "14/15", gridColumn: "1/2",  marginRight: "1rem", ...((props.isCudent  || props.isDeposit) && { display: "none" })}}>
            
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              ชื่อแพทย์บนใบเสร็จ
            </div>
          </div>
          <div
            style={{ gridRow: "14/15", gridColumn: "2/4",marginRight: "1rem", marginLeft: "-1rem", alignItems: "center", ...((props.isCudent  || props.isDeposit) && { display: "none" }) }}>
            
            <div>
              {props.doctorDropDown}
            </div>
          </div>
          <div
            style={{ gridRow: "14/15", gridColumn: "4/6",  marginRight: "1rem"}}>
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              ชื่อผู้ป่วยบนใบเสร็จ
            </div>
          </div>
          <div
            style={{ gridRow: "14/15", gridColumn: "6/8",marginRight: "1rem", marginLeft: "-1rem", alignItems: "center"  }}>
            <div>
              {props.patientDropDown}
            </div>
          </div>
          <div
            style={{ gridRow: "14/15", gridColumn: "8/9" }}>
            <Button
              color="green"
              disabled={props.cannotPay}
              onClick={props.onPay}
              style={{ width: "100%" }}>
              รับชำระเงิน
            </Button>
          </div>
          <div
            style={{ gridRow: "13/16", gridColumn: "8/9" , pointerEvents: "none"}}>
            
          </div>
          <div
            style={{ gridRow: "10/11", gridColumn: "4/5", ...(props.isCudent && {display: "none"}), ...(props.isDeposit && {gridRow: "9/10"})  }}>
            
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              ผ่อนชำระ
            </div>
          </div>
          <div
            style={{ gridRow: "10/11", gridColumn: "5/7", margin: "2.5px 0", ...(props.isCudent && {display: "none"}),  ...(props.isDeposit && {gridRow: "9/10"})  }}>
            
            <div
              className="ui label large fluid"
              id="txt-cashInstallment"
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              {props.installmentText}
            </div>
          </div>
          <div
            style={{ gridRow: "10/11", gridColumn: "7/8", ...(props.isCudent && {display: "none"}), ...(props.isDeposit && {gridRow: "9/10"})  }}>
            
            <Button
              className="circular"
              color="green"
              icon="plus"
              id="CardPaying-Button-AddPaymentMethod-7"
              onClick={()=> props.onAddPaymentMethod(7)}
              size="mini"
              style={{margin: "3px 10px"}}>
            </Button>
          </div>
          <div
            style={{ gridArea: "13 / 1 / 14 / 9" , margin: "-1.75em 0px -1em", padding: "0 2rem" }}>
            
            <div
              className="ui divider"
              style={{width: "100%"}}>
              
            </div>
            <Form
              className={`inline${props.showAutoEDC ? "": " hidden"}`}>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={2}>
                  <Checkbox
                    checked={props.check || false}
                    label="Auto EDC"
                    name="check"
                    onChange={props.onChangeAutoEDC}
                    style={{fontWeight: "bold",minWidth: "max-content"}}>
                  </Checkbox>
                </FormField>
                <FormField
                  inline={true}
                  width={5}>
                  <label
                    style={{minWidth: "max-content", width:  "140px"}}>
                    Approval Type
                  </label>
                  <Dropdown
                    disabled={!props.check}
                    fluid={true}
                    name="approvalType"
                    onChange={props.onChangeAutoEDC}
                    options={[{key:1, text: "เจ้าของสิทธิ", value: "SELF"},{key:2, text: "บุตรธิดา", value: "CHILD"},{key:3, text: "ชาวต่างชาติ", value: "FOREIGNER"},{key:4, text: "ไม่มีบัตร", value: "NO_CARD"}]}
                    selection={true}
                    style={{width: "100%"}}
                    value={props.approvalType || ""}>
                  </Dropdown>
                </FormField>
                <FormField
                  inline={true}
                  width={4}>
                  <label
                    style={{minWidth: "max-content"}}>
                    Trace ID
                  </label>
                  <Input
                    disabled={props.check || false}
                    name="traceId"
                    onChange={props.onChangeAutoEDC}
                    placeholder="Trace ID"
                    value={props.traceId || ""}>
                  </Input>
                </FormField>
                <FormField
                  inline={true}
                  width={5}>
                  <label
                    style={{minWidth: "max-content"}}>
                    Approval Code
                  </label>
                  <Input
                    disabled={props.check || false}
                    name="approvalCode"
                    onChange={props.onChangeAutoEDC}
                    placeholder="Approval Code"
                    value={props.approvalCode || ""}>
                  </Input>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={2}>
                </FormField>
                <FormField
                  inline={true}
                  width={5}>
                  <label
                    style={{minWidth: "max-content", width: "130px"}}>
                    EDC Amount
                  </label>
                  <Input
                    readOnly={true}
                    ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                    value={props.sumAmount.price || ""}>
                  </Input>
                </FormField>
              </FormGroup>
            </Form>
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "4/5",  ...(props.isDeposit && {display: "none"})  }}>
            
            <div
              style={{ paddingRight: "10px", width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", ...((props.isDeposit || props.isCudent) && {display: "none"}) }}>
              เงินมัดจำ
            </div>
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "5/7", margin: "2.5px 0", ...(props.isDeposit && {display: "none"}), ...(props.isCudent && {display: "none"})  }}>
            
            <div
              className="ui input"
              style={{ width : "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
              
              <input
                id="CardPaying-Input-CashDeposit"
                name="cashDepositText"
                onBlur={props.onBlurCashDepositText}
                onChange={props.onChangeCashDepositText}
                onKeyUp={props.onEnterCashDepositText}
                style={{  textAlign: "right" }}
                value={props.cashDepositText}>
              </input>
            </div>
          </div>
          <div
            style={{ gridRow: "7/8", gridColumn: "7/9", display: (props.isDeposit || props.isCudent) ? "none": "flex", alignItems: "center" }}>
            
            <Button
              className="circular"
              icon="plus"
              size="mini"
              style={{margin: "3px 10px", opacity:0, pointerEvents: "none"}}>
            </Button>
            <label
              style={{fontWeight: "bold", minWidth: "max-content"}}>
              คงเหลือ
            </label>
            <div
              className="ui label large fluid "
              style={{marginLeft: "15px", textAlign: "right"}}>
              {props.depositTotal || "0.00"}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardPayingUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Wed Dec 11 2024 12:04:16 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\", padding: \"10px\", marginTop:\"-1rem\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"rowGap\":\"5px\",\"gridTemplateColumns\":\"repeat(8, 1fr)\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", gridRow: \"1/2\", gridColumn: \"1/3\",display: \"none\"  }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", gridRow: \"1/2\", gridColumn: \"3/6\",display: \"none\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"7/9\",display: \"none\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/2\",  }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/4\", margin: \"2.5px 0\"  }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/2\",  }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"2/4\", margin: \"2.5px 0\"  }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/2\",  }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"2/4\", margin: \"2.5px 0\"  }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/2\",  }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"2/4\", margin: \"2.5px 0\"  }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/2\", }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"2/4\", margin: \"2.5px 0\"  }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/2\",  }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"2/4\", margin: \"2.5px 0\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"4/5\", }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"5/7\",  }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"4/5\", }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"5/7\", margin: \"2.5px 0\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"4/5\",  }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"5/7\", margin: \"2.5px 0\"  }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"4/5\", ...((props.isDeposit || props.isCudent) && {gridRow: \"7/8\"})}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"5/7\", margin: \"2.5px 0\", ...((props.isDeposit || props.isCudent) && {gridRow: \"7/8\"})  }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"4/5\", ...((props.isDeposit || props.isCudent) && {gridRow: \"8/9\"})  }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"5/7\", margin: \"2.5px 0\" , ...((props.isDeposit || props.isCudent) && {gridRow: \"8/9\"}) }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"4/5\", ...(props.isCudent && {gridRow: \"9/10\"}), ...(props.isDeposit && {gridRow: \"10/11\"})  }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.totalText"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"5/7\", ...(props.isCudent && {gridRow: \"9/10\"}), ...(props.isDeposit && {gridRow: \"10/11\"})}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"7/9\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridArea: \"5 / 7 / 6 / 9\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"7/8\" }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"7/8\", ...((props.isDeposit || props.isCudent) && {gridRow: \"7/8\"}) }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"7/8\", ...((props.isDeposit || props.isCudent) && {gridRow: \"8/9\"})}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"4/6\",  marginRight: \"1rem\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"6/8\",marginRight: \"1rem\", marginLeft: \"-1rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"8/9\" }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ค่าใช้จ่ายทั้งหมด"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลด"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "ต้องชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้างชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "เงินสด"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "เงินโอน/QR Code"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "บัตรเดบิต/บัตรเครดิต"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "แคชเชียร์เช็ค"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "คูปองเงินสด"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.sumAmount.price"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.sumAmount.reimbursable"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "(props.sumAmount.cashonly || 0)"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "(props.sumAmount.discount || 0)"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "(props.sumAmount.payable || 0)"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.oweText"
        },
        "className": {
          "type": "code",
          "value": "`ui label large fluid ${Number(props.rawOweText) > Number(props.totalText) ? \"red\" : \"green\"}`"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui input"
        },
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.transferText"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "id": {
          "type": "value",
          "value": "txt-cashTransfer"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\",}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cardText"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "id": {
          "type": "value",
          "value": "txt-cashCreditCard"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.chequeText"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.couponText"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Button",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงิน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.cannotPay"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPay"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ป่วยบนใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงิน (Paying)"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "รวม"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "input",
      "parent": 54,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPaying-Input-CashAmount"
        },
        "onBlur": {
          "type": "code",
          "value": "props.onBlurCashAmountText"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCashAmountText",
          "valueEN": ""
        },
        "onKeyUp": {
          "type": "code",
          "value": "props.onEnterCashAmountText"
        },
        "style": {
          "type": "code",
          "value": "{  textAlign: \"right\" }"
        },
        "value": {
          "type": "code",
          "value": "props.cashAmountText"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Label",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.citizenID || \"-\"}`"
        },
        "className": {
          "type": "value",
          "value": "large tag blue"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Label",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientNameAndHN"
        },
        "className": {
          "type": "value",
          "value": "ribbon large title blue"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Button",
      "parent": 30,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "id": {
          "type": "value",
          "value": "CardPaying-Button-AddPaymentMethod-2"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onAddPaymentMethod(2)"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"3px 10px\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Button",
      "parent": 31,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "id": {
          "type": "value",
          "value": "CardPaying-Button-AddPaymentMethod-3"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onAddPaymentMethod(3)"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"3px 10px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Button",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "id": {
          "type": "value",
          "value": "CardPaying-Button-AddPaymentMethod-4"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onAddPaymentMethod(4)"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"3px 10px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Button",
      "parent": 33,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "id": {
          "type": "value",
          "value": "CardPaying-Button-AddPaymentMethod-5"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onAddPaymentMethod(5)"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"3px 10px\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SubRepeaterPayMethod"
        },
        "style": {
          "type": "code",
          "value": "{ gridArea: \"12 / 1 / 12/ 9\" , margin: \"10px 0\" , padding: \"0 2rem\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ลดเศษสตางค์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roundOffText || \"\\u00a0\""
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", textAlign: \"right\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "ทอน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Button",
      "parent": 29,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 0, margin: \"3px 10px\", pointerEvents: \"none\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.changeText "
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", textAlign: \"right\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{ gridArea: \"3 / 1 / 4/ 9\" , margin: \"20px 0\" }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Loader"
        },
        "id": {
          "type": "value",
          "value": "CardPaying-Div-PayingHeader"
        },
        "style": {
          "type": "code",
          "value": "{ gridArea: \"3 / 1 / 4/ 9\" , margin: \"20px 0\" }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"4/5\", ...(props.isCudent && {display: \"none\"}), ...(props.isDeposit && {gridRow: \"9/10\"})  }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"5/7\", margin: \"2.5px 0\", ...(props.isCudent && {display: \"none\"}),  ...(props.isDeposit && {gridRow: \"9/10\"})  }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"7/8\", ...(props.isCudent && {display: \"none\"}), ...(props.isDeposit && {gridRow: \"9/10\"})  }"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.installmentText"
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid"
        },
        "id": {
          "type": "value",
          "value": "txt-cashInstallment"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "ผ่อนชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\" }"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Button",
      "parent": 84,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "id": {
          "type": "value",
          "value": "CardPaying-Button-AddPaymentMethod-7"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onAddPaymentMethod(7)"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"3px 10px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/16\", gridColumn: \"8/9\" , pointerEvents: \"none\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/9\", padding: \"10px\", display: \"flex\", margin: \"0.5rem 0\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Label",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientNameAndHN"
        },
        "className": {
          "type": "value",
          "value": "ribbon large title blue"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Label",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.citizenID || \"-\"}`"
        },
        "className": {
          "type": "value",
          "value": "large tag blue"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5rem\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงิน (Paying)"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", paddingRight: \"10px\",  height: \"100%\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridArea: \"13 / 1 / 14 / 9\" , margin: \"-1.75em 0px -1em\", padding: \"0 2rem\" }"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Form",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "`inline${props.showAutoEDC ? \"\": \" hidden\"}`"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Checkbox",
      "parent": 97,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.check || false"
        },
        "label": {
          "type": "value",
          "value": "Auto EDC"
        },
        "name": {
          "type": "value",
          "value": "check"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAutoEDC"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",minWidth: \"max-content\"}"
        },
        "toggle": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Dropdown",
      "parent": 99,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.check"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "approvalType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAutoEDC"
        },
        "options": {
          "type": "code",
          "value": "[{key:1, text: \"เจ้าของสิทธิ\", value: \"SELF\"},{key:2, text: \"บุตรธิดา\", value: \"CHILD\"},{key:3, text: \"ชาวต่างชาติ\", value: \"FOREIGNER\"},{key:4, text: \"ไม่มีบัตร\", value: \"NO_CARD\"}]"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.approvalType || \"\""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "Approval Type"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", width:  \"140px\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "Trace ID"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Input",
      "parent": 102,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.check || false"
        },
        "name": {
          "type": "value",
          "value": "traceId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAutoEDC"
        },
        "placeholder": {
          "type": "value",
          "value": "Trace ID"
        },
        "value": {
          "type": "code",
          "value": "props.traceId || \"\""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "Approval Code"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Input",
      "parent": 105,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.check || false"
        },
        "name": {
          "type": "value",
          "value": "approvalCode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAutoEDC"
        },
        "placeholder": {
          "type": "value",
          "value": "Approval Code"
        },
        "value": {
          "type": "code",
          "value": "props.approvalCode || \"\""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 108,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormField",
      "parent": 108,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "label",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "EDC Amount"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", width: \"130px\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Input",
      "parent": 110,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "value": {
          "type": "code",
          "value": "props.sumAmount.price || \"\""
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"4/5\",  ...(props.isDeposit && {display: \"none\"})  }"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"5/7\", margin: \"2.5px 0\", ...(props.isDeposit && {display: \"none\"}), ...(props.isCudent && {display: \"none\"})  }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"7/9\", display: (props.isDeposit || props.isCudent) ? \"none\": \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "เงินมัดจำ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", ...((props.isDeposit || props.isCudent) && {display: \"none\"}) }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "code",
          "value": "props.depositTotal || \"0.00\""
        },
        "className": {
          "type": "value",
          "value": "ui label large fluid "
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", textAlign: \"right\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui input"
        },
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "input",
      "parent": 121,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPaying-Input-CashDeposit"
        },
        "name": {
          "type": "value",
          "value": "cashDepositText"
        },
        "onBlur": {
          "type": "code",
          "value": "props.onBlurCashDepositText"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCashDepositText"
        },
        "onKeyUp": {
          "type": "code",
          "value": "props.onEnterCashDepositText"
        },
        "style": {
          "type": "code",
          "value": "{  textAlign: \"right\" }"
        },
        "value": {
          "type": "code",
          "value": "props.cashDepositText"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Button",
      "parent": 117,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"3px 10px\", opacity:0, pointerEvents: \"none\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "คงเหลือ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"1/2\",  marginRight: \"1rem\", ...((props.isCudent  || props.isDeposit) && { display: \"none\" })}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อแพทย์บนใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", width : \"100%\", height: \"100%\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\", }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"2/4\",marginRight: \"1rem\", marginLeft: \"-1rem\", alignItems: \"center\", ...((props.isCudent  || props.isDeposit) && { display: \"none\" }) }"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorDropDown"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientDropDown"
        }
      },
      "seq": 129,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 45,
  "isMounted": false,
  "memo": false,
  "name": "CardPayingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 60
}

*********************************************************************************** */
