import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Input,
  Radio,
  Dropdown,
  Button,
  TextArea
} from 'semantic-ui-react'

const CardHoldingRoomUX = (props: any) => {
    return(
      <div
        id="CardHoldingRoom"
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            Holding Room
          </label>
          <Label
            color={props.statusColor}
            style={{ float: "right" }}>
            {props.PreOperationSequence?.holdingRoomData?.status || ""}
          </Label>
          <hr>
          </hr>
        </div>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Patient identification
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.verbally}
                label="Verbally"
                name="verbally"
                onChange={props.onChangeData}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.name_band}
                label="Name band"
                name="name_band"
                onChange={props.onChangeData}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.chart}
                label="Chart"
                name="chart"
                onChange={props.onChangeData}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                History illness
              </label>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Input
                name="history_illness"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.holdingRoomData?.data?.history_illness || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{alignItems: "flex-start"}}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Previous Operation
              </label>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <label
                style={{display: "none"}}>
                {props.PreOperationSequence?.holdingRoomData?.data?.previous_operation || "-"}
              </label>
              <TextArea
                name="previous_operation"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.holdingRoomData?.data?.previous_operation || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            id="title-procedure-confirm"
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Procedure Confirm
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{marginLeft: "-7rem"}}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm}
                label={props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm ? "YES" : "NO"}
                name="procedure_confirm"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Input
                disabled={!props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm}
                name="procedure_confirm_detail"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm_detail || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Mark site Confirm
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "yes"}
                label="Yes"
                name="mark_site_confirm"
                onChange={props.onChangeData}
                value="yes">
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "no"}
                label="No"
                name="mark_site_confirm"
                onChange={props.onChangeData}
                value="no">
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "n/a"}
                label="N/A"
                name="mark_site_confirm"
                onChange={props.onChangeData}
                value="n/a">
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Input
                name="mark_site_confirm_detail"
                onChange={props.onChangeData}
                style={{display: props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "n/a" ? "" : "none" }}
                value={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm_detail || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Confirmation Surgical site per PT/Family
              </label>
            </FormField>
            <FormField>
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === "yes"}
                label="Yes"
                name="confirm_surgical"
                onChange={props.onChangeData}
                value="yes">
              </Radio>
            </FormField>
            <FormField>
              <Radio
                checked={props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === "no"}
                label="No"
                name="confirm_surgical"
                onChange={props.onChangeData}
                value="no">
              </Radio>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              className="required">
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Home medication
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                className="subtitle-indent"
                style={{paddingLeft: "20px", minWidth: "220px", maxWidth: "220px"}}>
                Anti Coagulation Drug
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug}
                label={props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug ? "YES" : "NO"}
                name="anti_coagulation_drug"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                className="subtitle-indent"
                style={{paddingLeft: "20px", minWidth: "220px", maxWidth: "220px"}}>
                DM Drug
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.dm_drug}
                label={props.PreOperationSequence?.holdingRoomData?.data?.dm_drug ? "YES" : "NO"}
                name="dm_drug"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                className="subtitle-indent"
                style={{paddingLeft: "20px", minWidth: "220px", maxWidth: "220px"}}>
                Anti Hypertensive Drug
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug}
                label={props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug ? "YES" : "NO"}
                name="anti_hypertensive_drug"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Valuable
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.valuable}
                label={props.PreOperationSequence?.holdingRoomData?.data?.valuable ? "YES" : "NO"}
                name="valuable"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
            <FormField
              style={{display: props.PreOperationSequence?.holdingRoomData?.data?.valuable ? "" : "none"}}>
              <Dropdown
                clearable={true}
                multiple={true}
                name="valuable_detail"
                onChange={props.onChangeData}
                options={props.masterOptions?.orValuable || []}
                search={true}
                selection={true}
                value={props.PreOperationSequence?.holdingRoomData?.data?.valuable_detail || []}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Implant
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.PreOperationSequence?.holdingRoomData?.data?.implant}
                label={props.PreOperationSequence?.holdingRoomData?.data?.implant ? "YES" : "NO"}
                name="implant"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
            <FormField
              style={{display: props.PreOperationSequence?.holdingRoomData?.data?.implant ? "" : "none" }}>
              <Input
                name="implant_detail"
                onChange={props.onChangeData}
                value={props.PreOperationSequence?.holdingRoomData?.data?.implant_detail || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Limitation
              </label>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Dropdown
                allowAdditions={true}
                fluid={true}
                name="limitation"
                onAddItem={props.onAddLimitation}
                onChange={props.onChangeData}
                options={props.masterOptions?.limitation || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.limitation}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Status of Conscious
              </label>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Dropdown
                fluid={true}
                name="status_of_conscious"
                onChange={props.onChangeData}
                options={props.masterOptions?.statusOfConscious || []}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.status_of_conscious}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Psychological Status
              </label>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Dropdown
                fluid={true}
                name="psychological_status"
                onChange={props.onChangeData}
                options={props.masterOptions?.psychologicalStatus || []}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.psychological_status}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Respiratory Status
              </label>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Dropdown
                fluid={true}
                name="respiratory_status"
                onChange={props.onChangeData}
                options={props.masterOptions?.respiratoryStatus || []}
                selection={true}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.respiratory_status}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Lab Data
              </label>
            </FormField>
            <FormField
              inline={true}>
              <label>
                {props.PreOperationSequence?.holdingRoomData?.data?.lab_data || ""}
              </label>
              <Button
                icon="list"
                onClick={props.onShowLabData}
                size="mini"
                type="button">
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Lab Remark
              </label>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Input
                fluid={true}
                name="lab_remark"
                onChange={props.onChangeData}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.lab_remark || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Blood
              </label>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Input
                fluid={true}
                name="blood"
                onChange={props.onChangeData}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.blood || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{alignItems: "baseline"}}>
            <FormField
              style={{alignItems: "baseline"}}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Remark
              </label>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <TextArea
                name="remark"
                onChange={props.onChangeData}
                rows={5}
                style={{width: "100%"}}
                value={props.PreOperationSequence?.holdingRoomData?.data?.remark || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Oxygen
              </label>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <Dropdown
                name="oxygen"
                onChange={props.onChangeData}
                options={[ { key: 1, value: 1, text: "1" }, { key: 2, value: 2, text: "2" }, { key: 3, value: 3, text: "3" }, { key: 4, value: 4, text: "4" }, { key: 5, value: 5, text: "5" }, { key: 6, value: 6, text: "6" }, { key: 7, value: 7, text: "7" }, { key: 8, value: 8, text: "8" }, { key: 9, value: 9, text: "9" }, { key: 10, value: 10, text: "10" } ]}
                selection={true}
                value={props.PreOperationSequence?.holdingRoomData?.data?.oxygen}>
              </Dropdown>
              <label>
                L/min
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{alignItems: "baseline"}}>
            <FormField
              style={{alignItems: "baseline"}}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Medication
              </label>
            </FormField>
            <FormField
              width={16}>
              <div
                style={{width: "100%"}}>
                {props.divMedication}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{alignItems: "baseline"}}>
            <FormField
              inline={true}
              style={{alignItems: "baseline"}}>
              <label
                style={{minWidth: "220px", maxWidth: "220px"}}>
                Other
              </label>
            </FormField>
            <FormField
              width={16}>
              <div
                style={{width: "100%"}}>
                {props.divOtherMedication}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{backgroundColor: "rgb(253, 248, 221)", padding: "30px 10px", marginTop: "2rem"}}>
            <FormField
              width={16}>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={7}>
                  <label>
                    Nursing Diagnosis
                  </label>
                  <div>
                    {props.PreOperationSequence?.holdingRoomData?.data?.nursing_diagnosis || "-"}
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={9}>
                  <label>
                    Goal
                  </label>
                  <div>
                    {props.PreOperationSequence?.holdingRoomData?.data?.goal || "-"}
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={7}>
                  <label
                    style={{width: "100%", maxWidth: "max-content"}}>
                    Plan and implementation
                  </label>
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    multiple={true}
                    name="plan_and_implementation"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.planAndImpleHr || []}
                    search={true}
                    selection={true}
                    style={{width: "100%"}}
                    value={props.PreOperationSequence?.holdingRoomData?.data?.plan_and_implementation || []}>
                  </Dropdown>
                </FormField>
                <FormField
                  inline={true}
                  width={5}>
                  <label>
                    Evaluation
                  </label>
                  <div>
                    {props.PreOperationSequence?.holdingRoomData?.data?.evaluation || "-"}
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  style={{padding:0}}
                  width={4}>
                  <Checkbox
                    checked={props.PreOperationSequence?.holdingRoomData?.data?.evaluation_check}
                    label={props.PreOperationSequence?.holdingRoomData?.data?.evaluation_check ? "YES" : "NO"}
                    name="evaluation_check"
                    onChange={props.onChangeData}
                    style={{minWidth: "max-content"}}
                    toggle={true}>
                  </Checkbox>
                  <Input
                    fluid={true}
                    name="evaluation_detail"
                    onChange={props.onChangeData}
                    style={{width: "100%"}}
                    value={props.PreOperationSequence?.holdingRoomData?.data?.evaluation_detail || ""}>
                  </Input>
                </FormField>
              </FormGroup>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginTop: "2rem"}}>
            <FormField
              inline={true}
              style={{flex:1}}>
            </FormField>
            <FormField>
              <div>
                {props.buttonSave}
              </div>
            </FormField>
            <FormField>
              <div>
                {props.buttonConfirm}
              </div>
            </FormField>
            <FormField>
              <div>
                {props.buttonUnconfirm}
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardHoldingRoomUX

export const screenPropsDefault = {}

/* Date Time : Thu Dec 12 2024 10:46:30 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardHoldingRoom"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Holding Room"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.status || \"\""
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormField",
      "parent": 176,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 176,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormField",
      "parent": 176,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "FormField",
      "parent": 176,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient identification"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Checkbox",
      "parent": 178,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.verbally"
        },
        "label": {
          "type": "value",
          "value": "Verbally"
        },
        "name": {
          "type": "value",
          "value": "verbally"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "Checkbox",
      "parent": 179,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.name_band"
        },
        "label": {
          "type": "value",
          "value": "Name band"
        },
        "name": {
          "type": "value",
          "value": "name_band"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "Checkbox",
      "parent": 180,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.chart"
        },
        "label": {
          "type": "value",
          "value": "Chart"
        },
        "name": {
          "type": "value",
          "value": "chart"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 185,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 185,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "History illness"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "Input",
      "parent": 187,
      "props": {
        "name": {
          "type": "value",
          "value": "history_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.history_illness || \"\""
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"flex-start\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "FormField",
      "parent": 190,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "FormField",
      "parent": 190,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 191,
      "props": {
        "children": {
          "type": "value",
          "value": "Previous Operation"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 192,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.previous_operation || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "id": {
          "type": "value",
          "value": "title-procedure-confirm"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "FormField",
      "parent": 195,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "FormField",
      "parent": 195,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"-7rem\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "label",
      "parent": 196,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure Confirm"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Checkbox",
      "parent": 197,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "procedure_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "FormField",
      "parent": 195,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "Input",
      "parent": 200,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm"
        },
        "name": {
          "type": "value",
          "value": "procedure_confirm_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm_detail || \"\""
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "FormField",
      "parent": 202,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "FormField",
      "parent": 202,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "label",
      "parent": 203,
      "props": {
        "children": {
          "type": "value",
          "value": "Mark site Confirm"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "Radio",
      "parent": 204,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "yes"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "FormField",
      "parent": 202,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "FormField",
      "parent": 202,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "Radio",
      "parent": 207,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"no\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "no"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Radio",
      "parent": 208,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"n/a\""
        },
        "label": {
          "type": "value",
          "value": "N/A"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "n/a"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "FormGroup",
      "parent": 175,
      "props": {
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "FormField",
      "parent": 211,
      "props": {
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "FormField",
      "parent": 211,
      "props": {
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "FormField",
      "parent": 211,
      "props": {
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "label",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": "Confirmation Surgical site per PT/Family"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Radio",
      "parent": 213,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === \"yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "confirm_surgical"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "yes"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "Radio",
      "parent": 214,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === \"no\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "confirm_surgical"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "no"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "FormGroup",
      "parent": 175,
      "props": {
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 218,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "label",
      "parent": 219,
      "props": {
        "children": {
          "type": "value",
          "value": "Home medication"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "FormField",
      "parent": 221,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "FormField",
      "parent": 221,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "FormField",
      "parent": 222,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormField",
      "parent": 222,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "FormField",
      "parent": 223,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "FormField",
      "parent": 223,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "Checkbox",
      "parent": 229,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "anti_hypertensive_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "Checkbox",
      "parent": 227,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.dm_drug"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.dm_drug ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "dm_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "Checkbox",
      "parent": 225,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "anti_coagulation_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": null,
      "id": 233,
      "name": "label",
      "parent": 228,
      "props": {
        "children": {
          "type": "value",
          "value": "Anti Hypertensive Drug"
        },
        "className": {
          "type": "value",
          "value": "subtitle-indent"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"20px\", minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "label",
      "parent": 226,
      "props": {
        "children": {
          "type": "value",
          "value": "DM Drug"
        },
        "className": {
          "type": "value",
          "value": "subtitle-indent"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"20px\", minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 235,
      "name": "label",
      "parent": 224,
      "props": {
        "children": {
          "type": "value",
          "value": "Anti Coagulation Drug"
        },
        "className": {
          "type": "value",
          "value": "subtitle-indent"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"20px\", minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 243,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "FormField",
      "parent": 245,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "FormField",
      "parent": 245,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 248,
      "name": "FormField",
      "parent": 244,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "FormField",
      "parent": 244,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "FormField",
      "parent": 243,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 251,
      "name": "FormField",
      "parent": 243,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "FormField",
      "parent": 242,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 253,
      "name": "FormField",
      "parent": 242,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "FormField",
      "parent": 241,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "FormField",
      "parent": 241,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 256,
      "name": "FormField",
      "parent": 240,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "FormField",
      "parent": 240,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "FormField",
      "parent": 239,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 259,
      "name": "FormField",
      "parent": 239,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 260,
      "name": "FormField",
      "parent": 238,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 261,
      "name": "FormField",
      "parent": 238,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 262,
      "name": "FormField",
      "parent": 237,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 263,
      "name": "FormField",
      "parent": 237,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "FormField",
      "parent": 236,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 265,
      "name": "FormField",
      "parent": 236,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 266,
      "name": "label",
      "parent": 264,
      "props": {
        "children": {
          "type": "value",
          "value": "Valuable"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "label",
      "parent": 262,
      "props": {
        "children": {
          "type": "value",
          "value": "Implant"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": null,
      "id": 268,
      "name": "label",
      "parent": 260,
      "props": {
        "children": {
          "type": "value",
          "value": "Limitation"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": null,
      "id": 269,
      "name": "label",
      "parent": 258,
      "props": {
        "children": {
          "type": "value",
          "value": "Status of Conscious"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": null,
      "id": 270,
      "name": "label",
      "parent": 256,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological Status"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": null,
      "id": 271,
      "name": "label",
      "parent": 254,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory Status"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": null,
      "id": 272,
      "name": "label",
      "parent": 252,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Data"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 273,
      "name": "label",
      "parent": 250,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Remark"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "label",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "label",
      "parent": 246,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 276,
      "name": "Checkbox",
      "parent": 265,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.valuable"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.valuable ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "valuable"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 277,
      "name": "Checkbox",
      "parent": 263,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.implant"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.implant ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "implant"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 278,
      "name": "Dropdown",
      "parent": 261,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "limitation"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddLimitation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.limitation || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.limitation"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Dropdown",
      "parent": 259,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status_of_conscious"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.statusOfConscious || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.status_of_conscious"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 280,
      "name": "Dropdown",
      "parent": 257,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "psychological_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.psychologicalStatus || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.psychological_status"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 281,
      "name": "Dropdown",
      "parent": 255,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "respiratory_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.respiratoryStatus || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.respiratory_status"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 282,
      "name": "Button",
      "parent": 253,
      "props": {
        "icon": {
          "type": "value",
          "value": "list"
        },
        "onClick": {
          "type": "code",
          "value": "props.onShowLabData"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "type": {
          "type": "value",
          "value": "button"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "Input",
      "parent": 251,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "lab_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.lab_remark || \"\""
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "Input",
      "parent": 249,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "blood"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.blood || \"\""
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 285,
      "name": "TextArea",
      "parent": 247,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.remark || \"\""
        }
      },
      "seq": 285,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 286,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 287,
      "name": "FormField",
      "parent": 286,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "FormField",
      "parent": 286,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 289,
      "name": "label",
      "parent": 287,
      "props": {
        "children": {
          "type": "value",
          "value": "Oxygen"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "Dropdown",
      "parent": 288,
      "props": {
        "name": {
          "type": "value",
          "value": "oxygen"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "[ { key: 1, value: 1, text: \"1\" }, { key: 2, value: 2, text: \"2\" }, { key: 3, value: 3, text: \"3\" }, { key: 4, value: 4, text: \"4\" }, { key: 5, value: 5, text: \"5\" }, { key: 6, value: 6, text: \"6\" }, { key: 7, value: 7, text: \"7\" }, { key: 8, value: 8, text: \"8\" }, { key: 9, value: 9, text: \"9\" }, { key: 10, value: 10, text: \"10\" } ]"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.oxygen"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "label",
      "parent": 288,
      "props": {
        "children": {
          "type": "value",
          "value": "L/min"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "FormField",
      "parent": 292,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "FormField",
      "parent": 292,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 297,
      "name": "FormField",
      "parent": 293,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": null,
      "id": 298,
      "name": "label",
      "parent": 294,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 299,
      "name": "label",
      "parent": 295,
      "props": {
        "children": {
          "type": "value",
          "value": "Other"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"220px\", maxWidth: \"220px\"}"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": null,
      "id": 300,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divMedication"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 301,
      "name": "div",
      "parent": 297,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divOtherMedication"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 302,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgb(253, 248, 221)\", padding: \"30px 10px\", marginTop: \"2rem\"}"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 303,
      "name": "FormField",
      "parent": 302,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 304,
      "name": "FormGroup",
      "parent": 303,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "FormGroup",
      "parent": 303,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 306,
      "name": "FormField",
      "parent": 304,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 307,
      "name": "FormField",
      "parent": 304,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 308,
      "name": "FormField",
      "parent": 305,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 309,
      "name": "FormField",
      "parent": 305,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "FormField",
      "parent": 305,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": null,
      "id": 311,
      "name": "label",
      "parent": 306,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursing Diagnosis"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": null,
      "id": 312,
      "name": "label",
      "parent": 307,
      "props": {
        "children": {
          "type": "value",
          "value": "Goal"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": null,
      "id": 313,
      "name": "label",
      "parent": 308,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan and implementation"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": null,
      "id": 314,
      "name": "label",
      "parent": 309,
      "props": {
        "children": {
          "type": "value",
          "value": "Evaluation"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": null,
      "id": 315,
      "name": "div",
      "parent": 307,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.goal || \"-\""
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": null,
      "id": 316,
      "name": "div",
      "parent": 306,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.nursing_diagnosis || \"-\""
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": null,
      "id": 317,
      "name": "div",
      "parent": 309,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.evaluation || \"-\""
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "Checkbox",
      "parent": 310,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.evaluation_check"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.evaluation_check ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "evaluation_check"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 319,
      "name": "Input",
      "parent": 310,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "evaluation_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.evaluation_detail || \"\""
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 320,
      "name": "Dropdown",
      "parent": 308,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "plan_and_implementation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.planAndImpleHr || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.plan_and_implementation || []"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 321,
      "name": "FormField",
      "parent": 202,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 321,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 322,
      "name": "Input",
      "parent": 321,
      "props": {
        "name": {
          "type": "value",
          "value": "mark_site_confirm_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{display: props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"n/a\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm_detail || \"\""
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 323,
      "name": "FormField",
      "parent": 236,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.PreOperationSequence?.holdingRoomData?.data?.valuable ? \"\" : \"none\"}"
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 324,
      "name": "Dropdown",
      "parent": 323,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "valuable_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orValuable || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.valuable_detail || []"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 325,
      "name": "FormField",
      "parent": 237,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.PreOperationSequence?.holdingRoomData?.data?.implant ? \"\" : \"none\" }"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 326,
      "name": "Input",
      "parent": 325,
      "props": {
        "name": {
          "type": "value",
          "value": "implant_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.implant_detail || \"\""
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "label",
      "parent": 253,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.lab_data || \"\""
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 328,
      "name": "FormGroup",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 329,
      "name": "FormField",
      "parent": 328,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 330,
      "name": "FormField",
      "parent": 328,
      "props": {
      },
      "seq": 330,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 331,
      "name": "FormField",
      "parent": 328,
      "props": {
      },
      "seq": 331,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 332,
      "name": "FormField",
      "parent": 328,
      "props": {
      },
      "seq": 332,
      "void": false
    },
    {
      "from": null,
      "id": 333,
      "name": "div",
      "parent": 330,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": null,
      "id": 334,
      "name": "div",
      "parent": 331,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": null,
      "id": 335,
      "name": "div",
      "parent": 332,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        }
      },
      "seq": 335,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "TextArea",
      "parent": 192,
      "props": {
        "name": {
          "type": "value",
          "value": "previous_operation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.previous_operation || \"\""
        }
      },
      "seq": 336,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardHoldingRoomUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
