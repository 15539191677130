import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Checkbox
} from 'semantic-ui-react'

const CardRehabTreatmentPlanUX = (props: any) => {
    return(
      <div
        style={{margin:"2px 6px"}}>
        <div>
          
          <Button
            color={props.treatmentPlanColorBtn}>
            Treatment Plan
          </Button>
          <Button
            color={props.treatmentPerformRecordColorBtn}>
            Treatment Perform Record
          </Button>
        </div>
        <div
          style={{display:"flex",justifyContent:"space-between",margin:"10px 0px", alignItems:"center"}}>
          
          <div
            style={{display:"flex", gap:"20px",margin:"8px 0px"}}>
            
            <div
              style={{color: "blue",fontWeight:"bolder",fontSize:"larger",alignItems:"center"}}>
              Treatment  plan list
            </div>
            <Checkbox
              label="Include Performed">
            </Checkbox>
            <Checkbox
              label="Include Finished">
            </Checkbox>
          </div>
          <div
            style={{display:"flex",gap:"6px", height:"40%", alignItems: "center"}}>
            
            <label
              style={{fontWeight:"bolder"}}>
              Select the action:
            </label>
            <Button
              color="yellow"
              icon={true}
              size="mini">
              {props.printbookIcon}
            </Button>
            <Button
              color="blue"
              icon={true}
              size="mini">
              {props.appointmentIcon}
            </Button>
            <Button
              color="green"
              icon={true}
              size="mini">
              {props.selectToPerformIcon}
            </Button>
          </div>
        </div>
        <div>
          {props.treatmentPlan}
        </div>
        <div>
          {props.treatmentPerformedToday}
        </div>
      </div>
    )
}


export default CardRehabTreatmentPlanUX

export const screenPropsDefault = {}

/* Date Time : Tue Jan 07 2025 15:25:48 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin:\"2px 6px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", gap:\"20px\",margin:\"8px 0px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment Plan"
        },
        "color": {
          "type": "code",
          "value": "props.treatmentPlanColorBtn"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment Perform Record"
        },
        "color": {
          "type": "code",
          "value": "props.treatmentPerformRecordColorBtn"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment  plan list"
        },
        "style": {
          "type": "code",
          "value": "{color: \"blue\",fontWeight:\"bolder\",fontSize:\"larger\",alignItems:\"center\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.treatmentPlan"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Checkbox",
      "parent": 2,
      "props": {
        "label": {
          "type": "value",
          "value": "Include Performed"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Checkbox",
      "parent": 2,
      "props": {
        "label": {
          "type": "value",
          "value": "Include Finished"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.treatmentPerformedToday"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"space-between\",margin:\"10px 0px\", alignItems:\"center\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"6px\", height:\"40%\", alignItems: \"center\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.printbookIcon"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.appointmentIcon"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectToPerformIcon"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Select the action:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bolder\"}"
        }
      },
      "seq": 13,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardRehabTreatmentPlanUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
