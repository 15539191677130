import React, {
  CSSProperties,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Button, ButtonProps, Form, Icon, Label } from "semantic-ui-react";

import Dropzone, { DropFilesEventHandler } from "react-dropzone";
import { useIntl } from "react-intl";

import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";

import { UPLOAD_TYPES, UploadKeys } from "./USERInterface";

// Types
type CardUploadTemplateExcelProps = {
  onEvent: (e: any) => void;
  setProp: (key: string, value: any, callback?: () => void) => any;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
};

// Constants
const BUTTONS = [
  { label: "Staff", name: UPLOAD_TYPES.DOCTOR },
  { label: "Division Has User", name: UPLOAD_TYPES.DIVISION_HAS_USER },
  { label: "Drug stock", name: UPLOAD_TYPES.DRUG },
];

type Styles = {
  [key in "upload"]: CSSProperties;
};

// Const
const styles: Styles = {
  upload: {
    alignItems: "center",
    background: "#f9fafb",
    border: "2px dashed #b2b2c8",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    padding: "3rem",
  },
};

const BUTTON_ACTIONS = {
  upload: "UPLOAD",
};

const CARD_UPLOAD_TEMPLATE_EXCEL = "CardUploadTemplateExcel";

const ACTION_UPLOAD = `${CARD_UPLOAD_TEMPLATE_EXCEL}_${BUTTON_ACTIONS.upload}`;

const CardUploadTemplateExcel = (props: CardUploadTemplateExcelProps) => {
  const intl = useIntl();

  const [activeMenu, setActiveMenu] = useState<UploadKeys>(UPLOAD_TYPES.DOCTOR);
  const [fileExcel, setFileExcel] = useState<File | null>(null);

  // Effect
  useEffect(
    () => () => {
      handleCloseErrMsg();
    },
    []
  );

  // Callback
  const handleChangeMenu = useCallback((e: SyntheticEvent, data: ButtonProps) => {
    handleClearFile();

    setActiveMenu(data.name);
  }, []);

  const handleChangeFile: DropFilesEventHandler = useCallback((accepted, rejected) => {
    handleCloseErrMsg();

    const acceptedFile = accepted[0];
    const rejectedFile = rejected[0];

    if (rejectedFile instanceof File) {
      console.warn(`ประเภทไฟล์ไม่ถูกต้อง: ${rejectedFile.name}`);

      return;
    }

    if (!(acceptedFile instanceof File)) {
      console.warn("file is not an instance of File");

      return;
    }

    setFileExcel(acceptedFile);
  }, []);

  // Memo
  const titleName = useMemo(
    () => BUTTONS.find((item) => item.name === activeMenu)?.label,
    [activeMenu]
  );

  const alertMessage = useMemo(() => {
    const error = props.errorMessage?.[ACTION_UPLOAD];
    const success = props.successMessage?.[ACTION_UPLOAD];
    const skip: string[] = success?.skip || [];

    let status = "";

    if (error) {
      status = "error";
    } else if (success) {
      status = skip.length > 0 ? "warning" : "success";
    }

    const contentMapping: Record<string, ReactElement> = {
      error,
      success: (
        <div style={{ textAlign: "center" }}>{intl.formatMessage({ id: "บันทึกสำเร็จ" })}</div>
      ),
      warning: (
        <>
          <div style={{ color: "#b58105" }}>ไม่สามารถบันทึกได้ทั้งหมด</div>
          <ul style={{ margin: "0.25rem 0 0 -1rem" }}>
            {skip.map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ul>
        </>
      ),
    };

    const content = contentMapping[status];

    return {
      [status]: status === "error" ? content : " ",
      header: status === "error" ? null : <div style={{ lineHeight: 1.75 }}>{content}</div>,
    };
  }, [props.errorMessage, props.successMessage]);

  const handleClearFile = (e?: SyntheticEvent) => {
    handleCloseErrMsg();

    e?.stopPropagation();

    setFileExcel(null);
  };

  const handleUploadFile = () => {
    handleCloseErrMsg();

    props.onEvent({
      message: "HandleUploadTemplateExcel",
      params: {
        btnAction: ACTION_UPLOAD,
        file: fileExcel,
        type: activeMenu,
        onSuccess: () => {
          setFileExcel(null);
        },
      },
    });
  };

  const handleCloseErrMsg = () => {
    props.setProp(`errorMessage.${ACTION_UPLOAD}`, null);
    props.setProp(`successMessage.${ACTION_UPLOAD}`, null);
  };

  console.log("CardUploadTemplateExcel:", props);

  return (
    <div style={{ padding: "1rem" }}>
      {/* <SnackMessage
        onEvent={props.onEvent}
        error={alertMessage.error}
        header={alertMessage.header}
        success={alertMessage.success}
        warning={alertMessage.warning}
        onClose={handleCloseErrMsg}
      /> */}

      <Form>
        <Form.Group inline>
          {BUTTONS.map((item) => (
            <Form.Field key={item.name}>
              <Button
                color={activeMenu === item.name ? "blue" : undefined}
                name={item.name}
                onClick={handleChangeMenu}
              >
                {item.label}
              </Button>
            </Form.Field>
          ))}
        </Form.Group>
      </Form>

      <div style={{ height: "calc(100dvh - 7.5rem)", overflow: "auto" }}>
        <div style={{ padding: "0.35rem 1rem" }}>
          <div style={{ fontSize: "1.15rem", fontWeight: "bold", margin: "0 0 1rem" }}>
            <Icon name="file alternate outline" />
            อัปโหลดข้อมูล {titleName}
          </div>

          <Dropzone
            accept={".xlsx,.xls"}
            multiple={false}
            style={styles.upload}
            onDrop={handleChangeFile}
          >
            <Label size="big" style={{ backgroundColor: "#eff6ff" }} circular>
              <Icon name="upload" style={{ color: "#2563eb", margin: 0 }} />
            </Label>
            <label style={{ color: "#2563eb", margin: "1rem 0 0.5rem" }}>เลือกไฟล์</label>
            <label style={{ color: "#6b7280" }}>รองรับไฟล์ .xlsx, .xls</label>
            {fileExcel && (
              <div
                style={{
                  alignItems: "baseline",
                  background: "white",
                  borderRadius: "8px",
                  color: "#4b5563",
                  display: "flex",
                  marginTop: "1rem",
                  padding: "0.5rem 1rem",
                }}
              >
                <Icon name="file alternate outline" />
                {fileExcel.name}
                <Icon
                  color="blue"
                  name="close"
                  style={{ marginLeft: "10rem" }}
                  onClick={handleClearFile}
                />
              </div>
            )}
          </Dropzone>

          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
            <Button color="blue" disabled={!fileExcel} onClick={handleUploadFile}>
              อัพโหลด
            </Button>
          </div>
        </div>

        <div style={{ padding: "1rem 1rem" }}>
          <ErrorMessage
            error={alertMessage.error}
            header={alertMessage.header}
            success={alertMessage.success}
            warning={alertMessage.warning}
          />
        </div>
      </div>

      <ModInfo
        buttonStyle={{ display: "none" }}
        open={props.buttonLoadCheck?.[ACTION_UPLOAD]}
        style={{ minWidth: "37rem" }}
        titleColor={"teal"}
      >
        <div style={{ fontWeight: "bold", lineHeight: 2, padding: "2rem 0" }}>
          <div>{intl.formatMessage({ id: "กรุณารอสักครู่ระบบกำลังอัพโหลดข้อมูล" })}</div>
          <div>{intl.formatMessage({ id: "ห้ามปิดหน้าต่างจนกว่าระบบจะประมวลผลเสร็จสิ้น" })}</div>
        </div>
      </ModInfo>
    </div>
  );
};

CardUploadTemplateExcel.displayName = "CardUploadTemplateExcel";

export default React.memo(CardUploadTemplateExcel);
