import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  Button,
  Form,
  FormField,
  FormGroup,
  Icon,
  Modal,
  Segment,
} from "semantic-ui-react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import FormMobEditOrderDetail from "react-lib/apps/HISV3/common/FormMobEditOrderDetail";
//STYLE
import "react-lib/apps/HISV3/common/CardOrderSetTemplate.scss";
import Callback from "react-lib/apps/IsHealth/Common/EmailLogin/EmailCallback";

const CARD_ORDER_SET_TEMPLATE = "CardOrderSetTemplateUX";

const orderDetailHeader = [
  { name: "ชื่อ", width: 5 },
  { name: "รายละเอียด", width: 6 },
  { name: "จำนวน", width: 2 },
  { name: "ราคาต่อหน่วย", width: 2 },
  { name: "ราคารวม", width: 2 },
  { name: "", width: 2 },
];

export const supplyMode = {
  EQUIPMENT: "Equipment",
  SUPPLY: "Supply",
  BOTH: "Both",
};

const headerModal = {
  DRUG: "ยา",
  PROC: "หัตการ",
  SUPPLY: "เวชภัณฑ์",
  LAB: "แลป",
  XRAY: " Imaging",
};

type FormOrderDetailListProps = {
  runSequence: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  orderTemplateList?: any[];
  buttonLoadCheck?: Record<string, any>;
  OrderSetTemplateSequence?: Record<string, any>;
  // options
  masterOptions?: Record<string, any>;
  supplyOrderEligibilityOptions?: any[];
};

const FormOrderDetailList = (props: FormOrderDetailListProps) => {
  const [selectedId, setSelectedId] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<number[]>([0, 1, 2, 3, 4]);

  const selectTemplate = useMemo(() => {
    return props.OrderSetTemplateSequence?.selectTemplate?.items?.filter(
      (item: any) => item?.id === selectedId
    )?.[0];
  }, [props.OrderSetTemplateSequence?.selectTemplate?.items, selectedId]);

  const handleSeletedData = (data: any, type: string) => {
    setSelectedId(data?.id);

    props.runSequence({
      sequence: "OrderSetTemplate",
      action: "getDetailData",
      data: data,
      type: type,
    });
  };

  const handleDeleteData = (id: number) => {
    props.setProp(
      "OrderSetTemplateSequence.selectTemplate.items",
      props.OrderSetTemplateSequence?.selectTemplate?.items?.filter((item: any) => item.id !== id)
    );
  };

  const handleEditOrderDetail = () => {
    props.runSequence({
      sequence: "OrderSetTemplate",
      action: "editOrderDetail",
      selectedId: selectedId,
      callback: (id: any) => {
        setSelectedId(id);
      },
    });
  };

  const orderTemplateListDetail = (type: string, data: any) => {
    if (type === "Medication") {
      let detailData = ["unit", "route", "frequency"].map((key: string) => {
        return (
          props.masterOptions?.[`${key}`]
            ?.filter(
              (item: any) => item.value === data?.[`${key === "unit" ? "drugUnits" : key}`]
            )?.[0]
            ?.text?.split("(")?.[0] || ""
        );
      });

      return `${detailData?.[1]} ${detailData?.[1] && "ครั้งละ"} ${
        (detailData?.[1] && Number(data?.dose)) || ""
      } ${detailData?.[0]} ${detailData?.[2]}`;
    } else if (type === "Treatment") {
      let icd9Detail = data?.icd9 ? `[${data?.icd9?.icd10_id}] ${data?.icd9?.icdterm} ,` : "";

      return `${icd9Detail} ${data?.toBePerformer || ""}`;
    } else if (type === "Supply") {
      let mode = data?.mode ? `${supplyMode[data?.mode]} ,` : "";
      let eligibilityType =
        props.supplyOrderEligibilityOptions?.filter(
          (item: any) => item.value === data?.eligibility_type
        )?.[0]?.text || "";

      return `${mode} ${eligibilityType}`;
    } else if (type === "Lab") {
      return data?.specimen_name && data?.urgency ? (
        <div style={{ display: "flex" }}>
          <div
            style={{
              backgroundColor: "gray",
              borderRadius: "50%",
              width: "1.5em",
              height: "1.5em",
              margin: "0px 10px",
            }}
          ></div>
          <div>{`[${data?.specimen_name}] ,${data?.urgency}`}</div>
        </div>
      ) : (
        ""
      );
    } else if (type === "X-ray") {
      let xrayGroup = data?.xrayGroup ? `${data?.xrayGroup} ,` : "";

      return `${xrayGroup} ${data?.urgency || ""}`;
    } else {
      return "";
    }
  };

  const orderTemplateListCount = (type: string, data: any) => {
    if (type === "Medication") {
      return `${Number(data?.dose) || 0} ${data?.drugUnits ? data?.drugUnits : data?.unit_name}`;
    } else {
      return `${Number(data?.qty) || 0} ${data?.unit_name}`;
    }
  };

  const orderTemplateListSomePrice = (type: string, data: any) => {
    let qty = 0;
    if (type === "Medication") {
      qty = Number(data?.dose || 0);
    } else {
      qty = Number(data?.qty || 0);
    }

    return qty * Number(data?.price_normal || 0);
  };

  const handleClickAccordion = (e: any, data: any) => {
    const index = Number(data.index);
    const newIndex = activeIndex.includes(data.index)
      ? activeIndex.filter((idx) => index !== idx)
      : [...activeIndex, index];

    setActiveIndex(newIndex);
  };

  return (
    <>
      <Form>
        {/* Header */}
        <FormGroup inline style={{ marginBottom: "0px", background: "#D6ECF3" }}>
          {orderDetailHeader.map((headerData: any) => {
            return (
              <FormField inline width={headerData?.width}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    margin: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {headerData?.name || ""}
                </div>
              </FormField>
            );
          })}
        </FormGroup>

        {/* Data */}
        <FormGroup inline>
          <FormField width={16} inline style={{padding: "0px"}}>
            <div style={{ width: "100%", height: "calc(55dvh - 7.5rem)", overflow: "auto" }}>
              <Accordion>
                {(props.orderTemplateList || [])
                  .filter((item: any) => item.list.length > 0)
                  .map((item: any, index: number) => (
                    <div style={{ margin: "5px 0px", borderBottom: "1px solid #0072BC" }}>
                      <Accordion.Title
                        active={activeIndex.includes(index)}
                        index={index}
                        style={{
                          color: "#0072BC",
                        }}
                        onClick={handleClickAccordion}
                      >
                        <div style={{ fontSize: "16px", color: "#2185d0" }}>
                          <Icon
                            color="blue"
                            name={
                              activeIndex.includes(index)
                                ? "chevron circle down"
                                : "chevron circle right"
                            }
                            style={{ marginRight: "10px" }}
                          />
                          {`${item?.name} ${
                            item?.list?.length > 0 ? `(${item?.list?.length})` : ""
                          }`}
                        </div>
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex.includes(index)}>
                        <Form style={{ margin: "0px 5px" }}>
                          {(item?.list || []).map((data: any) => {
                            return (
                              <FormGroup inline>
                                <FormField inline width={5}>
                                  <div
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    {`[${data.code}] ${data?.name}`}
                                  </div>
                                </FormField>

                                <FormField inline width={6}>
                                  <div
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    {orderTemplateListDetail(item?.name, data)}
                                  </div>
                                </FormField>

                                <FormField inline width={2}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      width: "100%",
                                    }}
                                  >
                                    {orderTemplateListCount(item?.name, data)}
                                  </div>
                                </FormField>
                                <FormField inline width={2}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      width: "100%",
                                    }}
                                  >
                                    {`${data?.price_normal || 0}` || ""}
                                  </div>
                                </FormField>
                                <FormField inline width={2}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      width: "100%",
                                    }}
                                  >
                                    {orderTemplateListSomePrice(item?.name, data)}
                                  </div>
                                </FormField>

                                <FormField inline width={2}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      color="yellow"
                                      icon
                                      onClick={() => handleSeletedData(data, item?.name)}
                                    >
                                      <Icon name="edit outline" />
                                    </Button>
                                    <Button
                                      size="small"
                                      color="red"
                                      icon
                                      onClick={() => handleDeleteData(data?.id)}
                                    >
                                      <Icon name="minus" />
                                    </Button>
                                  </div>
                                </FormField>
                              </FormGroup>
                            );
                          })}
                        </Form>
                      </Accordion.Content>
                    </div>
                  ))}
              </Accordion>
            </div>
          </FormField>
        </FormGroup>
      </Form>

      <Modal
        Modal
        open={selectedId !== null}
        size={selectTemplate?.p_type_code === "DRUG" ? "large" : "small"}
        onClose={() => setSelectedId(null)}
      >
        <Segment
          inverted
          style={{ backgroundColor: "var(--primary-theme-color)", fontWeight: "bold" }}
        >
          {`รายละเอียดการสั่ง${headerModal?.[selectTemplate?.p_type_code]}`}
        </Segment>
        <FormMobEditOrderDetail
          runSequence={props.runSequence}
          setProp={props.setProp}
          selectedData={selectTemplate}
          OrderSetTemplateSequence={props.OrderSetTemplateSequence}
          masterOptions={props.masterOptions}
          supplyOrderEligibilityOptions={props.supplyOrderEligibilityOptions}
        />
        <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px 10px" }}>
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEditOrderDetail}
            // data
            paramKey={`${CARD_ORDER_SET_TEMPLATE}_EditOrderDetail`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_ORDER_SET_TEMPLATE}_EditOrderDetail`]}
            // config
            color="green"
            size="small"
            title={"บันทึก"}
          />
          <Button color="red" size="small" onClick={() => setSelectedId(null)}>
            ยกเลิก
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(FormOrderDetailList);
