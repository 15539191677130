import * as SetBedI from "./SetBed";
import * as DischargeSummaryI from "./DischargeSummary";
import * as WardQueueI from "./WardQueue"
import * as AdmitChangeI from "./AdmitChange"
import * as InpatientDiagnosisI from "./InpatientDiagnosis";
import * as PreAssessmentI from './PreAssessment'
import * as ReAssessmentI from './ReAssessment'
import * as ReportPatientIPDListI from "./ReportPatientIPDList";
import * as ReportPatientListI from "./ReportPatientList"
import * as AnesthesiaStatReportI from "./AnesthesiaStatReport";
import * as AddCoPhysicianI from "./AddCoPhysician";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  SetBed: {
    default: {
      START: SetBedI.Start,
      SearchOrEdit: SetBedI.SearchOrEdit,
    },
  },
  DischargeSummary: {
    default: {
      START: DischargeSummaryI.Start,
      SearchAndEdit: DischargeSummaryI.SearchAndEdit,
      CreateAndUpdate: DischargeSummaryI.CreateAndUpdate,
    },
  },
  WardQueue: {
    default: {
      START: WardQueueI.Start,
      FilterQueue: WardQueueI.FilterQueue,
      AdmitAction: WardQueueI.AdmitAction,
    },
  },
  AdmitChange: {
    default: {
      START: AdmitChangeI.Start,
      Action: AdmitChangeI.Action,
    },
  },
  InpatientDiagnosis: {
    default: {
      START: InpatientDiagnosisI.Start,
      Action: InpatientDiagnosisI.Action,
    },
  },
  PreAssessment: {
    default: {
      START: PreAssessmentI.Start,
      AddOrEdit: PreAssessmentI.AddOrEdit,
    },
  },
  ReAssessment: {
    default: {
      START: ReAssessmentI.Start,
      AddOrEdit: ReAssessmentI.AddOrEdit,
    },
  },
  ReportPatientIPDList: {
    default: {
      START: ReportPatientIPDListI.GetMaster,
      Action: ReportPatientIPDListI.Action,
    },
  },
  ReportPatientList: {
    default: {
      START: ReportPatientListI.GetMaster,
      Action: ReportPatientListI.Action,
    },
  },
  AnesthesiaStatReport: {
    default: {
      START: AnesthesiaStatReportI.Start,
      Action: AnesthesiaStatReportI.Action,
    },
  },
  AddCoPhysician: {
    default: {
      START: AddCoPhysicianI.Start,
      Action: AddCoPhysicianI.Action,
    },
  },
};

export default SequencePattern;
