import WasmController from "react-lib/frameworks/WasmController";
import preflightM from "issara-sdk/apis/preflightM";
import RequestToken from "issara-sdk/apis/RequestTokenAPI_users";
import GetProviderInfo from "issara-sdk/apis/GetProviderInfo_apps_HRM";
import DivisionHasUserList from "issara-sdk/apis/DivisionHasUserList_apps_PRX";
import DeviceList from "issara-sdk/apis/DeviceList_core";
import RoleList from "issara-sdk/apis/RoleList_users";
import UserProfileAPI from "issara-sdk/apis/UserProfileAPI_users";
import ScreenListView from "issara-sdk/apis/ScreenListView_users";
import GetCurrentDoctorView from "issara-sdk/apis/GetCurrentDoctorView_core";
import MyPositionDetail from "issara-sdk/apis/MyPositionDetail_users";
import MyPermissionView from "issara-sdk/apis/MyPermissionView_users";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import DivisionHasUserListForOtherUser from "issara-sdk/apis/DivisionHasUserListForOtherUser_apps_PRX";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";
import UserDetailAPIView from "issara-sdk/apis/UserDetailAPIView_users";

import { Mode } from "../Model";
import * as CONSTANT from "react-lib/utils/constant";
import CONFIG from "config/config";
import Cookies from "js-cookie";
import DivisionDetail from "issara-sdk/apis/DivisionDetail_core";

import axios from "axios";
import { getSideMenuIndex } from "../CommonInterface";
import { getDeviceIdAndUserSubScription, getBrowserName, getOSName } from "./GetUserSubScription";

export type State = {
  django?: any;
  isDoctor?: boolean;
  isNurse?: boolean;
  providerInfo?: any;
  providerEmployeeInfo?: any;
  userRoleList?: any;
  divisionType?: string | null;
  divisionList?: any[];
  divisionOptions?: any[];
  divisionHasUserItems?: any[];
  selectedDivision?: any;
  deviceOptions?: any[];
  selectedDevice?: any;
  showMenuNoti?: boolean;
  openVideoCallModal?: boolean;
  closeOverriding?: boolean;
  videoCallRoom?: string;
  currChatChannelId?: string;

  loggedin?: boolean;
  currentDoctor?: any;
  userPosition?: any;
  myPermission?: any;
  // offline Mode
  isOffline?: boolean;

  AppInitializeSequence?: {
    sequenceIndex: string | null;
    username?: string;
    password?: string;
  } | null;

  mode?: Mode;
  resetLayout?: boolean;
  openPassword?: boolean;
  loadingLogin?: boolean;
  errorMessage?: null;
  initialLayout?: number;

  userId?: string;
  // From CU
  showColorInfo?: boolean;
  showTabName: string;

  // Language
  userSelectedLanguage?: "en" | "th";
};

export const StateInitial: State = {
  django: {},
  isDoctor: false,
  isNurse: false,
  providerInfo: null,
  providerEmployeeInfo: null,
  userRoleList: [],
  divisionType: null,
  divisionList: [],
  divisionOptions: [],
  divisionHasUserItems: [],
  selectedDivision: null,
  deviceOptions: [],
  selectedDevice: null,
  showMenuNoti: false,

  loggedin: false,
  currentDoctor: null,
  userPosition: null,
  myPermission: null,

  isOffline: false,

  AppInitializeSequence: null,

  mode: "patient",
  resetLayout: false,
  openPassword: false,
  loadingLogin: false,
  errorMessage: null,
  initialLayout: 0,

  userId: Cookies.get("user_id"),
  userSelectedLanguage: (Cookies.get("user_lang") || window.navigator?.language?.split("-")?.[0])  as "en" | "th" || "en",
  showColorInfo: false,
  showTabName: "",
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  divisionHasUser?: any[];
  divisionDict?: any;
  allDivisionList?: any[];
  allDivisionDict?: any;
  divisionDetail?: any;
  device?: number;
  deviceDict?: any;
  user?: number;
  provider?: number;
  userProfile?: Record<string, any>;
};

export const DataInitial = {
  division: 0,
  divisionHasUser: [],
  divisionDict: {},
  allDivisionList: [],
  allDivisionDict: {},
  divisionDetail: {},
  device: 0,
  deviceDict: {},
  userProfile: {},
};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;


export const clearLoginSession: Handler = (controller, params) => {

  controller.apiToken = "";
  if (!CONFIG.OFFLINE) {
    controller.app.auth().signOut();
  }

  Cookies.set("apiToken", "");
  Cookies.set("division_id", "");
  Cookies.set("device_id", "");
  Cookies.set("user_id", "");
  Cookies.set("offlineMode", "online");

  const currentFilter = Cookies.get("filterDrugOrderQueue");

  if (currentFilter) {
    const filter = JSON.parse(currentFilter);

    // บันทึก Cookie ใหม่ที่มีเฉพาะ selectedSort
    Cookies.set("filterDrugOrderQueue", JSON.stringify({ selectedSort: filter.selectedSort }));
  }

  controller.data.provider = 0;
}

export const First: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.AppInitializeSequence) return;

  // Go to Login if no apitoken TEST current APIToken
  if (controller.apiToken) {
    const [r, e, n] = await preflightM.get({
      apiToken: controller.apiToken,
    })
    if (n?.response?.status === 401 && e) {
      clearLoginSession(controller, params)
    }
  }

  const apiToken = controller.apiToken;

  if (typeof apiToken === "undefined" || apiToken === null || apiToken === "") {
    console.log("not logged in");
    return controller.setState({
      AppInitializeSequence: {
        ...state.AppInitializeSequence,
        sequenceIndex: "Login",
        username: "",
        password: "",
      },
    });
  }
  // User already login on load, go straight to Greeting
  controller.setState(
    {
      AppInitializeSequence: {
        ...state.AppInitializeSequence,
        sequenceIndex: "GetUserInfo",
      },
    },
    () => controller.handleEvent({ message: "RunSequence", params: params })
  );
  return;
};

export const Login: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    loadingLogin: true,
  });
  if (!state.AppInitializeSequence) return;
  if (params?.action === "LoginSubmit") {
    console.log("called getFingerPrintAndSubscription");

    const [deviceId, userSubscription] = await getDeviceIdAndUserSubScription();
    console.log("userSubscription: ", userSubscription);
    console.log("deviceId: ", deviceId);

    // let deviceId = ""
    // let userSubscription = null;
    // if (config.BYPASS_SERVICE_WORKER) {
    //   // ไม่มี Service worker
    //   const options = {};
    //   let components = await Fingerprint2.getPromise(options);
    //   let values = components.map((component: any) => component.value);
    //   deviceId = Fingerprint2.x64hash128(values.join(""), 31);;
    //   console.log("bypass sw.js");
    // } else {
    //   console.log(" getFingerPrintAndSubscription ");
    //   let FPAndUS = await getFingerPrintAndSubscription();
    //   console.log(" getFingerPrintAndSubscription FPAndUS: ", FPAndUS);
    //   deviceId = FPAndUS[0];
    //   userSubscription = FPAndUS[1];
    //   console.log(" sw.js");
    // }

    console.log("called getFingerPrintAndSubscription Done");

    let deviceName = `${getBrowserName()} on ${getOSName()}`;
    let deviceToken = userSubscription ? JSON.stringify(userSubscription) : "";

    const login = await RequestToken.post({
      data: {
        username: state.AppInitializeSequence.username,
        password: state.AppInitializeSequence.password,
        device_id: deviceId,
        device_type: "webpush",
        device_name: deviceName,
        device_token: deviceToken,
        application: CONFIG.APP_NAME,
      },
      apiToken: controller.apiToken,
    });
    // if (login[1]) return console.log(login[1]);
    if (login) {
      console.log("login", login);
      controller.setState({
        loadingLogin: false,
      });

      // * Response: Error หรือ login แล้วไม่ได้ token
      const isError = login[1] || !login[0]?.token;
      console.log("isError: ", isError);

      if (isError) {
        console.log(login[1]);
        controller.setState({
          errorMessage:
            login[2]?.response?.status >= 500
              ? { server: "Internal Server 500" }
              : login[1] || { request_token_errors: ["CORS errors"] },
        });
        return;
      }
    }

    // Set token in cookie and controller for future use
    Cookies.set("apiToken", login[0]?.token);
    controller.apiToken = login[0].token;

    // Default Lang
    let sysLang = Cookies.get("user_lang") || window.navigator.language || "en-US"
    if (sysLang?.includes("th")) {
      sysLang = "th"
    } else {
      sysLang = "en"
    }

    Cookies.set("user_id", login[0].user_id.toString());
    Cookies.set("user_lang", sysLang)

    controller.data.user = login[0].user_id;
    // Successful login, go to GetUserInfo
    controller.setState(
      {
        AppInitializeSequence: {
          ...state.AppInitializeSequence,
          sequenceIndex: "GetUserInfo",
        },
        userSelectedLanguage: sysLang as "th" | "en",
      },
      () => controller.handleEvent({ message: "RunSequence", params: params })
    );

    params.onSuccess?.();
  }
};

export const GetUserInfo: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.AppInitializeSequence) return;

  const [
    divisions,
    preFlight,
    providerDoctor,
    providerEmployee,
    roleList,
    screenRes,
    divisionHasUser,
    userPosition,
    myPermission,
    userEmployee,
    userDetail,
    currentDoctor,
    divisionHasUserForOther,
  ] = await Promise.all([
    DivisionList.list({
      params: { limit: 9999},
      apiToken: controller.apiToken,
    }),
    preflightM.get({
      apiToken: controller.apiToken,
    }),
    GetProviderInfo.get({
      user_id: controller.data.user,
      params: { type: "doctor" },
      apiToken: controller.apiToken,
    }),
    GetProviderInfo.get({
      user_id: controller.data.user,
      apiToken: controller.apiToken,
    }),
    RoleList.list({
      params: {},
      apiToken: controller.apiToken,
    }),
    ScreenListView.list({
      apiToken: controller.apiToken,
      params: { limit: 9999 },
    }),
    DivisionHasUserList.list({
      params: {},
      apiToken: controller.apiToken,
    }),
    MyPositionDetail.retrieve({
      apiToken: controller.apiToken,
    }),
    MyPermissionView.list({
      apiToken: controller.apiToken,
    }),
    UserEmployeeDetailAPIView.retrieve({
      apiToken: controller.apiToken,
      pk: controller.data.user,
      extra: { division: controller.data.division },
    }),
    UserDetailAPIView.retrieve({
      apiToken: controller.apiToken,
      pk: controller.data.user,
      extra: { division: controller.data.division },
    }),
    GetCurrentDoctorView.get({
      apiToken: controller.apiToken,
    }),
    DivisionHasUserListForOtherUser.get({
      pk: Cookies.get("user_id"),
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    }),
  ]);

  const divisionHasUserForOtherList = divisionHasUserForOther?.[0]?.items?.map(
    (item: any) => item.division.id
  );
  // console.log('GetUserInfo divisions: ', divisions);
  const divisionList = groupDivisionFamily(null, divisions[0]?.items || []);
  // console.log('GetUserInfo divisionList: ', divisionList);

  // console.log('GetUserInfo filterDivision: ', filterDivision);

  // console.log('GetUserInfo providerDoctor: ', providerDoctor);
  // console.log('GetUserInfo providerEmployee: ', providerEmployee);
  // console.log('GetUserInfo roleList: ', roleList);
  // console.log('GetUserInfo userPosition: ', userPosition);
  // console.log('GetUserInfo userEmployee: ', userEmployee);
  // console.log('GetUserInfo userDetail: ', userDetail);
  // console.log('GetUserInfo currentDoctor: ', currentDoctor);

  const userProfile = { ...userEmployee[0], ...userDetail[0], employee: userEmployee[0]?.id };

  // Get provider and roleList ====================================================

  // นำ Screen Item inject กลับไปที่ CONFIG
  CONFIG.SCREEN_ITEM = screenRes?.[0]?.items;

  // Handle division-related data and state ========================================
  const allDivisionList: Record<string, any>[] = divisions?.[0]?.items || [];
  const allDivisionIds = new Set(allDivisionList.map((item) => item.id as number));

  const divisionHasUserItems: any[] = (divisionHasUser[0]?.items || [])
    .filter((i: any) => allDivisionIds.has(i.division?.id || ""))
    .filter((i: any) => {
      let extra = JSON.parse(i.extra);
      if (extra.is_Active) {
        return true;
      }
      return false;
    });
  console.log('GetUserInfo divisionHasUserItems: ', divisionHasUserItems);

  if (divisionHasUserItems?.length === 0 && currentDoctor?.[0]?.doctor ) {
    alert("This Doctor not HAVE any active division to perform")
  }
  // console.log('GetUserInfo divisionHasUser: ', divisionHasUser);

  const divisionItems = divisionHasUserItems.reduce((acc: any, item: any) => {
    if (!acc.map((a: any) => a.id).includes(item.division.id)) {

      let extraJSON: any = {}
      try {
        extraJSON = JSON.parse(item.extra);
      } catch (e) {
        console.log("e", e)
      }
      acc.push({ ...item.division,
        useRights: extraJSON?.useRights || [],
      });
    }

    return acc;
  }, []);

  const divisionOptions = divisionItems
    .map((item: any) => ({
      ...item.division,
      text: item.name,
      value: item.id,
    }))
    .concat(
      CONFIG.CREATE_OFFICIAL_ACCOUNT
        ? {
            id: "create_oa",
            code: "create_oa",
            name: "+ สร้าง Official Account",
            text: "+ สร้าง Official Account",
            value: "create_oa",
          }
        : []
    );

  const divisionDict = Object.fromEntries(divisionItems.map((item: any) => [item.id, item]));
  const allDivisionDict = Object.fromEntries(allDivisionList.map((item: any) => [item.id, item]));
  const filterDivision = divisionList?.flatMap((item: any) =>
    divisionHasUserForOtherList?.includes(item.id)
      ? [{ ...item, useRights: divisionDict[item.id]?.useRights || [] }]
      : []
  );

  console.log("GetUserInfo divisionDict: ", divisionDict);

  // Set selectedDivision to in controller.data (from cookies) or first in the list
  const selectedDivision =
    (controller.data.division &&
      controller.data.division !== 0 &&
      divisionDict[controller.data.division]) ||
    divisionItems[0] ||
    null;

  const divisionType = divisionDict?.[selectedDivision?.id || 0]?.type_label || null;
  console.log("ประเภทหน่วยงาน:", divisionType || "ไม่ทราบ");

  const [divisionDetail, deviceList] = await Promise.all([
    DivisionDetail.retrieve({
      pk: selectedDivision?.id,
      apiToken: controller.apiToken,
    }),
    DeviceList.list({
      params: { division: selectedDivision?.id || 0 },
      apiToken: controller.apiToken,
    }),
  ]);

  const deviceItems: any[] = deviceList[0]?.items || [];
  // console.log(deviceList[1] ? deviceList[1] : deviceList[0]);
  const deviceOptions = deviceItems.map((item: any) => ({
    key: item.id,
    value: item.id,
    text: item.computer_name,
  }));

  const deviceDict = Object.fromEntries(deviceItems.map((item: any) => [item.id, item]));

  // Set selectedDevice to in controller.data (from cookies) or first in the list
  const selectedDevice =
    (controller.data.device &&
      controller.data.device !== 0 &&
      deviceDict[controller.data.device]) ||
    deviceItems[0] ||
    null;

  // Set data  and state ============================================================
  controller.data = {
    ...controller.data,
    provider: providerDoctor?.[0]?.id || null,
    division: selectedDivision?.id || 0,
    divisionHasUser: divisionHasUser[0]?.items || [],
    divisionDict: divisionDict,
    allDivisionList,
    allDivisionDict,
    divisionDetail: !divisionDetail[1] ? divisionDetail[0] : {},
    device: selectedDevice?.id || 0,
    deviceDict: deviceDict,
    userProfile,
  };

  Cookies.set("division_id", controller.data.division!.toString());
  Cookies.set("device_id", controller.data.device!.toString());

  console.log("GetUserInfo CU divisionList: filterDivision", filterDivision);
  console.log("GetUserInfo HISV3 (NOT USE) divisionList: divisionItems", divisionItems);

  console.log(
    "GetUserInfo CU selectedDivision: selectedDivision",
    filterDivision?.find((div: any) => div.id === controller.data.division)
  );
  console.log("GetUserInfo HISV3 (NOT USE) selectedDivision: selectedDivision", selectedDivision);

  controller.setState({
    django: {
      ...preFlight[0],
      division: selectedDivision?.id || 0,
    },
    isDoctor: preFlight?.user?.role_types?.includes(CONSTANT.USER_ROLE_TYPE.DOCTOR) ? true : false,
    isNurse: preFlight?.user?.role_types?.includes(CONSTANT.USER_ROLE_TYPE.REGISTERED_NURSE)
      ? true
      : false,
    providerInfo: {
      ...providerDoctor[0],
      provider_id: providerDoctor[0]?.id,
      first_name: providerDoctor[0]?.doctor_info?.first_name,
      last_name: providerDoctor[0]?.doctor_info?.last_name,
      pre_name: providerDoctor[0]?.doctor_info?.pre_name,
      code: providerDoctor[0]?.doctor_info?.code,
    },
    providerEmployeeInfo: providerEmployee[0],
    userRoleList: roleList[0] ? roleList[0]?.items || [] : [],
    divisionType: divisionType,
    selectedTabIndex: getSideMenuIndex(
      params.defaultSideMenu ? { tab: params.defaultSideMenu } : { divisionType }
    ),
    divisionList: filterDivision, // divisionItems,
    divisionOptions: divisionOptions,
    divisionHasUserItems: divisionHasUserItems,
    selectedDivision: selectedDivision,
    deviceOptions: deviceOptions,
    selectedDevice, // selectedDevice,
    loggedin: true,
    currentDoctor: currentDoctor?.[0]?.doctor,
    userPosition: userPosition?.[0],
    myPermission: myPermission?.[0],
    userId: preFlight[0]?.user?.id?.toString(),
    userProfile,
  });

  // Login Firebase --------------------------------------------------------

  controller.setState(
    {
      AppInitializeSequence: {
        ...state.AppInitializeSequence,
        sequenceIndex: "LoginFirebase",
      },
    },
    () => controller.handleEvent({ message: "RunSequence", params: params })
  );
};

export const LoginFirebaseWithCustomToken: Handler = (controller, params) => {
  console.log("LoginFirebaseWithCustomToken : signInWithCustomToken ");
  let retryWithFastAPI = false;
  // FUNCTION
  if (!CONFIG.OFFLINE) {
    controller.functions
      .httpsCallable("createTokenFromUser")(params?.userId?.toString())
      .then((result: any) => {
        controller.app
          .auth()
          .signInWithCustomToken(result.data)
          .then(async (res: any) => {
            console.log("LoginFirebaseWithCustomToken signInWithCustomToken res: ", res);
            console.log(
              "LoginFirebaseWithCustomToken signInWithCustomToken res.user.getIdToken();: ",
              res.user.getIdToken()
            );
          })
          .catch(function (error: any) {
            LoginFirebaseWithFastAPI(controller, {});
            console.log(error);
          });
      })
      .catch((error: any) => {
        LoginFirebaseWithFastAPI(controller, {});
        console.log(error);
      });
  }
};

export const LoginFirebaseWithFastAPI: Handler = async (controller, params) => {
  const FAST_API = `${CONFIG.FAST_API}`;
  axios
    .post(
      `${FAST_API}/api/login/get-firebase-token`,
      {},
      { headers: { Authorization: `Token ${controller.apiToken}` } }
    )
    .then((result: any) => {
      console.log("result: ", result);
      controller.app
        .auth()
        .signInWithCustomToken(result.data.token)
        .then(async (res: any) => {
          console.log("LoginFirebaseWithCustomToken signInWithCustomToken res: ", res);
          console.log(
            "LoginFirebaseWithCustomToken signInWithCustomToken res.user.getIdToken();: ",
            res.user.getIdToken()
          );
          // Test login with firebase id token
          // const firebase_id_token = await res.user.getIdToken();
          // axios.post(
          //   `${FAST_API}/api/login/get-firebase-user`, {},
          //   { headers: { "Authorization": `Token ${firebase_id_token}`}}
          // ).then((res: any) => {
          //     console.log("uid", res.data);
          // })
          // .catch((err: any) => {
          //   console.log(err);
          // });
        })
        .catch(function (error: any) {
          console.error("LoginFirebaseWithCustomToken error: ", error);
          console.log(error);
        });
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const LoginFirebase: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("LoginFirebase check controller: ", controller);

  const onFirebaseAuthStateChanged = async (user: any) => {
    if (!user) {
      console.log("User is signed out");
      const apiToken = controller.apiToken;
      const userId = controller.data.user;
      if (apiToken && userId) {
        LoginFirebaseWithCustomToken(controller, { userId: userId });
      }
    } else {
      console.log("User is signed in, ", user.uid);
    }
  };

  // LoginFirebaseWithCustomToken(controller, controller.data.user );
  if (!CONFIG.OFFLINE) {
    controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
  }

  // Finishing up --------------------------------------------------------
  controller.setState(
    {
      AppInitializeSequence: {
        ...state.AppInitializeSequence,
        sequenceIndex: "Finish",
      },
    },
    () => controller.handleEvent({ message: "RunSequence", params: params })
  );
};

export const Finish: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.AppInitializeSequence) return;

  // Finish the sequence
  controller.setState({
    AppInitializeSequence: null,
  });
};

const groupDivisionFamily: (parent: number | null, items: any[]) => any[] = (parent, items) => {
  if (!parent) {
    return items.map((item: any) => ({
      ...item,
      children: groupDivisionFamily(item.id, items),
    }));
  }
  return items
    .filter((item: any) => item.parent === parent)
    .map((item: any) => ({
      ...item,
      children: groupDivisionFamily(item.id, items),
    }));
};
