import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  FormField,
  FormGroup,
  Input,
  Modal,
  Segment,
  TextArea,
} from "semantic-ui-react";
//STYLE
import "react-lib/apps/HISV3/common/CardOrderSetTemplate.scss";
import { Table } from "react-lib/frameworks/Table";
import { supplyMode } from "react-lib/apps/HISV3/common/FormOrderDetailList";
import { URGENCY_OPTIONS } from "react-lib/apps/HISV3/LAB/CardLabRequest";
import TimeFreeTextBox24 from "react-lib/apps/common/TimeFreeTextBox24";

const toBePerformerOption = [
  { key: 1, value: "Doctor", text: "Doctor" },
  { key: 2, value: "Nurse", text: "Nurse" },
  { key: 3, value: "Other", text: "Other" },
];

const drugOrderType = [
  { key: "Stat", value: "Stat", text: "Stat" },
  { key: "One Day", value: "One Day", text: "One Day" },
  { key: "One Dose", value: "One Dose", text: "One Dose" },
  { key: "Home Med", value: "Home Med", text: "Home Med" },
];

const supplyOrderType = [
  { key: "HOME_OPD", value: "HOME_OPD", text: "HOME_OPD" },
  { key: "REFILL_OPD", value: "REFILL_OPD", text: "REFILL_OPD" },
  { key: "STAT", value: "STAT", text: "STAT" },
  { key: "COST_CENTER", value: "COST_CENTER", text: "COST_CENTER" },
];

type FormMobEditOrderDetailProps = {
  runSequence: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  selectedData?: Record<string, any>;
  OrderSetTemplateSequence?: Record<string, any>;
  // options
  masterOptions?: Record<string, any>;
  supplyOrderEligibilityOptions?: any[];
};

const FormMobEditOrderDetail = (props: FormMobEditOrderDetailProps) => {
  const selectDetail = props.OrderSetTemplateSequence?.selectDetail;

  const searchIcd9Data = useMemo(() => {
    return (props.OrderSetTemplateSequence?.searchIcd9 || [])?.map((item: any) => {
      return item;
    });
  }, [props.OrderSetTemplateSequence?.searchIcd9]);

  const handleChangeData = (e: any, v: any) => {
    let value = v.type === "checkbox" ? v.checked : v.value;

    props.setProp(`OrderSetTemplateSequence.selectDetail`, {
      ...props.OrderSetTemplateSequence?.selectDetail,
      [v.name]: value,
    });
  };

  const handleSearchICD9 = () => {
    props.runSequence({
      sequence: "OrderSetTemplate",
      action: "searchIcd9",
    });
  };

  const handleSelectedICD9Data = (data: any) => {
    props.setProp(`OrderSetTemplateSequence`, {
      ...props.OrderSetTemplateSequence,
      selectDetail: {
        ...props.OrderSetTemplateSequence?.selectDetail,
        icd9: data,
        icd9Name: data?.icdterm,
      },
      searchIcd9: [],
    });
  };

  return (
    <div style={{ margin: "20px" }}>
      {props.selectedData?.p_type_code === "DRUG" ? (
        <Form>
          <FormGroup
            inline
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormField inline>
              <label
                style={{ fontWeight: "bold", fontSize: "14px", margin: "10px 0px" }}
              >{`[${selectDetail?.code}] ${selectDetail?.name}`}</label>
            </FormField>
            <FormField inline width={3}>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                  ประเภท
                </div>
                <Dropdown
                  fluid={true}
                  onChange={handleChangeData}
                  options={drugOrderType}
                  search={true}
                  selection={true}
                  name="orderType"
                  value={selectDetail?.orderType}
                ></Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Dose</label>
              <Input
                type="number"
                value={selectDetail?.dose}
                fluid
                name="dose"
                onChange={handleChangeData}
              />
            </FormField>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Unit</label>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={props.masterOptions?.unit}
                search={true}
                selection={true}
                name="drugUnits"
                value={selectDetail?.drugUnits}
              ></Dropdown>
            </FormField>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Route</label>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={props.masterOptions?.route}
                search={true}
                selection={true}
                name="route"
                value={selectDetail?.route}
              ></Dropdown>
            </FormField>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Site</label>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={props.masterOptions?.site}
                search={true}
                selection={true}
                name="site"
                value={selectDetail?.site}
              ></Dropdown>
            </FormField>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Frequency</label>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={props.masterOptions?.frequency}
                search={true}
                selection={true}
                name="frequency"
                value={selectDetail?.frequency}
              ></Dropdown>
            </FormField>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Method</label>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={props.masterOptions?.method}
                search={true}
                selection={true}
                name="method"
                value={selectDetail?.method}
              ></Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            {/* "ตามแพทย์สั่ง (UD)" */}
            {selectDetail?.frequency === 330 ? (
              <FormField width={12}>
                <label style={{ margin: "10px 0px" }}>Description</label>
                <Input
                  fluid={true}
                  onChange={handleChangeData}
                  name="description"
                  value={selectDetail?.description}
                ></Input>
              </FormField>
            ) : (
              <FormField width={12}>
                <label style={{ margin: "10px 0px" }}>PRN</label>
                <Input
                  fluid={true}
                  onChange={handleChangeData}
                  name="prn"
                  value={selectDetail?.prn}
                ></Input>
              </FormField>
            )}
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>QTY</label>
              <Input
                fluid={true}
                onChange={handleChangeData}
                name="qty"
                value={selectDetail?.qty}
              ></Input>
            </FormField>
            <FormField width={3}></FormField>
          </FormGroup>
        </Form>
      ) : props.selectedData?.p_type_code === "PROC" ? (
        <Form>
          <FormGroup
            inline
            style={{
              display: "flex",
            }}
          >
            <FormField inline>
              <label
                style={{ fontWeight: "bold", fontSize: "14px", margin: "10px 0px" }}
              >{`[${selectDetail?.code}] ${selectDetail?.name}`}</label>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>QTY</label>
            </FormField>
            <FormField width={10}>
              <Input
                type="number"
                value={selectDetail?.qty}
                fluid
                name="qty"
                onChange={handleChangeData}
              />
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Eligibility type</label>
            </FormField>
            <FormField width={10}>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={props.masterOptions?.method}
                search={true}
                selection={true}
                name="method"
                value={selectDetail?.method}
              ></Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>ระบุ ICD9</label>
            </FormField>
            <FormField width={10}>
              <Input
                value={selectDetail?.icd9Name}
                fluid
                name="icd9Name"
                onChange={handleChangeData}
              />
              <div
                style={{
                  display: searchIcd9Data?.length === 0 ? "none" : "block",
                  zIndex: 100,
                }}
              >
                <Table
                  data={searchIcd9Data}
                  getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
                    return {
                      onClick: () => {
                        handleSelectedICD9Data(rowInfo.original);
                      },
                    };
                  }}
                  keys="medterm,icdterm,icdcode"
                  minRows="4"
                  showPagination={false}
                  style={{ height: "150px" }}
                ></Table>
              </div>
            </FormField>
            <FormField width={3}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button color="blue" size="small" onClick={handleSearchICD9}>
                  ค้นหา ICD9
                </Button>
              </div>{" "}
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>ผู้ทำหัตถการ</label>
            </FormField>
            <FormField width={10}>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={toBePerformerOption}
                search={true}
                selection={true}
                name="toBePerformer"
                value={selectDetail?.toBePerformer}
              ></Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>รายละเอียดเพิ่มเติม</label>
            </FormField>
            <FormField width={10}>
              <TextArea
                value={selectDetail?.orderDetail}
                fluid
                name="orderDetail"
                onChange={handleChangeData}
                style={{ width: "100%", height: "100%", border: "solid #cccccc 1px" }}
              ></TextArea>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>อุปกรณ์</label>
            </FormField>
            <FormField width={10}>
              <TextArea
                value={selectDetail?.equipmentDetail}
                fluid
                name="equipmentDetail"
                onChange={handleChangeData}
                style={{ width: "100%", height: "100%", border: "solid #cccccc 1px" }}
              ></TextArea>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Remark</label>
            </FormField>
            <FormField width={10}>
              <TextArea
                value={selectDetail?.remarkDetail}
                fluid
                name="remarkDetail"
                onChange={handleChangeData}
                style={{ width: "100%", height: "100%", border: "solid #cccccc 1px" }}
              ></TextArea>
            </FormField>
          </FormGroup>
        </Form>
      ) : props.selectedData?.p_type_code === "SUPPLY" ? (
        <Form>
          <FormGroup
            inline
            style={{
              display: "flex",
            }}
          >
            <FormField inline>
              <label
                style={{ fontWeight: "bold", fontSize: "14px", margin: "10px 0px" }}
              >{`[${selectDetail?.code}] ${selectDetail?.name}`}</label>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <div style={{ display: "flex", margin: "10px 0px" }}>
                <label style={{ fontWeight: "bold" }}>Mode</label>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  {supplyMode[selectDetail?.mode] || ""}
                </div>
              </div>
            </FormField>
            <FormField width={1}></FormField>
            <FormField width={1}>
              <label style={{ margin: "10px 0px" }}>QTY</label>
            </FormField>
            <FormField width={3}>
              <Input
                type="number"
                value={selectDetail?.qty}
                fluid
                name="qty"
                onChange={handleChangeData}
              />
            </FormField>
            <FormField width={1}></FormField>
            <FormField width={4}>
              <div style={{ display: "flex", margin: "10px 0px" }}>
                <label style={{ fontWeight: "bold", width: "100%" }}>ขนาดบรรจุ</label>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  {selectDetail?.unit_name}
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Supply type</label>
            </FormField>
            <FormField width={10}>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={supplyOrderType}
                search={true}
                selection={true}
                name="type"
                value={selectDetail?.type}
              ></Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Eligibility type</label>
            </FormField>
            <FormField width={10}>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={props.supplyOrderEligibilityOptions || []}
                search={true}
                selection={true}
                name="eligibility_type"
                value={selectDetail?.eligibility_type}
              ></Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Remark</label>
            </FormField>
            <FormField width={10}>
              <TextArea
                value={selectDetail?.remarkDetail}
                fluid
                name="remarkDetail"
                onChange={handleChangeData}
                style={{ width: "100%", height: "100%", border: "solid #cccccc 1px" }}
              ></TextArea>
            </FormField>
          </FormGroup>
        </Form>
      ) : props.selectedData?.p_type_code === "LAB" ? (
        <Form>
          <FormGroup
            inline
            style={{
              display: "flex",
            }}
          >
            <FormField inline>
              <label
                style={{ fontWeight: "bold", fontSize: "14px", margin: "10px 0px" }}
              >{`[${selectDetail?.code}] ${selectDetail?.name}`}</label>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={8}>
              <div style={{ display: "flex", margin: "10px 0px" }}>
                <label style={{ fontWeight: "bold" }}>Specimen: </label>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      backgroundColor: "gray",
                      borderRadius: "50%",
                      width: "1.5em",
                      height: "1.5em",
                      margin: "0px 10px",
                    }}
                  ></div>
                  <div>{selectDetail?.specimen_name}</div>
                </div>
              </div>
            </FormField>
            <FormField width={1}></FormField>
            <FormField width={4}>
              <div style={{ display: "flex", margin: "10px 0px" }}>
                <label style={{ fontWeight: "bold", width: "100%" }}>การส่งตรวจ: </label>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  {selectDetail?.lab_type_label}
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Specimen time</label>
            </FormField>
            <FormField width={3}>
              <div style={{ display: "flex", height: "100%", width: "100%" }}>
                <TimeFreeTextBox24
                  className="time-custom-react-picker"
                  onChange={(value: string) => {
                    props.setProp("OrderSetTemplateSequence.selectDetail.specimen_time", value);
                  }}
                  value={selectDetail?.specimen_time}
                  iconClockStyle={{ margin: "auto 30px" }}
                  autoFocus={false}
                  checkTimeOverflow={true}
                ></TimeFreeTextBox24>
              </div>
            </FormField>
            <FormField width={2}></FormField>
            <FormField width={2}>
              <label style={{ margin: "10px 0px" }}>Urgency</label>
            </FormField>
            <FormField width={3}>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={URGENCY_OPTIONS}
                search={true}
                selection={true}
                name="urgency"
                value={selectDetail?.urgency}
              ></Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={3}>
              <label style={{ margin: "10px 0px" }}>Note</label>
            </FormField>
            <FormField width={10}>
              <TextArea
                value={selectDetail?.remarkDetail}
                fluid
                name="remarkDetail"
                onChange={handleChangeData}
                style={{ width: "100%", height: "100%", border: "solid #cccccc 1px" }}
              ></TextArea>
            </FormField>
          </FormGroup>
        </Form>
      ) : props.selectedData?.p_type_code === "XRAY" ? (
        <Form>
          <FormGroup
            inline
            style={{
              display: "flex",
            }}
          >
            <FormField inline>
              <label
                style={{ fontWeight: "bold", fontSize: "14px", margin: "10px 0px" }}
              >{`[${selectDetail?.code}] ${selectDetail?.name}`}</label>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={7}>
              <div style={{ display: "flex", margin: "10px 0px" }}>
                <label style={{ fontWeight: "bold" }}>X-ray Group </label>
                <div style={{ margin: "0px 10px" }}>{selectDetail?.xrayGroup}</div>
              </div>
            </FormField>
            <FormField width={1}></FormField>
            <FormField width={2}>
              <label style={{ margin: "10px 0px" }}>Eligibility</label>
            </FormField>
            <FormField width={6}>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={props.supplyOrderEligibilityOptions || []}
                search={true}
                selection={true}
                name="eligibility_type"
                value={selectDetail?.eligibility_type}
              ></Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={2}>
              <label style={{ margin: "10px 0px" }}>QTY</label>
            </FormField>
            <FormField width={3}>
              <Input
                type="number"
                value={selectDetail?.qty}
                fluid
                name="qty"
                onChange={handleChangeData}
              />
            </FormField>
            <FormField width={3}></FormField>
            <FormField width={2}>
              <label style={{ margin: "10px 0px" }}>Urgency</label>
            </FormField>
            <FormField width={6}>
              <Dropdown
                fluid={true}
                onChange={handleChangeData}
                options={URGENCY_OPTIONS}
                search={true}
                selection={true}
                name="urgency"
                value={selectDetail?.urgency}
              ></Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField width={2}>
              <label style={{ margin: "10px 0px" }}>Note</label>
            </FormField>
            <FormField width={14}>
              <TextArea
                value={selectDetail?.remarkDetail}
                fluid
                name="remarkDetail"
                onChange={handleChangeData}
                style={{ width: "100%", height: "100%", border: "solid #cccccc 1px" }}
              ></TextArea>
            </FormField>
          </FormGroup>
        </Form>
      ) : (
        ""
      )}
    </div>
  );
};

export default React.memo(FormMobEditOrderDetail);
