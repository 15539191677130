import WasmController from "react-lib/frameworks/WasmController";
// apis
import DrugDetail from "issara-sdk/apis/DrugDetail_apps_TPD";
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";
import AdverseReactionCheck from "issara-sdk/apis/AdverseReactionCheck_apps_ADR";

export type State = {
  // CommonInterface
  masterOptions?: any;
  errorMessage?: any;
  successMessage?: any;

  // DrugSelect
  DrugSelectSequence?: any;
  drugPermission?: any;

  // selected data
  selectedDrug?: any;

  // mod
  modSolventSelectionConfig?: {
    openModal?: boolean;
    showButtonSolvent?: boolean;
    showButtonIVSave?: boolean;
    editableQuantity?: boolean;
    editable?: boolean;

    // options
    drugSolventSearchOptions?: any[];
    dosageUnitOptions?: any[];
    dripInOptions?: any[];
    ivRateOptions?: any[];
    concentrationOptions?: any[];

    // data
    solvent?: number | null;
    selectedSolvent?: any;

    txtMaxRate?: string;
    txtMaxRateColor?: string;
    txtNoRate?: string;
    txtMaxConc?: string;

    max_concentration_rate?: number | null;
    max_concentration_unit?: number | null;
    max_rate?: number | null;

    dripRate?: string;

    drug_strength?: number | null;
    drug_strength_choice?: number | null;

    volume?: string;

    drug_drip_in?: number | null;
    drug_drip_in_unit?: number | null;

    mixture_iv_rate?: number | null;
    mixture_iv_rate_unit?: number | null;

    quantity?: number | null;

    solute_concentration_rate?: number | null;
    solvent_concentration_rate?: number | null;
    conc_choice?: number | null;

    soluteQuantity?: number | null;
    soluteStockSize?: number | null;
    soluteBaseUnit?: number | null;
    solventStockSize?: number | null;
    solventBaseUnit?: number | null;

    //
    admixtureMode?: string;
    admixtureSolute?: any | null;
    admixtureIndex?: number | null;
  };
};

export const StateInitial: State = {
  // DrugSelect
  DrugSelectSequence: null,
  drugPermission: null,

  // selected data
  selectedDrug: null,

  // mod
  modSolventSelectionConfig: {
    openModal: false,
    showButtonSolvent: false,
    showButtonIVSave: false,
    editableQuantity: true,
    editable: true,

    // options
    drugSolventSearchOptions: [],
    dosageUnitOptions: [],
    dripInOptions: [],
    ivRateOptions: [],
    concentrationOptions: [],

    // data
    solvent: null,
    selectedSolvent: {},

    txtMaxRate: "",
    txtMaxRateColor: "",
    txtNoRate: "",
    txtMaxConc: "",

    max_concentration_rate: null,
    max_concentration_unit: null,
    max_rate: null,

    dripRate: "",

    drug_strength: null,
    drug_strength_choice: null,

    volume: "",

    drug_drip_in: null,
    drug_drip_in_unit: null,

    mixture_iv_rate: null,
    mixture_iv_rate_unit: null,

    quantity: null,

    solute_concentration_rate: null,
    solvent_concentration_rate: null,
    conc_choice: null,

    soluteQuantity: null,
    soluteStockSize: null,
    soluteBaseUnit: null,
    solventStockSize: null,
    solventBaseUnit: null,

    //
    admixtureMode: "",
    admixtureSolute: null,
    admixtureIndex: null,
  },
};

export type Event =
  // set
  | {
      message: "initModSolventSelection";
      params: {
        drugOrderType: string;
        solventId: number | null;
        admixtureOptions: any;
      };
    }
  | {
      message: "SetModSolventSelectionData";
      params: { name: string; value: any };
    }
  | { message: "SetModSolventSelectionConfig"; params: {} }
  // get
  | { message: "GetDrugSolventList"; params: { name: string } }
  | { message: "GetDrugSolventDetail"; params: { id: number } }
  // save
  | { message: "SaveSolventOldData" , params: any}
  | { message: "SaveSolventData"; params: { drugOrderType: string } }
  | { message: "RemoveSolventData", params: any};

export type Data = {
  division?: number;
};

export const DataInitial = {};

export const MOD_SOLVENT_SELECTION_ID: string = "ModSolventSelection";

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

// set
export const initModSolventSelection: Handler = async (controller, params) => {
  let state = controller.getState();
  let drugSelectSeq: any = { ...state.DrugSelectSequence };
  let modSolventConfig: any = {
    ...state.modSolventSelectionConfig,
    showButtonSolvent: false,
  };

  if (drugSelectSeq?.is_intravaneous && state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
    modSolventConfig.showButtonSolvent = true;
  }

  const updateState = async (drugSelectSeq: any, modSolventConfig: any) => {
    controller.setState({
      DrugSelectSequence: drugSelectSeq,
      modSolventSelectionConfig: modSolventConfig,
    } as any);
  };

  const handleSolventDetail = async (solvent?: Record<string, any>) => {
    if (params.skipSolventHandling) {
      return;
    }

    await updateState(drugSelectSeq, modSolventConfig);
    // await GetDrugSolventDetail(controller, { id: solventId });
    state = controller.getState();

    drugSelectSeq = { ...state.DrugSelectSequence };
    modSolventConfig = {
      ...state.modSolventSelectionConfig,
      selectedSolvent: solvent?.id ? solvent : null,
      solvent: solvent?.id || null,
    };

    setDrugSolventSearchOptions(modSolventConfig);
  };

  if (state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
    modSolventConfig = await clear(controller, modSolventConfig, params.skipSolventHandling);

    // modSolventConfig.drug_strength = Number.parseInt(params.admixtureOptions?.strength) || 1;
    // modSolventConfig.max_concentration_rate = drugSelectSeq?.max_concentration_rate;
    // modSolventConfig.max_concentration_unit = drugSelectSeq?.max_concentration_unit;
    // modSolventConfig.max_rate = drugSelectSeq?.max_rate_text;

    setDrugAdministrationOptions(modSolventConfig, drugSelectSeq, state);

    if (params?.solvent?.id) {
      await handleSolventDetail(params?.solvent);

      // modSolventConfig.volume = drugSelectSeq?.stock_size || 0;
      // modSolventConfig.solvent = params?.solventId;
    } else if (drugSelectSeq?.default_solvent && modSolventConfig.activeButtonSolvent) {
      if (params.drugOrderType !== "CONTINUE_PLAN") {
        const { adr, isAdrToProcess } = await EvaluateAdverseReaction(controller, {
          ...params,
          selectedDrug: { id: drugSelectSeq.default_solvent },
        });

        if (isAdrToProcess) {
          drugSelectSeq = {
            ...drugSelectSeq,
            sequenceIndex: "FeedbackADR",
            adrType: adr?.status,
            solventAdrNotes: adr?.notes,
          };
        }
      }

      await handleSolventDetail({
        id: drugSelectSeq.default_solvent,
        full_name: drugSelectSeq.default_solvent_name,
        label_name: drugSelectSeq.default_solvent_name,
      });
    }

    // if (
    //   !params.admixtureOptions?.quantity &&
    //   Number.parseInt(drugSelectSeq?.default_solvent_quantity)
    // ) {
    //   modSolventConfig.quantity = Number.parseInt(drugSelectSeq?.default_solvent_quantity) || 1;
    // }

    // if (
    //   !params.admixtureOptions?.mixture_iv_rate &&
    //   Number.parseFloat(drugSelectSeq?.default_iv_rate)
    // ) {
    //   modSolventConfig.mixture_iv_rate = drugSelectSeq?.default_iv_rate;
    // }
    // }

    // modSolventConfig.drug_strength_choice = params.admixtureOptions?.strength_unit || "";
    setConfigFromParams(modSolventConfig, params);

    if (
      params.admixtureOptions?.solute_concentration_rate &&
      params.admixtureOptions?.solvent_concentration_rate
    ) {
      modSolventConfig.solute_concentration_rate = Number.parseInt(
        params.admixtureOptions.solute_concentration_rate
      );
      modSolventConfig.solvent_concentration_rate = Number.parseInt(
        params.admixtureOptions.solvent_concentration_rate
      );
      modSolventConfig.concentration = "conc";
    } else {
      modSolventConfig.concentration = "strength";
    }

    modSolventConfig.dripRate =
      params.admixtureOptions?.drug_drip_in && params.admixtureOptions?.drug_drip_in_unit
        ? "drip"
        : "rate";

    if (
      params.drugOrderType &&
      modSolventConfig?.dripRate === "rate" &&
      modSolventConfig.max_rate
    ) {
      // modSolventConfig.txtMaxRate = modSolventConfig.max_rate;
      // modSolventConfig.txtMaxRateColor = "blue";
    }
  } else {
    modSolventConfig = await clear(controller, modSolventConfig, params.skipSolventHandling);

    // if (params.admixtureOptions?.quantity) {
    //   modSolventConfig.old_quantity = drugSelectSeq?.quantity;
    // }

    // if (params.admixtureOptions?.mixture_iv_rate) {
    //   modSolventConfig.old_rate = drugSelectSeq?.mixture_iv_rate;
    // }

    // modSolventConfig.solventStockSize = null;
    // modSolventConfig.solventBaseUnit = null;
    // modSolventConfig.soluteQuantity = Number.parseInt(params.admixtureOptions?.soluteQuantity) || 1;
    // modSolventConfig.soluteStockSize = drugSelectSeq?.stock_size;
    // modSolventConfig.soluteBaseUnit = drugSelectSeq?.base_unit_name;

    if (params.solventId) {
      await handleSolventDetail(params.solventId);

      modSolventConfig.solvent = params.solventId;
    } else {
      await handleSolventDetail(drugSelectSeq?.default_solvent);

      // if (
      //   !Number.parseInt(params.admixtureOptions?.quantity) &&
      //   Number.parseInt(drugSelectSeq?.default_solvent_quantity)
      // ) {
      //   modSolventConfig.old_quantity = drugSelectSeq?.default_solvent_quantity;
      // }

      // if (
      //   !Number.parseFloat(params.admixtureOptions?.mixture_iv_rate) &&
      //   Number.parseFloat(drugSelectSeq?.default_iv_rate)
      // ) {
      //   modSolventConfig.old_rate = drugSelectSeq?.default_iv_rate;
      // }
    }
  }

  modSolventConfig.openModal = true;
  modSolventConfig.priority = params.priority;

  const searchList = (modSolventConfig.drugSolventSearchOptions || []).map((option: any) => ({
    id: option.value,
    full_name: option.text,
  }));
  const arrayUniqueByKey = [
    ...new Map(
      [...searchList, ...(state.searchedItemListWithKey?.Drug_EMO || [])].map((item) => [
        item.id,
        item,
      ])
    ).values(),
  ];

  controller.setState({
    // DrugSelectSequence: drugSelectSeq,
    modSolventSelectionConfig: modSolventConfig,
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      Drug_EMO: arrayUniqueByKey,
    },
  } as any);
};

const setConfigFromParams = (config: any, params: any) => {
  const { admixtureOptions } = params;

  if (admixtureOptions?.quantity) {
    config.quantity = Number.parseInt(admixtureOptions.quantity);
  }

  if (admixtureOptions?.mixture_iv_rate) {
    config.mixture_iv_rate = Number.parseFloat(admixtureOptions.mixture_iv_rate);
  }

  if (admixtureOptions?.mixture_iv_rate_unit) {
    config.mixture_iv_rate_unit = Number.parseInt(admixtureOptions.mixture_iv_rate_unit);
  }

  if (admixtureOptions?.drug_drip_in) {
    config.drug_drip_in = Number.parseInt(admixtureOptions.drug_drip_in);
  }

  if (admixtureOptions?.drug_drip_in_unit) {
    config.drug_drip_in_unit = Number.parseInt(admixtureOptions.drug_drip_in_unit);
  }

  if (admixtureOptions?.unit) {
    config.unit = Number.parseInt(admixtureOptions.unit);
  }

  if (admixtureOptions?.price_unit) {
    config.price_unit = admixtureOptions.price_unit;
  }

  if (admixtureOptions?.concentration_unit) {
    config.conc_choice = Number.parseInt(admixtureOptions.concentration_unit);
  }

  if (admixtureOptions?.volume && Number.parseFloat(admixtureOptions.volume)) {
    config.volume = Number.parseFloat(admixtureOptions.volume);
  }
};

const setDrugSolventSearchOptions = (config: any) => {
  config.drugSolventSearchOptions = [
    {
      key: config.selectedSolvent?.id,
      text: config.selectedSolvent?.label_name,
      value: config.selectedSolvent?.id,
    },
  ];
};

const setDrugAdministrationOptions = (config: any, drugSelectSeq: any, state: any) => {
  const optionPairs = [
    ["dosageUnitOptions", "limited_dosage_units"],
    ["dripInOptions", "limited_drug_drip_in"],
    ["ivRateOptions", "limited_iv_rate"],
    ["concentrationOptions", "limited_drug_concentration"],
  ];

  const unit: any[] = state.masterOptions?.unit || [];

  for (const [configKey, seqKey] of optionPairs) {
    config[configKey] =
      drugSelectSeq[seqKey]?.length > 0
        ? unit.filter((option: any) => (drugSelectSeq[seqKey] as number[]).includes(option.key))
        : unit;
  }
};

export const SetModSolventSelectionData: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  let modSolventConfig: any = { ...state.modSolventSelectionConfig };
  modSolventConfig[params.name] = params.value;

  if (params.name === "dripRate") {
    modSolventConfig.txtMaxRate = "";
    modSolventConfig.txtMaxRateColor = "";

    if (params.value === "rate") {
      modSolventConfig.drug_drip_in = "";
      modSolventConfig.drug_drip_in_unit = null;

      if (modSolventConfig?.max_rate) {
        modSolventConfig.txtMaxRate = `ค่า Rate มาตรฐาน: ${modSolventConfig?.max_rate}`;
        modSolventConfig.txtMaxRateColor = "blue";
      }
    }

    if (params.value === "drip") {
      modSolventConfig.mixture_iv_rate = "";
      modSolventConfig.mixture_iv_rate_unit = null;
    }
  }

  await controller.setState({ modSolventSelectionConfig: modSolventConfig });

  if (params.name === "strength" && parseFloat(params.value) > 0) {
    checkMaxConc(controller);
    recalculateIV(controller, "SoluteConc");
    recalculateConc(controller);
  } else if (params.name === "drug_strength_choice") {
    checkMaxConc(controller);
  } else if (params.name === "volume" && parseFloat(params.value) > 0) {
    recalculateIV(controller, "SolventConc");
    checkMaxConc(controller);
    recalculateConc(controller);
  } else if (
    params.name === "solute_concentration_rate" &&
    parseFloat(params.value) > 0
  ) {
    recalculateConc(controller);
    recalculateIV(controller, "SoluteDose");
  } else if (
    params.name === "solvent_concentration_rate" &&
    parseFloat(params.value) > 0
  ) {
    recalculateConc(controller);
    recalculateIV(controller, "SolventVolume");
  } else if (params.name === "conc_choice") {
    checkMaxConc(controller);
  }
};

export const SetModSolventSelectionConfig: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    modSolventSelectionConfig: {
      ...state.modSolventSelectionConfig,
      ...params,
    },
  } as any);
};

// get
export const GetDrugSolventList: Handler = async (controller, params) => {
  const state = controller.getState();

  const [response, error, _network] = await DrugList.list({
    params: { solvent_for: state.selectedDrug?.id, name: params.name },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });
  if (error) {
  } else {
    const result = response?.items?.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.full_name,
    }));
    controller.setState({
      modSolventSelectionConfig: {
        ...state.modSolventSelectionConfig,
        drugSolventSearchOptions: result,
      },
    } as any);
  }
};

export const GetDrugSolventDetail: Handler = async (controller, params) => {
  let adr: any = [{}, null];
  let isAdrToProcess = false;

  if (params.orderType !== "CONTINUE_PLAN") {
    ({ adr, isAdrToProcess } = await EvaluateAdverseReaction(controller, {
      ...params,
      selectedDrug: { id: params.id },
    }));
  }

  const [response, error] = await DrugDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.id,
  });

  if (error) {
    return;
  }

  const state = controller.getState();

  let modSolventConfig: Record<string, any> = { ...state.modSolventSelectionConfig };

  modSolventConfig = {
    ...modSolventConfig,
    selectedSolvent: response,
    solvent: response.id,
  };

  if (state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
    const drug = state.DrugSelectSequence;

    const frequencyId =
      params.orderType === "CONTINUE_PLAN" ? drug.frequency : drug.selectedDrugFrequencyId;

    const frequency: any[] = controller.data?.masterData?.frequency || [];
    const foundFrequency = frequency.find((item) => item.id === frequencyId);

    // if (Number.parseFloat(modSolventConfig.selectedSolvent?.stock_size)) {
    //   modSolventConfig.volume = modSolventConfig.selectedSolvent?.stock_size;
    //   modSolventConfig.solvent_default_stock_size = modSolventConfig.selectedSolvent?.stock_size;
    // } else {
    //   modSolventConfig.volume = "NO SOLVENT STOCK SIZE";
    //   modSolventConfig.solvent_default_stock_size = 1;
    // }

    // if (!state.DrugSelectSequence?.quantity) {
    //   modSolventConfig.quantity = 1;
    // }
    modSolventConfig.unit = modSolventConfig.unit || response.default_volume_unit;
    modSolventConfig.volume =
      modSolventConfig.volume || Number(response.default_volume_for_mixing) || "";
    modSolventConfig.quantity =
      modSolventConfig.quantity ||
      response.default_solvent_quantity ||
      foundFrequency?.dose_per_day;

    modSolventConfig.showButtonIVSave = true;

    if (modSolventConfig.volume !== "NO SOLVENT STOCK SIZE") {
      const ivResult = recalculateIV(controller, "SolventConc");
      const concResult = recalculateConc(controller);

      modSolventConfig = { ...modSolventConfig, ...ivResult, ...concResult };
    }

    const maxConcResult = checkMaxConc(controller);

    modSolventConfig = { ...modSolventConfig, ...maxConcResult };
  } else {
    // Todo
    // modSolventConfig.solventStockSize = response?.stock_size;
    // modSolventConfig.solventBaseUnit = response?.base_unit_name;
  }

  controller.setState({
    modSolventSelectionConfig: modSolventConfig,
    ...(isAdrToProcess && {
      DrugSelectSequence: {
        ...state.DrugSelectSequence,
        sequenceIndex: "FeedbackADR",
        adrType: adr?.status,
        solventAdrNotes: adr?.notes,
      },
    }),
  });
};

// save
export const SaveSolventOldData: Handler = async (controller) => {
  if (!validateData(controller)) {
    return;
  }

  const state = controller.getState();

  let drugSelectSeq: any = { ...state.DrugSelectSequence };
  let modSolventConfig: any = { ...state.modSolventSelectionConfig };

  if (modSolventConfig.solvent) {
    modSolventConfig.openModal = false;

    if (drugSelectSeq?.is_intravaneous) {
      drugSelectSeq.solvent_product = modSolventConfig.solvent;
      drugSelectSeq.solvent_quantity = modSolventConfig.old_quantity;
      drugSelectSeq.iv_rate = modSolventConfig.old_rate;
    } else {
      drugSelectSeq.solvent_product = modSolventConfig.solvent;
      drugSelectSeq.solvent_quantity = modSolventConfig.old_quantity;
      drugSelectSeq.mixture_iv_rate = modSolventConfig.old_rate;
    }
    controller.setState({
      DrugSelectSequence: drugSelectSeq,
      modSolventSelectionConfig: modSolventConfig,
    });
  }
};

export const SaveSolventData: Handler = async (controller, params) => {
  if (!validateData(controller)) {
    return;
  }

  const state = controller.getState();

  const { drugPermission }= state;

  let { DrugSelectSequence: drugSelectSeq, modSolventSelectionConfig: modSolventConfig } = state;

  drugSelectSeq = { ...drugSelectSeq };
  modSolventConfig = { ...modSolventConfig };

  const { errors, isValid } = checkRequiredFields(
    modSolventConfig,
    params.drugOrderType,
    state.drugPermission
  );

  console.log("modSolventConfig", modSolventConfig, isValid, errors);

  if (!isValid && errors.length > 0 && params.isRequireRate) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [MOD_SOLVENT_SELECTION_ID]: errors,
      },
    });

    return;
  }
  // if (!isRequiredFields) {
  //   controller.setState({ modSolventSelectionConfig: modSolventConfig });

  //   return;
  // }

  // if (!modSolventConfig.solvent) {
  //   return;
  // }

  const result = createResultObject(modSolventConfig, params.drugOrderType);

  updateDrugSelectSeq(drugSelectSeq, result, drugPermission);

  controller.setState(
    {
      DrugSelectSequence: drugSelectSeq,
      modSolventSelectionConfig: {
        ...modSolventConfig,
        isRequireRate: params.isRequireRate,
        openModal: false,
      },
      errorMessage: {
        ...state.errorMessage,
        [MOD_SOLVENT_SELECTION_ID]: null,
      },
    },
    () => {
      params.onSuccess?.();
    }
  );
};

const checkRequiredFields = (
  config: Record<string, any>,
  drugOrderType: string,
  drugPermission: Record<string, any>
) => {
  const errors: Record<string, string>[] = [];

  if (
    config.concentration === "conc" &&
    !(config.solute_concentration_rate && config.solvent_concentration_rate && config.conc_choice)
  ) {
    config.txtNoRate = "required: \nConc. solute\n Conc. solvent \nConc. unit";
  }

  if (drugOrderType && drugPermission.config_TPD_ENABLE_IV_COMPATIBLE) {
    if (config.dripRate === "drip" && !(config.drug_drip_in && config.drug_drip_in_unit)) {
      config.txtNoRate = "required: \ndrip in \ndrip in unit";
    } else if (
      config.dripRate === "rate" &&
      !(config.mixture_iv_rate && config.mixture_iv_rate_unit)
    ) {
      config.txtNoRate = "required: \ndrug rate \ndrug rate unit";
    }
  }

  // เพิ่มการตรวจสอบ mixture_iv_rate, drug_drip_in และ unit ของทั้งสอง
  if (config.dripRate === "drip") {
    if (!config.drug_drip_in ) {
      errors.push({ "Drip Duration": "กรุณาระบุค่า" });
    } else if (Number.parseInt(config.drug_drip_in) < 1) {
      errors.push({ "Drip Duration": "กรุณาระบุให้มีจำนวนมากกว่า 0" });
    }

    if (!config.drug_drip_in_unit) {
      errors.push({ "Drip Duration unit": "กรุณาระบุหน่วย" });
    }
  }

  if (config.dripRate === "rate") {
    if (!config.mixture_iv_rate ) {
      errors.push({ "Drip Rate": "กรุณาระบุค่า" });
    } else if (Number.parseInt(config.mixture_iv_rate) < 1) {
      errors.push({ "Drip Rate": "กรุณาระบุให้มีจำนวนมากกว่า 0" });
    }

    if (!config.mixture_iv_rate_unit) {
      errors.push({ "Drip Rate unit": "กรุณาระบุหน่วย" });
    }
  }

  return {
    errors,
    isValid: errors.length === 0 && !config.txtNoRate,
  };
};

const createResultObject = (config: Record<string, any>, drugOrderType: string) => {
  const result: Record<string, any> = {
    concChoice: null,
    drugDripln: null,
    drugDriplnUnit: null,
    drugStrength: config.drug_strength,
    drugStrengthChoice: config.drug_strength_choice,
    mixturelvRate: null,
    mixturelvRateUnit: null,
    quantity: config.quantity,
    soluteConcentrationRate: null,
    solvent: config.solvent,
    solventConcentrationRate: null,
    volume: config.volume,
  };

  if (drugOrderType) {
    if (config.dripRate === "drip") {
      result.drugDriplln = config.drug_drip_in;
      result.drugDriplnUnit = config.drug_drip_in_unit;
    } else {
      result.mixturelVRate = config.mixture_iv_rate;
      result.mixturelVRateUnit = config.mixture_iv_rate_unit;
    }
  }

  if (config.concentration !== "strength") {
    result.soluteConcentrationRate = config.solute_concentration_rate;
    result.solventConcentrationRate = config.solvent_concentration_rate;
    result.concChoice = config.conc_choice;
  }

  return result;
}

const updateDrugSelectSeq = (
  drugSelectSeq: Record<string, any>,
  result: Record<string, any>,
  drugPermission: Record<string, any>
) => {
  const isIVCompatible = drugPermission.config_TPD_ENABLE_IV_COMPATIBLE;

  if (drugSelectSeq.is_intravaneous) {
    if (isIVCompatible && drugSelectSeq.add_auto_solvent) {
      Object.assign(drugSelectSeq, {
        // dose: result.drugStrength,
        drug_concentration_unit: result.concChoice,
        drug_drip_in: Number.parseInt(result.drugDriplln),
        drug_drip_in_unit: result.drugDriplnUnit,
        is_intravaneous: true,
        mixture_concentration_solute: result.soluteConcentrationRate,
        mixture_concentration_solvent: result.solventConcentrationRate,
        mixture_iv_rate: result.mixturelVRate,
        mixture_iv_rate_unit: result.mixturelVRateUnit,
        mixture_solvent_volume: result.volume,
        solvent_product: result.solvent,
        solvent_quantity: result.quantity,
        // unit: result.drugStrengthChoice,
      });
    } else if (!isIVCompatible) {
      Object.assign(drugSelectSeq, {
        mixture_iv_rate: result.mixturelVRate,
        solvent_product: result.solvent,
        solvent_quantity: result.quantity,
      });
    }
  } else {
    Object.assign(drugSelectSeq, {
      mixture_iv_rate: result.mixturelVRate,
      solvent_product: result.solvent,
      solvent_quantity: result.quantity,
    });

    if (isIVCompatible) {
      Object.assign(drugSelectSeq, {
        // dose: result.drugStrength,
        drug_concentration_unit: result.concChoice,
        drug_drip_in: Number.parseInt(result.drugDriplln),
        drug_drip_in_unit: result.drugDriplnUnit,
        iv_rate: result.mixturelVRate,
        iv_rate_unit: result.mixturelVRateUnit,
        mixture_concentration_solute: result.soluteConcentrationRate,
        mixture_concentration_solvent: result.solventConcentrationRate,
        mixture_iv_rate_unit: result.mixturelVRateUnit,
        mixture_solvent_volume: result.volume,
        // unit: result.drugStrengthChoice,
      });
    }
  }
};

export const RemoveSolventData: Handler = (controller) => {
  const state = controller.getState();

  let drugSelectSeq: any = { ...state.DrugSelectSequence };

  drugSelectSeq.solvent_product = null;
  drugSelectSeq.solvent_quantity = 0;
  drugSelectSeq.mixture_solvent_volume = null;
  drugSelectSeq.mixture_iv_rate = null;
  drugSelectSeq.mixture_iv_rate_unit = null;
  drugSelectSeq.mixture_concentration_solute = null;
  drugSelectSeq.mixture_concentration_solvent = null;
  drugSelectSeq.drug_concentration_unit = null;
  drugSelectSeq.drug_drip_in = null;
  drugSelectSeq.drug_drip_in_unit = null;

  let modSolventConfig: any = { ...state.modSolventSelectionConfig };
  modSolventConfig.openModal = false;

  controller.setState({
    DrugSelectSequence: drugSelectSeq,
    modSolventSelectionConfig: modSolventConfig,
  });
};

// calculation
const checkMaxConc = (controller: any) => {
  const state = controller.getState();
  let modSolventConfig: any = { ...state.modSolventSelectionConfig };

  if (
    modSolventConfig?.max_concentration_rate &&
    modSolventConfig?.max_concentration_unit
  ) {
    // unit
    const unitTextList: any[] =
      modSolventConfig?.drugUnits ||
      []?.filter((item: any) => {
        return item.value === modSolventConfig?.max_concentration_unit;
      });
    const unitText = unitTextList?.[0]?.text;

    // conc rate
    let concRateUnit: string = "";
    if (
      modSolventConfig?.concentration === "strength" &&
      modSolventConfig?.drug_strength_choice
    ) {
      const concRateUnitList: any[] =
        state.DrugSelectSequence?.drugUnits?.filter((item: any) => {
          return (
            item.value === parseInt(modSolventConfig?.drug_strength_choice)
          );
        });
      if (concRateUnitList.length > 0) {
        concRateUnit = concRateUnitList?.[0]?.text + "/mL";
      }
    }
    if (
      modSolventConfig?.concentration === "conc" &&
      modSolventConfig?.conc_choice
    ) {
      const concRateUnitList: any[] =
        state.DrugSelectSequence?.drugUnits?.filter((item: any) => {
          return item.value === parseInt(modSolventConfig?.conc_choice);
        });
      if (concRateUnitList.length > 0) {
        concRateUnit = concRateUnitList?.[0]?.text;
      }
    }

    // max conc
    let result: any = { ...state.modSolventSelectionConfig };
    result.txtMaxConc = `มาตรฐานค่าความเข้มข้นที่มากสุด คือ ${modSolventConfig?.max_concentration_rate} ${unitText}`;
    result.txtMaxConcColor = "blue";

    if (
      parseFloat(modSolventConfig?.drug_strength) / parseFloat(result?.volume) >
        modSolventConfig?.max_concentration_rate &&
      concRateUnit &&
      concRateUnit === unitText
    ) {
      result.txtMaxConc = `ความเข้มข้นของสารละลายเกินมาตรฐาน ซึ่งอยู่ที่ ${modSolventConfig?.max_concentration_rate} ${unitText}`;
      result.txtMaxConcColor = "red";
    }
    controller.setState({ modSolventSelectionConfig: result });
  }
};

const recalculateIV = (controller: any, calculateField: string) => {
  const state = controller.getState();

  let modSolventConfig: any = { ...state.modSolventSelectionConfig };

  if (
    !modSolventConfig?.solvent ||
    !parseFloat(modSolventConfig?.volume) ||
    !parseFloat(modSolventConfig?.drug_strength) ||
    !parseFloat(modSolventConfig?.solute_concentration_rate) ||
    !parseFloat(modSolventConfig?.solvent_concentration_rate)
  ) {
    return;
  }
  if (
    modSolventConfig?.concentration === "conc" &&
    (calculateField === "SoluteDose" || calculateField === "SolventVolume")
  ) {
    modSolventConfig.volume =
      parseFloat(modSolventConfig?.solvent_default_stock_size) *
      parseFloat(modSolventConfig?.solute_concentration_rate);
    modSolventConfig.drug_strength =
      parseFloat(modSolventConfig?.solvent_default_stock_size) *
      parseFloat(modSolventConfig?.solute_concentration_rate);
    controller.setState({ modSolventSelectionConfig: modSolventConfig });
    return;
  }

  let calculate = 0.0;
  let multipierSoluteDose = calculateMultipler(
    parseFloat(modSolventConfig?.drug_strength)
  );
  let multipierSolventVolume = calculateMultipler(
    parseFloat(modSolventConfig?.volume)
  );

  // calculate multipier of solute and solvent to make it value to integer
  if (multipierSoluteDose > multipierSolventVolume) {
    multipierSolventVolume = multipierSoluteDose;
  } else {
    multipierSoluteDose = multipierSolventVolume;
  }

  if (calculateField === "SoluteConc") {
    calculate =
      (parseFloat(modSolventConfig?.drug_strength) *
        multipierSoluteDose *
        parseFloat(modSolventConfig?.solvent_concentration_rate)) /
      (parseFloat(modSolventConfig?.volume) * multipierSolventVolume);
    modSolventConfig.solute_concentration_rate = calculate;
  }
  if (calculateField === "SoluteDose") {
    calculate =
      (parseFloat(modSolventConfig?.solute_concentration_rate) *
        parseFloat(modSolventConfig?.volume)) /
      parseFloat(modSolventConfig?.solvent_concentration_rate);
    modSolventConfig.drug_strength = calculate;
  }
  if (calculateField === "SolventConc") {
    calculate =
      (parseFloat(modSolventConfig?.volume) *
        multipierSolventVolume *
        parseFloat(modSolventConfig?.solute_concentration_rate)) /
      (parseFloat(modSolventConfig?.drug_strength) * multipierSolventVolume);
    // modSolventConfig.solvent_concentration_rate = calculate;
  }
  if (calculateField === "SolventVolume") {
    calculate =
      (parseFloat(modSolventConfig?.solvent_concentration_rate) *
        parseFloat(modSolventConfig?.drug_strength)) /
      parseFloat(modSolventConfig?.solute_concentration_rate);
    modSolventConfig.volume = calculate;
  }
  controller.setState({ modSolventSelectionConfig: modSolventConfig });
};

const recalculateConc = (controller: any) => {
  const state = controller.getState();
  let modSolventConfig: any = { ...state.modSolventSelectionConfig };

  if (
    !modSolventConfig?.solvent ||
    !parseFloat(modSolventConfig?.volume) ||
    !parseFloat(modSolventConfig?.drug_strength) ||
    !parseFloat(modSolventConfig?.solute_concentration_rate) ||
    !parseFloat(modSolventConfig?.solvent_concentration_rate)
  ) {
    return;
  }

  let concSolU: number = parseFloat(
    modSolventConfig?.solute_concentration_rate
  );
  let concSolV: number = parseFloat(
    modSolventConfig?.solvent_concentration_rate
  );

  if (concSolU === concSolV) {
    // modSolventConfig.solute_concentration_rate = 1;
    // modSolventConfig.solvent_concentration_rate = 1;
    controller.setState({ modSolventSelectionConfig: modSolventConfig });
    return;
  }

  // reformat Concentration to interger
  const concSolUMultipier: number = calculateMultipler(
    parseFloat(modSolventConfig?.solute_concentration_rate)
  );
  const concSolVMultipier: number = calculateMultipler(
    parseFloat(modSolventConfig?.solvent_concentration_rate)
  );
  if (concSolUMultipier > concSolVMultipier) {
    concSolV = concSolV * concSolUMultipier;
    concSolU = concSolU * concSolUMultipier;
  } else {
    concSolV = concSolV * concSolVMultipier;
    concSolU = concSolU * concSolVMultipier;
  }

  // find GCD
  if (!concSolU || !concSolV) {
    return;
  }
  let i: number = 2;
  while (true) {
    if (concSolU % i === 0 && concSolV % i === 0) {
      concSolV = concSolV / i;
      concSolU = concSolU / i;
      i = 2;
    }

    if (concSolU === 1 || concSolV === 1 || i >= concSolU || i >= concSolV) {
      break;
    }
    i = i + 1;
  }

  // modSolventConfig.solute_concentration_rate = concSolU;
  // modSolventConfig.solvent_concentration_rate = concSolV;
  controller.setState({ modSolventSelectionConfig: modSolventConfig });
};

const calculateMultipler = (value: number) => {
  var multipier = 1;
  while (true) {
    if (value % 1 === 0) {
      break;
    } else {
      multipier = multipier * 10;
      value = value * 10;
    }
  }
  return multipier;
};

//
const clear = (controller: any, modSolventConfig: any, skipSolventHandling = false) => {
  let state = controller.getState();

  if (!skipSolventHandling) {
    modSolventConfig.solvent = null;
    modSolventConfig.selectedSolvent = null;
  }

  if (state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
    modSolventConfig.txtMaxRate = "";
    modSolventConfig.txtNoRate = "";
    modSolventConfig.txtMaxConc = "";

    modSolventConfig.volume = "";
    modSolventConfig.drug_drip_in = "";
    modSolventConfig.mixture_iv_rate = "";
    modSolventConfig.quantity = "";
    // modSolventConfig.solute_concentration_rate = 1;
    // modSolventConfig.solvent_concentration_rate = 1;
    modSolventConfig.mixture_iv_rate_unit = "";
    modSolventConfig.drug_drip_in_unit = "";
    modSolventConfig.drug_strength_choice = "";
    modSolventConfig.conc_choice = "";
    // Todo
    // txtVolume.enabled = false;
    // btnIVSave.enabled = false;

    // txtConcSolute.readOnly = false;
    // txtConcSolvent.readOnly = false;
    // stbSolvent.readOnly = false;
    // txtDrugDrip.readOnly = false;
    // txtVolume.readOnly = false;
    // txtQuantity.readOnly = false;
    // txtRate.readOnly = false;
    // txtStrength.readOnly = false;
    // cboConcUnit.readOnly = false;
    // cboDrugDripUnit.readOnly = false;
    // cboDrugRateUnit.readOnly = false;
    // cboStrengthUnit.readOnly = false;
    // radioDrugConcentrate.readOnly = false;
    // radioStrength.readOnly = false;
    // radioDrugDrip.readOnly = false;
    // radioDrugRate.readOnly = false;
    // btnCancel.text = "CANCEL";
  } else {
    // Todo
    // txtOldQuantity.text = "";
    // txtOldRate.text = "";
  }
  return modSolventConfig;
};

const validateData = (controller: any) => {
  const state = controller.getState();

  const modSolventConfig: any = { ...state.modSolventSelectionConfig };
  const error = [];
  const active = modSolventConfig.activeButtonSolvent;

  if (state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
    if (active) {
      if (!modSolventConfig?.solvent) {
        error.push({ Solvent: "กรุณาระบุค่า" });
      }

      if (modSolventConfig?.concentration === "strength") {
        if (!modSolventConfig?.volume) {
          error.push({ "Volume (For mixing)": "กรุณาระบุค่า" });
        } else if (Number.parseInt(modSolventConfig?.volume) < 1) {
          error.push({ "Volume (For mixing)": "กรุณาระบุให้มีจำนวนมากกว่า 0" });
        }
      }

      if (!modSolventConfig?.quantity) {
        error.push({ "Total Qty (per dispense)": "กรุณาระบุค่า" });
      } else if (Number.parseInt(modSolventConfig?.quantity) < 1) {
        error.push({ "Total Qty (per dispense)": "กรุณาระบุให้มีจำนวนมากกว่า 0" });
      }

      if (!modSolventConfig?.unit) {
        error.push({ "Unit (of volume)": "กรุณาระบุค่า" });
      }
    }

    // if (!modSolventConfig?.drug_strength) {
    //   error.push({ drug_strength: "กรุณาระบุค่า" });
    // } else if (Number.parseInt(modSolventConfig?.drug_strength) < 1) {
    //   error.push({ drug_strength: "กรุณาระบุให้มีจำนวนมากกว่า 0" });
    // }

    // if (
    //   !modSolventConfig?.solute_concentration_rate ||
    //   modSolventConfig?.solute_concentration_rate === ""
    // ) {
    //   error.push({ solute_concentration_rate: "กรุณาระบุค่า" });
    // } else if (parseInt(modSolventConfig?.solute_concentration_rate) < 1) {
    //   error.push({ solute_concentration_rate: "กรุณาระบุให้มีจำนวนมากกว่า 0" });
    // }

    // if (
    //   !modSolventConfig?.solvent_concentration_rate ||
    //   modSolventConfig?.solvent_concentration_rate === ""
    // ) {
    //   error.push({ solvent_concentration_rate: "กรุณาระบุค่า" });
    // } else if (parseInt(modSolventConfig?.solvent_concentration_rate) < 1) {
    //   error.push({
    //     solvent_concentration_rate: "กรุณาระบุให้มีจำนวนมากกว่า 0",
    //   });
    // }
  } else {
    if (!modSolventConfig?.solvent || modSolventConfig?.solvent === "") {
      error.push({ solvent: "กรุณาระบุค่า" });
    }
    if (!modSolventConfig?.old_quantity || modSolventConfig?.old_quantity === "") {
      error.push({ old_quantity: "กรุณาระบุค่า" });
    } else if (parseInt(modSolventConfig?.old_quantity) < 1) {
      error.push({ old_quantity: "กรุณาระบุให้มีจำนวนมากกว่า 0" });
    }
  }

  if (error.length > 0) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [MOD_SOLVENT_SELECTION_ID]: error,
      },
      successMessage: {
        ...state.successMessage,
        [MOD_SOLVENT_SELECTION_ID]: null,
      },
    });
    return false;
  }
  return true;
};

export const EvaluateAdverseReaction: Handler = async (
  controller,
  params: {
    patient?: string;
    selectedDrug: Record<string, any>;
    skipChecking: boolean;
  }
) => {
  const state = controller.getState();

  if (params.skipChecking) {
    return { adr: [{}, null], isAdrToProcess: false };
  }

  const adr = await AdverseReactionCheck.get({
    adversary: params.selectedDrug.id,
    apiToken: controller.apiToken,
    params: {
      emr: state.selectedEmr?.id,
      encounter: state.selectedEncounter.id,
      progression_cycle: null,
    },
    patient: state.selectedEncounter.patient_id || params.patient,
  });

  const isAdrToProcess = !adr[1] && ["LOCKED", "NOTED", "PAUSED"].includes(adr[0].status);

  return { adr: adr[0], isAdrToProcess };
};