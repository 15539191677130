import React, { useEffect, useState, useMemo, useRef } from "react";
import { Tab } from "semantic-ui-react";
import moment from "moment";
import _ from "../../compat/lodashplus";
import * as Util from "../../utils";

import CardAdminQueueWard from "./CardAdminQueueWard";
import CardReviewQueueWard from "./CardReviewQueueWard";
import CardPreAdminQueueWard from "./CardPreAdminQueueWard";
import { useIntl } from "react-intl";

import {
  CardMedAdminQueueWardStateType,
  CardMedAdminQueueWardInitial,
} from "./CardPreAdminQueueWard";

export type FilterMedRecordType = {
  ward: number;
  date: string;
  time: string;
  checkedTime: boolean;
  division: any;
  hn: any;
  dateFiltered?: string;
};

export type CardQueueWardStateType = {} & CardMedAdminQueueWardStateType;

export const CardQueueWardInitial: CardQueueWardStateType = {
  ...CardMedAdminQueueWardInitial,
};

const currentTime = () => Util.formatDatetime(moment()).split(" ")[1];

export type DRUG_TYPE_COLOR =
  | ""
  | "RESUSCITATION"
  | "STAT"
  | "HOME_OPD"
  | "SELLING"
  | "HOME_IPD"
  | "CONTINUE_PLAN"
  | "CONTINUE"
  | "ONE_DOSE"
  | "ONE_DAY"
  | "REFILL"
  | "OPERATING"
  | "URGENT";

export const TYPE_COLOR = {
  "": "black",
  RESUSCITATION: "orange",
  STAT: "red",
  HOME_OPD: "violet",
  SELLING: "violet",
  HOME_IPD: "purple",
  CONTINUE_PLAN: "teal",
  CONTINUE: "blue",
  ONE_DOSE: "olive",
  ONE_DAY: "olive",
  DAILY: "olive",
  REFILL: "pink",
  OPERATING: "orange",
  URGENT: "red",
};

const panes = [
  // {
  //   menuItem: "Pre Admin",
  //   render: (props: any) => (
  //     <Tab.Pane>
  //       <CardPreAdminQueueWard {...props} />
  //     </Tab.Pane>
  //   ),
  // },
  {
    menuItem: "Admin",
    render: (props: any) => (
      <Tab.Pane>
        <CardAdminQueueWard
          {...props}
        />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Review",
    render: (props: any) => (
      <Tab.Pane>
        <CardReviewQueueWard
          {...props}
        />
      </Tab.Pane>
    ),
  },
];

/* ------------------------------------------------------ */

/*                   Display Drug Detail                  */

/* ------------------------------------------------------ */
export const DisplayDrugDetail = (props: any) => {
  const intl = useIntl();
  return (
    <>
      <p style={{ margin: "0.2em" }}>
        <div>
          {!props.byLabel
            ? props.drugInfo.changing_detail?.split("\n")[1] ||
              `${+props.drugInfo.dose} ${props.drugInfo.code_unit} ${props.drugInfo.code_method} ${
                props.drugInfo.code_frequency
              }`
            : // `${+props.drugInfo.dose} ${props.drugInfo.name_unit} ${
              //   props.drugInfo.label_th_route
              // } ${props.drugInfo.label_th_site} ${
              //   props.drugInfo.label_th_frequency
              // } ${props.drugInfo.label_th_site} ${props.drugInfo.description} ${
              //   ["BLANK", "NA"].includes(props.drugInfo.code_method)
              //     ? ""
              //     : props.drugInfo.label_th_method
              // }`
              `${props.drugInfo?.label_th_route} ${props.drugInfo?.label_th_site} ${intl.formatMessage({id: "ครั้งละ"})} ${+props.drugInfo
                ?.dose} ${props.drugInfo?.name_unit} ${props.drugInfo?.label_th_frequency} ${
                props.drugInfo?.prn
              } ${props.drugInfo?.description}\n`}
        </div>
        {props.drugInfo?.drip_duration || props.drugInfo?.drip_rate ? (
          <>
            <div>{props.drugInfo?.drip_duration && <>Drip duration : {props.drugInfo?.drip_duration}</>}</div>
            <div>{props.drugInfo?.drip_rate && <>Drip rate : {props.drugInfo?.drip_rate}</>}</div>
          </>
        ) : props.drugInfo?.solvent?.drip || props.drugInfo?.solvent?.rate ? (
          <>
            <div>{props.drugInfo?.solvent?.drip !== "-" && <>Drip duration : {props.drugInfo?.solvent?.drip}</>}</div>
            <div>{props.drugInfo?.solvent?.rate !== "-" && <>Drip rate : {props.drugInfo?.solvent?.rate}</>}</div>
          </>
        ) : (
          <></>
        )}
        {props.drugInfo?.solvent && (
          <div>
            <b>+ {props.drugInfo?.solvent?.drug_label || props.drugInfo?.solvent?.name}</b>
            <p style={{ margin: "0.2em" }}>
              VOLUME: {props.drugInfo?.solvent.volume}
            </p>
          </div>
        )}
        <div>
          {props.drugInfo?.highalert && (
            <div style={{ color: "red" }}>
              <b>HAD Monitoring</b>
              {`: ${props.drugInfo?.highalert_description}`}
            </div>
          )}
        </div>
        <div>
          {props.drugInfo?.helper_label &&
            props.drugInfo.helper_label?.split("\n")?.map((item: any) => {
              return (
                <div>
                  <b>Helper label</b>
                  {`: ${item}`}
                </div>
              );
            })}
        </div>
      </p>
    </>
  );
};

const CardQueueWard: React.FC<CardQueueWardStateType> = (props) => {
  const [filterMedRecord, setFilterMedRecord] = useState<FilterMedRecordType>({
    ward: props.django?.division?.id,
    date: Util.formatDate(moment()),
    time: currentTime(),
    checkedTime: false,
    division: " ",
    hn: null,
  });
  const barcodeHNRef = useRef();
  const [medicationRecord, setMedicationRecord] = useState<any[]>([]);
  const [userProfile, setUserProfile] = useState(null)

  const activeIndex = useRef<any>(0);
  const filterMedRecordRef = useRef<any>(null);

  useEffect(() => {
    if (props.django && props.wardTypeList) {
      const compare = _.find(props.wardTypeList, {
        id: props.django?.division?.id,
      });

      if (compare && !filterMedRecord.ward) {
        setFilterMedRecord({
          ...filterMedRecord,
          ward: props.django.division.id,
        });
      }
    }
  }, [props.django, props.wardTypeList]);

  useEffect(() => {
    const getUserDetail = async () => {
      const res = await props.controller.userManager.getUserDetail?.({ id: props.django.user.id });

      if (res?.[0]) {
        setUserProfile(res[0]);
      }
    };

    if (props.django) {
      getUserDetail();
    }
  }, [props.django]);

  useEffect(() => {
    filterMedRecordRef.current = filterMedRecord;
  }, [filterMedRecord])


  useEffect(() => {
    if (props.medicationRecord && props.tabCurrentIndex === activeIndex.current) {
      onSetFilter("dateFiltered", filterMedRecordRef.current?.date);

      setMedicationRecord(props.medicationRecord);
    }
  }, [props.medicationRecord]);

  const onSetFilter = (key: keyof FilterMedRecordType, value: any) => {
    setFilterMedRecord({
      ...filterMedRecord,
      [key]: value,
    });
  };

  const handleAddMedicationRecord = (drugInfo: any) => {
    if (medicationRecord.length === 0) {
      return;
    }

    const items: Record<string, any>[] = medicationRecord[0].items || []

    const updatedItems = items.map((item) => {
      if (item.drug_info?.id === drugInfo?.id) {
        return {
          ...item,
          medication_record: [
            ...item.medication_record,
            {
              admin_date: null,
              admin_time: null,
              can_admin: true,
              drug_order_item_id: item.drug_info?.id,
              is_editable: true,
              nurse1: null,
              nurse2: null,
              on_date_status: "ACTIVE",
              can_add_std_time: drugInfo?.can_add_std_time,
              frequency_dose_per_day: drugInfo?.frequency_dose_per_day,
            },
          ],
        };
      }

      return item;
    });

    const updatedRecord = [
      {
        ...medicationRecord[0],
        items: updatedItems,
      },
      ...medicationRecord.slice(1),
    ];

    setMedicationRecord(updatedRecord);
  };

  return (
    <Tab
      {...props}
      panes={panes as any}
      medicationRecord={medicationRecord}
      filterMedRecord={filterMedRecord}
      userProfile={userProfile}
      barcodeHNRef={barcodeHNRef}
      onSetFilter={onSetFilter}
      onAddMedicationRecord={handleAddMedicationRecord}
      onTabChange={(ev, data) => {
        if (activeIndex.current !== data.activeIndex) {
          activeIndex.current = data.activeIndex;
          setMedicationRecord([]);
        }
      }}
    />
  );
};

CardQueueWard.defaultProps = CardQueueWardInitial;

export default React.memo(CardQueueWard);
