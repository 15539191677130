import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Radio,
  Dropdown,
  Button,
  Input
} from 'semantic-ui-react'

const CardOrderSetTemplateUX = (props: any) => {
    return(
      <div
        className="ui form"
        style={{display: "flex", width: "100%", height: "95vh"}}>
        <div
          style={{ maxWidth: "530px", padding: "15px"}}>
          
          <div
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
            
            <label
              style={{ fontWeight: "bold", fontSize: "18px" }}>
              รายการชุดคำสั่ง
            </label>
            <div>
              {props.addNewOrder}
            </div>
          </div>
          <Form>
            <FormGroup
              inline={true}
              style={{ paddingTop: "15px" }}>
              <FormField
                inline={true}
                width={10}>
                <div
                  style={{ width: "100%" }}>
                  
                  <div>
                    ค้นหาชุดคำสั่ง
                  </div>
                  <input>
                  </input>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={8}>
                <div
                  style={{ width: "100%" }}>
                  
                  <div>
                    ประเภทชุดคำสั่ง
                  </div>
                  <Dropdown
                    fluid={true}
                    name="typeOrderDetail"
                    onChange={props.onChangeData}
                    options={props.typeOrderOption}
                    selection={true}
                    value={props.typeOrderDetail}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <div
                  style={{ width: "100%" }}>
                  
                  <div>
                    แผนก
                  </div>
                  <Dropdown
                    selection={true}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ width: "100%" }}>
                  
                  <div>
                    แพทย์
                  </div>
                  <Dropdown
                    icon="search"
                    selection={true}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%", marginTop: "12px" }}>
                  
                  <Button
                    color="blue"
                    fluid={true}>
                    ค้นหา
                  </Button>
                </div>
              </FormField>
            </FormGroup>
          </Form>
          <div
            style={{ paddingTop: "15px" }}>
            
            <div>
              {props.templateList}
            </div>
          </div>
        </div>
        <div
          style={{flex: "3 1 0%", height: "95vh", overflow: "auto", padding: "15px", marginTop: "60px", borderLeft: "2px solid rgb(204, 204, 204)" }}>
          
          <Form>
            <FormGroup
              inline={true}
              style={{ ...(!props.showTemplateDetail && { display: "none"}) }}>
              <FormField
                inline={true}
                width="12">
                <div
                  style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  
                  <div
                    style={{ display: "flex", width: "200px" }}>
                    
                    <label
                      style={{ fontWeight: "bold" }}>
                      ชื่อชุดคำสั่ง
                    </label>
                    <div
                      style={{ color: "red", margin: "0px 5px" }}>
                      *
                    </div>
                    <div>
                      :
                    </div>
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      fluid={true}
                      name="name"
                      onChange={props.onChangeData}
                      value={props.orderDetail?.name || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(!props.showTemplateDetail && { display: "none"}) }}>
              <FormField
                inline={true}
                width="2">
                <label
                  style={{ fontWeight: "bold" }}>
                  ประเภทชุดคำสั่ง : 
                </label>
              </FormField>
              <FormField
                inline={true}>
                <Radio
                  checked={props.orderDetail?.type === "ชุดคำสั่งกลาง"}
                  label="ชุดคำสั่งกลาง"
                  name="type"
                  onChange={props.onChangeData}
                  value="ชุดคำสั่งกลาง">
                </Radio>
              </FormField>
              <FormField
                inline={true}>
                <Radio
                  checked={props.orderDetail?.type === "ชุดคำสั่งประจำแผนก"}
                  label="ชุดคำสั่งประจำแผนก"
                  name="type"
                  onChange={props.onChangeData}
                  value="ชุดคำสั่งประจำแผนก">
                </Radio>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    disabled={props.orderDetail?.type !== "ชุดคำสั่งประจำแผนก"}
                    fluid={true}
                    name="divisionDetail"
                    onChange={props.onChangeData}
                    options={props.orderDivisionOption}
                    placeholder="เลือกแผนก"
                    search={true}
                    selection={true}
                    value={props.orderDetail?.divisionDetail}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <Radio
                  checked={props.orderDetail?.type === "ชุดคำสั่งแพทย์"}
                  label="ชุดคำสั่งแพทย์"
                  name="type"
                  onChange={props.onChangeData}
                  value="ชุดคำสั่งแพทย์">
                </Radio>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{width: "100%"}}>
                  {props.searchDoctorDetail}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(!props.showTemplateDetail && { display: "none"}) }}>
              <FormField
                inline={true}>
                <label
                  style={{ fontWeight: "bold", color: "#2185d0" }}>
                  รายการตำสั่งในชุดคำสั่ง
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(!props.showTemplateDetail && { display: "none"}) }}>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%" }}>
                  
                  <div>
                    ประเภทคำสั่ง
                  </div>
                  <Dropdown
                    fluid={true}
                    name="typeOrderDetail"
                    onChange={props.onChangeData}
                    options={props.typeOrderDetailOption}
                    selection={true}
                    value={props.typeOrderDetail}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={12}>
                <div
                  style={{ width: "100%" }}>
                  
                  <div>
                    ค้นหาคำสั่ง
                  </div>
                  <div>
                    {props.searchOrderList}
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              style={{ ...(!props.showTemplateDetail && { display: "none"}) }}>
              <div
                style={{ width: "100%", }}>
                
                <div>
                  {props.orderDetailList}
                </div>
              </div>
            </FormGroup>
            <FormGroup
              style={{ ...(!props.showTemplateDetail && { display: "none"}) }}>
              <div
                style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                
                <Button
                  color="green"
                  onClick={props.saveTemplate}
                  size="mini">
                  ยืนยันการสร้างชุดคำสั่ง
                </Button>
                <Button
                  color="red"
                  onClick={props.closeTemplate}
                  size="mini">
                  ยกเลิกการสร้างชุดคำสั่ง
                </Button>
              </div>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default CardOrderSetTemplateUX

export const screenPropsDefault = {"cardSelectTemplate":"[CardSelectTemplate]","divisionChoices":{"0":{"key":1,"text":"a","value":1}},"dsbList":{"0":1,"1":2},"mode":"home","selectedChairs":"00023-1, 00024-2","zoneSelector":"[ZoneSelector]"}

/* Date Time : Thu Jan 09 2025 17:47:10 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width: \"100%\", height: \"95vh\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ maxWidth: \"530px\", padding: \"15px\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: \"3 1 0%\", height: \"95vh\", overflow: \"auto\", padding: \"15px\", marginTop: \"60px\", borderLeft: \"2px solid rgb(204, 204, 204)\" }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\", height: \"40px\" }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการชุดคำสั่ง"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingTop: \"15px\" }"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Form",
      "parent": 38,
      "props": {
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormGroup",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.showTemplateDetail && { display: \"none\"}) }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "12"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", alignItems: \"center\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormGroup",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.showTemplateDetail && { display: \"none\"}) }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 97,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 97,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 97,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 97,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 97,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 97,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormGroup",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.showTemplateDetail && { display: \"none\"}) }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormGroup",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.showTemplateDetail && { display: \"none\"}) }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทชุดคำสั่ง : "
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Radio",
      "parent": 99,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.orderDetail?.type === \"ชุดคำสั่งกลาง\""
        },
        "label": {
          "type": "value",
          "value": "ชุดคำสั่งกลาง"
        },
        "name": {
          "type": "value",
          "value": "type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "ชุดคำสั่งกลาง"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Radio",
      "parent": 100,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.orderDetail?.type === \"ชุดคำสั่งประจำแผนก\""
        },
        "label": {
          "type": "value",
          "value": "ชุดคำสั่งประจำแผนก"
        },
        "name": {
          "type": "value",
          "value": "type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "ชุดคำสั่งประจำแผนก"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Radio",
      "parent": 102,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.orderDetail?.type === \"ชุดคำสั่งแพทย์\""
        },
        "label": {
          "type": "value",
          "value": "ชุดคำสั่งแพทย์"
        },
        "name": {
          "type": "value",
          "value": "type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "ชุดคำสั่งแพทย์"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "label",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการตำสั่งในชุดคำสั่ง"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", color: \"#2185d0\" }"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Form",
      "parent": 37,
      "props": {
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormGroup",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ paddingTop: \"15px\" }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormField",
      "parent": 118,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 118,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทชุดคำสั่ง"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาชุดคำสั่ง"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "input",
      "parent": 122,
      "props": {
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Dropdown",
      "parent": 121,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "typeOrderDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.typeOrderOption"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.typeOrderDetail"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Dropdown",
      "parent": 128,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.orderDetail?.type !== \"ชุดคำสั่งประจำแผนก\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "divisionDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.orderDivisionOption"
        },
        "placeholder": {
          "type": "value",
          "value": "เลือกแผนก"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.orderDetail?.divisionDetail"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทคำสั่ง"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 131,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาคำสั่ง"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "Dropdown",
      "parent": 130,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "typeOrderDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.typeOrderDetailOption"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.typeOrderDetail"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormGroup",
      "parent": 87,
      "props": {
        "style": {
          "type": "code",
          "value": "{ ...(!props.showTemplateDetail && { display: \"none\"}) }"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", }"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addNewOrder"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "code",
          "value": "props.templateList"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchDoctorDetail"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 137,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderDetailList"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 131,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchOrderList"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormGroup",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "Dropdown",
      "parent": 149,
      "props": {
        "selection": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "Dropdown",
      "parent": 151,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "selection": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", marginTop: \"12px\" }"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Button",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormGroup",
      "parent": 87,
      "props": {
        "style": {
          "type": "code",
          "value": "{ ...(!props.showTemplateDetail && { display: \"none\"}) }"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", width: \"100%\" }"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Button",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันการสร้างชุดคำสั่ง"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.saveTemplate"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Button",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการสร้างชุดคำสั่ง"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.closeTemplate"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"200px\" }"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", margin: \"0px 5px\" }"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": ":"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อชุดคำสั่ง"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Input",
      "parent": 164,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.orderDetail?.name || \"\""
        }
      },
      "seq": 168,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardOrderSetTemplateUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "cardSelectTemplate": "[CardSelectTemplate]",
    "divisionChoices": {
      "0": {
        "key": 1,
        "text": "a",
        "value": 1
      }
    },
    "dsbList": {
      "0": 1,
      "1": 2
    },
    "mode": "home",
    "selectedChairs": "00023-1, 00024-2",
    "zoneSelector": "[ZoneSelector]"
  },
  "width": 85
}

*********************************************************************************** */
