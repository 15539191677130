import { TDocumentDefinitions } from "pdfmake/interfaces";

import { getLocationsSet, tidyLocation2 } from "react-lib/apps/common/CUDENT/DentalRecordUtil";

// Types
type ReceiptProps = {
  an: string;
  bank_name?: string;
  billing_number: string;
  cash_value?: string;
  cheque_value?: string;
  credit_card_value?: string;
  day?: number;
  duration?: string;
  encounter_type?: string;
  fields: {
    name: string;
    non_claimable?: string;
    self_reimbursement?: string;
  }[];
  hn: string;
  invoice_created_date?: string;
  invoice_no?: string;
  is_cash?: boolean;
  is_cheque?: boolean;
  is_credit_card?: boolean;
  is_transfer?: boolean;
  isWatermark?: boolean;
  masterOrganItems: Record<string, any>[];
  month: string;
  paid_date?: string;
  patient_name: string;
  payer_name?: string;
  payment_remark_list?: string[];
  positionName: string;
  ref_no?: string;
  round_off_discount?: string;
  station_name: string;
  sum_non_claimable: number | string;
  sum_self_reimbursement: number | string;
  total_amount_text: string;
  transfer_value?: string;
  userName: string;
  year: string;
  total_amount: string;
};

// Constants
const COLORS = {
  blue: "black",
  light_blue: "black",
};

// #const PAYMENT_METHOD_MAPPING = {
//   is_cash: "เงินสด",
//   is_credit_card: "บัตรเครดิต",
//   is_transfer: "เงินโอน",
// };
// const PAYMENT_METHOD_KEYS = ["is_cash", "is_transfer", "is_credit_card"] as const;

// Surface Protection/ซี่ x 1 รายการ (ซี่ฟันที่ 47Li,47D,47B,47M,47O,48Li,48D,48B,48M,48O)
// \s* - ช่องว่าง 0+ ครั้ง
// ([^)\s]+(?:\s+[^)\s]+)*) - ตัวอักษรที่ไม่ใช่ ) และช่องว่าง
const LOCATION_NAME_REGEX = /ซี่ฟันที่\s*([^)\s]+(?:\s+[^)\s]+)*)/;

const formatPrice = (number: number | string) =>
  Number(number).toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: "decimal",
  });

const SvgCheckbox = (isCheck?: boolean) => `<svg width="12" height="12">
  <rect x="1" y="5" width="12" height="12" style="fill:transparent;stroke:${
    COLORS.blue
  };stroke-width:0;fill-opacity:1;stroke-opacity:0.75" />
  ${
    isCheck &&
    `<polygon style="fill:${COLORS.light_blue};stroke-width:1.5;stroke:${COLORS.light_blue};transform:scale(0.06) translate(15 60)" points="191.268,26.967 59.541,158.683 5.615,104.76 0,110.386 59.541,169.92 196.887,32.585"/>`
  }
  </svg>`;

const FormReceiptTextOnly = (props: ReceiptProps): TDocumentDefinitions => {
  console.log("FormReceiptTextOnly", props);

  return {
    defaultStyle: {
      color: COLORS.blue, // #0447b5//#13389d
      font: "THSarabunNew",
      fontSize: 12,
      lineHeight: 1,
    },
    pageMargins: [0, 169.5, 7.5, 0], // [10, 223.5, 10, BOTTOM]
    pageSize: {
      height: 576,
      width: 432,
    },
    styles: {
      billingNumber: {
        bold: true,
        fontSize: 14,
      },
      facultyHeader: {
        bold: true,
        fontSize: 16,
      },
      fieldKey: {
        bold: true,
        fontSize: 16,
      },
      fieldNumber: {
        bold: true,
        color: COLORS.light_blue,
        fontSize: 29,
      },
      fieldParamsValue: {
        fontSize: 14,
      },
      fieldValue: {
        fontSize: 16,
      },
      footerValue: {
        fontSize: 16,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 14,
      },
      miniFieldValue: {
        fontSize: 12,
      },
      tableHeader: {
        bold: true,
        fontSize: 16,
      },
    },
    watermark: {
      angle: -30,
      bold: true,
      fontSize: 120,
      opacity: 0.2,
      text: props.isWatermark ? "สำเนา" : "",
    },
    header: () => ({
      absolutePosition: { x: 0, y: 67.5 },
      stack: [
        {
          alignment: "center",
          color: COLORS.light_blue,
          style: "facultyHeader",
          text: props.station_name,
        },
        {
          absolutePosition: { x: 270, y: 7.5 },
          color: COLORS.light_blue,
          style: "billingNumber",
          text: `เลขที่การเงิน ${props.billing_number}`,
        },
        {
          stack: [
            {
              table: {
                widths: ["6%", "38.5%", "16%", "10%", "10%", "*"],
                body: [
                  [
                    { style: "fieldValue", text: "" },
                    { style: "fieldValue", text: "" },
                    {
                      columns: [
                        // {
                        //   text: "วันที่",
                        //   style: "fieldValue",
                        // },
                        {
                          absolutePosition: { x: 217.875, y: 86.75 },
                          color: COLORS.light_blue,
                          style: "facultyHeader",
                          text: props.day?.toString() || "",
                        },
                      ],
                    },
                    {
                      columns: [
                        // { text: "เดือน", style: "fieldValue" },
                        {
                          absolutePosition: { x: 283.125, y: 86.75 },
                          color: COLORS.light_blue,
                          style: "facultyHeader",
                          text: props.month,
                        },
                      ],
                    },
                    { style: "fieldValue", text: "" },
                    {
                      columns: [
                        // { text: "พ.ศ.", style: "fieldValue" },
                        {
                          absolutePosition: { x: 364.625, y: 86.75 },
                          color: COLORS.light_blue,
                          style: "facultyHeader",
                          text: props.year,
                        },
                      ],
                    },
                  ],
                  [
                    // { text: "ได้รับ", style: "fieldValue", noWrap: true },
                    "",
                    {
                      columns: [
                        {
                          absolutePosition: { x: 44.75, y: 101.5 },
                          svg: SvgCheckbox(props.is_cash),
                          width: 19,
                        },
                        // { text: "เงินสด", style: "fieldValue" },
                        {
                          absolutePosition: { x: 91.75, y: 101.5 },
                          color: COLORS.light_blue,
                          style: "facultyHeader",
                          text: props.cash_value,
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          absolutePosition: { x: 195.5, y: 101.5 },
                          svg: SvgCheckbox(props.is_cheque || props.is_transfer),
                          width: 19,
                        },
                        // { text: "QR Code.", style: "fieldValue" },
                      ],
                    },
                    {
                      absolutePosition: { x: 259.5, y: 101.5 },
                      color: COLORS.light_blue,
                      style: "facultyHeader",
                      text: props.cheque_value || props.transfer_value,
                    },
                    { style: "fieldValue", text: "" },
                    { style: "fieldValue", text: "" },
                  ],
                  [
                    { style: "fieldValue", text: "" },
                    {
                      columns: [
                        {
                          absolutePosition: { x: 43.5, y: 120 },
                          svg: SvgCheckbox(props.is_credit_card),
                          width: 19,
                        },
                        // { text: "บัตรเครดิต", style: "fieldValue" },
                        {
                          absolutePosition: { x: 103.5, y: 120 },
                          color: COLORS.light_blue,
                          style: "facultyHeader",
                          text: props.credit_card_value,
                        },
                      ],
                    },
                    {
                      columns: [
                        // { text: "เลขที่", style: "fieldValue" },
                        {
                          absolutePosition: { x: 216.75, y: 120 },
                          color: COLORS.light_blue,
                          style: "facultyHeader",
                          text: props.ref_no,
                        },
                      ],
                    },
                    { style: "fieldValue", text: "" },
                    {
                      columns: [
                        // { text: "ธนาคาร", style: "fieldValue" },
                        {
                          absolutePosition: { x: 344.25, y: 120 },
                          color: COLORS.light_blue,
                          style: "facultyHeader",
                          text: props.bank_name,
                        },
                      ],
                    },
                    { style: "fieldValue", text: "" },
                  ],
                  [
                    // { text: "จาก", style: "fieldValue" },
                    "",
                    {
                      absolutePosition: { x: 43.5, y: 136.5 },
                      color: COLORS.light_blue,
                      style: "facultyHeader",
                      text: props.patient_name,
                    },
                    {
                      columns: [
                        // { text: "HN", style: "fieldValue" },
                        {
                          absolutePosition: { x: 216.75, y: 136.5 },
                          color: COLORS.light_blue,
                          columns: [props.hn, props.an ? "AN" : "", props.an || ""].map(
                            (value) => ({
                              alignment: "left",
                              color: COLORS.light_blue,
                              margin: [0, 0, 10, 0],
                              style: "facultyHeader",
                              text: value,
                              width: "auto",
                            })
                          ),
                          style: "facultyHeader",
                        },
                      ],
                    },
                    {
                      text: "",
                    },
                    { style: "fieldValue", text: "" },
                    { style: "fieldValue", text: "" },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              columns: [
                // {
                //   text: "หน่วยงาน",
                //   style: "fieldValue",
                // },
                {
                  absolutePosition: { x: 60.75, y: 153.75 },
                  color: "black",
                  style: "fieldKey",
                  text: props.payer_name || "",
                },
              ],
              lineHeight: 0,
              margin: [4, 0, 0, 0],
            },
          ],
        },
      ],
    }),
    content: [
      {
        absolutePosition: { x: 11.25, y: 172 },
        color: "black",
        style: "fieldKey",
        text: props.duration || "",
      },
      {
        margin: [11.25, 0, 11.25, 0], // [45, 0, 45, 0]
        table: {
          headerRows: 2,
          widths: [260.25, "*", "*"], // ["*", "17.5%", "17.5%"]

          body: [
            [
              {
                rowSpan: 2,
                text: " ",
              },
              {
                colSpan: 2,
                text: " ",
              },
              {},
            ],
            [
              "",
              {
                text: " ",
              },
              {
                text: " ",
              },
            ],
            ...props.fields.map((item, index) => {
              const formattedName = item.name.replace(LOCATION_NAME_REGEX, (match, p1) => {
                const locationsSet = getLocationsSet(p1, props.masterOrganItems);

                return `ตำแหน่ง ${tidyLocation2(locationsSet, props.masterOrganItems).join(",")}`;
              });

              return [
                {
                  border: [0, 0, 0, 0],
                  color: COLORS.light_blue,
                  margin: [7.5, 0, 0, 0],
                  style: "fieldParamsValue",
                  text: `${index + 1}. ${formattedName}`,
                },
                {
                  alignment: "right",
                  border: [0, 0, 0, 0],
                  color: COLORS.light_blue,
                  style: "fieldParamsValue",
                  text: item.non_claimable || "",
                },
                {
                  alignment: "right",
                  border: [0, 0, 0, 0],
                  color: COLORS.light_blue,
                  style: "fieldParamsValue",
                  text: item.self_reimbursement || "",
                },
              ];
            }),
          ],
        },
        layout: "noBorders",
      },
      {
        absolutePosition: { x: 0, y: 390.25 },

        stack: [
          {
            margin: [11.25, 0, 11.25, 0], // [45, 0, 45, 0]

            table: {
              headerRows: 2,
              widths: [260.25, "*", "*"],
              body: [
                [
                  {
                    rowSpan: 2,
                    text: "",
                  },
                  {
                    colSpan: 2,
                    text: "",
                  },
                  {},
                ],
                [
                  "",
                  {
                    text: "",
                  },
                  {
                    text: "",
                  },
                ],
                ...([
                  [
                    {
                      alignment: "right",
                      style: "fieldParamsValue",
                      text: " ",
                    },
                    {
                      alignment: "right",
                      style: "fieldParamsValue",
                      text: Number(props.sum_non_claimable)
                        ? formatPrice(props.sum_non_claimable)
                        : " ",
                    },
                    {
                      alignment: "right",
                      style: "fieldParamsValue",
                      text: Number(props.sum_self_reimbursement)
                        ? formatPrice(props.sum_self_reimbursement)
                        : " ",
                    },
                  ],
                  [
                    {
                      alignment: "right",
                      style: "fieldParamsValue",
                      text: " ",
                    },
                    {
                      alignment: "center",
                      colSpan: 2,
                      relativePosition: { x: 0, y: 2.5 },
                      style: "fieldParamsValue",
                      text: props.total_amount
                      // text: formatPrice(
                      //   Number(props.sum_non_claimable) + Number(props.sum_self_reimbursement)
                      // ),
                    },
                  ],
                ] as any[]),
              ],
            },
            layout: "noBorders",
          },
        ],
      },

      props.duration && props.encounter_type !== "IPD"
        ? {
            absolutePosition: { x: 27.75, y: 398.25 },
            stack: [
              {
                color: COLORS.light_blue,
                lineHeight: 0.8,
                stack: [
                  "ตามใบแสดงรายการค่ารักษาพยาบาลที่",
                  `${props.invoice_no || ""} , ลว ${props.invoice_created_date || ""}`,
                  ...(props.payment_remark_list || []),
                ],
                style: "miniFieldValue",
              },
            ],
          }
        : {
            absolutePosition: { x: 27.75, y: 405 },
            stack: [
              {
                color: COLORS.light_blue,
                style: "facultyHeader",
                text: props.round_off_discount || "",
              },
            ],
          },
      {
        absolutePosition: { x: 0, y: 450.375 },
        stack: [
          {
            table: {
              headerRows: 1,
              heights: 25.5,
              widths: ["*"],
              body: [
                [
                  {
                    alignment: "center",
                    style: "facultyHeader",
                    text: `(${props.total_amount_text})`,
                  },
                ],
              ],
            },
            layout: "noBorders",
          },
          // {
          //   text: "(ตัวอักษร)",
          //   alignment: "center",
          //   style: "footerValue",
          // },
        ],
      },
      {
        absolutePosition: { x: 0, y: 536.125 },
        columns: [
          { text: " ", width: "50%" },
          { alignment: "center", text: props.userName, width: "50%" },
        ],
        style: "footerValue",
      },
      {
        absolutePosition: { x: 0, y: 553.375 },

        columns: [
          { text: " ", width: "50%" },
          { alignment: "center", text: props.positionName, width: "50%" },
        ],
        style: "footerValue",
      },
    ],
  };
};

export default FormReceiptTextOnly;
