import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Checkbox,
  Button,
  TextArea
} from 'semantic-ui-react'

const ANES_PACU_Anesthesia = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPACURecordAnesthesia"
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"85%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div>
            
            <Form>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={3}>
                  <div
                    style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                    Type of anesthesia :
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      disabled={true}
                      fluid={true}
                      style={{ opacity: 1 }}
                      value={props.anesPACUAnesthesia?.typeAnesthesia || ""}>
                    </Input>
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  style={{ display: "flex", justifyContent: "flex-end"  }}
                  width={2}>
                  <div
                    style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                    ผู้บันทึกล่าสุด:
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={5}>
                  <div
                    style={{display:"flex",width:"100%"}}>
                    
                    <div
                      style={{display:"flex",width:"50%"}}>
                      
                      <div>
                        {props.anesPACUAnesthesia?.editUser || ""}
                      </div>
                    </div>
                    <div
                      style={{display:"flex",width:"50%"}}>
                      
                      <div>
                        {props.anesPACUAnesthesia?.editDate || ""}
                      </div>
                    </div>
                  </div>
                  <div>
                    
                    <Button
                      color="green"
                      onClick={props.onSavePACUAnesthesia}>
                      SAVE
                    </Button>
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={3}>
                  <div
                    style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                    PACU Anesthetic agent  :
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      fluid={true}
                      name="AnesthesiaTeamRemark"
                      onChange={props.onChangeAnesthesiaData}
                      readOnly={true}
                      style={{ display: "flex" ,height:"100%",width:"100%"}}
                      value={props.anesPACUAnesthesia?.AnesthesiaTeamRemark || ""}>
                    </Input>
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={3}>
                  <div
                    style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                    Admission status
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      clearable={true}
                      fluid={true}
                      name="AdmissionStatus"
                      onChange={props.onChangeAnesthesiaData}
                      options={props.masterOptions?.pacu_adm_status || []}
                      selection={true}
                      value={props.anesPACUAnesthesia?.AdmissionStatus || ""}>
                    </Dropdown>
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  style={{ display: "flex", justifyContent: "center" }}
                  width={2}>
                  <div
                    style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                    Airway
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      clearable={true}
                      fluid={true}
                      name="Airway"
                      onChange={props.onChangeAnesthesiaData}
                      options={props.masterOptions?.pacu_airway || []}
                      selection={true}
                      value={props.anesPACUAnesthesia?.Airway || ""}>
                    </Dropdown>
                  </div>
                </FormField>
                <FormField
                  style={{ display: props.anesPACUAnesthesia?.Airway === "Other" ? "" : "none" }}
                  inline={true}
                  width={4}>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      name="AirwayOther"
                      onChange={props.onChangeAnesthesiaData}
                      value={props.anesPACUAnesthesia?.AirwayOther || ""}>
                    </Input>
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={3}>
                  
                  <div
                    style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                    Breathing
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      clearable={true}
                      fluid={true}
                      name="Breathing"
                      onChange={props.onChangeAnesthesiaData}
                      options={props.masterOptions?.pacu_breathing || []}
                      selection={true}
                      value={props.anesPACUAnesthesia?.Breathing || ""}>
                    </Dropdown>
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  style={{ display: "flex", justifyContent: "center" }}
                  width={2}>
                  <div
                    style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                    IMF
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      clearable={true}
                      fluid={true}
                      name="IMF"
                      onChange={props.onChangeAnesthesiaData}
                      options={props.masterOptions?.pacu_imf || []}
                      selection={true}
                      value={props.anesPACUAnesthesia?.IMF || ""}>
                    </Dropdown>
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={3}>
                  <div
                    style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                    ASA-PS
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      clearable={true}
                      fluid={true}
                      name="ASAPS"
                      onChange={props.onChangeAnesthesiaData}
                      options={props.masterOptions?.anes_asa_ps || []}
                      selection={true}
                      style={{minWidth: "100px",Height:"100%" ,flex: "5 4 13.00%"}}
                      value={props.anesPACUAnesthesia?.ASAPS || ""}>
                    </Dropdown>
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={3}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesPACUAnesthesia?.Emergency || false}
                      label="Emergency"
                      name="Emergency"
                      onChange={props.onChangeAnesthesiaData}>
                    </Checkbox>
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={3}>
                  <div
                    style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                     Sedation score
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={6}>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Dropdown
                      allowAdditions={true}
                      fluid={true}
                      name="SedationScore"
                      onAddItem={props.onAdditionSedationScore}
                      onChange={props.onChangeAnesthesiaData}
                      options={props.sedationOption || []}
                      search={true}
                      selection={true}
                      value={props.anesPACUAnesthesia?.SedationScore || ""}>
                    </Dropdown>
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={3}>
                  <div
                    style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                    Remarks for adverse events
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={9}>
                  <div
                    style={{ width: "100%" }}>
                    
                    <TextArea
                      name="AdverseEventsRemark"
                      onChange={props.onChangeAnesthesiaData}
                      style={{border: "solid 1px rgb(200, 200, 200, 0.5)", width:"100%",height:"100%"}}
                      value={props.anesPACUAnesthesia?.AdverseEventsRemark || ""}>
                    </TextArea>
                  </div>
                </FormField>
              </FormGroup>
            </Form>
          </div>
        </div>
      </div>
    )
}


export default ANES_PACU_Anesthesia

export const screenPropsDefault = {}

/* Date Time : Fri Nov 29 2024 14:04:43 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPACURecordAnesthesia"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"85%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Form",
      "parent": 87,
      "props": {
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormGroup",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormGroup",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormGroup",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormGroup",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormGroup",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormField",
      "parent": 89,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 90,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 93,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 94,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 90,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 93,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 93,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": "Type of anesthesia :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "PACU Anesthetic agent  :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "Admission status"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "Airway"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "ASA-PS"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "Remarks for adverse events"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Input",
      "parent": 116,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AnesthesiaTeamRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesiaData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.AnesthesiaTeamRemark || \"\""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Dropdown",
      "parent": 120,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AdmissionStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesiaData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_adm_status || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.AdmissionStatus || \"\""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Dropdown",
      "parent": 122,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Airway"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesiaData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_airway || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.Airway || \"\""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "Dropdown",
      "parent": 124,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ASAPS"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesiaData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.anes_asa_ps || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",Height:\"100%\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.ASAPS || \"\""
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Checkbox",
      "parent": 125,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.Emergency || false"
        },
        "label": {
          "type": "value",
          "value": "Emergency"
        },
        "name": {
          "type": "value",
          "value": "Emergency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesiaData"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 89,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 89,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\"  }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.editDate || \"\""
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.editUser || \"\""
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้บันทึกล่าสุด:"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 89,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "Button",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSavePACUAnesthesia"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Input",
      "parent": 141,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ opacity: 1 }"
        },
        "value": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.typeAnesthesia || \"\""
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",width:\"100%\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",width:\"50%\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",width:\"50%\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormGroup",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 156,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 156,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": " Sedation score"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Dropdown",
      "parent": 159,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "SedationScore"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAdditionSedationScore"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesiaData"
        },
        "options": {
          "type": "code",
          "value": "props.sedationOption || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.SedationScore || \"\""
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormGroup",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormGroup",
      "parent": 88,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 163,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "TextArea",
      "parent": 165,
      "props": {
        "name": {
          "type": "value",
          "value": "AdverseEventsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesiaData"
        },
        "style": {
          "type": "code",
          "value": "{border: \"solid 1px rgb(200, 200, 200, 0.5)\", width:\"100%\",height:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.AdverseEventsRemark || \"\""
        }
      },
      "seq": 166,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormField",
      "parent": 162,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "FormField",
      "parent": 162,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "FormField",
      "parent": 162,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "Breathing"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": "IMF"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Dropdown",
      "parent": 172,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Breathing"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesiaData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_breathing || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.Breathing || \"\""
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Dropdown",
      "parent": 174,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "IMF"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesiaData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_imf || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.IMF || \"\""
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Input",
      "parent": 178,
      "props": {
        "name": {
          "type": "value",
          "value": "AirwayOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesiaData"
        },
        "value": {
          "type": "code",
          "value": "props.anesPACUAnesthesia?.AirwayOther || \"\""
        }
      },
      "seq": 179,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PACU_Anesthesia",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
