import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSettingEmployeeUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        
        <div
          style={{margin:"10px"}}>
          {props.ErrorMessages}
        </div>
        <div
          style={{display: "grid", gridTemplateColumns: "1fr", margin: "-0.5rem  0.5rem 0"}}>
          
          <div
            className="ui form">
            
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem"}}>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold",width: "95px"}}>
                  
                  <label>
                    รหัสพนักงาน
                  </label>
                  <label
                    style={{color: "red"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.code ? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Input
                  fluid={true}
                  onChange={props.changeValue("code")}
                  style={{width: "100%"}}
                  value={props.code || ""}>
                </Input>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.code || ""}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold", width: "145px"}}>
                  
                  <label>
                    วัน/เดือน/ปีเกิด
                  </label>
                  <label
                    style={{color: "red"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <div
                  style={{width: "100%"}}>
                  {props.birthDate}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{fontWeight: "bold", width: "80px"}}>
                  อายุ
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{width: "100%"}}>
                  {props.age}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem"}}>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold",width: "95px"}}>
                  
                  <label>
                    ตำแหน่งงาน
                  </label>
                  <label
                    style={{color: "red"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.position ? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  onChange={props.changeValue("position")}
                  options={props.positionOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.position || ""}>
                </Dropdown>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.position || ""}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{display: props.hideDoctorForm ? "none": ""}}>
                <div
                  style={{display: "flex", fontWeight: "bold", width: "145px"}}>
                  
                  <label>
                    เลขใบประกอบวิชาชีพ
                  </label>
                  <label
                    style={{color: "red", display: props.position === 2 ? "": "none"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.certificate_no ? "error" : ""}
                inline={true}
                style={{position: "relative",display: props.hideDoctorForm ? "none": ""}}
                width={3}>
                <Input
                  disabled={props.hideDoctorForm || false}
                  fluid={true}
                  onChange={props.changeValue("certificate_no")}
                  style={{width: "100%"}}
                  value={props.certificateNo || ""}>
                </Input>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.certificate_no || ""}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{display: props.hideDoctorForm ? "none": ""}}>
                <div
                  style={{display: "flex", fontWeight: "bold", width: "80px"}}>
                  
                  <label>
                    Specialty
                  </label>
                  <label
                    style={{color: "red", display: props.position === 2 ? "": "none"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.specialties ? "error" : ""}
                inline={true}
                style={{position: "relative",display: props.hideDoctorForm ? "none": ""}}
                width={3}>
                <Dropdown
                  clearable={true}
                  disabled={props.hideDoctorForm || false}
                  fluid={true}
                  multiple={true}
                  onChange={props.changeValue("specialties")}
                  options={props.specialtyOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%", marginRight: 0}}
                  value={props.specialties}>
                </Dropdown>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.specialties || ""}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem",display: props.hideDoctorForm ? "none": ""}}>
              <FormField
                inline={true}>
                <div
                  style={{fontWeight: "bold", width: "95px"}}>
                  ประเภทแพทย์
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Dropdown
                  disabled={props.hideDoctorForm || false}
                  fluid={true}
                  onChange={props.changeValue("doctor_type")}
                  options={props.doctorTypeOptions}
                  selection={true}
                  style={{width: "100%",marginRight: "1rem"}}
                  value={props.doctorType || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold", width: "75px"}}>
                  
                  <label>
                    รหัสแพทย์
                  </label>
                  <label
                    style={{color: "red", display: props.position === 2 ? "": "none"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.codeDoctor ? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Input
                  disabled={props.hideDoctorForm || false}
                  fluid={true}
                  onChange={props.changeValue("codeDoctor")}
                  style={{width: "100%", marginRight: 0}}
                  value={props.codeDoctor || ""}>
                </Input>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.codeDoctor || ""}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem"}}>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex",fontWeight: "bold",width: "95px"}}>
                  
                  <label>
                    คำนำหน้า
                  </label>
                  <label
                    style={{color: "red", display: props.showUserForm? "": "none"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.pre_name ? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  onChange={props.changeValue("pre_name")}
                  options={props.prenameThOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%",marginRight: "1rem"}}
                  value={props.preNameTh || ""}>
                </Dropdown>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.pre_name || ""}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex",fontWeight: "bold", width: "75px"}}>
                  
                  <label>
                    ชื่อ
                  </label>
                  <label
                    style={{color: "red",display: props.showUserForm? "": "none"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.first_name? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Input
                  fluid={true}
                  onChange={props.changeValue("first_name")}
                  style={{width: "100%", marginRight: 0}}
                  value={props.firstNameTh || ""}>
                </Input>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.first_name|| ""}
                </div>
              </FormField>
              <FormField>
                <div
                  style={{display: "flex",fontWeight: "bold", marginLeft: "5rem", width: "80px"}}>
                  
                  <label>
                    นามสกุล
                  </label>
                  <label
                    style={{color: "red",display: props.showUserForm? "": "none"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.last_name? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Input
                  fluid={true}
                  onChange={props.changeValue("last_name")}
                  style={{width: "100%", marginRight: 0}}
                  value={props.lastNameTh || ""}>
                </Input>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.last_name|| ""}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem"}}>
              <FormField
                inline={true}>
                <div
                  style={{fontWeight: "bold",width: "95px"}}>
                  Pre-name
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  onChange={props.changeValue("pre_name_en")}
                  options={props.prenameEnOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%",marginRight: "1rem"}}
                  value={props.preNameEn || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{fontWeight: "bold", width: "75px"}}>
                  Name
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Input
                  fluid={true}
                  onChange={props.changeValue("first_name_en")}
                  style={{width:"100%"}}
                  value={props.firstNameEn || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{fontWeight: "bold", marginLeft: "5rem", width: "80px"}}>
                  Lastname
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Input
                  fluid={true}
                  onChange={props.changeValue("last_name_en")}
                  style={{width: "100%"}}
                  value={props.lastNameEn || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem", display: props.showUserForm?"":"none"}}>
              <FormField
                inline={true}>
                <div
                  style={{fontWeight: "bold", width: "95px"}}>
                  เบอร์โทรศัพท์
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Input
                  fluid={true}
                  onChange={props.changeValue("phone_no")}
                  style={{width: "100%", marginRight: "1rem"}}
                  value={props.phoneNo || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold", width: "75px"}}>
                  
                  <label>
                     อีเมล์
                  </label>
                  <label
                    style={{color: "red"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.email? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Input
                  fluid={true}
                  onChange={props.changeValue("email")}
                  style={{width: "100%", marginRight: 0}}
                  value={props.email || ""}>
                </Input>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red",fontSize: "0.8rem"}}>
                  {props.showRequiredField?.email|| ""}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem"}}>
              <FormField
                inline={true}>
                <div
                  style={{fontWeight: "bold",width: "95px"}}>
                  Role
                </div>
              </FormField>
              <FormField>
                <div
                  style={{width: "100%"}}>
                  {props.roleTotal}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{width: "100%"}}>
                  {props.icon}
                </div>
              </FormField>
              <FormField>
                <div
                  style={{width: "100%"}}>
                  {props.roles}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem"}}>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold",width: "95px"}}>
                  
                  <label>
                    วันที่เริ่มงาน
                  </label>
                  <label
                    style={{ color: "red"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.flag_start_date ? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <div
                  style={{width: "100%"}}>
                  
                  <DateTextBox
                    onChange={props.changeDate("flag_start_date")}
                    value={props.flagStartDate || ""}>
                  </DateTextBox>
                </div>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red",fontSize: "0.8rem"}}>
                  {props.showRequiredField?.flag_start_date || ""}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem"}}>
              <FormField
                inline={true}>
                <div
                  style={{fontWeight: "bold",width: "95px"}}>
                  วันที่สิ้นสุด
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{width: "100%",marginRight: "1rem"}}>
                  
                  <DateTextBox
                    onChange={props.changeDate("flag_end_date")}
                    value={props.flagEndDate || ""}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{fontWeight: "bold"}}>
                  เหตุผล
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Input
                  fluid={true}
                  onChange={props.changeValue("inactive_reason")}
                  style={{width: "100%"}}
                  value={props.inactiveReason || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem"}}>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold",width: "95px"}}>
                  
                  <label>
                    สถานะ
                  </label>
                  <label
                    style={{color: "red"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.status ? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Dropdown
                  defaultValue={1}
                  fluid={true}
                  onChange={props.changeValue("status")}
                  options={props.statusOptions}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.status || ""}>
                </Dropdown>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.status || ""}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem", display: props.showUserForm? "": "none"}}>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold",width: "95px"}}>
                  {}
                  <label>
                    ชื่อผู้ใช้
                  </label>
                  <label
                    style={{color: "red"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.username ? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Input
                  disabled={!props.showUserForm}
                  fluid={true}
                  onChange={props.changeValue("username")}
                  style={{width: "100%"}}
                  value={props.username || ""}>
                </Input>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.username || ""}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem", display:  props.showUserForm? props.allowedResetPassword? "none":"": "none"}}>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold",width: "95px"}}>
                  
                  <label>
                    รหัสผ่าน
                  </label>
                  <label
                    style={{color: "red"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.password ? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Input
                  disabled={!props.showUserForm}
                  fluid={true}
                  icon={props.eyePass1}
                  onChange={props.changeValue("password")}
                  style={{width: "100%"}}
                  type={props.typePassword1}
                  value={props.password || ""}>
                </Input>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.password || ""}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "1.3rem", display:  props.showUserForm? props.allowedResetPassword? "none":"": "none"}}>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold",width: "95px"}}>
                  
                  <label>
                    ยืนยันรหัสผ่าน
                  </label>
                  <label
                    style={{color: "red"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                className={props.showRequiredField?.confirm_password ? "error" : ""}
                inline={true}
                style={{position: "relative"}}
                width={3}>
                <Input
                  disabled={!props.showUserForm}
                  fluid={true}
                  icon={props.eyeConfirm1}
                  onChange={props.changeValue("confirm_password")}
                  style={{width: "100%"}}
                  type={props.typeConfirm1}
                  value={props.confirmPassword|| ""}>
                </Input>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.confirm_password || ""}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={{display:"flex",justifyContent:"flex-end", margin: "0 2rem 1rem"}}>
          
          <Button
            color="blue"
            disabled={!props.allowedResetPassword}
            onClick={props.resetPassword}
            size="small"
            style={{display: props.allowedResetPassword?"":"none", marginRight: "0.75rem"}}>
            Reset Password
          </Button>
          <div
            style={{marginRight:"10px"}}>
            {props.buttonSave}
          </div>
        </div>
      </div>
    )
}


export default CardSettingEmployeeUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Mon Nov 04 2024 09:46:42 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\", margin: \"0 2rem 1rem\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessages"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"1fr\", margin: \"-0.5rem  0.5rem 0\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\",display: props.hideDoctorForm ? \"none\": \"\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "FormField",
      "parent": 171,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "FormField",
      "parent": 171,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.code ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "FormField",
      "parent": 171,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "FormField",
      "parent": 171,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormField",
      "parent": 171,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 171,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 180,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.code || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"145px\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 183,
      "props": {
        "children": {
          "type": "code",
          "value": "props.birthDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": "อายุ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"80px\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "Input",
      "parent": 181,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"code\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.code || \"\""
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสพนักงาน"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "label",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน/เดือน/ปีเกิด"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "label",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "code",
          "value": "props.age"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "FormField",
      "parent": 172,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "FormField",
      "parent": 172,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.position ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "FormField",
      "parent": 172,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideDoctorForm ? \"none\": \"\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "FormField",
      "parent": 172,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.certificate_no ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\",display: props.hideDoctorForm ? \"none\": \"\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "FormField",
      "parent": 172,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideDoctorForm ? \"none\": \"\"}"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "FormField",
      "parent": 172,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.specialties ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\",display: props.hideDoctorForm ? \"none\": \"\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "div",
      "parent": 198,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "div",
      "parent": 200,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"145px\"}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "div",
      "parent": 199,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.position || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": null,
      "id": 207,
      "name": "div",
      "parent": 201,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.certificate_no || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "div",
      "parent": 202,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"80px\"}"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "div",
      "parent": 203,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.specialties || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": null,
      "id": 210,
      "name": "label",
      "parent": 204,
      "props": {
        "children": {
          "type": "value",
          "value": "ตำแหน่งงาน"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "label",
      "parent": 204,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "label",
      "parent": 205,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขใบประกอบวิชาชีพ"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "label",
      "parent": 205,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", display: props.position === 2 ? \"\": \"none\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "label",
      "parent": 208,
      "props": {
        "children": {
          "type": "value",
          "value": "Specialty"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "label",
      "parent": 208,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", display: props.position === 2 ? \"\": \"none\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Dropdown",
      "parent": 199,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"position\")"
        },
        "options": {
          "type": "code",
          "value": "props.positionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.position || \"\""
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "Dropdown",
      "parent": 203,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.hideDoctorForm || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"specialties\")"
        },
        "options": {
          "type": "code",
          "value": "props.specialtyOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.specialties"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "Input",
      "parent": 201,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.hideDoctorForm || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"certificate_no\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.certificateNo || \"\""
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 173,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 220,
      "name": "FormField",
      "parent": 173,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "FormField",
      "parent": 173,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "FormField",
      "parent": 173,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.codeDoctor ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "FormField",
      "parent": 174,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "FormField",
      "parent": 174,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.pre_name ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "FormField",
      "parent": 174,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "FormField",
      "parent": 174,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.first_name? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormField",
      "parent": 174,
      "props": {
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "FormField",
      "parent": 174,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.last_name? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "FormField",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "FormField",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "FormField",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "FormField",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormField",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "FormField",
      "parent": 175,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 235,
      "name": "div",
      "parent": 219,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"95px\"}"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": null,
      "id": 236,
      "name": "div",
      "parent": 221,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"75px\"}"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "Dropdown",
      "parent": 220,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.hideDoctorForm || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"doctor_type\")"
        },
        "options": {
          "type": "code",
          "value": "props.doctorTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.doctorType || \"\""
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "Input",
      "parent": 222,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.hideDoctorForm || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"codeDoctor\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.codeDoctor || \"\""
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "Dropdown",
      "parent": 224,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"pre_name\")"
        },
        "options": {
          "type": "code",
          "value": "props.prenameThOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.preNameTh || \"\""
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "Dropdown",
      "parent": 230,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"pre_name_en\")"
        },
        "options": {
          "type": "code",
          "value": "props.prenameEnOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.preNameEn || \"\""
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": null,
      "id": 241,
      "name": "div",
      "parent": 223,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 242,
      "name": "div",
      "parent": 225,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",fontWeight: \"bold\", width: \"75px\"}"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": null,
      "id": 243,
      "name": "div",
      "parent": 227,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",fontWeight: \"bold\", marginLeft: \"5rem\", width: \"80px\"}"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "div",
      "parent": 229,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre-name"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "div",
      "parent": 233,
      "props": {
        "children": {
          "type": "value",
          "value": "Lastname"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"5rem\", width: \"80px\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "div",
      "parent": 231,
      "props": {
        "children": {
          "type": "value",
          "value": "Name"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"75px\"}"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "Input",
      "parent": 226,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"first_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.firstNameTh || \"\""
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 251,
      "name": "Input",
      "parent": 228,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"last_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.lastNameTh || \"\""
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "Input",
      "parent": 234,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"last_name_en\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.lastNameEn || \"\""
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 253,
      "name": "Input",
      "parent": 232,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"first_name_en\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.firstNameEn || \"\""
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "FormField",
      "parent": 176,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "FormField",
      "parent": 176,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "div",
      "parent": 254,
      "props": {
        "children": {
          "type": "value",
          "value": "Role"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": null,
      "id": 257,
      "name": "div",
      "parent": 255,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roleTotal"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "FormField",
      "parent": 176,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 259,
      "name": "FormField",
      "parent": 176,
      "props": {
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 260,
      "name": "div",
      "parent": 258,
      "props": {
        "children": {
          "type": "code",
          "value": "props.icon"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": null,
      "id": 261,
      "name": "div",
      "parent": 259,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roles"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 262,
      "name": "FormField",
      "parent": 177,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 263,
      "name": "FormField",
      "parent": 177,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.flag_start_date ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "FormField",
      "parent": 178,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 265,
      "name": "FormField",
      "parent": 178,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 266,
      "name": "FormField",
      "parent": 178,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 267,
      "name": "FormField",
      "parent": 178,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "FormField",
      "parent": 179,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "FormField",
      "parent": 179,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.status ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": null,
      "id": 270,
      "name": "div",
      "parent": 262,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": null,
      "id": 271,
      "name": "div",
      "parent": 264,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สิ้นสุด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": null,
      "id": 272,
      "name": "div",
      "parent": 266,
      "props": {
        "children": {
          "type": "value",
          "value": "เหตุผล"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 273,
      "name": "div",
      "parent": 268,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 274,
      "name": "Input",
      "parent": 267,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"inactive_reason\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.inactiveReason || \"\""
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 275,
      "name": "Dropdown",
      "parent": 269,
      "props": {
        "defaultValue": {
          "type": "code",
          "value": "1"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"status\")"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.status || \"\""
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 276,
      "name": "label",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เริ่มงาน"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "label",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\"}"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "label",
      "parent": 273,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": null,
      "id": 279,
      "name": "label",
      "parent": 273,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "div",
      "parent": 269,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.status || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 263,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "div",
      "parent": 263,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.flag_start_date || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\",fontSize: \"0.8rem\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 287,
      "name": "DateTextBox",
      "parent": 281,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"flag_start_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.flagStartDate || \"\""
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 288,
      "name": "div",
      "parent": 265,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",marginRight: \"1rem\"}"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 289,
      "name": "DateTextBox",
      "parent": 288,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"flag_end_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.flagEndDate || \"\""
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": null,
      "id": 290,
      "name": "label",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสแพทย์"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "label",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", display: props.position === 2 ? \"\": \"none\"}"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 292,
      "name": "div",
      "parent": 222,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.codeDoctor || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\", display: props.showUserForm?\"\":\"none\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 297,
      "name": "FormField",
      "parent": 293,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.email? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": null,
      "id": 300,
      "name": "div",
      "parent": 294,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์โทรศัพท์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"95px\"}"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 303,
      "name": "div",
      "parent": 297,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.email|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\",fontSize: \"0.8rem\"}"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": null,
      "id": 304,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"75px\"}"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": null,
      "id": 308,
      "name": "label",
      "parent": 304,
      "props": {
        "children": {
          "type": "value",
          "value": " อีเมล์"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": null,
      "id": 309,
      "name": "label",
      "parent": 304,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 311,
      "name": "Input",
      "parent": 295,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"phone_no\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.phoneNo || \"\""
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 312,
      "name": "Input",
      "parent": 297,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"email\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.email || \"\""
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\", display: props.showUserForm? \"\": \"none\"}"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 314,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\", display:  props.showUserForm? props.allowedResetPassword? \"none\":\"\": \"none\"}"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 315,
      "name": "FormGroup",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\", display:  props.showUserForm? props.allowedResetPassword? \"none\":\"\": \"none\"}"
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 316,
      "name": "FormField",
      "parent": 313,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 317,
      "name": "FormField",
      "parent": 314,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "FormField",
      "parent": 315,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 319,
      "name": "FormField",
      "parent": 313,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.username ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 320,
      "name": "FormField",
      "parent": 314,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.password ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 321,
      "name": "FormField",
      "parent": 315,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.confirm_password ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 321,
      "void": false
    },
    {
      "from": null,
      "id": 322,
      "name": "div",
      "parent": 316,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": null,
      "id": 323,
      "name": "div",
      "parent": 317,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": null,
      "id": 324,
      "name": "div",
      "parent": 318,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"95px\"}"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": null,
      "id": 325,
      "name": "label",
      "parent": 322,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ใช้"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": null,
      "id": 326,
      "name": "label",
      "parent": 322,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "label",
      "parent": 323,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสผ่าน"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": null,
      "id": 328,
      "name": "label",
      "parent": 323,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": null,
      "id": 329,
      "name": "label",
      "parent": 324,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันรหัสผ่าน"
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": null,
      "id": 330,
      "name": "label",
      "parent": 324,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 331,
      "name": "Input",
      "parent": 321,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.showUserForm"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "code",
          "value": "props.eyeConfirm1"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"confirm_password\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "code",
          "value": "props.typeConfirm1"
        },
        "value": {
          "type": "code",
          "value": "props.confirmPassword|| \"\""
        }
      },
      "seq": 331,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 332,
      "name": "Input",
      "parent": 320,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.showUserForm"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "code",
          "value": "props.eyePass1"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"password\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "code",
          "value": "props.typePassword1"
        },
        "value": {
          "type": "code",
          "value": "props.password || \"\""
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 333,
      "name": "Input",
      "parent": 319,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.showUserForm"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"username\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.username || \"\""
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": null,
      "id": 334,
      "name": "div",
      "parent": 319,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.username || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": null,
      "id": 335,
      "name": "div",
      "parent": 320,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.password || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 335,
      "void": false
    },
    {
      "from": null,
      "id": 336,
      "name": "div",
      "parent": 321,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.confirm_password || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": null,
      "id": 337,
      "name": "label",
      "parent": 241,
      "props": {
        "children": {
          "type": "value",
          "value": "คำนำหน้า"
        }
      },
      "seq": 337,
      "void": false
    },
    {
      "from": null,
      "id": 338,
      "name": "label",
      "parent": 241,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", display: props.showUserForm? \"\": \"none\"}"
        }
      },
      "seq": 338,
      "void": false
    },
    {
      "from": null,
      "id": 339,
      "name": "label",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        }
      },
      "seq": 339,
      "void": false
    },
    {
      "from": null,
      "id": 340,
      "name": "label",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\",display: props.showUserForm? \"\": \"none\"}"
        }
      },
      "seq": 340,
      "void": false
    },
    {
      "from": null,
      "id": 341,
      "name": "label",
      "parent": 243,
      "props": {
        "children": {
          "type": "value",
          "value": "นามสกุล"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 341,
      "void": false
    },
    {
      "from": null,
      "id": 342,
      "name": "label",
      "parent": 243,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\",display: props.showUserForm? \"\": \"none\"}"
        }
      },
      "seq": 342,
      "void": false
    },
    {
      "from": null,
      "id": 343,
      "name": "div",
      "parent": 224,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.pre_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": null,
      "id": 344,
      "name": "div",
      "parent": 226,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.first_name|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 344,
      "void": false
    },
    {
      "from": null,
      "id": 345,
      "name": "div",
      "parent": 228,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.last_name|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 345,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 346,
      "name": "Button",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "Reset Password"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "!props.allowedResetPassword"
        },
        "onClick": {
          "type": "code",
          "value": "props.resetPassword"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.allowedResetPassword?\"\":\"none\", marginRight: \"0.75rem\"}"
        }
      },
      "seq": 98,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSettingEmployeeUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 80
}

*********************************************************************************** */
