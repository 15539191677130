import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Icon, Input, Modal } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI ANE
import CardPACURecordFormUX from "./CardPACURecordFormUX";
import CardPACURecordTabUX from "./CardPACURecordTabUX";
import CardPACURecordAnesthesiaUX from "./CardPACURecordAnesthesiaUX";
import CardPACURecordIntraoperativeUX from "./CardPACURecordIntraoperativeUX";
import CardPostAnestheticRecoveryScore from "./CardPostAnestheticRecoveryScore";
import CardPACURecordMonitorUX from "./CardPACURecordMonitorUX";
import CardPACURecordMedicationUX from "./CardPACURecordMedicationUX";
import CardPACURecordProblemsUX from "./CardPACURecordProblemsUX";
import CardPACURecordReferralUX from "./CardPACURecordReferralUX";
import CardPACURecordFluidUX from "./CardPACURecordFluidUX";
//
import CardANSLog from "react-lib/apps/ANS/CardANSLog";
import moment from "moment";
import TimeFreeTextBox24 from "react-lib/apps/common/TimeFreeTextBox24";
import { useIntl } from "react-intl";
import CardVitalSign from "react-lib/apps/HISV3/PTM/CardVitalSign";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";
import { SelectedItemHandler } from "react-lib/appcon/common/SearchBoxDropdown";

const CARD_KEY: string = "CardPACURecord";
const FORM_CODE: string = "CardPacuRecord";
const FORM_NAME: string = "CardPacuRecord";
const FORM_VERSION: string = "1.0";

type CardPACURecordProps = {
  // function
  onEvent: any;
  setProp: any;
  DJANGO: any;
  selectedEncounter: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;
  masterData?: any;
  userNurseOptions?: any;

  // seq
  searchedItemListWithKey?: any;
  runSequence?: any;
  PACURecordSequence?: any;
};

const CardPACURecord = (props: CardPACURecordProps) => {
  const intl = useIntl();
  const [tab, setTab] = useState<string>("Anesthesia");
  const [medication, setMedication] = useState<any>({
    time: "",
    medication: "",
    detail: "",
    mdrn: "",
    index: "",
  });
  const [problem, setProblem] = useState<any>({
    time: "",
    remark: "",
    index: "",
  });
  const [fluid, setFluid] = useState<any>({
    time: "",
    remark: "",
    index: "",
  });
  const [sedationOption, setSedationOption] = useState<any[]>([]);

  // PACURecord
  useEffect(() => {
    props.runSequence({
      sequence: "PACURecord",
      action: "FETCH_PACU",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  useEffect(() => {
    if (
      props.PACURecordSequence?.data?.AttendingStaff !== "" &&
      props.masterData?.doctor instanceof Array
    ) {
      let doctorData = props.masterData?.doctor?.filter(
        (item: any) => item.id === props.PACURecordSequence?.data?.AttendingStaff
      );

      if (doctorData?.[0]?.user !== undefined) {
        props.runSequence({
          sequence: "PACURecord",
          action: "GET_LICENSE",
          cardKey: CARD_KEY,
          paramKey: doctorData[0]?.user,
          dataKey: "AttendingStaff",
        });
      }
    }
  }, [props.PACURecordSequence?.data?.AttendingStaff]);

  useEffect(() => {
    if (props.PACURecordSequence?.data?.PACUNurse !== "") {
      let userNurseOptions = props.PACURecordSequence?.userNurseOptions?.filter(
        (item: any) => item.value === props.PACURecordSequence?.data?.PACUNurse
      );

      if (userNurseOptions?.[0]?.value !== undefined) {
        props.runSequence({
          sequence: "PACURecord",
          action: "GET_LICENSE",
          cardKey: CARD_KEY,
          paramKey: userNurseOptions[0]?.value,
          dataKey: "PACUNurse",
        });
      }
    }
  }, [props.PACURecordSequence?.data?.PACUNurse]);

  useEffect(() => {
    let sedationScoreOptions = (props.masterOptions?.sedation_score || []).map((items: any) => {
      return items.value;
    });
    let sedationScore = props.PACURecordSequence?.anesPACUAnesthesia?.SedationScore;

    if (!(sedationScoreOptions || []).includes(sedationScore)) {
      setSedationOption([
        ...(props.masterOptions?.sedation_score || []),
        { key: sedationScore, text: sedationScore, value: sedationScore },
      ]);
    } else {
      setSedationOption(props.masterOptions?.sedation_score);
    }
  }, [
    props.masterOptions?.sedation_score,
    props.PACURecordSequence?.anesPACUAnesthesia?.SedationScore,
  ]);

  const mapOptions = useCallback((items: any) => {
    return (items || []).map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.name_code || item.full_name_code || item.full_name,
      content: item.full_name,
    }));
  }, []);

  // -------------------- Medication
  const columnsMedication: any[] = [
    {
      Header: "Time",
      accessor: "time",
      width: 300,
      Cell: (row: any) => (
        <Input
          fluid
          name="time"
          value={row.original?.time || ""}
          onChange={(_event: any, data: any) => {
            handleChangeMedication(row.index, data);
          }}
        />
      ),
    },
    {
      Header: "Medication",
      accessor: "medication",
      width: 600,
      Cell: (row: any) => (
        <Input
          fluid
          name="medication"
          value={row.original?.medication || ""}
          onChange={(_event: any, data: any) => {
            handleChangeMedication(row.index, data);
          }}
        />
      ),
    },
    {
      Header: "MD/RN",
      accessor: "mdrn",
      width: 600,
      Cell: (row: any) => (
        <Input
          fluid
          name="mdrn"
          value={row.original?.mdrn || ""}
          onChange={(_event: any, data: any) => {
            handleChangeMedication(row.index, data);
          }}
        />
      ),
    },
  ];

  const handleChangeMedication = (index: number, data: any) => {
    let medData = [...(props.PACURecordSequence.data?.nurseMedicationData || [])];
    medData[index][data.name] = data.value;
    props.setProp("PACURecordSequence.data.nurseMedicationData", medData);
  };

  // -------------------- Problem
  const columnsProblem: any[] = [
    {
      Header: "Time",
      accessor: "time",
      width: 300,
      Cell: (row: any) => (
        <Input
          fluid
          name="time"
          value={row.original?.time || ""}
          onChange={(_event: any, data: any) => {
            handleChangeProblem(row.index, data);
          }}
        />
      ),
    },
    {
      Header: "Problem/Remarks",
      accessor: "remark",
      width: 1200,
      Cell: (row: any) => (
        <Input
          fluid
          name="remark"
          value={row.original?.remark || ""}
          onChange={(_event: any, data: any) => {
            handleChangeProblem(row.index, data);
          }}
        />
      ),
    },
  ];

  const handleChangeProblem = (index: number, data: any) => {
    let problemData = [...(props.PACURecordSequence.data?.ansProblemData || [])];
    problemData[index][data.name] = data.value;
    props.setProp("PACURecordSequence.data.ansProblemData", problemData);
  };

  // -------------------- Fluid
  const columnsFluid: any[] = [
    {
      Header: "Time",
      accessor: "time",
      width: 300,
      Cell: (row: any) => (
        <Input
          fluid
          name="time"
          value={row.original?.time || ""}
          onChange={(_event: any, data: any) => {
            handleChangeFluid(row.index, data);
          }}
        />
      ),
    },
    {
      Header: "Medication/IV Fluid",
      accessor: "remark",
      width: 1200,
      Cell: (row: any) => (
        <Input
          fluid
          name="remark"
          value={row.original?.remark || ""}
          onChange={(_event: any, data: any) => {
            handleChangeFluid(row.index, data);
          }}
        />
      ),
    },
  ];

  const handleChangeFluid = (index: number, data: any) => {
    let fluidData = [...(props.PACURecordSequence?.data?.ansFluidData || [])];
    fluidData[index][data.name] = data.value;
    props.setProp(`PACURecordSequence.data.ansFluidData`, fluidData);
  };

  // --------------------
  const handleChangeContainerName = (_event: any, data: any) => {
    setTab(data.name);
  };

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`PACURecordSequence.data.${data.name}`, data.checked);
    } else {
      props.setProp(`PACURecordSequence.data.${data.name}`, data.value);
    }
  };

  const handleChangeAnesthesiaData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`PACURecordSequence.anesPACUAnesthesia.${data.name}`, data.checked);
    } else {
      if (data.name === "Airway" && data.value !== "Other") {
        props.setProp(`PACURecordSequence.anesPACUAnesthesia`, {
          ...props.PACURecordSequence?.anesPACUAnesthesia,
          AirwayOther: "",
          [data.name]: data.value,
        });
      } else {
        props.setProp(`PACURecordSequence.anesPACUAnesthesia.${data.name}`, data.value);
      }
    }
  };

  const handleGetUserTokenizeLicense = (event: any, paramKey: string) => {
    if (event.key === "Enter") {
      props.runSequence({
        sequence: "PACURecord",
        action: "GET_USER_TOKENIZE_LICENSE",
        cardKey: CARD_KEY,
        paramKey: paramKey,
      });
    }
  };

  const handleSetTime = () => {
    let dataRecord = props.PACURecordSequence?.data;
    let newEndDate = moment(
      `${dataRecord?.EndDate} ${dataRecord?.EndAnesRemark}`,
      "DD/MM/YYYY HH:mm"
    );
    let newStartDate = moment(
      `${dataRecord?.StartDate} ${dataRecord?.StartOpRemark}`,
      "DD/MM/YYYY HH:mm"
    );

    let totalTime = newEndDate.diff(newStartDate, "minutes");

    props.setProp("PACURecordSequence.data.PACUDurartionTime", totalTime || 0);
  };

  const deleteAnsProblemData = (index: any) => {
    let newData: any = [...props.PACURecordSequence?.data?.ansProblemData];
    newData.splice(index, 1);
    props.setProp("PACURecordSequence.data.ansProblemData", newData);
  };

  const ansProblemData = useMemo(() => {
    return (props.PACURecordSequence?.data?.ansProblemData || []).map((item: any, index: any) => ({
      ...item,
      time: <div style={{ display: "flex", justifyContent: "center" }}>{item?.time || ""}</div>,
      remark: <div>{item?.remark || ""}</div>,
      menu: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "8px",
          }}
        >
          <div style={{ margin: "10px" }}>
            <Icon
              name="edit outline"
              color="yellow"
              size="large"
              onClick={() => setProblem({ ...item, index: index })}
            />
          </div>
          <div style={{ margin: "10px" }}>
            <Icon
              name="trash alternate outline"
              color="red"
              size="large"
              onClick={() => deleteAnsProblemData(index)}
            />
          </div>
        </div>
      ),
    }));
  }, [props.PACURecordSequence?.data?.ansProblemData]);

  const deleteAnsFluidData = (index: any) => {
    let newData: any = [...props.PACURecordSequence?.data?.ansFluidData];
    newData.splice(index, 1);
    props.setProp(`PACURecordSequence.data.ansFluidData`, newData);
  };

  const ansFluidData = useMemo(() => {
    return (props.PACURecordSequence?.data?.ansFluidData || []).map((item: any, index: number) => ({
      ...item,
      time: <div style={{ display: "flex", justifyContent: "center" }}>{item?.time || ""}</div>,
      remark: <div>{item?.remark || ""}</div>,
      menu: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "8px",
          }}
        >
          <div style={{ margin: "10px" }}>
            <Icon
              name="edit outline"
              color="yellow"
              size="large"
              onClick={() => setFluid({ ...item, index: index })}
            />
          </div>
          <div style={{ margin: "10px" }}>
            <Icon
              name="trash alternate outline"
              color="red"
              size="large"
              onClick={() => deleteAnsFluidData(index)}
            />
          </div>
        </div>
      ),
    }));
  }, [props.PACURecordSequence?.data?.ansFluidData]);

  const deleteNurseMedicationData = (index: any) => {
    let newData: any = [...props.PACURecordSequence?.data?.nurseMedicationData];
    newData.splice(index, 1);
    props.setProp("PACURecordSequence.data.nurseMedicationData", newData);
  };

  const checkMedication = (item: any, type: any) => {
    if (type === "medication") {
      if (props.masterOptions?.drugName) {
        let name =
          props.masterOptions?.drugName.filter((data: any) => data?.value === item?.medication) ||
          "";

        return name?.[0]?.text || "";
      }
    } else if (type === "mdrn") {
      if (props.masterOptions?.drugName && props.PACURecordSequence?.userNurseOptions) {
        let mdrnOptions = props.DJANGO?.user?.role_types?.includes("DOCTOR")
          ? props.masterOptions?.doctor
          : props.DJANGO?.user?.role_types?.includes("REGISTERED_NURSE")
          ? props.PACURecordSequence?.userNurseOptions
          : [];

        let name = mdrnOptions.filter((data: any) => data?.value === item?.mdrn);

        return name?.[0]?.text || "";
      }
    }
  };

  const nurseMedicationData = useMemo(() => {
    let role = props.DJANGO?.user?.role_types?.includes("DOCTOR")
      ? "DOCTOR"
      : props.DJANGO?.user?.role_types?.includes("REGISTERED_NURSE")
      ? "REGISTERED_NURSE"
      : "";

    return (props.PACURecordSequence?.data?.nurseMedicationData || [])
      .filter((item: any) => item.roleTypes === role)
      .map((item: any, index: any) => ({
        ...item,
        time: <div style={{ display: "flex", justifyContent: "center" }}>{item?.time || ""}</div>,
        medication: <div>{checkMedication(item, "medication")}</div>,
        detail: <div>{item?.detail || ""}</div>,
        mdrn: <div>{checkMedication(item, "mdrn")}</div>,
        menu: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "8px",
            }}
          >
            <div style={{ margin: "10px" }}>
              <Icon
                name="edit outline"
                color="yellow"
                size="large"
                onClick={() => setMedication({ ...item, index: index })}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <Icon
                name="trash alternate outline"
                color="red"
                size="large"
                onClick={() => deleteNurseMedicationData(index)}
              />
            </div>
          </div>
        ),
      }));
  }, [props.PACURecordSequence?.data?.nurseMedicationData]);

  // -------------------- Modal Log
  const handleCloseModLog = () => {
    props.setProp("PACURecordSequence.modLogInfo", {
      open: false,
      logList: [],
    });
  };

  const handleAdditionSedationScore = (e: any, v: any) => {
    setSedationOption([...sedationOption, { key: v.value, text: v.value, value: v.value }]);
  };

  // console.log("Yeti PACU Props: ", props?.PACURecordSequence?.data);
  // console.log("Yeti PACU Props (vitalsign): ", props.PACURecordSequence?.anesPACUVitalSign);

  const handleGetVitalSign = (params: any) => {
    props.runSequence({
      sequence: "PACURecord",
      action: "testGetVitalSign",
      data: params,
    });
  };

  const handleSelectedUserTokenizeLicense =
    (searchBox: any): SelectedItemHandler =>
    async (value, key, data) => {
      props.setProp("PACURecordSequence.data", {
        ...props.PACURecordSequence.data,
        PACUNurseId: data?.id,
        PACUNurse: data?.full_name,
      });
    };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />

      <CardPACURecordFormUX
        // function
        onChangeData={handleChangeData}
        onChangeDateForm={(date: string) =>
          props.setProp("PACURecordSequence.data.DateRamrk", date)
        }
        onChangeStartDate={(date: any) => {
          props.setProp("PACURecordSequence.data.StartDate", date);
          handleSetTime();
        }}
        onChangeEndDate={(date: any) => {
          props.setProp("PACURecordSequence.data.EndDate", date);
          handleSetTime();
        }}
        // seq
        PACURecordSequence={props.PACURecordSequence}
        buttonStatus={props.PACURecordSequence?.status}
        masterOptions={props.masterOptions}
        // component
        startTime={
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <TimeFreeTextBox24
              className="time-custom-react-picker"
              onChange={(value: string) => {
                props.setProp("PACURecordSequence.data.StartOpRemark", value);
                handleSetTime();
              }}
              value={props.PACURecordSequence?.data?.StartOpRemark || ""}
              iconClockStyle={{ margin: "auto 30px" }}
              autoFocus={false}
              checkTimeOverflow={true}
              disabled={false}
            ></TimeFreeTextBox24>
          </div>
        }
        endTime={
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <TimeFreeTextBox24
              className="time-custom-react-picker"
              onChange={(value: string) => {
                props.setProp("PACURecordSequence.data.EndAnesRemark", value);
                handleSetTime();
              }}
              value={props.PACURecordSequence?.data?.EndAnesRemark || ""}
              iconClockStyle={{ margin: "auto 30px" }}
              autoFocus={false}
              checkTimeOverflow={true}
              disabled={false}
            ></TimeFreeTextBox24>
          </div>
        }
        buttonLog={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "PACURecord",
                action: "LOG",
                cardKey: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_LOG`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_LOG`]}
            // config
            color="orange"
            size="medium"
            style={{ width: "120px" }}
            title="Log"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "PACURecord",
                action: "PRINT",
                cardKey: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="blue"
            size="medium"
            style={{ width: "120px" }}
            title="Print"
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "PACURecord",
                action: "SAVE",
                cardKey: CARD_KEY,
                formCode: FORM_CODE,
                formName: FORM_NAME,
                formVersion: FORM_VERSION,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE`]}
            // config
            color={"orange"}
            size="medium"
            style={{ width: "120px" }}
            title={"Save Draft"}
          />
        }
        buttonConfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "PACURecord",
                action: "CONFIRM",
                cardKey: CARD_KEY,
                formCode: FORM_CODE,
                formName: FORM_NAME,
                formVersion: FORM_VERSION,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_CONFIRM`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_CONFIRM`]}
            // config
            color="green"
            size="medium"
            style={{ width: "120px" }}
            title="Confirm"
          />
        }
        isPACU={true}
        languageUX={props.languageUX}
      />

      <CardPACURecordTabUX
        onChangeContainerName={handleChangeContainerName}
        tab={tab}
        languageUX={props.languageUX}
      />

      {tab === "Intraoperative" ? (
        <CardPACURecordIntraoperativeUX
          onChangeData={handleChangeData}
          PACURecordSequence={props.PACURecordSequence}
          languageUX={props.languageUX}
        />
      ) : tab === "PostAnesthetic" ? (
        <CardPostAnestheticRecoveryScore
          masterOptions={props.masterOptions}
          setProp={props.setProp}
          runSequence={props.runSequence}
          PACURecordSequence={props.PACURecordSequence}
          buttonLoadCheck={props.buttonLoadCheck}
          languageUX={props.languageUX}
        />
      ) : tab === "AnesVitalSign" ? (
        <CardVitalSign
          useUTCTime={true}
          toggleable={false}
          key="anesVitalSign"
          setProp={props.setProp}
          buttonLoadCheck={props.buttonLoadCheck}
          useCheckboxDateManual={true}
          vitalSignTypeByEncounter={props.PACURecordSequence?.anesPACUVitalSign?.encounterId}
          vitalSignTypeByAge={props.selectedEncounter?.patient_age?.split(" ")[0]}
          defaultDateFilter={props.selectedEncounter?.type === "IPD"}
          onGetLastHeight={() =>
            props.onEvent({
              message: "GetLastHeight",
              params: {},
            })
          }
          onGetVitalSignType={() =>
            props.onEvent({
              message: "GetVitalSignType",
              params: {},
            })
          }
          onGetVitalSign={(params: any) =>
            props.onEvent({
              message: "GetAnesVitalSign",
              params: params,
            })
          }
          onNewMeasurement={(params: any) =>
            props.runSequence({
              sequence: "PACURecord",
              action: "CreateMeasurement",
              data: params,
            })
          }
          onUpdateVitalsignValue={(params: any) =>
            props.runSequence({
              sequence: "PACURecord",
              action: "EditVitalSignValue",
              data: params,
            })
          }
          onDeleteMeasurement={(delMeasureId: number, params: any) =>
            props.runSequence({
              sequence: "PACURecord",
              action: "DeleteMeasurement",
              delMeasureId,
              data: params,
            })
          }
          languageUX={props.languageUX}
        />
      ) : tab === "Monitor" ? (
        <CardPACURecordMonitorUX
          masterOptions={props.masterOptions}
          userNurseOptions={props.PACURecordSequence?.userNurseOptions}
          onChangeData={handleChangeData}
          onKeyAttendingStaff={(event: any) =>
            handleGetUserTokenizeLicense(event, "AttendingStaff")
          }
          onKeyPACUNurse={(event: any) => handleGetUserTokenizeLicense(event, "PACUNurse")}
          PACURecordSequence={props.PACURecordSequence}
          selectPACUNurse={
            <SearchBoxWithKey
              // callback
              onEvent={props.onEvent}
              type="User"
              id="PAREN"
              role="REGISTERED_NURSE"
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={props.PACURecordSequence?.data?.PACUNurseId || null}
              defaultText={props.PACURecordSequence?.data?.PACUNurse}
              inline
              mapOptions={mapOptions}
              setSelected={handleSelectedUserTokenizeLicense({
                id: "User_PARED",
                name: "PACUNurse",
              })}
            />
          }
          languageUX={props.languageUX}
        />
      ) : // : tab === "Medication" ? (
      //   <CardPACURecordMedicationUX
      //     onChangeTimeInput={(value: string) =>
      //       setMedication({ ...medication, time: value })
      //     }
      //     onChangeMedicationInput={(_event: any, data: any) =>
      //       setMedication({ ...medication, [data.name]: data.value })
      //     }
      //     onAddMedication={() => {
      //       if (medication?.index === "") {
      //         // ADD Medication
      //         let data = [
      //           ...(props.PACURecordSequence.data?.nurseMedicationData || []),
      //         ];
      //         data.push({
      //           ...medication,
      //           id: new Date().getTime(),
      //           roleTypes: props.DJANGO?.user?.role_types?.includes("DOCTOR")
      //             ? "DOCTOR"
      //             : props.DJANGO?.user?.role_types?.includes("REGISTERED_NURSE")
      //             ? "REGISTERED_NURSE"
      //             : "",
      //         });
      //         props.setProp(
      //           "PACURecordSequence.data.nurseMedicationData",
      //           data
      //         );
      //         setMedication({
      //           time: "",
      //           medication: "",
      //           detail: "",
      //           mdrn: "",
      //           index: "",
      //         });
      //       } else {
      //         // Edit Medication
      //         let nurseMedicationData = [
      //           ...(props.PACURecordSequence.data?.nurseMedicationData || []),
      //         ];
      //         nurseMedicationData[medication?.index] = medication;
      //         props.setProp(
      //           "PACURecordSequence.data.nurseMedicationData",
      //           nurseMedicationData
      //         );
      //         setMedication({
      //           time: "",
      //           medication: "",
      //           detail: "",
      //           mdrn: "",
      //           index: "",
      //         });
      //       }
      //     }}
      //     PACURecordSequence={props.PACURecordSequence}
      //     columnsMedication={columnsMedication}
      //     medication={medication}
      //     nurseMedicationData={nurseMedicationData}
      //     masterOptions={props.masterOptions}
      //     mdrnOptions={
      //       props.DJANGO?.user?.role_types?.includes("DOCTOR")
      //         ? props.masterOptions?.doctor
      //         : props.DJANGO?.user?.role_types?.includes("REGISTERED_NURSE")
      //         ? props.PACURecordSequence?.userNurseOptions
      //         : []
      //     }
      //     medicationTime={
      //       <TimeFreeTextBox24
      //         className="time-custom-react-picker"
      //         onChange={(value: string) =>
      //           setMedication({ ...medication, time: value })
      //         }
      //         value={medication?.time}
      //         iconClockStyle={{ margin: "auto 30px" }}
      //         autoFocus={false}
      //         checkTimeOverflow={true}
      //       ></TimeFreeTextBox24>
      //     }
      //   />
      // )
      tab === "Problems" ? (
        <CardPACURecordProblemsUX
          onChangeTimeInput={(value: string) => setProblem({ ...problem, time: value })}
          onChangeProblemInput={(_event: any, data: any) =>
            setProblem({ ...problem, [data.name]: data.value })
          }
          onAddProblem={() => {
            if (problem?.index === "") {
              let data = [...(props.PACURecordSequence.data?.ansProblemData || [])];
              data.push({ ...problem, id: new Date().getTime() });
              props.setProp("PACURecordSequence.data.ansProblemData", data);
              setProblem({ time: "", remark: "", index: "" });
            } else {
              // Edit Medication
              let ansProblemData = [...(props.PACURecordSequence.data?.ansProblemData || [])];
              ansProblemData[problem?.index] = problem;
              props.setProp("PACURecordSequence.data.ansProblemData", ansProblemData);
              setProblem({
                time: "",
                remark: "",
                index: "",
              });
            }
          }}
          PACURecordSequence={props.PACURecordSequence}
          columnsProblem={columnsProblem}
          ansProblemData={ansProblemData}
          problem={problem}
          problemTime={
            <TimeFreeTextBox24
              className="time-custom-react-picker"
              onChange={(value: string) => setProblem({ ...problem, time: value })}
              value={problem?.time || ""}
              iconClockStyle={{ margin: "auto 30px" }}
              autoFocus={false}
              checkTimeOverflow={true}
            ></TimeFreeTextBox24>
          }
          languageUX={props.languageUX}
        />
      ) : tab === "Referral" ? (
        <CardPACURecordReferralUX
          onChangeData={handleChangeData}
          masterOptions={props.masterOptions}
          PACURecordSequence={props.PACURecordSequence}
          languageUX={props.languageUX}
        />
      ) : tab === "Fluid" ? (
        <CardPACURecordFluidUX
          onChangeTimeInput={(value: string) => setFluid({ ...fluid, time: value })}
          onChangeFluidInput={(_event: any, data: any) =>
            setFluid({ ...fluid, [data.name]: data.value })
          }
          onAddFluid={() => {
            if (fluid?.index === "") {
              let data = [...(props.PACURecordSequence?.data?.ansFluidData || [])];
              data.push({ ...fluid, id: new Date().getTime() });
              props.setProp(`PACURecordSequence.data.ansFluidData`, data);
              setFluid({ time: "", remark: "", index: "" });
            } else {
              // Edit Fluid data
              let ansFluidData = [...(props.PACURecordSequence?.data?.ansFluidData || [])];
              ansFluidData[fluid?.index] = fluid;
              props.setProp(`PACURecordSequence.data.ansFluidData`, ansFluidData);
              setFluid({
                time: "",
                remark: "",
                index: "",
              });
            }
          }}
          PACURecordSequence={props.PACURecordSequence}
          columnsFluid={columnsFluid}
          ansFluidData={ansFluidData}
          fluid={fluid}
          fluidTime={
            <TimeFreeTextBox24
              className="time-custom-react-picker"
              onChange={(value: string) => setFluid({ ...fluid, time: value })}
              value={fluid?.time || ""}
              iconClockStyle={{ margin: "auto 30px" }}
              autoFocus={false}
              checkTimeOverflow={true}
            />
          }
          languageUX={props.languageUX}
        />
      ) : (
        <CardPACURecordAnesthesiaUX
          onAdditionSedationScore={handleAdditionSedationScore}
          onChangeData={handleChangeData}
          onChangeAnesthesiaData={handleChangeAnesthesiaData}
          sedationOption={sedationOption}
          masterOptions={props.masterOptions}
          anesPACUAnesthesia={props.PACURecordSequence?.anesPACUAnesthesia}
          onSavePACUAnesthesia={() => {
            props.runSequence({
              sequence: "PACURecord",
              action: "SaveAnesthesia",
              formCode: "CardPACURecordAnesthesia",
              formName: "CardPACURecordAnesthesia",
              formVersion: FORM_VERSION,
            });
          }}
          languageUX={props.languageUX}
        />
      )}

      <Modal
        open={props.PACURecordSequence?.modLogInfo?.open}
        closeOnDimmerClick={true}
        onClose={handleCloseModLog}
      >
        <CardANSLog
          showAction={true}
          editDetail={true}
          dataList={props.PACURecordSequence?.modLogInfo?.logList || []}
          hideCallback={handleCloseModLog}
          languageUX={props.languageUX}
        />
      </Modal>
    </>
  );
};

export default React.memo(CardPACURecord);
