import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
import ConstanceView from "issara-sdk/apis/ConstanceView_core";

import TelepharDrugOrderListView from "issara-sdk/apis/TelepharDrugOrderListView_apps_TPD";
import TelepharDrugOrderOnlineListView from "issara-sdk/apis/TelepharDrugOrderOnlineListView_apps_TPD";

// Serializer
import DrugOrderSerializerForTelepharI from "issara-sdk/types/DrugOrderSerializerForTelephar_apps_TPD";

// Interface
import { getDrugStatusName } from "../TPDInterface";
import { State as MainState } from "HIS/MainHISInterface";

// Utils
import { beToAd, formatDate } from "react-lib/utils/dateUtils";

// Types
export type State = Partial<{
  // CommonInterface
  masterOptions?: MasterOptionsType;

  // sequence
  IntraTelepharQueueSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;

    filterRx: Partial<FilterRxType>;
    filterInprogress: Partial<FilterInprogressType>;
    filterBilled: Partial<FilterBilledType>;
    filterOnlineQueue: Partial<FilterOnlineQueueType>;
    inprogressRxList: PrescriptionListType;
    billedRxList: PrescriptionListType;
    onlineQueueList: { all: OnlineQueueType[]; result: OnlineQueueType[] };
    constance: {
      TPD_TELEPHAR_BILLED_WARNING_TIME: number;
      TPD_TELEPHAR_ONLINE_WARNING_TIME: number;
    };
    // options
    inprogressRxTypeOptions: OptionType[];
  }> | null;
}>;

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "selectedDivision"
    | "searchedItemListWithKey"
    | "selectedEncounter"
    | "selectedRecordViewIndex"
    // * Video call
    | "openVideoCallModal"
    | "closeOverriding"
    | "videoCallRoom"
    // * Drug Order Queue
    | "drugOrderQueue"
  >
>;

export type DrugOrderQueueSerializer = {
  type: IntraTelepharType;
  missing: boolean;
  exceed_time: boolean;
  telephar_type: keyof typeof TELEPHAR_TYPE;
} & Omit<DrugOrderSerializerForTelepharI, "type" | "telephar_type">;

export type MasterOptionsType = Record<
  (typeof Masters)[number][0],
  OptionType[]
>;

export type IntraTelepharType = keyof typeof INTRA_TELEPHAR_TYPE;

export type CallStatus = keyof typeof CALL_STATUS | "";

export type FilterOnlineQueueType = {
  type: string[];
  status: string[];
};

type OnlineQueueType = {
  id: number;
  code: string;
  type: string;
  status: string;
  order_by: string;
  order_time: string;
  order_payment_status: string;
  last_tele_consult_at: any;
  order_div: number;
  order_perform_div: number;
  patient: {
    id: number;
    full_name: string;
    hn: string;
  };
  telephar_type: keyof typeof TELEPHAR_TYPE;
  consult_status: keyof typeof CONSULT_STATUS;

  call_type: keyof typeof CALL_TYPE;
  call_status: CallStatus;
};

type PrescriptionListType = {
  all: DrugOrderQueueSerializer[];
  result: DrugOrderQueueSerializer[];
};

type FilterRxType = {
  code: string;
  patientId: number | null;
  doctorId: number | null;
  fromDivisionId: number;
  startDate: string;
  endDate: string;
  toDivisionId: number;
  approveId: "NONE" | string;
};

type FilterInprogressType = {
  type: (string | number)[];
  status: string[];
};

type FilterBilledType = {
  type: (string | number)[];
  status: string;
  isHideMissing: boolean;
};

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "IntraTelepharQueue";
  restart?: boolean;
  clear?: boolean;
  card?: string;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; card: string; noError?: boolean }
  // Action
  | { action: "FILTER_INPROGRESS_RX"; data?: { name: string; value: string } }
  | { action: "FILTER_BILLED_RX"; data?: { name: string; value: string } }
  | { action: "FILTER_ONLINE_QUEUE"; data: Partial<FilterOnlineQueueType> }
  | {
    action: "SELECT_ORDER";
    data: Record<string, any>;
    history: any;
    // callback
    forward?: () => any;
  };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type CustomExtract<T, U> = T extends T
  ? U extends Partial<T>
  ? T
  : never
  : never;

type Params<A extends ActionType["action"]> = CustomExtract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  IntraTelepharQueueSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

const Masters = [
  ["divisionPharma", {}],
  ["drugOrderType", {}],
  ["division", {}],
] as const;

const IMAGES = {
  home_med_intra: "/static/images/intratelephar/home-med-intra.png",
  home_med_delivery: "/static/images/intratelephar/home-med-delivery.png",
  home_med_delivery_performed:
    "/static/images/intratelephar/home-med-delivery-performed.png",
};

export const INTRA_TELEPHAR_TYPE = {
  STAT: "STAT",
  ONE_DOSE: "ONE_DOSE",
  HOME_OPD: "HOME_OPD",
  HOME_INTRA: "HOME_INTRA",
  HOME_DELIVERY: "HOME_DELIVERY",
} as const;

export const INTRA_TELEPHAR_STATUS = {
  REQUESTED: "REQUESTED",
  REJECTED: "REJECTED",
  PRINTED: "PRINTED",
  VERIFIED: "VERIFIED",
  CHECKED: "CHECKED",
  DELIVERED: "DELIVERED",
  TRANSPORTED: "TRANSPORTED",
  RECEIVED: "RECEIVED",
  COUNSELLING: "COUNSELLING",
  CHECKED_PAID_NO_PICKED_UP: "CHECKED_PAID_NO_PICKED_UP",
} as const;

export const TELEPHAR_TYPE = {
  NONE: "NONE",
  INTRA: "INTRA",
  DELIVERY: "DELIVERY",
  NURSE_COUNSELLING: "NURSE_COUNSELLING",
} as const;

export const CONSULT_STATUS = {
  IDLE: "IDLE",
  CALLING: "CALLING",
  ON_CALL: "ON_CALL",
  FINISH: "FINISH",
} as const;

export const INPROGRESS_RX_STATUS = {
  [INTRA_TELEPHAR_STATUS.REQUESTED]: INTRA_TELEPHAR_STATUS.REQUESTED,
  [INTRA_TELEPHAR_STATUS.REJECTED]: INTRA_TELEPHAR_STATUS.REJECTED,
  [INTRA_TELEPHAR_STATUS.PRINTED]: INTRA_TELEPHAR_STATUS.VERIFIED,
  [INTRA_TELEPHAR_STATUS.CHECKED]: INTRA_TELEPHAR_STATUS.CHECKED,
  [INTRA_TELEPHAR_STATUS.DELIVERED]: INTRA_TELEPHAR_STATUS.DELIVERED,
};

export const INPROGRESS_RX_TYPE = {
  [INTRA_TELEPHAR_TYPE.STAT]: INTRA_TELEPHAR_TYPE.STAT,
  [INTRA_TELEPHAR_TYPE.ONE_DOSE]: "ONE DOSE",
  [INTRA_TELEPHAR_TYPE.HOME_OPD]: "HOME MED (Normal)",
  [INTRA_TELEPHAR_TYPE.HOME_INTRA]: "HOME MED (IntraTelePhar)",
  [INTRA_TELEPHAR_TYPE.HOME_DELIVERY]: "HOME MED (Delivery)",
};

export const BILLED_RX_STATUS = {
  [INTRA_TELEPHAR_STATUS.CHECKED]: INTRA_TELEPHAR_STATUS.CHECKED,
  [INTRA_TELEPHAR_STATUS.DELIVERED]: INTRA_TELEPHAR_STATUS.DELIVERED,
  [INTRA_TELEPHAR_STATUS.TRANSPORTED]: INTRA_TELEPHAR_STATUS.TRANSPORTED,
};

export const BILLED_RX_TYPE = {
  [INTRA_TELEPHAR_TYPE.HOME_OPD]: "HOME MED (Normal)",
  [INTRA_TELEPHAR_TYPE.HOME_DELIVERY]: "HOME MED (Delivery)",
};

const INPROGRESS_STATUS = [
  [INTRA_TELEPHAR_STATUS.REQUESTED, ""],
  [INTRA_TELEPHAR_STATUS.REJECTED, ""],
  [INTRA_TELEPHAR_STATUS.VERIFIED, ""],
  [INTRA_TELEPHAR_STATUS.CHECKED, "READY"],
];

const BILLED_STATUS = [
  [INTRA_TELEPHAR_STATUS.CHECKED, "PAID"],
  [INTRA_TELEPHAR_STATUS.DELIVERED, ""],
  [INTRA_TELEPHAR_STATUS.TRANSPORTED, ""],
];

export const CALL_TYPE = {
  CONSULT: "Consult",
  [TELEPHAR_TYPE.INTRA]: "Telepharmacy",
  [TELEPHAR_TYPE.NURSE_COUNSELLING]: "Nurse counselling",
};

export const CALL_STATUS = {
  [INTRA_TELEPHAR_STATUS.CHECKED]: "Waiting",
  [INTRA_TELEPHAR_STATUS.TRANSPORTED]: "Transported",
  [INTRA_TELEPHAR_STATUS.RECEIVED]: "Received",
  [INTRA_TELEPHAR_STATUS.COUNSELLING]: "Counselling",
  [INTRA_TELEPHAR_STATUS.DELIVERED]: "Delivered",
  "": "",
};

export const TYPE_LABEL = {
  [INTRA_TELEPHAR_TYPE.STAT]: { text: INTRA_TELEPHAR_TYPE.STAT },
  [INTRA_TELEPHAR_TYPE.ONE_DOSE]: { text: "ONE DOSE" },
  [INTRA_TELEPHAR_TYPE.HOME_OPD]: { text: "HOME MED" },
  [INTRA_TELEPHAR_TYPE.HOME_INTRA]: {
    text: "HOME MED",
    icon: IMAGES.home_med_intra,
    style: { padding: "1px" },
  },
  [INTRA_TELEPHAR_TYPE.HOME_DELIVERY]: {
    text: "HOME MED",
    icon: IMAGES.home_med_delivery,
    active: IMAGES.home_med_delivery_performed,
  },
};

export const APPROVE_OPTIONS = [
  { key: "NONE", text: "ALL", value: "NONE" },
  { key: "APPROVED", text: "APPROVED", value: "APPROVED" },
  { key: "NEED_APPROVE", text: "NEED APPROVE", value: "NEED_APPROVE" },
];

export const ONLINE_QUEUE_FILTER = {
  type: Object.keys(CALL_TYPE),
  status: Object.keys(CALL_STATUS).filter(
    (key) => key && key !== INTRA_TELEPHAR_STATUS.DELIVERED
  ),
};

export const PACKAGE_SEARCH_ID = "Patient_ITQ";

export const DOCTOR_SEARCH_ID = "Doctor_ITQ";

export const CURRENT_DATE = formatDate(moment());

const DATE_FORMAT = "YYYY-MM-DD";

const DRUG_ORDER_LIMIT = 200;

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: { masters: Masters },
  } as any);

  const [[constance], options] = await Promise.all([
    GetConstance(controller, params),
    GetDrugTypeOptions(controller, params),
  ]);

  const state = controller.getState();

  controller.setState(
    {
      IntraTelepharQueueSequence: {
        ...state.IntraTelepharQueueSequence,
        sequenceIndex: "Action",
        filterRx: {
          startDate: CURRENT_DATE,
          endDate: CURRENT_DATE,
          toDivisionId: state.selectedDivision?.id,
          approveId: "NONE",
        },
        inprogressRxTypeOptions: options,
        constance: constance?.result || {},
      },
    },
    () =>
      Action(controller, {
        card: params.card || "",
        action: "SEARCH",
        noError: true,
      })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers = {
    SEARCH: HandleSearch,
    FILTER_INPROGRESS_RX: HandleFilterInprogressRx,
    FILTER_BILLED_RX: HandleFilterBilledRx,
    FILTER_ONLINE_QUEUE: HandleFilterOnlineQueue,
    SELECT_ORDER: HandleSelectOrder,
  };

  const handlerFunction = actionHandlers[params.action];

  handlerFunction?.(controller, params as any);
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
  });

  const seq = state.IntraTelepharQueueSequence;
  const inprogressRxTypeOptions = seq?.inprogressRxTypeOptions || [];

  // * Types
  const inprogressType = [
    INTRA_TELEPHAR_TYPE.STAT,
    INTRA_TELEPHAR_TYPE.ONE_DOSE,
    INTRA_TELEPHAR_TYPE.HOME_OPD,
  ];
  const billedType = [INTRA_TELEPHAR_TYPE.HOME_OPD];
  const billedRxStatus = {
    ...BILLED_RX_STATUS,
    [INTRA_TELEPHAR_STATUS.CHECKED_PAID_NO_PICKED_UP]: "",
  };

  // * Fetch
  const [[inprogress, errorI], [billed, errorB], [online, errorO]] =
    await Promise.all([
      GetListDrugOrderQueue(controller, {
        billed: false,
        type: inprogressType,
        status: Object.keys(INPROGRESS_RX_STATUS),
      }),
      GetListDrugOrderQueue(controller, {
        billed: true,
        type: billedType,
        status: Object.keys(billedRxStatus),
      }),
      GetListDrugOrderOnlineQueue(controller, {}),
    ]);

  // * Filter
  const inprogressRxList = filterNSortList(
    inprogress?.items,
    INPROGRESS_STATUS
  ).filter((item) => INPROGRESS_RX_TYPE[item.type]);

  const billedRxList = filterNSortList(billed?.items, BILLED_STATUS).filter(
    (item) => Object.keys(BILLED_RX_TYPE).includes(item.type)
  );

  const onlineQueueList = filterOnlineQueue(controller, {
    items: online?.items || [],
    filter: ONLINE_QUEUE_FILTER,
  });

  const inprogressTypeItems = inprogressRxTypeOptions.map((item) => item.value);
  const billedTypeItems = Object.keys(BILLED_RX_TYPE);

  const statusItems = Object.keys(INPROGRESS_RX_STATUS);

  // * แสดง error message
  const notFound =
    !inprogressRxList.length && !billedRxList.length && !onlineQueueList.length;

  const errorMessage = errorI || errorB || errorO;

  const status =
    !params.noError && notFound
      ? { error: errorMessage || "NOT_FOUND", button: "ERROR" }
      : { error: null, button: "SUCCESS" };

  state = controller.getState();

  controller.setState(
    {
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card]: status.button,
      },
      errorMessage: { ...state.errorMessage, [params.card]: status.error },
      IntraTelepharQueueSequence: {
        ...seq,
        filterInprogress: {
          type: inprogressTypeItems,
          status: statusItems,
        },
        filterBilled: {
          isHideMissing: true,
          status: BILLED_RX_STATUS.CHECKED,
          type: billedTypeItems,
        },
        filterOnlineQueue: ONLINE_QUEUE_FILTER,
        inprogressRxList: {
          all: [...inprogressRxList],
          result: [], // * เพื่อรอเรียกการกรอง,
        },
        billedRxList: {
          all: [...billedRxList],
          result: [], // * เพื่อรอเรียกการกรอง,
        },
        onlineQueueList: {
          all: [...(online?.items || [])],
          result: onlineQueueList,
        },
      },
    },
    async () => {
      await Action(controller, { action: "FILTER_INPROGRESS_RX" });
      await Action(controller, { action: "FILTER_BILLED_RX" });
    }
  );
};

const HandleFilterInprogressRx: Handler<Params<"FILTER_INPROGRESS_RX">> = (
  controller,
  params
) => {
  const state = controller.getState();

  const seq = state.IntraTelepharQueueSequence;
  const allList = seq?.inprogressRxList?.all || [];
  const filterInprogress = seq?.filterInprogress || {};

  const filter = {
    ...filterInprogress,
    ...(params.data ? { [params.data.name]: params.data.value } : {}),
  };

  let items = allList.filter((item) => {
    const filterStatus = filter.status?.map((status) =>
      status === INTRA_TELEPHAR_STATUS.PRINTED ? INTRA_TELEPHAR_STATUS.VERIFIED : status
    );

    return (
      compareValue(item.status, filterStatus, "in") && compareValue(item.type, filter.type, "in")
    );
  });

  controller.setState({
    IntraTelepharQueueSequence: {
      ...seq,
      inprogressRxList: { all: allList, result: items },
      filterInprogress: filter,
    },
  });
};

const HandleFilterBilledRx: Handler<Params<"FILTER_BILLED_RX">> = (
  controller,
  params
) => {
  const state = controller.getState();

  const seq = state.IntraTelepharQueueSequence;
  const allList = seq?.billedRxList?.all || [];
  const filterBilled = seq?.filterBilled || {};

  const filter = {
    ...filterBilled,
    ...(params.data ? { [params.data.name]: params.data.value } : {}),
  };

  let items = allList.filter((item) => {
    return (
      compareValue(item.status, filter.status, "in") &&
      compareValue(item.type, filter.type, "in") &&
      compareValue(!item.missing, filter.isHideMissing, "eq")
    );
  });

  controller.setState({
    IntraTelepharQueueSequence: {
      ...seq,
      billedRxList: { all: allList, result: items },
      filterBilled: filter,
    },
  });
};

const HandleFilterOnlineQueue: Handler<Params<"FILTER_ONLINE_QUEUE">> = (
  controller,
  params
) => {
  const state = controller.getState();

  const seq = state.IntraTelepharQueueSequence;
  const allList = seq?.onlineQueueList?.all || [];
  const filter = params.data;

  let items = filterOnlineQueue(controller, { items: allList, filter });

  controller.setState({
    IntraTelepharQueueSequence: {
      ...seq,
      onlineQueueList: { all: allList, result: items },
      filterOnlineQueue: filter,
    },
  });
};

const HandleSelectOrder: Handler<Params<"SELECT_ORDER">> = async (
  controller,
  params
) => {
  const data = params.data;

  // * กดเข้าไปไม่ได้ถ้าเป็น ON CALL
  if (data.consult_status === "ON_CALL") {
    return;
  }

  const drugOrderQueue = getDrugOrderQueue(controller, params);

  await controller.setState({
    selectedEncounter: null,
    selectedRecordViewIndex: 0,
    drugOrderQueue,
  });

  const encounterId = data.encounter?.id || data.encounter;

  const isCalling = data.consult_status === CONSULT_STATUS.CALLING;

  // * Table online queue
  if (isCalling) {
    // start video call
    await controller.setState({
      openVideoCallModal: true,
      closeOverriding: false,
      videoCallRoom: data.code,
    });

    controller.handleEvent({
      message: "DrugOrderUpdateCallTelephar" as any,
      params: {
        id: params.data.id,
        data: { consult_status: CONSULT_STATUS.ON_CALL },
      },
    });
  }

  controller.handleEvent({
    message: "SelectDrugOrderWorking" as any,
    params: {
      drugOrder: data ? { ...data, encounter: encounterId } : null,
      history: params.history,
    },
  });

  params.forward?.();
};

const filterOnlineQueue: Handler<{
  items: OnlineQueueType[];
  filter: Partial<FilterOnlineQueueType>;
}> = (controller, params) => {
  const state = controller.getState();

  const seq = state.IntraTelepharQueueSequence;
  const filterRx = seq?.filterRx || {};

  const status = [...(params.filter?.status || []), ""].flatMap((item) =>
    item.split(",")
  );

  const searchedDoctor = state.searchedItemListWithKey?.[
    DOCTOR_SEARCH_ID
  ]?.find((item: any) => item.id === filterRx.doctorId);

  let items = params.items.filter((item) => {
    // #const orderDate = moment(item.order_time).format(DATE_FORMAT);
    // const fromDate = beToAd(filterRx.startDate || "")?.format(DATE_FORMAT);
    // const toDate = beToAd(filterRx.endDate || "")?.format(DATE_FORMAT);

    return (
      // filter prescription
      compareValue(item.code, filterRx.code, "eq") &&
      compareValue(item.patient.id, filterRx.patientId, "eq") &&
      compareValue(item.order_by, searchedDoctor?.full_name, "eq") &&
      compareValue(item.order_div, filterRx.fromDivisionId, "eq") &&
      // compareValue(orderDate, fromDate, "gte") &&
      // compareValue(orderDate, toDate, "lte") &&
      compareValue(item.order_perform_div, filterRx.toDivisionId, "eq") &&
      // filter online
      compareValue(item.call_type, params.filter?.type, "in") &&
      compareValue(item.call_status, status, "in")
    );
  });

  return items;
};

const getDrugOrderQueue: Handler<{ data: Record<string, any> }> = (
  controller,
  params
) => {
  const state = controller.getState();

  const seq = state.IntraTelepharQueueSequence;

  const inprogress = seq?.inprogressRxList?.all || [];
  const billed = seq?.billedRxList?.all || [];
  const online = seq?.onlineQueueList?.all || [];

  let allList: any[] = [...inprogress, ...billed, ...online];

  const target = allList.find((item) => item.id === params.data.id);

  // * Filter เอาเฉพาะ IDLE
  const filteredList = allList.filter((item) => item.consult_status === "IDLE");

  const arrayUniqueByKey = [
    ...new Map(
      [...filteredList, target].map((item) => [item["id"], item])
    ).values(),
  ];

  // * Format ข้อมูลเพื่อนำไปแสดงหน้า queue drug workflow
  const formattedList = arrayUniqueByKey.map((item) => {
    const division = state.masterOptions?.division?.find(
      (option) => option.value === item.order_div
    )?.text;

    const orderDivName =
      typeof item.order_div === "number" ? division : item.order_div.name;

    return {
      id: item.id,
      pk: item.id,
      code: item.code,
      encounter_type: "OPD",
      type: item.raw_type,
      status: item.raw_status,
      hn: item.patient.hn,
      patient: item.patient.full_name,
      patient_id: item.patient.id.toString(),
      order_by_name: item.order_by,
      order_div_name: orderDivName,
      requested: item.order_time,
    };
  });

  return formattedList;
};

/* ------------------------------------------------------ */

/*                          APIS                          */

/* ------------------------------------------------------ */
const GetListDrugOrderQueue: Handler<
  { billed: boolean; type: string[]; status: string[] },
  Promise<[{ items: DrugOrderQueueSerializer[] } | null, any, any]>
> = async (controller, params) => {
  const state = controller.getState();
  const seq = state.IntraTelepharQueueSequence;
  const filterRx = seq?.filterRx || {};

  const searchedPatient = state.searchedItemListWithKey?.[
    PACKAGE_SEARCH_ID
  ]?.find((item: any) => item.id === filterRx.patientId);

  const [result, error] = await TelepharDrugOrderListView.list({
    apiToken: controller.apiToken,
    params: {
      billed: params.billed,
      created__gte: filterRx.startDate
        ? beToAd(filterRx.startDate)?.format(DATE_FORMAT)
        : undefined,
      created__lte: filterRx.endDate
        ? beToAd(filterRx.endDate)?.format(DATE_FORMAT)
        : undefined,
      exclude_verbal_null_item: true,
      order_perform_div: filterRx.toDivisionId,
      approve_status: filterRx.approveId,
      order_by: filterRx.doctorId,
      type: params.type,
      status: params.status,
      order_div: filterRx.fromDivisionId,
      patient: searchedPatient?.hn,
      code: filterRx.code,
      limit: DRUG_ORDER_LIMIT,
    },
  });

  const items = (result?.items || []).map((item: any) => {
    const homes = {
      INTRA: INTRA_TELEPHAR_TYPE.HOME_INTRA,
      DELIVERY: INTRA_TELEPHAR_TYPE.HOME_DELIVERY,
    } as any;

    const type = homes[item.telephar_type] || item.type;

    const status = getDrugStatusName(item.status, item.consult_status);

    const receipt = item.receipts.slice(-1)?.[0] || {};

    const diff = moment().diff(moment(receipt.created), "seconds");

    const time =
      state.IntraTelepharQueueSequence?.constance
        ?.TPD_TELEPHAR_BILLED_WARNING_TIME || -1;

    // * เวลาการรอคอยในการรับยาของผู้ป่วย
    const isExceed =
      [
        INTRA_TELEPHAR_STATUS.CHECKED,
        INTRA_TELEPHAR_STATUS.CHECKED_PAID_NO_PICKED_UP,
      ].includes(item.status) && diff > time;

    return {
      ...item,
      status,
      type,
      raw_type: item.type,
      raw_status: item.status,
      exceed_time: isExceed,
      missing: item.status === INTRA_TELEPHAR_STATUS.CHECKED_PAID_NO_PICKED_UP,
    };
  });

  return [{ items }, error, null];
};

const GetListDrugOrderOnlineQueue: Handler<
  {},
  Promise<[{ items: OnlineQueueType[] } | null, any, any]>
> = async (controller, params) => {
  const state = controller.getState();
  const seq = state.IntraTelepharQueueSequence;
  const filterRx = seq?.filterRx || {};

  const fromDate = beToAd(filterRx.startDate || "")?.format(DATE_FORMAT);
  const toDate = beToAd(filterRx.endDate || "")?.format(DATE_FORMAT);

  const [result, error] = await TelepharDrugOrderOnlineListView.list({
    apiToken: controller.apiToken,
    params: {
      consult_status: Object.keys(CONSULT_STATUS),
      created__gte: fromDate,
      created__lte: toDate,
    },
  });

  let items = (result?.items || []).map((item: any) => {
    const isIntra =
      item.order_payment_status === "PAID" &&
      item.telephar_type === TELEPHAR_TYPE.INTRA;

    let callStatus = "";

    if (isIntra) {
      const isReceiveCall =
        item.status === INTRA_TELEPHAR_STATUS.RECEIVED &&
        [CONSULT_STATUS.CALLING, CONSULT_STATUS.ON_CALL].includes(
          item.consult_status
        );

      const isReceiveFinish =
        item.status === INTRA_TELEPHAR_STATUS.RECEIVED &&
        item.consult_status === CONSULT_STATUS.FINISH;

      if (isReceiveCall) {
        callStatus = INTRA_TELEPHAR_STATUS.COUNSELLING;
      } else if (isReceiveFinish) {
        callStatus = INTRA_TELEPHAR_STATUS.DELIVERED;
      } else {
        callStatus = item.status;
      }
    }

    const diff = moment().diff(moment(item.last_tele_consult_at), "seconds");

    const time =
      state.IntraTelepharQueueSequence?.constance
        ?.TPD_TELEPHAR_ONLINE_WARNING_TIME || -1;

    // * เวลาการรอคอยในการรับยาของผู้ป่วย
    const isExceed =
      item.consult_status === CONSULT_STATUS.CALLING &&
      callStatus === INTRA_TELEPHAR_STATUS.COUNSELLING &&
      diff > time;

    return {
      ...item,
      call_type: isIntra ? TELEPHAR_TYPE.INTRA : "CONSULT",
      call_status: callStatus,
      exceed_time: isExceed,
      raw_type: item.type,
      raw_status: item.status,
    };
  });

  const sequences = {
    INTRA: [
      INTRA_TELEPHAR_STATUS.COUNSELLING,
      INTRA_TELEPHAR_STATUS.RECEIVED,
      INTRA_TELEPHAR_STATUS.TRANSPORTED,
      INTRA_TELEPHAR_STATUS.CHECKED,
      INTRA_TELEPHAR_STATUS.DELIVERED,
    ],
  } as any;

  const sortedArray = items.sort((a: any, b: any) => {
    const seqA = sequences[a.call_type]?.indexOf(a.status) || -1;
    const seqB = sequences[b.call_type]?.indexOf(b.status) || -1;

    return seqA - seqB;
  });

  return [{ items: sortedArray }, error, null];
};

const GetConstance: Handler = async (controller, params) => {
  return await ConstanceView.get({
    params: {
      list: [
        "TPD_TELEPHAR_BILLED_WARNING_TIME",
        "TPD_TELEPHAR_ONLINE_WARNING_TIME",
      ].join(","),
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });
};

const GetDrugTypeOptions: Handler = async (controller, params) => {
  const state = controller.getState();

  await controller.handleEvent({
    message: "GetMasterData",
    params: { masters: [Masters[1]] },
  } as any);

  const homeMedExtra = [
    { key: 98, value: 98, text: "HOME_INTRA" },
    { key: 99, value: 99, text: "HOME_DELIVERY" },
  ];

  const drugTypeOptions = [
    ...(state.masterOptions?.drugOrderType || []),
    ...homeMedExtra,
  ];

  const options = drugTypeOptions.flatMap((option) =>
    Object.keys(INPROGRESS_RX_TYPE).includes(option.text)
      ? [
        {
          key: option.value,
          value: option.text,
          text: (INPROGRESS_RX_TYPE as any)[option.text],
        },
      ]
      : []
  );

  return options;
};

/* ------------------------------------------------------ */

/*                          UTILS                         */

/* ------------------------------------------------------ */
const filterNSortList = (
  items?: DrugOrderQueueSerializer[],
  status: string[][] = []
) => {
  const filter = (items || []).filter((item: any) =>
    status.find((acc) => item.status.includes(acc[0]))
  );

  const sequences = {
    STAT: 1,
    ONE_DOSE: 2,
    HOME_OPD: 3,
    HOME_INTRA: 3,
    HOME_DELIVERY: 3,
  } as any;

  const sortedArray = filter.sort((a, b) => {
    const seqA = sequences[a.type] || 0;
    const seqB = sequences[b.type] || 0;

    return seqA - seqB;
  });

  return sortedArray;
};

const compareValue = (
  data: number | string | boolean | number[],
  compare?: null | number | string | boolean | (string | number)[],
  operator: "eq" | "in" | "gte" | "lte" = "eq"
) => {
  const isCompare = Array.isArray(compare) ? compare.length : compare;

  if (!isCompare) {
    return true;
  }

  const operators = {
    eq: () => data === compare,
    gte: () => compare && data >= compare,
    lte: () => compare && data <= compare,
    in: () => {
      if (typeof data === "string") {
        if (Array.isArray(compare)) {
          return compare.includes(data);
        } else if (typeof compare === "string") {
          return data.includes(compare);
        }
      } else if (Array.isArray(data) && typeof compare === "number") {
        return data.includes(compare);
      }
      return false;
    },
    // Add more operators as needed
  };

  return operators[operator]();
};
