import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Form, FormGroup, FormField, Button } from "semantic-ui-react";

const CardAddCoPhysicianUX = (props: any) => {
  return (
    <div>
      <Form>
        <FormGroup>
          <FormField inline={true}>
            <label style={{ fontWeight: "bold" }}>{props.primary_doctor}</label>
            <label style={{ color: "grey" }}>(แพทย์เจ้าของไข้)</label>
          </FormField>
        </FormGroup>
        <hr></hr>
        <FormGroup>
          <FormField>
            <label style={{ color: "grey" }}>แพทย์ผู้ร่วมรักษา</label>
          </FormField>
        </FormGroup>
        <FormGroup style={{ marginBottom: "30px" }}>
          <FormField width={14}>
            <div>{props.addDoctorSearchbox}</div>
          </FormField>
          <FormField width={2}>
            <Button color="green" icon="plus" onClick={props.onAddCoPhysician} size="mini"></Button>
          </FormField>
        </FormGroup>
        <FormGroup>
          <div style={{ width: "100%" }}>{props.coPhysicianComponent}</div>
        </FormGroup>
        <FormGroup>
          <FormField width={9}></FormField>
          <FormField width={4}>
            <div>{props.buttonSave}</div>
          </FormField>
          <FormField width={3}>
            <Button color="red" onClick={props.onClose}>
              ปิด
            </Button>
          </FormField>
        </FormGroup>
      </Form>
    </div>
  );
};

export default CardAddCoPhysicianUX;

export const screenPropsDefault = {};

/* Date Time : Fri Jan 10 2025 14:02:38 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.primary_doctor"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "(แพทย์เจ้าของไข้)"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"grey\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้ร่วมรักษา"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"grey\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 8,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ marginBottom: \"30px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 13,
      "props": {
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 13,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addDoctorSearchbox"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 15,
      "props": {
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddCoPhysician"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.coPhysicianComponent"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 20,
      "props": {
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 20,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 20,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Button",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "ปิด"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 26,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardAddCoPhysicianUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
