import React, { useState } from "react";

import { Button } from "semantic-ui-react";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

import ModConfirmCNMI from "react-lib/apps/common/cnmi/ModConfirm";
import ModInfo from "react-lib/apps/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Types
type CardBuyerEncounterProps = {
  onEvent: (e: any) => void;
  setProp: (key: string, value: any, callback?: () => void) => any;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  selectedEncounter?: Record<string, any>;
  selectedPatient?: Record<string, any>;
};

const BUTTON_ACTIONS = {
  save: "SAVE",
};

const CARD_BUYER_ENCOUNTER = "CardBuyerEncounter";

const ACTION_SAVE = `${CARD_BUYER_ENCOUNTER}_${BUTTON_ACTIONS.save}`;

const CardBuyerEncounter = (props: CardBuyerEncounterProps) => {
  const [openModConfirm, setOpenModConfirm] = useState<boolean>(false);
  const [openModInfo, setOpenModInfo] = useState<boolean>(false);

  const handleOpenModConfirm = () => {
    setOpenModConfirm(true);
  };

  const handleConfirm = () => {
    props.onEvent({
      message: "HandleCreateBuyerEncounter",
      params: {
        btnAction: ACTION_SAVE,
        card: CARD_BUYER_ENCOUNTER,
        onSuccess: () => {
          handleCloseModConfirm();

          setOpenModInfo(true);
        },
      },
    });
  };

  const handleCloseModConfirm = () => {
    setOpenModConfirm(false);
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(false);
  };

  return (
    <>
      {!props.selectedEncounter?.id && props.selectedPatient?.id && (
        <Button
          color="orange"
          size="small"
          style={{ lineHeight: 1.25, paddingBottom: "0.25rem", paddingTop: "0.25rem" }}
          fluid
          onClick={handleOpenModConfirm}
        >
          เปิด Encounter
          <br />
          เพื่อซื้อสินค้า
        </Button>
      )}

      <ModConfirmCNMI
        openModal={openModConfirm}
        titleName={"แจ้งเตือน"}
        onCloseWithDimmerClick={handleCloseModConfirm}
        onDeny={handleCloseModConfirm}
        approveButton={
          <ButtonLoadCheck
            setProp={props.setProp}
            color={"green"}
            paramKey={ACTION_SAVE}
            size="medium"
            title={"บันทึก"}
            basic
            fluid
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SAVE]}
            onClick={handleConfirm}
          />
        }
        content={
          <>
            <SnackMessage
              error={props.errorMessage?.[CARD_BUYER_ENCOUNTER]}
              success={null}
              onClose={() => {
                props.setProp(`errorMessage.${CARD_BUYER_ENCOUNTER}`, null);
              }}
            />
            <div style={{ margin: "0.25rem 0 -0.75rem", textAlign: "center" }}>
              ยืนยันการเปิด Encounter เพื่อซื้อสินค้า
            </div>
          </>
        }
      />

      <ModInfo
        open={openModInfo}
        titleColor={"green"}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        <div style={{ fontWeight: "bold", lineHeight: 1.5, padding: "1rem 0rem" }}>
          เปิด Encounter เพื่อซื้อสินค้าสำเร็จ
        </div>
      </ModInfo>
    </>
  );
};

CardBuyerEncounter.displayName = "CardBuyerEncounter";

export default React.memo(CardBuyerEncounter);
