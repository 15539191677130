import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  TextArea,
  Dropdown,
  Checkbox
} from 'semantic-ui-react'

const CardDentistCertificateUX = (props: any) => {
    return(
      <div>
        <Form>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "1rem 0rem 0rem 1rem"}}>
            <label>
              การวินิจฉัย
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <TextArea
              name="diagnosis"
              onChange={props.onChangeData}
              rows="15"
              style={{marginLeft: "1rem"}}
              value={props.dentistReport?.diagnosis || ""}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <label>
              การรักษาที่ให้
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <TextArea
              name="treatment"
              onChange={props.onChangeData}
              rows="15"
              style={{marginLeft: "1rem"}}
              value={props.dentistReport?.treatment || ""}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <label>
              ความคิดเห็นทันตแพทย์
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <TextArea
              name="detail"
              onChange={props.onChangeData}
              rows="15"
              style={{marginLeft: "1rem"}}
              value={props.dentistReport?.detail || ""}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <label>
              แพทย์ที่แสดงในใบรับรอง
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <Dropdown
              clearable={true}
              name="doctor"
              onChange={props.onChangeData}
              options={props.doctorList || []}
              search={true}
              selection={true}
              style={{marginLeft: "1rem", minWidth: "22rem"}}
              value={props.dentistReport?.doctor}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <Checkbox
              checked={props.dentistReport?.is_pediatric_dentists}
              label="นักศึกษาทันตแพทย์"
              name="is_pediatric_dentists"
              onChange={props.onChangeData}
              style={{marginLeft: "2rem"}}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0rem 0rem 0rem 1rem"}}>
            <Dropdown
              clearable={true}
              disabled={!props.dentistReport?.is_pediatric_dentists}
              name="pediatric_dentists"
              onChange={props.onChangeData}
              options={props.doctorList || []}
              search={true}
              selection={true}
              style={{marginLeft: "1rem", minWidth: "22rem"}}
              value={props.dentistReport?.pediatric_dentists}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", justifyContent: "flex-end"}}>
            <div>
              {props.onCloseSelected}
            </div>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardDentistCertificateUX

export const screenPropsDefault = {}

/* Date Time : Thu Nov 28 2024 14:24:51 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "การวินิจฉัย"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "TextArea",
      "parent": 5,
      "props": {
        "name": {
          "type": "value",
          "value": "diagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "value",
          "value": "15"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.dentistReport?.diagnosis || \"\""
        }
      },
      "seq": 4,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "การรักษาที่ให้"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ความคิดเห็นทันตแพทย์"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "TextArea",
      "parent": 7,
      "props": {
        "name": {
          "type": "value",
          "value": "treatment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "value",
          "value": "15"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.dentistReport?.treatment || \"\""
        }
      },
      "seq": 12,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "TextArea",
      "parent": 9,
      "props": {
        "name": {
          "type": "value",
          "value": "detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "value",
          "value": "15"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.dentistReport?.detail || \"\""
        }
      },
      "seq": 13,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0rem 0rem 0rem 1rem\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ที่แสดงในใบรับรอง"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Dropdown",
      "parent": 15,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "doctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.doctorList || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\", minWidth: \"22rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.dentistReport?.doctor"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Dropdown",
      "parent": 17,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.dentistReport?.is_pediatric_dentists"
        },
        "name": {
          "type": "value",
          "value": "pediatric_dentists"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.doctorList || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\", minWidth: \"22rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.dentistReport?.pediatric_dentists"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onCloseSelected"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Checkbox",
      "parent": 16,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.dentistReport?.is_pediatric_dentists"
        },
        "label": {
          "type": "value",
          "value": "นักศึกษาทันตแพทย์"
        },
        "name": {
          "type": "value",
          "value": "is_pediatric_dentists"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"2rem\"}"
        }
      },
      "seq": 24,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardDentistCertificateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
