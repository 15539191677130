import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input,
  Button,
  Dropdown
} from 'semantic-ui-react'

const CardRegSearchUX = (props: any) => {
    return(
      <div
        style={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden" }}>
        <div
          style={{ display: "flex", flex: "1 0 auto", ...(!props.isHiddenScan && { display: "none" })}}>

          <div
            style={{ flex: "3" }}>

          </div>
          <div
            style={{ display: "flex", marginLeft: "50px", width: "460px"}}>
            สแกนรหัส
          </div>
        </div>
        <div
          style={{ display: "flex", flex: "1 0 auto" }}>

          <Input
            id="txt-searchPatient"
            onChange={props.changeSearchText}
            onKeyDown={(e: any) => (e.key === "Enter") && props.onSearch()}
            placeholder="ค้นหาจาก HN, ชื่อ, นามสกุล"
            style={{flex:2, paddingRight: "0.25rem"}}
            value={props.searchText}>
          </Input>
          <Input
            id="txt-searchCitizen"
            onChange={props.changeCitizenText}
            onKeyDown={(e: any) => (e.key === "Enter") && props.onSearch()}
            placeholder="ค้นหาจาก หมายเลขบัตรประชาชน"
            style={{flex:1,paddingRight: "0.25rem"}}
            value={props.citizenText}>
          </Input>
          <div>
            {props.buttonSearch}
          </div>
          <Button
            color="green"
            id="btn-createPatient"
            onClick={(e:any) => props.onNewPatient()}
            size="small"
            style={{ ...(props.isHidden && { display: "none" }) }}>
            {props.addPatientName}
          </Button>
          <Button
            color="green"
            style={{ ...(props.isHidden && { display: "none" }) }}>
            {props.addPatientGroupName}
          </Button>
          <div
            style={{ ...(props.isHidden && { display: "none" }) }}>
            {props.buttonSmartCard}
          </div>
          <Button
            color="yellow"
            onClick={props.OnEmergency}
            style={{ marginLeft: "50px",  ...(props.isHidden && { display: "none" }) }}>
            {props.addPatientEmergencyName}
          </Button>
          <div
            style={{ display: "flex", marginLeft: "50px", width: "460px", ...(!props.isHiddenScan && { display: "none" })}}>

            <div>

              <Input
                name="searchScan"
                onChange={props.changeSearchType}
                onKeyDown={props.onKeyDownScan}
                placeholder={props.scanPlaceholder}
                value={props.searchData?.searchScan}>
              </Input>
            </div>
            <div>
              {props.buttonSearchScan}
            </div>
            <div>

              <Dropdown
                name="searchType"
                onChange={props.changeSearchType}
                options={props.searchOptions}
                selection={true}
                value={props.searchData?.searchType}>
              </Dropdown>
            </div>
          </div>
          <Button
            color="orange"
            onClick={props.onOpenModTempPatient}
            style={{display: props.showButtonTempPatient?"":"none"}}>
            สร้างผู้ป่วยชั่วคราว
          </Button>
        </div>
        <div
          style={{ display: "flex", flex: "1 1 auto", width: "100%"}}>

          <Table
            NoDataComponent={()=> props.NoDataComponent || null}
            data={props.patientList}
            getTrProps={props.patientRowProps}
            headers="HN,ชื่อ,วันเดือนปีเกิด,เลขประจำตัวประชาชน/พาสปอร์ต,ชื่อเดิม,ชื่อมารดา,ที่อยู่ปัจจุบัน,เบอร์โทรศัพท์"
            id="tb-patientInfoSearch"
            keys="hn,full_name,birthdate,citizen_passport,old_name,mother,address,tel_num"
            showPagination={false}
            style={{width: "100%", height: "calc(90dvh - 75px)", marginTop: "5px"}}
            widths="190,auto,auto,auto,auto,auto,auto,auto">
          </Table>
        </div>
        <div
          style={{ display: "flex", flex: "1 0 auto" , margin: "5px", justifyContent: "flex-start"}}>
          {props.paginationComponent}
        </div>
      </div>
    )
}


export default CardRegSearchUX

export const screenPropsDefault = {}

/* Date Time : Fri Dec 27 2024 15:56:14 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: \"column\", flexGrow: 1, overflow: \"hidden\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flex: \"1 0 auto\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 7,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "()=> props.NoDataComponent || null"
        },
        "data": {
          "type": "code",
          "value": "props.patientList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.patientRowProps"
        },
        "headers": {
          "type": "value",
          "value": "HN,ชื่อ,วันเดือนปีเกิด,เลขประจำตัวประชาชน/พาสปอร์ต,ชื่อเดิม,ชื่อมารดา,ที่อยู่ปัจจุบัน,เบอร์โทรศัพท์"
        },
        "id": {
          "type": "value",
          "value": "tb-patientInfoSearch"
        },
        "keys": {
          "type": "value",
          "value": "hn,full_name,birthdate,citizen_passport,old_name,mother,address,tel_num"
        },
        "minRows": {
          "type": "value",
          "value": ""
        },
        "resizable": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"calc(90dvh - 75px)\", marginTop: \"5px\"}"
        },
        "widths": {
          "type": "value",
          "value": "190,auto,auto,auto,auto,auto,auto,auto"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Input",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "txt-searchPatient"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSearchText"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => (e.key === \"Enter\") && props.onSearch()"
        },
        "placeholder": {
          "type": "value",
          "value": "ค้นหาจาก HN, ชื่อ, นามสกุล"
        },
        "style": {
          "type": "code",
          "value": "{flex:2, paddingRight: \"0.25rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchText"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addPatientName"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "btn-createPatient"
        },
        "onClick": {
          "type": "code",
          "value": "(e:any) => props.onNewPatient()"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isHidden && { display: \"none\" }) }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "div"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flex: \"1 1 auto\", width: \"100%\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.paginationComponent"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flex: \"1 0 auto\" , margin: \"5px\", justifyContent: \"flex-start\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addPatientGroupName"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isHidden && { display: \"none\" }) }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 1,
      "props": {
        "basic": {
          "type": "value",
          "value": ""
        },
        "children": {
          "type": "code",
          "value": "props.addPatientEmergencyName"
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.OnEmergency"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"50px\",  ...(props.isHidden && { display: \"none\" }) }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Input",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "txt-searchCitizen"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCitizenText"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => (e.key === \"Enter\") && props.onSearch()"
        },
        "placeholder": {
          "type": "value",
          "value": "ค้นหาจาก หมายเลขบัตรประชาชน"
        },
        "style": {
          "type": "code",
          "value": "{flex:1,paddingRight: \"0.25rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.citizenText"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSmartCard"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isHidden && { display: \"none\" }) }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginLeft: \"50px\", width: \"460px\", ...(!props.isHiddenScan && { display: \"none\" })}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Input",
      "parent": 16,
      "props": {
        "name": {
          "type": "value",
          "value": "searchScan"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSearchType"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyDownScan"
        },
        "placeholder": {
          "type": "code",
          "value": "props.scanPlaceholder"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.searchData?.searchScan"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Dropdown",
      "parent": 17,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "searchType"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSearchType"
        },
        "options": {
          "type": "code",
          "value": "props.searchOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.searchData?.searchType"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearchScan"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flex: \"1 0 auto\", ...(!props.isHiddenScan && { display: \"none\" })}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: \"3\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "สแกนรหัส"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginLeft: \"50px\", width: \"460px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "สร้างผู้ป่วยชั่วคราว"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModTempPatient"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showButtonTempPatient?\"\":\"none\"}"
        }
      },
      "seq": 25,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardRegSearchUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
