import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Dropdown,
  Radio,
  Input
} from 'semantic-ui-react'

const CardSkinPreparationUX = (props: any) => {
    return(
      <div
        id="CardSkinPreparation"
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            Skin Preparation
          </label>
          <Label
            color={props.statusColor}
            style={{display: props.config?.hideStatus ? "none" : "", float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <div
          style={{display: "flex"}}>
          
          <div
            className="ui form"
            style={{ marginTop: "13px" }}>
            
            <FormGroup
              inline={true}>
              <FormField
                className="required"
                inline={true}>
                <label
                  style={{minWidth: "135px", maxWidth: "135px"}}>
                  Surgical Skin scrub
                </label>
              </FormField>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.isSurgicalSkinScrub || false}
                  label={props.isSurgicalSkinScrub ? "Yes" : "No"}
                  name="is_surgical_skin_scrub"
                  onChange={props.onChangeData}
                  style={{marginRight: "1rem"}}
                  toggle={true}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                style={!props.isSurgicalSkinScrub ? {display: "none"} : {}}>
                <label>
                  Scrub skin area of
                </label>
              </FormField>
            </FormGroup>
          </div>
          <div
            className="ui form"
            style={{flex: 1}}>
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{flex: 1}}>
                <div>
                  {props.AddSkinScrub}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={!props.isSurgicalSkinScrub ? {display: "none"} : {display: "flex"}}>
          
          <div
            className="ui form"
            style={{ marginTop: "10px", width: "375px" }}>
            
            <FormGroup
              inline={true}
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <FormField
                inline={true}>
                <label>
                  Paint Area of
                </label>
              </FormField>
            </FormGroup>
          </div>
          <div
            className="ui form"
            style={{flex: 1}}>
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{flex: 1}}>
                <div>
                  {props.AddPaintArea}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <Form>
          <FormGroup
            inline={true}
            style={{display: "none"}}>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <Checkbox
                checked={props.isSurgicalSite || false}
                label="Surgical site"
                name="is_surgical_site"
                onChange={props.onChangeData}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <div>
                Solution
              </div>
            </FormField>
            <FormField
              inline={true}
              width={12}>
              <Dropdown
                allowAdditions={true}
                clearable={true}
                fluid={true}
                multiple={true}
                name="vagina"
                onAddItem={props.handleAddVaginaOptions}
                onChange={props.onChangeData}
                options={props.orVaginaOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.vagina || []}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "135px", maxWidth: "135px"}}>
                Wound Classification
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.woundClassification === "clean"}
                label="Clean"
                onChange={(e: any)=>props.onChangeData?.(e,{value: "clean", name: "wound_classification"})}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.woundClassification === "clean_contaminated"}
                label="Clean-contaminated"
                onChange={(e: any)=>props.onChangeData?.(e,{value: "clean_contaminated", name: "wound_classification"})}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.woundClassification === "contaminated"}
                label="Contaminated"
                onChange={(e: any)=>props.onChangeData?.(e,{value: "contaminated", name: "wound_classification"})}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.woundClassification === "infected"}
                label="Infected"
                onChange={(e: any)=>props.onChangeData?.(e,{value: "infected", name: "wound_classification"})}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.woundClassification === "scope"}
                label="Scope"
                onChange={(e: any)=>props.onChangeData?.(e,{value: "scope", name: "wound_classification"})}>
              </Radio>
            </FormField>
          </FormGroup>
        </Form>
        <div
          className="ui form"
          style={{ backgroundColor: "#FDF8DD", padding: "20px 10px", margin: "15px 0px 15px" }}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={7}>
              <label
                style={{width: "100%", maxWidth: "max-content"}}>
                Nursing Diagnosis
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.nurseDiagnosis || ""}
              </label>
            </FormField>
            <FormField
              inline={true}
              width={9}>
              <label
                style={{width: "100%", maxWidth: "max-content"}}>
                Goal
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.goal || ""}
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{margin:0}}>
            <FormField
              inline={true}
              width={7}>
              <label
                style={{width: "100%", maxWidth: "max-content"}}>
                Plan and implementation
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.planAndImplementation || ""}
              </label>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{width: "100%", maxWidth: "max-content"}}>
                Evaluation
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.evaluation || ""}
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Checkbox
                checked={props.evaluationCheck || false}
                label={props.evaluationCheck ? "Yes" : "No"}
                name="evaluation_check"
                onChange={props.onChangeData}
                style={{minWidth: "max-content"}}
                toggle={true}>
              </Checkbox>
              <Input
                fluid={true}
                name="evaluation_detail"
                onChange={props.onChangeData}
                value={props.evaluationDetail || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{ float: "right" , display: "flex"}}>
            
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonSave}
            </div>
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonConfirm}
            </div>
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonUnConfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardSkinPreparationUX

export const screenPropsDefault = {}

/* Date Time : Tue Dec 03 2024 11:50:49 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSkinPreparation"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Skin Preparation"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideStatus ? \"none\" : \"\", float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" , display: \"flex\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonUnConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "FormGroup",
      "parent": 179,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 227,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 181,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormField",
      "parent": 181,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "FormField",
      "parent": 181,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "FormField",
      "parent": 181,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "FormField",
      "parent": 181,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "FormField",
      "parent": 181,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "Checkbox",
      "parent": 184,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isSurgicalSite || false"
        },
        "label": {
          "type": "value",
          "value": "Surgical site"
        },
        "name": {
          "type": "value",
          "value": "is_surgical_site"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Dropdown",
      "parent": 186,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "vagina"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddVaginaOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.orVaginaOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.vagina || []"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Radio",
      "parent": 192,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.woundClassification === \"scope\""
        },
        "label": {
          "type": "value",
          "value": "Scope"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any)=>props.onChangeData?.(e,{value: \"scope\", name: \"wound_classification\"})"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Radio",
      "parent": 191,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.woundClassification === \"infected\""
        },
        "label": {
          "type": "value",
          "value": "Infected"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any)=>props.onChangeData?.(e,{value: \"infected\", name: \"wound_classification\"})"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "Radio",
      "parent": 190,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.woundClassification === \"contaminated\""
        },
        "label": {
          "type": "value",
          "value": "Contaminated"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any)=>props.onChangeData?.(e,{value: \"contaminated\", name: \"wound_classification\"})"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "Radio",
      "parent": 189,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.woundClassification === \"clean_contaminated\""
        },
        "label": {
          "type": "value",
          "value": "Clean-contaminated"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any)=>props.onChangeData?.(e,{value: \"clean_contaminated\", name: \"wound_classification\"})"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "Radio",
      "parent": 188,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.woundClassification === \"clean\""
        },
        "label": {
          "type": "value",
          "value": "Clean"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any)=>props.onChangeData?.(e,{value: \"clean\", name: \"wound_classification\"})"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "label",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": "Wound Classification"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"135px\", maxWidth: \"135px\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#FDF8DD\", padding: \"20px 10px\", margin: \"15px 0px 15px\" }"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "FormGroup",
      "parent": 204,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "FormGroup",
      "parent": 204,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin:0}"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "FormField",
      "parent": 205,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "FormField",
      "parent": 205,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "FormField",
      "parent": 206,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "FormField",
      "parent": 206,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "label",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursing Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "label",
      "parent": 207,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseDiagnosis || \"\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "label",
      "parent": 208,
      "props": {
        "children": {
          "type": "value",
          "value": "Goal"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "label",
      "parent": 208,
      "props": {
        "children": {
          "type": "code",
          "value": "props.goal || \"\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": null,
      "id": 216,
      "name": "label",
      "parent": 209,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan and implementation"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": null,
      "id": 217,
      "name": "label",
      "parent": 209,
      "props": {
        "children": {
          "type": "code",
          "value": "props.planAndImplementation || \"\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": null,
      "id": 218,
      "name": "label",
      "parent": 210,
      "props": {
        "children": {
          "type": "value",
          "value": "Evaluation"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "label",
      "parent": 210,
      "props": {
        "children": {
          "type": "code",
          "value": "props.evaluation || \"\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "FormField",
      "parent": 206,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "Checkbox",
      "parent": 223,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.evaluationCheck || false"
        },
        "label": {
          "type": "code",
          "value": "props.evaluationCheck ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "evaluation_check"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "Input",
      "parent": 223,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "evaluation_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.evaluationDetail || \"\""
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "Solution"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormGroup",
      "parent": 179,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 230,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormGroup",
      "parent": 244,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "FormGroup",
      "parent": 243,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormField",
      "parent": 234,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormField",
      "parent": 234,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormField",
      "parent": 234,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "!props.isSurgicalSkinScrub ? {display: \"none\"} : {}"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": null,
      "id": 238,
      "name": "label",
      "parent": 235,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical Skin scrub"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"135px\", maxWidth: \"135px\"}"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": null,
      "id": 239,
      "name": "label",
      "parent": 237,
      "props": {
        "children": {
          "type": "value",
          "value": "Scrub skin area of"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "Checkbox",
      "parent": 236,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isSurgicalSkinScrub || false"
        },
        "label": {
          "type": "code",
          "value": "props.isSurgicalSkinScrub ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "is_surgical_skin_scrub"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "FormField",
      "parent": 233,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 242,
      "name": "div",
      "parent": 241,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AddSkinScrub"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": null,
      "id": 243,
      "name": "div",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"13px\" }"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "div",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": null,
      "id": 245,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "!props.isSurgicalSkinScrub ? {display: \"none\"} : {display: \"flex\"}"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "div",
      "parent": 245,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\", width: \"375px\" }"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "div",
      "parent": 245,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 248,
      "name": "FormGroup",
      "parent": 246,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "FormGroup",
      "parent": 247,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "FormField",
      "parent": 249,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "div",
      "parent": 250,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AddPaintArea"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "FormField",
      "parent": 248,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "label",
      "parent": 252,
      "props": {
        "children": {
          "type": "value",
          "value": "Paint Area of"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 253,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardSkinPreparationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
