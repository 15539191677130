import WasmController from "react-lib/frameworks/WasmController";

//APIS
import DrugReturnRequestList from "issara-sdk/apis/DrugReturnRequestList_apps_TPD";
import DrugReturnRequestDetail from "issara-sdk/apis/DrugReturnRequestDetail_apps_TPD";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { SetProperty } from "../../common/CommonInterface";

// Utils
import moment from "moment";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";

export type State = {
  DrugReturnRequestHistorySequence?: {
    sequenceIndex?: string | null;
    selectedDivisionReturn?: any | null;
    selectedDivisionReceive?: any | null;
    selectedStartDate?: string | null;
    selectedEndDate?: string | null;
    selectedStatus?: string | null;
    selectedPatient?: string | null;
    checkedStatus?: boolean | null;
    checkedPatient?: boolean | null;
    checkedDivisionReturn?: boolean | null;
    checkedDivisionReceive?: boolean | null;
    checkedFilterDate?: boolean | null;
    selectedBox?: any;
  } | null;
  DrugReturnRequestHistoryList?: any;
};

type Picked = Partial<
  Pick<
    MainState,
    "successMessage" | "errorMessage" | "buttonLoadCheck" | "loadingStatus"
  >
>;

export type SetProp = SetProperty<State & Picked>;

export const StateInitial: State = {
  DrugReturnRequestHistorySequence: null,
  DrugReturnRequestHistoryList: [],
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.DrugReturnRequestHistorySequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["drugStatus", {}],
      ],
    },
  });

  state = controller.getState();

  const seq = state.DrugReturnRequestHistorySequence;

  const defaultDate = dateToStringWithoutTimeBE(moment());

  const [response] = await DrugReturnRequestList.list({
    params: {
      patient: seq?.checkedPatient ? seq?.selectedPatient : null,
      status: seq?.checkedStatus ? seq?.selectedStatus : null,
      from_date: defaultDate,
      to_date: defaultDate,
      requester: seq?.checkedDivisionReturn
        ? seq?.selectedDivisionReturn
        : null,
      receiver: seq?.checkedDivisionReceive
        ? seq?.selectedDivisionReceive
        : null,
      offset: 0,
      limit: 40,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  controller.setState({
    DrugReturnRequestHistorySequence: {
      ...state.DrugReturnRequestHistorySequence,
      sequenceIndex: "SearchDrugReturnRequestHistory",
      // Init data
      checkedFilterDate: true,
      selectedStartDate: defaultDate,
      selectedEndDate: defaultDate,
    },
    DrugReturnRequestHistoryList: {
      ...state.DrugReturnRequestHistoryList,
      ...response,
    },
  });
};

export const SearchDrugReturnRequestHistory: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  if (params?.action === "search") {
    HandleSearch(controller, params);
  } else if (params?.action === "clear") {
    const defaultDate = dateToStringWithoutTimeBE(moment());

    controller.setState({
      DrugReturnRequestHistorySequence: {
        ...state.DrugReturnRequestHistorySequence,
        sequenceIndex: "SearchDrugReturnRequestHistory",
        checkedDivisionReceive: false,
        checkedDivisionReturn: false,
        checkedFilterDate: false,
        checkedPatient: false,
        checkedStatus: false,
        selectedDivisionReceive: "แสดงทุกหน่วยงาน",
        selectedDivisionReturn: "แสดงทุกหน่วยงาน",
        selectedEndDate: defaultDate,
        selectedPatient: null,
        selectedStartDate: defaultDate,
        selectedStatus: "แสดงทุกสถานะ",
      },
      DrugReturnRequestHistoryList: { ...state.DrugReturnRequestHistoryList },
    });
  } else if (params.action === "SET_SELECTED") {
    const state = controller.getState();

    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.card]: "" },
      DrugReturnRequestHistorySequence: {
        ...state.DrugReturnRequestHistorySequence,
        selectedBox: params.selectedBox,
      },
    });
  } else if (
    [
      "APPROVE",
      "REJECT",
      "DELIVER",
      "RECEIVE",
      "EDIT",
      "UNAPPROVE",
      "UNDELIVER",
      "UNRECEIVE",
    ].includes(params.action)
  ) {
    HandleUpdateStatus(controller, params);
  } else if (params?.action === "SELECTBOX_EDIT_VALUE") {
    const state = controller.getState();

    let selectedBoxItem = [
      ...(state.DrugReturnRequestHistorySequence?.selectedBox?.items || []),
    ].map((item: any, idx: number) =>
      item.id === params?.selectedItemId ? { ...item, [params.name]: params.value } : { ...item }
    );

    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.card]: "" },
      DrugReturnRequestHistorySequence: {
        ...state.DrugReturnRequestHistorySequence,
        selectedBox: {
          ...state.DrugReturnRequestHistorySequence?.selectedBox,
          items: [...selectedBoxItem],
        },
      },
    });
  }
};

const HandleSearch: Handler = async (controller, params) => {
  const state = controller.getState();

  const seq = state.DrugReturnRequestHistorySequence;

  const [response] = await DrugReturnRequestList.list({
    params: {
      patient: seq?.checkedPatient ? seq?.selectedPatient : null,
      status: seq?.checkedStatus ? seq?.selectedStatus : null,
      from_date: seq?.checkedFilterDate ? seq?.selectedStartDate : null,
      to_date: seq?.checkedFilterDate ? seq?.selectedEndDate : null,
      requester: seq?.checkedDivisionReturn
        ? seq?.selectedDivisionReturn
        : null,
      receiver: seq?.checkedDivisionReceive
        ? seq?.selectedDivisionReceive
        : null,
      offset: 0,
      limit: 40,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  // let selectedBox = null;
  // if (params.selectedBoxId) {
  //   selectedBox = response?.items?.find(
  //     (item: any) => item?.id === params?.selectedBoxId
  //   );
  // }

  controller.setState({
    DrugReturnRequestHistorySequence: {
      ...state.DrugReturnRequestHistorySequence,
      ...(params.selectedBox ? { selectedBox: params.selectedBox } : {}),
      sequenceIndex: "SearchDrugReturnRequestHistory",
    },
    DrugReturnRequestHistoryList: response,
  });
};

const HandleUpdateStatus: Handler = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const [response, error] = await DrugReturnRequestDetail.update({
    pk: state.DrugReturnRequestHistorySequence?.selectedBox?.id,
    data: {
      action: params?.action,
      items: state.DrugReturnRequestHistorySequence?.selectedBox?.items,
    },
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  if (response) {
    controller.setState({
      successMessage: {
        ...state.successMessage,
        [params?.card]: response,
      },
      errorMessage: { ...state.errorMessage, [params?.card]: "" },
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
    });

    HandleSearch(controller, {
      ...params,
      selectedBox: response,
    });

    params.onSuccess?.();
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.card]: error },
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "ERROR" },
    });
  }
};
