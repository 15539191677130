import React, { SyntheticEvent, useEffect, useState } from "react";

import {
  Button,
  Form,
  FormField,
  FormGroup,
  Input,
  InputProps,
  Modal,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

import SnackMessage from "react-lib/apps/common/SnackMessage";

// Types
type ModTempPatientProps = {
  setProp: (key: string, value: any, callback?: () => void) => any;
  runSequence: (e: any) => void;
  open: boolean;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // callback
  onClose: () => void;
  onSelectPatient: (data: Record<string, any>) => void;
};

const BUTTON_ACTIONS = {
  save: "SAVE",
};

const MOD_TEMP_PATIENT = "ModTempPatient";

const ACTION_SAVE = `${MOD_TEMP_PATIENT}_${BUTTON_ACTIONS.save}`;

const ModTempPatient = (props: ModTempPatientProps) => {
  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (props.open) {
      setName("");
    }
  }, [props.open]);

  const handleChangeName = (e: SyntheticEvent, data: InputProps) => {
    setName(data.value);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "SetPatient",
      action: "save_temp_patient",
      btnAction: ACTION_SAVE,
      card: MOD_TEMP_PATIENT,
      name,
      onSuccess: (data: Record<string, any>) => {
        props.onSelectPatient(data);

        props.onClose();
      },
    });
  };

  return (
    <>
      <SnackMessage
        error={props.errorMessage?.[MOD_TEMP_PATIENT]}
        success={null}
        onClose={() => {
          props.setProp(`errorMessage.${MOD_TEMP_PATIENT}`, null);
        }}
      />

      <Modal open={props.open} size="small" closeOnDimmerClick onClose={props.onClose}>
        <ModalHeader style={{ fontSize: "1.15rem" }}>ลงทะเบียนผู้ซื้อสินค้า</ModalHeader>
        <ModalContent>
          <Form>
            <FormGroup style={{ margin: "1rem 0 2rem" }} inline>
              <FormField width={16} inline>
                <label style={{ minWidth: "max-content" }}>ชื่อเต็ม / หน่วยงาน</label>
                <Input value={name} onChange={handleChangeName} />
              </FormField>
            </FormGroup>
            <FormGroup style={{ margin: 0 }}>
              <FormField style={{ flex: 1 }} />
              <FormField>
                <ButtonLoadCheck
                  setProp={props.setProp}
                  color={"green"}
                  paramKey={ACTION_SAVE}
                  size="medium"
                  title={"บันทึก"}
                  buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SAVE]}
                  onClick={handleSave}
                />
                <Button color="red" onClick={props.onClose}>
                  ปิดหน้าต่าง
                </Button>
              </FormField>
            </FormGroup>
          </Form>
        </ModalContent>
      </Modal>
    </>
  );
};

ModTempPatient.displayName = "ModTempPatient";

export default React.memo(ModTempPatient);
