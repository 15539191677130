import WasmController from "react-lib/frameworks/WasmController";

import * as SettingMediaDocument from "./sequence/SettingMediaDocument";
import * as SettingTRTOptions from "./sequence/SettingTRTOptions";

export type State = {} & SettingMediaDocument.State & SettingTRTOptions.State;

export const StateInitial: State = {};

export type Event = SettingMediaDocument.Event | SettingTRTOptions.Event;

export type Data = {
  division?: number;
} & SettingMediaDocument.Data &
  SettingTRTOptions.Data;

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;
