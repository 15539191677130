import CONFIG from "config/config";

const FormReceiptCoverageDocument = (props: any) => {
  console.log("FormReceiptCoverageDocument", props);

  return {
    pageSize: "A5",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 12,
    },
    pageMargins: [10, 235, 10, 60],
    styles: {
      fieldHeader: {
        fontSize: 18,
        bold: true,
      },
      tableHeader: {
        fontSize: 10,
        bold: true,
      },
      tableValue: {
        fontSize: 10,
      },
      fieldKey: {
        bold: true,
        fontSize: 11,
      },
      footerValue: {
        fontSize: 11,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 14,
      },
      miniFieldValue: {
        fontSize: 14,
      },
    },
    header: (currentPage: number, pageCount: number) => {
      return {
        margin: [15, 15, 15, 0],
        stack: [
          {
            columns: [
              !CONFIG.HIDE_COMPANY_LOGO_PRINT ? {
                image: "logochula",
                width: 80,
              } : null,
              {
                width: "*",
                marginTop: 0,
                stack: [
                  {
                    text: "เอกสารแสดงค่าใช้จ่ายในการรักษาพยาบาล",
                    alignment: "center",
                    style: "fieldHeader",
                    lineHeight: 0.95,
                  },
                  !CONFIG.HIDE_COMPANY_LOGO_PRINT ? {
                    text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                    alignment: "center",
                    fontSize: 16,
                    bold: false,
                  } : null,
                  {
                    margin: [2.5, 5, 0, 0],
                    columns: [
                      {
                        width: "auto",
                        text: "ชื่อ-สกุล ",
                        style: "fieldKey",
                      },
                      {
                        width: "auto",
                        stack: [
                          {
                            text: props.patient_name,
                            relativePosition: { x: 7.5, y: -3 },
                            style: "fieldKey",
                          },
                          {
                            margin: [5, 0, 2.5, 0],
                            text: Array(95).fill("."),
                            style: "fieldKey",
                          },
                        ],
                      },
                      {
                        width: "auto",
                        text: "HN ",
                        style: "fieldKey",
                      },
                      {
                        width: "auto",
                        stack: [
                          {
                            text: props.hn,
                            relativePosition: { x: 15, y: -2.5 },
                            style: "fieldKey",
                          },
                          {
                            marginLeft: 1,
                            text: Array(36).fill("."),
                            style: "fieldKey",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    margin: [2.5, 5, 0, 0],
                    columns: [
                      {
                        width: "auto",
                        text: "วันที่รับบริการ ",
                        style: "fieldKey",
                      },
                      {
                        width: "auto",
                        stack: [
                          {
                            text: props.service_date,
                            relativePosition: { x: 12.5, y: -2.5 },
                            style: "fieldKey",
                          },
                          {
                            margin: [5, 0, 2.5, 0],
                            text: Array(30).fill("."),
                            style: "fieldKey",
                          },
                        ],
                      },
                      {
                        width: "auto",
                        text: "หน่วยบริการ ",
                        style: "fieldKey",
                      },
                      {
                        width: "auto",
                        stack: [
                          {
                            text: props.division_name,
                            relativePosition: { x: 7.5, y: -2.5 },
                            style: "fieldKey",
                          },
                          {
                            marginLeft: 1,
                            text: Array(75).fill("."),
                            style: "fieldKey",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            margin: [10, 7.5, 0, 0],
            columns: [
              {
                width: "auto",
                text: "บัตรประจำตัวประชาชน ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.citizen_passport,
                    relativePosition: { x: 10, y: -2.5 },
                    style: "fieldKey",
                  },
                  {
                    margin: [5, 0, 2.5, 0],
                    text: Array(35).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
              {
                width: "auto",
                text: "สิทธิการรักษา ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.coverage_name,
                    relativePosition: { x: 2.5, y: -5 },
                    style: "fieldKey",
                  },
                  {
                    marginLeft: 1,
                    text: Array(97).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
            ],
          },
          {
            margin: [10, -10, 0, 0],
            columns: [
              {
                width: "auto",
                text: "วันเกิด ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.birthdate,
                    relativePosition: { x: 30, y: -5 },
                    style: "fieldKey",
                  },
                  {
                    margin: [5, 0, 2.5, 0],
                    text: Array(48).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
              {
                width: "auto",
                text: "สถานะภาพ ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.marriage_status,
                    relativePosition: { x: 10, y: -5 },
                    style: "fieldKey",
                  },
                  {
                    margin: [1, 0, 5, 0],
                    text: Array(30).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
              {
                width: "auto",
                text: "สัญชาติ ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.nationality,
                    relativePosition: { x: 10, y: -5 },
                    style: "fieldKey",
                  },
                  {
                    marginLeft: 1,
                    text: Array(69).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
            ],
          },
          {
            margin: [10, -10, 0, 0],
            columns: [
              {
                width: "auto",
                text: "อาชีพ ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.career,
                    relativePosition: { x: 5, y: -5 },
                    style: "fieldKey",
                  },
                  {
                    margin: [5, 0, 2.5, 0],
                    text: Array(52).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
              {
                width: "auto",
                text: "รหัสทันตแพทย์ที่รักษา ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.format_doctor_name, //* issue #70196
                    relativePosition: { x: 5, y: -5 },
                    style: "fieldKey",
                  },
                  {
                    marginLeft: 1,
                    text: Array(94).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
            ],
          },
          {
            margin: [10, -10, 0, 0],
            columns: [
              {
                width: "auto",
                text: props.approval_label, // issue 70291
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.approval_code.length > 0 ? props.approval_code : props.params?.approval_code, // issue 67700
                    relativePosition: { x: 7.5, y: -2.5 },
                    style: "fieldKey",
                  },
                  {
                    margin: [5, 0, 2.5, 0],
                    text: Array(40).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
              {
                width: "auto",
                text: "รหัสหน่วยงาน ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.payer_name || "",
                    relativePosition: { x: 15, y: -2.5 },
                    style: "fieldKey",
                  },
                  {
                    table: {
                      widths: [100],
                      body: [
                        [
                          {
                            text: "",
                            border: [false, true, false, false],
                            borderColor: [
                              "transparent",
                              "grey",
                              "transparent",
                              "transparent",
                            ],
                          },
                        ],
                      ],
                    },
                    relativePosition: { x: 5, y: 9 },
                    style: "fieldKey",
                  },
                  {
                    marginLeft: 1,
                    text: Array(102).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
            ],
          },
          {
            margin: [10, 4, 0, 0],
            columns: [
              {
                width: "auto",
                text: "บัตรประจำตัวประชาชน(ผู้มีสิทธิ) ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.citizen_passport_eligible,
                    relativePosition: { x: 7.5, y: -2.5 },
                    style: "fieldKey",
                  },
                  {
                    margin: [5, 0, 2.5, 0],
                    text: Array(45).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
              {
                width: "auto",
                text: "ชื่อ-สกุล(ผู้มีสิทธิ) ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.patient_name_eligible,
                    relativePosition: { x: 2.5, y: -2.5 },
                    style: "fieldKey",
                  },
                  {
                    marginLeft: 1,
                    text: Array(67).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
            ],
          },
          {
            margin: [10, -10, 0, 0],
            columns: [
              {
                width: "auto",
                text: "การวินิจฉัย(CD10) ",
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.icd10,
                    relativePosition: { x: 15, y: -5 },
                    style: "fieldKey",
                  },
                  {
                    margin: [5, 0, 2.5, 0],
                    text: Array(157).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
            ],
          },
          {
            margin: [10, 5, 0, 0],
            columns: [
              {
                width: "auto",
                text: ["การผ่าตัด", "/", "หัตถการ(ICD9CM)"],
                style: "fieldKey",
              },
              {
                width: "auto",
                stack: [
                  {
                    text: props.icd9cm,
                    relativePosition: { x: 10, y: -5 },
                    style: "fieldKey",
                  },
                  {
                    margin: [5, 0, 2.5, 0],
                    text: Array(140).fill("."),
                    style: "fieldKey",
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    content: [
      {
        margin: [0, -2.5, 0, 0], // original [12.5, -2.5, 12.5, 0]
        table: {
          headerRows: 1,
          dontBreakRows: true,
          widths: ["40%", "auto", "auto", "auto", "auto", "auto", "auto"], // issue 67740 original ["49.0%", "7.0%", "8.5%", "9%", "8.5%", "9%", "9%"]
          body: [
            [
              {
                marginTop: 7,
                text: "รายการ",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "จำนวน (หน่วย)",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ราคา/ หน่วย",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "จำนวนเงิน เบิกได้",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "จำนวนเงิน เบิกไม่ได้",
                style: "tableHeader",
                alignment: "center",
              },
              {
                marginTop: 7,
                text: "สวัสดิการ",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "จำนวน เงินสุทธิ",
                style: "tableHeader",
                alignment: "center",
              },
            ],
            ...props.fields.map((item: any, index: number) => {
              const border = item.is_header
                ? [true, true, true, false]
                : props.fields.length - 1 === index
                ? [true, false, true, true]
                : [true, false, true, false];
              const style = item.is_header ? "tableHeader" : "tableValue";

              return [
                {
                  text: item.name,
                  style,
                  border,
                  marginLeft: item.is_header ? 0 : 4,
                },
                {
                  text: item.quantity,
                  style,
                  alignment: "center",
                  border,
                },
                {
                  text: item.unit_price,
                  style,
                  alignment: "right",
                  border,
                },
                {
                  text: item.send_claim_price,
                  style,
                  alignment: "right",
                  border,
                },
                {
                  text: item.non_claimable_price,
                  style,
                  alignment: "right",
                  border,
                },
                {
                  text: item.employee_send_claim_price,
                  style,
                  alignment: "right",
                  border,
                },
                {
                  text: item.net_price,
                  style,
                  alignment: "right",
                  border,
                },
              ];
            }),
            ...[
              [
                {
                  text: "รวมทั้งสิ้น",
                  colSpan: 3,
                  style: "tableHeader",
                  alignment: "right",
                  margin: [0, 5, 25, 0],
                },
                {},
                {},
                {
                  text: props.total_send_claim_price,
                  style: "tableValue",
                  alignment: "right",
                  margin: [0, 5, 0, 0],
                },
                {
                  text: props.total_non_claimable_price,
                  style: "tableValue",
                  alignment: "right",
                  margin: [0, 5, 0, 0],
                },
                {
                  text: props.total_employee_send_claim_price,
                  style: "tableValue",
                  alignment: "right",
                  margin: [0, 5, 0, 0],
                },
                {
                  text: props.total_net_price,
                  style: "tableValue",
                  alignment: "right",
                  margin: [0, 5, 0, 0],
                },
              ],
            ],
          ],
        },
        layout: {
          paddingLeft: function (i: number, node: any) {
            return i === 1 ? 1 : 4;
          },
          paddingRight: function (i: number, node: any) {
            return i === 1 ? 1 : 4;
          },
          paddingBottom: function (i: number, node: any) {
            // ตำแหน่ง hLine ล่าสุด
            const target = node.positions[node.positions.length - 1];
            // // หน้าแรก
            // const isFirst = target?.pageNumber === 1;
            // // last
            console.log("layout", target, i);
            // return target?.top >= FIRST_PAGE_TOP && isFirst
            //   ? 40
            //   : target?.top >= ANOTHER_PAGE_TOP
            //   ? BOTTOM - target?.top
            //   : 2.0;
            return 4;
          },
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [15, 0, 15, 50],
        style: "footerValue",
        columns: [
          {
            stack: [
              { text: "ลงชื่อ ..................................................................." },
              {
                stack: [
                  {
                    text: props.patient_name,
                    margin: [0, 0, 0, -12]
                  },
                  { text: "(...........................................................................)" }
                ],
                width: "auto"
              },
              { text: "ผู้ป่วย" }
            ],
            alignment: "center"
          },
          {
            stack: [
              { text: "ลงชื่อ ..................................................................." },
              {
                stack: [
                  {
                    text: props.finance_staff_name,
                    margin: [0, 0, 0, -12]
                  },
                  { text: "(...........................................................................)" }
                ],
                width: "auto"
              },
              { text: "เจ้าหน้าที่เก็บเงิน" }
            ],
            alignment: "center"
          }
        ],
      };
    },
    images: {
      logochula: origin + "/static/images/logochula-grey.png",
    },
  };
};

export default FormReceiptCoverageDocument;
