import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'

const CardOPDOrderSummaryUX = (props: any) => {
    return(
      <div
        style={{height: "100%", width: "100%", padding: "10px", maxHeight: "calc(100dvh - 60px)", overflowY: "auto"}}>
        <div
          style={{fontSize: "20px", fontWeight: "bold",padding: "10px"}}>
          รายการคำสั่งแพทย์
        </div>
        <div
          style={{backgroundColor: "#f5f5f5", padding: "10px"}}>
          
          <Button
            color="blue"
            onClick={props.onCheckedAll}>
            สรุปรายการทั้งหมด
          </Button>
          <div
            style={{paddingTop: "10px", width: "100%"}}>
            
            <Form>
              <FormGroup>
                <FormField
                  inline={true}
                  width="2">
                  <Checkbox
                    checked={props.isOrder}
                    label="สถานะคำสั่ง"
                    onChange={props.onChangeChecked("isOrder")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                </FormField>
                <FormField
                  className="four width"
                  width="4">
                  <Dropdown
                    onChange={props.onOrderTypeChange}
                    options={props.orderTypeOptions}
                    selection={true}
                    value={props.orderType}>
                  </Dropdown>
                </FormField>
                <FormField
                  width="1">
                </FormField>
                <FormField
                  width="8">
                  <Checkbox
                    checked={props.isLab}
                    label="Lab"
                    onChange={props.onChangeChecked("isLab")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                  <Checkbox
                    checked={props.isImaging}
                    label="Imaging"
                    onChange={props.onChangeChecked("isImaging")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                  <Checkbox
                    checked={props.isTreatment}
                    label="Treatment"
                    onChange={props.onChangeChecked("isTreatment")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                  <Checkbox
                    checked={props.isOthers}
                    label="อื่นๆ"
                    onChange={props.onChangeChecked("isOthers")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                  <Checkbox
                    checked={props.isOutPerformDiv}
                    label="คำสั่งนอกแผนก"
                    onChange={props.onChangeChecked("isOutPerformDiv")}
                    style={{padding: "10px"}}>
                  </Checkbox>
                </FormField>
                <FormField
                  width="3">
                  <Button
                    color="blue"
                    loading={props.loadingSummary}
                    onClick={props.onSearch}>
                    ค้นหา
                  </Button>
                </FormField>
              </FormGroup>
            </Form>
          </div>
        </div>
        <div
          style={{paddingTop: "10px"}}>
          
          <Table
            className="-customTablePadding"
            columns={props.orderSummaryColumns}
            data={props.orderSummaryOPD}
            getTrProps={props.onSelectedOrder}
            minRows="12"
            showPagination={false}
            style={{height: "600px"}}>
          </Table>
        </div>
        <div
          style={{ display: "flex", justifyContent: " flex-end", marginTop : "10px" }}>
          
          <Button
            color="green"
            disabled={props.disabledNursePerform}
            onClick={props.buttonNursePerform}
            size="small"
            style={{ width: "150px" }}>
            รับคำสั่ง
          </Button>
        </div>
      </div>
    )
}


export default CardOPDOrderSummaryUX

export const screenPropsDefault = {}

/* Date Time : Wed Jan 08 2025 10:08:02 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"100%\", width: \"100%\", padding: \"10px\", maxHeight: \"calc(100dvh - 60px)\", overflowY: \"auto\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการคำสั่งแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"20px\", fontWeight: \"bold\",padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#f5f5f5\", padding: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"10px\", width: \"100%\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 6,
      "name": "Table",
      "parent": 3,
      "props": {
        "className": {
          "type": "value",
          "value": "-customTablePadding"
        },
        "columns": {
          "type": "code",
          "value": "props.orderSummaryColumns"
        },
        "data": {
          "type": "code",
          "value": "props.orderSummaryOPD"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onSelectedOrder"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "minRows": {
          "type": "value",
          "value": "12"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"600px\"}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "สรุปรายการทั้งหมด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCheckedAll"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Form",
      "parent": 5,
      "props": {
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormGroup",
      "parent": 36,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 37,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Checkbox",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOrder"
        },
        "label": {
          "type": "value",
          "value": "สถานะคำสั่ง"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isOrder\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 37,
      "props": {
        "className": {
          "type": "value",
          "value": "four width"
        },
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Dropdown",
      "parent": 40,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onOrderTypeChange"
        },
        "options": {
          "type": "code",
          "value": "props.orderTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.orderType"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 37,
      "props": {
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 37,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isLab"
        },
        "label": {
          "type": "value",
          "value": "Lab"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isLab\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isImaging"
        },
        "label": {
          "type": "value",
          "value": "Imaging"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isImaging\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isTreatment"
        },
        "label": {
          "type": "value",
          "value": "Treatment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isTreatment\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOthers"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isOthers\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOutPerformDiv"
        },
        "label": {
          "type": "value",
          "value": "คำสั่งนอกแผนก"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked(\"isOutPerformDiv\")"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 37,
      "props": {
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Button",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingSummary"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \" flex-end\", marginTop : \"10px\" }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Button",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "รับคำสั่ง"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledNursePerform"
        },
        "onClick": {
          "type": "code",
          "value": "props.buttonNursePerform"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"150px\" }"
        }
      },
      "seq": 53,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardOPDOrderSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
