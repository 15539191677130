import React from 'react'
import CardRehabHistoryUX from './CardRehabHistoryUX'

const CardRehabHistory = () => {
  return (
    <CardRehabHistoryUX
    />
  )
}

export default CardRehabHistory