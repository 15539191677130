import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { CheckboxProps, Dimmer, Divider, Icon, Loader } from "semantic-ui-react";

import { useIntl } from "react-intl";

import { BLClickHandler } from "react-lib/appcon/common/ButtonLoadCheck";

import SubDrugNameFormat from "react-lib/apps/HISV3/common/SubDrugNameFormat";

import ButtonCallPharmacist from "./ButtonCallPharmacist";
import CardHomeMedDetailUX from "./CardHomeMedDetailUX";

import {
  ORDER_ITEM_STATUS_LABEL,
  RESULT_SUMMARY,
  RESULT_SUMMARY_LABEL,
  getStatusImagingOrder,
} from "../IME/sequence/ImagingHandler";

import { getDrugStatusName } from "../TPD/TPDInterface";

import CONFIG from "config/config";

// Types
type CardHomeMedDetailProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;

  displayAbnormal?: boolean;
  index: number;
  items: Partial<{
    [key: string]: any;
    drugTransportNotAllowedIds?: number[];
    encounterStatus: string;
    items: Record<string, any>[];
    loading?: boolean;
    order_items: Record<string, any>[];
    order_type_label?: "drug" | "imagingorder" | "supply";
    summary: {
      order_summary_detail: string;
      specific_status: Record<string, any>;
      specific_type: Record<string, any>;
    };
    telephar_type: string;
  }>[];
  languageUX?: string;
  // options
  masterOptions?: any;
  // callback
  onClose?: (e: SyntheticEvent) => any;
};

type Styles = Record<"divider" | "warningIcon", CSSProperties>;

const styles: Styles = {
  divider: { margin: "0.25rem 0 0.75rem" },
  warningIcon: {
    fontSize: "1.2em",
    left: 0,
    marginTop: "-1px",
    position: "absolute",
  },
};

const DETAIL_REGEX = /(.*?):(.\d+)/;

const CardHomeMedDetail = (props: CardHomeMedDetailProps) => {
  const intl = useIntl();

  const [telepharType, setTelepharType] = useState("NONE");

  // Memo Effect
  const data = useMemo(
    () =>
      // console.log(" effect data  props.items: ", props.items)
      props.items[props.index] || {},
    [props.index, props.items]
  );

  // Effect
  useEffect(() => {
    setTelepharType(data.telephar_type || "");
  }, [data.telephar_type]);

  // Memo callback
  const productType = useMemo(
    (): "drug" | "imagingorder" | "supply" => data.order_type_label || "drug",
    [data]
  );

  const orderItems = useMemo(() => data.order_items || data.items || [], [data]);

  const drugDetail = useMemo(() => {
    // console.log(" effect drugDetail data: ", data)
    const { order_id: orderId, summary, supply_transfer_status: supplyTransferStatus } = data;
    const detail = summary?.order_summary_detail || "";
    /*
      รายการสั่งยากลับบ้านผู้ป่วยนอก:2312000063<br/><b>Paracetamol (Paracetamol) 325 mg Tablet</b>
      <font color="gray">1 Tablet รับประทาน <b>{intl.formatMessage({ id: "ทุก 6 ชั่วโมง" })}</b> <b>{intl.formatMessage({ id: "เฉพาะเมื่อมีอาการปวด" })}</b> <br/> <b>Duration</b> 1 days</font>
    */
    const matches = DETAIL_REGEX.exec(detail);
    const groups = matches ? { code: matches[2], title: matches[1] } : {};
    const groupCode = productType === "imagingorder" ? orderId : groups.code?.trim() || "";
    const groupTitle =
      productType === "imagingorder" ? "รายการคำสั่ง Imaging" : groups.title?.trim() || "";
    const statusName: string = summary?.specific_status.name;
    const formattedStatusName = getDrugStatusName(summary?.specific_status.name);

    return {
      code: groupCode,
      names: orderItems.map((item) => item.drug_name_from_format as string),
      specificType: summary?.specific_type.name,
      statusName: productType === "drug" ? formattedStatusName : statusName,
      supplyTransferStatus,
      title: groupTitle,
    };
  }, [data, productType]);

  // Callback
  const getDrugStatusLabel = (statusLabel: string) => {
    // #console.log('status: ', status);
    const consultStatus = data.consult_status;
    // #console.log('consultStatus: ', consultStatus);

    const paymentStatus = {
      PAID: "(ชำระเงินแล้ว)",
      READY: "(รอชำระเงิน)",
    } as any;

    const orderStatus = {
      CHECKED: paymentStatus[data.order_payment_status],
      RECEIVED: {
        CALLING: "อยู่ในสายกับเภสัชกร",
        IDLE: "รอต่อสายหาเภสัชกร",
        ON_CALL: "อยู่ในสายกับเภสัชกร",
      },
      TRANSPORTED: "รอพยาบาลรับยา",
    } as any;

    let status = statusLabel;
    let remark = orderStatus[status];

    if (status === "RECEIVED") {
      remark = orderStatus[status][consultStatus];

      // * Intra TelePharmacy order status RECEIVED และ consult status FINISH ให้แสดง status DELIVERED
      if (consultStatus === "FINISH") {
        status = "DELIVERED";
      }
    }

    return { remark, status };
  };

  // Handler
  const handleChangeValue = useCallback(
    (e: any, v: CheckboxProps) => {
      const value = telepharType === v.name ? "NONE" : v.name;

      setTelepharType(value || "");

      props.onEvent({
        message: "DrugOrderUpdateCallTelephar",
        params: { id: data.id, data: { telephar_type: value } },
      });
    },
    [data, telepharType]
  );

  const handleCall: BLClickHandler = useCallback(
    (e) => {
      // start video call
      console.log(drugDetail.code);

      props.setProp("openVideoCallModal", true);
      props.setProp("closeOverriding", true);
      props.setProp("videoCallRoom", drugDetail.code);

      props.onEvent({
        message: "DrugOrderUpdateCallTelephar",
        params: {
          id: data.id,
          data: { consult_status: "CALLING" },
        },
      });

      props.onClose?.(e);
    },
    [data, drugDetail]
  );

  // Memo
  const statusLabel = useMemo(() => {
    // console.log(" effect statusLabel drugDetail: ", drugDetail)
    let status = drugDetail.statusName;
    // #console.log('drugDetail: ', drugDetail);
    // #console.log('data: ', data);

    // Supply
    if (status === "PRINTED" && productType === "supply") {
      status = drugDetail.supplyTransferStatus;
    }

    // Drug
    const drugStatusLabel = getDrugStatusLabel(status);

    status = drugStatusLabel.status;

    // Imaging
    if (productType === "imagingorder") {
      status = getStatusImagingOrder(orderItems as { [key: string]: any; status: string }[]).label;
    }

    let { remark } = drugStatusLabel;

    remark = remark ? ` : ${remark}` : "";

    // console.log(" `${status} ${remark || }`; ", `${status} ${remark || ""}`)
    return productType === "drug" && CONFIG.ENABLE_INTRA_TELEPHAR ? `${status}${remark}` : status;
  }, [drugDetail, orderItems, productType]);

  const allowedEdit = useMemo(
    () => !["DELIVERED", "RECEIVED", "TRANSPORTED"].includes(drugDetail.statusName),
    [drugDetail.statusName]
  );

  const showCheck = useMemo(
    () =>
      drugDetail.specificType === "HOME_OPD" &&
      productType === "drug" &&
      !!CONFIG.ENABLE_INTRA_TELEPHAR,
    [drugDetail.specificType, productType]
  );

  const itemsElement = useMemo(
    () => (
      <ul style={{ margin: "-0.15rem 0 0", padding: "0 7rem 0 1.5rem" }}>
        {orderItems.map((item: any, index: number) => {
          // #const unitOptions: any[] = props.masterOptions?.unit || [];
          // const unitName: string =
          //   unitOptions.find((option: any) => option.value === item.unit)?.text || "";
          const names: string[] = drugDetail.names;

          const configMapping = {
            drug: {
              isAbnormal: false,
              name: (
                <div style={{ fontWeight: "lighter" }}>
                  <SubDrugNameFormat
                    onEvent={props.onEvent}
                    name={names[index] || item.drug_name_from_format}
                    product={item.product}
                  />
                </div>
              ),
              prefix: "",
              quantity: Number(item.quantity) || "",
              // * เม็ด (TAB)
              unit: item.stock_unit_name, // /\((.*)\)/.exec(unitName)?.[1].toLowerCase(),
            },
            imagingorder: {
              isAbnormal: RESULT_SUMMARY_LABEL[item.result_summary] === RESULT_SUMMARY.ABNORMAL,
              name: item.name_code,
              prefix: "",
              quantity: "สถานะ: ",
              unit: ORDER_ITEM_STATUS_LABEL[item.status],
            },
            supply: {
              isAbnormal: false,
              name: item.name,
              prefix: "จำนวน",
              quantity: item.quantity_request,
              unit: item.stock_unit,
            },
          };

          const config = configMapping[productType];

          return (
            <li key={item.id} style={props.displayAbnormal ? { marginLeft: "1.45rem" } : {}}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 100px",
                  lineHeight: 1.75,
                }}
              >
                <div style={{ textAlign: "left" }}>
                  {config.isAbnormal && (
                    <Icon color="red" name="warning circle" style={styles.warningIcon} />
                  )}
                  {config.name}
                </div>
                <div style={{ minWidth: "max-content", paddingLeft: "2rem", textAlign: "right" }}>
                  {config.prefix} {config.quantity} {config.unit}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    ),
    [drugDetail, orderItems, productType, props.displayAbnormal, props.masterOptions?.unit]
  );

  const buttonCall = useMemo(
    () => (
      <ButtonCallPharmacist
        setProp={props.setProp}
        consultStatus={data.consult_status}
        encounterStatus={data.encounterStatus}
        orderPaymentStatus={data.order_payment_status}
        productType={productType}
        size="small"
        specificType={drugDetail.specificType}
        statusName={drugDetail.statusName}
        telepharType={data.telephar_type}
        onCall={handleCall}
      />
    ),
    [data, drugDetail, handleCall, productType]
  );

  return (
    <div style={{ marginBottom: "-1.5rem" }}>
      <Dimmer active={data.loading} inverted>
        <Loader inverted />
      </Dimmer>

      {props.index !== 0 && <Divider style={styles.divider} />}

      <CardHomeMedDetailUX
        buttonCall={!data.loading && buttonCall}
        disabledCheck={!allowedEdit}
        drugDetail={drugDetail}
        hasDrugTransportNotAllowed={data.drugTransportNotAllowedIds?.length}
        itemsElement={itemsElement}
        languageUX={props.languageUX}
        productType={productType}
        showCheck={showCheck}
        status={statusLabel}
        telepharType={telepharType}
        // callback
        onChangeValue={handleChangeValue}
      />
    </div>
  );
};

CardHomeMedDetail.displayName = "CardHomeMedDetail";

export default React.memo(CardHomeMedDetail);
