import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Radio,
  Input,
  Checkbox,
  Dropdown,
  Form,
  FormGroup,
  FormField,
  TextArea
} from 'semantic-ui-react'

const CardPreAssessmentHealthUX = (props: any) => {
    return(
      <div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px" }}>
          1. การรับรู้และการดูแลสุขภาพ
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "15%", fontWeight: "bold",marginRight: "10px" }}>
              
              <div>
                ผู้ให้ข้อมูล :
              </div>
            </div>
            <div
              style={{ width: "85%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.health_given_by=== "ผู้ป่วย"}
                label="ผู้ป่วย"
                name="health_given_by"
                onChange={props.onChangeData}
                style={{ width:"8%",display: "flex", alignItems: "center"  }}
                value="ผู้ป่วย">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.health_given_by=== "บิดา/มารดา"}
                label="บิดา/มารดา"
                name="health_given_by"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="บิดา/มารดา">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.health_given_by=== "สามี/ภรรยา"}
                label="สามี/ภรรยา"
                name="health_given_by"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="สามี/ภรรยา">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.health_given_by=== "อื่นๆ"}
                label="อื่นๆ โปรดระบุ"
                name="health_given_by"
                onChange={props.onChangeData}
                style={{marginRight: "2%", display: "flex", alignItems: "center" }}
                value="อื่นๆ">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.health_given_by !== "อื่นๆ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.health_given_by !== "อื่นๆ"}
                name="health_given_by_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.health_given_by === "อื่นๆ"  ? props.PreAssessmentSequence?.health_given_by_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "15%", fontWeight: "bold",marginRight: "10px" }}>
              
              <div>
                สุขภาพก่อนการเจ็บป่วย :
              </div>
            </div>
            <div
              style={{ width: "85%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.health_before_illness === "ดี"}
                label="ดี"
                name="health_before_illness"
                onChange={props.onChangeData}
                style={{ width:"8%",display: "flex", alignItems: "center"  }}
                value="ดี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.health_before_illness === "ไม่ดี"}
                label="ไม่ดี โปรดระบุ"
                name="health_before_illness"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center" }}
                value="ไม่ดี">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.health_before_illness !== "ไม่ดี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.health_before_illness !== "ไม่ดี"}
                name="health_before_illness_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.health_before_illness === "ไม่ดี"  ? props.PreAssessmentSequence?.health_before_illness_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "15%", fontWeight: "bold",marginRight: "10px" }}>
              
              <div>
                การเจ็บป่วยครั้งนี้ :
              </div>
            </div>
            <div
              style={{ width: "85%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.health_present_illness === "รุนแรง"}
                label="รุนแรง"
                name="health_present_illness"
                onChange={props.onChangeData}
                style={{ width:"8%", display: "flex", alignItems: "center"  }}
                value="รุนแรง">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.health_present_illness === "ไม่รุนแรง"}
                label="ไม่รุนแรง"
                name="health_present_illness"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่รุนแรง">
              </Radio>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "15%", fontWeight: "bold",marginRight: "10px" }}>
              
              <div>
                การดูแลสุขภาพเมื่อเจ็บป่วย :
              </div>
            </div>
            <div
              style={{ width: "85%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.health_care === "ไปสถานพยาบาล"}
                label="ไปสถานพยาบาล"
                name="health_care"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไปสถานพยาบาล">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.health_care === "ซื้อยารับประทานเอง"}
                label="ซื้อยารับประทานเอง"
                name="health_care"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ซื้อยารับประทานเอง">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.health_care === "อื่นๆ"}
                label="อื่นๆ โปรดระบุ"
                name="health_care"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center" }}
                value="อื่นๆ">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.health_care !== "อื่นๆ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.health_care !== "อื่นๆ"}
                name="health_care_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.health_care === "อื่นๆ"  ? props.PreAssessmentSequence?.health_care_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "21%", fontWeight: "bold",marginRight: "10px" }}>
              
              <div>
                ความคาดหวังในการรักษาครั้งนี้ คิดว่า :
              </div>
            </div>
            <div
              style={{ width: "85%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.expectation === "หาย"}
                label="หาย"
                name="expectation"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="หาย">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.expectation === "ไม่แน่ใจ"}
                label="ไม่แน่ใจ"
                name="expectation"
                onChange={props.onChangeData}
                style={{ marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="ไม่แน่ใจ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.expectation === "ไม่หาย"}
                label="ไม่หาย"
                name="expectation"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่หาย">
              </Radio>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          2. โภชนาการและการเผาผลาญ
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "18%", fontWeight: "bold" }}>
              
              <div>
                รับประทานอาหารจำนวน (มื้อ/วัน) :
              </div>
            </div>
            <div
              style={{ width: "82%", display: "flex"}}>
              
              <Input
                name="meal_per_day"
                onChange={props.onChangeData}
                onKeyDown={props.onKeyDown}
                style={{ marginRight: "2%", marginRight: "20px"}}
                value={props.PreAssessmentSequence?.meal_per_day}>
              </Input>
              <Radio
                checked={props.PreAssessmentSequence?.meal === "อาหารอ่อน"}
                label="อาหารอ่อน"
                name="meal"
                onChange={props.onChangeData}
                style={{ marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="อาหารอ่อน">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.meal === "อาหารธรรมดา"}
                label="อาหารธรรมดา"
                name="meal"
                onChange={props.onChangeData}
                style={{ marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="อาหารธรรมดา">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.meal === "อาหารทางสายยาง"}
                label="อาหารทางสายยาง"
                name="meal"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="อาหารทางสายยาง">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.meal === "อาหารเฉพาะโรค"}
                label="อาหารเฉพาะโรค โปรดระบุ"
                name="meal"
                onChange={props.onChangeData}
                style={{display: "flex", alignItems: "center"  }}
                value="อาหารเฉพาะโรค">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.meal !== "อาหารเฉพาะโรค" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.meal !== "อาหารเฉพาะโรค"}
                name="meal_remark"
                onChange={props.onChangeData}
                style={{marginLeft: "30px"}}
                value={props.PreAssessmentSequence?.meal === "อาหารเฉพาะโรค"  ? props.PreAssessmentSequence?.meal_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "50%", display: "flex" }}>
              
              <div
                style={{ width: "18%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                อาหารที่งด ระบุ :
              </div>
              <Input
                name="abstinence_food"
                onChange={props.onChangeData}
                style={{ width: "80%"}}
                value={props.PreAssessmentSequence?.abstinence_food || ""}>
              </Input>
            </div>
            <div
              style={{ width: "50%", display: "flex"}}>
              
              <div
                style={{ marginRight: "2%",display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                ปริมาณน้ำดื่มในแต่ละวัน :
              </div>
              <Input
                name="amount_water"
                onChange={props.onChangeData}
                style={{ width: "80%"}}
                value={props.PreAssessmentSequence?.amount_water || ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "16%", display: "flex" ,marginRight:"15px"}}>
              
              <div
                style={{ display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                ปัญหาในการรับประทานอาหาร :
              </div>
            </div>
            <div
              style={{ width: "84%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.eating_problem === "ไม่มี"}
                label="ไม่มี"
                name="eating_problem"
                onChange={props.onChangeData}
                style={{ marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.eating_problem === "มี"}
                label="มี โปรดระบุ"
                name="eating_problem"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="มี">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.eating_problem === "มี" ? "flex" : "none" , width: "100%" }}>
                
                <Checkbox
                  checked={props.PreAssessmentSequence?.eating_problem_swallowing}
                  label="การกลืน"
                  name="eating_problem_swallowing"
                  onChange={props.onChangeData}
                  style={{ width: "8%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <Checkbox
                  checked={props.PreAssessmentSequence?.eating_problem_chewing}
                  label="การเคี้ยว"
                  name="eating_problem_chewing"
                  onChange={props.onChangeData}
                  style={{ width: "8%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <Checkbox
                  checked={props.PreAssessmentSequence?.eating_problem_bored}
                  label="เบื่ออาหาร"
                  name="eating_problem_bored"
                  onChange={props.onChangeData}
                  style={{ width: "8%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <Checkbox
                  checked={props.PreAssessmentSequence?.eating_problem_squeamish}
                  label="คลื่นไส้"
                  name="eating_problem_squeamish"
                  onChange={props.onChangeData}
                  style={{ width: "8%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <Checkbox
                  checked={props.PreAssessmentSequence?.eating_problem_vomit}
                  label="อาเจียน"
                  name="eating_problem_vomit"
                  onChange={props.onChangeData}
                  style={{ width: "8%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <Checkbox
                  checked={props.PreAssessmentSequence?.eating_problem_other}
                  label="อื่นๆ โปรดระบุ"
                  name="eating_problem_other"
                  onChange={props.onChangeData}
                  style={{ width: "12%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <div
                  style={{ display: !props.PreAssessmentSequence?.eating_problem_other ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                  *
                </div>
                <Input
                  disabled={!props.PreAssessmentSequence?.eating_problem_other}
                  name="eating_problem_other_remark"
                  onChange={props.onChangeData}
                  style={{ width: "30%" }}
                  value={props.PreAssessmentSequence?.eating_problem_other ? props.PreAssessmentSequence?.eating_problem_other_remark || "" : ""}>
                </Input>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          3. การขับถ่าย
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "8%", fontWeight: "bold" }}>
              
              <div
                style={{ fontWeight: "bold", marginRight: "15px" }}>
                ถ่ายปัสสาวะ :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Input
                name="urination_per_day"
                onChange={props.onChangeData}
                onKeyDown={props.onKeyDown}
                style={{ marginRight: "2%", marginRight: "20px"}}
                value={props.PreAssessmentSequence?.urination_per_day}>
              </Input>
              <div
                style={{ fontWeight: "bold", marginRight: "20px", alignItems: "center", display: "flex" }}>
                ครั้ง/วัน
              </div>
              <Radio
                checked={props.PreAssessmentSequence?.urination_problem === "ปกติ"}
                label="ปกติ"
                name="urination_problem"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ปกติ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.urination_problem === "ไม่ปกติ"}
                label="ไม่ปกติ โปรดระบุ"
                name="urination_problem"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่ปกติ">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.urination_problem !== "ไม่ปกติ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.urination_problem !== "ไม่ปกติ" }
                name="urination_problem_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.urination_problem === "ไม่ปกติ" ? props.PreAssessmentSequence?.urination_problem_remark || "" : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "8%", fontWeight: "bold" }}>
              
              <div>
                ถ่ายอุจจาระ :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Input
                name="defecation_per_day"
                onChange={props.onChangeData}
                onKeyDown={props.onKeyDown}
                style={{ marginRight: "2%", marginRight: "20px"}}
                value={props.PreAssessmentSequence?.defecation_per_day}>
              </Input>
              <div
                style={{ fontWeight: "bold", marginRight: "20px", alignItems: "center", display: "flex" }}>
                ครั้ง/วัน
              </div>
              <Radio
                checked={props.PreAssessmentSequence?.defecation_problem === "ปกติ"}
                label="ปกติ"
                name="defecation_problem"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ปกติ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.defecation_problem === "ไม่ปกติ"}
                label="ไม่ปกติ โปรดระบุ"
                name="defecation_problem"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่ปกติ">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.defecation_problem !== "ไม่ปกติ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.defecation_problem !== "ไม่ปกติ" }
                name="defecation_problem_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.defecation_problem === "ไม่ปกติ" ? props.PreAssessmentSequence?.defecation_problem_remark || "" : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "17%", fontWeight: "bold" }}>
              
              <div>
                การขับถ่ายทางหน้าท้อง :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.stomach_defecation === "ไม่มี"}
                label="ไม่มี"
                name="stomach_defecation"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "none", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.stomach_defecation === "มี"}
                label="มี"
                name="stomach_defecation"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "none", alignItems: "center"  }}
                value="มี">
              </Radio>
              <Checkbox
                checked={props.PreAssessmentSequence?.noStomachDefecation}
                label="ไม่มี"
                name="noStomachDefecation"
                onChange={props.onChangeStomachDefecation}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.haveStomachDefecation}
                label="มี"
                name="haveStomachDefecation"
                onChange={props.onChangeStomachDefecation}
                style={{ marginRight: "2%",display: "flex", alignItems: "center"  }}>
              </Checkbox>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          4. กิจวัตรประจำวันและการออกกำลังกาย
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "20%", fontWeight: "bold" }}>
              
            </div>
            <div
              style={{ width: "80%", display: "flex"}}>
              
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", fontWeight: "bold", background: "#F9D3E5" }}>
                ก่อนการเจ็บป่วย
              </div>
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", fontWeight: "bold", background: "#F5B793" }}>
                ขณะเจ็บป่วยครั้งนี้
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "20%", fontWeight: "bold" }}>
              
              <div>
                1. การรับประทานอาหาร
              </div>
            </div>
            <div
              style={{ width: "80%", display: "flex"}}>
              
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.eating_before_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="eating_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.eating_before_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="eating_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.eating_before_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="eating_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.eating_before_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="eating_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.eating_present_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="eating_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                  <Radio>
                  </Radio>
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.eating_present_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="eating_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.eating_present_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="eating_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.eating_present_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="eating_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "20%", fontWeight: "bold" }}>
              
              <div>
                2. การทำความสะอาดปากและฟัน
              </div>
            </div>
            <div
              style={{ width: "80%", display: "flex"}}>
              
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.cleaning_mounth_and_teeth_before_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="cleaning_mounth_and_teeth_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.cleaning_mounth_and_teeth_before_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="cleaning_mounth_and_teeth_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.cleaning_mounth_and_teeth_before_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="cleaning_mounth_and_teeth_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.cleaning_mounth_and_teeth_before_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="cleaning_mounth_and_teeth_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.cleaning_mounth_and_teeth_present_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="cleaning_mounth_and_teeth_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.cleaning_mounth_and_teeth_present_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="cleaning_mounth_and_teeth_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.cleaning_mounth_and_teeth_present_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="cleaning_mounth_and_teeth_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.cleaning_mounth_and_teeth_present_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="cleaning_mounth_and_teeth_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "20%", fontWeight: "bold" }}>
              
              <div>
                3. การแต่งตัว
              </div>
            </div>
            <div
              style={{ width: "80%", display: "flex"}}>
              
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.dressing_before_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="dressing_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.dressing_before_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="dressing_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.dressing_before_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="dressing_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.dressing_before_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="dressing_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.dressing_after_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="dressing_after_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.dressing_after_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="dressing_after_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.dressing_after_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="dressing_after_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.dressing_after_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="dressing_after_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "20%", fontWeight: "bold" }}>
              
              <div>
                4. การเดิน
              </div>
            </div>
            <div
              style={{ width: "80%", display: "flex"}}>
              
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.walking_before_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="walking_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.walking_before_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="walking_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.walking_before_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="walking_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.walking_before_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="walking_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.walking_present_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="walking_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.walking_present_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="walking_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.walking_present_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="walking_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.walking_present_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="walking_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "20%", fontWeight: "bold" }}>
              
              <div>
                5. การขับถ่าย
              </div>
            </div>
            <div
              style={{ width: "80%", display: "flex"}}>
              
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.defecating_before_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="defecating_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.defecating_before_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="defecating_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.defecating_before_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="defecating_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.defecating_before_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="defecating_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.defecating_present_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="defecating_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.defecating_present_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="defecating_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.defecating_present_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="defecating_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.defecating_present_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="defecating_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "20%", fontWeight: "bold" }}>
              
              <div>
                6. การอาบน้ำ
              </div>
            </div>
            <div
              style={{ width: "80%", display: "flex"}}>
              
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.bathing_before_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="bathing_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.bathing_before_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="bathing_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.bathing_before_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="bathing_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.bathing_before_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="bathing_before_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
              <div
                style={{ width: "50%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.bathing_present_illness === "ทำได้เอง"}
                  label="ทำได้เอง"
                  name="bathing_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ทำได้เอง">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.bathing_present_illness === "บางส่วน"}
                  label="บางส่วน"
                  name="bathing_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="บางส่วน">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.bathing_present_illness === "ไม่ได้เลย"}
                  label="ไม่ได้เลย"
                  name="bathing_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ไม่ได้เลย">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.bathing_present_illness === "ใช้อุปกรณ์"}
                  label="ใช้อุปกรณ์"
                  name="bathing_present_illness"
                  onChange={props.onChangeData}
                  style={{ width: "20%", display: "flex", alignItems: "center"  }}
                  value="ใช้อุปกรณ์">
                </Radio>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          5. การพักผ่อนนอนหลับ
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "4%", fontWeight: "bold" }}>
              
              <div>
                นอน :
              </div>
            </div>
            <div
              style={{ width: "96%", display: "flex"}}>
              
              <Input
                name="sleeping_per_day"
                onChange={props.onChangeData}
                onKeyDown={props.onKeyDown}
                style={{ width: "5%", marginRight: "20px"}}
                value={props.PreAssessmentSequence?.sleeping_per_day}>
              </Input>
              <div
                style={{ fontWeight: "bold", marginRight: "20px", alignItems: "center", display: "flex" }}>
                ชม./วัน
              </div>
              <Radio
                checked={props.PreAssessmentSequence?.sleeping_enough === "เพียงพอ"}
                label="เพียงพอ"
                name="sleeping_enough"
                onChange={props.onChangeData}
                style={{ marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="เพียงพอ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.sleeping_enough === "ไม่เพียงพอ"}
                label="ไม่เพียงพอ"
                name="sleeping_enough"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่เพียงพอ">
              </Radio>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "10%", fontWeight: "bold",marginRight: "10px" }}>
              
              <div>
                ปัญหาการนอน :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.sleeping_problem === "ไม่มี"}
                label="ไม่มี"
                name="sleeping_problem"
                onChange={props.onChangeData}
                style={{ width: "9%",display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.sleeping_problem === "มี"}
                label="มี โปรดระบุ"
                name="sleeping_problem"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="มี">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.sleeping_problem !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.sleeping_problem !== "มี"}
                name="sleeping_problem_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.sleeping_problem === "มี"  ? props.PreAssessmentSequence?.sleeping_problem_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "10%", fontWeight: "bold" ,marginRight: "10px"}}>
              
              <div>
                การใช้ยานอนหลับ :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.sleep_pill === "ไม่ใช้"}
                label="ไม่ใช้"
                name="sleep_pill"
                onChange={props.onChangeData}
                style={{ width: "9%", display: "flex", alignItems: "center"  }}
                value="ไม่ใช้">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.sleep_pill === "ไม่ทราบ"}
                label="ไม่ทราบ"
                name="sleep_pill"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่ทราบ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.sleep_pill === "ใช้"}
                label="ใช้ โปรดระบุ"
                name="sleep_pill"
                onChange={props.onChangeData}
                style={{ marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="ใช้">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.sleep_pill !== "ใช้" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.sleep_pill !== "ใช้" }
                name="sleep_pill_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.sleep_pill === "ใช้"  ? props.PreAssessmentSequence?.sleep_pill_remark || ""   : ""}>
              </Input>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          6. สติปัญญาการรับรู้และประสาทสัมผัส
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "11%", fontWeight: "bold" }}>
              
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", fontWeight: "bold", background: "#F9D3E5" }}>
                ก่อนการเจ็บป่วย
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.beforeDetail && { display: "none" })}}>
                
              </div>
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", fontWeight: "bold", background: "#F5B793" }}>
                แรกรับ
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "11%", fontWeight: "bold" }}>
              
              <div>
                การรับรู้
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.recognition_before === "ปกติ"}
                  label="ปกติ"
                  name="recognition_before"
                  onChange={props.onChangeData}
                  style={{ width: "35%",display: "flex", alignItems: "center"  }}
                  value="ปกติ">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.recognition_before === "ไม่ปกติ"}
                  label="ไม่ปกติ"
                  name="recognition_before"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ไม่ปกติ">
                </Radio>
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.beforeDetail && { display: "none" })}}>
                
                <div
                  style={{ color: "red", ...(props.PreAssessmentSequence?.recognition_before !== "ไม่ปกติ" && { display: "none" }) }}>
                  *
                </div>
                <Input
                  name="recognition_before_detail"
                  onChange={props.onChangeData}
                  style={{ width: "100%" , margin: "0px 10px", ...(props.PreAssessmentSequence?.recognition_before !== "ไม่ปกติ" && { display: "none" })}}
                  value={props.PreAssessmentSequence?.recognition_before_detail}>
                </Input>
              </div>
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.recognition_present === "ปกติ"}
                  label="ปกติ"
                  name="recognition_present"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ปกติ">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.recognition_present === "ไม่ปกติ"}
                  label="ไม่ปกติ"
                  name="recognition_present"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ไม่ปกติ">
                </Radio>
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.PreAssessmentSequence?.recognition_present !== "ไม่ปกติ" && { display: "none" })}}>
                
                <div
                  style={{ color: "red" }}>
                  *
                </div>
                <Input
                  name="recognition_present_detail"
                  onChange={props.onChangeData}
                  style={{ width: "100%" , margin: "0px 10px"}}
                  value={props.PreAssessmentSequence?.recognition_present_detail}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "11%", fontWeight: "bold" }}>
              
              <div>
                ความจำ/การโต้ตอบ
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.memory_and_response_before === "ปกติ"}
                  label="ปกติ"
                  name="memory_and_response_before"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ปกติ">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.memory_and_response_before === "ไม่ปกติ"}
                  label="ไม่ปกติ"
                  name="memory_and_response_before"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ไม่ปกติ">
                </Radio>
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.beforeDetail && { display: "none" })}}>
                
                <div
                  style={{ color: "red", ...(props.PreAssessmentSequence?.memory_and_response_before !== "ไม่ปกติ" && { display: "none" }) }}>
                  *
                </div>
                <Input
                  name="memory_and_response_before_detail"
                  onChange={props.onChangeData}
                  style={{ width: "100%" , margin: "0px 10px", ...(props.PreAssessmentSequence?.memory_and_response_before !== "ไม่ปกติ" && { display: "none" })}}
                  value={props.PreAssessmentSequence?.memory_and_response_before_detail}>
                </Input>
              </div>
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.memory_and_response_present === "ปกติ"}
                  label="ปกติ"
                  name="memory_and_response_present"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ปกติ">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.memory_and_response_present === "ไม่ปกติ"}
                  label="ไม่ปกติ"
                  name="memory_and_response_present"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ไม่ปกติ">
                </Radio>
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.PreAssessmentSequence?.memory_and_response_present !== "ไม่ปกติ" && { display: "none" })}}>
                
                <div
                  style={{ color: "red" }}>
                  *
                </div>
                <Input
                  name="memory_and_response_present_detail"
                  onChange={props.onChangeData}
                  style={{ width: "100%" , margin: "0px 10px"}}
                  value={props.PreAssessmentSequence?.memory_and_response_present_detail}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "11%", fontWeight: "bold" }}>
              
              <div>
                การได้ยิน
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.hearing_before === "ปกติ"}
                  label="ปกติ"
                  name="hearing_before"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ปกติ">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.hearing_before === "ไม่ปกติ"}
                  label="ไม่ปกติ"
                  name="hearing_before"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ไม่ปกติ">
                </Radio>
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.beforeDetail && { display: "none" })}}>
                
                <div
                  style={{ color: "red", ...(props.PreAssessmentSequence?.hearing_before !== "ไม่ปกติ" && { display: "none" }) }}>
                  *
                </div>
                <Input
                  name="hearing_before_detail"
                  onChange={props.onChangeData}
                  style={{ width: "100%" , margin: "0px 10px", ...(props.PreAssessmentSequence?.hearing_before !== "ไม่ปกติ" && { display: "none" })}}
                  value={props.PreAssessmentSequence?.hearing_before_detail}>
                </Input>
              </div>
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.hearing_present === "ปกติ"}
                  label="ปกติ"
                  name="hearing_present"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ปกติ">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.hearing_present === "ไม่ปกติ"}
                  label="ไม่ปกติ"
                  name="hearing_present"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ไม่ปกติ">
                </Radio>
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.PreAssessmentSequence?.hearing_present !== "ไม่ปกติ" && { display: "none" })}}>
                
                <div
                  style={{ color: "red" }}>
                  *
                </div>
                <Input
                  name="hearing_present_detail"
                  onChange={props.onChangeData}
                  style={{ width: "100%" , margin: "0px 10px"}}
                  value={props.PreAssessmentSequence?.hearing_present_detail}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "11%", fontWeight: "bold" }}>
              
              <div>
                การมองเห็น
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.seeing_before === "ปกติ"}
                  label="ปกติ"
                  name="seeing_before"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ปกติ">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.seeing_before === "ไม่ปกติ"}
                  label="ไม่ปกติ"
                  name="seeing_before"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ไม่ปกติ">
                </Radio>
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.beforeDetail && { display: "none" })}}>
                
                <div
                  style={{ color: "red", ...(props.PreAssessmentSequence?.seeing_before !== "ไม่ปกติ" && { display: "none" }) }}>
                  *
                </div>
                <Input
                  name="seeing_before_detail"
                  onChange={props.onChangeData}
                  style={{ width: "100%" , margin: "0px 10px", ...(props.PreAssessmentSequence?.seeing_before !== "ไม่ปกติ" && { display: "none" })}}
                  value={props.PreAssessmentSequence?.seeing_before_detail}>
                </Input>
              </div>
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.seeing_present === "ปกติ"}
                  label="ปกติ"
                  name="seeing_present"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ปกติ">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.seeing_present === "ไม่ปกติ"}
                  label="ไม่ปกติ"
                  name="seeing_present"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ไม่ปกติ">
                </Radio>
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.PreAssessmentSequence?.seeing_present !== "ไม่ปกติ" && { display: "none" })}}>
                
                <div
                  style={{ color: "red" }}>
                  *
                </div>
                <Input
                  name="seeing_present_detail"
                  onChange={props.onChangeData}
                  style={{ width: "100%" , margin: "0px 10px"}}
                  value={props.PreAssessmentSequence?.seeing_present_detail}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px 30px" }}>
            
            <div
              style={{ width: "11%", fontWeight: "bold" }}>
              
              <div>
                การสัมผัส
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(249, 211, 229, 0.29)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.touch_before === "ปกติ"}
                  label="ปกติ"
                  name="touch_before"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ปกติ">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.touch_before === "ไม่ปกติ"}
                  label="ไม่ปกติ"
                  name="touch_before"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ไม่ปกติ">
                </Radio>
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.beforeDetail && { display: "none" })}}>
                
                <div
                  style={{ color: "red", ...(props.PreAssessmentSequence?.touch_before !== "ไม่ปกติ" && { display: "none" }) }}>
                  *
                </div>
                <Input
                  name="touch_before_detail"
                  onChange={props.onChangeData}
                  style={{ width: "100%" , margin: "0px 10px", ...(props.PreAssessmentSequence?.touch_before !== "ไม่ปกติ" && { display: "none" })}}
                  value={props.PreAssessmentSequence?.touch_before_detail}>
                </Input>
              </div>
              <div
                style={{ width: "20%", display: "flex",justifyContent: "center", padding: "10px", background: "rgba(245, 183, 147, 0.35)" }}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.touch_present === "ปกติ"}
                  label="ปกติ"
                  name="touch_present"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ปกติ">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.touch_present === "ไม่ปกติ"}
                  label="ไม่ปกติ"
                  name="touch_present"
                  onChange={props.onChangeData}
                  style={{ width: "35%", display: "flex", alignItems: "center"  }}
                  value="ไม่ปกติ">
                </Radio>
              </div>
              <div
                style={{ width: "30%", display: "flex",padding: "10px", ...(props.PreAssessmentSequence?.touch_present !== "ไม่ปกติ" && { display: "none" })}}>
                
                <div
                  style={{ color: "red" }}>
                  *
                </div>
                <Input
                  name="touch_present_detail"
                  onChange={props.onChangeData}
                  style={{ width: "100%" , margin: "0px 10px"}}
                  value={props.PreAssessmentSequence?.touch_present_detail}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "8%", fontWeight: "bold", marginRight: "10px"}}>
              
              <div>
                ความเจ็บปวด :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.pain_status === "ปวด"}
                label="ปวด"
                name="pain_status"
                onChange={props.onChangeData}
                style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="ปวด">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.pain_status === "ไม่ปวด"}
                label="ไม่ปวด"
                name="pain_status"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่ปวด">
              </Radio>
            </div>
          </div>
          <div>
            
            <Form>
              <FormGroup
                inline={true}
                style={{ display: "flex", alignItems: "baseline", margin: "10px" }}>
                <FormField
                  inline={true}
                  style={{ display: "flex", alignItems: "center", }}>
                  <label>
                    ประเมินระดับความเจ็บปวด :
                  </label>
                  <Dropdown
                    clearable={true}
                    name="assessPainLevel"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.pain}
                    search={true}
                    selection={true}
                    value={props.PreAssessmentSequence?.assessPainLevel}>
                  </Dropdown>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{ display: "flex", alignItems: "baseline", margin: "10px" }}>
                <FormField
                  inline={true}
                  style={{ display: "flex", alignItems: "center", }}>
                  <label>
                    ระดับความปวด :
                  </label>
                  <Dropdown
                    clearable={true}
                    name="painLevel"
                    onChange={props.onChangeData}
                    options={props.preAssessmentOptions?.painLevel}
                    search={true}
                    selection={true}
                    value={props.PreAssessmentSequence?.painLevel}>
                  </Dropdown>
                </FormField>
                <FormField
                  inline={true}
                  style={{ display: "flex", alignItems: "center", }}>
                  <label>
                    บริเวณที่ปวด :
                  </label>
                  <Dropdown
                    allowAdditions={true}
                    clearable={true}
                    name="painArea"
                    onAddItem={props.onAdditionLocation}
                    onChange={props.onChangeData}
                    options={props.locationOption}
                    search={true}
                    selection={true}
                    value={props.PreAssessmentSequence?.painArea}>
                  </Dropdown>
                </FormField>
                <FormField
                  inline={true}
                  style={{ display: "flex", alignItems: "center", ...(props.PreAssessmentSequence?.painArea !== 1321 && { display: "none" } ) }}
                  width={4}>
                  <TextArea
                    name="painAreaRemark"
                    onChange={props.onChangeData}
                    value={props.PreAssessmentSequence?.painAreaRemark}>
                  </TextArea>
                </FormField>
                <FormField
                  inline={true}
                  style={{ display: "flex", alignItems: "center", }}>
                  <label>
                    ลักษณะการปวด :
                  </label>
                  <Dropdown
                    allowAdditions={true}
                    clearable={true}
                    name="painNature"
                    onAddItem={props.onAdditionCharacteristic}
                    onChange={props.onChangeData}
                    options={props.characteristicOption}
                    search={true}
                    selection={true}
                    value={props.PreAssessmentSequence?.painNature}>
                  </Dropdown>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{ display: "flex", alignItems: "flex-start", margin: "10px" }}>
                <FormField
                  inline={true}
                  style={{ display: "flex", alignItems: "center", }}
                  width={6}>
                  <label
                    style={{ width: "fit-content" }}>
                    การให้การพยาบาลผู้ป่วย :
                  </label>
                  <Dropdown
                    clearable={true}
                    multiple={true}
                    name="senses_nursing_care"
                    onChange={props.onChangeData}
                    options={props.preAssessmentOptions?.sensesNursingCareOptions}
                    search={true}
                    selection={true}
                    style={{ width: "100%"}}
                    value={props.PreAssessmentSequence?.senses_nursing_care}>
                  </Dropdown>
                </FormField>
                <FormField
                  inline={true}
                  style={{ display: "flex", alignItems: "center", ...(!((props.PreAssessmentSequence?.senses_nursing_care || []).includes("อื่นๆ") || (props.PreAssessmentSequence?.senses_nursing_care || []).includes("มี RX")) && { display: "none" } ) }}
                  width={4}>
                  <TextArea
                    name="sensesNursingCareRemark"
                    onChange={props.onChangeData}
                    value={props.PreAssessmentSequence?.sensesNursingCareRemark}>
                  </TextArea>
                </FormField>
              </FormGroup>
            </Form>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          7. การรับรู้ตนเองและอัตมโนทัศน์ : การเจ็บป่วยครั้งนี้มีผลกระทบต่อ
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "10%", fontWeight: "bold" }}>
              
              <div>
                ภาพลักษณ์ :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.illness_affect_image === "ไม่มี"}
                label="ไม่มี"
                name="illness_affect_image"
                onChange={props.onChangeData}
                style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.illness_affect_image === "มี"}
                label="มี โปรดระบุ"
                name="illness_affect_image"
                onChange={props.onChangeData}
                style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="มี">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.illness_affect_image !== "มี"  ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.illness_affect_image !== "มี" }
                name="illness_affect_image_remark"
                onChange={props.onChangeData}
                style={{ width: "30%"}}
                value={props.PreAssessmentSequence?.illness_affect_image === "มี"  ? props.PreAssessmentSequence?.illness_affect_image_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "30%", fontWeight: "bold",marginRight: "10px" }}>
              
              <div>
                ความสามารถ (การทำกิจวัตรประจำวัน/การทำงาน) 
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.illness_affect_ability === "ไม่มี"}
                label="ไม่มี"
                name="illness_affect_ability"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.illness_affect_ability === "มี"}
                label="มี โปรดระบุ"
                name="illness_affect_ability"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="มี">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.illness_affect_ability !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.illness_affect_ability !== "มี"}
                name="illness_affect_ability_remark"
                onChange={props.onChangeData}
                style={{ width: "30%"}}
                value={props.PreAssessmentSequence?.illness_affect_ability === "มี"  ? props.PreAssessmentSequence?.illness_affect_ability_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "10%", fontWeight: "bold" }}>
              
              <div>
                อารมณ์และจิตใจ
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.illness_affect_emotion_and_mind === "ไม่มี"}
                label="ไม่มี"
                name="illness_affect_emotion_and_mind"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.illness_affect_emotion_and_mind === "มี"}
                label="มี โปรดระบุ"
                name="illness_affect_emotion_and_mind"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="มี">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.illness_affect_emotion_and_mind === "มี" ? "flex" : "none" , width: "100%", marginLeft: "20px" }}>
                
                <Checkbox
                  checked={props.PreAssessmentSequence?.emotion_anxious}
                  label="วิตกกังวล"
                  name="emotion_anxious"
                  onChange={props.onChangeData}
                  style={{ width: "10%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <Checkbox
                  checked={props.PreAssessmentSequence?.emotion_fear}
                  label="กลัว"
                  name="emotion_fear"
                  onChange={props.onChangeData}
                  style={{ width: "10%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <Checkbox
                  checked={props.PreAssessmentSequence?.emotion_drear}
                  label="ซึมเศร้า"
                  name="emotion_drear"
                  onChange={props.onChangeData}
                  style={{ width: "10%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <Checkbox
                  checked={props.PreAssessmentSequence?.emotion_aggressive}
                  label="ก้าวร้าว"
                  name="emotion_aggressive"
                  onChange={props.onChangeData}
                  style={{ width: "10%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <Checkbox
                  checked={props.PreAssessmentSequence?.emotion_other}
                  label="อื่นๆ โปรดระบุ"
                  name="emotion_other"
                  onChange={props.onChangeData}
                  style={{ width: "14%", display: "flex", alignItems: "center"  }}>
                </Checkbox>
                <div
                  style={{ display: !props.PreAssessmentSequence?.emotion_other  ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                  *
                </div>
                <Input
                  disabled={!props.PreAssessmentSequence?.emotion_other}
                  name="emotion_other_remark"
                  onChange={props.onChangeData}
                  style={{ width: "30%"}}
                  value={props.PreAssessmentSequence?.emotion_other ? props.PreAssessmentSequence?.emotion_other_remark || ""   : ""}>
                </Input>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          8. บทบาทและสัมพันธภาพ
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ fontWeight: "bold",marginRight: "2%"}}>
              
              <div>
                การเจ็บป่วยครั้งนี้มีผลกระทบต่อผู้ป่วย :
              </div>
            </div>
            <div
              style={{ width: "100%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.illness_affect_on_character_and_relation_status === "ไม่มี"}
                label="ไม่มี"
                name="illness_affect_on_character_and_relation_status"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.illness_affect_on_character_and_relation_status === "มี"}
                label="มี"
                name="illness_affect_on_character_and_relation_status"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="มี">
              </Radio>
            </div>
          </div>
          <div
            style={{ display: props.PreAssessmentSequence?.illness_affect_on_character_and_relation_status === "มี" ? "flex" : "none" , alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "100%", display: "flex"}}>
              
              <Checkbox
                checked={props.PreAssessmentSequence?.relation_family_role}
                label="บทบาทในครอบครัว"
                name="relation_family_role"
                onChange={props.onChangeData}
                style={{ width: "15%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.relation_family_relationship}
                label="สัมพันธภาพในครอบครัว"
                name="relation_family_relationship"
                onChange={props.onChangeData}
                style={{ width: "15%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.relation_other_relationship}
                label="สัมพัธภาพกับผู้อื่น"
                name="relation_other_relationship"
                onChange={props.onChangeData}
                style={{ width: "12%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.relation_career_role}
                label="บทบาทด้านอาชีพ"
                name="relation_career_role"
                onChange={props.onChangeData}
                style={{ width: "12%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.relation_education_role}
                label="บทบาทด้านการศึกษา"
                name="relation_education_role"
                onChange={props.onChangeData}
                style={{ width: "13%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.relation_other}
                label="อื่นๆ โปรดระบุ"
                name="relation_other"
                onChange={props.onChangeData}
                style={{ width: "12%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <div
                style={{ display: !props.PreAssessmentSequence?.relation_other ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={!props.PreAssessmentSequence?.relation_other }
                name="relation_other_remark"
                onChange={props.onChangeData}
                style={{ width: "20%"}}
                value={props.PreAssessmentSequence?.relation_other ? props.PreAssessmentSequence?.relation_other_remark || "" : ""}>
              </Input>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          9. เพศและการเจริญพันธ์ุ
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "100%", display: "flex" }}>
              
              <Radio
                checked={props.PreAssessmentSequence?.problems_fertility === "ไม่มีปัญหา"}
                label="ไม่มีปัญหา"
                name="problems_fertility"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่มีปัญหา">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.problems_fertility === "มีปัญหา"}
                label="มีปัญหา โปรดระบุ"
                name="problems_fertility"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="มีปัญหา">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.problems_fertility !== "มีปัญหา" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.problems_fertility !== "มีปัญหา"}
                name="problems_fertility_remark"
                onChange={props.onChangeData}
                style={{ width: "20%"}}
                value={props.PreAssessmentSequence?.problems_fertility === "มีปัญหา" ? props.PreAssessmentSequence?.problems_fertility_remark || "" : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "11%", fontWeight: "bold" }}>
              
              <div>
                อวัยวะสืบพันธุ์ :
              </div>
            </div>
            <div
              style={{ width: "89%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.genital_organ === "ปกติ"}
                label="ปกติ"
                name="genital_organ"
                onChange={props.onChangeData}
                style={{  width: "8%", display: "flex", alignItems: "center"  }}
                value="ปกติ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.genital_organ === "ไม่ปกติ"}
                label="ไม่ปกติ"
                name="genital_organ"
                onChange={props.onChangeData}
                style={{ display: "flex", alignItems: "center"  }}
                value="ไม่ปกติ">
              </Radio>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px", ...(!props.isShowPregnancy && {display: "none"}) }}>
            
            <div
              style={{ width: "11%", fontWeight: "bold" }}>
              
              <div>
                ประวัติการตั้งครรภ์:
              </div>
            </div>
            <div
              style={{ width: "89%", display: "flex", }}>
              
              <Radio
                checked={props.PreAssessmentSequence?.pregnancy_status === 1}
                label="ไม่ทราบ"
                name="pregnancy_status"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center"  }}
                value={1}>
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.pregnancy_status === 2}
                label="ไม่ตั้งครรภ์"
                name="pregnancy_status"
                onChange={props.onChangeData}
                style={{ marginRight: "2%",display: "flex", alignItems: "center"  }}
                value={2}>
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.pregnancy_status === 3}
                label="กำลังตั้งครรภ์"
                name="pregnancy_status"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}
                value={3}>
              </Radio>
              <div
                style={{ display: "flex", alignItems: "center", marginRight: "10px", ...(props.PreAssessmentSequence?.pregnancy_status !== 3 && {display: "none"}) }}>
                ระบุไตรมาส
              </div>
              <Dropdown
                name="pregnancy_period"
                onChange={props.onChangeData}
                options={props.preAssessmentOptions?.pregnancyPeriodOptions}
                selection={true}
                style={{ ...(props.PreAssessmentSequence?.pregnancy_status !== 3 && {display: "none"}) }}
                value={props.PreAssessmentSequence?.pregnancy_period}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "11%", fontWeight: "bold" }}>
              
              <div>
                ประจำเดือน :
              </div>
            </div>
            <div
              style={{ width: "89%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.period === "ไม่มี"}
                label="ไม่มี"
                name="period"
                onChange={props.onChangeData}
                style={{  width: "8%",  display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.period === "มีปกติ"}
                label="มีปกติ"
                name="period"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="มีปกติ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.period === "มีไม่ปกติ"}
                label="มี ไม่ปกติ"
                name="period"
                onChange={props.onChangeData}
                style={{   display: "flex", alignItems: "center"  }}
                value="มีไม่ปกติ">
              </Radio>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "11%", fontWeight: "bold" }}>
              
              <div>
                เต้านม :
              </div>
            </div>
            <div
              style={{ width: "89%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.breast === "ปกติ"}
                label="ปกติ"
                name="breast"
                onChange={props.onChangeData}
                style={{  width: "8%", display: "flex", alignItems: "center"  }}
                value="ปกติ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.breast === "ไม่ปกติ"}
                label="ไม่ปกติ"
                name="breast"
                onChange={props.onChangeData}
                style={{  display: "flex", alignItems: "center"  }}
                value="ไม่ปกติ">
              </Radio>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          10. ความเครียดและความทนต่อความเครียด
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "15%", fontWeight: "bold" }}>
              
              <div>
                การตัดสินใจในเรื่องสำคัญ :
              </div>
            </div>
            <div
              style={{ width: "85%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.decisions === "ตัดสินใจเองได้"}
                label="ตัดสินใจเองได้"
                name="decisions"
                onChange={props.onChangeData}
                style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="ตัดสินใจเองได้">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.decisions === "ให้ผู้อื่นตัดสินใจให้"}
                label="ให้ผู้อื่นตัดสินใจให้"
                name="decisions"
                onChange={props.onChangeData}
                style={{ width: "14%", display: "flex", alignItems: "center"  }}
                value="ให้ผู้อื่นตัดสินใจให้">
              </Radio>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "12%", fontWeight: "bold" }}>
              
              <div>
                วิธีแก้ไขความเครียด :
              </div>
            </div>
            <div
              style={{ width: "88%", display: "flex"}}>
              
              <Checkbox
                checked={props.PreAssessmentSequence?.stress_relief_consult }
                label="ปรึกษาผู้ไว้ใจ"
                name="stress_relief_consult"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}>
                <Checkbox>
                </Checkbox>
                <Checkbox>
                </Checkbox>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.stress_relief_hobby }
                label="ทำงานอดิเรก"
                name="stress_relief_hobby"
                onChange={props.onChangeData}
                style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.stress_relief_seclude}
                label="แยกตัว"
                name="stress_relief_seclude"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.stress_relief_drug}
                label="ใช้ยา"
                name="stress_relief_drug"
                onChange={props.onChangeData}
                style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.stress_relief_other}
                label="อื่นๆ โปรดระบุ"
                name="stress_relief_other"
                onChange={props.onChangeData}
                style={{ marginRight: "2%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <div
                style={{ display: !props.PreAssessmentSequence?.stress_relief_other ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={!props.PreAssessmentSequence?.stress_relief_other}
                name="stress_relief_other_remark"
                onChange={props.onChangeData}
                style={{ width: "20%" }}
                value={props.PreAssessmentSequence?.stress_relief_other ? props.PreAssessmentSequence?.stress_relief_other_remark || "" : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "29%", fontWeight: "bold" }}>
              
              <div>
                ผู้ป่วย/ญาติ สามารถที่จะดูแลตนเองขณะเจ็บป่วยที่บ้าน :
              </div>
            </div>
            <div
              style={{ width: "71%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.care_yourself_at_home === "ได้"}
                label="ได้"
                name="care_yourself_at_home"
                onChange={props.onChangeData}
                style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="ได้">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.care_yourself_at_home === "ไม่ได้"}
                label="ไม่ได้ โปรดระบุ"
                name="care_yourself_at_home"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                value="ไม่ได้">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.care_yourself_at_home !== "ไม่ได้" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.care_yourself_at_home !== "ไม่ได้"}
                name="care_yourself_at_home_remark"
                onChange={props.onChangeData}
                style={{ width: "20%" }}
                value={props.PreAssessmentSequence?.care_yourself_at_home === "ไม่ได้" ? props.PreAssessmentSequence?.care_yourself_at_home_remark || "" : ""}>
              </Input>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          11. ความเชื่อและค่านิยม
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "19%", fontWeight: "bold",marginRight: "10px" }}>
              
              <div>
                การเจ็บป่วยครั้งนี้เชื่อว่ามีสาเหตุจาก :
              </div>
            </div>
            <div
              style={{ width: "81%", display: "flex"}}>
              
              <Checkbox
                checked={props.PreAssessmentSequence?.cause_health }
                label="ดูแลสุขภาพไม่ถูกต้อง"
                name="cause_health"
                onChange={props.onChangeData}
                style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.cause_age}
                label="ตามวัย"
                name="cause_age"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.cause_destiny}
                label="เคราะห์กรรม"
                name="cause_destiny"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.cause_other}
                label="อื่นๆ โปรดระบุ"
                name="cause_other"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <div
                style={{ display: !props.PreAssessmentSequence?.cause_other ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={!props.PreAssessmentSequence?.cause_other}
                name="cause_other_remark"
                onChange={props.onChangeData}
                value={props.PreAssessmentSequence?.cause_other ? props.PreAssessmentSequence?.cause_other_remark || "" : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "19%", fontWeight: "bold",marginRight: "10px" }}>
              
              <div>
                สิ่งยึดเหนี่ยวในขณะเจ็บป่วย :
              </div>
            </div>
            <div
              style={{ width: "81%", display: "flex"}}>
              
              <Checkbox
                checked={props.PreAssessmentSequence?.support_family}
                label="ครอบครัว"
                name="support_family"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.support_religion}
                label="ศาสนา"
                name="support_religion"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <Checkbox
                checked={props.PreAssessmentSequence?.support_other}
                label="อื่นๆ โปรดระบุ"
                name="support_other"
                onChange={props.onChangeData}
                style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}>
              </Checkbox>
              <div
                style={{ display: !props.PreAssessmentSequence?.support_other ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                
              </div>
              <Input
                disabled={!props.PreAssessmentSequence?.support_other}
                name="support_other_remark"
                onChange={props.onChangeData}
                value={props.PreAssessmentSequence?.support_other ? props.PreAssessmentSequence?.support_other_remark || "" : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "19%", fontWeight: "bold",marginRight: "10px" }}>
              
              <div>
                ต้องการปฏิบัติทางศาสนา :
              </div>
            </div>
            <div
              style={{ width: "81%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.doing_religion === "ไม่ต้องการ"}
                label="ไม่ต้องการ"
                name="doing_religion"
                onChange={props.onChangeData}
                style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="ไม่ต้องการ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.doing_religion === "ต้องการ"}
                label="ต้องการ โปรดระบุ"
                name="doing_religion"
                onChange={props.onChangeData}
                style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}
                value="ต้องการ">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.doing_religion !== "ต้องการ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.doing_religion !== "ต้องการ"}
                name="doing_religion_remark"
                onChange={props.onChangeData}
                style={{ width: "20%"}}
                value={props.PreAssessmentSequence?.doing_religion === "ต้องการ" ? props.PreAssessmentSequence?.doing_religion_remark || "" : ""}>
              </Input>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          12. การประเมินความเสี่ยงต่อการพลัดตกหกล้ม
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <Form>
            <FormGroup
              inline={true}
              style={{ display: "flex", alignItems: "center", margin: "10px", }}>
              <FormField
                inline={true}
                width={2}>
                <Radio
                  checked={props.PreAssessmentSequence?.risk_fall === "ไม่มีความเสี่ยง"}
                  label="ไม่มีความเสี่ยง"
                  name="risk_fall"
                  onChange={props.onChangeData}
                  style={{  marginRight: "2%", display: "flex", alignItems: "center"  }}
                  value="ไม่มีความเสี่ยง">
                </Radio>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Radio
                  checked={props.PreAssessmentSequence?.risk_fall === "มีความเสี่ยง"}
                  label="มีความเสี่ยง โปรดระบุ"
                  name="risk_fall"
                  onChange={props.onChangeData}
                  style={{  marginRight: "2%",display: "flex", alignItems: "center"  }}
                  value="มีความเสี่ยง">
                </Radio>
                <div
                  style={{ color: "red" }}>
                  *
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Dropdown
                  clearable={true}
                  disabled={props.PreAssessmentSequence?.risk_fall !== "มีความเสี่ยง"}
                  name="risk_fall_remark"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.painRiskFallOptions}
                  selection={true}
                  style={{ width: "100%" }}
                  value={props.PreAssessmentSequence?.risk_fall === "มีความเสี่ยง" ? props.PreAssessmentSequence?.risk_fall_remark || "" : ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "flex", alignItems: "center", margin: "10px", }}>
              <FormField
                inline={true}
                style={{ display: "flex", alignItems: "center"}}>
                <label
                  style={{ fontWeight: "bold", marginRight: "30px" }}>
                  การให้การพยาบาลผู้ป่วย :
                </label>
              </FormField>
              <FormField
                width={7}>
                <Dropdown
                  clearable={true}
                  multiple={true}
                  name="nursing_care"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.painNursingCareOptions}
                  selection={true}
                  style={{ width: "700px" }}
                  value={props.PreAssessmentSequence?.nursing_care}>
                </Dropdown>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default CardPreAssessmentHealthUX

export const screenPropsDefault = {}

/* Date Time : Tue Jan 07 2025 10:57:32 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 709,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "1. การรับรู้และการดูแลสุขภาพ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 710,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 711,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 711,
      "void": false
    },
    {
      "from": null,
      "id": 712,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 712,
      "void": false
    },
    {
      "from": null,
      "id": 719,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"15%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 719,
      "void": false
    },
    {
      "from": null,
      "id": 720,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"85%\", display: \"flex\"}"
        }
      },
      "seq": 720,
      "void": false
    },
    {
      "from": null,
      "id": 721,
      "name": "div",
      "parent": 719,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ให้ข้อมูล :"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 721,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 722,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_given_by=== \"ผู้ป่วย\""
        },
        "label": {
          "type": "value",
          "value": "ผู้ป่วย"
        },
        "name": {
          "type": "value",
          "value": "health_given_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"8%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ผู้ป่วย"
        }
      },
      "seq": 722,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 723,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_given_by=== \"บิดา/มารดา\""
        },
        "label": {
          "type": "value",
          "value": "บิดา/มารดา"
        },
        "name": {
          "type": "value",
          "value": "health_given_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บิดา/มารดา"
        }
      },
      "seq": 723,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 724,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_given_by=== \"สามี/ภรรยา\""
        },
        "label": {
          "type": "value",
          "value": "สามี/ภรรยา"
        },
        "name": {
          "type": "value",
          "value": "health_given_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "สามี/ภรรยา"
        }
      },
      "seq": 724,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 726,
      "name": "Input",
      "parent": 720,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_given_by !== \"อื่นๆ\""
        },
        "name": {
          "type": "value",
          "value": "health_given_by_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_given_by === \"อื่นๆ\"  ? props.PreAssessmentSequence?.health_given_by_remark || \"\"   : \"\""
        }
      },
      "seq": 895,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 727,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_given_by=== \"อื่นๆ\""
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "health_given_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"2%\", display: \"flex\", alignItems: \"center\" }"
        },
        "value": {
          "type": "value",
          "value": "อื่นๆ"
        }
      },
      "seq": 726,
      "void": false
    },
    {
      "from": null,
      "id": 728,
      "name": "div",
      "parent": 712,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"15%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 728,
      "void": false
    },
    {
      "from": null,
      "id": 729,
      "name": "div",
      "parent": 728,
      "props": {
        "children": {
          "type": "value",
          "value": "สุขภาพก่อนการเจ็บป่วย :"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 729,
      "void": false
    },
    {
      "from": null,
      "id": 731,
      "name": "div",
      "parent": 712,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"85%\", display: \"flex\"}"
        }
      },
      "seq": 731,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 732,
      "name": "Radio",
      "parent": 731,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_before_illness === \"ดี\""
        },
        "label": {
          "type": "value",
          "value": "ดี"
        },
        "name": {
          "type": "value",
          "value": "health_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"8%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ดี"
        }
      },
      "seq": 732,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 735,
      "name": "Radio",
      "parent": 731,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_before_illness === \"ไม่ดี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ดี โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "health_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\" }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ดี"
        }
      },
      "seq": 735,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 736,
      "name": "Input",
      "parent": 731,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_before_illness !== \"ไม่ดี\""
        },
        "name": {
          "type": "value",
          "value": "health_before_illness_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_before_illness === \"ไม่ดี\"  ? props.PreAssessmentSequence?.health_before_illness_remark || \"\"   : \"\""
        }
      },
      "seq": 906,
      "void": false
    },
    {
      "from": null,
      "id": 737,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 737,
      "void": false
    },
    {
      "from": null,
      "id": 738,
      "name": "div",
      "parent": 737,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"15%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 738,
      "void": false
    },
    {
      "from": null,
      "id": 739,
      "name": "div",
      "parent": 737,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"85%\", display: \"flex\"}"
        }
      },
      "seq": 739,
      "void": false
    },
    {
      "from": null,
      "id": 740,
      "name": "div",
      "parent": 738,
      "props": {
        "children": {
          "type": "value",
          "value": "การเจ็บป่วยครั้งนี้ :"
        }
      },
      "seq": 740,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 741,
      "name": "Radio",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_present_illness === \"รุนแรง\""
        },
        "label": {
          "type": "value",
          "value": "รุนแรง"
        },
        "name": {
          "type": "value",
          "value": "health_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width:\"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "รุนแรง"
        }
      },
      "seq": 741,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 744,
      "name": "Radio",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_present_illness === \"ไม่รุนแรง\""
        },
        "label": {
          "type": "value",
          "value": "ไม่รุนแรง"
        },
        "name": {
          "type": "value",
          "value": "health_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่รุนแรง"
        }
      },
      "seq": 744,
      "void": false
    },
    {
      "from": null,
      "id": 746,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 747,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "2. โภชนาการและการเผาผลาญ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 748,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 748,
      "void": false
    },
    {
      "from": null,
      "id": 749,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 749,
      "void": false
    },
    {
      "from": null,
      "id": 750,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 750,
      "void": false
    },
    {
      "from": null,
      "id": 755,
      "name": "div",
      "parent": 748,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"18%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 755,
      "void": false
    },
    {
      "from": null,
      "id": 756,
      "name": "div",
      "parent": 748,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"82%\", display: \"flex\"}"
        }
      },
      "seq": 756,
      "void": false
    },
    {
      "from": null,
      "id": 757,
      "name": "div",
      "parent": 755,
      "props": {
        "children": {
          "type": "value",
          "value": "รับประทานอาหารจำนวน (มื้อ/วัน) :"
        }
      },
      "seq": 757,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 758,
      "name": "Radio",
      "parent": 756,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.meal === \"อาหารอ่อน\""
        },
        "label": {
          "type": "value",
          "value": "อาหารอ่อน"
        },
        "name": {
          "type": "value",
          "value": "meal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "อาหารอ่อน"
        }
      },
      "seq": 907,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 759,
      "name": "Radio",
      "parent": 756,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.meal === \"อาหารธรรมดา\""
        },
        "label": {
          "type": "value",
          "value": "อาหารธรรมดา"
        },
        "name": {
          "type": "value",
          "value": "meal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "อาหารธรรมดา"
        }
      },
      "seq": 908,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 760,
      "name": "Input",
      "parent": 756,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.meal !== \"อาหารเฉพาะโรค\""
        },
        "name": {
          "type": "value",
          "value": "meal_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"30px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.meal === \"อาหารเฉพาะโรค\"  ? props.PreAssessmentSequence?.meal_remark || \"\"   : \"\""
        }
      },
      "seq": 1299,
      "void": false
    },
    {
      "from": null,
      "id": 761,
      "name": "div",
      "parent": 749,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\" }"
        }
      },
      "seq": 761,
      "void": false
    },
    {
      "from": null,
      "id": 762,
      "name": "div",
      "parent": 749,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\"}"
        }
      },
      "seq": 762,
      "void": false
    },
    {
      "from": null,
      "id": 763,
      "name": "div",
      "parent": 761,
      "props": {
        "children": {
          "type": "value",
          "value": "อาหารที่งด ระบุ :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"18%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 763,
      "void": false
    },
    {
      "from": null,
      "id": 765,
      "name": "div",
      "parent": 762,
      "props": {
        "children": {
          "type": "value",
          "value": "ปริมาณน้ำดื่มในแต่ละวัน :"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\",display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 765,
      "void": false
    },
    {
      "from": null,
      "id": 772,
      "name": "div",
      "parent": 750,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"16%\", display: \"flex\" ,marginRight:\"15px\"}"
        }
      },
      "seq": 772,
      "void": false
    },
    {
      "from": null,
      "id": 773,
      "name": "div",
      "parent": 750,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"84%\", display: \"flex\"}"
        }
      },
      "seq": 773,
      "void": false
    },
    {
      "from": null,
      "id": 774,
      "name": "div",
      "parent": 772,
      "props": {
        "children": {
          "type": "value",
          "value": "ปัญหาในการรับประทานอาหาร :"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 774,
      "void": false
    },
    {
      "from": null,
      "id": 817,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 818,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "3. การขับถ่าย"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 820,
      "name": "div",
      "parent": 817,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 820,
      "void": false
    },
    {
      "from": null,
      "id": 822,
      "name": "div",
      "parent": 820,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 822,
      "void": false
    },
    {
      "from": null,
      "id": 831,
      "name": "div",
      "parent": 822,
      "props": {
        "children": {
          "type": "value",
          "value": "ถ่ายปัสสาวะ :"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"15px\" }"
        }
      },
      "seq": 831,
      "void": false
    },
    {
      "from": null,
      "id": 853,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "4. กิจวัตรประจำวันและการออกกำลังกาย"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": null,
      "id": 854,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": null,
      "id": 855,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 867,
      "void": false
    },
    {
      "from": null,
      "id": 856,
      "name": "div",
      "parent": 855,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 856,
      "void": false
    },
    {
      "from": null,
      "id": 857,
      "name": "div",
      "parent": 855,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\", display: \"flex\"}"
        }
      },
      "seq": 857,
      "void": false
    },
    {
      "from": null,
      "id": 858,
      "name": "div",
      "parent": 856,
      "props": {
        "children": {
          "type": "value",
          "value": "2. การทำความสะอาดปากและฟัน"
        }
      },
      "seq": 858,
      "void": false
    },
    {
      "from": null,
      "id": 863,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 868,
      "void": false
    },
    {
      "from": null,
      "id": 864,
      "name": "div",
      "parent": 863,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 864,
      "void": false
    },
    {
      "from": null,
      "id": 865,
      "name": "div",
      "parent": 863,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\", display: \"flex\"}"
        }
      },
      "seq": 865,
      "void": false
    },
    {
      "from": null,
      "id": 866,
      "name": "div",
      "parent": 864,
      "props": {
        "children": {
          "type": "value",
          "value": "3. การแต่งตัว"
        }
      },
      "seq": 866,
      "void": false
    },
    {
      "from": null,
      "id": 867,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 959,
      "void": false
    },
    {
      "from": null,
      "id": 868,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 960,
      "void": false
    },
    {
      "from": null,
      "id": 869,
      "name": "div",
      "parent": 867,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 869,
      "void": false
    },
    {
      "from": null,
      "id": 870,
      "name": "div",
      "parent": 867,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\", display: \"flex\"}"
        }
      },
      "seq": 870,
      "void": false
    },
    {
      "from": null,
      "id": 871,
      "name": "div",
      "parent": 869,
      "props": {
        "children": {
          "type": "value",
          "value": "4. การเดิน"
        }
      },
      "seq": 871,
      "void": false
    },
    {
      "from": null,
      "id": 872,
      "name": "div",
      "parent": 868,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 872,
      "void": false
    },
    {
      "from": null,
      "id": 873,
      "name": "div",
      "parent": 868,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\", display: \"flex\"}"
        }
      },
      "seq": 873,
      "void": false
    },
    {
      "from": null,
      "id": 874,
      "name": "div",
      "parent": 872,
      "props": {
        "children": {
          "type": "value",
          "value": "5. การขับถ่าย"
        }
      },
      "seq": 874,
      "void": false
    },
    {
      "from": null,
      "id": 887,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 887,
      "void": false
    },
    {
      "from": null,
      "id": 888,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 888,
      "void": false
    },
    {
      "from": null,
      "id": 889,
      "name": "div",
      "parent": 887,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"15%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 889,
      "void": false
    },
    {
      "from": null,
      "id": 890,
      "name": "div",
      "parent": 887,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"85%\", display: \"flex\"}"
        }
      },
      "seq": 890,
      "void": false
    },
    {
      "from": null,
      "id": 891,
      "name": "div",
      "parent": 889,
      "props": {
        "children": {
          "type": "value",
          "value": "การดูแลสุขภาพเมื่อเจ็บป่วย :"
        }
      },
      "seq": 891,
      "void": false
    },
    {
      "from": null,
      "id": 892,
      "name": "div",
      "parent": 888,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"21%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 892,
      "void": false
    },
    {
      "from": null,
      "id": 893,
      "name": "div",
      "parent": 888,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"85%\", display: \"flex\"}"
        }
      },
      "seq": 893,
      "void": false
    },
    {
      "from": null,
      "id": 894,
      "name": "div",
      "parent": 892,
      "props": {
        "children": {
          "type": "value",
          "value": "ความคาดหวังในการรักษาครั้งนี้ คิดว่า :"
        }
      },
      "seq": 894,
      "void": false
    },
    {
      "from": null,
      "id": 895,
      "name": "div",
      "parent": 720,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.health_given_by !== \"อื่นๆ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 727,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 896,
      "name": "Radio",
      "parent": 890,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_care === \"ไปสถานพยาบาล\""
        },
        "label": {
          "type": "value",
          "value": "ไปสถานพยาบาล"
        },
        "name": {
          "type": "value",
          "value": "health_care"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไปสถานพยาบาล"
        }
      },
      "seq": 896,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 897,
      "name": "Radio",
      "parent": 890,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_care === \"ซื้อยารับประทานเอง\""
        },
        "label": {
          "type": "value",
          "value": "ซื้อยารับประทานเอง"
        },
        "name": {
          "type": "value",
          "value": "health_care"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ซื้อยารับประทานเอง"
        }
      },
      "seq": 897,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 898,
      "name": "Radio",
      "parent": 890,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_care === \"อื่นๆ\""
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "health_care"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\" }"
        },
        "value": {
          "type": "value",
          "value": "อื่นๆ"
        }
      },
      "seq": 898,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 899,
      "name": "Radio",
      "parent": 893,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.expectation === \"หาย\""
        },
        "label": {
          "type": "value",
          "value": "หาย"
        },
        "name": {
          "type": "value",
          "value": "expectation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "หาย"
        }
      },
      "seq": 899,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 900,
      "name": "Radio",
      "parent": 893,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.expectation === \"ไม่แน่ใจ\""
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "ไม่แน่ใจ"
        },
        "name": {
          "type": "value",
          "value": "expectation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่แน่ใจ"
        }
      },
      "seq": 900,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 901,
      "name": "Radio",
      "parent": 893,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.expectation === \"ไม่หาย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่หาย"
        },
        "name": {
          "type": "value",
          "value": "expectation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่หาย"
        }
      },
      "seq": 901,
      "void": false
    },
    {
      "from": null,
      "id": 904,
      "name": "div",
      "parent": 890,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.health_care !== \"อื่นๆ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 904,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 905,
      "name": "Input",
      "parent": 890,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_care !== \"อื่นๆ\""
        },
        "name": {
          "type": "value",
          "value": "health_care_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health_care === \"อื่นๆ\"  ? props.PreAssessmentSequence?.health_care_remark || \"\"   : \"\""
        }
      },
      "seq": 905,
      "void": false
    },
    {
      "from": null,
      "id": 906,
      "name": "div",
      "parent": 731,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.health_before_illness !== \"ไม่ดี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 736,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 908,
      "name": "Radio",
      "parent": 756,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.meal === \"อาหารทางสายยาง\""
        },
        "label": {
          "type": "value",
          "value": "อาหารทางสายยาง"
        },
        "name": {
          "type": "value",
          "value": "meal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "อาหารทางสายยาง"
        }
      },
      "seq": 909,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 909,
      "name": "Radio",
      "parent": 756,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.meal === \"อาหารเฉพาะโรค\""
        },
        "label": {
          "type": "value",
          "value": "อาหารเฉพาะโรค โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "meal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "อาหารเฉพาะโรค"
        }
      },
      "seq": 910,
      "void": false
    },
    {
      "from": null,
      "id": 910,
      "name": "div",
      "parent": 756,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.meal !== \"อาหารเฉพาะโรค\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1298,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 911,
      "name": "Input",
      "parent": 761,
      "props": {
        "name": {
          "type": "value",
          "value": "abstinence_food"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.abstinence_food || \"\""
        }
      },
      "seq": 911,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 912,
      "name": "Input",
      "parent": 762,
      "props": {
        "name": {
          "type": "value",
          "value": "amount_water"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.amount_water || \"\""
        }
      },
      "seq": 912,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 921,
      "name": "Radio",
      "parent": 773,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_problem === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "eating_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 913,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 922,
      "name": "Radio",
      "parent": 773,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_problem === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "eating_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 914,
      "void": false
    },
    {
      "from": null,
      "id": 923,
      "name": "div",
      "parent": 773,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.eating_problem === \"มี\" ? \"flex\" : \"none\" , width: \"100%\" }"
        }
      },
      "seq": 915,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 924,
      "name": "Checkbox",
      "parent": 923,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_problem_swallowing"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "การกลืน"
        },
        "name": {
          "type": "value",
          "value": "eating_problem_swallowing"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 924,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 925,
      "name": "Checkbox",
      "parent": 923,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_problem_chewing"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "การเคี้ยว"
        },
        "name": {
          "type": "value",
          "value": "eating_problem_chewing"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 925,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 926,
      "name": "Checkbox",
      "parent": 923,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_problem_bored"
        },
        "label": {
          "type": "value",
          "value": "เบื่ออาหาร"
        },
        "name": {
          "type": "value",
          "value": "eating_problem_bored"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 926,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 927,
      "name": "Checkbox",
      "parent": 923,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_problem_squeamish"
        },
        "label": {
          "type": "value",
          "value": "คลื่นไส้"
        },
        "name": {
          "type": "value",
          "value": "eating_problem_squeamish"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 927,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 928,
      "name": "Checkbox",
      "parent": 923,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_problem_vomit"
        },
        "label": {
          "type": "value",
          "value": "อาเจียน"
        },
        "name": {
          "type": "value",
          "value": "eating_problem_vomit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 928,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 929,
      "name": "Checkbox",
      "parent": 923,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_problem_other"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "eating_problem_other"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"12%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 929,
      "void": false
    },
    {
      "from": null,
      "id": 930,
      "name": "div",
      "parent": 923,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.PreAssessmentSequence?.eating_problem_other ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 930,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 931,
      "name": "Input",
      "parent": 923,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAssessmentSequence?.eating_problem_other"
        },
        "name": {
          "type": "value",
          "value": "eating_problem_other_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_problem_other ? props.PreAssessmentSequence?.eating_problem_other_remark || \"\" : \"\""
        }
      },
      "seq": 931,
      "void": false
    },
    {
      "from": null,
      "id": 932,
      "name": "div",
      "parent": 817,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 932,
      "void": false
    },
    {
      "from": null,
      "id": 933,
      "name": "div",
      "parent": 932,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 933,
      "void": false
    },
    {
      "from": null,
      "id": 934,
      "name": "div",
      "parent": 932,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 934,
      "void": false
    },
    {
      "from": null,
      "id": 935,
      "name": "div",
      "parent": 817,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 935,
      "void": false
    },
    {
      "from": null,
      "id": 936,
      "name": "div",
      "parent": 935,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"17%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 936,
      "void": false
    },
    {
      "from": null,
      "id": 937,
      "name": "div",
      "parent": 935,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 937,
      "void": false
    },
    {
      "from": null,
      "id": 941,
      "name": "div",
      "parent": 820,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 941,
      "void": false
    },
    {
      "from": null,
      "id": 942,
      "name": "div",
      "parent": 933,
      "props": {
        "children": {
          "type": "value",
          "value": "ถ่ายอุจจาระ :"
        }
      },
      "seq": 942,
      "void": false
    },
    {
      "from": null,
      "id": 943,
      "name": "div",
      "parent": 936,
      "props": {
        "children": {
          "type": "value",
          "value": "การขับถ่ายทางหน้าท้อง :"
        }
      },
      "seq": 943,
      "void": false
    },
    {
      "from": null,
      "id": 944,
      "name": "div",
      "parent": 941,
      "props": {
        "children": {
          "type": "value",
          "value": "ครั้ง/วัน"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"20px\", alignItems: \"center\", display: \"flex\" }"
        }
      },
      "seq": 946,
      "void": false
    },
    {
      "from": null,
      "id": 946,
      "name": "div",
      "parent": 941,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.urination_problem !== \"ไม่ปกติ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 949,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 947,
      "name": "Radio",
      "parent": 941,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.urination_problem === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "urination_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 947,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 948,
      "name": "Radio",
      "parent": 941,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.urination_problem === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "urination_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 948,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 949,
      "name": "Input",
      "parent": 941,
      "props": {
        "name": {
          "type": "value",
          "value": "urination_per_day"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyDown"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", marginRight: \"20px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.urination_per_day"
        }
      },
      "seq": 944,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 950,
      "name": "Input",
      "parent": 941,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.urination_problem !== \"ไม่ปกติ\" "
        },
        "name": {
          "type": "value",
          "value": "urination_problem_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.urination_problem === \"ไม่ปกติ\" ? props.PreAssessmentSequence?.urination_problem_remark || \"\" : \"\""
        }
      },
      "seq": 950,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 951,
      "name": "Input",
      "parent": 934,
      "props": {
        "name": {
          "type": "value",
          "value": "defecation_per_day"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyDown"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", marginRight: \"20px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecation_per_day"
        }
      },
      "seq": 951,
      "void": false
    },
    {
      "from": null,
      "id": 952,
      "name": "div",
      "parent": 934,
      "props": {
        "children": {
          "type": "value",
          "value": "ครั้ง/วัน"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"20px\", alignItems: \"center\", display: \"flex\" }"
        }
      },
      "seq": 952,
      "void": false
    },
    {
      "from": null,
      "id": 953,
      "name": "div",
      "parent": 934,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.defecation_problem !== \"ไม่ปกติ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 955,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 954,
      "name": "Radio",
      "parent": 934,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecation_problem === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "defecation_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 953,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 955,
      "name": "Radio",
      "parent": 934,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecation_problem === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "defecation_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 954,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 956,
      "name": "Input",
      "parent": 934,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecation_problem !== \"ไม่ปกติ\" "
        },
        "name": {
          "type": "value",
          "value": "defecation_problem_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecation_problem === \"ไม่ปกติ\" ? props.PreAssessmentSequence?.defecation_problem_remark || \"\" : \"\""
        }
      },
      "seq": 956,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 957,
      "name": "Radio",
      "parent": 937,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.stomach_defecation === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "stomach_defecation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"none\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 957,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 958,
      "name": "Radio",
      "parent": 937,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.stomach_defecation === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี"
        },
        "name": {
          "type": "value",
          "value": "stomach_defecation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"none\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 958,
      "void": false
    },
    {
      "from": null,
      "id": 959,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 855,
      "void": false
    },
    {
      "from": null,
      "id": 960,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 863,
      "void": false
    },
    {
      "from": null,
      "id": 961,
      "name": "div",
      "parent": 959,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 961,
      "void": false
    },
    {
      "from": null,
      "id": 962,
      "name": "div",
      "parent": 959,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\", display: \"flex\"}"
        }
      },
      "seq": 962,
      "void": false
    },
    {
      "from": null,
      "id": 963,
      "name": "div",
      "parent": 960,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 963,
      "void": false
    },
    {
      "from": null,
      "id": 964,
      "name": "div",
      "parent": 960,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\", display: \"flex\"}"
        }
      },
      "seq": 964,
      "void": false
    },
    {
      "from": null,
      "id": 965,
      "name": "div",
      "parent": 963,
      "props": {
        "children": {
          "type": "value",
          "value": "1. การรับประทานอาหาร"
        }
      },
      "seq": 965,
      "void": false
    },
    {
      "from": null,
      "id": 966,
      "name": "div",
      "parent": 962,
      "props": {
        "children": {
          "type": "value",
          "value": "ก่อนการเจ็บป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", fontWeight: \"bold\", background: \"#F9D3E5\" }"
        }
      },
      "seq": 966,
      "void": false
    },
    {
      "from": null,
      "id": 967,
      "name": "div",
      "parent": 962,
      "props": {
        "children": {
          "type": "value",
          "value": "ขณะเจ็บป่วยครั้งนี้"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", fontWeight: \"bold\", background: \"#F5B793\" }"
        }
      },
      "seq": 967,
      "void": false
    },
    {
      "from": null,
      "id": 968,
      "name": "div",
      "parent": 964,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 968,
      "void": false
    },
    {
      "from": null,
      "id": 969,
      "name": "div",
      "parent": 964,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 969,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 970,
      "name": "Radio",
      "parent": 968,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_before_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "eating_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 970,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 971,
      "name": "Radio",
      "parent": 968,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_before_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "eating_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 971,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 972,
      "name": "Radio",
      "parent": 968,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_before_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "eating_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 972,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 973,
      "name": "Radio",
      "parent": 968,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_before_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "eating_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 973,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 974,
      "name": "Radio",
      "parent": 969,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_present_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "eating_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 974,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 975,
      "name": "Radio",
      "parent": 969,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_present_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "eating_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 975,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 976,
      "name": "Radio",
      "parent": 969,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_present_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "eating_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 976,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 977,
      "name": "Radio",
      "parent": 969,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.eating_present_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "eating_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 977,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 978,
      "name": "Radio",
      "parent": 974,
      "props": {
      },
      "seq": 978,
      "void": false
    },
    {
      "from": null,
      "id": 979,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 979,
      "void": false
    },
    {
      "from": null,
      "id": 980,
      "name": "div",
      "parent": 979,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 980,
      "void": false
    },
    {
      "from": null,
      "id": 981,
      "name": "div",
      "parent": 979,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\", display: \"flex\"}"
        }
      },
      "seq": 981,
      "void": false
    },
    {
      "from": null,
      "id": 982,
      "name": "div",
      "parent": 980,
      "props": {
        "children": {
          "type": "value",
          "value": "6. การอาบน้ำ"
        }
      },
      "seq": 982,
      "void": false
    },
    {
      "from": null,
      "id": 983,
      "name": "div",
      "parent": 857,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 983,
      "void": false
    },
    {
      "from": null,
      "id": 984,
      "name": "div",
      "parent": 857,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 984,
      "void": false
    },
    {
      "from": null,
      "id": 985,
      "name": "div",
      "parent": 865,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 985,
      "void": false
    },
    {
      "from": null,
      "id": 986,
      "name": "div",
      "parent": 865,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 986,
      "void": false
    },
    {
      "from": null,
      "id": 987,
      "name": "div",
      "parent": 870,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 987,
      "void": false
    },
    {
      "from": null,
      "id": 988,
      "name": "div",
      "parent": 870,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 988,
      "void": false
    },
    {
      "from": null,
      "id": 989,
      "name": "div",
      "parent": 873,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 989,
      "void": false
    },
    {
      "from": null,
      "id": 990,
      "name": "div",
      "parent": 873,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 990,
      "void": false
    },
    {
      "from": null,
      "id": 991,
      "name": "div",
      "parent": 981,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 991,
      "void": false
    },
    {
      "from": null,
      "id": 992,
      "name": "div",
      "parent": 981,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 992,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 994,
      "name": "Radio",
      "parent": 983,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cleaning_mounth_and_teeth_before_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "cleaning_mounth_and_teeth_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 994,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 995,
      "name": "Radio",
      "parent": 983,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cleaning_mounth_and_teeth_before_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "cleaning_mounth_and_teeth_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 995,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 996,
      "name": "Radio",
      "parent": 983,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cleaning_mounth_and_teeth_before_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "cleaning_mounth_and_teeth_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 996,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 997,
      "name": "Radio",
      "parent": 983,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cleaning_mounth_and_teeth_before_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "cleaning_mounth_and_teeth_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "toggle": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 997,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 998,
      "name": "Radio",
      "parent": 985,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.dressing_before_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "dressing_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 998,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 999,
      "name": "Radio",
      "parent": 985,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.dressing_before_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "dressing_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 999,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1000,
      "name": "Radio",
      "parent": 985,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.dressing_before_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "dressing_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 1000,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1001,
      "name": "Radio",
      "parent": 985,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.dressing_before_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "dressing_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 1001,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1002,
      "name": "Radio",
      "parent": 987,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.walking_before_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "walking_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 1002,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1003,
      "name": "Radio",
      "parent": 987,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.walking_before_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "walking_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 1003,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1004,
      "name": "Radio",
      "parent": 987,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.walking_before_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "walking_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 1004,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1005,
      "name": "Radio",
      "parent": 987,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.walking_before_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "walking_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 1005,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1006,
      "name": "Radio",
      "parent": 989,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecating_before_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "defecating_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 1006,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1007,
      "name": "Radio",
      "parent": 989,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecating_before_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "defecating_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 1007,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1008,
      "name": "Radio",
      "parent": 989,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecating_before_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "defecating_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 1008,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1009,
      "name": "Radio",
      "parent": 989,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecating_before_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "defecating_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 1009,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1010,
      "name": "Radio",
      "parent": 991,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.bathing_before_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "bathing_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 1010,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1011,
      "name": "Radio",
      "parent": 991,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.bathing_before_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "bathing_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 1011,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1012,
      "name": "Radio",
      "parent": 991,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.bathing_before_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "bathing_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 1012,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1013,
      "name": "Radio",
      "parent": 991,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.bathing_before_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "bathing_before_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 1013,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1014,
      "name": "Radio",
      "parent": 984,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cleaning_mounth_and_teeth_present_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "cleaning_mounth_and_teeth_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 1014,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1015,
      "name": "Radio",
      "parent": 984,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cleaning_mounth_and_teeth_present_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "cleaning_mounth_and_teeth_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 1015,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1016,
      "name": "Radio",
      "parent": 984,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cleaning_mounth_and_teeth_present_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "cleaning_mounth_and_teeth_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 1016,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1017,
      "name": "Radio",
      "parent": 984,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cleaning_mounth_and_teeth_present_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "cleaning_mounth_and_teeth_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 1017,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1018,
      "name": "Radio",
      "parent": 986,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.dressing_after_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "dressing_after_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 1018,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1019,
      "name": "Radio",
      "parent": 986,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.dressing_after_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "dressing_after_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 1019,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1020,
      "name": "Radio",
      "parent": 986,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.dressing_after_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "dressing_after_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 1020,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1021,
      "name": "Radio",
      "parent": 986,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.dressing_after_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "dressing_after_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 1021,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1022,
      "name": "Radio",
      "parent": 988,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.walking_present_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "walking_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 1022,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1023,
      "name": "Radio",
      "parent": 988,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.walking_present_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "walking_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 1023,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1024,
      "name": "Radio",
      "parent": 988,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.walking_present_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "walking_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 1024,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1025,
      "name": "Radio",
      "parent": 988,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.walking_present_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "walking_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 1025,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1026,
      "name": "Radio",
      "parent": 990,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecating_present_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "defecating_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 1026,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1027,
      "name": "Radio",
      "parent": 990,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecating_present_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "defecating_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 1027,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1028,
      "name": "Radio",
      "parent": 990,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecating_present_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "defecating_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 1028,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1029,
      "name": "Radio",
      "parent": 990,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.defecating_present_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "defecating_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 1029,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1030,
      "name": "Radio",
      "parent": 992,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.bathing_present_illness === \"ทำได้เอง\""
        },
        "label": {
          "type": "value",
          "value": "ทำได้เอง"
        },
        "name": {
          "type": "value",
          "value": "bathing_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ทำได้เอง"
        }
      },
      "seq": 1030,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1031,
      "name": "Radio",
      "parent": 992,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.bathing_present_illness === \"บางส่วน\""
        },
        "label": {
          "type": "value",
          "value": "บางส่วน"
        },
        "name": {
          "type": "value",
          "value": "bathing_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "บางส่วน"
        }
      },
      "seq": 1031,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1032,
      "name": "Radio",
      "parent": 992,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.bathing_present_illness === \"ไม่ได้เลย\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้เลย"
        },
        "name": {
          "type": "value",
          "value": "bathing_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้เลย"
        }
      },
      "seq": 1032,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1033,
      "name": "Radio",
      "parent": 992,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.bathing_present_illness === \"ใช้อุปกรณ์\""
        },
        "label": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        },
        "name": {
          "type": "value",
          "value": "bathing_present_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้อุปกรณ์"
        }
      },
      "seq": 1033,
      "void": false
    },
    {
      "from": null,
      "id": 1034,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 574,
      "void": false
    },
    {
      "from": null,
      "id": 1035,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "5. การพักผ่อนนอนหลับ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 571,
      "void": false
    },
    {
      "from": null,
      "id": 1036,
      "name": "div",
      "parent": 1034,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1036,
      "void": false
    },
    {
      "from": null,
      "id": 1037,
      "name": "div",
      "parent": 1034,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1037,
      "void": false
    },
    {
      "from": null,
      "id": 1038,
      "name": "div",
      "parent": 1034,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1038,
      "void": false
    },
    {
      "from": null,
      "id": 1039,
      "name": "div",
      "parent": 1036,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"4%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1039,
      "void": false
    },
    {
      "from": null,
      "id": 1040,
      "name": "div",
      "parent": 1036,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"96%\", display: \"flex\"}"
        }
      },
      "seq": 1040,
      "void": false
    },
    {
      "from": null,
      "id": 1041,
      "name": "div",
      "parent": 1037,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 1041,
      "void": false
    },
    {
      "from": null,
      "id": 1042,
      "name": "div",
      "parent": 1037,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1042,
      "void": false
    },
    {
      "from": null,
      "id": 1043,
      "name": "div",
      "parent": 1038,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", fontWeight: \"bold\" ,marginRight: \"10px\"}"
        }
      },
      "seq": 1043,
      "void": false
    },
    {
      "from": null,
      "id": 1044,
      "name": "div",
      "parent": 1038,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1044,
      "void": false
    },
    {
      "from": null,
      "id": 1045,
      "name": "div",
      "parent": 1039,
      "props": {
        "children": {
          "type": "value",
          "value": "นอน :"
        }
      },
      "seq": 1045,
      "void": false
    },
    {
      "from": null,
      "id": 1046,
      "name": "div",
      "parent": 1041,
      "props": {
        "children": {
          "type": "value",
          "value": "ปัญหาการนอน :"
        }
      },
      "seq": 1046,
      "void": false
    },
    {
      "from": null,
      "id": 1047,
      "name": "div",
      "parent": 1043,
      "props": {
        "children": {
          "type": "value",
          "value": "การใช้ยานอนหลับ :"
        }
      },
      "seq": 1047,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1048,
      "name": "Input",
      "parent": 1040,
      "props": {
        "name": {
          "type": "value",
          "value": "sleeping_per_day"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyDown"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"5%\", marginRight: \"20px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleeping_per_day"
        }
      },
      "seq": 1048,
      "void": false
    },
    {
      "from": null,
      "id": 1049,
      "name": "div",
      "parent": 1040,
      "props": {
        "children": {
          "type": "value",
          "value": "ชม./วัน"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"20px\", alignItems: \"center\", display: \"flex\" }"
        }
      },
      "seq": 1049,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1050,
      "name": "Radio",
      "parent": 1040,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleeping_enough === \"เพียงพอ\""
        },
        "label": {
          "type": "value",
          "value": "เพียงพอ"
        },
        "name": {
          "type": "value",
          "value": "sleeping_enough"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "เพียงพอ"
        }
      },
      "seq": 1050,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1051,
      "name": "Radio",
      "parent": 1040,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleeping_enough === \"ไม่เพียงพอ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่เพียงพอ"
        },
        "name": {
          "type": "value",
          "value": "sleeping_enough"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่เพียงพอ"
        }
      },
      "seq": 1051,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1052,
      "name": "Radio",
      "parent": 1042,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleeping_problem === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "sleeping_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"9%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 1052,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1053,
      "name": "Radio",
      "parent": 1042,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleeping_problem === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "sleeping_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 1053,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1054,
      "name": "Radio",
      "parent": 1044,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleep_pill === \"ไม่ใช้\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช้"
        },
        "name": {
          "type": "value",
          "value": "sleep_pill"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"9%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ใช้"
        }
      },
      "seq": 1054,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1055,
      "name": "Radio",
      "parent": 1044,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleep_pill === \"ไม่ทราบ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "sleep_pill"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 1055,
      "void": false
    },
    {
      "from": null,
      "id": 1056,
      "name": "div",
      "parent": 1042,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.sleeping_problem !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1056,
      "void": false
    },
    {
      "from": null,
      "id": 1057,
      "name": "div",
      "parent": 1044,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.sleep_pill !== \"ใช้\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1059,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1058,
      "name": "Input",
      "parent": 1042,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleeping_problem !== \"มี\""
        },
        "name": {
          "type": "value",
          "value": "sleeping_problem_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleeping_problem === \"มี\"  ? props.PreAssessmentSequence?.sleeping_problem_remark || \"\"   : \"\""
        }
      },
      "seq": 1058,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1059,
      "name": "Input",
      "parent": 1044,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleep_pill !== \"ใช้\" "
        },
        "name": {
          "type": "value",
          "value": "sleep_pill_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleep_pill === \"ใช้\"  ? props.PreAssessmentSequence?.sleep_pill_remark || \"\"   : \"\""
        }
      },
      "seq": 1061,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1061,
      "name": "Radio",
      "parent": 1044,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sleep_pill === \"ใช้\""
        },
        "label": {
          "type": "value",
          "value": "ใช้ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "sleep_pill"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ใช้"
        }
      },
      "seq": 1057,
      "void": false
    },
    {
      "from": null,
      "id": 1062,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "6. สติปัญญาการรับรู้และประสาทสัมผัส"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 611,
      "void": false
    },
    {
      "from": null,
      "id": 1063,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 614,
      "void": false
    },
    {
      "from": null,
      "id": 1064,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1064,
      "void": false
    },
    {
      "from": null,
      "id": 1065,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1065,
      "void": false
    },
    {
      "from": null,
      "id": 1066,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1066,
      "void": false
    },
    {
      "from": null,
      "id": 1067,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1067,
      "void": false
    },
    {
      "from": null,
      "id": 1068,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1068,
      "void": false
    },
    {
      "from": null,
      "id": 1069,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px 30px\" }"
        }
      },
      "seq": 1069,
      "void": false
    },
    {
      "from": null,
      "id": 1070,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1070,
      "void": false
    },
    {
      "from": null,
      "id": 1071,
      "name": "div",
      "parent": 1064,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1071,
      "void": false
    },
    {
      "from": null,
      "id": 1072,
      "name": "div",
      "parent": 1064,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1072,
      "void": false
    },
    {
      "from": null,
      "id": 1073,
      "name": "div",
      "parent": 1065,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1073,
      "void": false
    },
    {
      "from": null,
      "id": 1074,
      "name": "div",
      "parent": 1065,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1074,
      "void": false
    },
    {
      "from": null,
      "id": 1075,
      "name": "div",
      "parent": 1066,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1075,
      "void": false
    },
    {
      "from": null,
      "id": 1076,
      "name": "div",
      "parent": 1066,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1076,
      "void": false
    },
    {
      "from": null,
      "id": 1077,
      "name": "div",
      "parent": 1067,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1077,
      "void": false
    },
    {
      "from": null,
      "id": 1078,
      "name": "div",
      "parent": 1067,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1078,
      "void": false
    },
    {
      "from": null,
      "id": 1079,
      "name": "div",
      "parent": 1068,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1079,
      "void": false
    },
    {
      "from": null,
      "id": 1080,
      "name": "div",
      "parent": 1068,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1080,
      "void": false
    },
    {
      "from": null,
      "id": 1081,
      "name": "div",
      "parent": 1069,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1081,
      "void": false
    },
    {
      "from": null,
      "id": 1082,
      "name": "div",
      "parent": 1069,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1082,
      "void": false
    },
    {
      "from": null,
      "id": 1084,
      "name": "div",
      "parent": 1072,
      "props": {
        "children": {
          "type": "value",
          "value": "ก่อนการเจ็บป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", fontWeight: \"bold\", background: \"#F9D3E5\" }"
        }
      },
      "seq": 1084,
      "void": false
    },
    {
      "from": null,
      "id": 1085,
      "name": "div",
      "parent": 1072,
      "props": {
        "children": {
          "type": "value",
          "value": "แรกรับ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", fontWeight: \"bold\", background: \"#F5B793\" }"
        }
      },
      "seq": 1408,
      "void": false
    },
    {
      "from": null,
      "id": 1086,
      "name": "div",
      "parent": 1073,
      "props": {
        "children": {
          "type": "value",
          "value": "การรับรู้"
        }
      },
      "seq": 1086,
      "void": false
    },
    {
      "from": null,
      "id": 1087,
      "name": "div",
      "parent": 1074,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 1087,
      "void": false
    },
    {
      "from": null,
      "id": 1088,
      "name": "div",
      "parent": 1074,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 1378,
      "void": false
    },
    {
      "from": null,
      "id": 1089,
      "name": "div",
      "parent": 1075,
      "props": {
        "children": {
          "type": "value",
          "value": "ความจำ/การโต้ตอบ"
        }
      },
      "seq": 1089,
      "void": false
    },
    {
      "from": null,
      "id": 1090,
      "name": "div",
      "parent": 1076,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 1090,
      "void": false
    },
    {
      "from": null,
      "id": 1091,
      "name": "div",
      "parent": 1076,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 1379,
      "void": false
    },
    {
      "from": null,
      "id": 1092,
      "name": "div",
      "parent": 1077,
      "props": {
        "children": {
          "type": "value",
          "value": "การได้ยิน"
        }
      },
      "seq": 1092,
      "void": false
    },
    {
      "from": null,
      "id": 1093,
      "name": "div",
      "parent": 1078,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 1093,
      "void": false
    },
    {
      "from": null,
      "id": 1094,
      "name": "div",
      "parent": 1078,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 1380,
      "void": false
    },
    {
      "from": null,
      "id": 1095,
      "name": "div",
      "parent": 1079,
      "props": {
        "children": {
          "type": "value",
          "value": "การมองเห็น"
        }
      },
      "seq": 1095,
      "void": false
    },
    {
      "from": null,
      "id": 1096,
      "name": "div",
      "parent": 1080,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 1096,
      "void": false
    },
    {
      "from": null,
      "id": 1097,
      "name": "div",
      "parent": 1080,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 1381,
      "void": false
    },
    {
      "from": null,
      "id": 1098,
      "name": "div",
      "parent": 1081,
      "props": {
        "children": {
          "type": "value",
          "value": "การสัมผัส"
        }
      },
      "seq": 1098,
      "void": false
    },
    {
      "from": null,
      "id": 1099,
      "name": "div",
      "parent": 1082,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(249, 211, 229, 0.29)\" }"
        }
      },
      "seq": 1099,
      "void": false
    },
    {
      "from": null,
      "id": 1100,
      "name": "div",
      "parent": 1082,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\",justifyContent: \"center\", padding: \"10px\", background: \"rgba(245, 183, 147, 0.35)\" }"
        }
      },
      "seq": 1382,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1101,
      "name": "Radio",
      "parent": 1087,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.recognition_before === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "recognition_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1102,
      "name": "Radio",
      "parent": 1087,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.recognition_before === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "recognition_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1103,
      "name": "Radio",
      "parent": 1088,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.recognition_present === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "recognition_present"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1105,
      "name": "Radio",
      "parent": 1088,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.recognition_present === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "recognition_present"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1106,
      "name": "Radio",
      "parent": 1090,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.memory_and_response_before === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "memory_and_response_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1107,
      "name": "Radio",
      "parent": 1090,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.memory_and_response_before === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "memory_and_response_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1108,
      "name": "Radio",
      "parent": 1091,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.memory_and_response_present === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "memory_and_response_present"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1109,
      "name": "Radio",
      "parent": 1091,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.memory_and_response_present === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "memory_and_response_present"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1110,
      "name": "Radio",
      "parent": 1093,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.hearing_before === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "hearing_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1111,
      "name": "Radio",
      "parent": 1093,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.hearing_before === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "hearing_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1112,
      "name": "Radio",
      "parent": 1094,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.hearing_present === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "hearing_present"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1113,
      "name": "Radio",
      "parent": 1094,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.hearing_present === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "hearing_present"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1114,
      "name": "Radio",
      "parent": 1096,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.seeing_before === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "seeing_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1115,
      "name": "Radio",
      "parent": 1096,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.seeing_before === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "seeing_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1116,
      "name": "Radio",
      "parent": 1097,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.seeing_present === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "seeing_present"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1117,
      "name": "Radio",
      "parent": 1097,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.seeing_present === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "seeing_present"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1118,
      "name": "Radio",
      "parent": 1099,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.touch_before === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "touch_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1119,
      "name": "Radio",
      "parent": 1099,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.touch_before === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "touch_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1120,
      "name": "Radio",
      "parent": 1100,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.touch_present === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "touch_present"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1122,
      "name": "Radio",
      "parent": 1100,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.touch_present === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "touch_present"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1122,
      "void": false
    },
    {
      "from": null,
      "id": 1123,
      "name": "div",
      "parent": 1070,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", fontWeight: \"bold\", marginRight: \"10px\"}"
        }
      },
      "seq": 1123,
      "void": false
    },
    {
      "from": null,
      "id": 1124,
      "name": "div",
      "parent": 1070,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1124,
      "void": false
    },
    {
      "from": null,
      "id": 1125,
      "name": "div",
      "parent": 1123,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเจ็บปวด :"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1126,
      "name": "Radio",
      "parent": 1124,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.pain_status === \"ปวด\""
        },
        "label": {
          "type": "value",
          "value": "ปวด"
        },
        "name": {
          "type": "value",
          "value": "pain_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปวด"
        }
      },
      "seq": 1126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1127,
      "name": "Radio",
      "parent": 1124,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.pain_status === \"ไม่ปวด\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปวด"
        },
        "name": {
          "type": "value",
          "value": "pain_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปวด"
        }
      },
      "seq": 1127,
      "void": false
    },
    {
      "from": null,
      "id": 1148,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "7. การรับรู้ตนเองและอัตมโนทัศน์ : การเจ็บป่วยครั้งนี้มีผลกระทบต่อ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 710,
      "void": false
    },
    {
      "from": null,
      "id": 1149,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 746,
      "void": false
    },
    {
      "from": null,
      "id": 1150,
      "name": "div",
      "parent": 1149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1150,
      "void": false
    },
    {
      "from": null,
      "id": 1151,
      "name": "div",
      "parent": 1149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1151,
      "void": false
    },
    {
      "from": null,
      "id": 1152,
      "name": "div",
      "parent": 1149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1152,
      "void": false
    },
    {
      "from": null,
      "id": 1153,
      "name": "div",
      "parent": 1150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1153,
      "void": false
    },
    {
      "from": null,
      "id": 1154,
      "name": "div",
      "parent": 1150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1154,
      "void": false
    },
    {
      "from": null,
      "id": 1155,
      "name": "div",
      "parent": 1153,
      "props": {
        "children": {
          "type": "value",
          "value": "ภาพลักษณ์ :"
        }
      },
      "seq": 1155,
      "void": false
    },
    {
      "from": null,
      "id": 1156,
      "name": "div",
      "parent": 1151,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 1156,
      "void": false
    },
    {
      "from": null,
      "id": 1157,
      "name": "div",
      "parent": 1151,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1157,
      "void": false
    },
    {
      "from": null,
      "id": 1158,
      "name": "div",
      "parent": 1156,
      "props": {
        "children": {
          "type": "value",
          "value": "ความสามารถ (การทำกิจวัตรประจำวัน/การทำงาน) "
        }
      },
      "seq": 1158,
      "void": false
    },
    {
      "from": null,
      "id": 1159,
      "name": "div",
      "parent": 1152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1159,
      "void": false
    },
    {
      "from": null,
      "id": 1160,
      "name": "div",
      "parent": 1152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 1160,
      "void": false
    },
    {
      "from": null,
      "id": 1161,
      "name": "div",
      "parent": 1159,
      "props": {
        "children": {
          "type": "value",
          "value": "อารมณ์และจิตใจ"
        }
      },
      "seq": 1161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1162,
      "name": "Radio",
      "parent": 1154,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_image === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "illness_affect_image"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 1162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1163,
      "name": "Radio",
      "parent": 1154,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_image === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "illness_affect_image"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 1163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1164,
      "name": "Radio",
      "parent": 1157,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_ability === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "illness_affect_ability"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 1164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1165,
      "name": "Radio",
      "parent": 1157,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_ability === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "illness_affect_ability"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 1165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1166,
      "name": "Radio",
      "parent": 1160,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_emotion_and_mind === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "illness_affect_emotion_and_mind"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 1166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1167,
      "name": "Radio",
      "parent": 1160,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_emotion_and_mind === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "illness_affect_emotion_and_mind"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 1167,
      "void": false
    },
    {
      "from": null,
      "id": 1168,
      "name": "div",
      "parent": 1160,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.illness_affect_emotion_and_mind === \"มี\" ? \"flex\" : \"none\" , width: \"100%\", marginLeft: \"20px\" }"
        }
      },
      "seq": 1168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1169,
      "name": "Checkbox",
      "parent": 1168,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.emotion_anxious"
        },
        "label": {
          "type": "value",
          "value": "วิตกกังวล"
        },
        "name": {
          "type": "value",
          "value": "emotion_anxious"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1170,
      "name": "Checkbox",
      "parent": 1168,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.emotion_fear"
        },
        "label": {
          "type": "value",
          "value": "กลัว"
        },
        "name": {
          "type": "value",
          "value": "emotion_fear"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1171,
      "name": "Checkbox",
      "parent": 1168,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.emotion_drear"
        },
        "label": {
          "type": "value",
          "value": "ซึมเศร้า"
        },
        "name": {
          "type": "value",
          "value": "emotion_drear"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1172,
      "name": "Checkbox",
      "parent": 1168,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.emotion_aggressive"
        },
        "label": {
          "type": "value",
          "value": "ก้าวร้าว"
        },
        "name": {
          "type": "value",
          "value": "emotion_aggressive"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1173,
      "name": "Checkbox",
      "parent": 1168,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.emotion_other"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "emotion_other"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"14%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1173,
      "void": false
    },
    {
      "from": null,
      "id": 1174,
      "name": "div",
      "parent": 1168,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.PreAssessmentSequence?.emotion_other  ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1174,
      "void": false
    },
    {
      "from": null,
      "id": 1175,
      "name": "div",
      "parent": 1157,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.illness_affect_ability !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1175,
      "void": false
    },
    {
      "from": null,
      "id": 1176,
      "name": "div",
      "parent": 1154,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.illness_affect_image !== \"มี\"  ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1177,
      "name": "Input",
      "parent": 1168,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAssessmentSequence?.emotion_other"
        },
        "name": {
          "type": "value",
          "value": "emotion_other_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.emotion_other ? props.PreAssessmentSequence?.emotion_other_remark || \"\"   : \"\""
        }
      },
      "seq": 1177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1178,
      "name": "Input",
      "parent": 1157,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_ability !== \"มี\""
        },
        "name": {
          "type": "value",
          "value": "illness_affect_ability_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_ability === \"มี\"  ? props.PreAssessmentSequence?.illness_affect_ability_remark || \"\"   : \"\""
        }
      },
      "seq": 1178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1179,
      "name": "Input",
      "parent": 1154,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_image !== \"มี\" "
        },
        "name": {
          "type": "value",
          "value": "illness_affect_image_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_image === \"มี\"  ? props.PreAssessmentSequence?.illness_affect_image_remark || \"\"   : \"\""
        }
      },
      "seq": 1179,
      "void": false
    },
    {
      "from": null,
      "id": 1180,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "8. บทบาทและสัมพันธภาพ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 818,
      "void": false
    },
    {
      "from": null,
      "id": 1181,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 853,
      "void": false
    },
    {
      "from": null,
      "id": 1182,
      "name": "div",
      "parent": 1181,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1182,
      "void": false
    },
    {
      "from": null,
      "id": 1183,
      "name": "div",
      "parent": 1182,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",marginRight: \"2%\"}"
        }
      },
      "seq": 1183,
      "void": false
    },
    {
      "from": null,
      "id": 1184,
      "name": "div",
      "parent": 1182,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\"}"
        }
      },
      "seq": 1184,
      "void": false
    },
    {
      "from": null,
      "id": 1185,
      "name": "div",
      "parent": 1183,
      "props": {
        "children": {
          "type": "value",
          "value": "การเจ็บป่วยครั้งนี้มีผลกระทบต่อผู้ป่วย :"
        }
      },
      "seq": 1185,
      "void": false
    },
    {
      "from": null,
      "id": 1186,
      "name": "div",
      "parent": 1181,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.illness_affect_on_character_and_relation_status === \"มี\" ? \"flex\" : \"none\" , alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1186,
      "void": false
    },
    {
      "from": null,
      "id": 1187,
      "name": "div",
      "parent": 1186,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\"}"
        }
      },
      "seq": 1187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1188,
      "name": "Radio",
      "parent": 1184,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_on_character_and_relation_status === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "illness_affect_on_character_and_relation_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 1188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1189,
      "name": "Radio",
      "parent": 1184,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.illness_affect_on_character_and_relation_status === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี"
        },
        "name": {
          "type": "value",
          "value": "illness_affect_on_character_and_relation_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 1189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1190,
      "name": "Checkbox",
      "parent": 1187,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.relation_family_role"
        },
        "label": {
          "type": "value",
          "value": "บทบาทในครอบครัว"
        },
        "name": {
          "type": "value",
          "value": "relation_family_role"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"15%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1191,
      "name": "Checkbox",
      "parent": 1187,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.relation_family_relationship"
        },
        "label": {
          "type": "value",
          "value": "สัมพันธภาพในครอบครัว"
        },
        "name": {
          "type": "value",
          "value": "relation_family_relationship"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"15%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1192,
      "name": "Checkbox",
      "parent": 1187,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.relation_other_relationship"
        },
        "label": {
          "type": "value",
          "value": "สัมพัธภาพกับผู้อื่น"
        },
        "name": {
          "type": "value",
          "value": "relation_other_relationship"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"12%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1193,
      "name": "Checkbox",
      "parent": 1187,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.relation_career_role"
        },
        "label": {
          "type": "value",
          "value": "บทบาทด้านอาชีพ"
        },
        "name": {
          "type": "value",
          "value": "relation_career_role"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"12%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1194,
      "name": "Checkbox",
      "parent": 1187,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.relation_education_role"
        },
        "label": {
          "type": "value",
          "value": "บทบาทด้านการศึกษา"
        },
        "name": {
          "type": "value",
          "value": "relation_education_role"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"13%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1195,
      "name": "Checkbox",
      "parent": 1187,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.relation_other"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "relation_other"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"12%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1195,
      "void": false
    },
    {
      "from": null,
      "id": 1196,
      "name": "div",
      "parent": 1187,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.PreAssessmentSequence?.relation_other ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1197,
      "name": "Input",
      "parent": 1187,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAssessmentSequence?.relation_other "
        },
        "name": {
          "type": "value",
          "value": "relation_other_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.relation_other ? props.PreAssessmentSequence?.relation_other_remark || \"\" : \"\""
        }
      },
      "seq": 1197,
      "void": false
    },
    {
      "from": null,
      "id": 1198,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "9. เพศและการเจริญพันธ์ุ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 854,
      "void": false
    },
    {
      "from": null,
      "id": 1199,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 1034,
      "void": false
    },
    {
      "from": null,
      "id": 1201,
      "name": "div",
      "parent": 1199,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1201,
      "void": false
    },
    {
      "from": null,
      "id": 1202,
      "name": "div",
      "parent": 1199,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1202,
      "void": false
    },
    {
      "from": null,
      "id": 1203,
      "name": "div",
      "parent": 1199,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1209,
      "void": false
    },
    {
      "from": null,
      "id": 1204,
      "name": "div",
      "parent": 1201,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\" }"
        }
      },
      "seq": 1204,
      "void": false
    },
    {
      "from": null,
      "id": 1205,
      "name": "div",
      "parent": 1202,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1205,
      "void": false
    },
    {
      "from": null,
      "id": 1206,
      "name": "div",
      "parent": 1202,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"89%\", display: \"flex\"}"
        }
      },
      "seq": 1206,
      "void": false
    },
    {
      "from": null,
      "id": 1207,
      "name": "div",
      "parent": 1203,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1207,
      "void": false
    },
    {
      "from": null,
      "id": 1208,
      "name": "div",
      "parent": 1203,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"89%\", display: \"flex\"}"
        }
      },
      "seq": 1208,
      "void": false
    },
    {
      "from": null,
      "id": 1209,
      "name": "div",
      "parent": 1199,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1302,
      "void": false
    },
    {
      "from": null,
      "id": 1210,
      "name": "div",
      "parent": 1209,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1210,
      "void": false
    },
    {
      "from": null,
      "id": 1211,
      "name": "div",
      "parent": 1209,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"89%\", display: \"flex\"}"
        }
      },
      "seq": 1211,
      "void": false
    },
    {
      "from": null,
      "id": 1212,
      "name": "div",
      "parent": 1207,
      "props": {
        "children": {
          "type": "value",
          "value": "ประจำเดือน :"
        }
      },
      "seq": 1212,
      "void": false
    },
    {
      "from": null,
      "id": 1213,
      "name": "div",
      "parent": 1205,
      "props": {
        "children": {
          "type": "value",
          "value": "อวัยวะสืบพันธุ์ :"
        }
      },
      "seq": 1213,
      "void": false
    },
    {
      "from": null,
      "id": 1214,
      "name": "div",
      "parent": 1210,
      "props": {
        "children": {
          "type": "value",
          "value": "เต้านม :"
        }
      },
      "seq": 1214,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1215,
      "name": "Radio",
      "parent": 1211,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.breast === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "breast"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1216,
      "name": "Radio",
      "parent": 1211,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.breast === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "breast"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1217,
      "name": "Radio",
      "parent": 1208,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.period === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "period"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  width: \"8%\",  display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 1217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1218,
      "name": "Radio",
      "parent": 1208,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.period === \"มีปกติ\""
        },
        "label": {
          "type": "value",
          "value": "มีปกติ"
        },
        "name": {
          "type": "value",
          "value": "period"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มีปกติ"
        }
      },
      "seq": 1218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1219,
      "name": "Radio",
      "parent": 1208,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.period === \"มีไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "มี ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "period"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{   display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มีไม่ปกติ"
        }
      },
      "seq": 1219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1220,
      "name": "Radio",
      "parent": 1206,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.genital_organ === \"ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ปกติ"
        },
        "name": {
          "type": "value",
          "value": "genital_organ"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ปกติ"
        }
      },
      "seq": 1220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1221,
      "name": "Radio",
      "parent": 1206,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.genital_organ === \"ไม่ปกติ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปกติ"
        },
        "name": {
          "type": "value",
          "value": "genital_organ"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปกติ"
        }
      },
      "seq": 1221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1222,
      "name": "Radio",
      "parent": 1204,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.problems_fertility === \"ไม่มีปัญหา\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มีปัญหา"
        },
        "name": {
          "type": "value",
          "value": "problems_fertility"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มีปัญหา"
        }
      },
      "seq": 1222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1223,
      "name": "Radio",
      "parent": 1204,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.problems_fertility === \"มีปัญหา\""
        },
        "label": {
          "type": "value",
          "value": "มีปัญหา โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "problems_fertility"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มีปัญหา"
        }
      },
      "seq": 1223,
      "void": false
    },
    {
      "from": null,
      "id": 1224,
      "name": "div",
      "parent": 1204,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.problems_fertility !== \"มีปัญหา\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1225,
      "name": "Input",
      "parent": 1204,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.problems_fertility !== \"มีปัญหา\""
        },
        "name": {
          "type": "value",
          "value": "problems_fertility_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.problems_fertility === \"มีปัญหา\" ? props.PreAssessmentSequence?.problems_fertility_remark || \"\" : \"\""
        }
      },
      "seq": 1225,
      "void": false
    },
    {
      "from": null,
      "id": 1226,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "10. ความเครียดและความทนต่อความเครียด"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 1063,
      "void": false
    },
    {
      "from": null,
      "id": 1227,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 1148,
      "void": false
    },
    {
      "from": null,
      "id": 1228,
      "name": "div",
      "parent": 1227,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1228,
      "void": false
    },
    {
      "from": null,
      "id": 1229,
      "name": "div",
      "parent": 1227,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1229,
      "void": false
    },
    {
      "from": null,
      "id": 1230,
      "name": "div",
      "parent": 1227,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1230,
      "void": false
    },
    {
      "from": null,
      "id": 1231,
      "name": "div",
      "parent": 1228,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"15%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1231,
      "void": false
    },
    {
      "from": null,
      "id": 1232,
      "name": "div",
      "parent": 1228,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"85%\", display: \"flex\"}"
        }
      },
      "seq": 1232,
      "void": false
    },
    {
      "from": null,
      "id": 1233,
      "name": "div",
      "parent": 1231,
      "props": {
        "children": {
          "type": "value",
          "value": "การตัดสินใจในเรื่องสำคัญ :"
        }
      },
      "seq": 1233,
      "void": false
    },
    {
      "from": null,
      "id": 1234,
      "name": "div",
      "parent": 1229,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"12%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1234,
      "void": false
    },
    {
      "from": null,
      "id": 1235,
      "name": "div",
      "parent": 1229,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"88%\", display: \"flex\"}"
        }
      },
      "seq": 1235,
      "void": false
    },
    {
      "from": null,
      "id": 1236,
      "name": "div",
      "parent": 1234,
      "props": {
        "children": {
          "type": "value",
          "value": "วิธีแก้ไขความเครียด :"
        }
      },
      "seq": 1236,
      "void": false
    },
    {
      "from": null,
      "id": 1237,
      "name": "div",
      "parent": 1230,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"29%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1237,
      "void": false
    },
    {
      "from": null,
      "id": 1238,
      "name": "div",
      "parent": 1230,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"71%\", display: \"flex\"}"
        }
      },
      "seq": 1238,
      "void": false
    },
    {
      "from": null,
      "id": 1239,
      "name": "div",
      "parent": 1237,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วย/ญาติ สามารถที่จะดูแลตนเองขณะเจ็บป่วยที่บ้าน :"
        }
      },
      "seq": 1239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1240,
      "name": "Radio",
      "parent": 1232,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.decisions === \"ตัดสินใจเองได้\""
        },
        "label": {
          "type": "value",
          "value": "ตัดสินใจเองได้"
        },
        "name": {
          "type": "value",
          "value": "decisions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ตัดสินใจเองได้"
        }
      },
      "seq": 1240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1241,
      "name": "Radio",
      "parent": 1232,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.decisions === \"ให้ผู้อื่นตัดสินใจให้\""
        },
        "label": {
          "type": "value",
          "value": "ให้ผู้อื่นตัดสินใจให้"
        },
        "name": {
          "type": "value",
          "value": "decisions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"14%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ให้ผู้อื่นตัดสินใจให้"
        }
      },
      "seq": 1241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1242,
      "name": "Radio",
      "parent": 1238,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.care_yourself_at_home === \"ได้\""
        },
        "label": {
          "type": "value",
          "value": "ได้"
        },
        "name": {
          "type": "value",
          "value": "care_yourself_at_home"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ได้"
        }
      },
      "seq": 1242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1243,
      "name": "Radio",
      "parent": 1238,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.care_yourself_at_home === \"ไม่ได้\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "care_yourself_at_home"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ได้"
        }
      },
      "seq": 1243,
      "void": false
    },
    {
      "from": null,
      "id": 1244,
      "name": "div",
      "parent": 1238,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.care_yourself_at_home !== \"ไม่ได้\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1245,
      "name": "Input",
      "parent": 1238,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.care_yourself_at_home !== \"ไม่ได้\""
        },
        "name": {
          "type": "value",
          "value": "care_yourself_at_home_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.care_yourself_at_home === \"ไม่ได้\" ? props.PreAssessmentSequence?.care_yourself_at_home_remark || \"\" : \"\""
        }
      },
      "seq": 1245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1246,
      "name": "Checkbox",
      "parent": 1235,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.stress_relief_consult "
        },
        "label": {
          "type": "value",
          "value": "ปรึกษาผู้ไว้ใจ"
        },
        "name": {
          "type": "value",
          "value": "stress_relief_consult"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1247,
      "name": "Checkbox",
      "parent": 1246,
      "props": {
      },
      "seq": 1247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1248,
      "name": "Checkbox",
      "parent": 1246,
      "props": {
      },
      "seq": 1248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1249,
      "name": "Checkbox",
      "parent": 1235,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.stress_relief_hobby "
        },
        "label": {
          "type": "value",
          "value": "ทำงานอดิเรก"
        },
        "name": {
          "type": "value",
          "value": "stress_relief_hobby"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1250,
      "name": "Checkbox",
      "parent": 1235,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.stress_relief_seclude"
        },
        "label": {
          "type": "value",
          "value": "แยกตัว"
        },
        "name": {
          "type": "value",
          "value": "stress_relief_seclude"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1251,
      "name": "Checkbox",
      "parent": 1235,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.stress_relief_drug"
        },
        "label": {
          "type": "value",
          "value": "ใช้ยา"
        },
        "name": {
          "type": "value",
          "value": "stress_relief_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1253,
      "name": "Checkbox",
      "parent": 1235,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.stress_relief_other"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "stress_relief_other"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1253,
      "void": false
    },
    {
      "from": null,
      "id": 1254,
      "name": "div",
      "parent": 1235,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.PreAssessmentSequence?.stress_relief_other ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1255,
      "name": "Input",
      "parent": 1235,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAssessmentSequence?.stress_relief_other"
        },
        "name": {
          "type": "value",
          "value": "stress_relief_other_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.stress_relief_other ? props.PreAssessmentSequence?.stress_relief_other_remark || \"\" : \"\""
        }
      },
      "seq": 1255,
      "void": false
    },
    {
      "from": null,
      "id": 1256,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "11. ความเชื่อและค่านิยม"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 1199,
      "void": false
    },
    {
      "from": null,
      "id": 1257,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 1226,
      "void": false
    },
    {
      "from": null,
      "id": 1258,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "12. การประเมินความเสี่ยงต่อการพลัดตกหกล้ม"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 1258,
      "void": false
    },
    {
      "from": null,
      "id": 1259,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 1259,
      "void": false
    },
    {
      "from": null,
      "id": 1260,
      "name": "div",
      "parent": 1257,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1260,
      "void": false
    },
    {
      "from": null,
      "id": 1261,
      "name": "div",
      "parent": 1257,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1261,
      "void": false
    },
    {
      "from": null,
      "id": 1262,
      "name": "div",
      "parent": 1257,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 1262,
      "void": false
    },
    {
      "from": null,
      "id": 1263,
      "name": "div",
      "parent": 1260,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"19%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 1263,
      "void": false
    },
    {
      "from": null,
      "id": 1264,
      "name": "div",
      "parent": 1260,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"81%\", display: \"flex\"}"
        }
      },
      "seq": 1264,
      "void": false
    },
    {
      "from": null,
      "id": 1265,
      "name": "div",
      "parent": 1263,
      "props": {
        "children": {
          "type": "value",
          "value": "การเจ็บป่วยครั้งนี้เชื่อว่ามีสาเหตุจาก :"
        }
      },
      "seq": 1265,
      "void": false
    },
    {
      "from": null,
      "id": 1266,
      "name": "div",
      "parent": 1261,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"19%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 1266,
      "void": false
    },
    {
      "from": null,
      "id": 1267,
      "name": "div",
      "parent": 1261,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"81%\", display: \"flex\"}"
        }
      },
      "seq": 1267,
      "void": false
    },
    {
      "from": null,
      "id": 1268,
      "name": "div",
      "parent": 1266,
      "props": {
        "children": {
          "type": "value",
          "value": "สิ่งยึดเหนี่ยวในขณะเจ็บป่วย :"
        }
      },
      "seq": 1268,
      "void": false
    },
    {
      "from": null,
      "id": 1269,
      "name": "div",
      "parent": 1262,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"19%\", fontWeight: \"bold\",marginRight: \"10px\" }"
        }
      },
      "seq": 1269,
      "void": false
    },
    {
      "from": null,
      "id": 1270,
      "name": "div",
      "parent": 1262,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"81%\", display: \"flex\"}"
        }
      },
      "seq": 1270,
      "void": false
    },
    {
      "from": null,
      "id": 1271,
      "name": "div",
      "parent": 1269,
      "props": {
        "children": {
          "type": "value",
          "value": "ต้องการปฏิบัติทางศาสนา :"
        }
      },
      "seq": 1271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1272,
      "name": "Checkbox",
      "parent": 1264,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cause_health "
        },
        "label": {
          "type": "value",
          "value": "ดูแลสุขภาพไม่ถูกต้อง"
        },
        "name": {
          "type": "value",
          "value": "cause_health"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1272,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1273,
      "name": "Checkbox",
      "parent": 1264,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cause_age"
        },
        "label": {
          "type": "value",
          "value": "ตามวัย"
        },
        "name": {
          "type": "value",
          "value": "cause_age"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1273,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1274,
      "name": "Checkbox",
      "parent": 1264,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cause_destiny"
        },
        "label": {
          "type": "value",
          "value": "เคราะห์กรรม"
        },
        "name": {
          "type": "value",
          "value": "cause_destiny"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1274,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1275,
      "name": "Checkbox",
      "parent": 1264,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cause_other"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "cause_other"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1275,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1276,
      "name": "Checkbox",
      "parent": 1267,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.support_family"
        },
        "label": {
          "type": "value",
          "value": "ครอบครัว"
        },
        "name": {
          "type": "value",
          "value": "support_family"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1276,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1277,
      "name": "Checkbox",
      "parent": 1267,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.support_religion"
        },
        "label": {
          "type": "value",
          "value": "ศาสนา"
        },
        "name": {
          "type": "value",
          "value": "support_religion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1277,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1278,
      "name": "Checkbox",
      "parent": 1267,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.support_other"
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "support_other"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1278,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1279,
      "name": "Radio",
      "parent": 1270,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.doing_religion === \"ไม่ต้องการ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ต้องการ"
        },
        "name": {
          "type": "value",
          "value": "doing_religion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ต้องการ"
        }
      },
      "seq": 1279,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1280,
      "name": "Radio",
      "parent": 1270,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.doing_religion === \"ต้องการ\""
        },
        "label": {
          "type": "value",
          "value": "ต้องการ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "doing_religion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ต้องการ"
        }
      },
      "seq": 1280,
      "void": false
    },
    {
      "from": null,
      "id": 1281,
      "name": "div",
      "parent": 1264,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.PreAssessmentSequence?.cause_other ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1281,
      "void": false
    },
    {
      "from": null,
      "id": 1282,
      "name": "div",
      "parent": 1267,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.PreAssessmentSequence?.support_other ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1282,
      "void": false
    },
    {
      "from": null,
      "id": 1283,
      "name": "div",
      "parent": 1270,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.doing_religion !== \"ต้องการ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 1283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1284,
      "name": "Input",
      "parent": 1264,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAssessmentSequence?.cause_other"
        },
        "name": {
          "type": "value",
          "value": "cause_other_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cause_other ? props.PreAssessmentSequence?.cause_other_remark || \"\" : \"\""
        }
      },
      "seq": 1284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1285,
      "name": "Input",
      "parent": 1267,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAssessmentSequence?.support_other"
        },
        "name": {
          "type": "value",
          "value": "support_other_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.support_other ? props.PreAssessmentSequence?.support_other_remark || \"\" : \"\""
        }
      },
      "seq": 1285,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1286,
      "name": "Input",
      "parent": 1270,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.doing_religion !== \"ต้องการ\""
        },
        "name": {
          "type": "value",
          "value": "doing_religion_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.doing_religion === \"ต้องการ\" ? props.PreAssessmentSequence?.doing_religion_remark || \"\" : \"\""
        }
      },
      "seq": 1286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1299,
      "name": "Input",
      "parent": 756,
      "props": {
        "name": {
          "type": "value",
          "value": "meal_per_day"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyDown"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", marginRight: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.meal_per_day"
        }
      },
      "seq": 760,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1300,
      "name": "Checkbox",
      "parent": 937,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.noStomachDefecation"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "noStomachDefecation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStomachDefecation"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1300,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1301,
      "name": "Checkbox",
      "parent": 937,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.haveStomachDefecation"
        },
        "label": {
          "type": "value",
          "value": "มี"
        },
        "name": {
          "type": "value",
          "value": "haveStomachDefecation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStomachDefecation"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        }
      },
      "seq": 1301,
      "void": false
    },
    {
      "from": null,
      "id": 1302,
      "name": "div",
      "parent": 1199,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\", ...(!props.isShowPregnancy && {display: \"none\"}) }"
        }
      },
      "seq": 1203,
      "void": false
    },
    {
      "from": null,
      "id": 1303,
      "name": "div",
      "parent": 1302,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"11%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1303,
      "void": false
    },
    {
      "from": null,
      "id": 1304,
      "name": "div",
      "parent": 1302,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"89%\", display: \"flex\", }"
        }
      },
      "seq": 1304,
      "void": false
    },
    {
      "from": null,
      "id": 1305,
      "name": "div",
      "parent": 1303,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการตั้งครรภ์:"
        }
      },
      "seq": 1305,
      "void": false
    },
    {
      "from": null,
      "id": 1306,
      "name": "div",
      "parent": 1304,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุไตรมาส"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", marginRight: \"10px\", ...(props.PreAssessmentSequence?.pregnancy_status !== 3 && {display: \"none\"}) }"
        }
      },
      "seq": 1309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1307,
      "name": "Radio",
      "parent": 1304,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.pregnancy_status === 1"
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "pregnancy_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 1306,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1308,
      "name": "Radio",
      "parent": 1304,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.pregnancy_status === 2"
        },
        "label": {
          "type": "value",
          "value": "ไม่ตั้งครรภ์"
        },
        "name": {
          "type": "value",
          "value": "pregnancy_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 1307,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1309,
      "name": "Radio",
      "parent": 1304,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.pregnancy_status === 3"
        },
        "label": {
          "type": "value",
          "value": "กำลังตั้งครรภ์"
        },
        "name": {
          "type": "value",
          "value": "pregnancy_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 1308,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1310,
      "name": "Dropdown",
      "parent": 1304,
      "props": {
        "name": {
          "type": "value",
          "value": "pregnancy_period"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.pregnancyPeriodOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.PreAssessmentSequence?.pregnancy_status !== 3 && {display: \"none\"}) }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.pregnancy_period"
        }
      },
      "seq": 1310,
      "void": false
    },
    {
      "from": null,
      "id": 1334,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1334,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1335,
      "name": "Form",
      "parent": 1334,
      "props": {
      },
      "seq": 1335,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1337,
      "name": "FormGroup",
      "parent": 1335,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"flex-start\", margin: \"10px\" }"
        }
      },
      "seq": 1355,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1338,
      "name": "FormField",
      "parent": 1337,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", }"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 1338,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1339,
      "name": "FormField",
      "parent": 1337,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", ...(!((props.PreAssessmentSequence?.senses_nursing_care || []).includes(\"อื่นๆ\") || (props.PreAssessmentSequence?.senses_nursing_care || []).includes(\"มี RX\")) && { display: \"none\" } ) }"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 1339,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1340,
      "name": "TextArea",
      "parent": 1339,
      "props": {
        "name": {
          "type": "value",
          "value": "sensesNursingCareRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.sensesNursingCareRemark"
        }
      },
      "seq": 1340,
      "void": true
    },
    {
      "from": null,
      "id": 1341,
      "name": "label",
      "parent": 1338,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาลผู้ป่วย :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"fit-content\" }"
        }
      },
      "seq": 1341,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1342,
      "name": "Dropdown",
      "parent": 1338,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "senses_nursing_care"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.sensesNursingCareOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.senses_nursing_care"
        }
      },
      "seq": 1342,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1343,
      "name": "FormGroup",
      "parent": 1335,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"baseline\", margin: \"10px\" }"
        }
      },
      "seq": 1343,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1344,
      "name": "FormField",
      "parent": 1343,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", }"
        }
      },
      "seq": 1344,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1345,
      "name": "FormField",
      "parent": 1343,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", ...(props.PreAssessmentSequence?.painArea !== 1321 && { display: \"none\" } ) }"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 1346,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1346,
      "name": "FormField",
      "parent": 1343,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", }"
        }
      },
      "seq": 1345,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1347,
      "name": "FormField",
      "parent": 1343,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", }"
        }
      },
      "seq": 1347,
      "void": false
    },
    {
      "from": null,
      "id": 1348,
      "name": "label",
      "parent": 1344,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับความปวด :"
        }
      },
      "seq": 1348,
      "void": false
    },
    {
      "from": null,
      "id": 1349,
      "name": "label",
      "parent": 1346,
      "props": {
        "children": {
          "type": "value",
          "value": "บริเวณที่ปวด :"
        }
      },
      "seq": 1349,
      "void": false
    },
    {
      "from": null,
      "id": 1350,
      "name": "label",
      "parent": 1347,
      "props": {
        "children": {
          "type": "value",
          "value": "ลักษณะการปวด :"
        }
      },
      "seq": 1350,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1351,
      "name": "Dropdown",
      "parent": 1344,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painLevel"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.painLevel"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.painLevel"
        }
      },
      "seq": 1351,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1352,
      "name": "Dropdown",
      "parent": 1346,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painArea"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAdditionLocation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.locationOption"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.painArea"
        }
      },
      "seq": 1352,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1353,
      "name": "Dropdown",
      "parent": 1347,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painNature"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAdditionCharacteristic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.characteristicOption"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.painNature"
        }
      },
      "seq": 1353,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1354,
      "name": "TextArea",
      "parent": 1345,
      "props": {
        "name": {
          "type": "value",
          "value": "painAreaRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.painAreaRemark"
        }
      },
      "seq": 1354,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 1355,
      "name": "FormGroup",
      "parent": 1335,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"baseline\", margin: \"10px\" }"
        }
      },
      "seq": 1337,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1356,
      "name": "FormField",
      "parent": 1355,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", }"
        }
      },
      "seq": 1356,
      "void": false
    },
    {
      "from": null,
      "id": 1357,
      "name": "label",
      "parent": 1356,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินระดับความเจ็บปวด :"
        }
      },
      "seq": 1357,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1358,
      "name": "Dropdown",
      "parent": 1356,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "assessPainLevel"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pain"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.assessPainLevel"
        }
      },
      "seq": 1358,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1363,
      "name": "Form",
      "parent": 1259,
      "props": {
      },
      "seq": 1363,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1364,
      "name": "FormGroup",
      "parent": 1363,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\", }"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1370,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1365,
      "name": "FormField",
      "parent": 1364,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1365,
      "void": false
    },
    {
      "from": null,
      "id": 1366,
      "name": "label",
      "parent": 1365,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาลผู้ป่วย :"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"30px\" }"
        }
      },
      "seq": 1366,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1368,
      "name": "FormField",
      "parent": 1364,
      "props": {
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 1368,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1369,
      "name": "Dropdown",
      "parent": 1368,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "nursing_care"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.painNursingCareOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"700px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.nursing_care"
        }
      },
      "seq": 1369,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1370,
      "name": "FormGroup",
      "parent": 1363,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\", }"
        }
      },
      "seq": 1364,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1371,
      "name": "FormField",
      "parent": 1370,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 1371,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1372,
      "name": "FormField",
      "parent": 1370,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 1372,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1373,
      "name": "FormField",
      "parent": 1370,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 1373,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1374,
      "name": "Radio",
      "parent": 1371,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.risk_fall === \"ไม่มีความเสี่ยง\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มีความเสี่ยง"
        },
        "name": {
          "type": "value",
          "value": "risk_fall"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มีความเสี่ยง"
        }
      },
      "seq": 1374,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1375,
      "name": "Radio",
      "parent": 1372,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.risk_fall === \"มีความเสี่ยง\""
        },
        "label": {
          "type": "value",
          "value": "มีความเสี่ยง โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "risk_fall"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"2%\",display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มีความเสี่ยง"
        }
      },
      "seq": 1375,
      "void": false
    },
    {
      "from": null,
      "id": 1376,
      "name": "div",
      "parent": 1372,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 1376,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1377,
      "name": "Dropdown",
      "parent": 1373,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.risk_fall !== \"มีความเสี่ยง\""
        },
        "name": {
          "type": "value",
          "value": "risk_fall_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.painRiskFallOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.risk_fall === \"มีความเสี่ยง\" ? props.PreAssessmentSequence?.risk_fall_remark || \"\" : \"\""
        }
      },
      "seq": 1377,
      "void": false
    },
    {
      "from": null,
      "id": 1378,
      "name": "div",
      "parent": 1074,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.PreAssessmentSequence?.recognition_present !== \"ไม่ปกติ\" && { display: \"none\" })}"
        }
      },
      "seq": 1393,
      "void": false
    },
    {
      "from": null,
      "id": 1379,
      "name": "div",
      "parent": 1076,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.PreAssessmentSequence?.memory_and_response_present !== \"ไม่ปกติ\" && { display: \"none\" })}"
        }
      },
      "seq": 1396,
      "void": false
    },
    {
      "from": null,
      "id": 1380,
      "name": "div",
      "parent": 1078,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.PreAssessmentSequence?.hearing_present !== \"ไม่ปกติ\" && { display: \"none\" })}"
        }
      },
      "seq": 1399,
      "void": false
    },
    {
      "from": null,
      "id": 1381,
      "name": "div",
      "parent": 1080,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.PreAssessmentSequence?.seeing_present !== \"ไม่ปกติ\" && { display: \"none\" })}"
        }
      },
      "seq": 1402,
      "void": false
    },
    {
      "from": null,
      "id": 1382,
      "name": "div",
      "parent": 1082,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.PreAssessmentSequence?.touch_present !== \"ไม่ปกติ\" && { display: \"none\" })}"
        }
      },
      "seq": 1404,
      "void": false
    },
    {
      "from": null,
      "id": 1383,
      "name": "div",
      "parent": 1378,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 1383,
      "void": false
    },
    {
      "from": null,
      "id": 1384,
      "name": "div",
      "parent": 1379,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 1384,
      "void": false
    },
    {
      "from": null,
      "id": 1385,
      "name": "div",
      "parent": 1380,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 1385,
      "void": false
    },
    {
      "from": null,
      "id": 1386,
      "name": "div",
      "parent": 1381,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 1386,
      "void": false
    },
    {
      "from": null,
      "id": 1387,
      "name": "div",
      "parent": 1382,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 1387,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1388,
      "name": "Input",
      "parent": 1378,
      "props": {
        "name": {
          "type": "value",
          "value": "recognition_present_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , margin: \"0px 10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.recognition_present_detail"
        }
      },
      "seq": 1388,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1389,
      "name": "Input",
      "parent": 1379,
      "props": {
        "name": {
          "type": "value",
          "value": "memory_and_response_present_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , margin: \"0px 10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.memory_and_response_present_detail"
        }
      },
      "seq": 1389,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1390,
      "name": "Input",
      "parent": 1380,
      "props": {
        "name": {
          "type": "value",
          "value": "hearing_present_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , margin: \"0px 10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.hearing_present_detail"
        }
      },
      "seq": 1390,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1391,
      "name": "Input",
      "parent": 1381,
      "props": {
        "name": {
          "type": "value",
          "value": "seeing_present_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , margin: \"0px 10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.seeing_present_detail"
        }
      },
      "seq": 1391,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1392,
      "name": "Input",
      "parent": 1382,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "touch_present_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , margin: \"0px 10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.touch_present_detail"
        }
      },
      "seq": 1392,
      "void": false
    },
    {
      "from": null,
      "id": 1393,
      "name": "div",
      "parent": 1074,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.beforeDetail && { display: \"none\" })}"
        }
      },
      "seq": 1088,
      "void": false
    },
    {
      "from": null,
      "id": 1394,
      "name": "div",
      "parent": 1393,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", ...(props.PreAssessmentSequence?.recognition_before !== \"ไม่ปกติ\" && { display: \"none\" }) }"
        }
      },
      "seq": 1394,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1395,
      "name": "Input",
      "parent": 1393,
      "props": {
        "name": {
          "type": "value",
          "value": "recognition_before_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , margin: \"0px 10px\", ...(props.PreAssessmentSequence?.recognition_before !== \"ไม่ปกติ\" && { display: \"none\" })}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.recognition_before_detail"
        }
      },
      "seq": 1395,
      "void": false
    },
    {
      "from": null,
      "id": 1396,
      "name": "div",
      "parent": 1076,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.beforeDetail && { display: \"none\" })}"
        }
      },
      "seq": 1091,
      "void": false
    },
    {
      "from": null,
      "id": 1397,
      "name": "div",
      "parent": 1396,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", ...(props.PreAssessmentSequence?.memory_and_response_before !== \"ไม่ปกติ\" && { display: \"none\" }) }"
        }
      },
      "seq": 1397,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1398,
      "name": "Input",
      "parent": 1396,
      "props": {
        "name": {
          "type": "value",
          "value": "memory_and_response_before_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , margin: \"0px 10px\", ...(props.PreAssessmentSequence?.memory_and_response_before !== \"ไม่ปกติ\" && { display: \"none\" })}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.memory_and_response_before_detail"
        }
      },
      "seq": 1398,
      "void": false
    },
    {
      "from": null,
      "id": 1399,
      "name": "div",
      "parent": 1078,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.beforeDetail && { display: \"none\" })}"
        }
      },
      "seq": 1094,
      "void": false
    },
    {
      "from": null,
      "id": 1400,
      "name": "div",
      "parent": 1399,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", ...(props.PreAssessmentSequence?.hearing_before !== \"ไม่ปกติ\" && { display: \"none\" }) }"
        }
      },
      "seq": 1400,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1401,
      "name": "Input",
      "parent": 1399,
      "props": {
        "name": {
          "type": "value",
          "value": "hearing_before_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , margin: \"0px 10px\", ...(props.PreAssessmentSequence?.hearing_before !== \"ไม่ปกติ\" && { display: \"none\" })}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.hearing_before_detail"
        }
      },
      "seq": 1401,
      "void": false
    },
    {
      "from": null,
      "id": 1402,
      "name": "div",
      "parent": 1080,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.beforeDetail && { display: \"none\" })}"
        }
      },
      "seq": 1097,
      "void": false
    },
    {
      "from": null,
      "id": 1403,
      "name": "div",
      "parent": 1402,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", ...(props.PreAssessmentSequence?.seeing_before !== \"ไม่ปกติ\" && { display: \"none\" }) }"
        }
      },
      "seq": 1403,
      "void": false
    },
    {
      "from": null,
      "id": 1404,
      "name": "div",
      "parent": 1082,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.beforeDetail && { display: \"none\" })}"
        }
      },
      "seq": 1100,
      "void": false
    },
    {
      "from": null,
      "id": 1405,
      "name": "div",
      "parent": 1404,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\", ...(props.PreAssessmentSequence?.touch_before !== \"ไม่ปกติ\" && { display: \"none\" }) }"
        }
      },
      "seq": 1405,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1406,
      "name": "Input",
      "parent": 1402,
      "props": {
        "name": {
          "type": "value",
          "value": "seeing_before_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , margin: \"0px 10px\", ...(props.PreAssessmentSequence?.seeing_before !== \"ไม่ปกติ\" && { display: \"none\" })}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.seeing_before_detail"
        }
      },
      "seq": 1406,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1407,
      "name": "Input",
      "parent": 1404,
      "props": {
        "name": {
          "type": "value",
          "value": "touch_before_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , margin: \"0px 10px\", ...(props.PreAssessmentSequence?.touch_before !== \"ไม่ปกติ\" && { display: \"none\" })}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.touch_before_detail"
        }
      },
      "seq": 1407,
      "void": false
    },
    {
      "from": null,
      "id": 1408,
      "name": "div",
      "parent": 1072,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\",padding: \"10px\", ...(props.beforeDetail && { display: \"none\" })}"
        }
      },
      "seq": 1085,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardPreAssessmentHealthUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
