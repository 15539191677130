import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Form, TextArea, TextAreaProps } from "semantic-ui-react";

// Common
import { useIntl } from "react-intl";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Types
type ModDrugPendingRemarkProps = {
  onEvent?: (e: any) => any;
  setProp?: (key: string, value: any, callback?: () => any) => any;
  id?: number;
  label?: string;
  open: boolean;
  value: string;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // callback
  onClose: () => any;
  onSave?: (data: Record<string, any>) => void;
};

type Styles = Record<"approveButton" | "denyButton" | "modal", CSSProperties>;

const styles: Styles = {
  approveButton: { width: "110px" },
  denyButton: { marginLeft: "-1.25rem", width: "110px" },
  modal: { paddingBottom: "0.5rem", width: "25%" },
};

const MOD_DRUG_PENDING_REMARK = "ModDrugPendingRemark";

const ModDrugPendingRemark = (props: ModDrugPendingRemarkProps) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState("");

  // Effect
  useEffect(() => {
    if (props.open) {
      setInputValue(props.value || "");
    }
  }, [props.open]);

  // Callback
  const handleChange = useCallback((e: SyntheticEvent, data: TextAreaProps) => {
    setInputValue(String(data.value));
  }, []);

  const handleSave = useCallback(() => {
    const data = {
      id: props.id,
      card: MOD_DRUG_PENDING_REMARK,
      value: inputValue,
      onSuccess: props.onClose,
    };

    if (props.onSave) {
      props.onSave(data);
    } else {
      props.onEvent?.({
        message: "SaveDrugPendingRemark",
        params: data,
      });
    }
  }, [inputValue, props.id]);

  const handleCloseErrMsg = useCallback(() => {
    props.setProp?.(`errorMessage.${MOD_DRUG_PENDING_REMARK}`, null);
  }, []);

  const content = useMemo(
    () => (
      <div
        style={{
          margin: "-1rem 0.5rem -1.25rem",
        }}
      >
        <Form>
          <Form.Field>
            <label
              htmlFor="reasonTextArea"
              style={{ fontWeight: "normal", marginBottom: "0.5rem" }}
            >
              {props.label ?? "ระบุสาเหตุที่รอดำเนินการ"}
            </label>
            <TextArea id="reasonTextArea" value={inputValue} onChange={handleChange} />
          </Form.Field>
        </Form>
      </div>
    ),
    [handleChange, inputValue]
  );

  // Memo
  const approveButton = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"green"}
        paramKey={`${MOD_DRUG_PENDING_REMARK}_SAVE`}
        size="medium"
        style={styles.approveButton}
        title={intl.formatMessage({ id: "บันทึก" })}
        basic
        buttonLoadCheck={props.buttonLoadCheck?.[`${MOD_DRUG_PENDING_REMARK}_SAVE`]}
        onClick={handleSave}
      />
    ),
    [handleSave, inputValue, props.buttonLoadCheck]
  );

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[MOD_DRUG_PENDING_REMARK]}
        success={null}
        onClose={handleCloseErrMsg}
      />

      <ModConfirm
        approveButton={approveButton}
        backgroundColor="var(--primary-theme-color)"
        content={content}
        denyButtonColor="red"
        denyButtonStyle={styles.denyButton}
        denyButtonText={intl.formatMessage({ id: "ปิดหน้าต่าง" })}
        modalStyle={styles.modal}
        openModal={props.open}
        size="mini"
        titleName={intl.formatMessage({ id: "กรุณาระบุข้อความ" })}
        // onApprove={handleApprove}
        onCloseWithDimmerClick={props.onClose}
        onDeny={props.onClose}
      />
    </>
  );
};

ModDrugPendingRemark.displayName = "ModDrugPendingRemark";

export default React.memo(ModDrugPendingRemark);
