import React from 'react'
import CardRehabTreatmentPlanUX from './CardRehabTreatmentPlanUX'
import { Icon } from 'semantic-ui-react'

const CardRehabTreatmentPlan = () => {
  return (
    <CardRehabTreatmentPlanUX 
      printbookIcon={<>
       <Icon name='print' style={{marginRight: "6px"}}/>
       <>Print booking</>
       </>}
      appointmentIcon={<>
          <Icon name='calendar alternate' style={{marginRight: "6px"}}/>
          <>Appointment</>
      </>}
      selectToPerformIcon={<>
          <Icon name='check square outline' style={{marginRight: "6px"}}/>
          <>Perform Treatment</>
      </>}

      />
  )
}

export default CardRehabTreatmentPlan