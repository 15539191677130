import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";

import { Popup } from "semantic-ui-react";

import ModDrugPendingRemark from "react-lib/apps/HISV3/TPD/ModDrugPendingRemark";

// Types
type ButtonEditRemarkProps = {
  onEvent?: (e: any) => void;
  setProp?: (key: string, value: any, callback?: () => void) => any;
  id?: number;
  label?: string;
  readOnly?: boolean;
  trigger: React.ReactElement<{
    onClick?: (e: React.MouseEvent) => void;
    onDoubleClick?: (e: React.MouseEvent) => void;
  }>;
  value: string;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  onSave?: (data: Record<string, any>) => void;
};

const ButtonEditRemark = (props: ButtonEditRemarkProps) => {
  const [openModRemark, setOpenModRemark] = useState(false);

  const handleOpenModRemark = useCallback(() => {
    if (props.readOnly) {
      return;
    }

    setOpenModRemark(true);
  }, [props.readOnly]);

  const handleCloseModRemark = useCallback(() => {
    setOpenModRemark(false);
  }, []);

  const handleClickMod = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const trigger = useMemo(
    () =>
      React.isValidElement(props.trigger)
        ? React.cloneElement(props.trigger, {
            onClick: (e: SyntheticEvent) => {
              e.stopPropagation();

              handleOpenModRemark();
            },
            onDoubleClick: (e: SyntheticEvent) => {
              e.stopPropagation();
            },
          })
        : props.trigger,
    [handleOpenModRemark, props.trigger]
  );

  return (
    <div>
      <Popup
        content={props.value}
        open={props.value ? undefined : false}
        position="bottom center"
        trigger={trigger}
      />

      <div aria-hidden="true" onClick={handleClickMod} onDoubleClick={handleClickMod}>
        <ModDrugPendingRemark
          onEvent={props.onEvent}
          setProp={props.setProp}
          id={props.id}
          label={props.label}
          open={openModRemark}
          value={props.value}
          buttonLoadCheck={props.buttonLoadCheck}
          errorMessage={props.errorMessage}
          onClose={handleCloseModRemark}
          onSave={props.onSave}
        />
      </div>
    </div>
  );
};

ButtonEditRemark.displayName = "ButtonEditRemark";

export default React.memo(ButtonEditRemark);
