import React, { useState, useEffect } from "react";
import { Accordion, AccordionContent, AccordionTitle, Button, Divider, Header, Icon, Modal, ModalActions, ModalContent, ModalDescription, ModalHeader } from "semantic-ui-react";
import adapterErrorNetwork from "react-lib/apps/common/adapterErrorNetwork";


const ModalNetWorkError = (props: any) => {

  const [error, setError] = useState<any>({})
  const [fold, setFold] = useState(true)

  const handleClick = () => {
    setFold(!fold)
  };

  useEffect(() => {
    adapterErrorNetwork.setRef(setError)
  }, [])

  return (
    <Modal
      size="small"
      open={!!error?.message}
      closeOnDimmerClick={false}
      onClose={() => {
        setFold(true);
        setError({});
      }}
     
      // {...(!fold && { size: "fullscreen" })}
      closeIcon
    >
      <ModalHeader style={{color: "red"}}> เกิดข้อผิดพลาด กรุณาติดต่อสารสนเทศ </ModalHeader>
      <ModalContent>
        <ModalDescription>
          <Accordion>
            <AccordionTitle active={!fold} onClick={handleClick} style={{fontWeight: "bold"}}>
              <Icon name="dropdown" />
              เนื้อหา ข้อผิดพลาด สำหรับฝ่ายสารสนเทศ
            </AccordionTitle>
            <AccordionContent active={!fold}>
              <p>{error?.message}</p>
              <p>{error?.response?.data}</p>
            </AccordionContent>
          </Accordion>
        </ModalDescription>
      </ModalContent>
      <ModalActions>
          <Button
          color="yellow"
          onClick={() => {
            navigator.clipboard.writeText(error?.response?.data)
          }}
        >
          <Icon name="copy" /> Copy Debug Message
        </Button>
        <Button
          color="green"
          onClick={() => {
            setFold(true);
            setError({});
          }}
        >
          <Icon name="tag" /> OK
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default ModalNetWorkError;


