import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  TextArea,
  Checkbox,
  Button,
  Radio,
  Input
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardAdverseReactionNoteUX = (props: any) => {
    return(
      <div
        className="card-adverse-reaction-note">
        <div
          className="card-title">

          <div>
            {props.adrSelected !== "new" ? "แก้ไขรายละเอียดอาการไม่พึ่งประสงค์จากการใช้ยา" : "บันทึกรายละเอียดอาการไม่พึ่งประสงค์จากการใช้ยา"}
          </div>
        </div>
        <div>
          {props.menu}
        </div>
        <div
          style={{height: "25px", width: "100%"}}>

        </div>
        <div
          style={{display: props.drugForm? "flex": "none", width: "100%", padding: "20px"}}>

          <div
            className="ui form"
            style={{width: "75%"}}>

            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title red"
                  style={{color:"red"}}>
                  สารก่อการแพ้*
                </div>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{width: "100%"}}>
                  {props.searchAdversary}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title">
                  อาการแพ้
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <div
                  style={{width: "100%"}}>

                  <Dropdown
                    clearable={true}
                    fluid={true}
                    multiple={true}
                    name="symptoms"
                    onChange={props.onChangeADR}
                    options={props.adrSymptomsOptions}
                    search={true}
                    selection={true}
                    value={props.symptoms}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title">
                  วันที่มีอาการ
                </div>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <DateTextBox
                  onChange={props.onChangeDateSymptoms}
                  style={{ width: "100%" }}
                  value={props.dateSymptoms}>
                </DateTextBox>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title">
                  ระบุอาการแพ้อื่นๆ
                </div>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{width: "100%"}}>

                  <Input
                    name="other_symptom"
                    onChange={props.onChangeADR}
                    value={props.AllergySequence?.adrSelected?.other_symptom}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title"
                  style={{color:"red"}}>
                  ประเภท*
                </div>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{width: "100%"}}>

                  <Dropdown
                    clearable={true}
                    fluid={true}
                    name="adversary_symptom_type"
                    onChange={props.onChangeADR}
                    options={props.affectTypeOptions}
                    search={true}
                    selection={true}
                    value={props.affectType}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title">
                  ความรุนแรง
                </div>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{width: "100%"}}>

                  <Dropdown
                    clearable={true}
                    fluid={true}
                    name="severity"
                    onChange={props.onChangeADR}
                    options={props.adrSeverityOptions}
                    search={true}
                    selection={true}
                    value={props.severity}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title">
                  ความเป็นไปได้
                </div>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{display: "flex", alignItems: "center", width: "100%"}}>

                  <Checkbox
                    checked={props.naranjoChecked}
                    id="CardAdverseReactionNote-Checkbox-NaranjoChecked"
                    onChange={props.onChangeNaranjoChecked}
                    style={{marginRight: "8px"}}>
                  </Checkbox>
                  <Button
                    color="blue"
                    disabled={props.AllergySequence?.adrSelected?.naranjoChecked !== true}
                    onClick={props.onClickNaranjo}
                    style={{minWidth: "max-content", paddingLeft: "0.7rem", paddingRight: "0.7rem"}}>
                    Naranjo Score
                  </Button>
                  <Input
                    disabled={true}
                    fluid={true}
                    readOnly={true}
                    style={{width: "100%", padding: "0 0.5rem", opacity: "1",}}
                    value={props.AllergySequence.adrSelected?.naranjo_data?.score !== undefined ?  props.AllergySequence.adrSelected?.naranjo_data?.score : ""}>
                  </Input>
                  <Dropdown
                    clearable={true}
                    disabled={props.naranjoChecked}
                    fluid={true}
                    name="probability"
                    onChange={props.onChangeADR}
                    options={props.adrProbabilityOptions}
                    search={true}
                    selection={true}
                    value={props.probability}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title"
                  style={{color:"red"}}>
                  ข้อความแจ้งเตือน*
                </div>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{width: "100%"}}>

                  <TextArea
                    name="note"
                    onChange={props.onChangeADR}
                    value={props.note}>
                  </TextArea>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{display: "flex", justifyContent: "flex-end", alignItems: "Center", width: "100%"}}>

                  <Button
                    color="green"
                    onClick={props.onSaveNewADRNote}
                    style={{marginRight: "10px"}}>
                    บันทึก
                  </Button>
                  <Button
                    color="red"
                    onClick={props.onCancelNewADRNote}>
                    ยกเลิก
                  </Button>
                </div>
              </FormField>
            </FormGroup>
          </div>
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

            <div
              style={{display: "flex", flexDirection:"column", width: "100%", height: "100%", alignItems: "center"}}>

              <div
                style={{paddingLeft: "10px",display: props.showPharma}}>
                สำหรับเภสัชกร
              </div>
              <div
                style={{display: props.typePharma, flexDirection: "column", padding: "10px", backgroundColor: "#e8f5fd", borderRadius: "5px", height: "170px", justifyContent: "space-between"}}>

                <div>

                  <Radio
                    checked={props.pharmacyAction === "NOTED"}
                    label="NOTE"
                    onChange={props.onChangePharmacyAction}
                    value="NOTED">
                  </Radio>
                </div>
                <div>

                  <Radio
                    checked={props.pharmacyAction === "VERIFIED"}
                    label="VERIFY (สั่งยาได้)"
                    onChange={props.onChangePharmacyAction}
                    value="VERIFIED">
                  </Radio>
                </div>
                <div>

                  <Radio
                    checked={props.pharmacyAction === "LOCKED"}
                    label="VERIFY & LOCK (ห้ามสั่งยา)"
                    onChange={props.onChangePharmacyAction}
                    value="LOCKED">
                  </Radio>
                </div>
                <div
                  style={{display: "flex"}}>

                  <Radio
                    checked={props.adrActive}
                    label={props.adrActive ? "Active" : "INACTIVE"}
                    onChange={props.onChangePharmacyAction}
                    toggle={true}
                    value="is_inactive">
                  </Radio>
                </div>
                <div>

                  <div
                    style={{width: "100%"}}>
                    {props.inactiveDate}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{display: props.otherForm? "flex": "none", width: "100%", padding: "20px"}}>

          <div
            className="ui form"
            style={{width: "75%"}}>

            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title"
                  style={{color:"red"}}>
                  รายละเอียด*
                </div>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{width: "100%"}}>

                  <TextArea
                    name="name"
                    onChange={props.onChangeADR}
                    value={props.adversaryText}>
                  </TextArea>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title"
                  style={{color:"red"}}>
                  อาการแพ้*
                </div>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{width: "100%"}}>

                  <TextArea
                    name="note"
                    onChange={props.onChangeADR}
                    value={props.note}>
                  </TextArea>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
              </FormField>
              <FormField
                width={3}>
              </FormField>
              <FormField
                inline={true}
                width={10}>
                <div
                  style={{display: "flex", justifyContent: "flex-end", alignItems: "Center", width: "100%"}}>

                  <Button
                    color="green"
                    onClick={props.onSaveNewADRNote}
                    style={{marginRight: "10px"}}>
                    บันทึก
                  </Button>
                  <Button
                    color="red"
                    onClick={props.onCancelNewADRNote}>
                    ยกเลิก
                  </Button>
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={{display: props.drugFreeText? "flex": "none", width: "100%", padding: "20px"}}>

          <div
            className="ui form"
            style={{width: "75%"}}>

            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  className="field-title"
                  style={{color:"red"}}>
                  รายละเอียดการแพ้ยา*
                </div>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{width: "100%"}}>

                  <TextArea
                    name="note"
                    onChange={props.onChangeADR}
                    value={props.note}>
                  </TextArea>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
              </FormField>
              <FormField
                inline={true}
                width={13}>
                <div
                  style={{display: "flex", justifyContent: "flex-end", alignItems: "Center", width: "100%"}}>

                  <Button
                    color="green"
                    onClick={props.onSaveNewADRNote}
                    style={{marginRight: "10px"}}>
                    บันทึก
                  </Button>
                  <Button
                    color="red"
                    onClick={props.onCancelNewADRNote}>
                    ยกเลิก
                  </Button>
                </div>
              </FormField>
            </FormGroup>
          </div>
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

            <div
              style={{display: "flex", flexDirection:"column", width: "100%", height: "100%", alignItems: "center"}}>

              <div
                style={{paddingLeft: "10px",display: props.showPharma}}>
                สำหรับเภสัชกร
              </div>
              <div
                style={{display: props.typePharma, flexDirection: "column", padding: "10px", backgroundColor: "#e8f5fd", borderRadius: "5px", height: "170px", justifyContent: "space-between"}}>

                <div>

                  <Radio
                    checked={props.pharmacyAction === "NOTED"}
                    label="NOTE"
                    onChange={props.onChangePharmacyAction}
                    value="NOTED">
                  </Radio>
                </div>
                <div>

                  <Radio
                    checked={props.pharmacyAction === "VERIFIED"}
                    label="VERIFY (สั่งยาได้)"
                    onChange={props.onChangePharmacyAction}
                    value="VERIFIED">
                  </Radio>
                </div>
                <div>

                  <Radio
                    checked={props.pharmacyAction === "LOCKED"}
                    label="VERIFY & LOCK (ห้ามสั่งยา)"
                    onChange={props.onChangePharmacyAction}
                    value="LOCKED">
                  </Radio>
                </div>
                <div>

                  <Radio
                    checked={props.adrActive}
                    label={props.adrActive ? "Active" : "INACTIVE"}
                    onChange={props.onChangePharmacyAction}
                    toggle={true}
                    value="is_inactive">
                  </Radio>
                </div>
                <div>

                  <div
                    style={{width: "100%"}}>
                    {props.inactiveDate}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{display: props.groupForm ?  "": "none", width: "100%", padding: "0px 20px 20px"}}>

          <div
            style={{display: "flex", width: "100%" }}>

            <div
              style={{ width: "50%", marginRight: "20px", }}>

              <div
                style={{ margin: "0px 20px 10px" }}>
                --เลือกจากตัวยา--
              </div>
              <div
                style={{backgroundColor: "rgba(217, 217, 217, 0.46)", overflowY: "auto", height: "450px", }}>
                {props.ingredientList}
              </div>
            </div>
            <div
              style={{ width: "50%", marginLeft: "20px", }}>

              <div
                style={{ margin: "0px 20px 10px" }}>
                --เลือกจากกลุ่มยา--
              </div>
              <div
                style={{backgroundColor: "rgba(217, 217, 217, 0.46)", overflowY: "auto", height: "450px", }}>
                {props.drugGroupList}
              </div>
            </div>
          </div>
          <div
            style={{display: "flex", justifyContent: "flex-end", alignItems: "Center", width: "100%", margin: "20px 0px", }}>

            <Button
              color="green"
              onClick={props.onSaveCommonADRNote}
              style={{ marginRight: "10px" }}>
              บันทึก
            </Button>
            <Button
              color="red"
              onClick={props.onCancelNewADRNote}>
              ยกเลิก
            </Button>
          </div>
        </div>
        <div>
          {props.modalNaranjo}
        </div>
      </div>
    )
}


export default CardAdverseReactionNoteUX

export const screenPropsDefault = {}

/* Date Time : Mon Dec 09 2024 10:28:45 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": "card-adverse-reaction-note"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "card-title"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.menu"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.adrSelected !== \"new\" ? \"แก้ไขรายละเอียดอาการไม่พึ่งประสงค์จากการใช้ยา\" : \"บันทึกรายละเอียดอาการไม่พึ่งประสงค์จากการใช้ยา\""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"25px\", width: \"100%\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.drugForm? \"flex\": \"none\", width: \"100%\", padding: \"20px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.otherForm? \"flex\": \"none\", width: \"100%\", padding: \"20px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"75%\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"center\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 54,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 54,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 55,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 55,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 56,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 56,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "สารก่อการแพ้*"
        },
        "className": {
          "type": "value",
          "value": "field-title red"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchAdversary"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "อาการแพ้"
        },
        "className": {
          "type": "value",
          "value": "field-title"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Dropdown",
      "parent": 77,
      "props": {
        "allowAdditions": {
          "type": "value",
          "value": ""
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "symptoms"
        },
        "onAddItem": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeADR"
        },
        "options": {
          "type": "code",
          "value": "props.adrSymptomsOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.symptoms"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท*"
        },
        "className": {
          "type": "value",
          "value": "field-title"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Dropdown",
      "parent": 80,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "adversary_symptom_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeADR"
        },
        "options": {
          "type": "code",
          "value": "props.affectTypeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.affectType"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "ความรุนแรง"
        },
        "className": {
          "type": "value",
          "value": "field-title"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเป็นไปได้"
        },
        "className": {
          "type": "value",
          "value": "field-title"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Dropdown",
      "parent": 90,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "severity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeADR"
        },
        "options": {
          "type": "code",
          "value": "props.adrSeverityOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.severity"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อความแจ้งเตือน*"
        },
        "className": {
          "type": "value",
          "value": "field-title"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "TextArea",
      "parent": 93,
      "props": {
        "name": {
          "type": "value",
          "value": "note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeADR"
        },
        "rows": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 94,
      "void": true
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"100%\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Checkbox",
      "parent": 95,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjoChecked"
        },
        "id": {
          "type": "value",
          "value": "CardAdverseReactionNote-Checkbox-NaranjoChecked"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjoChecked"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"8px\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Button",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "Naranjo Score"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.AllergySequence?.adrSelected?.naranjoChecked !== true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickNaranjo"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", paddingLeft: \"0.7rem\", paddingRight: \"0.7rem\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", alignItems: \"Center\", width: \"100%\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Button",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveNewADRNote"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Button",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelNewADRNote"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection:\"column\", width: \"100%\", height: \"100%\", alignItems: \"center\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "สำหรับเภสัชกร"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\",display: props.showPharma}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.typePharma, flexDirection: \"column\", padding: \"10px\", backgroundColor: \"#e8f5fd\", borderRadius: \"5px\", height: \"170px\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Radio",
      "parent": 107,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pharmacyAction === \"NOTED\""
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "NOTE"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePharmacyAction"
        },
        "value": {
          "type": "value",
          "value": "NOTED"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Radio",
      "parent": 108,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pharmacyAction === \"VERIFIED\""
        },
        "label": {
          "type": "value",
          "value": "VERIFY (สั่งยาได้)"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePharmacyAction"
        },
        "toggle": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "value",
          "value": "VERIFIED"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Radio",
      "parent": 109,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pharmacyAction === \"LOCKED\""
        },
        "label": {
          "type": "value",
          "value": "VERIFY & LOCK (ห้ามสั่งยา)"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePharmacyAction"
        },
        "value": {
          "type": "value",
          "value": "LOCKED"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Radio",
      "parent": 110,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.adrActive"
        },
        "label": {
          "type": "code",
          "value": "props.adrActive ? \"Active\" : \"INACTIVE\""
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePharmacyAction"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "value",
          "value": "is_inactive"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"75%\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormGroup",
      "parent": 116,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียด*"
        },
        "className": {
          "type": "value",
          "value": "field-title"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "TextArea",
      "parent": 121,
      "props": {
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeADR"
        },
        "rows": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.adversaryText"
        }
      },
      "seq": 122,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormGroup",
      "parent": 116,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 123,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "อาการแพ้*"
        },
        "className": {
          "type": "value",
          "value": "field-title"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "FormField",
      "parent": 123,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "TextArea",
      "parent": 127,
      "props": {
        "name": {
          "type": "value",
          "value": "note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeADR"
        },
        "rows": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 128,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormGroup",
      "parent": 116,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 137,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 137,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormField",
      "parent": 137,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", alignItems: \"Center\", width: \"100%\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Button",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveNewADRNote"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Button",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelNewADRNote"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "Dropdown",
      "parent": 95,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.naranjoChecked"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "probability"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeADR"
        },
        "options": {
          "type": "code",
          "value": "props.adrProbabilityOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.probability"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "code",
          "value": "props.inactiveDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.drugFreeText? \"flex\": \"none\", width: \"100%\", padding: \"20px\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"75%\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "div"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.groupForm ?  \"\": \"none\", width: \"100%\", padding: \"0px 20px 20px\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalNaranjo"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormGroup",
      "parent": 152,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormGroup",
      "parent": 152,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 156,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 156,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 157,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormField",
      "parent": 157,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการแพ้ยา*"
        },
        "className": {
          "type": "value",
          "value": "field-title"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", alignItems: \"Center\", width: \"100%\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "TextArea",
      "parent": 163,
      "props": {
        "name": {
          "type": "value",
          "value": "note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeADR"
        },
        "rows": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 165,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Button",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveNewADRNote"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Button",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelNewADRNote"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"center\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection:\"column\", width: \"100%\", height: \"100%\", alignItems: \"center\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": "สำหรับเภสัชกร"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\",display: props.showPharma}"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.typePharma, flexDirection: \"column\", padding: \"10px\", backgroundColor: \"#e8f5fd\", borderRadius: \"5px\", height: \"170px\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "code",
          "value": "props.inactiveDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Radio",
      "parent": 172,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pharmacyAction === \"NOTED\""
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "NOTE"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePharmacyAction"
        },
        "value": {
          "type": "value",
          "value": "NOTED"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Radio",
      "parent": 173,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pharmacyAction === \"VERIFIED\""
        },
        "label": {
          "type": "value",
          "value": "VERIFY (สั่งยาได้)"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePharmacyAction"
        },
        "value": {
          "type": "value",
          "value": "VERIFIED"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Radio",
      "parent": 174,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pharmacyAction === \"LOCKED\""
        },
        "label": {
          "type": "value",
          "value": "VERIFY & LOCK (ห้ามสั่งยา)"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePharmacyAction"
        },
        "value": {
          "type": "value",
          "value": "LOCKED"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Radio",
      "parent": 175,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.adrActive"
        },
        "label": {
          "type": "code",
          "value": "props.adrActive ? \"Active\" : \"INACTIVE\""
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePharmacyAction"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "value",
          "value": "is_inactive"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Input",
      "parent": 95,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", padding: \"0 0.5rem\", opacity: \"1\",}"
        },
        "value": {
          "type": "code",
          "value": "props.AllergySequence.adrSelected?.naranjo_data?.score !== undefined ?  props.AllergySequence.adrSelected?.naranjo_data?.score : \"\""
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width: \"100%\" }"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", marginRight: \"20px\", }"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", marginLeft: \"20px\", }"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "--เลือกจากตัวยา--"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 20px 10px\" }"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "--เลือกจากกลุ่มยา--"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 20px 10px\" }"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 186,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugGroupList"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(217, 217, 217, 0.46)\", overflowY: \"auto\", height: \"450px\", }"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ingredientList"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(217, 217, 217, 0.46)\", overflowY: \"auto\", height: \"450px\", }"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", alignItems: \"Center\", width: \"100%\", margin: \"20px 0px\", }"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "Button",
      "parent": 191,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveCommonADRNote"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Button",
      "parent": 191,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelNewADRNote"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่มีอาการ"
        },
        "className": {
          "type": "value",
          "value": "field-title"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 197,
      "name": "DateTextBox",
      "parent": 195,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateSymptoms"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.dateSymptoms"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "FormField",
      "parent": 198,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "FormField",
      "parent": 198,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "div",
      "parent": 199,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุอาการแพ้อื่นๆ"
        },
        "className": {
          "type": "value",
          "value": "field-title"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "div",
      "parent": 200,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "Input",
      "parent": 202,
      "props": {
        "name": {
          "type": "value",
          "value": "other_symptom"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeADR"
        },
        "value": {
          "type": "code",
          "value": "props.AllergySequence?.adrSelected?.other_symptom"
        }
      },
      "seq": 203,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardAdverseReactionNoteUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 90
}

*********************************************************************************** */
