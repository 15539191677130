import React, { useRef, useState, useEffect, Ref } from "react";
import { Modal, Form, Icon, Button, Grid, Dimmer, Loader } from "semantic-ui-react";

import Cookies from "js-cookie";
import ChatBox from "react-lib/apps/MSG/ChatBox";
import * as Chat from "react-lib/apps/IsHealth/Chat"
import { ChatControllerInterface } from "react-lib/apps/IsHealth/Chat/ChatControllerInterface";

import ErrorMessages from "../../common/ErrorMessage";
import { useHistory } from "react-router-dom";
import config from "config/config";
import { useIntl } from "react-intl";

type ChatSupportProps = {
  className: string;
  setProp: any;
  apiToken: any;
  controller: any;
  // ChatList
  chatListFetch: any;
  getLatestChatChannel: () => {};
  onSelectChat: () => {};

  // ChatBox
  messageFetch: any;
  userId?: string | null;
  chatChannelId?: string | number | null;
  onSendMessage: () => {};
  onOpenVideoCall: (url: string, chat_channel_id: string) => {};
  onNavigationMessage?: any;
  // ChatDetail
  chatUserFetch: any;
  fullname?: string | null;
  username?: string | null;

  // DiagFormHistroy
  diagFormController: any;
  classifyController: any;
  finishedTriageLevel: any[];
  allowCreateAppoint: boolean;

  isPatient: boolean;
};

const ChatSupport = (props: ChatSupportProps, ref: Ref<any>) => {
  const intl = useIntl();
  const history = useHistory();
  const isMounted = useRef(true);
  const chatListRef = React.useRef();
  const chatBoxRef = React.useRef();
  const [allClassify, setAllClassify] = React.useState([]);
  const [triageLevelList, setTriageLevelList] = React.useState<
    {
      key: string;
      text: string;
      value: string;
      triage_level?: string;
      code_color?: string;
      active?: boolean;
    }[]
  >([]);
  const [openModDiagHis, setOpenModDiagHis] = React.useState(false);
  const [chatChannelId, setChatChannelId] = React.useState<string>();

  React.useImperativeHandle(ref, () => ({
    chatListRef: chatListRef.current,
    chatBoxRef: chatBoxRef.current,
  }));

  React.useEffect(() => {
    handleGetClassify();
  }, [props.finishedTriageLevel, allClassify]);

  const handleGetClassify = async () => {
    if (!allClassify) {
      return;
    }
    let newArr = allClassify.filter((item) => {
      if (props.finishedTriageLevel && !props.finishedTriageLevel.includes(item.triage_level)) {
        return item;
      }
      return null;
    });
    let options = newArr.map(({ triage_level, description, active, codeColor }) => ({
      key: triage_level,
      value: triage_level,
      triage_level: triage_level,
      code_color: codeColor,
      text: description,
      active: active,
    }));
    setTriageLevelList([
      { key: "all", text: "ALL", value: "all" },
      { key: "default", text: "DEFAULT", value: "default" },
      { key: "app", text: "มีนัดหมาย", value: "app" },
      { key: "noApp", text: "ไม่มีนัดหมาย", value: "noApp" },
      ...options,
    ]);
  };

  React.useEffect(() => {
    const getTriageLevelClassify = async () => {
      const [response, error, network] = await props.controller.getTriageLevelClassify({
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      });
      if (!isMounted.current) {
        return;
      }
      if (response) {
        setAllClassify(response.classify);
      } else {
        setAllClassify([]);
        // setTriageLevelList([]);
      }
    };

    getTriageLevelClassify();
    return () => (isMounted.current = false);
  }, []);

  const handleModDiagHis = () => {
    setOpenModDiagHis(!openModDiagHis);
  };

  const handleSetChatChannel = (chatId: string) => {
    setChatChannelId(chatId);
  };

  console.log("saika ~ props:", props)

  return (
    <>
      <Grid className="ChatTable noMargin" celled>
        {/* <Table.Body>
        <Table.Row> */}
        <Grid.Column width={4} style={{ padding: 0 }} className="chatList"> 
          <Chat.ChatList
            {...props}
            match={{
              params: { chatChannelId: chatChannelId },
            }}
            ref={chatListRef}
            hideReclassify={true}
            controller={props.controller}
            userId={props.userId}
            apiToken={props.apiToken}
            division={props.division}
            finishedTriageLevel={props.finishedTriageLevel}
            triageLevelList={triageLevelList}
            usePatientId={true}
            hideFilterDate={true}
            unsignedOnly={config.UNSIGNED_ONLY}
            unsignedFilter={config.UNSIGNED_FILTER}
            userState={true}
            handleSetChatChannel={handleSetChatChannel}
            signedOnly={true}
          />
        </Grid.Column>
        <Grid.Column width={12} style={{ padding: 0 }} className="chatBox">
          {chatChannelId ? (
            <>
              <ChatBox
                ref={chatBoxRef}
                // controller
                match={{
                  params: { chatChannelId: chatChannelId },
                }}
                controller={props.controller}
                classifyController={props.classifyController}
                diagFormController={props.diagFormController}
                // data
                messageViewStyle={{ height: "81vh" }}
                userId={props.userId}
                apiToken={props.apiToken}
                fullname={props.fullname}
                division={props.division}
                isPatient={false}
                nullId={false}
                readOnly={false}
                username={props.username}
                // encounterId={context.state.selectedEncounter?.id}
                match={{
                  params: {
                    chatChannelId: chatChannelId,
                  },
                }}
                // patientId={context.state.selectedPatient?.id}
                patientData={{}}
                // config
                hideButtonMenu={true}
                // callback
                onDidMount={() => props.setProp("chatDetail.unread_message_count", 0)}
                enHasNoDiv={() => {}}
                enHasDiv={() => {}}
                onNavigationMessage={({ content }: any) => content}
                onCallGetLastMessage={() => {}}
                onOpenVideoCall={(url: string) => {
                  props.setProp(
                    "currChatChannelId", chatChannelId
                  );
                  console.log("saika ~ url:", chatChannelId)
                  props.onOpenVideoCall(url, chatChannelId)
                }}
                onCloseVideoCall={() => {}}
                onSetChannelName={() => {}}
              />
            </>
          ) : null}
        </Grid.Column>
        {/* <Grid.Column width={4} style={{ padding: 0 }} className="chatDetail">
          <iframe src="https://staging.penguin.drjaysayhi.com/" frameBorder='no' />
       </Grid.Column> */}
        {/* </Table.Row> */}
        {/* </Table.Body> */}
        {/* <Modal open={openModDiagHis} closeOnDimmerClick onClose={handleModDiagHis} size="large">
        <CardDiagFormHistory
          {...props}
          controller={props.diagFormController}
          hideCallback={handleModDiagHis}
          // userId={props.userId}
          // division={props.division}
          // apiToken={props.apiToken}
        />
       </Modal> */}
      </Grid>
    </>
  );
};

const ForwardedCardChatSupport = React.forwardRef<any, ChatSupportProps>(
  ChatSupport
);

ChatSupport.displayName = "ChatSupport";
export default React.memo(ForwardedCardChatSupport);
