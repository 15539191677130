import WasmController from "react-lib/frameworks/WasmController";

import RehabPlanList from "issara-sdk/apis/RehabPlanList_apps_RHB";
import RehabPlanDetail from "issara-sdk/apis/RehabPlanDetail_apps_RHB";
import Icd9cmList from "issara-sdk/apis/Icd9cmList_core";

export type RehabPlanType = {
  problem: string;
  goal: string;
  precaution: string[];
  order: Record<string, any> | null;
  items: {
    id: number;
    product: number;
    to_department: number;
    detail: string;
    frequency: number;
    duration: number;
    total: number;
    icd9_type: "PRIMARY" | "SECONDARY";
    medical_description: string;
    icd9cm: number;
    icd9cm_med_term: number;
    status: "P" | "C";
    canceled_reason: string;
    icd9Table: any[];
    images: { image: string }[];
  }[];
  id?: number;
  encounter: number;
  order_by: { id: number; name: string };
  total: number;
  next: any;
  previous: any;
};

export type State = {
  rehabExamPlan?: RehabPlanType;
};

export const StateInitial: State = {
  rehabExamPlan: undefined,
};

export type Event = { message: "createUpdateRhbExamPlan"; params: any };

export type Data = {
  division: any;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const createUpdateRhbExamPlan: Handler = async (controller, params) => {
  //   "encounter": 5367,
  // "problem": "1dayleft",
  // "goal": "Updated goal text",
  // "precaution": "Updated precaution text",
  // "order_by": 2,
  // "items": [
  //     {
  //         "product": 294724,
  //         "to_department": 3,
  //         "detail": "Updated plan item detail",
  //         "frequency": 3,
  //         "duration": 5,
  //         "total": 15,
  //         "icd9_type": "PRIMARY",
  //         "medical_description": "Updated medical description",
  //         "icd9cm": 1,
  //         "icd9cm_med_term": 1,
  //         "images": [
  //             {
  //                 "file": ""
  //             }
  //         ]
  //     }
  // ]

  let state = controller.getState();

  console.log("createUpdateRhbExamPlan params", params);
  let items = params?.items?.map((i: any) => ({
    product: i.product,
    to_department: i.to_department,
    detail: i.detail,
    frequency: i.frequency,
    duration: i.duration,
    total: i.total,
    icd9_type: i.icd9_type,
    medical_description: i?.icd9Data?.icdterm,
    ...(i.icd9Data?.icd10_id && { icd9cm: i.icd9Data?.icd10_id }),
    ...(i.icd9Data?.medterm_id && { icd9cm_med_term: i.icd9Data?.medterm_id }),
    images: [],
  }));

  if (params?.id) {
    /// Update

    const [res, err, net] = await RehabPlanDetail.update({
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
      pk: params.id,
      data: {
        encounter: state?.selectedEncounter?.id,
        problem: params.problem,
        goal: params.goal,
        precaution: params.precaution,
        items,
      },
    });

    console.log("createUpdateRhbExamPlan Update res: ", res);
    console.log("createUpdateRhbExamPlan Update err: ", err);
  } else {
    /// Create

    const [res, err, net] = await RehabPlanList.create({
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
      data: {
        encounter: state?.selectedEncounter?.id,
        problem: params.problem,
        goal: params.goal,
        precaution: params.precaution,
        items,
      },
    });

    console.log("createUpdateRhbExamPlan Create res: ", res);
    console.log("createUpdateRhbExamPlan Create err: ", err);
  }

  // data.patient = controller.getState().selectedEncounter.patient_id
  // if (action) data.action = action;
  // data.note = note;
  // return await AdverseReactionList.create({
  //   params: params,
  //   data: data,
  //   apiToken: controller.apiToken
  // });
};

export const getRhbExamPlan: Handler = async (controller, params) => {
  console.log("getRhbExamPlan: ");

  const combileICDTable = async (rehab: any) => {
  
    // let icd9Table = []
    // if (rehab?.items?.length > 0) {
    //   for await (const item of rehab?.items) {
    //     let [res, err, net] = await Icd9cmList.list({
    //       extra: { division: controller.data.division },
    //       apiToken: controller.apiToken,
    //       params: {
    //         search: item.medical_description,
    //       },
    //     });
    //     if (!err) {
    //       let find = res?.items?.find((i: any) => i.id === item?.icd9cm);
    //       if (find) {
    //         icd9Table.push(find)
    //       }
    //     } 

    //     console.log("getRhbExamPlan combileICDTable res", res);
    //   }
    // }

    // return {...rehab, icd9Table};

    let items = [];
    // let icd9Table = []

    if (rehab?.items?.length > 0) {
      for await (const item of rehab?.items) {
        let [res, err, net] = await Icd9cmList.list({
          extra: { division: controller.data.division },
          apiToken: controller.apiToken,
          params: {
            search: item.medical_description,
          },
        });
        if (!err) {
          let find = res?.items?.find((i: any) => i.id === item?.icd9cm);
          if (find) {
            // icd9Table.push(find)
            items.push({
              ...item,
              icd9Data: {
                icd10_id: find?.id,
                icdcode: find?.code,
                icdterm: find?.term,
              },
            });
          } else {
            items.push(item);
          }
        } else {
          items.push(item);
        }

        console.log("getRhbExamPlan combileICDTable res", res);
      }
    }

    let modRehab = {
      ...rehab,
      // icd9Table,
      items,
    };
    return modRehab;
  };

  let state = controller.getState();

  const [res, err, net] = await RehabPlanList.list({
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
    params: {
      encounter: state?.selectedEncounter?.id,
      patient: state?.selectedPatient?.patient_id,
    },
  });

  if (!err) {
    if (res?.items?.length > 1) {
      let rehab = res?.items?.[res?.items?.length - 1];
      console.log('getRhbExamPlan rehab1: ', rehab);
      rehab = await combileICDTable(rehab);
      console.log('getRhbExamPlan rehab2: ', rehab);
      controller.setState({ rehabExamPlan: rehab });
    } else if (res?.items?.length === 1) {
      let rehab = res?.items?.[0];
      console.log('getRhbExamPlan rehab3: ', rehab);
      rehab = await combileICDTable(rehab);
      console.log('getRhbExamPlan rehab4: ', rehab);
      controller.setState({ rehabExamPlan: rehab });
    } else {
      controller.setState({ rehabExamPlan: undefined });
    }
  }
};



export const clearRhbExamPlan: Handler = async (controller, params) => {
  console.log("clearRhbExamPlan: ");
  let state = controller.getState();
  controller.setState({ rehabExamPlan: undefined})
}