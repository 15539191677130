import WasmController from "react-lib/frameworks/WasmController";
// interface
import * as TPDI from "../TPDInterface";
import * as ModPlanningI from "../../common/ModPlanningInterface";

// apis
import DrugDetail from "issara-sdk/apis/DrugDetail_apps_TPD";
import DrugInteractionCheck from "issara-sdk/apis/DrugInteractionCheck_apps_ADR";
import DrugLabInteractionCheck from "issara-sdk/apis/DrugLabInteractionCheck_apps_ADR";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import DrugDiseaseCheck from "issara-sdk/apis/CheckDrugDisease_apps_PHR";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import DoctorPendingTaskList from "issara-sdk/apis/DoctorPendingTaskList_apps_DPO";

// card id
import { CARD_DRUG_CONT_ID } from "../CardDrugContinuePlan";
import { EvaluateAdverseReaction } from "../ModSolventSelectionInterface";

export const DRUG_INTERACTION_IN = {
  ORDER: 1,
  TRIAGE: 2,
};

export type State = {
  // CommonInterface
  masterOptions?: any; // Obtained from event GetMasterData
  drugDose?: string | number;
  currentDoctor?: any;
  errorMessage?: any;
  // permission
  drugPermission?: any;

  verbalDrugOrderList?: any[];

  // triage
  drugTriage?: any;

  // selected
  selectedEncounter?: any;
  selectedPatient?: any;
  selectedEmr?: any;
  selectedDrug?: any | null;

  // search
  drugSearchText?: string;

  // order
  drugOrder?: any;

  // seq
  DrugSelectSequence?: {
    sequenceIndex: string | null;
    id?: number;
    selectedDrug?: any | null;

    // ADR
    adrType?: string | null;
    solventAdrNotes?: string | null;
    note_adverse_reaction?: string | null;
    solvent_note_adverse_reaction?: string | null;
    adrNotes?: string | null;

    // DI
    diFeedback?: boolean;
    diLock?: boolean;
    diItems?: any[];
    confirmDINote?: string | null;
    confirmDLINote?: string | null;
    confirmDDNote?: string | null;
    isTriageDrug?: boolean | null;

    limited_age?: string | null;
    note_limited_age?: string | null;

    // Drug Transport
    drugTransportNotAllowed?: any;
    isDrugTransportNotAllowed?: boolean;

    // Master
    drugUnits?: any[];
    drugRoutes?: any[];
    drugSites?: any[];
    drugFrequencies?: any[];
    drugMethods?: any[];
    divisionList?: any[];
    priority: string;
    standardAdminTimeOptions?: any[];

    selectedDrugUnitId?: any | null;
    selectedDrugRouteId?: any | null;
    selectedDrugSiteId?: any | null;
    selectedDrugFrequencyId?: any | null;
    selectedDrugMethodId?: any | null;

    claim_payload?: any;

    // Drug cont
    admin_time?: any[];
    dose?: string;
    extra_note?: string;
    frequency?: string;
    method?: string;
    route?: string;
    site?: string;
    standard_admin_time?: string;
    unit?: string;
    calculated_daily_quantity?: string;
    prn?: string;
    sending_interval?: string;
    sending_quantity?: string;
    stock_unit_name?: string;
    disabledClickSearchDrugTable?: boolean;
    // solvent
    is_intravaneous?: boolean;
    default_solvent?: number | null;
    default_solvent_quantity?: number | null;
    default_iv_rate?: number | null;
    solvent?: number | null;
    solvent_product?: number | null;
    solvent_quantity?: number | null;
    strength?: number | null;
    strength_unit?: number | null;
    quantity?: number | null;
    volume?: number | null;
    drug_concentration_unit?: number | null;
    drug_drip_in?: number | null;
    drug_drip_in_unit?: number | null;
    // iv_rate?: number | null;
    // iv_rate_unit?: number | null;
    mixture_solvent_volume?: number | null;
    mixture_iv_rate?: number | null;
    mixture_iv_rate_unit?: any;
    mixture_concentration_solute?: any;
    mixture_concentration_solvent?: any;
    old_quantity?: number | null;
    old_rate?: number | null;

    note?: string;

    // action log
    actionLogs?: { open?: boolean; data?: any };
  } | null;

  // drug lab interaction modal
  modDrugLabInteraction?: {
    open?: boolean;
    interactionItems?: any[];
    orderItem?: any;
    note?: string;
  };

  // drug disease interaction modal
  modDrugDisease?: {
    open?: boolean;
    diseaseItems?: any[];
    orderItem?: any;
    note?: string;
  };

  // drug interaction modal
  modDrugInteraction?: {
    open?: boolean;
    drugItems?: any[];
    orderItem?: any;
    note?: string;
  };

  modSolventSelectionConfig?: any;
  isStudentUser?: boolean;
  django?: any;
};

export const StateInitial: State = {
  // CommonInterface
  masterOptions: {}, // Obtained from event GetMasterData

  // permission
  drugPermission: null,

  // triage lastest drug
  drugTriage: [],

  // seq
  DrugSelectSequence: {
    sequenceIndex: "",
    selectedDrug: null,

    // ADR
    adrType: null,
    note_adverse_reaction: null,
    adrNotes: null,

    // DI
    diFeedback: false,
    diLock: false,
    diItems: [],
    confirmDINote: null,

    limited_age: null,
    note_limited_age: null,

    // Master
    drugUnits: [],
    drugRoutes: [],
    drugSites: [],
    drugFrequencies: [],
    drugMethods: [],
    standardAdminTimeOptions: [],

    selectedDrugUnitId: null,
    selectedDrugRouteId: null,
    selectedDrugSiteId: null,
    selectedDrugFrequencyId: null,
    selectedDrugMethodId: null,

    claim_payload: null,

    // drug cont
    admin_time: [],
    dose: "",
    extra_note: "",
    frequency: "",
    method: "",
    route: "",
    site: "",
    standard_admin_time: "",
    unit: "",
    calculated_daily_quantity: "",
    prn: "",
    sending_quantity: "",
    sending_interval: "",
    stock_unit_name: "",
    disabledClickSearchDrugTable: false,
    // solvent
    is_intravaneous: false,
    default_solvent: null,
    default_solvent_quantity: null,
    default_iv_rate: null,
    solvent: null,
    solvent_product: null,
    solvent_quantity: null,
    strength: null,
    strength_unit: null,
    quantity: null,
    volume: null,
    drug_concentration_unit: null,
    drug_drip_in: null,
    drug_drip_in_unit: null,
    // iv_rate: null,
    // iv_rate_unit: null,
    mixture_solvent_volume: null,
    mixture_iv_rate: null,
    mixture_iv_rate_unit: null,
    mixture_concentration_solute: null,
    mixture_concentration_solvent: null,
    old_quantity: null,
    old_rate: null,

    note: "",
    // action log
    actionLogs: { open: false, data: {} },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any }
  | { message: "ClearDrugOrderForm"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  console.log("DrugSelect Start");
  let state = controller.getState();
  if (!state.DrugSelectSequence) return;

  // Fetch master data, then transform into state.masterOptions[xxx]
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["unit", {}],
        ["route", {}],
        ["site", {}],
        ["frequency", {}],
        ["method", {}],
        ["duplicateReasons", {}],
        ["conversion", {}],
        ["standardAdminTime", {}],
        ["shippingEmployee", {}],
        ["divisionForOrderDiv", {}],
        ["drug_limited_age", {}],
        ["prn", {}],
        ["drugAllergyReason", {}],
        ["drugInteractReason", {}],
        ["drugDiseaseReason", {}],
        ["drugLabReason", {}],
        ["storage", {}],
        ["rxEditReason", {}],
      ],
    },
  });

  if (params?.card === CARD_DRUG_CONT_ID) {
    await controller.handleEvent({ message: "GetDrugContinuePlan" as any, params: {} });
  }

  const [latestTriage, orderBy, verbalOrder, permission] = await Promise.all([
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedEncounter?.id,
        form_code: "CardPreAssessment",
        form_version: "1.0",
      },
    }),
    TPDI.getDefaultOrderBy(controller as any, params),
    DoctorPendingTaskList.list({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedEncounter?.id,
        order_specific_types: "DRUG_ORDER",
      },
    }),
    GetDrugPermission(controller, params),
  ]);

  state = controller.getState();

  let divisionId: number | null = null;

  const hasDrugOperating = state.preOrderList?.some(
    (item: any) => item.type === "drugoperatingorder"
  );

  // ถ้าเป็นห้องผ่าตัด
  if (state.selectedOrOrder?.division_code) {
    if (hasDrugOperating) {
      divisionId = state.drugOrder.order_div || null;
    } else {
      divisionId =
        state.divisionList?.find((item: any) => item.code === state.selectedOrOrder?.division_code)
          ?.id || null;
    }
  } else if (state.selectedEncounter?.division) {
    // ถ้าไม่มี selectedEncounter ให้เช็ค drugOrder
    divisionId = state.selectedEncounter.division;
  } else if (state.drugOrder) {
    divisionId =
      state.drugOrder.order_div === "1" ? controller.data.division : state.drugOrder.order_div;
  }

  controller.setState({
    drugPermission: permission,
    DrugSelectSequence: {
      ...state.DrugSelectSequence,
      sequenceIndex: "CheckDrugTransport",
      divisionList: [],
    },
    verbalDrugOrderList: verbalOrder?.[0]?.items || [],
    drugOrder: {
      ...state.drugOrder,
      order_div: divisionId,
      ...orderBy,
    },
    drugTriage: [...(latestTriage[0]?.data?.medication?.present_drugs || [])],
    ...(!state.isStudentUser && {
      isStudentUser: state.selectedEncounter?.student_user === state.django?.user?.id,
    }),
  });
};

export const CheckDrugTransport: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.DrugSelectSequence || !params.selectedDrug) {
    return;
  }

  let drugTransportNotAllowed = null;

  if (state.drugOrder?.telephar_type === "DELIVERY" && !params.skipChecking) {
    const drugTransport = await TPDI.GetDrugNotAllowTransports(controller as any, {
      drugs: [{ product: state.selectedDrug?.id }],
    });

    drugTransportNotAllowed = drugTransport.items[0] || null;
  }

  // Will pass through if fetch error or adr not in the above list of statuses
  const dl = state.DrugSelectSequence.divisionList;
  const prevPriority = state.DrugSelectSequence.priority;

  state = controller.getState();

  let sequenceIndex = "CheckADR";

  if (drugTransportNotAllowed) {
    sequenceIndex = "FeedbackDrugTransport";
  } else if (params.skipChecking) {
    sequenceIndex = "GetDrugDetail";
  }

  if (params.checkDrugTransferOnly && !drugTransportNotAllowed) {
    return;
  }

  controller.setState(
    {
      DrugSelectSequence: params.checkDrugTransferOnly
        ? { ...state.DrugSelectSequence, sequenceIndex, drugTransportNotAllowed }
        : {
            sequenceIndex,
            disabledClickSearchDrugTable: !!state.DrugSelectSequence?.disabledClickSearchDrugTable,
            divisionList: dl,
            drugTransportNotAllowed,
            prevPriority,
            priority: params.selectedDrug?.priority || prevPriority,
            selectedDrug: params.selectedDrug,
          },
    },
    () => {
      // Go right to next sequence if there is no adr to process
      if (!drugTransportNotAllowed && !params.checkDrugTransferOnly) {
        controller.handleEvent({ message: "RunSequence", params });
      }
    }
  );
};

export const FeedbackDrugTransport: Handler = (controller, params) => {
  const state = controller.getState();

  if (["cancel"].includes(params?.feedback || "")) {
    controller.handleEvent({ message: "ClearDrugOrderForm", params: {} });

    return;
  }

  const selectedDrug = state.DrugSelectSequence?.selectedDrug;

  controller.setState(
    {
      DrugSelectSequence: {
        ...state.DrugSelectSequence,
        sequenceIndex: "CheckADR",
        drugTransportNotAllowed: null,
        isDrugTransportNotAllowed: true,
      },
      drugOrder: {
        ...state.drugOrder,
        telephar_type: "NONE",
      },
      errorMessage: { ...state.errorMessage, [params.card]: null },
    },
    () => {
      controller.handleEvent({
        message: "RunSequence",
        params: { ...params, selectedDrug },
      });
    }
  );
};

export const CheckADR: Handler = async (controller, params) => {
  let state = controller.getState();
  console.log("DrugSelect CheckADR: ");

  if (!state.DrugSelectSequence) return;
  if (!params.selectedDrug) return;

  // กัน debounce ปุ่ม
  if (params.card === CARD_DRUG_CONT_ID) {
    console.log("DrugSelect CheckADR: debounce ปุ่ม ");
    controller.setState({
      DrugSelectSequence: {
        ...state.DrugSelectSequence,
        disabledClickSearchDrugTable: true,
      },
    });
  }

  ModPlanningI.handleSetDefaultPlanningData(controller as any);

  const { adr, isAdrToProcess } = await EvaluateAdverseReaction(controller as any, { ...params });

  state = controller.getState();

  controller.setState(
    {
      DrugSelectSequence: {
        ...state.DrugSelectSequence,
        sequenceIndex: isAdrToProcess ? "FeedbackADR" : "CheckDI",
        adrNotes: adr?.notes,
        adrType: isAdrToProcess ? adr?.status : null,
      },
    },
    () => {
      // Go right to next sequence if there is no adr to process
      !isAdrToProcess && controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const FeedbackADR: Handler = async (controller, params) => {
  console.log("FeedbackADR: ");
  const state = controller.getState();

  if (!state.DrugSelectSequence) {
    return;
  }

  const isSolventActive = state.modSolventSelectionConfig?.activeButtonSolvent;

  if (["acknowledge", "cancel"].includes(params?.feedback || "")) {
    if (isSolventActive) {
      controller.setState({
        DrugSelectSequence: {
          ...state.DrugSelectSequence,
          sequenceIndex: "CheckDrugTransport",
          adrType: null,
          note_adverse_reaction: "",
          solvent_note_adverse_reaction: "",
          solventAdrNotes: "",
        },
        modSolventSelectionConfig: {
          ...state.modSolventSelectionConfig,
          quantity: null,
          selectedSolvent: null,
          solvent: null,
          unit: null,
          volume: null,
        },
      });
    } else {
      controller.handleEvent({ message: "ClearDrugOrderForm", params: {} });
    }

    controller.setState({
      // DrugSelectSequence: null, // มันไปเซ็ตใหม่ ใน ClearDrugOrderForm อยู่แล้ว แล้วก็ต้องไม่ใช่เซ็ตเป็น null ด้วย
      errorMessage: { ...state.errorMessage, [params.card]: null },
    });

    return;
  }

  const note = isSolventActive
    ? state.DrugSelectSequence.solvent_note_adverse_reaction
    : state.DrugSelectSequence.note_adverse_reaction;

  if (params.feedback === "confirm" && !note) {
    const { selectedDrug } = state.DrugSelectSequence;
    const name = formatDrugName(selectedDrug);

    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: {
          error: {
            [selectedDrug.drug_name_from_format]: [
              "เนื่องจากผู้ป่วยแพ้ยานี้ กรุณาระบุหมายเหตุการใช้ยา",
            ],
          },
          showMessage: true,
        },
      },
    });

    return;
  }

  if (isSolventActive) {
    controller.setState({
      DrugSelectSequence: {
        ...state.DrugSelectSequence,
        sequenceIndex: "CheckDrugTransport",
        adrType: null,
      },
      errorMessage: { ...state.errorMessage, [params.card]: null },
    });
  } else {
    controller.setState(
      {
        DrugSelectSequence: {
          ...state.DrugSelectSequence,
          sequenceIndex: "CheckDI", // To go to step 2
          adrType: null, // To turn of ModCheckADR
        },
        errorMessage: { ...state.errorMessage, [params.card]: null },
      },
      () => {
        controller.handleEvent({ message: "RunSequence", params });
      }
    );
  }
};

export const CheckDI: Handler = async (controller, params) => {
  console.log('CheckDI: ');
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  const di = await DrugInteractionCheck.list({
    params: {
      drugs: [state.DrugSelectSequence.selectedDrug.id]
        .concat((state.drugOrder?.items || []).map((item: any) => item.product))
        .join(","),
      patient: state.selectedPatient?.id,
    },
    apiToken: controller.apiToken,
  });
  // If no items or error go through to GetDrugDetail else go to FeedbackDI
  const items = (di[0]?.items || []).filter((item: any) =>
    item.members.includes(state.DrugSelectSequence?.selectedDrug.id)
  );
  const diFeedback = !di[1] && items.length > 0;
  console.log(items);
  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: diFeedback ? "FeedbackDI" : "CheckDrugLabInteraction", // To go to step 3
        diItems: items,
        // diFeedback: diFeedback,
        diLock: items.filter((item: any) => item.is_lock).length > 0,
      },
      modDrugInteraction: {
        ...state.modDrugLabInteraction,
        open: diFeedback,
        drugItems: items,
      },
    },
    () => {
      !diFeedback && controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const FeedbackDI: Handler = async (controller, params) => {
  console.log('FeedbackDI: ');
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  if (["cancel", "acknowledge"].includes(params?.feedback || "")) {
    controller.handleEvent({ message: "ClearDrugOrderForm", params: {} });
    controller.setState({
      // DrugSelectSequence: null, // มันไปเซ็ตใหม่ ใน ClearDrugOrderForm อยู่แล้ว แล้วก็ต้องไม่ใช่เซ็ตเป็น null ด้วย
      modDrugInteraction: {
        open: false,
        drugItems: [],
        orderItem: {},
        note: "",
      },
    });
    return;
  }

  let drugInteractionData: any = [];
  state.modDrugInteraction?.drugItems?.forEach((drugI: any) => {
    drugInteractionData = drugInteractionData.concat(drugI.members);
  });

  let isTriageData = state.drugTriage?.some((drug: any) => {
    return drugInteractionData.includes(drug.id);
  });
  controller.setState(
    {
      drugOrder: {
        ...state.drugOrder,
        items: [...(state.drugOrder.items || [])].map((item: any) =>
          drugInteractionData.includes(item.product)
            ? { ...item, note_drug_interaction: params?.note }
            : { ...item }
        ),
      },
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: "CheckDrugLabInteraction", // To go to step 2
        // diFeedback: false, // To turn of ModCheckADR
        confirmDINote: params?.note,
        isTriageDrug: isTriageData,
      },
      modDrugInteraction: {
        open: false,
        drugItems: [],
        orderItem: {},
        note: "",
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const CheckDrugLabInteraction: Handler = async (controller, params) => {
  console.log('CheckDrugLabInteraction: ');
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  const dli = await DrugLabInteractionCheck.get({
    apiToken: controller.apiToken,
    params: {
      drugs: state.DrugSelectSequence.selectedDrug.id,
      patient: (state.selectedPatient as any)?.id,
    },
    extra: {
      division: controller.data?.division,
      device: (controller.data as any)?.device,
    },
  });
  const items = dli[0]?.items || [];
  const dliFeedBack = !dli[1] && items.length > 0;
  // console.log(items);
  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: dliFeedBack ? "FeedbackDrugLabInteraction" : "CheckDrugDisease", // To go to step 4
      },
      modDrugLabInteraction: {
        ...state.modDrugLabInteraction,
        open: dliFeedBack,
        interactionItems: items,
      },
    },
    () => {
      !dliFeedBack && controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const FeedbackDrugLabInteraction: Handler = (controller, params) => {
  console.log('FeedbackDrugLabInteraction: ');
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  if (params?.action === "cancel" || !state.modDrugLabInteraction?.note) {
    controller.handleEvent({ message: "ClearDrugOrderForm", params: {} });
    controller.setState({
      // DrugSelectSequence: null, // มันไปเซ็ตใหม่ ใน ClearDrugOrderForm อยู่แล้ว แล้วก็ต้องไม่ใช่เซ็ตเป็น null ด้วย
      modDrugLabInteraction: {
        open: false,
        interactionItems: [],
        orderItem: {},
        note: "",
      },
    });
    return;
  }
  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: "CheckDrugDisease",
        confirmDLINote: params?.note,
      },
      modDrugLabInteraction: {
        open: false,
        interactionItems: [],
        orderItem: {},
        note: "",
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const CheckDrugDisease: Handler = async (controller, params) => {
  console.log('CheckDrugDisease: ');
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  const dli = await DrugDiseaseCheck.get({
    apiToken: controller.apiToken,
    params: {
      drugs: state.DrugSelectSequence.selectedDrug.id,
      patient: (state.selectedPatient as any)?.id,
    },
    extra: {
      division: controller.data?.division,
      device: (controller.data as any)?.device,
    },
  });
  const items = dli[0]?.items || [];
  const ddFeedBack = !dli[1] && items.length > 0;
  // console.log(items);
  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: ddFeedBack ? "FeedbackDrugDisease" : "GetDrugDetail", // To go to step 4
      },
      modDrugDisease: {
        ...state.modDrugDisease,
        open: ddFeedBack,
        diseaseItems: items,
      },
    },
    () => {
      !ddFeedBack && controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const FeedbackDrugDisease: Handler = (controller, params) => {
  console.log('FeedbackDrugDisease: ');
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  if (params?.action === "cancel" || !state.modDrugDisease?.note) {
    controller.handleEvent({ message: "ClearDrugOrderForm", params: {} });
    controller.setState({
      // DrugSelectSequence: null, // มันไปเซ็ตใหม่ ใน ClearDrugOrderForm อยู่แล้ว แล้วก็ต้องไม่ใช่เซ็ตเป็น null ด้วย
      modDrugDisease: {
        open: false,
        diseaseItems: [],
        orderItem: {},
        note: "",
      },
    });
    return;
  }
  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: "GetDrugDetail",
        confirmDDNote: params?.note,
      },
      modDrugDisease: {
        open: false,
        diseaseItems: [],
        orderItem: {},
        note: "",
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const GetDrugDetail: Handler = async (controller, params) => {
  console.log('GetDrugDetail params: ', params);
  let state = controller.getState();

  if (!state.DrugSelectSequence) {
    return;
  }

  let [drugDetail, e, _n] = await DrugDetail.retrieve({
    pk: state.DrugSelectSequence.selectedDrug.id,
    apiToken: controller.apiToken,
  });
  if (e) {
    return console.log(`Error: ${e.toString()}`);
  }

  const drug = state.DrugSelectSequence.selectedDrug

  const defaultProperties = [
    "dosage_unit",
    "frequency",
    "method",
    "route",
    "site",
    "unit_dose",
    "prn",
    "description",
    "quantity",
    "duration"
  ];

  const updatedProperties: Record<string, any> = {};

  const prn: Record<string, any>[] = state.masterOptions?.prn || [];
  const matchedPrn = prn.find((item: any) => item.value === drugDetail.default_prn);

  updatedProperties.default_prn = matchedPrn ? matchedPrn.text : "";

  for (const key of defaultProperties) {
    const fullProp = `default_${key}`;

    if (drug[fullProp]) {
      updatedProperties[fullProp] = drug[fullProp];
    }
  }

  const r = {
    ...drugDetail,
    ...updatedProperties,
    index: drug.index,
  };

  const orderType = TPDI.GetDrugOrderType(controller as any, params)

  // Filter master options for selected drug
  await controller.handleEvent({
    message: "SetDrugContinueOptions",
    params: { drugResp: r, orderType },
  } as any);

  state = controller.getState();

  console.log(
    "--- GetDrugDetail state.DrugSelectSequence?.selectedDrug: ",
    state.DrugSelectSequence?.selectedDrug
  );
  console.log("--- GetDrugDetail r:", state, params, state.DrugSelectSequence?.drugFrequencies);

  let frequencyId = r.default_frequency;

  // เฉพาะ STAT (Med Order) ส่ง orderType มา, Continue Med (IPD) ไม่ส่ง orderType มา
  if (orderType?.includes("STAT") && !drug.product) {
    frequencyId =
      state.DrugSelectSequence?.drugFrequencies?.find((option) => option.text.includes("STAT"))
        ?.value || null;
  }

  const isSetDuration = orderType?.includes("HOME") || orderType?.includes("OPERATING");

  controller.setState(
    {
      drugSearchText: state.DrugSelectSequence?.selectedDrug?.full_name,
      selectedDrug: {
        ...state.DrugSelectSequence?.selectedDrug,
        note_adverse_reaction: state.DrugSelectSequence?.note_adverse_reaction,
        note_drug_interaction: state.DrugSelectSequence?.confirmDINote,
        note_drug_lab_interaction: state.DrugSelectSequence?.confirmDLINote,
        note_drug_disease: state.DrugSelectSequence?.confirmDDNote,
        drug_interaction_in: state.DrugSelectSequence?.isTriageDrug ? "TRIAGE" : "ORDER",
        product_name: state.DrugSelectSequence?.selectedDrug?.full_name,
        factsheet: !r.factsheet || r.factsheet === "" ? null : r.factsheet,
        original: r,
      },
      DrugSelectSequence: {
        ...state.DrugSelectSequence,
        ...r,
        // seq
        sequenceIndex: "CheckDrugTransport",
        // drug data
        selectedDrugFrequencyId: frequencyId,
        selectedDrugMethodId: r.default_method,
        selectedDrugRouteId: r.default_route,
        selectedDrugSiteId: r.default_site,
        selectedDrugUnitId: r.default_dosage_unit,
        // drug cont
        id: null,
        dose: "",
        frequency: r.default_frequency,
        method: r.default_method,
        route: r.default_route,
        site: r.default_site,
        unit: r.default_dosage_unit,
        prn: r.default_prn,
        default_frequency: drugDetail?.default_frequency,
      },
      drugDescription: r.default_description,
      drugPrn: r.default_prn,
      drugQty: r.default_quantity,
      drugDose: Number(r.default_unit_dose) ? r.default_unit_dose.toString() : "",
      ...(isSetDuration && { drugDuration: r.default_duration }),
    },
    () => {
      if (params?.card === CARD_DRUG_CONT_ID) {
        TPDI.PreviewDrugContinuePlan(controller as any, params);
        TPDI.ClaimDrugContinuePlan(controller as any, params);
      } else {
        controller.handleEvent({ message: "CheckShowButtonSolvent" } as any);
      }
    }
  );
};

export const GetDrugPermission: Handler = async (controller, params) => {
  const [result] = await UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_TPD_ENABLE_IV_COMPATIBLE: false,
      config_TPD_PRESCRIPTION_FREQUENCY_OTHER_CODE: "OTH",
      action_TPD_DrugContinuePlan_REQUEST: false,
      action_TPD_DrugContinuePlan_EDIT: false,
      action_TPD_DrugContinuePlan_EDIT_QUANTITY: false,
      action_TPD_DrugContinuePlan_CANCEL: false,
      action_TPD_DrugContinuePlan_REJECT: false,
      action_TPD_DrugContinuePlan_VERIFY: false,
      role_DOCTOR: false,
      role_PHARMACIST: false,
      config_CLM_ENABLE_CLAIM_DRUG_FRONTEND_V2: false,
      config_TPD_ENABLE_PTC_DISCOUNT: false,
      config_TPD_ENABLE_PTC_DISPENSE: false,
      action_TPD_DrugReturnRequest_REQUEST: false,
      action_TPD_DrugReturnRequest_APPROVE: false,
      action_TPD_DrugReturnRequest_UNAPPROVE: false,
      action_TPD_DrugReturnRequest_DELIVER: false,
      action_TPD_DrugReturnRequest_UNDELIVER: false,
      action_TPD_DrugReturnRequest_RECEIVE: false,
      action_TPD_DrugReturnRequest_UNRECEIVE: false,
      action_TPD_DrugReturnRequest_REJECT: false,
      action_TPD_DrugReturnRequest_CANCEL: false,
      action_TPD_DrugReturnRequest_EDIT: false,
    },
  });

  return result;
};

// Utils
const formatDrugName = (selectedDrug: any) => {
  const regex = / \((.*)\)/;
  const fullName = selectedDrug.full_name;
  const drugName = selectedDrug.drug_name;
  const dosageForm = selectedDrug.dosage_form_name;

  const genericName = regex.exec(fullName)?.[1];
  // * Coamox Cap 500 MG  (Amoxycillin) to Amoxycillin (Coamox) 500 MG Cap
  const formattedName = `${genericName} ${fullName
    .replace(regex, "")
    .replace(dosageForm, "")} ${dosageForm}`.replace(drugName, `(${drugName})`);

  return genericName ? formattedName : fullName;
};