import React from "react";

// UX
import CardSkinPreparationUX from "./CardSkinPreparationUX";
import {
  ACTION,
  checkHideButton,
} from "./CardOtherForm";
import PreOPButtonAction from "./PreOPButtonAction";
import { useIntl } from "react-intl";
import { Form, FormGroup, FormField, Dropdown, Button } from "semantic-ui-react";

type AddDataProps = {
  index: number;
  type: string;
  item: any;
  disabled: boolean;
  onAddData: Function;
  onDeleteData: Function;
  onChangeData: Function;
  // Options
  orVaginaOptions?: any[];
  orSkinAreaOptions?: any[];
  handleAddVaginaOptions?: any;
  handleAddSkinAreaOptions?: any;
};

export const SKIN_PREPARATION = "skinPreparation";
const CARD_SKIN_PREPARATION = "CardSkinPreparation";

const SKIN_DATA_ITEMS = {
  skinArea: "",
  solution: "",
};

const CardSkinPreparation = (props: any) => {
  const intl = useIntl();
  const data = {
    ...props.data?.data,
    skin_scrub_items: props.data?.data?.skin_scrub_items || [SKIN_DATA_ITEMS],
    paint_area_items: props.data?.data?.paint_area_items || [SKIN_DATA_ITEMS],
  };

  const handleSave = (action: string) => (e: any, v: any) => {
    props.onSave(action)(e, {
      ...v,
      formCode: CARD_SKIN_PREPARATION,
      formName: "Skin Preparation",
      key: SKIN_PREPARATION,
    });
  };

  const handleAddVaginaOptions = (e: any, { value }: any) => {
    props.setProp("masterOptions.orVagina", [
      { text: value, value: value, key: value },
      ...props.masterOptions?.orVagina,
    ]);
  };

  const handleAddSkinAreaOptions = (e: any, { value }: any) => {
    props.setProp("masterOptions.orSkinArea", [
      ...props.masterOptions?.orSkinArea,
      { text: value, value: value, key: value },
    ]);
  };

  const handleChangeValueSkinScrub = (_event: any, data: any) => {
    props.setProp(`PerioperativeNursingSequence.skinPreparation.data.${data.name}`, data.value)
  };

  const handleAddSkinScrub = (type: string) => {
    const items: any[] = data?.[type] || [];

    items.push(JSON.parse(JSON.stringify(SKIN_DATA_ITEMS)));
    handleChangeValueSkinScrub(null, { name: type, value: items });
  };

  const handleDeleteSkinScrub = (index: number, type: string) => {
    const items: any[] = data?.[type] || [];

    if (items.length > 1) {
      items.splice(index, 1);
      handleChangeValueSkinScrub(null, { name: type, value: items });
    }
  };

  const handleChangeSkinScrub = (index: number, value: any, key: string, type: string) => {
    let items: any[] = JSON.parse(JSON.stringify(data?.[type] || []));

    if (!items[index]) {
      items[index] = JSON.parse(JSON.stringify(SKIN_DATA_ITEMS));
    }

    items[index][key] = value;
    handleChangeValueSkinScrub(null, { name: type, value: items });
  };

  return (
    <>
      <CardSkinPreparationUX
        // data
        status={props.data?.status}
        isSurgicalSkinScrub={props.data?.data?.is_surgical_skin_scrub}
        isSurgicalSite={props.data?.data?.is_surgical_site}
        isVagina={props.data?.data?.is_vagina}
        vagina={props.data?.data?.vagina}
        handleAddVaginaOptions={handleAddVaginaOptions}
        woundClassification={props.data?.data?.wound_classification}
        nurseDiagnosis={props.data?.data?.nursing_diagnosis}
        goal={props.data?.data?.goal}
        planAndImplementation={props.data?.data?.plan_and_implementation}
        evaluation={props.data?.data?.evaluation}
        evaluationCheck={props.data?.data?.evaluation_check}
        evaluationDetail={props.data?.data?.evaluation_detail}
        // options
        orVaginaOptions={props.masterOptions?.orVagina}
        // callback
        onChangeData={props.onChangeData}
        // config
        config={{
          disabledVagina: !props.data?.data?.is_vagina,
          hideStatus: checkHideButton(props.data, "STATUS"),
        }}
        // Element
        AddSkinScrub={
          <Form
            style={
              props.data?.data?.is_surgical_skin_scrub ? { width: "100%" } : { display: "none" }
            }
          >
            {(data?.skin_scrub_items || [SKIN_DATA_ITEMS])?.map((item: any, index: number) => {
              return (
                <SkinScrubAddData
                  key={`skin_scrub_${index}`}
                  index={index}
                  item={item}
                  type={"skin_scrub_items"}
                  // config
                  disabled={!props.data?.data?.is_surgical_skin_scrub}
                  // callback
                  onAddData={handleAddSkinScrub}
                  onDeleteData={handleDeleteSkinScrub}
                  onChangeData={handleChangeSkinScrub}
                  // addition
                  handleAddSkinAreaOptions={handleAddSkinAreaOptions}
                  handleAddVaginaOptions={handleAddVaginaOptions}
                  orVaginaOptions={props.masterOptions?.orVagina}
                  orSkinAreaOptions={props.masterOptions?.orSkinArea}
                />
              );
            })}
          </Form>
        }
        AddPaintArea={
          <Form
            style={
              props.data?.data?.is_surgical_skin_scrub ? { width: "100%" } : { display: "none" }
            }
          >
            {(data?.paint_area_items || [SKIN_DATA_ITEMS])?.map((item: any, index: number) => {
              return (
                <SkinScrubAddData
                  key={`paint_area_${index}`}
                  index={index}
                  item={item}
                  type={"paint_area_items"}
                  // config
                  disabled={!props.data?.data?.is_surgical_skin_scrub}
                  // callback
                  onAddData={handleAddSkinScrub}
                  onDeleteData={handleDeleteSkinScrub}
                  onChangeData={handleChangeSkinScrub}
                  // addition
                  handleAddSkinAreaOptions={handleAddSkinAreaOptions}
                  handleAddVaginaOptions={handleAddVaginaOptions}
                  orVaginaOptions={props.masterOptions?.orVagina}
                  orSkinAreaOptions={props.masterOptions?.orSkinArea}
                />
              );
            })}
          </Form>
        }
        ButtonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [SKIN_PREPARATION]: props.data }}
            type="save"
            cardKey={SKIN_PREPARATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.SAVE)}
          />
        }
        ButtonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [SKIN_PREPARATION]: props.data }}
            type="confirm"
            cardKey={SKIN_PREPARATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.CONFIRM)}
          />
        }
        ButtonUnConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [SKIN_PREPARATION]: props.data }}
            type="unconfirm"
            cardKey={SKIN_PREPARATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.UNCONFIRM)}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

/**============================================
 **             SkinScrubAddData
 *=============================================**/
const SkinScrubAddData = (props: AddDataProps) => {
  const handleChange = (key: string) => (_event: any, data?: any) => {
    const value = typeof _event === "string" ? _event : data.value;
    props.onChangeData(props.index, value, key, props.type);
  };

  return (
    <FormGroup inline>
      <FormField inline width={5}>
        <Dropdown
          style={{ width: "100%" }}
          value={props.item?.skinArea}
          disabled={props.disabled}
          onChange={handleChange("skinArea")}
          selection={true}
          clearable={true}
          options={props.orSkinAreaOptions || []}
          allowAdditions={true}
          search={true}
          onAddItem={props.handleAddSkinAreaOptions}
        >
        </Dropdown>
      </FormField>
      <FormField inline width={1}>
        <label style={{ width: "100%", maxWidth: "max-content" }}>with</label>
      </FormField>
      <FormField inline width={8}>
        <Dropdown
          style={{ width: "100%" }}
          selection={true}
          onChange={handleChange("solution")}
          clearable={true}
          value={props.item?.solution || []}
          options={props.orVaginaOptions || []}
          name="vagina"
          search={true}
          multiple={true}
          allowAdditions={true}
          fluid={true}
          onAddItem={props.handleAddVaginaOptions}
        >
        </Dropdown>
      </FormField>
      <FormField inline style={{ display: "flex" }}>
        {props.index !== 0 && (
          <Button
            color="red"
            icon="minus"
            size="mini"
            disabled={props.disabled}
            style={{ margin: "0px 20px" }}
            onClick={() => props.onDeleteData(props.index, props.type)}
          ></Button>
        )}
        <Button
          color="blue"
          icon="plus"
          size="mini"
          disabled={props.disabled}
          style={props.index === 0 ? { margin: "0px 20px" } : { display: "none" }}
          onClick={() => props.onAddData(props.type)}
        ></Button>
      </FormField>
    </FormGroup>
  )
};

export default React.memo(CardSkinPreparation);
