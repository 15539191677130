import JsBarcode from "jsbarcode";
import moment from "moment";
import { ContentTable, TDocumentDefinitions } from "pdfmake/interfaces";

// Common
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import { splitStringNewLine } from "react-lib/apps/HISV3/common/CommonInterface";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

type FormMedicineProps = {
  adrLines: string[];
  birthdate?: string;
  code: string;
  barcode: string;
  division_type: string;
  dxLines: string[];
  encounter_type: string;
  full_age?: string;
  hn: number;
  items: Record<string, any>[];
  order_div_name: string;
  page: string;
  patient: string;
  principal_diagnosis_text: string;
  requested: Record<string, any> | null;
  room_no: string;
  type: string;
  key_user?: string;
  ip_address?: string;
};

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");

  JsBarcode(canvas, text, { displayValue: false });

  return canvas.toDataURL("image/png");
};

const formatCode = (code: string) => (!code || code === "BLANK" ? "" : ` ${code}`);

const rotateImage = (base64Image: string, degrees: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const radians = (degrees * Math.PI) / 180;
      const sin = Math.abs(Math.sin(radians));
      const cos = Math.abs(Math.cos(radians));
      canvas.width = img.width * cos + img.height * sin;
      canvas.height = img.width * sin + img.height * cos;

      ctx?.translate(canvas.width / 2, canvas.height / 2);
      ctx?.rotate(radians);

      ctx?.drawImage(img, -img.width / 2, -img.height / 2);

      resolve(canvas.toDataURL("image/png"));
    };
    img.onerror = reject;
    img.src = base64Image;
  });
};

export const getDrugTable = (drugs: Record<string, any>[]): ContentTable => ({
  table: {
    widths: ["7%", "64%", "15%", "7%", "7%"],
    body: [
      [
        {
          alignment: "center",
          fillColor: "#d9d9d9",
          style: "tableHeader",
          text: "ลำดับ",
        },
        {
          alignment: "center",
          fillColor: "#d9d9d9",
          style: "tableHeader",
          text: "ชื่อยา-วิธีใช้",
        },
        {
          alignment: "center",
          fillColor: "#d9d9d9",
          style: "tableHeader",
          text: "Bin location",
        },
        {
          alignment: "center",
          fillColor: "#d9d9d9",
          style: "tableHeader",
          text: "จำนวน",
        },
        {
          alignment: "center",
          fillColor: "#d9d9d9",
          style: "tableHeader",
          text: "หน่วย",
        },
      ],
      ...drugs.map((item, index) => [
        {
          alignment: "center",
          style: "tableData",
          text: `${item.seq}.`,
        },
        {
          stack: [
            {
              columns: [
                { text: item.drug_name_from_format, width: "auto" },
                {
                  alignment: "right",
                  text: `${item.alerts?.H ? "HAD" : ""} ${item.alerts?.L ? "LASA" : ""}`,
                },
              ],
            },
            {
              marginTop: -5,
              text: `${item.dose}${formatCode(item.code_unit)}${formatCode(
                item.code_route
              )}${formatCode(item.code_frequency)}${formatCode(item.code_method)}`,
            },
          ],
          style: "tableData",
        },
        {
          alignment: "center",
          style: "tableData",
          text: item.bin_location,
        },
        {
          alignment: "center",
          style: "tableData",
          text: Number(item.quantity),
        },
        {
          alignment: "center",
          style: "tableData",
          text: item.code_stock,
        },
      ]),
    ],
  },
  layout: {
    hLineWidth: () => 0.5,
    paddingBottom: () => 0,
    paddingTop: () => 0,
    vLineWidth: () => 0.5,
  },
});

const FORM_NAME = "FormMedicine";

const FormMedicine = async (props: FormMedicineProps): Promise<TDocumentDefinitions> => {
  const currentDate = moment();

  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    form: FORM_NAME,
    height: 35,
    showAddress: ["CU"],
  });
  const { addressWidth, font, images } = companyLogoForm;

  const headerName =
    props.encounter_type === "IPD"
      ? `${props.division_type || ""} ${props.order_div_name} ห้อง ${props.room_no}`
      : `แผนก ${props.order_div_name}`;

  const headerLines = splitStringNewLine(headerName, {
    fontSize: 18,
    width: 587 - addressWidth,
  });

  const rotatedBarcode = await rotateImage(textToBase64BarcodeBlob(props.barcode), 90);

  console.log("FormMedicine", props);

  return {
    defaultStyle: {
      font,
      fontSize: 16,
      lineHeight: 1,
    },
    images: {
      ...images,
    },
    pageMargins: [15, 130 + (props.dxLines.length - 1) * 16, 30, 55], // [15, 130 + (props.dxLines.length - 1) * 16, 15, 55],
    pageOrientation: "landscape",
    pageSize: "A5",
    styles: {
      tableData: { fontSize: 13 },
      tableHeader: {
        bold: true,
        color: "black",
        fontSize: 13,
      },
    },
    header: () => ({
      columns: [
        {
          stack: [
            {
              columns: [
                {
                  ...companyLogoForm,
                  margin: [5, 0, 0, 0],
                },
                {
                  alignment: "center",
                  bold: true,
                  fontSize: 18,
                  marginLeft: -10,
                  marginTop: headerLines.length > 1 ? 1.5 : 25,
                  stack: headerLines.map((text) => ({ noWrap: true, text })),
                  width: "*",
                },
              ],
            },
            {
              columns: [
                {
                  stack: [
                    { bold: true, text: props.patient },
                    {
                      text: [
                        { bold: true, fontSize: 13, text: "ว/ด/ป เกิด: " },
                        {
                          fontSize: 13,
                          text: `${props.birthdate || "-"} อายุ ${props.full_age || "-"}`,
                        },
                      ],
                    },
                    {
                      lineHeight: 0.7,
                      text: [
                        { bold: true, text: "DX-: " },
                        { text: props.principal_diagnosis_text },
                      ],
                    },
                  ],
                  width: "46%",
                },
                { text: "", width: "1%" },
                {
                  stack: [
                    {
                      bold: true,
                      text: `HN: ${props.hn} วันที่ ${formatDate(
                        currentDate
                      )} เวลา ${currentDate.format("HH:mm")} น.`,
                    },
                    {
                      table: {
                        heights: 30,
                        widths: [325],
                        body: [
                          [
                            {
                              bold: true,
                              border: [false, false, false, false],
                              fillColor: "#d9d9d9",

                              ...(props.adrLines.length > 2
                                ? {
                                    stack: [
                                      { fontSize: 10, text: "ADR" },
                                      {
                                        alignment: "center",
                                        fontSize: 15,
                                        marginTop: -7.5,
                                        text: "**MORE...",
                                      },
                                    ],
                                  }
                                : {
                                    fontSize: 13,
                                    lineHeight: 0.85,
                                    text: props.adrLines.slice(0, 2).map((text) => ({ text })),
                                  }),
                            },
                          ],
                        ],
                      },
                    },
                  ],
                  width: "53%",
                },
              ],
              margin: [20, 7.75, 20, 0],
            },
          ],
          width: "*",
        },
        {
          stack: [
            {
              alignment: "right",
              fontSize: 13,
              margin: [0, -2.5, 15, 0],
              text: `Page ${props.page}`,
            },
            {
              table: {
                widths: [55],
                body: [
                  [
                    {
                      alignment: "center",
                      border: [false, false, false, false],
                      color: "white",
                      fillColor: "#000000",
                      text: props.type.replace("_", ""),
                    },
                  ],
                  [
                    {
                      alignment: "center",
                      fontSize: 12,
                      text: props.code,
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth: () => 0.5,
                paddingBottom: () => 0,
                paddingLeft: () => 10,
                paddingRight: () => 10,
                paddingTop: () => 0,
                vLineWidth: () => 0.5,
              },
            },
          ],
          width: 85,
        },
      ],
      margin: [2.5, 10, 5, 0],
    }),
    content: [
      getDrugTable(props.items),
      {
        // border: [false, false, false, false],
        height: 120,
        image: rotatedBarcode,
        // margin: [-12.5, 2, 0, 3],
        width: 25,
        absolutePosition: { x: 567, y: 240 },
      },
    ],
    footer: () => ({
      margin: [15, 0, 30, 0], // [15, 0, 15, 0]
      table: {
        widths: ["25%", "25%", "25%", "25%"],
        body: [
          [
            {
              style: "tableData",
              text: `แพทย์: ${props.requested?.name || ""}`,
            },
            { style: "tableData", text: "ผู้จัดยา" },
            { style: "tableData", text: "เภสัชกร1" },
            { style: "tableData", text: "Tel" },
          ],
          [
            // { style: "tableData", text: "\u00A0" },
            { style: "tableData",
              text: `ผู้คีย์: ${props.key_user || ""}`
            },
            { style: "tableData", text: "Pre-check" },
            { style: "tableData", text: "เภสัชกร2" },
            { style: "tableData",
              text: `IP: ${props.ip_address || ""}`
            },
          ],
        ],
      },
      layout: {
        hLineWidth: () => 0.5,
        vLineWidth: () => 0.5,
      },
    }),
  };
};

export default FormMedicine;
