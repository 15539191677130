import WasmController from "react-lib/frameworks/WasmController";

// APIs
import AdmitOrderListPostponeReportPrint from "issara-sdk/apis/AdmitOrderListPostponeReportPrint_apps_ADM";
import AdmitOrderListPrint from "issara-sdk/apis/AdmitOrderListPrint_apps_ADM";
import RoomList from "issara-sdk/apis/RoomList_apps_ADM";
import AdmitOrderList from "issara-sdk/apis/AdmitOrderList_apps_ADM";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import AdmitOrderRoomItemDetail from "issara-sdk/apis/AdmitOrderRoomItemDetail_apps_ADM";
import CurrentRoomByPatient from "issara-sdk/apis/CurrentRoomByPatient_apps_ADM";
import DoctorConsultAppointmentList from "issara-sdk/apis/DoctorConsultAppointmentList_apps_DPO";

import * as SetBed from "./sequence/SetBed";
import * as WardQueue from "./sequence/WardQueue";
import * as InpatientDiagnosis from "./sequence/InpatientDiagnosis";
import * as PreAssessment from "./sequence/PreAssessment";
import * as ReAssessment from "./sequence/ReAssessment";
import * as ReportPatientIPDList from "./sequence/ReportPatientIPDList";
import * as ReportPatientList from "./sequence/ReportPatientList";
import AdmitOrderRoomItemList from "issara-sdk/apis/AdmitOrderRoomItemList_apps_ADM";
import * as AnesthesiaStatReport from "./sequence/AnesthesiaStatReport";
import * as AddCoPhysician from "./sequence/AddCoPhysician";

export type State = {
  admitOrderId?: number;
} & SetBed.State &
  WardQueue.State &
  InpatientDiagnosis.State &
  PreAssessment.State &
  ReAssessment.State &
  ReportPatientIPDList.State &
  AnesthesiaStatReport.State &
  AddCoPhysician.State;

export const StateInitial: State = {
  // ...SetBed.StateInitial,
  // ...WardQueue.StateInitial,
  // ...InpatientDiagnosis.StateInitial,
  // ...PreAssessment.StateInitial,
  // ...ReAssessment.StateInitial,
  // ...ReportPatientIPDList.StateInitial,
  // ...ReportPatientList.StateInitial,
  // ...AnesthesiaStatReport.StateInitial,
};

export type Event =
  | { message: "DidMount"; params: any }
  // GET
  | { message: "GetListOrderDivision"; params: any }
  | { message: "GetListRoom"; params: any }
  | { message: "GetListAdmitOrder"; params: any }
  | { message: "GetCurrentRoomByPatient"; params: any }
  // PRINT
  | { message: "PrintReportAdmitOrder"; params: any }
  | { message: "PrintReportPostponeAdmitOrder"; params: any }
  | { message: "HandleSelectAdmitEncounter"; params: any }
  | { message: "HandleConsultListToWard"; params: any }
  | { message: "HandleGetDoctorFeeTokenization"; params: any }
  | SetBed.Event
  | WardQueue.Event
  | InpatientDiagnosis.Event
  | PreAssessment.Event
  | ReAssessment.Event
  | ReportPatientIPDList.Event
  | ReportPatientList.Event
  | AnesthesiaStatReport.Event
  | AddCoPhysician.Event;

export type Data = {
  division?: number;
} & SetBed.Data &
  WardQueue.Data &
  InpatientDiagnosis.Data &
  PreAssessment.Data &
  ReAssessment.Data &
  ReportPatientIPDList.Data &
  ReportPatientList.Data &
  AnesthesiaStatReport.Data &
  AddCoPhysician.Data;

export const DataInitial = {
  ...SetBed.DataInitial,
  ...WardQueue.DataInitial,
  ...InpatientDiagnosis.DataInitial,
  ...PreAssessment.DataInitial,
  ...ReAssessment.DataInitial,
  ...ReportPatientIPDList.DataInitial,
  ...ReportPatientList.DataInitial,
  ...AnesthesiaStatReport.DataInitial,
  ...AddCoPhysician.DataInitial,
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {};

export const HandleSelectAdmitEncounter: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  const [[enRes, enError], [admitRes, admitError], [consultRes, consultError]] =
    await Promise.all([
      EncounterDetail.retrieve({
        pk: params.encounterId,
        apiToken: controller.apiToken,
      }),
      AdmitOrderRoomItemDetail.retrieve({
        pk: params.admitRoom.room_item_id,
        apiToken: controller.apiToken,
      }),
      DoctorConsultAppointmentList.list({
        apiToken: controller.apiToken,
        params: { order_encounter: params.encounterId, id_list: [params?.consultId] },
      }),
  ]);

  if (enError) {
    console.log("HandleSelect Admit Encounter error:", enError);
    if (params.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: enError },
        },
      });
    }
    return;
  }

  const result: any = {
    ...enRes,
    doctor_orders: [
      { ...consultRes?.items?.[0], specific_type: "doctorconsultorder" },
    ],
  };

  await controller.handleEvent({
    message: "SelectEncounter" as any,
    params: {
      encounter: result || null,
      admitRoom: admitRes,
      callForm: "WARD",
      goToMenu: params.goToMenu,
    },
  });
};

export const HandleConsultListToWard: Handler = async (controller, params) => {
  const { errorMessage } = controller.getState();
  const patientAdmitOrderList = await AdmitOrderRoomItemList.list({
    apiToken: controller?.apiToken,
    extra: {
      division: controller.data.division,
    },
    params: {
      barcode_or_an_or_hn: params?.hn,
    },
  });

  if (patientAdmitOrderList?.[1]) {
    controller.setState({
      errorMessage: {
        ...errorMessage,
        ...(params.card ? { [params.card]: patientAdmitOrderList[1] } : {}),
      },
    });

    params?.callback?.(false);

    return;
  }

  let admitOrderRoomItemTarget = (patientAdmitOrderList?.[0]?.items || []).find(
    (item: any) => item.encounter?.id === params?.encounter
  );
  if (!admitOrderRoomItemTarget) {
    admitOrderRoomItemTarget = { room_item_id: null };
  } else {
    admitOrderRoomItemTarget = {
      ...admitOrderRoomItemTarget,
      room_item_id: admitOrderRoomItemTarget.id,
    };
  }

  controller.handleEvent({
    message: "ChangeDivision" as any,
    params: { divisionId: params?.divisionId, tabPatientList: "consultation" },
  });

  HandleSelectAdmitEncounter(controller, {
    encounterId: params.encounter,
    consultId: params?.consultId,
    admitRoom: admitOrderRoomItemTarget,
    goToMenu: "Consultation",
  });

  params?.callback?.(true);
};

export const GetListOrderDivision: Handler = async (controller, params) => {
  const result = await DivisionList.list({
    apiToken: controller.apiToken,
    params,
  });

  return result;
};

export const GetListRoom: Handler = async (controller, params) => {
  const result = await RoomList.list({
    apiToken: controller.apiToken,
    params,
  });

  return result;
};

export const GetListAdmitOrder: Handler = async (controller, params) => {
  const result = await AdmitOrderList.list({
    apiToken: controller.apiToken,
    params,
  });

  return result;
};

export const GetCurrentRoomByPatient: Handler = async (controller, params) => {
  const result = await CurrentRoomByPatient.get({
    apiToken: controller.apiToken,
    pk: params.patient,
  });

  return result;
};

export const PrintReportAdmitOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  await AdmitOrderListPrint.post({
    apiToken: controller.apiToken,
    data: {
      items: state.SetBedSequence?.reserveBedQueueList || [],
    },
    extra: {
      division: controller.data.division,
    },
  });
};

export const PrintReportPostponeAdmitOrder: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  await AdmitOrderListPostponeReportPrint.post({
    apiToken: controller.apiToken,
    data: {
      items: state.SetBedSequence?.reserveBedQueueList || [],
    },
    extra: {
      division: controller.data.division,
    },
  });
};
