import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Input,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'

const CardPostopeartiveNursingRecordUX = (props: any) => {
    return(
      <div
        id="CardPostopeartiveNursingRecord"
        style={{ margin: "15px",  padding: "1rem",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>

          <label
            style={{ fontWeight: "bold", fontSize: "22px" }}>
            Postopeartive Nursing Record
          </label>
          <Label
            color={props.statusColor}
            style={{ float: "right" }}>
            {props.postOperativeData?.status}
          </Label>
          <hr>
          </hr>
        </div>
        <div
          style={{ backgroundColor: "#FDF8DD", padding: "20px 15px", margin: "0.5rem 0 1.5rem" }}>

          <div
            style={{ display: "flex", paddingBottom: "5px" }}>

            <label
              style={{  fontWeight: "bold",   minWidth: "180px",maxWidth: "180px",  margin: "5px 10px 0px 0px" }}>
              NURSING DIAGNOSIS
            </label>
            <label
              style={{ width: "40%",  margin: "5px 10px 0px 0px" }}>
              : POTENTIAL FOR OPERATIVE AND ANESTHETIC COMPLICATION
            </label>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>

            <label
              style={{  fontWeight: "bold",   minWidth: "180px",maxWidth: "180px",  margin: "5px 10px 0px 0px" }}>
              GOAL
            </label>
            <label
              style={{ width: "40%",  margin: "5px 10px 0px 0px" }}>
              : SAFETY FOR OPERATIVE AND ANESTHETIC COMPLICATION
            </label>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>

            <label
              style={{  fontWeight: "bold",   minWidth: "180px",maxWidth: "180px",  margin: "5px 10px 0px 0px" }}>
              PLAN & IMPLEMENTATION
            </label>
            <label
              style={{ width: "80%",  margin: "5px 10px 0px 0px" }}>
               : MONITOR FOR ACTIVITY, RESPIRATION, CIRCULATIONS, CONSCIOUSNESS, COLOR, NAUSEA/VOMITTING, PAIN AND BLEEDING
            </label>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>

            <label
              style={{  fontWeight: "bold",   minWidth: "180px",maxWidth: "180px",  margin: "5px 10px 0px 0px" }}>
              D/C EVALUATION
            </label>
            <label
              style={{ width: "40%",  margin: "5px 10px 0px 0px" }}>
              : OK
            </label>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>

            <label
              style={{  fontWeight: "bold",   minWidth: "180px",maxWidth: "180px",  margin: "5px 10px 0px 0px" }}>
              NOTE
            </label>
            <div
              style={{ width: "50%",  margin: "5px 10px 0px 0px" }}>

              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="noteNursingRecord"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue}
                options={props.noteNursingRecordOptions}
                search={true}
                selection={true}
                value={props.postOperativeData?.data?.noteNursingRecord}>
              </Dropdown>
            </div>
            <div
              style={{ width: "50%",  margin: "5px 10px 0px 0px" }}>

              <Input
                fluid={true}
                name="note"
                onChange={props.handleChangeValue}
                style={{ ...(!props.postOperativeData?.data?.noteNursingRecord?.includes("Other") && { display: "none" }) }}
                value={props.postOperativeData?.data?.note}>
              </Input>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <div
            style={{  fontWeight: "bold",   minWidth: "310px",maxWidth: "310px",  margin: "5px 10px 0px 0px" }}>

            <label>
              HEALTH EDUCATION
            </label>
            <Checkbox
              checked={props.postOperativeData?.data?.is_health_education}
              label={props.postOperativeData?.data?.is_health_education  ? "YES" : "NO"}
              name="is_health_education"
              onChange={props.handleChangeValue}
              style={{ float: "right", marginRight: "20px"}}
              toggle={true}>
            </Checkbox>
          </div>
          <Input
            disabled={props.postOperativeData?.data?.is_health_education  ? true :false}
            fluid={true}
            name="no_health_education"
            onChange={props.handleChangeValue}
            style={{width:"50%"}}
            value={props.postOperativeData?.data?.no_health_education}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <div
            style={{  fontWeight: "bold",   minWidth: "310px",maxWidth: "310px",  margin: "5px 10px 0px 0px" }}>

            <label>
              TRANSPORT TO
            </label>
          </div>
          <Dropdown
            fluid={true}
            name="transport_from_pacu_to"
            onChange={props.handleChangeValue}
            options={props.pacuToOptions}
            selection={true}
            style={{width:"20%"}}
            value={props.postOperativeData?.data?.transport_from_pacu_to}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <div
            style={{  fontWeight: "bold",   minWidth: "310px",maxWidth: "310px",  margin: "5px 10px 0px 0px" }}>

            <label>
              TRANSPORT BY
            </label>
          </div>
          <Dropdown
            fluid={true}
            name="transport_by"
            onChange={props.handleChangeValue}
            options={props.arriveStatusOptions}
            selection={true}
            style={{width:"20%"}}
            value={props.postOperativeData?.data?.transport_by}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <div
            style={{  fontWeight: "bold",   minWidth: "310px",maxWidth: "310px",  margin: "5px 10px 0px 0px" }}>

            <label>
              SIGNATURE
            </label>
            <label
              style={{ color: "red" }}>
               *
            </label>
          </div>
          <div
            style={{width:"20%",marginRight:"10px"}}>
            {props.code}
          </div>
          <label
            style={{ fontWeight: "bold",  margin: "5px 10px 0px 0px", }}>
            TIME
          </label>
          <div
            style={{width:"20%"}}>
            {props.signatureTime}
          </div>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>

          <div
            style={{display:"flex",justifyContent:"flex-end"}}>

            <div
              style={{margin:"10px"}}>
              {props.buttonSave}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonConfirm}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardPostopeartiveNursingRecordUX

export const screenPropsDefault = {}

/* Date Time : Fri Dec 20 2024 16:07:36 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPostopeartiveNursingRecord"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"1rem\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Postopeartive Nursing Record"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.postOperativeData?.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   minWidth: \"310px\",maxWidth: \"310px\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "HEALTH EDUCATION "
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#FDF8DD\", padding: \"20px 15px\", margin: \"0.5rem 0 1.5rem\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "NURSING DIAGNOSIS "
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   minWidth: \"180px\",maxWidth: \"180px\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": ": POTENTIAL FOR OPERATIVE AND ANESTHETIC COMPLICATION"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"40%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "GOAL"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   minWidth: \"180px\",maxWidth: \"180px\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": ": SAFETY FOR OPERATIVE AND ANESTHETIC COMPLICATION"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"40%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "label",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": "PLAN & IMPLEMENTATION"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   minWidth: \"180px\",maxWidth: \"180px\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "label",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": " : MONITOR FOR ACTIVITY, RESPIRATION, CIRCULATIONS, CONSCIOUSNESS, COLOR, NAUSEA/VOMITTING, PAIN AND BLEEDING"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "label",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": "D/C EVALUATION"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   minWidth: \"180px\",maxWidth: \"180px\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "label",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": ": OK"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"40%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "NOTE"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   minWidth: \"180px\",maxWidth: \"180px\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "Input",
      "parent": 169,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "note"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.postOperativeData?.data?.noteNursingRecord?.includes(\"Other\") && { display: \"none\" }) }"
        },
        "value": {
          "type": "code",
          "value": "props.postOperativeData?.data?.note"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "Checkbox",
      "parent": 69,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.postOperativeData?.data?.is_health_education"
        },
        "label": {
          "type": "code",
          "value": "props.postOperativeData?.data?.is_health_education  ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_health_education"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", marginRight: \"20px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Input",
      "parent": 68,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.postOperativeData?.data?.is_health_education  ? true :false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "no_health_education"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{width:\"50%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.postOperativeData?.data?.no_health_education"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   minWidth: \"310px\",maxWidth: \"310px\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   minWidth: \"310px\",maxWidth: \"310px\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 175,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   minWidth: \"310px\",maxWidth: \"310px\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "label",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "TRANSPORT TO"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "TRANSPORT BY"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "label",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": "SIGNATURE"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Dropdown",
      "parent": 173,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "transport_from_pacu_to"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.pacuToOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"20%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.postOperativeData?.data?.transport_from_pacu_to"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "Dropdown",
      "parent": 174,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "transport_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.arriveStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"20%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.postOperativeData?.data?.transport_by"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 175,
      "props": {
        "children": {
          "type": "code",
          "value": "props.code"
        },
        "style": {
          "type": "code",
          "value": "{width:\"20%\",marginRight:\"10px\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "label",
      "parent": 175,
      "props": {
        "children": {
          "type": "value",
          "value": "TIME"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 175,
      "props": {
        "children": {
          "type": "code",
          "value": "props.signatureTime"
        },
        "style": {
          "type": "code",
          "value": "{width:\"20%\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "label",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Dropdown",
      "parent": 192,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "noteNursingRecord"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.noteNursingRecordOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.postOperativeData?.data?.noteNursingRecord"
        }
      },
      "seq": 193,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardPostopeartiveNursingRecordUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
