import React, {
  useState,
  useEffect,
  useMemo,
  CSSProperties,
  useCallback,
  useRef,
  KeyboardEvent,
  SyntheticEvent,
  ReactElement,
} from "react";
import {
  Dropdown,
  DropdownOnSearchChangeData,
  DropdownProps,
  Icon,
  InputProps,
} from "semantic-ui-react";

// Interface
import { SearchTypes } from "./SearchBoxI";

// CSS
import "../../css/DropdownOptions.scss";
import { useIntl } from "react-intl";

export type SearchBoxDropdownProps<T = undefined> = {
  onEvent: OnEventHandler;
  // data
  id?: number | string;
  searchedItemList?: Record<string, any>[];
  vertical?: boolean;
  label?: string;
  style?: CSSProperties;
  boxStyle?: CSSProperties;
  selectedItem?: T extends object ? T : Exclude<SelectedItem, object>;
  startSearchLength?: number;
  type?: SearchTypes;
  verbose?: boolean;
  placeholder?: string;
  fluid?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  search?: boolean;
  noResultsMessage?: ReactElement;
  icon?: InputProps["icon"];
  iconStyle?: CSSProperties;
  dropdownStyle?: CSSProperties;
  defaultText?: string;
  valueKey?: string;

  searchedItemListWithKey?: Record<string, any>;
  useWithKey?: boolean;
  role?: string;
  limit?: number;
  inline?: boolean;
  upward?: boolean;

  setSelectedItem?: SelectedItemHandler;
  onBlur?: (e: KeyboardEvent<HTMLElement>, data: DropdownProps) => void;
  onBlurInput?: (e: KeyboardEvent<HTMLElement>, data: DropdownProps) => void;
  mapOptions?: (items: any[]) => Record<string, any>[];
  onAdditionalUrlParams?: (searchQuery?: string) => Record<string, any>;
} & ToDisplayProps<T>;

export type SelectedItemHandler = (
  value: any,
  key: any,
  data: Record<string, any> | null,
  action?: string
) => void;

export type OnEventHandler = (e: {
  message: any;
  params: {
    action: string;
    searchText: string;
    urlParams: Record<string, any>;
  } & Pick<SearchBoxDropdownProps, "id" | "limit" | "role" | "type" | "verbose">;
}) => void;

type SelectedItem = number | string | null | Record<string, any>;

type ToDisplayProps<T> = T extends Record<string, any> | null
  ? { toDisplay: ToDisplay }
  : { toDisplay?: ToDisplay };

type ToDisplay = (item: Record<string, any>) => string | number;

const SearchBoxDropdown = (props: SearchBoxDropdownProps) => {
  const intl = useIntl();
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Ref
  const isFocusRef = useRef<boolean>(false);
  const isClearRef = useRef<boolean>(false);
  const dropdownRef = useRef<any>(null);
  const timeoutRef = useRef<any>(0);

  const handleClear = useCallback(() => {
    props.onEvent({
      message: "ItemSearch",
      params: {
        id: props.id,
        type: props.type,
        action: "clear",
        role: props.role,
      },
    });
  }, [props.id, props.type, props.role]);

  // Callback Effect
  const handleOnClick = useCallback(async (ev: MouseEvent) => {
    const target = ev.target as HTMLElement;
    const parent = target.closest(".dropdown");

    if (isFocusRef.current && !parent) {
      dropdownRef.current.ref.current.firstElementChild.blur();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleOnClick);

    return () => {
      document.removeEventListener("click", handleOnClick);
    };
  }, []);

  useEffect(() => {
    if (props.selectedItem === null) {
      handleClear();
    }
  }, [props.selectedItem]);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [props.searchedItemList, props.searchedItemListWithKey]);

  const mapOptions = useCallback(
    (items: any[]) => {
      return items.filter(Boolean).map((item) => ({
        key: item[props.valueKey || "id"],
        value: item[props.valueKey || "id"],
        text: item.name_code || item.full_name_code || item.full_name || item.name,
      }));
    },
    [props.valueKey]
  );

  const searchedList = useMemo(() => {
    const key = props.type + "_" + props.id;
    const itemList = props.searchedItemList;
    const listWithKey = props.searchedItemListWithKey?.[key];

    let sourceList = props.useWithKey ? listWithKey : itemList;

    if (sourceList) {
      return (sourceList.items || sourceList) as any[];
    }

    return [];
  }, [
    props.searchedItemList,
    props.searchedItemListWithKey,
    props.type,
    props.id,
    props.useWithKey,
  ]);

  const options = useMemo(() => {
    if (searchedList) {
      return props.mapOptions ? props.mapOptions(searchedList) : mapOptions(searchedList);
    }

    return [];
  }, [searchedList, mapOptions]);

  const value = useMemo(() => {
    if (props.selectedItem) {
      return typeof props.selectedItem === "object"
        ? // * ถ้า selectedItem เป็น object ต้อง มี toDisplay เท่านั้นเพื่อ return value กลับมา
          props.toDisplay?.(props.selectedItem)
        : props.selectedItem;
    } else {
      return "";
    }
  }, [props.selectedItem, props.toDisplay, searchText]);

  const open = useMemo(() => {
    const key = props.type + "_" + props.id;
    const itemList = props.useWithKey
      ? props.searchedItemListWithKey?.[key]
      : props.searchedItemList;

    if (itemList) {
      const items = itemList.items || itemList;
      const hasItems = items.length > 0;
      const isNoSelectedItem = props.selectedItem === null;

      return hasItems && isNoSelectedItem && isFocusRef.current && !isLoading;
    }

    return false;
  }, [
    props.useWithKey,
    props.searchedItemList,
    props.selectedItem,
    props.searchedItemListWithKey,
    props.type,
    props.id,
    isLoading,
  ]);

  const noResultsMessage = useMemo(() => {
    return isLoading ? null : props.noResultsMessage;
  }, [props.noResultsMessage, isLoading]);

  const showDefaultText = !searchText && !!props.defaultText && !options.length;
  console.log('SearchBoxDropdown showDefaultText: ', showDefaultText);
  console.log('SearchBoxDropdown options: ', options);
  console.log('SearchBoxDropdown props.defaultText: ', props.defaultText);
  console.log('SearchBoxDropdown searchText: ', searchText);

  useEffect(() => {
    if (!!options?.length && value === null && !isFocusRef.current) {
      handleClear();
    }
  }, [value, options, open]);

  const handleSearchChange = (
    event: SyntheticEvent<HTMLElement, Event> | null,
    { searchQuery }: DropdownOnSearchChangeData
  ) => {
    if (searchQuery.length <= 2 && isLoading) {
      setIsLoading(false);
    }

    setSearchText(searchQuery);

    if (!searchText.length && searchQuery.length === 1 && value) {
      props.setSelectedItem?.(null, null, null);
    }

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      clearTimeout(timeoutRef.current);

      handleSearch(searchQuery);
    }, 250);
  };

  const handleChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data?.value === "" || event.type === "blur") {
      if (!props.onBlur) {
        props.setSelectedItem?.("", null, null, isClearRef.current ? "clear" : "");
      }

      if (options?.length) {
        handleClear();
      }

      return;
    }

    const obj = data.options?.find((item) => data.value === item.value);

    if (obj) {
      const selectedItem = searchedList.find((item) => item[props.valueKey || "id"] === data.value);

      props.setSelectedItem?.(data.value, obj.key, selectedItem);

      setSearchText("");
    }
  };

  const handleClick = (event: KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    const target = event.target as HTMLElement;

    if (event.type === "click" && target.className.includes("clear")) {
      const inputElm = target.parentElement?.firstElementChild as HTMLInputElement;

      if (inputElm) {
        isClearRef.current = true;

        inputElm.focus();
        inputElm.blur();
      }
    }
  };

  const handleBlur = async (e: KeyboardEvent<HTMLElement>, data: DropdownProps) => {
    const target = e.target as HTMLInputElement;

    if (props.selectedItem && !isClearRef.current) {
      props.onBlurInput?.(e, data);
    }

    if (!props.selectedItem || isClearRef.current) {
      props.onBlur?.(e, {
        ...data,
        value: isClearRef.current ? "" : target.value,
      });
    }

    setTimeout(() => {
      isFocusRef.current = false;

      setSearchText("");

      isClearRef.current = false;
    });
  };

  const handleFocus = (e: SyntheticEvent, data: DropdownProps) => {
    isFocusRef.current = true;

    if (!value && !isClearRef.current && props.defaultText) {
      handleSearchChange(null, { searchQuery: props.defaultText });
    }
  };

  const handleSearch = (searchQuery: string) => {
    if (searchQuery.length >= (props.startSearchLength || 3)) {
      setIsLoading(true);

      const urlParams = props.onAdditionalUrlParams?.(searchQuery);

      props.onEvent({
        message: "ItemSearch",
        params: {
          id: props.id,
          type: props.type,
          searchText: searchQuery,
          verbose: props?.verbose,
          role: props.role,
          limit: props.limit,
          urlParams,
        },
      });
    } else if (options?.length) {
      handleClear();
    }
  };

  return (
    <div
      id={`SearchBoxDropdown-${props.type}`}
      className={props.inline ? "inline-label" : ""}
      style={{
        ...(props.vertical
          ? {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }
          : {
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }),
        ...(props.boxStyle || {}),
      }}
    >
      <div style={{ marginRight: props.label ? "10px" : "0px" }}>{props.label}</div>
      <div style={{ position: "relative", ...(props.style || {}) }}>
        <Dropdown
          ref={dropdownRef}
          clearable={props.clearable ?? true}
          search={props.search ?? true}
          upward={props.upward}
          searchQuery={searchText}
          selection
          loading={isLoading}
          value={showDefaultText ? props.defaultText : value}
          disabled={props.disabled}
          style={props.dropdownStyle}
          onSearchChange={handleSearchChange}
          open={
            // !!props.noResultsMessage &&
            searchText.length >= (props.startSearchLength || 3) && !open ? true : open
          }
          noResultsMessage={noResultsMessage}
          options={
            showDefaultText
              ? [{ key: 1, value: props.defaultText, text: props.defaultText }]
              : options
          }
          onChange={handleChange}
          onClick={handleClick}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={props.placeholder}
          fluid={props.fluid}
          icon={
            props.icon ? (
              <Icon
                name={props.icon}
                style={{
                  marginTop: "-1em",
                  marginRight: "-1rem",
                  ...(props.iconStyle || {}),
                }}
              />
            ) : (
              "dropdown"
            )
          }
        />
      </div>
    </div>
  );
};

SearchBoxDropdown.displayName = "SearchBoxDropdown";

export default React.memo(SearchBoxDropdown) as <T extends SelectedItem | undefined = undefined>(
  props: SearchBoxDropdownProps<T>
) => ReactElement;
