import React, { useState, useMemo } from "react";
import { Button } from "semantic-ui-react";
// UI ANE
import CardPreAnesthetic from "./CardPreAnesthetic";
import CardPACURecord from "./CardPACURecord";
import CardPeriDataSheet from "./CardPeriDataSheet";
import CardAnestheticComplication from "./CardAnestheticComplication";
import CardAnesRecord from "./CardAnesRecord";
import CardAnesBill from "./CardAnesBill";
// CONFIG
import CONFIG from "config/config";
import { useIntl } from "react-intl";

type CardAnestheticDocumentProps = {
  // function
  onEvent: any;
  setProp: any;
  DJANGO: any;

  // controller
  cardANSBillController?: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  billDrugOrder?: any;
  selectedEncounter?: any;
  selectedEmr?: any;
  selectedProgressCycle?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PreAnestheticSequence?: any;
  PACURecordSequence?: any;
  PeriDataSheetSequence?: any;
  AnesComplicationSequence?: any;
  AnesRecordSequence?: any;
  AnesBillSequence?: any;
  selectedDoctor?: any;
  selectedAnesthesiologist?: any;
  searchedItemListWithKey?: any;
  HistoryCentralLabSequence?: any;
};

const CardAnestheticDocument = (props: CardAnestheticDocumentProps) => {
  const intl = useIntl();
  const [containerName, setContainerName] = useState<string>("PreAnesthetic");

  const buttonLists = useMemo(() => {
    return [
      { code: "PreAnesthetic", name: "Pre Anes" },
      { code: "AnesRecord", name: "Anes Record" },
      { code: "PACU", name: "PACU Record" },
      { code: "PeriDataSheet", name: "Peri Data Sheet" },
      { code: "AnesComplication", name: "Anes Complication" },
      {
        code: "AnesBill",
        name: intl.formatMessage({id: "Anes ค่าใช้จ่าย"}),
        disabled:
          props.selectedEncounter?.type !== "IPD" && props.selectedEncounter?.type !== "OPD",
      },
    ];
  }, [props.selectedEncounter]);

  return (
    <>
      <div
        className="anesthetic-document-tabs"
        style={{
          paddingTop: "15px",
          marginBottom: "-6px",
          marginLeft: "15px",
        }}
      >
        {buttonLists
          .filter((items: any) => !CONFIG.V3_HIDDEN_TAB_LIST.includes(items.code))
          .map((button) => (
            <Button
              key={button.code}
              color={containerName === button.code ? "blue" : undefined}
              disabled={button.disabled}
              onClick={() => setContainerName(button.code)}
              style={{ margin: "0.25rem 0.25rem 0 0" }}
            >
              {button.name}
            </Button>
          ))}
      </div>
      <hr />

      {containerName === "PreAnesthetic" ? (
        <CardPreAnesthetic
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          masterData={props.masterData}
          // seq
          runSequence={props.runSequence}
          PreAnestheticSequence={props.PreAnestheticSequence}
          selectedDoctor={props.selectedDoctor}
          selectedAnesthesiologist={props.selectedAnesthesiologist}
          searchedItemListWithKey={props.searchedItemListWithKey}
          HistoryCentralLabSequence={props.HistoryCentralLabSequence}
          selectedOrOrder={props.selectedOrOrder}
          selectedEncounter={props.selectedEncounter}
          languageUX={props.languageUX}
        />
      ) : containerName === "PACU" ? (
        <CardPACURecord
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          DJANGO={props.DJANGO}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          selectedEncounter={props.selectedEncounter}
          // options
          masterOptions={props.masterOptions}
          masterData={props.masterData}
          // seq
          runSequence={props.runSequence}
          PACURecordSequence={props.PACURecordSequence}
          searchedItemListWithKey={props.searchedItemListWithKey}
          languageUX={props.languageUX}
        />
      ) : containerName === "PeriDataSheet" ? (
        <CardPeriDataSheet
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          PACURecordSequence={props.PACURecordSequence}
          PeriDataSheetSequence={props.PeriDataSheetSequence}
          languageUX={props.languageUX}
        />
      ) : containerName === "AnesComplication" ? (
        <CardAnestheticComplication
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          PACURecordSequence={props.PACURecordSequence}
          AnesComplicationSequence={props.AnesComplicationSequence}
          languageUX={props.languageUX}
        />
      ) : containerName === "AnesRecord" ? (
        <CardAnesRecord
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          AnesRecordSequence={props.AnesRecordSequence}
          searchedItemListWithKey={props.searchedItemListWithKey}
          languageUX={props.languageUX}
        />
      ) : containerName === "AnesBill" ? (
        <CardAnesBill
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // controller
          cardANSBillController={props.cardANSBillController}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          billDrugOrder={props.billDrugOrder}
          selectedEncounter={props.selectedEncounter}
          selectedEmr={props.selectedEmr}
          selectedProgressCycle={props.selectedProgressCycle}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          AnesBillSequence={props.AnesBillSequence}
          languageUX={props.languageUX}
        />
      ) : containerName === "AnesRecord" ? (
        <CardAnesRecord
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          AnesRecordSequence={props.AnesRecordSequence}
          searchedItemListWithKey={props.searchedItemListWithKey}
          languageUX={props.languageUX}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(CardAnestheticDocument);
