import React, { CSSProperties, SyntheticEvent, useEffect, useState } from "react";

import { Button, Divider, Grid, GridColumn, Icon, Label } from "semantic-ui-react";

import Dropzone, { DropFilesEventHandler } from "react-dropzone";

import * as CAgent from "react-lib/apps/common/CAgent";

import SnackMessage from "../common/SnackMessage";

type Styles = {
  [key in "upload"]: CSSProperties;
};

// Const
const styles: Styles = {
  upload: {
    alignItems: "center",
    border: "2px dashed #b2b2c8",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
  },
};

const CardPrinterList = () => {
  const [selectedPrinter, setSelectedPrinter] = useState<string | null>(null);
  const [filePDF, setFilePDF] = useState<File | null>(null);
  const [printerList, setPrinterList] = useState<{ name: any; status: any }[]>([]);

  const [errorMessage, setErrorMessage] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    CAgent.getPrintList(
      (data: any) => {
        setPrinterList(
          Object.entries(data || {}).map(([key, value]) => ({ name: key, status: value }))
        );
      },
      (error: any) => {
        console.error(error);
      }
    );
  }, []);

  const handleSelectedPrinter = (name: string) => () => {
    setSelectedPrinter(name);
  };

  const handleChangeFile: DropFilesEventHandler = (accepted, rejected) => {
    const acceptedFile = accepted[0];
    const rejectedFile = rejected[0];

    if (rejectedFile instanceof File) {
      console.warn(`ประเภทไฟล์ไม่ถูกต้อง: ${rejectedFile.name}`);

      return;
    }

    if (!(acceptedFile instanceof File)) {
      console.warn("file is not an instance of File");

      return;
    }

    setFilePDF(acceptedFile);
  };

  const handleClearFile = (e?: SyntheticEvent) => {
    e?.stopPropagation();

    setFilePDF(null);
  };

  const handleCloseErrMsg = () => {
    setErrorMessage(null);
  };

  const handlePrint = async () => {
    if (!filePDF) {
      return;
    }

    setIsLoading(true);

    CAgent.printerPrint(
      {
        file: filePDF,
        printer: selectedPrinter || "",
      },
      async () => {
        setIsLoading(false);
      },
      async (error: any) => {
        setErrorMessage(error);
        setIsLoading(false);
      }
    );
  };

  return (
    <div style={{ height: "calc(100vh - 40px)", overflow: "auto", padding: "10px" }}>
      <SnackMessage error={errorMessage} success={null} onClose={handleCloseErrMsg} />

      <div style={{ fontSize: "1.25rem", fontWeight: "bold" }}>Printer list</div>
      <Divider />
      <Grid style={{ padding: "0 1rem" }}>
        <GridColumn width={8}>
          <div style={{ fontSize: "1.2rem", fontWeight: "bold", marginBottom: "1rem" }}>
            Available Printers
          </div>
          <div>
            {printerList.length === 0 && <div>*No Printers Found</div>}
            {printerList.map((item) => (
              <div
                key={item.name}
                onClick={handleSelectedPrinter(item.name)}
                style={{
                  alignItems: "center",
                  border: "1px solid #e5e7eb",
                  borderRadius: "0.5rem",
                  cursor: "pointer",
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  marginBottom: "1rem",
                  padding: "1rem",
                  ...(selectedPrinter === item.name && {
                    background: "#eff6ff",
                    border: "1px solid #3b82f6",
                  }),
                }}
              >
                <div style={{ padding: "0 1rem 0 0" }}>
                  <PrintIcon color="#1E1E1E" />
                </div>
                <div>
                  <b>
                    <div>{item.name}</div>
                  </b>
                  <label>Status: {item.status}</label>
                </div>
              </div>
            ))}
          </div>
        </GridColumn>
        <GridColumn width={8}>
          <div style={{ fontSize: "1.2rem", fontWeight: "bold", marginBottom: "1rem" }}>
            Document
          </div>
          <Dropzone
            accept={".pdf"}
            multiple={false}
            style={styles.upload}
            onDrop={handleChangeFile}
          >
            <Label size="big" style={{ backgroundColor: "#eff6ff" }} circular>
              <Icon name="upload" style={{ margin: 0 }} />
            </Label>
            <label style={{ margin: "1rem 0 0.5rem" }}>Choose PDF file</label>
            <label style={{ color: "#6b7280" }}>or drag and drop here</label>
            {filePDF && (
              <div
                style={{
                  alignItems: "baseline",
                  background: "white",
                  borderRadius: "8px",
                  color: "#4b5563",
                  display: "flex",
                  marginTop: "1rem",
                  padding: "0.5rem 1rem",
                }}
              >
                <Icon name="file alternate outline" />
                {filePDF.name}
                <Icon
                  color="blue"
                  name="close"
                  style={{ marginLeft: "5rem" }}
                  onClick={handleClearFile}
                />
              </div>
            )}
          </Dropzone>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
            <Button
              color="blue"
              disabled={!(selectedPrinter && filePDF)}
              loading={isLoading}
              style={{ alignItems: "center", display: "flex" }}
              onClick={handlePrint}
            >
              <PrintIcon color="#ffffff" />
              <label style={{ marginLeft: "1rem" }}>Print Document</label>
            </Button>
          </div>
        </GridColumn>
      </Grid>
    </div>
  );
};

const PrintIcon = (props: { color: string }) => (
  <svg fill="none" height="18" viewBox="0 0 48 48" width="18" xmlns="http://www.w3.org/2000/svg">
    <title>Printer</title>
    <path
      d="M12 18V4H36V18M12 36H8C6.93913 36 5.92172 35.5786 5.17157 34.8284C4.42143 34.0783 4 33.0609 4 32V22C4 20.9391 4.42143 19.9217 5.17157 19.1716C5.92172 18.4214 6.93913 18 8 18H40C41.0609 18 42.0783 18.4214 42.8284 19.1716C43.5786 19.9217 44 20.9391 44 22V32C44 33.0609 43.5786 34.0783 42.8284 34.8284C42.0783 35.5786 41.0609 36 40 36H36M12 28H36V44H12V28Z"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
    />
  </svg>
);

CardPrinterList.displayName = "CardPrinterList";

export default React.memo(CardPrinterList);
