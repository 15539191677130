import React, { ReactElement, useCallback, useEffect, useMemo } from "react";

import { Button, Modal, Segment, SemanticCOLORS } from "semantic-ui-react";

import "react-lib/css/ModInfo.css";
import { useIntl } from "react-intl";

type ModMedReconcileAlertProps = {
  onEvent: (e: any) => void;
  setProp?: (key: any, value: any, callback?: () => void) => void;

  btnLoading?: boolean;
  buttonColor?: SemanticCOLORS;
  buttonText?: string;
  card?: string;
  children?: ReactElement;
  divisionType?: string | null;
  django?: any;
  medReconcileCheck?: {
    med_reconciliation?: string | null;
    med_reconciliation_label?: string;
    open_card_med_reconciliation?: boolean;
    plan_transfer_id?: string;
  } | null;
  medReconcileIndex?: number;
  open: boolean;
  skipDivisionCheck?: boolean;
  titleColor?: SemanticCOLORS;
  titleName?: string;
  errorMessage?: Record<string, any>;
  selectedEncounter?: Record<string, any> | null;
  // callback
  onApprove?: (data: Record<string, any>) => void;
  onClose?: () => void;
  medReconcileId?: string;
};

const styles = {
  button: { marginTop: "10px" },
  header: { lineHeight: "1.3em" },
  content: {
    color: "red",
    fontSize: "larger",
    fontWeight: "bold",
    lineHeight: 1.5,
  },
};

const ModMedReconcileAlert = (props: ModMedReconcileAlertProps) => {
  console.log('CardDischarge ModMedReconcileAlert props: ', props);
  const intl = useIntl();
  // Effect
  useEffect(() => {
    const roleTypes: string[] = props.django?.user?.role_types || [];

    const divisions = ["หอผู้ป่วย", "หอผู้ป่วยพรีเมี่ยม"];
    const roles = new Set(["REGISTERED_NURSE", "DOCTOR"]);

    const isDivisionValid = divisions.includes(props.divisionType || "") || props.skipDivisionCheck;
    const hasRole = roleTypes.some((role) => roles.has(role));
    const isIPDEncounter = props.selectedEncounter?.type === "IPD";

    if (isDivisionValid && hasRole && isIPDEncounter) {
      const callFrom = roleTypes.includes("DOCTOR") ? "DPI" : "WARD";

      if (props.selectedEncounter?.id) {
        props.onEvent({
          message: "GetMedReconcileCheck",
          params: { callForm: callFrom, encounter: props.selectedEncounter },
        });
      }

      return;
    }

    console.warn(`ไม่ได้มาจาก ${divisions.join(" หรือ ")} หรือไม่ใช่ Role ที่กำหนด`);
  }, [props.divisionType, props.django, props.selectedEncounter]);

  // Callback
  const handleApprove = useCallback(() => {
    props.setProp?.("medReconcileCheck.open_card_med_reconciliation", false);

    const params: any = {
      medReconcileCheck: props.medReconcileCheck,
      selectedRecordViewIndex: props.medReconcileIndex,
    };

    if (props.errorMessage) {
      const error: string = props.errorMessage[props.card || ""]?.error?.[0] || "";

      const medId = error.split("[")[1].split("]")[0];

      params.medReconcileCheck = { med_reconciliation: medId };

      // Clear error message after handling
      props.setProp(`errorMessage.${props.card || ""}`, null);
    }

    if (props.onApprove) {
      props.onApprove(params);
    } else {
      props.onEvent({
        message: "OpenMedReconcileFromWarning",
        params,
      });
    }
  }, [props.card, props.errorMessage, props.medReconcileCheck, props.medReconcileIndex, props.medReconcileId]);

  // Memo
  const alertContent = useMemo(() => {
    if (props.errorMessage) {
      return props.errorMessage[props.card || ""]?.error?.[0] as string;
    }

    const medReconciliation = props.medReconcileCheck?.med_reconciliation;

    if (medReconciliation !== null && medReconciliation !== undefined) {
      const roleTypes: string[] = props.django?.user?.role_types || [];

      let role = "";

      if (roleTypes.includes("DOCTOR")) {
        role = intl.formatMessage({ id: "แพทย์" }) ;
      } else if (roleTypes.includes("REGISTERED_NURSE")) {
        role = intl.formatMessage({ id: "พยาบาล" }) ;
      } else if (roleTypes.includes("PHARMACIST")) {
        role = intl.formatMessage({ id: "เภสัชกร" }) ;
      }

      return `Med Reconcile ${
        props.medReconcileCheck?.med_reconciliation_label || ""
      } ยังไม่ได้ถูกรับทราบโดย${role}`;
    }

    return `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`;
  }, [props.card, props.django?.user?.role_types, props.errorMessage, props.medReconcileCheck]);

  return (
    <Modal open={props.open} size="mini" onClose={props.onClose}>
      <Segment
        className={`modHeader ${props.titleColor || "yellow"}`}
        style={styles.header}
        inverted
      >
        {props.titleName || "แจ้งเตือน"}
      </Segment>
      <Segment align="center" className="modContent" padded>
        <div style={styles.content}>{props.children ?? alertContent}</div>
        <Button
          className="approveButton"
          color={props.buttonColor || "green"}
          loading={props.btnLoading}
          style={styles.button}
          basic
          onClick={handleApprove}
        >
          {props.buttonText || "ตกลง"}
        </Button>
      </Segment>
    </Modal>
  );
};

ModMedReconcileAlert.displayName = "ModMedReconcileAlert";

export default React.memo(ModMedReconcileAlert);
