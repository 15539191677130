import { CSSProperties, useEffect, useState } from "react";

import { Button } from "semantic-ui-react";

import { useIntl } from "react-intl";
import ReactTable, { CellInfo } from "react-table-6";

import EditorBoxColumn, { ChangeHandler } from "react-lib/appcon/common/EditorBoxColumn";

import ModInfo from "react-lib/apps/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { formatDateToStringBe } from "react-lib/utils/dateUtils";

type CardActiveEncounterType = {
  onEvent: any;
  encounterPatientList?: any[] | undefined;
  patientInfo: any;
  toMiscellaneous: (data: { patient_id: number; id: number; type: string }) => void;
  errorMessage: any;
  searchedItemListWithKey: any;
};

const ColumnStyle = {
  background: "rgb(255, 255, 204)",
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  padding: "7px 7px",
} as CSSProperties;

const CARD_ACTIVE_ENCOUNTER = "CardActiveEncounter";

const CardActiveEncounter = (props: CardActiveEncounterType) => {
  const intl = useIntl();

  const [activeEncounter, setActiveEncounter] = useState<any[]>([]);

  const [openModInfo, setOpenModInfo] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  useEffect(() => {
    if (props.patientInfo?.id) {
      // Get today's date
      const today = new Date();
      // Calculate yesterday's date
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      props.onEvent({
        message: "GetEncounterWithPatient",

        params: {
          patientId: props.patientInfo?.id,
          excludeCancel: true,
          // fromDate: formatDateToStringBe(yesterday),
        },
      });
    }
  }, [props.patientInfo]);

  useEffect(() => {
    if (props.encounterPatientList && props.encounterPatientList?.length > 0) {
      setActiveEncounter(props.encounterPatientList);
    }
  }, [props.encounterPatientList]);

  const handleChangeDoctor =
    (original: any): ChangeHandler<string> =>
    (e, data) => {
      if (data.action === "clear") {
        setEditingIndex(null);

        return;
      }

      if (original?.id && data.value) {
        props.onEvent({
          message: "UpdateEncounterDetail",
          params: {
            card: CARD_ACTIVE_ENCOUNTER,
            division: original.division,
            doctor: data.value || null,
            encounter: original.id,
            patient: original.patient,
            type: original.type,
          },
        });

        setEditingIndex(null);
      }
    };

  const columns = [
    {
      Header: "",
      Cell: (cellInfo: CellInfo) => {
        return (
          <Button
            size="mini"
            basic
            color="blue"
            onClick={() => {
              // console.log('cellInfo?.original: ', cellInfo?.original);
              if (!cellInfo?.original?.doctor) {
                setOpenModInfo(true);

                return;
              }

              props.toMiscellaneous(cellInfo?.original);
            }}
          >
            Select
          </Button>
        );
      },
      width: 90,
    },
    {
      Header: "Encounter",
      accessor: "number",
      width: 90,
    },
    {
      Header: "Division",
      accessor: "division_name",
    },
    {
      Header: "Doctor",
      accessor: "doctor_name",
      Cell: ({ index, original }: any = {}) => (
        <div
          style={ColumnStyle}
          onClick={() => {
            if (editingIndex !== index) {
              const doctor = { id: original?.doctor, name_code: original?.doctor_name_code };

              props.setProp(`searchedItemListWithKey.Doctor_AEN_${index}`, [doctor]);

              setEditingIndex(index);
            }
          }}
        >
          {editingIndex === index ? (
            <EditorBoxColumn
              boxType="searchbox"
              onEvent={props.onEvent}
              type="Doctor"
              id={`AEN_${index}`}
              name="doctor"
              index={index}
              value={original?.doctor || null}
              searchedItemListWithKey={props.searchedItemListWithKey}
              editing={true}
              autoFocus={true}
              onChange={handleChangeDoctor(original)}
              onBlur={() => {
                setEditingIndex(null);
              }}
              onBlurInput={() => {
                setEditingIndex(null);
              }}
            />
          ) : (
            <div>{original?.doctor_name_code}</div>
          )}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status_name",
    },
    {
      Header: "Created",
      accessor: "created",
    },
    {
      Header: "Update",
      accessor: "edited",
    },
  ];

  const handleCloseModInfo = () => {
    setOpenModInfo(false);
  };

  return (
    <div style={{ margin: "4px" }}>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_ACTIVE_ENCOUNTER]}
        success={null}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_ACTIVE_ENCOUNTER}`, null);
        }}
      />

      <ReactTable
        className="-striped -highlight"
        // defaultPageSize={activeEncounter?.length}
        showPagination={false}
        data={activeEncounter}
        columns={columns}
      />

      <ModInfo
        open={openModInfo}
        titleColor={"red"}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        <div style={{ fontWeight: "bold", padding: "1rem 0" }}>กรุณาระบุแพทย์</div>
      </ModInfo>
    </div>
  );
};

export default CardActiveEncounter;
