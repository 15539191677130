import { rgb, RGB } from "pdf-lib";

/**
 * Draw a texts on Static PDF (Example: FormConsentSpinalTH)
 * @param page - Page to draw
 * @param textCoordinates - Array of texts
 * @param font - Font name
 * @param fontSize - Font size
 * @param textColor - (Optional) Font color
 */
export const drawTextOnPage = (
  page: any,
  textCoordinates: { text: string | undefined; x: number; y: number }[],
  font: any,
  fontSize: number,
  textColor?: RGB,
) => {
  for (const { text, x, y } of textCoordinates) {
    page.drawText(text || "", {
      x,
      y,
      size: fontSize,
      font,
      color: textColor ?? rgb(0, 0, 0),
    });
  }
};