import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Dimmer,
  Icon,
  Input,
  Modal,
  ModalActions,
  Header,
  ModalContent,
  Button
} from "semantic-ui-react";
import CONFIG from "config/config";
import UserLoginView from "issara-sdk/apis/UserLoginView_core";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

import {
  collection,
  getDocs,
  updateDoc,
  query,
  where,
  setDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import _ from "react-lib/compat/lodashplus";

import { firebaseApp, db } from "react-lib/frameworks/Firebase";
import { useIntl } from "react-intl";

// chat_channel_id
// patient_id
// encounter_id
// division_id

export const Main = (props: any) => {
  const history = useHistory();
  const [firstTime, setFirstTime] = useState(true);
  const [iframeHeight, setIframeHeight] = useState(480);
  const [iframeWidth, setIframeWidth] = useState(640);
  const [visible, setVisible] = useState(true);
  const [videoCallStartPip, setVideoCallStartPip] = useState(false);
  const [openModConfirm, setOpenModConfirm] = useState(false);
  const [employeeUsername, setEmployeeUsername] = useState("");
  const [employeePwd, setEmployeePwd] = useState("");
  const [pwdError, setPwdError] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [waiting, setWaiting] = React.useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (firstTime) {
      const _openVideoCallModal = localStorage.getItem("openVideoCallModal");
      const _videoCallRoom = localStorage.getItem("videoCallRoom");
      const _closeOverriding = localStorage.getItem("closeOverriding");
      setFirstTime(false);
      if (_openVideoCallModal === "true" && _videoCallRoom !== undefined) {
        props.setProp("openVideoCallModal", true);
        props.setProp("videoCallRoom", _videoCallRoom);
        props.setProp("closeOverriding", _closeOverriding);
      }
      if (_closeOverriding === "true" && _openVideoCallModal === "true") {
        setPopupTitle("กรุณารอการตอบรับจากเภสัชกร")
        setWaiting(true)
      }
    } else {
      setVisible(props.openVideoCallModal);
    }


    // Save to local storage
    localStorage.setItem("openVideoCallModal", props.openVideoCallModal);
    localStorage.setItem("closeOverriding", props.closeOverriding);
    localStorage.setItem("videoCallRoom", props.videoCallRoom);

    if (props.closeOverriding === true && props.openVideoCallModal) {
      setPopupTitle("กรุณารอการตอบรับจากเภสัชกร")
      setWaiting(true)
    }

    if (employeeUsername === "") {
      let u = props.userProfile ? props.userProfile?.username : ""
      setEmployeeUsername(u)
    }

  }, [props.openVideoCallModal]);

  // const debounce = (fn:any, ms = 0) => {
  //   let timeoutId: number | undefined = undefined;
  //   return function(...args: any[]) {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => fn.apply(this, args), ms);
  //   };
  // };

  const updateDocBySessionId = async (sessionId: string) => {
    console.log("ready with debouncing", props.currChatChannelId)
    // Query to find the document based on sessionId

    var chDetail, error, network;
    [chDetail, error, network] =
      await props.chatController.getChannelDetail({
        chatChannelId: props.currChatChannelId,
        apiToken: props.apiToken,
      });

    console.log("chDetail", chDetail)

    try {
      const q = query(
        collection(db, "webrtc_sessions"),
        where("sessionId", "==", sessionId)
      );

      const querySnapshot = await getDocs(q);

      const updateObject = {
        href: window.location.href,
        referrer: window.location.href,
        username: props.userProfile ? props.userProfile?.username : "",
        channel_id: props.currChatChannelId ? props.currChatChannelId : "",
        patient_id: props.patient_id ? props.patient_id : "",
        encounter_id: props.encounter_id ? props.encounter_id : "",
        division_id: chDetail ? chDetail?.division : "",
        division_name: chDetail ? chDetail?.division_name : "",
        organization_code: chDetail ? chDetail?.organization_code : "",
        organization_name: chDetail ? chDetail?.organization_name : "",
      };

      if (!querySnapshot.empty) {
        // Update all documents with the same sessionId
        const updatePromises = querySnapshot.docs.map((doc) => {
          const docRef = doc.ref;

          return updateDoc(docRef, updateObject);
        });

        await Promise.all(updatePromises);

        console.log(
          `$updated successfully for all documents with the same sessionId.`
        );
      } else {
        // Document with sessionId does not exist, create a new document
        const newDocRef = doc(collection(db, "webrtc_sessions"));
        await setDoc(newDocRef, updateObject);
        console.log("New document created with the given sessionId.");
      }
    } catch (error) {
      console.error("Error updating or creating document:", error);
    }
  };

  const sendMessage = ({ contentType, content }: any = {}) => {
    return new Promise(async (resolve, reject) => {
      let txtContent = content;
      console.log("end_call to: ", props.currChatChannelId, txtContent);

      if (
        contentType !== "vid_call" &&
        contentType !== "end_call" &&
        !txtContent
      ) {
        return;
      }
      if (!props.currChatChannelId) {
        return;
      }

      var response, error, network;
      [response, error, network] =
        await props.chatController.postChatChannelMessage({
          content: txtContent,
          contentFile: txtContent,
          contentType: contentType ? contentType : "text",
          chatChannelId: props.currChatChannelId,
          apiToken: props.apiToken,
          divisionId: props.division_id ? props.division_id : props.division,
        });
    });
  };

  const checkAuth = () => {
    return new Promise(async (resolve, reject) => {
      setPwdError("");

      const [r, e, n] = await UserLoginView.post({
        apiToken: props.apiToken,
        data: { username: props.userProfile?.username, password: employeePwd },
      });

      setEmployeePwd("");
      if (r) {
        handleClose();
        handleCloseVideoCall({ sendEndMessage: false });
      }
      if (e) {
        setPwdError(e);
      }
    });
  };

  const handleCloseVideoCall = ({ sendEndMessage = true }) => {
    console.log("MainVideoCall handle close video call");

    if (sendEndMessage) {
      setTimeout(() => {
        sendMessage({
          contentType: "end_call",
          content: props.videoCallRoom, // `${CONFIG.WEB_RTC}/${props.match.params.chatChannelId}/?name=${name}`
        });
      }, 1000);
    }

    localStorage.setItem("openVideoCallModal", "false");
    props.onClose?.();

    setVisible(false);
    setVideoCallStartPip(false);
    setIframeHeight(1);
    setIframeWidth(1);
    window.removeEventListener("message", handleIframeMessage);
  };

  const handleIframeResize = () => {
    setTimeout(() => {
      setIframeHeight(window.innerHeight - 100);
      setIframeWidth(window.innerWidth - 10);
      setVisible(true);
    }, 500);
  };

  const handleIframeMessage = (msg: any) => {
    try {
      let jsonMsg = JSON.parse(msg.data);
      console.log(msg);
      if (jsonMsg.message === "closeOverriding") {
        if (jsonMsg.users_count >= 1) {
          setPopupTitle("ไม่สามารถปิดหน้าต่างได้ในขณะนี้ เนื่องจากเภสัชกรยังไม่เสร็จสิ้นการให้คำแนะนำ")
          setWaiting(true)
        } else {
          setOpenModConfirm(true);
          setEmployeePwd("");
        }
      } else if (jsonMsg.message === "end_call") {
        if (jsonMsg.users_count <= 1) {
          handleCloseVideoCall({ sendEndMessage: true });
        } else {
          handleCloseVideoCall({ sendEndMessage: false });
        }
      } else if (jsonMsg.message === "start_pip") {
        setTimeout(() => {
          setIframeHeight(1);
          setIframeWidth(1);
          setVideoCallStartPip(true);
          // setVisible(false);
        }, 500);
        window.removeEventListener("resize", handleIframeResize);
      } else if (jsonMsg.message === "stop_pip") {
        window.addEventListener("resize", handleIframeResize);
        setVideoCallStartPip(false);
        handleIframeResize();
      } else if (jsonMsg.message === "ready") {
        console.log("got ready message")
        debounceDocUpdate()
        if (jsonMsg.users_count > 1) {
          setWaiting(false)
        }
      }
    } catch (e) {
      // ignore
    }
  };

  const debounceDocUpdate = useCallback(_.debounce(() => {
    updateDocBySessionId(props.videoCallRoom)
  }, 5000, { leading: false }),
    [props.currChatChannelId]
  )

  const onLoadIfrm = () => {
    window.addEventListener("resize", handleIframeResize);
    window.addEventListener("message", handleIframeMessage);
    if (visible) handleIframeResize();
  };

  const changeUsername = (data: any) => {
    setEmployeeUsername(data.value);
  };

  const changePassword = (data: any) => {
    setEmployeePwd(data.value);
  };

  const handleClose = () => {
    setOpenModConfirm(false);
  };

  return (
    <div>
      <ModConfirm
        openModal={!!openModConfirm}
        basic={false}
        modalStyle={{ marginTop: "calc(50vh - 150px)", width: "28%" }}
        titleName={intl.formatMessage({ id: "กรุณายืนยันสิทธิ์การปิดหน้าต่าง" })}
        size="small"
        // callback
        onCloseWithDimmerClick={handleClose}
        onClose={handleClose}
        // Element
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={checkAuth}
            // data
            // paramKey={`${CARD_EXPORT_E_CLAIM_FILE}_${BUTTON_ACTIONS.SAVE}`}
            // buttonLoadCheck={
            //   props.buttonLoadCheck?.[
            //     `${CARD_EXPORT_E_CLAIM_FILE}_${BUTTON_ACTIONS.SAVE}`
            //   ]
            // }
            // config
            // disabled={!props.userTokenize?.employeeName}
            fluid={true}
            color={"green"}
            // name={BUTTON_ACTIONS.SAVE}
            size="medium"
            title={intl.formatMessage({ id: "ยืนยัน" })}
          />
        }
        // !!Note ใช่ onDeny เนื่องจากต้องการให้ปุ่มบันทึกอยู่ทางขวา
        denyButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleClose}
            // data
            // paramKey={`${CARD_EXPORT_E_CLAIM_FILE}_${BUTTON_ACTIONS.SAVE}`}
            // buttonLoadCheck={
            //   props.buttonLoadCheck?.[
            //     `${CARD_EXPORT_E_CLAIM_FILE}_${BUTTON_ACTIONS.SAVE}`
            //   ]
            // }
            // config
            // disabled={!props.userTokenize?.employeeName}
            fluid={true}
            color={"red"}
            // name={BUTTON_ACTIONS.SAVE}
            size="medium"
            title={intl.formatMessage({ id: "ยกเลิก" })}
          />
        }
        content={
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              margin: "0 0 -1rem",
            }}
          >
            <div
              style={{ width: "90%", display: "flex", alignItems: "center" }}
            >
              <label style={{ marginRight: "2.5rem" }}>
                <strong>Username</strong>
                <span style={{ color: "red" }}>*</span>
              </label>
              <Input
                value={employeeUsername}
                onChange={(event, data) => changeUsername(data)}
                error={props.error}
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <label style={{ marginRight: "2.5rem" }}>
                <strong>Password</strong>
                <span style={{ color: "red" }}>*</span>
              </label>
              <Input
                type="password"
                value={employeePwd}
                onChange={(event, data) => changePassword(data)}
                onKeyDown={(e) => { if (e.keyCode === 13) { checkAuth() } }}
                style={{ width: "100%" }}
              />
            </div>
            <ErrorMessage error={pwdError} style={{ width: "100%" }} />
          </div>
        }
      />

      <Modal
        basic
        onClose={() => setWaiting(false)}
        open={waiting}
        size="small"
      >
        <Header icon>
          <Icon name="call square" />
          {popupTitle}
        </Header>
        <ModalContent>
          <p>
          </p>
        </ModalContent>
        {/* <ModalActions>
          <Button color="green" inverted onClick={() => setWaiting(false)}>
            <Icon name="checkmark" /> Ok
          </Button>
        </ModalActions> */}
      </Modal>

      {props.openVideoCallModal && (
        <Dimmer
          inverted
          active={visible}
          size="fullscreen"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.0)",
            width: videoCallStartPip ? "0px" : "100%",
            height: videoCallStartPip ? "0px" : "100%",
          }}
          className={{}}
        >
          <iframe
            frameBorder={0}
            onLoad={onLoadIfrm}
            scrolling="no"
            allow="microphone; camera *; display-capture *;"
            src={`${CONFIG.WEB_RTC}/${props.videoCallRoom}/?name=${props.username}&
                  isPatient=${props.isPatient}&closeOverriding=${props.closeOverriding}`}
            // src={`http://localhost:3001/${props.videoCallRoom}/?name=${props.username}&
            //       isPatient=${props.isPatient}&closeOverriding=${props.closeOverriding}`}
            width={videoCallStartPip ? "1px" : iframeWidth}
            height={videoCallStartPip ? "1px" : iframeHeight}
          />
        </Dimmer>
      )}
    </div>
  );
};
