import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import {
  Button,
  FormField,
  FormGroup,
  Grid,
  Icon,
  Label,
  Modal,
  TextArea,
  Input,
  Form,
} from "semantic-ui-react";
import WebcamComponent from "../common/WebcamProfile";
import ModalEditImage from "react-lib/apps/common/ModalEditImage";

// UI
import CardByEquipmentUX from "./CardByEquipmentUX";
import PreOPButtonAction from "./PreOPButtonAction";
import { useIntl } from "react-intl";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";

type ImplantAddDataProps = {
  index: number;
  type: string;
  item: any;
  disabled: boolean;
  onAddImplant: Function;
  onDeleteImplant: Function;
  onChangeImplant: Function;
};

const CARD_KEY = "byEquipment";
const FORM_CODE = "CardTube";
const FORM_NAME = "Tube";

const CARD_KEY_Drain = "drainData";
const FORM_CODE_Drain = "CardORDrain";
const FORM_NAME_Drain = "ORDrain";

const CARD_KEY_Dressing = "dressingData";
const FORM_CODE_Dressing = "CardORDressing";
const FORM_NAME_Dressing = "ORDressing";

const ACTION = {
  SAVE: "SAVE",
  CONFIRM: "CONFIRM",
  UNCONFIRM: "UNCONFIRM",
};

const IMAGE = {
  imageBgUpload: "images/picture.gif",
};

const ImplantItemsData = {
  name: "",
  lot: "",
  serial_no: "",
  manufracturer: "",
  expired_date: "",
};

const FormUploadImg = {
  nurse_implant: "",
  image: "",
  detail: "",
  id: "",
  is_active: true,
};

const CardByEquipment = (props: any) => {
  const intl = useIntl();
  const [selectImage, setSelectImage] = useState();
  const [editedImage, setEditedImage] = useState(FormUploadImg);
  const [uploadImage, setUploadImage] = useState(null);
  const [detailImage, setDetailImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(FormUploadImg);
  const [openUploadImg, setOpenUploadImg] = useState(false);
  const [openImageDetail, setOpenImageDetail] = useState(false);
  const [openModalEditImage, setOpenModalEditImage] = useState(false);
  const [openWebcam, setOpenWebCam] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);

  const fileUploaderRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "get_equipment",
    });
  }, []);

  useEffect(() => {
    if (props.PerioperativeNursingSequence?.byEquipment?.data?.insertOR === false) {
      props.setProp(`PerioperativeNursingSequence.byEquipment.data`, {
        ...props.PerioperativeNursingSequence?.byEquipment?.data,
        insert_in_or: "",
        is_foley_cath_size: false,
        foley_cath_size: "",
        catheterize_solution: "",
        insert_in_or_user: "",
        is_ng_tube_size: false,
        ng_tube_size: "",
        ng_tube_in_or: "",
      });

    }
  }, [props.PerioperativeNursingSequence?.byEquipment?.data?.insertOR])

  const handleChangeValue = (key: string) => (_event: any, data: any) => {
    if (data.type === "checkbox" || data.type === "radio") {
      props.setProp(`PerioperativeNursingSequence.${key}`, data.checked);
    } else {
      props.setProp(`PerioperativeNursingSequence.${key}`, data.value);
    }
  };

  const handleChangeValueImplant = (_event: any, data: any) => {
    props.setProp(`PerioperativeNursingSequence.implantData.${data.name}`, data.value);
  };

  const handleSave = (type: any, action: any) => {
    if (type === "Tube") {
      props.runSequence({
        sequence: "PerioperativeNursing",
        action: action,
        cardKey: CARD_KEY,
        formCode: FORM_CODE,
        formName: FORM_NAME,
        formVersion: "0.1",
      });
    } else if (type === "Drain") {
      props.runSequence({
        sequence: "PerioperativeNursing",
        action: action,
        cardKey: CARD_KEY_Drain,
        formCode: FORM_CODE_Drain,
        formName: FORM_NAME_Drain,
        formVersion: "0.1",
      });
    } else if (type === "Dressing") {
      props.runSequence({
        sequence: "PerioperativeNursing",
        action: action,
        cardKey: CARD_KEY_Dressing,
        formCode: FORM_CODE_Dressing,
        formName: FORM_NAME_Dressing,
        formVersion: "0.1",
      });
    }
  };

  //UploadImage
  const readChooseFile = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setUploadImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const saveImg = (imgSrc: any) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "save_uploadImg",
      image: imgSrc === "" ? uploadImage : imgSrc,
      detail: detailImage,
    });
    setOpenUploadImg(false);
    setOpenWebCam(false);
    setUploadImage(null);
    setDetailImage("");
  };

  const handleUpdateImage = (is_active: any) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "update_uploadImg",
      image: editedImage?.image,
      detail: editedImage?.detail,
      id: editedImage?.id,
      is_active: is_active,
    });
    setOpenImageDetail(false);
    setDeleteImage(false);
  };

  const handleSelectedImage = (item: any) => {
    setSelectedImage(item);
    setOpenModalEditImage(true);
  };

  const handleEditDescription = (e: any) => {
    var newDescription = {
      ...editedImage,
      detail: e,
    };
    setEditedImage(newDescription);
  };

  const handleEditedImage = (dataURI: string) => {
    var newImage = {
      ...selectedImage,
      image: dataURI,
    };

    setEditedImage(newImage);
    setOpenImageDetail(true);
  };

  const handleAddImplant = (type: string) => {
    const items: any[] = props.PerioperativeNursingSequence?.implantData?.[type] || [];

    items.push(JSON.parse(JSON.stringify(ImplantItemsData)));
    handleChangeValueImplant(null, { name: type, value: items });
  };

  const handleDeleteImplant = (index: number, type: string) => {
    const items: any[] = props.PerioperativeNursingSequence?.implantData?.[type] || [];

    if (items.length > 1) {
      items.splice(index, 1);
      handleChangeValueImplant(null, { name: type, value: items });
    }
  };

  const handleChangeImplant = (index: number, value: any, key: string, type: string) => {
    const items: any[] = props.PerioperativeNursingSequence?.implantData?.[type] || [];

    items[index][key] = value;
    handleChangeValueImplant(null, { name: type, value: items });
  };

  const mapOptions = useCallback((items: any) => {
    return (items || []).map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.full_name_code,
    }));
  }, []);

  const handleAddNgTubeSizeOptions = (e: any, { value }: any) => {
    props.setProp("masterOptions.orNgTubeSize", [
      ...props.masterOptions?.orNgTubeSize,
      { text: value, value: value, key: value },
    ]);
  };

  return (
    <div style={{ paddingBottom: "5rem" }}>
      <CardByEquipmentUX
        byEquipment={props.PerioperativeNursingSequence?.byEquipment}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
        masterOptions={props.masterOptions}
        handleChangeValue={handleChangeValue}
        onAddNgTubeSize={handleAddNgTubeSizeOptions}
        status={props.PerioperativeNursingSequence?.byEquipment?.status}
        statusDrain={props.PerioperativeNursingSequence?.drainData?.status}
        statusDressing={props.PerioperativeNursingSequence?.dressingData?.status}
        saveImplant={() => {
          props.runSequence({
            sequence: "PerioperativeNursing",
            action: "put_nurse_implant",
          });
        }}
        //* เมื่อ toggle disable ให้ clear ค่า
        ImplantAdd={
          <Form
            style={
              props.PerioperativeNursingSequence?.implantData?.is_active
                ? { marginTop: "0.5rem" }
                : { display: "none" }
            }
          >
            {(props.PerioperativeNursingSequence?.implantData?.implant_items || [{}])?.map(
              (item: any, index: number) => {
                return (
                  <ImplantAddData
                    index={index}
                    item={item}
                    type={"implant_items"}
                    // config
                    disabled={!props.PerioperativeNursingSequence?.implantData?.is_active}
                    // callback
                    onAddImplant={handleAddImplant}
                    onDeleteImplant={handleDeleteImplant}
                    onChangeImplant={handleChangeImplant}
                  />
                );
              }
            )}
          </Form>
        }
        ImplantImage={
          <div style={{ backgroundColor: "white", padding: "1rem" }}>
            <div style={{ fontWeight: "bold", marginBottom: "1rem" }}>{intl.formatMessage({ id: "ภาพประกอบการ Implant" })}</div>
            <div>
              <ImgDetail
                uploadImg={props.PerioperativeNursingSequence?.implantData}
                selectImage={selectImage}
                openImageDetail={openImageDetail}
                editedImage={editedImage}
                handleUpdateImage={handleUpdateImage}
                handleEditDescription={handleEditDescription}
                setSelectImage={setSelectImage}
                handleSelectedImage={handleSelectedImage}
                deleteImage={deleteImage}
                setDeleteImage={setDeleteImage}
                setOpenWebCam={setOpenWebCam}
                setOpenUploadImg={setOpenUploadImg}
              />
            </div>
          </div>
        }
        foleyCathNurseSearchBox={
          <SearchBoxWithKey
            // callback
            onEvent={props.onEvent}
            type="User"
            id="or_user"
            role="REGISTERED_NURSE"
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.PerioperativeNursingSequence?.byEquipment?.data?.insert_in_or_user ||
              null
            }
            disabled={!props.PerioperativeNursingSequence?.byEquipment?.data?.insertOR}
            mapOptions={mapOptions}
            setSelected={(value:any) => {
              props.setProp("PerioperativeNursingSequence.byEquipment.data.insert_in_or_user",value)
            }}
          />
        }
        ngTubeNurseSearchBox={
          <SearchBoxWithKey
            // callback
            onEvent={props.onEvent}
            type="User"
            id="ng_or_user"
            role="REGISTERED_NURSE"
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.PerioperativeNursingSequence?.byEquipment?.data?.ng_tube_in_or ||
              null
            }
            disabled={!props.PerioperativeNursingSequence?.byEquipment?.data?.insertOR}
            mapOptions={mapOptions}
            setSelected={(value:any) => {
              props.setProp("PerioperativeNursingSequence.byEquipment.data.ng_tube_in_or",value)
            }}
          />
        }
        // component Inplant
        buttonSaveImplant={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              if (!props.PerioperativeNursingSequence?.implantData?.is_active) {
                handleChangeValueImplant(null, { name: "implant_items", value: [] });
              }
              props.runSequence({
                sequence: "PerioperativeNursing",
                action: "put_nurse_implant",
              });
            }}
            // data
            paramKey={`implantData_save`}
            buttonLoadCheck={props.buttonLoadCheck?.[`implantData_save`]}
            // config
            color="green"
            size="small"
            style={{
              display: props.PerioperativeNursingSequence?.implantData?.allowed_actions?.includes(
                ACTION.SAVE
              )
                ? "none"
                : "",
            }}
            title="Save"
          />
        }
        // component Tube
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Tube", ACTION.SAVE)}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Tube", ACTION.CONFIRM)}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Tube", ACTION.UNCONFIRM)}
          />
        }
        // component Drain
        buttonSaveDrain={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY_Drain}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Drain", ACTION.SAVE)}
          />
        }
        buttonConfirmDrain={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY_Drain}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Drain", ACTION.CONFIRM)}
          />
        }
        buttonUnconfirmDrain={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY_Drain}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Drain", ACTION.UNCONFIRM)}
          />
        }
        // component Dressing
        buttonSaveDressing={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY_Dressing}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Dressing", ACTION.SAVE)}
          />
        }
        buttonConfirmDressing={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY_Dressing}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Dressing", ACTION.CONFIRM)}
          />
        }
        buttonUnconfirmDressing={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY_Dressing}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("Dressing", ACTION.UNCONFIRM)}
          />
        }
        languageUX={props.languageUX}
      />
      {/* Webcam */}
      <WebcamComponent
        open={openWebcam}
        onClose={() => setOpenWebCam(false)}
        setNewImage={(imgSrc: any) => {
          saveImg(imgSrc);
        }}
      />

      {/* EditImage */}
      <ModalEditImage
        image={selectedImage}
        openEditModal={openModalEditImage}
        onCloseModal={() => setOpenModalEditImage(false)}
        cancelImage={() => {
          setOpenModalEditImage(false);
          setOpenImageDetail(false);
        }}
        editedImage={(dataURI: string) => handleEditedImage(dataURI)}
      />

      {/* Upload */}
      <Modal
        open={openUploadImg}
        size="small"
        onClose={() => {
          setOpenUploadImg(false);
          setDetailImage("");
        }}
      >
        <div style={{ padding: "15px" }}>
          <div>
            <div>
              <label style={{ fontWeight: "bold" }}>
                {intl.formatMessage({ id: "คำอธิบายภาพ" })}
              </label>
            </div>
            <div style={{ margin: "15px 0px" }}>
              <TextArea
                rows={3}
                style={{
                  width: "100%",
                  resize: "none",
                  padding: "10px",
                }}
                value={detailImage}
                onChange={(e: any) => setDetailImage(e.target.value)}
              ></TextArea>
            </div>
          </div>
          <div>
            <Button
              color="blue"
              onClick={() => {
                fileUploaderRef.current.click();
              }}
            >
              Choose file
            </Button>
            <input
              type="file"
              id="file"
              ref={fileUploaderRef}
              style={{ display: "none" }}
              onChange={readChooseFile}
              accept="image/*"
            />
          </div>
          <div
            style={{
              margin: "10px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Modal.Description style={{ textAlign: "center" }}>
              <img
                src={uploadImage ? uploadImage : IMAGE.imageBgUpload}
                style={{ maxWidth: 640, maxHeight: 480 }}
                onClick={() => {
                  fileUploaderRef.current.click();
                }}
              />
            </Modal.Description>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              color="green"
              icon
              disabled={!uploadImage}
              labelPosition="left"
              onClick={() => saveImg("")}
            >
              <Icon className="cloud upload"></Icon>
              Upload
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const ImgDetail = (props: any) => {
  const intl = useIntl();
  return (
    <Grid divided>
      <Grid.Column width={11}>
        <Grid columns={3}>
          {props.uploadImg?.image?.map((item: any, index: any) => {
            return (
              <Grid.Column style={{ padding: "10px", position: "relative" }}>
                <img
                  onClick={() => props.handleSelectedImage(item)}
                  onMouseEnter={() => props.setSelectImage(index)}
                  onMouseLeave={() => props.setSelectImage(undefined)}
                  src={item.image}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <Label
                  pointing
                  style={{
                    display: props.selectImage === index ? "block" : "none",
                    position: "absolute",
                    zIndex: 100,
                    backgroundColor: "white",
                  }}
                >
                  รายละเอียด: {item.detail}
                </Label>
              </Grid.Column>
            );
          })}
        </Grid>
        <div style={{ paddingTop: "20px" }}>
          <Button color="blue" onClick={() => props.setOpenWebCam(true)}>
            Take Photo
          </Button>
          <Button color="blue" onClick={() => props.setOpenUploadImg(true)}>
            Upload
          </Button>
        </div>
      </Grid.Column>
      <Grid.Column width={5}>
        <ModConfirm
          titleColor="red"
          openModal={props.deleteImage}
          content={<>ต้องการลบรูปภาพ ใช่หรือไม่</>}
          onApprove={() => props.handleUpdateImage(false)}
          onDeny={() => props.setDeleteImage(false)}
        />
        {props.openImageDetail && (
          <div>
            <div>
              <img src={props.editedImage?.image} style={{ maxWidth: "100%", height: "auto" }} />
            </div>
            <div>
              <label style={{ fontWeight: "bold" }}>
                {intl.formatMessage({ id: "รายละเอียด" })}
              </label>
            </div>
            <div style={{ marginTop: "10px 0px" }}>
              <TextArea
                rows={3}
                style={{
                  width: "100%",
                  resize: "none",
                  padding: "10px",
                }}
                value={props.editedImage?.detail}
                onChange={(e: any) => props.handleEditDescription(e.target.value)}
              ></TextArea>
            </div>
            <div>
              <Button color="green" onClick={() => props.handleUpdateImage(true)}>
                Update
              </Button>
              <Button
                style={{ margin: "0px 10px" }}
                color="red"
                onClick={() => props.setDeleteImage(true)}
              >
                Remove
              </Button>
            </div>
          </div>
        )}
      </Grid.Column>
    </Grid>
  );
};

/**============================================
 *               ImplantAddData
 *=============================================**/
const ImplantAddData = (props: ImplantAddDataProps) => {
  const handleChange = (key: string) => (_event: any, data?: any) => {
    const value = typeof _event === "string" ? _event : data.value;
    props.onChangeImplant(props.index, value, key, props.type);
  };

  return (
    <FormGroup inline>
      <FormField inline style={{ width: "30px" }}>{`${props.index + 1}.`}</FormField>
      <FormField inline width={3}>
        <Input
          style={{ width: "100%" }}
          value={props.item?.name}
          readOnly={props.disabled}
          placeholder="Please Specify Name/ Band/ Type/ Size"
          onChange={handleChange("name")}
        ></Input>
      </FormField>
      <FormField inline width={2}>
        <div>Lot</div>
        <Input
          style={{ width: "100%" }}
          value={props.item?.lot}
          readOnly={props.disabled}
          onChange={handleChange("lot")}
        ></Input>
      </FormField>
      <FormField inline style={{ width: "73px" }}>
        Serial No.
      </FormField>
      <FormField inline width={2}>
        <Input
          style={{ width: "100%" }}
          value={props.item?.serial_no}
          readOnly={props.disabled}
          onChange={handleChange("serial_no")}
        ></Input>
      </FormField>
      <FormField inline style={{ width: "95px" }}>
        Manufacturer
      </FormField>
      <FormField inline width={2}>
        <Input
          style={{ width: "100%" }}
          value={props.item?.manufracturer}
          readOnly={props.disabled}
          onChange={handleChange("manufracturer")}
        ></Input>
      </FormField>
      <FormField inline style={{ width: "95px" }}>
        Expired date
      </FormField>
      <FormField inline width={2}>
        <DateTextBox
          style={{ width: "100%" }}
          value={props.item?.expired_date}
          onChange={handleChange("expired_date")}
        />
      </FormField>
      <FormField style={{ display: "flex" }}>
        {props.index !== 0 && (
          <Button
            color="red"
            icon="minus"
            size="mini"
            disabled={props.disabled}
            style={{ margin: "0px 20px" }}
            onClick={() => props.onDeleteImplant(props.index, props.type)}
          ></Button>
        )}
        <Button
          color="blue"
          icon="plus"
          size="mini"
          disabled={props.disabled}
          style={props.index === 0 ? { margin: "0px 20px" } : { display: "none" }}
          onClick={() => props.onAddImplant(props.type)}
        ></Button>
      </FormField>
    </FormGroup>
  );
};

export default React.memo(CardByEquipment);
