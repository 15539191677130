import React, {
  CSSProperties,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Dropdown, DropdownProps, Form, Icon, Input, InputProps } from "semantic-ui-react";

import moment from "moment";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";

import SearchBoxDropdown, {
  SearchBoxDropdownProps,
  SelectedItemHandler,
} from "./SearchBoxDropdown";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// Types
type EditorBoxColumnProps<T extends BoxTypes> = {
  autoFocus?: boolean;
  boxType: T;
  editing?: boolean;
  index?: number;
  name?: string;
  // callback
  onChange?: ChangeHandler<T>;
} & ValueProps<T>;

export type ChangeHandler<T> = (
  event: SyntheticEvent | null,
  data: {
    action?: string;
    data: Record<string, any> | null;
    index?: number;
    name?: string;
    value: ValueProps<T>["value"];
  }
) => void;

type OnChangeHandler = (
  e: SyntheticEvent | null,
  data: { action?: string; data?: Record<string, any> | null; value: number | string | null },
  isRefresh?: boolean
) => void;

type RefHandler = (
  ref:
    | {
        ref?: {
          inputRef?: {
            current: HTMLInputElement | null;
          };
        };
      }
    | HTMLDivElement
    | null
) => void;

type BoxTypes = "date" | "datetime" | "dropdown" | "input" | "searchbox";

type ValueProps<T> = T extends "dropdown"
  ? {
      // callback
      onEvent?: never;
      id?: never;
      defaultText?: never;
      type?: never;
      value?: number | string;
      valueKey?: never;
      searchedItemListWithKey?: never;
      options?: DropdownProps["options"];
      mapOptions?: never;
      onBlur?: never;
      onBlurInput?: never;
    }
  : T extends "searchbox"
  ? {
      // callback
      onEvent: (e: any) => void;
      id: string;
      value?: number | string | null;
      options?: never;
    } & Pick<
      SearchBoxDropdownProps,
      | "defaultText"
      | "mapOptions"
      | "searchedItemListWithKey"
      | "type"
      | "valueKey"
      | "onBlur"
      | "onBlurInput"
    >
  : {
      onEvent?: never;
      id?: never;
      defaultText?: never;
      type?: never;
      // type datetime value BE HH:mm => 12/12/2562 12:12
      value?: string;
      valueKey?: never;
      searchedItemListWithKey?: never;
      options?: never;
      mapOptions?: never;
      onBlur?: never;
      onBlurInput?: never;
    };

type Direction = "Left" | "Top";

type StyleKeys =
  | "content"
  | "dateTextbox"
  | "dateTextboxInput"
  | "dateTimeTextboxInput"
  | "dropdown"
  | "icon"
  | "input"
  | "popup"
  | "searchbox"
  | "searchboxDropdown";

type Styles = {
  [key in StyleKeys]: CSSProperties;
};

// Const
const HEIGHT = 40;

const MARGIN = 7;

// #const BG_COLOR = "rgb(255, 255, 204)";

const styles: Styles = {
  dateTextbox: { width: "100%" },
  dateTextboxInput: { width: "100%" },
  dateTimeTextboxInput: { width: "97px" },
  dropdown: { fontSize: "0.96em", width: "100%" },
  icon: { width: "82px" },
  input: { fontSize: "0.96em" },
  popup: {
    height: "fit-content",
    marginTop: -HEIGHT,
    width: "100%",
  },
  searchbox: { width: "100%" },
  searchboxDropdown: { fontSize: "0.96em" },
  content: {
    height: "calc(100% + 7px)",
    marginLeft: -4,
    width: "calc(100% - -10px)",
  },
};

const EditorBoxColumn = (props: EditorBoxColumnProps<BoxTypes>) => {
  const intl = useIntl();
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  // Ref
  const divRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const tbodyRef = useRef<HTMLDivElement | null>(null);
  const rtTableRef = useRef<HTMLDivElement | null>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);

  const isSearchBoxChanged = useRef<boolean>(false);
  const isDatetimeChanged = useRef<boolean>(false);

  const isOpen = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);
  const isOpenTime = useRef<boolean>(false);
  const isOpenDate = useRef<boolean>(false);

  const rtTdBg = useRef<string>("");

  // Callback effect
  const handleSetStyle = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    // ค้นหา rt-td ที่ใกล้ที่สุดของ inputRef เพื่อเซ็ตสีพื้นหลัง
    const rtTd = inputRef.current.closest(".rt-td") as HTMLDivElement | undefined;

    // #rtTd.style.backgroundColor = BG_COLOR;

    // กำหนดเลือกคำสั่งเพื่อเปลี่ยนแปลงสไตล์ของ element ที่ใกล้ที่สุดของ inputRef ตาม selectors ที่กำหนด
    const selectors = [".DayPickerInput", "[id*='SearchBoxDropdown']", ".ui.dropdown", ".ui.input"];

    // ค้นหา element ที่ใกล้ที่สุดของ inputRef จาก selectors ที่กำหนด
    const closestElement = selectors.flatMap((selector) => {
      const elm = inputRef.current?.closest(selector);

      return elm ? [elm] : [];
    })[0] as HTMLElement | null;

    // ปรับขนาดความกว้างของ element ที่ใกล้ที่สุดของ inputRef ตามขนาดของ rt-td
    if (rtTd && closestElement && props.boxType !== "datetime") {
      closestElement.style.width = `${rtTd.offsetWidth - 12}px`;
    }

    handleScrollTop({ target: tbodyRef.current } as Event);
    handleScrollLeft({ target: rtTableRef.current } as Event);
  }, [props.boxType]);

  // Effect
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      try {
        // Use requestAnimationFrame to avoid sync updates
        requestAnimationFrame(() => {
          for (const entry of entries) {
            handleSetStyle();
          }
        });
      } catch (error) {
        console.error("ResizeObserver error:", error);
      }
    });

    if (inputRef.current) {
      resizeObserver.observe(inputRef.current);
    } else {
      console.error("Element to observe not found");
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [handleSetStyle]);

  // Callback
  const handleScroll = useCallback((e: any, direction: Direction) => {
    if (!popupRef.current || !isOpen.current || !inputRef.current) {
      return;
    }

    const target = e.target as HTMLDivElement;
    const dimension = `margin${direction}` as `margin${Direction}`;
    // คำนวณค่า scrollValue โดยดูทิศทางการ scroll (Top/Left) เพื่อปรับตำแหน่งของ popup
    const scrollValue =
      direction === "Top" ? -target.scrollTop + -HEIGHT + MARGIN : -target.scrollLeft;

    // ปรับตำแหน่งของ popup ตาม scrollValue ที่คำนวณได้
    popupRef.current.style[dimension] = `${scrollValue}px`;

    const {
      bottom: tableBottom,
      left: tableLeft,
      right: tableRight,
      top: tableTop,
    } = target.getBoundingClientRect();
    const { bottom, left, right, top } = inputRef.current.getBoundingClientRect();

    // ตรวจสอบว่าต้องการซ่อน popup หรือไม่ เมื่อ scroll ทิศทางเป็น Top หรือ Left
    const shouldBlurTop = direction === "Top" && (tableTop > top || tableBottom < bottom);
    const shouldBlurLeft = direction === "Left" && (tableLeft > left || tableRight < right);

    // ซ่อน popup
    if (shouldBlurTop || shouldBlurLeft) {
      handleBlur();
    }
  }, []);

  const handleScrollTop = useCallback((e: Event) => {
    handleScroll(e, "Top");
  }, []);

  const handleScrollLeft = useCallback((e: Event) => {
    handleScroll(e, "Left");
  }, []);

  const handleEventListener = useCallback(() => {
    const input = inputRef.current;

    if (input) {
      // เซ็ตค่า isSearchBoxChanged เป็น false เมื่อมีการ focus ที่ input
      isSearchBoxChanged.current = false;
      isDatetimeChanged.current = false;

      // เพิ่ม event listener เมื่อมีการ focus ที่ input
      input.onfocus = () => {
        handleClick(); // เรียกใช้ handleClick เมื่อมีการ focus ที่ input
        handleEventListener(); // เรียกใช้ฟังก์ชัน handleEventListener เพื่อให้กำหนด event listener ใหม่หลังจาก focus
      };

      input.onblur = (e) => {
        const picker = input.closest(".DayPickerInput");

        setTimeout(() => {
          // ตรวจสอบว่า element ที่ blur ไปอยู่ใน picker หรือไม่
          const isPickerValid = picker?.contains(e.target as Node) && !isDatetimeChanged.current;

          if (isPickerValid) {
            return;
          }

          const shouldFocusTime = isOpenTime.current && picker;
          const shouldFocusDate = isOpenDate.current && input.closest(".DropdownOptions");

          if (shouldFocusTime || shouldFocusDate) {
            return;
          }

          handleDelayBlur();
        }, 150);
      };
    }

    const tbody = tbodyRef.current;
    const rtTable = rtTableRef.current;

    // ตรวจสอบและเพิ่มหรือลบ event listener เมื่อมีการ scroll ที่ tbody และ rtTable
    if (tbody) {
      tbody.removeEventListener("scroll", handleScrollTop);
      tbody.addEventListener("scroll", handleScrollTop);
    }
    if (rtTable) {
      rtTable.removeEventListener("scroll", handleScrollLeft);
      rtTable.addEventListener("scroll", handleScrollLeft);
    }
  }, []);

  const handleRef: RefHandler = useCallback(
    (ref) => {
      if (!ref) {
        return;
      }

      inputRef.current =
        ref instanceof HTMLDivElement
          ? ref.querySelector("input")
          : ref.ref?.inputRef?.current || null;

      tbodyRef.current = inputRef.current?.closest(".rt-tbody") as HTMLDivElement;
      rtTableRef.current = tbodyRef.current.parentElement as HTMLDivElement;

      handleSetStyle();

      handleEventListener();

      if(props.autoFocus){
        setTimeout(() => {
          inputRef.current?.focus();
        }, 150);
      }
    },
    [handleSetStyle, props.autoFocus]
  );

  const handleLabelRef = useCallback(
    (ref: HTMLSpanElement | null) => {
      if (ref) {
        const rtTd = ref.closest(".rt-td") as HTMLDivElement | null;

        if (rtTd) {
          // ตรวจสอบว่า component ถูก mount หรือยัง และกำหนดสีพื้นหลังของ rtTd เมื่อ component ถูก mount ครั้งแรก
          if (!isMounted.current) {
            rtTdBg.current = rtTd.style.backgroundColor; // เก็บค่าสีพื้นหลังเดิมของ rtTd

            isMounted.current = true;
          }

          // #rtTd.style.backgroundColor = rtTdBg.current; // เซ็ตสีพื้นหลังของ rtTd เป็นค่าเดิม

          isOpenDate.current = false;
          isOpenTime.current = false;
        }
      }
    },
    [props.editing]
  );

  const handleDateRef = useCallback((ref: HTMLDivElement | null) => {
    if (!ref) {
      return;
    }

    const input = ref.querySelector("input");

    if (!input) {
      return;
    }

    // #const rtTd = input.closest(".rt-td") as HTMLDivElement;

    // เซ็ตสีพื้นหลังของ rtTd เป็น BG_COLOR
    // #rtTd.style.backgroundColor = BG_COLOR;

    const handleOpen = (timeout = 0) => {
      handleRef(ref);
      handleClick();

      setTimeout(() => {
        isOpenDate.current = true;
        isOpenTime.current = false;
      }, timeout);
    };

    if (isOpenTime.current) {
      input.click();
      input.focus();

      handleOpen(152);
    }

    input.onfocus = () => {
      handleOpen();
    };
  }, []);

  const handleTimeRef = useCallback((ref: HTMLDivElement | null) => {
    if (!ref) {
      return;
    }

    const input = ref.querySelector("input") as HTMLInputElement;

    const handleOpen = (timeout = 0) => {
      handleRef(ref);
      handleClick();

      setTimeout(() => {
        isOpenTime.current = true;
        isOpenDate.current = false;
      }, timeout);
    };

    if (isOpenDate.current) {
      input.focus();

      handleOpen(152);
    }

    input.onfocus = () => {
      handleOpen();
    };
  }, []);

  const handleClick = useCallback(() => {
    if (isSearchBoxChanged.current) {
      isSearchBoxChanged.current = false;

      return;
    }

    if (
      !tbodyRef.current ||
      !inputRef.current ||
      !divRef.current ||
      !popupRef.current ||
      !rtTableRef.current
    ) {
      return;
    }

    isOpen.current = true;

    const tbody = tbodyRef.current;
    const rtTable = rtTableRef.current;
    const popup = popupRef.current;
    const div = divRef.current;

    // ดึงค่าตำแหน่งของตาราง
    const { left: tableLeft, right: tableRight } = rtTable.getBoundingClientRect();
    const { bottom: tableBottom, top: tableTop } = tbody.getBoundingClientRect();
    const { bottom, left, right, top } = inputRef.current.getBoundingClientRect();

    // ปรับขนาดของ div ที่ครอบตัว popup
    div.style.marginBottom = `-${MARGIN}px`;

    // ตรวจสอบและปรับ scroll ของตารางแนวนอน
    if (tableRight < right) {
      rtTable.scrollLeft = rtTable.scrollLeft + (right - tableRight) + MARGIN;
    }
    if (tableLeft > left) {
      rtTable.scrollLeft = rtTable.scrollLeft - (tableLeft - left) - MARGIN;
    }

    // ตรวจสอบและปรับ scroll ของตารางแนวตั้ง
    if (tableBottom < bottom) {
      tbody.scrollTop = tbody.scrollTop + bottom - tableBottom + MARGIN;
    }
    if (tableTop > top) {
      tbody.scrollTop = tbody.scrollTop - (tableTop - top) - MARGIN;
    }

    popup.style.position = "absolute";

    popup.style.zIndex = "999";
    popup.style.width = "fit-content";

    handleScrollTop({ target: tbody } as any);
    handleScrollLeft({ target: rtTableRef.current } as any);
  }, []);

  const handleBlur = useCallback(async (isRefresh = true) => {
    if (
      !popupRef.current ||
      !divRef.current ||
      !tbodyRef.current ||
      !rtTableRef.current ||
      !isRefresh
    ) {
      return;
    }

    const popup = popupRef.current;

    popup.style.marginTop = `${-HEIGHT}px`;
    popup.style.marginLeft = ``;
    popup.style.position = "";
    popup.style.zIndex = "";
    popup.style.width = "100%";

    divRef.current.style.marginBottom = "";

    isOpen.current = false;

    inputRef.current?.blur();

    isOpenDate.current = false;
    isOpenTime.current = false;

    setIsRefresh(true);
    setIsRefresh(false);

    isSearchBoxChanged.current = false;

    tbodyRef.current.removeEventListener("scroll", handleScrollTop);
    rtTableRef.current.removeEventListener("scroll", handleScrollLeft);
  }, []);

  const handleDelayBlur = useCallback(() => {
    handleBlur();

    setTimeout(() => {
      isOpenTime.current = false;
      isOpenDate.current = false;

      isDatetimeChanged.current = false;
    }, 151);
  }, [handleBlur]);

  const handleChange: OnChangeHandler = useCallback(
    (e, data, isRefresh = true) => {
      handleBlur(isRefresh);

      props.onChange?.(e, {
        action: data.action,
        data: data.data || null,
        index: props.index,
        name: props.name,
        value: data.value,
      });
    },
    [handleBlur, props.index, props.name]
  );

  const handleChangeDate = useCallback(
    (value: string) => {
      handleChange(null, { value }, value.length === 10 || !value);
    },
    [handleChange]
  );

  const handleChangeSearchBox: SelectedItemHandler = useCallback(
    (value: any, key: any, data: Record<string, any> | null, action) => {
      isSearchBoxChanged.current = true;

      handleChange(null, { action, data, value }, !!value);
    },
    [handleChange]
  );

  const handleChangeDropdown = useCallback(
    (e: SyntheticEvent, data: DropdownProps) => {
      handleChange(e, { value: data.value as number | string });
    },
    [handleChange]
  );

  const handleChangeInput = useCallback(
    (e: SyntheticEvent, data: InputProps) => {
      handleChange(e, { value: data.value as string }, false);
    },
    [handleChange]
  );

  const handleChangeTime = useCallback(
    (time: string) => {
      const value = props.value?.toString().replace(/\d+:\d+$/, time) || "";

      handleChange(null, { value }, false);
    },
    [props.value]
  );

  const handleChangeDatetime = useCallback(
    (date: string) => {
      isOpenDate.current = false;
      isOpenTime.current = false;

      isDatetimeChanged.current = true;

      const time = props.value?.toString().split(" ")[1] || "";

      handleChange(null, { value: `${date} ${time || "00:00"}` }, false);
    },
    [props.value]
  );

  const handleCurrentDate = useCallback(() => {
    handleChange(null, { value: formatDatetime(moment()) });
  }, []);

  // Memo
  const paddingTop = useMemo(() => {
    if (["dropdown", "input"].includes(props.boxType)) {
      return 9;
    } else if (["searchbox"].includes(props.boxType)) {
      return 2;
    }

    return 1;
  }, [props.boxType]);

  const value = useMemo(() => {
    if (props.boxType === "dropdown") {
      const text = props.options?.find((item) => item.value === props.value)?.text;

      return text || "";
    }

    return props.value;
  }, [props.boxType, props.options, props.value]);

  const datetimeValue = useMemo(() => {
    if (props.boxType === "datetime" && props.value) {
      const [date, time] = props.value.toString().split(" ");

      return { date, time };
    }

    return { date: "", time: "" };
  }, [props.boxType, props.value]);

  return (
    // @ts-ignore
    <Form
      size="small"
      style={
        props.editing ? { height: `calc(${HEIGHT}px - 2px)`, marginTop: -7, position: "unset" } : {}
      }
    >
      <div
        ref={divRef}
        style={props.editing ? { height: HEIGHT, ...styles.content, paddingTop } : {}}
      >
        {!props.editing && (
          <span ref={handleLabelRef} style={{ padding: "7px 5px" }}>
            {value}
          </span>
        )}
      </div>
      {/*  ---------- Popup ----------  */}
      {props.editing && (
        <div ref={popupRef} style={styles.popup}>
          {/* DateTextBox */}
          {props.boxType === "date" && !isRefresh && (
            <DateTextBox
              inputRef={handleRef}
              // style
              inputStyle={styles.dateTextboxInput}
              style={styles.dateTextbox}
              value={props.value || ""}
              inputFluid
              useAdDate
              setLanguage={"en"}
              // callback
              onChange={handleChangeDate}
            />
          )}
          {props.boxType === "datetime" && !isRefresh ? (
            <div className="datetime-text-box">
              <div ref={handleDateRef}>
                <DateTextBox
                  inputIcon=""
                  // style
                  inputStyle={styles.dateTimeTextboxInput}
                  value={datetimeValue.date}
                  // inputRef={handleRef}
                  inputFluid
                  // callback
                  onChange={handleChangeDatetime}
                />
              </div>
              <div className="separator">-</div>
              <div ref={handleTimeRef}>
                <TimeComboBox
                  defaultValue={datetimeValue.time}
                  icon={"-" as any}
                  inputStyle={styles.icon}
                  placeholder=""
                  fluid
                  onTextChange={handleChangeTime}
                />
              </div>
              <Icon.Group className="right-icon" onClick={handleCurrentDate}>
                <Icon name="calendar alternate outline" />
                <Icon name="time" corner />
              </Icon.Group>
            </div>
          ) : null}
          {/* SearchBoxDropdown */}
          {props.boxType === "searchbox" && (
            <div ref={handleRef}>
              <SearchBoxDropdown
                onEvent={props.onEvent as ValueProps<"searchbox">["onEvent"]}
                id={props.id}
                defaultText={props.defaultText}
                dropdownStyle={styles.searchboxDropdown}
                icon="search"
                limit={20}
                selectedItem={props.value || null}
                // style
                style={styles.searchbox}
                type={props.type as ValueProps<"searchbox">["type"]}
                valueKey={props.valueKey}
                fluid
                inline
                useWithKey
                searchedItemListWithKey={props.searchedItemListWithKey}
                setSelectedItem={handleChangeSearchBox}
                // callback
                mapOptions={props.mapOptions}
                onBlur={props.onBlur}
                onBlurInput={props.onBlurInput}
              />
            </div>
          )}
          {/* Dropdown */}
          {props.boxType === "dropdown" && (
            <div ref={handleRef}>
              <Dropdown
                className="inline-label"
                // style
                style={styles.dropdown}
                value={props.value || ""}
                fluid
                search
                selection
                options={props.options}
                // callback
                onChange={handleChangeDropdown}
              />
            </div>
          )}
          {/* Input */}
          {props.boxType === "input" && (
            <div ref={handleRef}>
              <Input
                // style
                style={styles.input}
                value={props.value}
                fluid
                // callback
                onChange={handleChangeInput}
              />
            </div>
          )}
        </div>
      )}
    </Form>
  );
};

EditorBoxColumn.displayName = "EditorBoxColumn";

export default React.memo(EditorBoxColumn) as <T extends BoxTypes>(
  props: EditorBoxColumnProps<T>
) => ReactElement;
