import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { drawTextOnPage } from "./StaticPdfUtils";

// PDF Path
const PDF = "/static/pdf/printList/cons_periphe_nerv_th.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormConsentPeripheNervTH = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const fontSize = 15; //*

    // Common Text
    const commonTextCoordinates = [
      { text: props.items?.formatPatient, x: 422, y: 745 },
      { text: props.items?.hn, x: 422, y: 732 },
      { text: props.items?.patientEncounter, x: 536, y: 732 },
      { text: props.items?.birthdate, x: 434, y: 718 },
      { text: props.items?.age.toString(), x: 526, y: 718 },
      { text: props.items?.formatGender, x: 426, y: 704 },
    ];

    // Page 1
    const firstTextCoordinates = [
      { text: props.items?.formatPatient, x: 142, y: 606 },
      { text: props.items?.formatDoctor || "-", x: 250, y: 583 },
    ];

    // Page 2
    const secondTextCoordinates = [
      { text: props.items?.patientFullName, x: 68, y: 401 },
      { text: props.items?.doctorFullName || "", x: 68, y: 332 },
    ];

    const secondDateTimeTextCoordinates = [
      { text: props.items?.signedDate, x: 80, y: 286 },
      { text: props.items?.printTime, x: 143, y: 286 },
    ];

    drawTextOnPage(pages[0], [...commonTextCoordinates, ...firstTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[1], [...commonTextCoordinates, ...secondTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[1], secondDateTimeTextCoordinates, customFont, 10);

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }

  } catch (error) {
    console.error("Error modifying PDF: ", error);
    return {};
  }
};

export default FormConsentPeripheNervTH;