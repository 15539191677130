import { useState, useEffect, useMemo, useCallback, SyntheticEvent } from "react";
import { useHistory } from "react-router-dom";

import {
  Modal,
  Checkbox,
  Radio,
  Button,
  Icon,
  Dropdown,
  Input,
  Menu,
  Dimmer,
  Loader,
  Label,
  Header,
  Image,
  ButtonGroup,
  ButtonProps,
  Message,
  MessageContent,
  MessageHeader,
} from "semantic-ui-react";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import CardDrugOrderWorkingUX from "./CardDrugOrderWorkingUX";
import CardDrugOrderQueue from "react-lib/apps/HISV3/TPD/CardDrugOrderQueue";
import CardDrugContinuePlan from "react-lib/apps/HISV3/TPD/CardDrugContinuePlan";
import CardDrugTransferRequest from "react-lib/apps/HISV3/TPD/CardDrugTransferRequest";
import ModReprintDrugOrderWorkingUX from "react-lib/apps/HISV3/TPD/ModReprintDrugOrderWorkingUX";
import CardDrugOrderRefillUX from "./CardDrugOrderRefillUX";
import ModDrugOrderReject from "./ModDrugOrderReject";
import ModCoverageDrugDiscount from "./ModCoverageDrugDiscount";

import CardMedicationErrorWorking from "./CardMedicationErrorWorking";
import DrugActionLog from "./DrugActionLog";

import SubDrugOrderToolbox from "./SubDrugOrderToolbox";
import ModConfirmReprint from "./ModConfirmReprint";
import ModDrugOrderActionNoteUX from "./ModDrugOrderActionNoteUX";
import SubDrugOrderStatusAlert from "react-lib/apps/HISV3/TPD/SubDrugOrderStatusAlert";
import CardReturnDrug from "react-lib/apps/HISV3/PTM/CardReturnDrug";
import ModDrugRecommendation from "./ModDrugRecommendation";
import ModLotNoExp from "./ModLotNoExp";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import SubAdrPopup from "./SubAdrPopup";

import ModDrugInteraction from "./ModDrugInteraction";
import ModDrugDisease from "./ModDrugDisease";
import ModDrugLabInteraction from "./ModDrugLabInteraction";
import ModDescriptionUD from "./ModDescriptionUD";

// Common

import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModInfo from "react-lib/apps/common/ModInfo";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SubDrugNameFormat from "react-lib/apps/HISV3/common/SubDrugNameFormat";
import ModLockExpense from "react-lib/apps/HISV3/BIL/ModLockExpense";
import ButtonCallPharmacist from "react-lib/apps/HISV3/common/ButtonCallPharmacist";

// Interface
import { COLORS, ORDER_STATUS_COLORS, ORDER_TYPE_COLORS } from "./sequence/DrugOrderAction";
import { getDrugStatusName, getDrugTypeName } from "./TPDInterface";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";

// Config
import CONFIG from "config/config";
import PasswordRecheck from "react-lib/apps/common/PasswordRecheck";
import { useIntl } from "react-intl";

const CARD_MOD_REPRINT = "CardModReprint";
const CARD_DRUG_ORDER_WORKING = "CardDrugOrderWorking";
const CARD_DRUG_ORDER_REFILL = "CardDrugOrderRefill";
const CARD_REPRINT_DRUG_ORDER_WORKING = "ModReprintDrugOrderWorkingUX";

const CloseIconStyle = {
  cursor: "pointer",
  padding: "2px",
  right: "13px",
  position: "absolute",
  zIndex: 1,
};

const ORDER_PAYMENT_STATUS = {
  PENDING: COLORS.orange,
  READY: COLORS.green,
  PAID: COLORS.green,
  BILL: COLORS.green,
};

const ACTION_BUTTON: { [action: string]: string } = {
  PRINT: "VERIFY AND PRINT",
  VERIFY: "VERIFY",
  CHECK: "CHECK",
  DELIVER: "DELIVER",
  RECEIVE: "RECEIVE",
  UNVERIFY: "UNVERIFY",
  UNDIVIDE: "UNDIVIDE",
  UNCHECK: "UNCHECK",
  UNDELIVER: "UNDELIVER",
  CANCEL: "CANCEL",
  REJECT: "REJECT",
  WAIVE: "WAIVE",
  CANCEL_REJECT: "CANCEL_REJECT",
};

const LABEL_BUTTON: { [action: string]: string } = {
  CANCEL_REJECT: "ยกเลิก REJECT",
};

const IMAGES = {
  //refill
  refill: "/static/images/drugRefill/refill.png",
  view: "/static/images/drugRefill/view.png",
  call_medicine: "/static/images/intratelephar/call-medicine-white.png",
};

const CardDrugOrderWorking = (props: any) => {
  const intl = useIntl();
  const history = useHistory();

  const [forwardAction, setForwardAction] = useState<string | null>(null);
  const [backwardAction, setBackwardAction] = useState<string | null>(null);
  const [buttonLoadKey, setButtonLoadKey] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDelivery, setSelectedDelivery] = useState<string | null>(null);
  const [isReprint, setIsReprint] = useState<boolean>(false);

  const [forwardColor, setForwardColor] = useState("green");
  const [openErrorReject, setOpenErrorReject] = useState<boolean>(false);
  const [multiCheckbox, setMultiCheckbox] = useState<number[]>([]);
  // Mod
  const [openModalLog, setOpenModelLog] = useState(false);
  const [openModHomeMedUnPaid, setOpenModHomeMedUnPaid] = useState(false);
  const [openModReturnDrug, setOpenModReturnDrug] = useState<boolean>(false);
  const [openModRefillDrug, setOpenModRefillDrug] = useState<boolean>(false);
  const [openModReprint, setOpenModeReprint] = useState<boolean>(false);
  const [openModRefill, setOpenModRefill] = useState<boolean>(false);
  const [openModRefillLog, setOpenModRefillLog] = useState<boolean>(false);
  const [openCancelRefill, setOpenCancelRefill] = useState<boolean>(false);
  const [openRefillPlanned, setOpenRefillPlanned] = useState<boolean>(false);
  const [openModLotNo, setOpenModLotNo] = useState<boolean>(false);
  const [openDrugRequest, setOpenDrugRequest] = useState<boolean>(false);
  const [modDrugDesc, setModDrugDesc] = useState<{
    open?: boolean;
    desc?: string;
  }>({});
  const [openModAlreadyOffHold, setOpenModAlreadyOffHold] = useState<boolean>(false)

  const [tab, setTab] = useState("thai");
  const [printLang, setPrintLang] = useState<"TH" | "EN">("TH");

  const [password, setPassword] = useState("");

  // Effect
  useEffect(() => {
    setOpenCancelRefill(false);

    props.DrugRefillSequence?.appointments.forEach((item: any) => {
      if (item?.refill != null) {
        setOpenCancelRefill(true);
      }
    });
  }, [props.DrugRefillSequence?.appointments]);

  useEffect(() => {
    if (props.successMessage?.[CARD_DRUG_ORDER_REFILL] != null) {
      if (props.successMessage?.[CARD_DRUG_ORDER_REFILL]?.edit) {
        setOpenModRefillDrug(false);
      }

      props.setProp(`successMessage.${CARD_DRUG_ORDER_REFILL}`, null);
    }
  }, [props.successMessage?.[CARD_DRUG_ORDER_REFILL]]);

  useEffect(() => {
    if (props.selectedEncounter?.id) {
      props.runSequence({ sequence: "DrugOrderAction", restart: true });
    }
  }, [props.selectedEncounter?.id]);

  useEffect(() => {
    return () => {
      props.runSequence({ sequence: "ReturnOrder", clear: true });
      props.runSequence({ sequence: "DrugOrderAction", clear: true });
    };
  }, []);

  useEffect(() => {
    if (props.drugDelivery?.showDrugDelivery) {
      setSelectedDelivery("drugDelivery");
    } else {
      setSelectedDelivery(null);
    }
  }, [props.drugDelivery?.showDrugDelivery]);

  useEffect(() => {
    if (props?.DrugOrderActionSequence?.isReprintSuccess) {
      setOpenModeReprint(false);
      props.setProp("DrugOrderActionSequence", {
        ...props.DrugOrderActionSequence,
        printPharma: false,
        printPatient: false,
        printLabel: false,
        printMedicine: false,
        language: "thai",
        noteReprint: "",
        isReprintSuccess: false,
      });
      setTab("thai");
    }
  }, [props.DrugOrderActionSequence?.isReprintSuccess]);

  useEffect(() => {
    props.onEvent({ message: "GetDrugTransferRequestList" });
  }, []);

  useEffect(() => {
    const order = props.drugOrder || {};
    const allowed_actions = order.allowed_actions || [];

    // * เมื่อกดผู้ป่วยไม่มารับยาให้แสดงปุ่ม DELIVER โดย disabled ปุ่ม deliver กับ รายละเอียดการรับยา
    if (order.status === "CHECKED_PAID_NO_PICKED_UP") {
      allowed_actions.push("DELIVER");
    }

    // * setForwardAction
    if (allowed_actions.includes("PRINT")) setForwardAction("PRINT");
    else if (allowed_actions.includes("VERIFY")) setForwardAction("VERIFY");
    else if (allowed_actions.includes("CHECK")) setForwardAction("CHECK");
    else if (allowed_actions.includes("DELIVER")) setForwardAction("DELIVER");
    else if (allowed_actions.includes("RECEIVE")) setForwardAction("RECEIVE");

    // * setBackwardAction
    if (allowed_actions.includes("UNVERIFY")) setBackwardAction("UNVERIFY");
    else if (allowed_actions.includes("UNDIVIDE")) setBackwardAction("UNDIVIDE");
    else if (allowed_actions.includes("UNCHECK")) setBackwardAction("UNCHECK");
    else if (allowed_actions.includes("UNDELIVER")) setBackwardAction("UNDELIVER");
    else if (allowed_actions.includes("CANCEL")) setBackwardAction("CANCEL");
    else if (allowed_actions.includes("REJECT")) setBackwardAction("REJECT");
    else if (allowed_actions.includes("CANCEL_REJECT")) setBackwardAction("CANCEL_REJECT");

    if (
      order.order_payment_status !== "PAID" &&
      order.status === "CHECKED" &&
      order.type === "HOME_OPD"
    ) {
      setForwardAction("WAIVE");
      setForwardColor("yellow");
      setOpenModHomeMedUnPaid(true);
    } else {
      setForwardColor("green");
      setOpenModHomeMedUnPaid(false);
    }

    if (["CANCELED", "WAIVED"].includes(order.status || "")) {
      setForwardAction(null);
      setBackwardAction(null);
    }

    setPrintLang("TH");
  }, [props.drugOrder]);

  useEffect(() => {
    const handleSetDrugState = async () => {
      // const findDivision = props.masterOptions?.divisionPharma.find(
      //   (item: any) => item.text === props.drugOrder?.order_perform_div
      // );
      // const findDivision = props.masterOptions?.divisionPharma.slice(-1)[0];
      const findDivision = props.masterOptions?.divisionPharma.find(
        (option: any) => option.value !== props.selectedDivision.id
      );

      await props.setProp(`DrugTransferRequestSequence.sequenceIndex`, "SaveDrugTransferRequest");
      await props.setProp(
        `DrugTransferRequestSequence.selectedDivisionPharma`,
        findDivision?.value
      );
      await props.setProp(
        "DrugTransferRequestSequence.selectedDrugSearch.id",
        selectedRow?.product
      );

      props.setProp("DrugTransferRequestSequence.selectedDrugDose", "1", () => {
        props.runSequence({ sequence: "DrugTransferRequest", action: "add" });
      });
    };

    if (
      openDrugRequest &&
      props.masterOptions?.divisionPharma?.length &&
      props.drugOrder?.order_perform_div
    ) {
      handleSetDrugState();
    }
  }, [
    props.masterOptions?.divisionPharma,
    openDrugRequest,
    props.drugOrder?.order_perform_div,
    selectedRow,
  ]);

  useEffect(() => {
    const handleDataInitial = async () => {
      const type: string = props.drugOrder?.type || "";
      const telepharType: string = props.drugOrder?.telephar_type || "";

      const contains = (array: string[]) => array.find((item) => type.includes(item));

      let delivery: any = null;

      if (contains(["HOME"])) {
        delivery = "patient";

        if (telepharType === "INTRA") {
          delivery = "telelift";
        } else if (telepharType === "DELIVERY") {
          delivery = "drugDelivery";
        }
      } else if (contains(["STAT", "ONE_DAY", "ONE_DOSE", "CONTINUE"])) {
        delivery = "transporter";
      } else if (props.drugOrder.is_telemed) {
        delivery = "drugDelivery";
      }

      // * Home med drug delivery
      if (props.drugDelivery?.showDrugDelivery) {
        delivery = "drugDelivery";
      }

      await props.setProp("drugDelivery.token", "");

      await props.setProp("drugDelivery.employeeName", "");

      handleSelectedDelivery(delivery);
    };
    if (props.drugOrder?.pk) {
      handleDataInitial();
    }
  }, [props.drugOrder?.pk]);

  // MedReconcile Check
  // useEffect(() => {
  //   console.log("useEffect props.selectedEncounter: ", props.selectedEncounter);
  //   console.log("useEffect props.divisionType: ", props.divisionType);
  //   let roleTypes = props.django?.user?.role_types || [];
  //   if (
  //     ["หอผู้ป่วย"].includes(props.divisionType) &&
  //     (roleTypes || []).includes("DOCTOR") &&
  //     props.selectedEncounter?.type === "IPD"
  //   ) {
  //     let callFrom = "DPI";
  //     if (props.selectedEncounter?.id) {
  //       props.onEvent({
  //         message: "GetMedReconcileCheck",
  //         params: { encounter: props.selectedEncounter, callForm: callFrom },
  //       });
  //     }
  //   } else {
  //     console.warn("ไม่ได้มาจาก หอผู้ป่วย เปิด CardTreatmentOrder: ");
  //   }
  // }, [props.django, props.divisionType]);

  const handleDrugClaim = useCallback(
    ({ item, questionIndex, isEdit, haveQuestion, itemIndex }: any = {}) => {
      props.onEvent({
        message: "CreateDrugOrderItemClaim",
        params: {
          data: {
            claim_payload: item.claim_payload,
            emr: props.selectedEmr?.id || null,
            encounter: props.selectedEncounter?.id || null,
            order_type: props.drugOrder?.type,
            product: item.product,
            quantity: Number.parseFloat(item.quantity),
          },
          item,
          questionIndex,
          itemIndex,
          stateKey: "selectedDrugOrderWorking",
          isEdit,
          haveQuestion,
          order_type: item.type,
        },
      });
    },
    [props.selectedEmr, props.selectedEncounter, props.drugOrder]
  );

  const handleCheckbox = (e: SyntheticEvent, d: any) => {
    e.preventDefault();
    e.stopPropagation();

    let checkList = [...multiCheckbox];

    const names = Array.isArray(d.name) ? d.name : [d.name];

    checkList = d.checked
      ? [...new Set([...checkList, ...names])]
      : checkList.filter((id) => !names.includes(id));

    setMultiCheckbox(checkList);
  };

  const handleChangeItemValue = (id: number, name: string, value: any) => {
    props.runSequence({
      sequence: "DrugOrderAction",
      action: "changeOrderItemData",
      name,
      value,
      id,
    });
  };

  const handleChangeChecked =
    (key: string) =>
    (_e: any, { checked }: any) => {
      props.setProp(`DrugOrderActionSequence.${key}`, checked);
    };

  const getLabelFromCode = useCallback(
    (item: Record<string, any>) => {
      const data: Record<string, any[] | undefined> = props.masterData || {};

      data.stock = data.unit;

      const keys = ["frequency", "site", "route", "unit", "stock"];

      return keys.reduce<Record<string, any>>((result, key) => {
        const value: string = item[`code_${key}`] || "";
        const foundCode = data[key]?.find((item) => item.code === value);

        return {
          ...result,
          [`code_${key}`]: foundCode?.text || "",
        };
      }, {});
    },
    [props.masterData]
  );

  const replaceQuantity = useCallback((unit: string, label: string, qty: number): string => {
    const regex = new RegExp(`(\\d+)\\s*${unit}`, "i");

    return label.replace(regex, `${qty} ${unit}`);
  }, []);

  const drugOrderItems = useMemo(() => {
    const items: Record<string, any>[] = props.drugOrder?.items || [];

    return items.map((item, index, self) => {
      const isDrugAdmixture = self[index + 1]?.is_solvent;
      const drugItemWithLabels = getLabelFromCode({
        ...item,
        code_stock: item.is_external ? "-" : item.code_stock,
      });

      const label = item.is_solvent
        ? replaceQuantity(drugItemWithLabels.code_unit, item.label, Number(item.solvent_volume))
        : item.label;

      let drugName: string = item.drug_name_from_format || item.name || "";

      drugName = drugName.includes("ยานอกโรงพยาบาล") ? item.name : drugName;

      return { ...item, drugItemWithLabels, drugName, isDrugAdmixture, label };
    }) as Record<string, any>[];
  }, [getLabelFromCode, props.drugOrder?.items]);

  const formattedDrugOrderItems = useMemo(() => {
    return drugOrderItems.map((item: any, index: number, self) => {
      const mixtureIvRateUnit = props.masterData?.unit?.find(
        (data: any) => data.id === item.mixture_iv_rate_unit
      )?.text;
      const drugDripInUnit = props.masterData?.unit?.find(
        (data: any) => data.id === item.drug_drip_in_unit
      )?.text;

      const ids = item.isDrugAdmixture ? [item.id, self[index + 1].id] : item.id;

      return {
        ...item,
        check: (
          <div
            style={{
              position: "absolute",
              display: "flex",
              width: "100%",
              ...(item.isDrugAdmixture && { bottom: "-8px", zIndex: "1" }),
            }}
          >
            {!item.is_solvent && (
              <Checkbox
                name={ids}
                onChange={handleCheckbox}
                checked={multiCheckbox.includes(item.id)}
              />
            )}
          </div>
        ),
        print_label: (
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              left: "-0.25rem",
              ...(item.isDrugAdmixture && { bottom: "-8px", zIndex: "1" }),
            }}
          >
            {!item.is_solvent && (
              <Checkbox
                name={`print_label${item.id}`}
                onChange={(e: SyntheticEvent, data: any) => {
                  e.stopPropagation();

                  handleChangeItemValue(ids, "print", data.checked);
                }}
                checked={item.print}
              />
            )}
          </div>
        ),
        name_status: (
          <>
            {item.note_rejected ? (
              <div style={{ backgroundColor: "#db2828", fontSize: "1.3em" }}>
                {" "}
                Rejected : {item.note_rejected}
              </div>
            ) : null}
            <div>
              <div style={{ marginRight: "1rem", fontWeight: "lighter" }}>
                <SubDrugNameFormat
                  onEvent={props.onEvent}
                  name={item.drugName}
                  product={item.product}
                />
              </div>
              {item?.individual_use && (
                <label style={{ color: "red", fontSize: "12px", paddingLeft: "10px" }}>
                  {intl.formatMessage({ id: "(ยาสำหรับผู้ป่วยเฉพาะราย)" })}
                </label>
              )}
              {item.is_external && (
                <Label color="yellow" size="mini">
                  own med
                </Label>
              )}
              {/* {item.code_frequency === "UD" && (
              <Button
                icon="clipboard list"
                circular={true}
                color="green"
                style={{ padding: "0.4rem 0.45rem 0.45rem", fontSize: "1rem" }}
                onClick={(e) => {
                  e.stopPropagation();

                  setModDrugDesc({ open: true, desc: item.description });
                }}
              />
            )} */}
            </div>
            <SubDrugOrderStatusAlert
              onEvent={props.onEvent}
              stateKey="selectedDrugOrderWorking"
              index={index}
              item={item}
              estimate={props.drugOrder?.estimate || {}}
              isFemale={props.selectedEncounter?.patient_gender_name === "Female"}
              // callback
              onDrugClaim={handleDrugClaim}
              targetStateOrder={"selectedDrugOrderWorking"}
              languageUX={props.languageUX}
            />
          </>
        ),
        onhand: item.is_dispensed ? (
          <b style={{ color: "#00c600" }}>DIVIDED</b>
        ) : item.is_external ? (
          "-"
        ) : (
          item.onhand
        ),
        dose: item.is_solvent ? Number(item.solvent_volume) || "" : item.dose,
        quantity: item.quantity ? parseInt(item.quantity) : "",
        price_unit: item.is_external ? (
          "-"
        ) : (
          <div style={{ textAlign: "right" }}>
            {Number(item.price_unit || "0").toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        ),
        price_total: item.is_external ? (
          "-"
        ) : (
          <div style={{ textAlign: "right" }}>
            {Number(item.price_total || "0").toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        ),
        ...item.drugItemWithLabels,
        code_frequency: (
          <>
            <div>{item.drugItemWithLabels.code_frequency}</div>
            <div>{item.description}</div>
          </>
        ),
        code_route: item.is_solvent ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "98%",
            }}
          >
            สำหรับผสม {self[index - 1].drugName}
          </div>
        ) : (
          <>
            {item.drugItemWithLabels.code_route}
            {!!Number(item.mixture_iv_rate) && (
              <>
                <div>
                  Drip Rate : {Number(item.mixture_iv_rate)} {mixtureIvRateUnit}
                </div>
              </>
            )}
            {!!Number(item.drug_drip_in) && (
              <>
                <div>
                  Drip Duration : {Number(item.drug_drip_in)} {drugDripInUnit}
                </div>
              </>
            )}
          </>
        ),
      };
    });
  }, [
    drugOrderItems,
    props.drugOrder?.estimate,
    props.drugOrder?.type,
    props.selectedEncounter,
    multiCheckbox,
    props.masterOptions,
    props.masterData,
  ]);

  const drugTable = useMemo(() => {
    const defaultTable = { header: "", width: "", key: "" };
    const showDiscount = true;

    const showPrintLabel = forwardAction === "PRINT";

    const label = showPrintLabel
      ? { header: ",Label", key: ",print_label", width: ",45" }
      : defaultTable;
    const discount = showDiscount
      ? {
          header: ",ส่วนลด,ส่วนลดสิทธิ",
          key: ",price_discount,price_discount_coverage",
          width: ",^55,^65",
        }
      : defaultTable;

    return {
      headers: `,ชื่อยา,Dose,Unit,Route,Frequency,Method,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price${discount.header}${label.header}`,
      keys: `check, name_status, dose, code_unit, code_route, code_frequency, code_method, prn, quantity, onhand, code_stock, price_unit, price_total${discount.key}${label.key}`,
      widths: `30,^150,^50,^80,^80,^80,^80,^70,^50,^65,^60,^65,^65${discount.width}${label.width}`,
    };
  }, [props.drugOrder, forwardAction]);

  // อัพเดต SelectedRow
  useEffect(() => {
    if (selectedRow?.id && !!formattedDrugOrderItems.length) {
      const order = formattedDrugOrderItems.find((item: any) => item.id === selectedRow.id);

      if (!!order && selectedRow.order_status !== order?.order_status) {
        handleSelectedRow(order);
      }
    }
  }, [selectedRow, formattedDrugOrderItems]);
  // -----------

  const drugOrderRefill = useMemo(() => {
    return (props.DrugRefillSequence?.order_items || []).map((item: any, _index: number) => ({
      ...item,
      code: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0px",
          }}
        >
          {item.code}
        </div>
      ),
      name: <div style={{ margin: "10px 0px 10px 20px" }}>{item.name}</div>,
      planned_quantity: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0px",
            color: item.planned_quantity !== item.quantity ? "red" : "",
          }}
        >
          {item.planned_quantity}
        </div>
      ),
      quantity: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0px",
          }}
        >
          {item.quantity}
        </div>
      ),
    }));
  }, [props.DrugRefillSequence]);

  const priceDrugOrder = useMemo(() => {
    return (props.drugOrder?.items || []).map((item: any) => ({
      price_total: item.price_total,
      price_claimable: item.price_claimable,
      price_non_claimable: item.price_non_claimable,
    }));
  }, [props.drugOrder?.items]);

  const sumPriceTotal = useMemo(() => {
    return priceDrugOrder.reduce(
      (sum: any, current: any) => sum + parseFloat(current.price_total),
      0
    );
  }, [priceDrugOrder]);

  const sumPriceClaimable = useMemo(() => {
    return priceDrugOrder.reduce((sum: any, current: any) => sum + current.price_claimable, 0);
  }, [priceDrugOrder]);

  const sumPriceNonClaimable = useMemo(() => {
    return priceDrugOrder.reduce((sum: any, current: any) => sum + current.price_non_claimable, 0);
  }, [priceDrugOrder]);

  const drugOrderLog = useMemo(() => {
    return (props.drugOrderLog?.items || []).map((item: any) => ({
      ...item,
      action: LABEL_BUTTON[item.action] || item.action,
      datetime: formatDatetime(item.datetime, true),
    }));
  }, [props.drugOrderLog?.items]);

  const drugDeliverOptions = useMemo(() => {
    const drugDelivery = {
      key: 1,
      value: "drugDelivery",
      text: "Drug Delivery",
      telepharType: "DELIVERY",
    };

    if (props.drugDelivery?.showDrugDelivery) {
      return [drugDelivery];
    } else {
      const options = [
        {
          key: 2,
          value: "patient",
          text: "ผู้ป่วยรับเอง",
          telepharType: "NONE",
        },
        {
          key: 3,
          value: "others",
          text: "บุคคลอื่นรับยาแทน",
          telepharType: "NONE",
        },
        {
          key: 4,
          value: "transporter",
          text: "เจ้าหน้าที่ รพ.",
          telepharType: "NONE",
        },
        ...(CONFIG.ENABLE_INTRA_TELEPHAR
          ? [
              {
                key: 5,
                value: "telelift",
                text: "Telelift",
                telepharType: "INTRA",
              },
            ]
          : []),
      ];

      if (!props.drugOrder?.drugTransportNotAllowedIds?.length) {
        options.push(drugDelivery);
      }

      return options;
    }
  }, [props.drugDelivery, props.drugOrder?.drugTransportNotAllowedIds]);

  const selectedDrugList = useMemo(() => {
    return drugOrderItems.filter((item: any) => multiCheckbox.includes(item.id));
  }, [drugOrderItems, multiCheckbox]);

  const isNurse = useMemo(
    () =>
      !!props.django?.user?.role_types?.find((role: string) => ["REGISTERED_NURSE"].includes(role)),
    [props.django]
  );

  const allowedDeliver = useMemo(
    () =>
      // * ต้องเป็นเภสัช หรือ พยายาล และใช้หน้า Drug order working เท่านั้นถึงจะ Deliver ได้
      (props.isPharmacist || isNurse) && !props.isCardDrugOrderHistory && !props.isCardReceive,
    [isNurse, props.isCardDrugOrderHistory, props.isCardReceive, props.isPharmacist]
  );
  const selectedVerbalDrugOrder = useMemo(() => {
    return props.verbalDrugOrderQueue?.find(
      (item: any) => item.ref_order === props.drugOrder?.pk
      );
    }, [props.drugOrder?.pk, props.verbalDrugOrderQueue]);

  const shouldShowReturn = useCallback(
    (data: { isDivideRefillPlan: boolean; isHome: boolean; status: string }) => {
      const hiddenStatuses = ["DELIVERED", "RECEIVED", "REJECTED"];
      const isDivideAtHome = data.isDivideRefillPlan && data.isHome;
      const showDrugDelivery = !!props.drugDelivery?.showDrugDelivery;

      return hiddenStatuses.includes(data.status) || isDivideAtHome || showDrugDelivery;
    },
    [props.drugDelivery]
  );

  const shouldShowForward = useCallback(
    (data: { isDivideRefillPlan: boolean; isHome: boolean; status: string }) => {
      const showReturn = shouldShowReturn(data);
      const isOrderHistory = props.isCardDrugOrderHistory;
      const isDeliveredTransported =
        ["DELIVERED", "TRANSPORTED"].includes(data.status) && forwardAction === "DELIVER";
      const notReceiveCard = !props.isCardReceive && forwardAction === "RECEIVE";
      const noForwardAction = !forwardAction;
      const checkedNoDeliver = !allowedDeliver && data.status === "CHECKED";
      const isReceiveNotTransported = props.isCardReceive && !["TRANSPORTED"].includes(data.status);

      return !(
        showReturn ||
        isOrderHistory ||
        isDeliveredTransported ||
        notReceiveCard ||
        noForwardAction ||
        checkedNoDeliver ||
        isReceiveNotTransported
      );
    },
    [
      allowedDeliver,
      forwardAction,
      props.drugDelivery,
      props.isCardDrugOrderHistory,
      props.isCardReceive,
      shouldShowReturn,
    ]
  );

  const shouldShowBackward = useCallback(
    (data: { orderPaymentStatus: string; status: string }) => {
      const isOrderHistory = props.isCardDrugOrderHistory;
      const isPaidUncheck = data.orderPaymentStatus === "PAID" && backwardAction === "UNCHECK";
      const isNurseUndeliver = isNurse && backwardAction === "UNDELIVER";
      const isPharmacistReceivedUndeliver =
        props.isPharmacist && backwardAction === "UNDELIVER" && data.status === "RECEIVED";
      const noBackwardAction = !backwardAction;
      const isRejectedOrCheckedPaidNoPickup = ["CHECKED_PAID_NO_PICKED_UP"].includes(
        data.status
      );
      const isReceiveNotTransported = props.isCardReceive && !["TRANSPORTED"].includes(data.status);

      return !(
        isOrderHistory ||
        isPaidUncheck ||
        isNurseUndeliver ||
        isPharmacistReceivedUndeliver ||
        noBackwardAction ||
        isRejectedOrCheckedPaidNoPickup ||
        isReceiveNotTransported
      );
    },
    [backwardAction, isNurse, props.isCardDrugOrderHistory, props.isPharmacist]
  );

  const buttonActions = useMemo(() => {
    const order = props.drugOrder || {};
    const isHome = ["HOME_IPD", "HOME_OPD"].includes(order.type);
    const status: string = order.status || ""

    const isDivideRefillPlan = ["DIVIDED", "REFILL_PLANNED"].includes(status);

    let openVideoCallModal: any = localStorage.getItem("openVideoCallModal");

    openVideoCallModal = openVideoCallModal === "true";

    const showConsult =
      !openVideoCallModal &&
      ["CALLING", "ON_CALL"].includes(order.consult_status) &&
      // * ไม่แสดงหน้าประวัติยา
      !props.isCardDrugOrderHistory &&
      // * ไม่แสดงหน้า Receive & Return
      !props.isCardReceive;

    const showTelephar =
      // * แสดงหน้า Receive & Return
      props.isCardReceive &&
      // order.consult_status === "IDLE" &&
      // order.status === "RECEIVED" &&
      CONFIG.ENABLE_INTRA_TELEPHAR;

    const hideAction = openVideoCallModal || showConsult;

    const showNarcotic = order.has_narcotic;
    const showReprint = status !== "REQUESTED";
    const showDrugRecommendation = order.recommendationFile?.length > 0;

    const showMissMeds = status.includes("CHECKED") && order.order_payment_status === "PAID";

    const isRequestedHome = status === "REQUESTED" && isHome;

    const showForward = shouldShowForward({ isDivideRefillPlan, isHome, status });

    const showBackward = shouldShowBackward({
      orderPaymentStatus: order.order_payment_status,
      status,
    });

    const showReturn = shouldShowReturn({ isDivideRefillPlan, isHome, status });

    // * Button Return
    const buttonReturnColorMap = {
      DIVIDED: { true: "teal" },
      REFILL_PLANNED: { true: "black" },
    } as any;

    const buttonReturnColor = buttonReturnColorMap[status]?.[isHome.toString()] || "";

    const buttonReturnTitle = isDivideRefillPlan && isHome ? "แผนการเติมยา REFILL" : "คืนยา";

    const isMissMeds = status === "CHECKED_PAID_NO_PICKED_UP";

    const showDiscount =
      status === "PRINTED" && props.drugPermission?.config_TPD_ENABLE_PTC_DISCOUNT;

    const items: Record<string, any>[] = order.items || [];

    const isPsychotropic = items.some((item) => item.is_psychotropic);

    return {
      additional: {
        // * แสดง Component การ delivery
        show: !(isRequestedHome || hideAction),
      },
      backward: {
        show: showBackward && !hideAction,
      },
      callAgain: {
        // * แสดงหลังจากกดปิด VDO call
        show: showConsult,
      },
      discount: {
        show: showDiscount,
      },
      drugRecommendation: {
        // * คำแนะนำการใช้ยา
        show: showDrugRecommendation,
      },
      endConsult: {
        // * แสดงหลังจากกดปิด VDO call
        show: showConsult,
        title:
          // * RECEIVED INTRA TELEPHAR
          status === "RECEIVED" ? "จบการให้คำแนะนำ" : "Consult เสร็จสิ้น",
      },
      forward: {
        disabled: isMissMeds,
        show: showForward && !hideAction,
      },
      language: {
        show: status === "REQUESTED" && !props.isCardReceive && !hideAction,
      },
      missMeds: {
        checked: isMissMeds,
        show: showMissMeds,
      },
      narcotic: {
        // * แบบ ย.ส. 5
        show: showNarcotic,
      },
      psychotropic: {
        show: isPsychotropic,
      },
      refill: {
        // * REFILL
        show: isRequestedHome && !hideAction,
      },
      reprint: {
        // * Reprint
        show: showReprint,
      },
      return: {
        color: buttonReturnColor,
        // * คืนยา
        show: showReturn && status !== "REJECTED" && !hideAction,
        title: buttonReturnTitle,
      },
      telephar: {
        show: showTelephar,
      },
    };
  }, [
    allowedDeliver,
    backwardAction,
    forwardAction,
    isNurse,
    props.drugDelivery,
    props.drugOrder,
    props.drugPermission,
    props.isCardDrugOrderHistory,
    props.isCardReceive,
    props.openVideoCallModal,
    shouldShowBackward,
    shouldShowForward,
    shouldShowReturn,
  ]);

  const handleSelectedRow = async (row: any) => {
    const [medError] = await props.onEvent({
      message: "GetMedicationErrorDetail",
      params: { id: row.id },
    });

    setSelectedRow({ ...row, med_error_status: medError?.status });
  };

  const handleRowProps = (state: any, rowInfo: any, column: any, instance: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id ? "#cccccc" : "white",
      },
      onClick: () => handleSelectedRow(rowInfo.original),
    };
  };

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    isDrugAdmixture: boolean,
    isSolvent: boolean
  ) => {
    const row = event.currentTarget;
    row.classList.add("linked-row");

    const nextRow = row.nextElementSibling as HTMLTableRowElement | null;
    const prevRow = row.previousElementSibling as HTMLTableRowElement | null;

    if (isDrugAdmixture && nextRow) {
      nextRow.classList.add("linked-row");
    } else if (isSolvent && prevRow) {
      prevRow.classList.add("linked-row");
    }
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    const row = event.currentTarget;
    row.classList.remove("linked-row");

    const nextRow = row.nextElementSibling as HTMLTableRowElement | null;
    const prevRow = row.previousElementSibling as HTMLTableRowElement | null;

    if (nextRow) {
      nextRow.classList.remove("linked-row");
    }

    if (prevRow) {
      prevRow.classList.remove("linked-row");
    }
  };

  const handleTrGroupProps = (state: any, rowInfo: any) => {
    const original = rowInfo?.original || {};

    return {
      style: {
        borderBottom: 0,
        lineHeight: 1.5,
        textDecoration: original.is_terminated ? "line-through" : "",
      },
      onMouseEnter: (event: any) =>
        handleMouseEnter(event, original.isDrugAdmixture, original.is_solvent),
      onMouseLeave: handleMouseLeave,
    };
  };

  const handleTdProps = (state: any, rowInfo: any, column: any) => {
    const original = rowInfo?.original || {};
    const borderBottom =
      ["check", "print_label"].includes(column.id) && original.isDrugAdmixture
        ? ""
        : "solid 1px rgba(0,0,0,0.05)";

    if (original.is_solvent) {
      if (column.id === "code_route") {
        return {
          colSpan: 4,
          style: {
            borderBottom,
            minWidth: "455px", // Adjust this value as needed
          },
        };
      } else if (["code_frequency", "code_site", "prn"].includes(column.id)) {
        return { style: { borderBottom, display: "none" } };
      }
    }

    return {
      style: { borderBottom, overflow: "unset", position: "relative" },
    };
  };

  const handleCloseMod = () => {
    setIsOpen(false);

    props.setProp("MedErrorListSequence.medErrorWorking", {});
  };

  const handleEdited = () => {
    handleCloseMod();
  };

  const handleSelectedDelivery = (deliver: string | null) => {
    let deliveryBy: any = null;

    if (selectedDelivery !== deliver) {
      deliveryBy = deliver;
    }

    props.setProp("drugDelivery", {
      ...props.drugDelivery,
      isTransport: ["transporter", "telelift"].includes(deliveryBy || ""),
      isOthers: deliveryBy === "others",
      isDrugDelivery: deliver === "drugDelivery",
      telepharType: drugDeliverOptions.find((item) => item.value === deliveryBy)?.telepharType,
    });

    setSelectedDelivery(deliveryBy);
  };

  const handleSelectedAddressDelivery = (key: string) => {
    props.setProp("drugDelivery", {
      ...props.drugDelivery,
      address: key,
    });
  };

  const changePropDrugDelivery = (key: string) => (_e: any, v: any) => {
    props.setProp(`drugDelivery.${key}`, v.value);
  };

  const handleReprint = (data: any) => {
    props.onEvent({
      message: "PutDrugOrderItemPrintLabel",
      params: { id: selectedRow?.id, data, card: CARD_MOD_REPRINT },
    });
  };

  const handleLogDrugOrder = () => {
    props.onEvent({
      message: "HanleOnLogDrugOrder",
      params: { action: "LOG" },
    });
    setOpenModelLog(true);
  };

  const handleClickDrugRequest = () => {
    setOpenDrugRequest(true);
  };

  const handleDrugOrderAction = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: forwardAction,
        card: CARD_DRUG_ORDER_WORKING,
        language: printLang,
        buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_FORWARD`,
        repassword: password,
      },
    });

    props.setProp("drugDelivery", {
      ...props.drugDelivery,
      token: "",
      employeeName: "",
      loading: false,
      error: null,
    });
  }

  const handleForwardAction = async () => {
    if (forwardAction === "PRINT") {
      const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

      if (isLocked) {
        return;
      }
    }

    setButtonLoadKey(`${CARD_DRUG_ORDER_WORKING}_FORWARD`);

    if (forwardAction === "DELIVER") {
      if (props?.drugDelivery?.isDrugDelivery) {
        console.log("CHECK DELIVER  DRUG");
      } else {
        props.onEvent({
          message: "RunSequence",
          params: {
            sequence: "DrugOrderAction",
            action: forwardAction,
            card: CARD_DRUG_ORDER_WORKING,

            buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_FORWARD`,
          },
        });
      }
    } else {
      const isUnavailableItems =
        props.drugOrder?.already_off_items?.length > 0 ||
        props.drugOrder?.already_hold_items?.length > 0;

      if (isUnavailableItems && forwardAction === "CHECK") {
        setOpenModAlreadyOffHold(true);
      } else {
        handleDrugOrderAction();
      }
    }
  };

  const handlebackwardAction = () => {
    setButtonLoadKey(`${CARD_DRUG_ORDER_WORKING}_BACKWARD`);

    if (backwardAction === "REJECT") {
      if (!multiCheckbox.length) {
        setOpenErrorReject(true);
      } else {
        props.onEvent({
          message: "RunSequence",
          params: {
            sequence: "DrugOrderAction",
            action: backwardAction,
            card: CARD_DRUG_ORDER_WORKING,
          },
        });
      }
    } else if (props.drugOrder?.status === "REFILL_PLANNED") {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "CANCEL_PLAN",
          card: CARD_DRUG_ORDER_REFILL,
          buttonLoadKey: `${CARD_DRUG_ORDER_REFILL}_CENCAL`,
        },
      });
    } else {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: backwardAction,
          card: CARD_DRUG_ORDER_WORKING,
          buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_BACKWARD`,
        },
      });
    }
  };

  const handleReturnDrug = () => {
    const order = props.drugOrder || {};

    if (
      ["DIVIDED", "REFILL_PLANNED"].includes(order.status) &&
      ["HOME_OPD", "HOME_IPD"].includes(order.type)
    ) {
      setOpenModRefillDrug(true);

      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "getRefill",
        },
      });
    } else {
      setOpenModReturnDrug(true);

      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "ReturnOrder",
          action: "RETURN_DRUG",
          id: props.drugOrder.pk,
        },
      });
    }
  };

  const handleStartTelepharmacy = () => {
    console.log(props.drugOrder);
    props?.onOpenVideoCall?.(props.drugOrder?.code);

    if (props.drugOrder?.consult_status === "IDLE") {
      props.onEvent({
        message: "DrugOrderUpdateCallTelephar",
        params: {
          id: props.drugOrder?.pk,
          data: { consult_status: "CALLING" },
        },
      });
    }
  };

  const handleRefillAction = () => {
    setOpenModRefill(false);

    if (props.drugOrder?.status === "REFILL_PLANNED") {
      setButtonLoadKey(`${CARD_DRUG_ORDER_WORKING}_REFILLPRINT`);
      setOpenModRefillDrug(false);

      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "createRefillOrder",
          getRefill: true,
        },
      });
    } else {
      setButtonLoadKey(`${CARD_DRUG_ORDER_WORKING}_REFILL`);

      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "DIVIDE",
          card: CARD_DRUG_ORDER_WORKING,
          buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_REFILL`,
          getRefill: true,
        },
      });

      setOpenModRefillDrug(true);
    }
  };

  const handleRemoveRefill = (index: any) => {
    //calculatorRefill
    let newDataOrderItems: any = props.DrugRefillSequence?.order_items || [];

    (props.DrugRefillSequence?.appointments || []).forEach((item: any, id: any) => {
      if (index === id) {
        item.plannings.forEach((data: any, number: any) => {
          newDataOrderItems[number].planned_quantity =
            newDataOrderItems[number].planned_quantity - data.quantity;
        });
      }
    });

    // newDataOrderList
    let newDataAppointments: any = [];
    (props.DrugRefillSequence?.appointments || []).forEach((item: any, id: any) => {
      if (index !== id) {
        newDataAppointments.push(item);
      }
    });

    props.setProp("DrugRefillSequence.appointments", newDataAppointments);
  };

  const handleCalculatorRefill = (index: any, number: any, value: any) => {
    props.setProp(`DrugRefillSequence.appointments.${index}.plannings.${number}.quantity`, value);

    let sum: number = 0;
    (props.DrugRefillSequence?.appointments || []).forEach((item: any) => {
      sum = sum + parseInt(item.plannings[number].quantity);
    });

    props.setProp(`DrugRefillSequence.order_items.${number}.planned_quantity`, sum);
  };

  const handleAddOrderList = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: "addOrderList",
      },
    });
  };

  const handleCalculatorSplit = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: "calculatorSplit",
      },
    });
  };

  const handleChangeSplit = (key: string) => (_e: any, v: any) => {
    props.setProp(`DrugRefillSequence.${key}`, v.value);
  };

  const handleCloseDrugTransferRequest = async () => {
    await props.setProp(`DrugTransferRequestSequence.selectedDivisionPharma`, null);
    await props.setProp("DrugTransferRequestSequence.selectedDrugSearch", null);
    await props.setProp("DrugTransferRequestSequence.selectedDrugDose", null);
    props.setProp("drugTransferRequestlist", []);
    props.setProp("drugTransferRequestDetail", null);

    setOpenDrugRequest(false);
  };

  const handleClearSelectDrug = () => {
    props.setProp("selectedDrugOrderWorking", null);
  };

  const handleClickLotNo = () => {
    setOpenModLotNo(true);
  };

  const handleCloseModLotNo = () => {
    setOpenModLotNo(false);
  };

  // Handler
  const handleClose = () => {
    setOpenModelLog(false);
  };

  const handleCloseError = () => {
    setOpenErrorReject(false);
  };

  const handleCloseModReturnDrug = () => {
    setOpenModReturnDrug(false);

    props.setProp(`DrugReturnList`, null);
    props.setProp(`OrderHistory`, null);
    props.setProp(`OrderReturnLog`, null);
    props.setProp(`OrderReturnList`, null);
    props.setProp(`ReturnOrderSequence.sequenceIndex`, "START");
  };

  const handleChangeValue = (key: string) => (_e: any, v: any) => {
    props.setProp(`DrugOrderActionSequence.${key}`, v.value);
  };

  const handleCloseModReprint = () => {
    setOpenModeReprint(false);
    props.setProp("DrugOrderActionSequence", {
      ...props.DrugOrderActionSequence,
      printPharma: false,
      printPatient: false,
      printLabel: false,
      printMedicine: false,
      language: "thai",
      noteReprint: "",
    });
    setTab("thai");
  };

  const handleNarCoticPrint = () => {
    props.onEvent({
      message: "HandlePrintNarcoticForm",
      params: {
        order_id: props.drugOrder.pk,
        card: CARD_DRUG_ORDER_WORKING,
        loading: "narcotic",
      },
    });
  };

  const handleClickMedError = () => {
    setIsOpen(true);
  };

  const handleChangeActionNote = (_e: any, data: any) => {
    // console.log("modConfirmAction: ",e, data)
    props.setProp("modConfirmAction", {
      ...props.modConfirmAction,
      value: data.value,
      text: data.options.find((i: any) => i.value === data.value).text,
    });
  };

  const handleConfirmActionNote = () => {
    if (props.drugOrder?.status === "REFILL_PLANNED") {
      setOpenModRefillDrug(false);
    }

    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: props.modConfirmAction?.action,
        card: CARD_DRUG_ORDER_WORKING,
        buttonLoadKey: buttonLoadKey,
        confirm: true,
      },
    });
  };

  const handleConfirmModReprint = () => {
    if (
      props?.DrugOrderActionSequence?.printPharma ||
      props?.DrugOrderActionSequence?.printPatient ||
      props?.DrugOrderActionSequence?.printLabel ||
      props?.DrugOrderActionSequence?.printMedReport ||
      props?.DrugOrderActionSequence?.printMedicine
    ) {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "REPRINT",
          card: CARD_REPRINT_DRUG_ORDER_WORKING,
        },
      });
    } else {
      alert("กรุณาเลือก");
    }
  };
  const handleDrugDeliveryPrint = () => {
    if (props?.drugDelivery?.showDrugDelivery) {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "PRINTQR",
          card: CARD_DRUG_ORDER_WORKING,
        },
      });
    } else {
      setButtonLoadKey(`${CARD_DRUG_ORDER_WORKING}_FORWARD`);
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "DELIVER",
          card: CARD_DRUG_ORDER_WORKING,
          buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_FORWARD`,
        },
      });
    }
  };

  const handleChangePersonName = (_e: any, data: any) => {
    props.setProp("drugDelivery.personName", data.value);
  };

  const handleCloseModDesc = () => {
    setModDrugDesc({});
  };

  const handleCloseModLastEdited = async () => {
    props.setProp(`errorMessage.${CARD_DRUG_ORDER_WORKING}_LAST_EDITED`, null);

    props.onEvent({
      message: "SelectDrugOrderWorking",
      params: {
        drugOrder: props.drugOrder ? { ...props.drugOrder, id: props.drugOrder.pk } : null,
        history,
      },
    });
  };

  const handleEndConsult = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: "UPDATE_CONSULT_STATUS",
        card: CARD_DRUG_ORDER_WORKING,
        buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_END`,
      },
    });
  };

  const handleMissMeds = async () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: buttonActions.missMeds.checked
          ? "REVERSE_CHECKED_PAID_NO_PICK_UP"
          : "CHECKED_PAID_NO_PICK_UP",
      },
    });
  };

  const handleGetDiscount = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: "getDiscount",
        encounterId: props.selectedEncounter?.id,
        orderId: props.drugOrder?.pk,
      },
    });
  };

  const handleEditDiscount = (params: any) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: "editDiscount",
        card: CARD_DRUG_ORDER_WORKING,
        buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_FORWARD`,
        ...params,
      },
    });
  };

  const handleCloseModDiscount = () => {
    props.setProp("DrugOrderActionSequence.modDiscount", null);
  };

  const handleChangePrintLang = (e: any, data: ButtonProps) => {
    setPrintLang(data.name);
  };

  const handleCloseModAlreadyOffHold = () => {
    setOpenModAlreadyOffHold(false);
  }

  const handleApproveModAlreadyOffHold = () => {
    handleDrugOrderAction();

    handleCloseModAlreadyOffHold();
  }

  const handleRefreshDrug = () => {
    props.onEvent({
      message: "SelectDrugOrderWorking",
      params: {
        btnAction: `${CARD_DRUG_ORDER_WORKING}_REFRESH`,
        drugOrder: { ...props.drugOrder, id: props.drugOrder?.pk },
        isNotGetEncounter: true,
      },
    });
  };

  // Todo: make drug order item table shrink to left and show toolbox on the right
  // Todo: add symbols for adr , etc in drug order item table
  // Todo: add CardOrderSummary for pharmacy to review
  // Todo: add drugorder type refill
  console.log("CardDrugOrderWorking:", props, selectedRow);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {props.drugOrderQueue && !props.isCardReceive && (
        <CardDrugOrderQueue
          hidePanel={true}
          height={props.drugOrder ? "200px" : "80vh"}
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          drugOrderQueue={
            !openRefillPlanned ? props.drugOrderQueue : props.DrugOrderPendingList || []
          }
          showDrugOrderPendingList={props.DrugOrderPendingList?.length !== 0}
          selectedDrugOrderWorking={props.drugOrder}
          selectedEncounter={props.selectedEncounter}
          layout={props.layout}
          forward={props.forward}
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          refillPlaned={
            <div
              aria-hidden="true"
              // callback
              onClick={() => {
                setOpenRefillPlanned(!openRefillPlanned);

                props.onEvent({
                  message: "RunSequence",
                  params: {
                    sequence: "DrugOrderAction",
                    action: "getDrugRefillPlaned",
                  },
                });
              }}
            >
              {openRefillPlanned ? (
                <div
                  style={{
                    display: "flex",
                    color: "#000000",
                    fontSize: "14px",
                    padding: "6px 20px",
                    alignItems: "center",
                    border: "solid 0.5px ",
                    borderRadius: "10px",
                    borderColor: "#ED5151",
                    cursor: "pointer",
                  }}
                >
                  ซ่อนใบยา Refill_Planned
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    background: "#ED5151",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    padding: "6px 20px",
                    borderRadius: "10px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      background: "#FEC13B",
                      color: "#ED5151",
                      borderRadius: "220px",
                      fontSize: "18px",
                      padding: "2px 8px",
                      marginRight: "5px",
                    }}
                  >
                    !
                  </div>
                  แสดงใบยา Refill_Planned
                </div>
              )}
            </div>
          }
          languageUX={props.languageUX}
        />
      )}

      {props.errorMessage?.[CARD_DRUG_ORDER_WORKING]?.error?.length > 0 &&
      props.errorMessage?.[CARD_DRUG_ORDER_WORKING]?.error?.[0]?.includes("Med Reconcile") &&
      props.errorMessage?.[CARD_DRUG_ORDER_WORKING]?.error?.[0]?.includes("ยังไม่ถูกรับทราบ") ? (
        <ModMedReconcileAlert
          onEvent={props.onEvent}
          setProp={props.setProp}
          card={CARD_DRUG_ORDER_WORKING}
          django={props.django}
          medReconcileCheck={props.medReconcileCheck}
          medReconcileIndex={props.medReconcileIndex}
          open={true}
          errorMessage={props.errorMessage}
          languageUX={props.languageUX}
        />
      ) : (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_DRUG_ORDER_WORKING}`, null);
          }}
          error={props.errorMessage?.[CARD_DRUG_ORDER_WORKING]?.error}
          success={props.successMessage?.[CARD_DRUG_ORDER_WORKING]}
          languageUX={props.languageUX}
        />
      )}

      {props.drugOrder && props.drugOrder?.type !== "CONTINUE_PLAN" && (
        <div
          id={"CardDrugOrderWorking"}
          className={selectedRow ? "show-toolbox" : ""}
          style={{ width: "100%", paddingBottom: "2rem" }}
        >
          {props.closeIcon && (
            <div style={{ height: "20px", position: "relative" }}>
              <Icon
                name="close"
                size="large"
                color="grey"
                style={CloseIconStyle}
                onClick={handleClearSelectDrug}
              />
            </div>
          )}

          <CardDrugOrderWorkingUX
            //data
            height={selectedDelivery === "drugDelivery" ? "829px" : "750px"}
            nameTypeOrder={
              props?.isCardDrugOrderHistory ? (
                <Label
                  size="small"
                  style={{
                    backgroundColor: (ORDER_TYPE_COLORS as any)[props.prescriptionType] || "",
                    margin: "auto 0",
                    color: "white",
                  }}
                >
                  {props.nameTypeOrder}
                </Label>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ minWidth: "max-content" }}>
                    {props.selectedEncounter?.general_type === "IPD"
                      ? "ตรวจสอบการสั่งยาผู้ป่วยใน"
                      : "ตรวจสอบการสั่งยาผู้ป่วยนอก"}
                  </label>
                  <Button
                    size="mini"
                    loading={!!props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_REFRESH`]}
                    onClick={handleRefreshDrug}
                    style={{ margin: "0 -0.25rem 0 0.75rem", padding: "0.25rem 0.5rem 0.075rem" }}
                  >
                    <Icon name="refresh" style={{ margin: 0 }} />
                    <div style={{ fontSize: "0.5rem" }}>Refresh</div>
                  </Button>
                  <Label
                    size="small"
                    style={{
                      backgroundColor: (ORDER_TYPE_COLORS as any)[props.drugOrder?.type] || "",
                      marginLeft: "1rem",
                      color: "white",
                    }}
                  >
                    {props.drugOrder?.code} : {getDrugTypeName(props.drugOrder?.type)}
                  </Label>
                  <div style={{ marginLeft: "1rem" }}>
                    {props.drugOrderQueue
                      ?.filter((item: any) => item.id === props.drugOrder?.pk)
                      .map((item: any) => {
                        return (
                          <div>
                            {item?.need_approve === false && item?.approved_by_name ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={"/static/images/drugOrder/approve_check.png"}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  style={{
                                    color: "green",
                                    marginRight: "10px",
                                  }}
                                >
                                  APPROVE
                                </label>
                                <div style={{ display: "flex", color: "black" }}>
                                  <label>
                                    {"[ "}
                                    <span style={{ color: "#898383" }}>
                                      {intl.formatMessage({ id: "โดย" })}{" "}
                                    </span>
                                    {item?.approved_by_name} {" ]"}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              item?.need_approve === true && (
                                <div
                                  style={{
                                    color: "gray",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={"/static/images/drugOrder/need_approve.png"}
                                    style={{ marginRight: "5px" }}
                                  />
                                  <label>NEED APPROVE</label>
                                </div>
                              )
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )
            }
            msgVerbal={
              selectedVerbalDrugOrder &&
              selectedVerbalDrugOrder?.note && (
                <Message icon compact warning>
                  <Icon name="pencil" size="tiny" />
                  <MessageContent>
                    <MessageHeader>{intl.formatMessage({ id: "รคส:" })}</MessageHeader>
                    {selectedVerbalDrugOrder?.note || ""}
                  </MessageContent>
                </Message>
              )
            }
            orderStatus={
              props.drugOrder?.status && (
                <Label
                  size="small"
                  style={{
                    backgroundColor: (ORDER_STATUS_COLORS as any)[props.drugOrder?.status] || "",
                    marginLeft: "1rem",
                    color: "white",
                  }}
                >
                  {getDrugStatusName(props.drugOrder?.status, props.drugOrder?.consult_status)}
                </Label>
              )
            }
            orderPaymentStatus={
              props.drugOrder?.order_payment_status && (
                <Label
                  size="small"
                  style={{
                    backgroundColor:
                      (ORDER_PAYMENT_STATUS as any)[props.drugOrder?.order_payment_status] || "",
                    marginLeft: "1rem",
                    color: "white",
                  }}
                >
                  {props.drugOrder?.order_payment_status}
                  {/* {"PENDING"} */}
                </Label>
              )
            }
            subAdrPopup={
              <SubAdrPopup
                runSequence={props.runSequence}
                AllergySequence={props.AllergySequence}
                // data
                patientId={props.isCardReceive ? props.selectedPatient?.id : null}
                languageUX={props.languageUX}
              />
            }
            showTelepharType={props.drugOrder?.type === "HOME_OPD" && CONFIG.ENABLE_INTRA_TELEPHAR}
            telepharType={props.drugOrder?.telephar_type || ""}
            OnReprint={() => setOpenModeReprint(true)}
            onClickDiscount={handleGetDiscount}
            showReprint={props.drugOrder?.status !== "REQUESTED"}
            drugOrder={props.drugOrder}
            // formattedDrugOrderItems={formattedDrugOrderItems}
            sumPriceTotal={sumPriceTotal}
            sumPriceClaimable={sumPriceClaimable}
            sumPriceNonClaimable={sumPriceNonClaimable}
            requestTime={
              moment(new Date(props.drugOrder?.requested?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            printedTime={
              moment(new Date(props.drugOrder?.printed?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            checkedTime={
              moment(new Date(props.drugOrder?.checked?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            deliveredTime={
              moment(new Date(props.drugOrder?.delivered?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            transportedTime={
              moment(new Date(props.drugOrder?.transported?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            receivedTime={
              moment(new Date(props.drugOrder?.received?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            showPrintLabel={forwardAction === "PRINT"}
            drugTable={drugTable}
            loadingStatus={props.loadingStatus}
            buttonActions={buttonActions}
            // Component
            tableElement={
              <Table
                className="--rt-tbody-overflow-y-hidden"
                data={formattedDrugOrderItems}
                getTrProps={handleRowProps}
                getTrGroupProps={handleTrGroupProps}
                getTdProps={handleTdProps}
                headers={
                  forwardAction === "PRINT"
                    ? ",code,ชื่อยา,Dose,Unit,Route,Site,Frequency,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price,Label"
                    : ",code,ชื่อยา,Dose,Unit,Route,Site,Frequency,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price"
                }
                id="tb-medOrderReqList"
                keys={
                  forwardAction === "PRINT"
                    ? "check,code,name_status,dose,code_unit,code_route,code_site,code_frequency,prn,quantity,onhand,code_stock,price_unit,price_total,print_label"
                    : "check,code,name_status,dose,code_unit,code_route,code_site,code_frequency,prn,quantity,onhand,code_stock,price_unit,price_total"
                }
                minRows="4"
                showPagination={false}
                style={{ height: "255px" }}
                widths={
                  forwardAction === "PRINT"
                    ? "30,80,^150,^60,^60,170,95,95,95,^60,^60,^80,^80,^70,^55"
                    : "30,80,^150,^60,^60,170,95,95,95,^60,^70,^70,^75,^60"
                }
              />
            }
            passwordInput={
              <div>
                {forwardAction &&
                ACTION_BUTTON[forwardAction] === "RECEIVE" &&
                buttonActions?.forward?.show ? (
                  <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                    <div>
                      Password <span style={{ color: "red" }}>*</span>
                    </div>
                    <PasswordRecheck password={password} setPassword={setPassword} />
                  </div>
                ) : null}
              </div>
            }
            buttonLanguage={
              !props.isCardDrugOrderHistory && (
                <ButtonGroup size="small">
                  {["TH", "EN"].map((lang) => (
                    <Button
                      key={lang}
                      basic={printLang !== lang}
                      color="blue"
                      name={lang}
                      onClick={handleChangePrintLang}
                    >
                      {lang}
                    </Button>
                  ))}
                </ButtonGroup>
              )
            }
            buttonTelePharmacy={
              <ButtonCallPharmacist
                setProp={props.setProp}
                consultStatus={props.drugOrder?.consult_status}
                encounterStatus={props.selectedEncounter?.status}
                orderPaymentStatus={props.drugOrder?.order_payment_status}
                productType={"drug"}
                specificType={props.drugOrder?.type}
                statusName={props.drugOrder?.status}
                telepharType={props.drugOrder?.telephar_type}
                onCall={handleStartTelepharmacy}
              />
            }
            buttonEndConsult={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleEndConsult}
                // data
                paramKey={`${CARD_DRUG_ORDER_WORKING}_END`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_END`]}
                // config
                color={"green"}
                name=""
                size="small"
                style={{
                  margin: 0,
                  display: CONFIG.ENABLE_INTRA_TELEPHAR ? "" : "none",
                }}
                title={buttonActions.endConsult.title}
              />
            }
            buttonVideoCallAgain={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleStartTelepharmacy}
                // data
                paramKey={`${CARD_DRUG_ORDER_WORKING}_AGAIN`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_AGAIN`]}
                // config
                color={"blue"}
                name=""
                size="small"
                style={{
                  margin: 0,
                  display: CONFIG.ENABLE_INTRA_TELEPHAR ? "" : "none",
                }}
                title={intl.formatMessage({ id: "ต่อสาย VDO call อีกครั้ง" })}
              />
            }
            buttonReturn={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleReturnDrug}
                // data
                paramKey={`${CARD_DRUG_ORDER_WORKING}_REFILL`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_REFILL`]}
                // config
                color={buttonActions.return.color}
                name=""
                size="small"
                style={{ width: "100%" }}
                title={buttonActions.return.title}
              />
            }
            buttonRefill={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => setOpenModRefill(true)}
                // data
                paramKey={`${CARD_DRUG_ORDER_WORKING}_REFILL`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_REFILL`]}
                // config
                color={"yellow"}
                name="REFILL"
                size="small"
                title={"REFILL"}
                style={{ margin: 0 }}
              />
            }
            buttonForward={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleForwardAction}
                // data
                paramKey={`${CARD_DRUG_ORDER_WORKING}_FORWARD`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_FORWARD`]}
                // config
                color={forwardColor || "green"}
                name="FORWARD"
                size="small"
                disabled={buttonActions.forward.disabled}
                style={{ margin: 0 }}
                title={forwardAction ? ACTION_BUTTON[forwardAction] : "NO ACTION"}
              />
            }
            buttonBackward={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handlebackwardAction}
                // data
                paramKey={`${CARD_DRUG_ORDER_WORKING}_BACKWARD`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_BACKWARD`]}
                // config
                color="red"
                name="BACKWARD"
                size="small"
                style={{ margin: 0 }}
                title={
                  backwardAction
                    ? LABEL_BUTTON[backwardAction] || ACTION_BUTTON[backwardAction]
                    : "NO ACTION"
                }
              />
            }
            //
            onReturnDrug={() => {
              setOpenModReturnDrug(true);
              props.onEvent({
                message: "RunSequence",
                params: {
                  sequence: "ReturnOrder",
                  action: "RETURN_DRUG",
                  id: props.drugOrder.pk,
                },
              });
            }}
            printDrugAdvice={() => {
              props.onEvent({
                message: "HandleDrugRecommendation",
                params: {
                  action: "open",
                  drugOrderId: props.drugOrder?.pk,
                  card: CARD_DRUG_ORDER_WORKING,
                },
              });
            }}
            onPrintPsychotropic={() => {
              props.onEvent({
                message: "HandlePrintPsychotropicForm",
                params: {
                  order_id: props.drugOrder?.pk,
                  loading: "PRINT_PSYCHOTROPIC_FORM",
                  card: CARD_DRUG_ORDER_WORKING,
                },
              });
            }}
            // narcotic set
            loadingNarcotic={props.loadingStatus?.["narcotic"] || false}
            narcoticPrint={handleNarCoticPrint}
            handleOnLog={handleLogDrugOrder}
            // rowProps={handleRowProps}
            selectedTransporter={["transporter", "telelift"].includes(selectedDelivery || "")}
            additionalProps={
              <div
                style={{
                  display: "flex",
                  justifyContent: props.drugDelivery?.showDrugDelivery ? "flex-start" : "flex-end",
                }}
              >
                <div style={{ flex: 1 }}></div>
                {allowedDeliver && props.drugDelivery?.showDrugDelivery ? (
                  <>
                    <DropdownDeliver
                      value={selectedDelivery}
                      options={drugDeliverOptions}
                      onChange={handleSelectedDelivery}
                    />
                  </>
                ) : !["CHECKED", "CHECKED_PAID_NO_PICKED_UP"].includes(
                    props.drugOrder?.status || ""
                  ) || forwardAction === "WAIVE" ? (
                  <></>
                ) : (
                  <>
                    {buttonActions.missMeds.show && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "-5px 1rem 0 0",
                        }}
                      >
                        <Checkbox
                          className="blue"
                          label={intl.formatMessage({ id: "ผู้ป่วยไม่มารับยา" })}
                          checked={buttonActions.missMeds.checked}
                          onClick={handleMissMeds}
                        />
                      </div>
                    )}

                    {allowedDeliver && (
                      <DropdownDeliver
                        value={selectedDelivery}
                        disabled={buttonActions.missMeds.checked}
                        options={drugDeliverOptions}
                        onChange={handleSelectedDelivery}
                      />
                    )}

                    {allowedDeliver &&
                      ["transporter", "telelift"].includes(selectedDelivery || "") && (
                        <div id="deliver-employee-token">
                          {/* <span style={{ padding: "0px 5px" }}>{intl.formatMessage({ id: "ผู้จัดส่ง" })}</span> */}
                          <EmployeeToken
                            employeeName={props.drugDelivery?.employeeName}
                            error={props.drugDelivery?.error}
                            loading={props.drugDelivery?.loading}
                            fluid={true}
                            disabled={buttonActions.missMeds.checked}
                            inputStyle={{
                              width: "150px",
                              marginLeft: "0.75rem",
                            }}
                            // Callback
                            onEnterToken={(code) => {
                              props.onEvent({
                                message: "HandleGetDeliverDrugTokenization",
                                params: { code },
                              });
                            }}
                            onClearToken={() =>
                              props.setProp("drugDelivery", {
                                ...props.drugDelivery,
                                token: "",
                                employeeName: "",
                                loading: false,
                                error: null,
                              })
                            }
                          />
                        </div>
                      )}

                    {selectedDelivery === "others" && (
                      <Input
                        id="deliver-others"
                        value={props.drugDelivery?.personName}
                        placeholder={intl.formatMessage({ id: "ชื่อผู้รับยา" })}
                        style={{ paddingLeft: "1rem" }}
                        onChange={handleChangePersonName}
                      />
                    )}
                  </>
                )}
              </div>
            }
            drugDelivery={
              <>
                {selectedDelivery === "drugDelivery" && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "10px ",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "5px" }}>
                        <Icon name="truck" size="small" />
                      </div>

                      <div style={{ display: "flex", marginRight: "5px" }}>
                        <label> Shipper </label>
                        <label style={{ color: "red" }}> * </label>
                      </div>

                      <div style={{ marginRight: "5px", marginBottom: "-5px" }}>
                        <Dropdown
                          clearable={true}
                          onChange={changePropDrugDelivery("shipper")}
                          options={props?.masterOptions?.shippingEmployee}
                          search={true}
                          selection={true}
                          value={props?.drugDelivery?.shipper || ""}
                          disabled={!!props?.drugDelivery?.showDrugDelivery}
                        ></Dropdown>
                      </div>

                      <div style={{ marginRight: "5px" }}>
                        <label> TrackingNo. </label>
                        <label style={{ color: "red" }}> * </label>
                      </div>

                      <div style={{ marginRight: "5px" }}>
                        <Input
                          value={props?.drugDelivery?.trackingNo || ""}
                          onChange={changePropDrugDelivery("trackingNo")}
                          readOnly={!!props?.drugDelivery?.showDrugDelivery}
                        ></Input>
                      </div>
                    </div>

                    <div
                      style={{
                        paddingTop: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "5px" }}>
                        <Icon name="home" size="small" />
                      </div>

                      <div style={{ marginRight: "5px" }}>
                        <label>{intl.formatMessage({ id: "สถานที่จัดส่ง" })}</label>
                        <label style={{ color: "red" }}> * </label>
                        <label> : </label>
                      </div>

                      <Radio
                        style={{ padding: "2px 5px" }}
                        label={intl.formatMessage({ id: "ที่อยู่ตามทะเบียนบ้าน" })}
                        value="home"
                        checked={props?.drugDelivery?.address === "home"}
                        readOnly={!!props?.drugDelivery?.showDrugDelivery}
                        onChange={() => {
                          handleSelectedAddressDelivery("home");
                        }}
                      />

                      <Radio
                        style={{ padding: "2px 5px" }}
                        label={intl.formatMessage({ id: "ที่อยู่ปัจจุบัน" })}
                        value="present"
                        checked={props?.drugDelivery?.address === "present"}
                        readOnly={!!props?.drugDelivery?.showDrugDelivery}
                        onChange={() => {
                          handleSelectedAddressDelivery("present");
                        }}
                      />

                      <ButtonLoadCheck
                        // function
                        setProp={props.setProp}
                        onClick={handleDrugDeliveryPrint}
                        // data
                        paramKey={`${CARD_DRUG_ORDER_WORKING}_FORWARD`}
                        buttonLoadCheck={
                          props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_FORWARD`]
                        }
                        // config
                        name="FORWARD"
                        size="tiny"
                        color="blue"
                        style={{ marginLeft: "15px" }}
                        title="PRINT"
                      />
                    </div>
                  </div>
                )}
              </>
            }
            showToolBox={!!selectedRow}
            SubDrugOrderToolbox={
              !!selectedRow && (
                <SubDrugOrderToolbox
                  onClose={() => setSelectedRow(null)}
                  name={selectedRow?.name || ""}
                  label={selectedRow?.label || ""}
                  orderStatus={selectedRow?.order_status}
                  // config
                  disabledMedError={selectedRow?.med_error_status === "COMPLETED"}
                  showButtonLotNo={!!selectedRow?.order_status}
                  // Callback
                  onClickMedError={handleClickMedError}
                  onClickReprint={() => setIsReprint(true)}
                  onClickDrugRequest={handleClickDrugRequest}
                  onClickLotNo={handleClickLotNo}
                  languageUX={props.languageUX}
                />
              )
            }
            languageUX={props.languageUX}
          />
        </div>
      )}
      {isReprint && (
        <ModConfirmReprint
          error={props.errorMessage?.[CARD_MOD_REPRINT]?.error}
          success={props.successMessage?.[CARD_MOD_REPRINT]}
          loading={props.loadingStatus?.[CARD_MOD_REPRINT]}
          name={selectedRow?.name}
          // callback
          onApprove={handleReprint}
          onDeny={() => setIsReprint(false)}
          onClose={() => {
            setIsReprint(false);
            props.setProp(`successMessage.${CARD_MOD_REPRINT}`, null);
          }}
        />
      )}
      {props.drugOrder && props.drugOrder?.type === "CONTINUE_PLAN" && (
        <>
          {props.closeIcon && (
            <div
              style={{
                height: "20px",
                position: "relative",
                marginBottom: "-15px",
              }}
            >
              <Icon
                name="close"
                size="large"
                color="grey"
                style={CloseIconStyle}
                onClick={handleClearSelectDrug}
              />
            </div>
          )}
          <CardDrugContinuePlan
            // sequence
            runSequence={props.runSequence}
            DrugSelectSequence={props.DrugSelectSequence}
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            masterData={props.masterData}
            masterOptions={props.masterOptions}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // permission
            drugPermission={props.drugPermission}
            // options
            duplicateReasonsOptions={props.masterOptions.duplicateReasons}
            // selected data
            selectedPatient={props.selectedPatient}
            selectedEncounter={props.selectedEncounter}
            selectedDrug={props.selectedDrug}
            // drug list
            drugContinueFilter={props.drugContinueFilter}
            drugContinuePlanItems={props.drugContinuePlanItems}
            // search drug
            drugSearchLoading={props.drugSearchLoading}
            drugSearchText={props.drugSearchText}
            drugSearchResult={props.drugSearchResult}
            // planning
            modPlanningConfig={props.modPlanningConfig}
            planningData={props.planningData}
            // SpecialDirection
            modSpecialDirectionConfig={props.modSpecialDirectionConfig}
            // SolventSelectionConfig
            modSolventSelectionConfig={props.modSolventSelectionConfig}
            // mod note
            modNoteData={props.modNoteData}
            // modal doctor certificate check
            modDoctorCertificate={props.modDoctorCertificate}
            modDrugLabInteraction={props.modDrugLabInteraction}
            modDrugDisease={props.modDrugDisease}
            modDrugInteraction={props.modDrugInteraction}
            currentDoctor={props.currentDoctor}
            // Medreconcile Check
            django={props.django}
            isDoctor={props.isDoctor}
            medReconcileCheck={props.medReconcileCheck}
            divisionType={props.divisionType}
            medReconcileIndex={props.medReconcileIndex}
            languageUX={props.languageUX}
          />
        </>
      )}

      <Modal open={isOpen} closeOnDimmerClick={true} closeIcon={true} onClose={handleCloseMod}>
        <CardMedicationErrorWorking
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          title="Medication error note"
          medErrId={selectedRow?.id}
          MedErrorListSequence={props.MedErrorListSequence}
          drugName={selectedRow?.name || ""}
          drugCode={selectedRow?.code || ""}
          // options
          masterOptions={props.masterOptions}
          // CommonInterface
          selectedDivision={props.selectedDivision}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // config
          defaultStakeholder={{
            id: props.drugOrder?.requested?.user || null,
            name: props.drugOrder?.requested?.name || "",
          }}
          isOrderItem={true}
          // callback
          onEdited={handleEdited}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal open={openDrugRequest} size="large">
        <CardDrugTransferRequest
          // onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          DrugTransferRequestSequence={props.drugTransferRequestSequence}
          // controller
          controller={props.drugOrderHistoryController}
          // options
          masterOptions={props.masterOptions}
          // data
          drugRequestList={props.drugRequestList}
          // selectedDevice={props.selectedDevice}
          selectedDivision={props.selectedDivision}
          drugTransferRequestlist={props.drugTransferRequestlist}
          drugTransferLog={props.drugTransferLog}
          drugTransferRequestDetail={props.drugTransferRequestDetail}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // config
          showStatus={true}
          closeIcon={true}
          notAllowedAdd={true}
          // callback
          onClose={handleCloseDrugTransferRequest}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModDrugOrderReject
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        open={!!props.modNoteReject?.open}
        items={selectedDrugList}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        // callback
        onClose={() => props.setProp(`modNoteReject`, { note: "", open: false })}
        languageUX={props.languageUX}
      />

      {/* <Modal open={openErrorReject} onClose={handleCloseError} size="mini">
        <ModErrorRejectUX onConfirm={handleCloseError} />
      </Modal> */}
      <ModInfo
        open={openErrorReject}
        titleName={intl.formatMessage({ id: "ข้อความแจ้งเตือน" })}
        titleColor="red"
        size="mini"
        buttonColor="red"
        centered={true}
        closeOnDimmerClick={true}
        onApprove={handleCloseError}
        onClose={handleCloseError}
      >
        <div
          style={{
            margin: "1.25rem 0rem 0.5rem",
            lineHeight: 2,
            fontWeight: "bold",
          }}
        >
          กรุณาเลือกยาที่ต้องการ Reject
        </div>
      </ModInfo>

      <Modal
        open={props.modConfirmAction?.open}
        size="mini"
        closeOnDimmerClick={false}
        onClose={() => {
          props.setProp("modConfirmAction.open", false);
        }}
      >
        <ModDrugOrderActionNoteUX
          options={[
            { key: 1, value: 1, text: "พบความผิดพลาดในการสั่งยา" },
            { key: 2, value: 2, text: "ดำเนินขั้นตอนล่าสุดโดยไม่ได้จงใจ" },
            { key: 3, value: 3, text: "ขอแก้ไขคำสั่งการใช้ยา" },
          ]}
          note={props.modConfirmAction.value}
          actionName={
            props.drugOrder?.status === "REFILL_PLANNED" ? "CANCEL" : props.modConfirmAction?.action
          }
          onChange={handleChangeActionNote}
          onConfirm={handleConfirmActionNote}
          onCancel={() => {
            props.setProp("modConfirmAction.open", false);
          }}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal open={openModalLog} size="large" onClose={handleClose}>
        <DrugActionLog data={drugOrderLog} />
      </Modal>

      <Modal open={openModReprint} size="tiny" onClose={handleCloseModReprint}>
        <ModReprintDrugOrderWorkingUX
          handleChangeChecked={handleChangeChecked}
          handleChangeValue={handleChangeValue}
          printPharma={props?.DrugOrderActionSequence?.printPharma}
          printPatient={props?.DrugOrderActionSequence?.printPatient}
          printLabel={props?.DrugOrderActionSequence?.printLabel}
          printMedReport={props?.DrugOrderActionSequence?.printMedReport}
          isHomeMed={["HOME_OPD", "HOME_IPD"].includes(props.drugOrder?.type)}
          noteReprint={props?.DrugOrderActionSequence?.noteReprint}
          loading={
            <Dimmer active={props.loadingStatus?.[CARD_REPRINT_DRUG_ORDER_WORKING]} inverted>
              <Loader />
            </Dimmer>
          }
          language={
            <Menu inverted>
              <Menu.Item
                name="thai"
                color={tab === "thai" ? "purple" : "black"}
                active={tab === "thai"}
                onClick={() => {
                  setTab("thai");
                  props.setProp("DrugOrderActionSequence.language", "TH");
                }}
              >
                Thai
              </Menu.Item>

              <Menu.Item
                name="eng"
                color={tab === "eng" ? "purple" : "black"}
                active={tab === "eng"}
                onClick={() => {
                  setTab("eng");
                  props.setProp("DrugOrderActionSequence.language", "EN");
                }}
              >
                Eng
              </Menu.Item>
            </Menu>
          }
          onCancel={handleCloseModReprint}
          onConfirm={handleConfirmModReprint}
          languageUX={props.languageUX}
        />
      </Modal>
      <Modal
        open={openModReturnDrug}
        size="large"
        className="centered"
        onClose={handleCloseModReturnDrug}
      >
        <div>
          <CardReturnDrug
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            // data
            card={CARD_DRUG_ORDER_WORKING}
            drugPermission={props.drugPermission}
            drugOrderId={props.drugOrder?.pk}
            OrderReturnList={props.OrderReturnList}
            DrugReturnList={props.DrugReturnList}
            OrderHistory={props.OrderHistory}
            OrderReturnLog={props.OrderReturnLog}
            closeIcon={true}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            // CommonInterface
            onClose={handleCloseModReturnDrug}
            languageUX={props.languageUX}
          />
        </div>
      </Modal>
      <Modal
        open={openModHomeMedUnPaid}
        size="mini"
        onClose={() => {
          setOpenModHomeMedUnPaid(false);
        }}
      >
        <Modal.Header as="h5" style={{ backgroundColor: "red", color: "white", fontSize: "16px" }}>
          ใบยายังไม่ได้รับชำระเงิน
        </Modal.Header>
        <Modal.Content style={{ textAlign: "center" }}>
          <Button
            inverted
            color="red"
            content={intl.formatMessage({ id: "ตกลง" })}
            onClick={() => {
              setOpenModHomeMedUnPaid(false);
            }}
          />
        </Modal.Content>
      </Modal>

      {/* Refill */}
      <Modal open={openModRefillDrug} size="fullscreen" onClose={() => setOpenModRefillDrug(false)}>
        <CardDrugOrderRefillUX
          split={props.DrugRefillSequence}
          handleChangeSplit={handleChangeSplit}
          onChangeSplitDate={(date: any) => {
            props.setProp(`DrugRefillSequence.splitDate`, date);
          }}
          nameTypeOrder={`${props.drugOrder?.type} [${props.drugOrder?.code}]`}
          drugStatus={props.drugOrder?.status}
          openCancelRefill={!openCancelRefill && props.drugOrder?.status === "REFILL_PLANNED"}
          orderStatus={
            props.drugOrder?.status && (
              <Label
                size="small"
                style={{
                  backgroundColor: (ORDER_STATUS_COLORS as any)[props.drugOrder?.status] || "",
                  color: "white",
                }}
              >
                {props.drugOrder?.status}
                {/* {"TRANSPORTED"} */}
              </Label>
            )
          }
          calculatorSplit={handleCalculatorSplit}
          addOrderList={handleAddOrderList}
          drugOrderRefill={drugOrderRefill}
          logRefillPlanning={() => {
            props.onEvent({
              message: "HanleOnLogDrugOrder",
              params: { action: "LOG" },
            });
            setOpenModRefillLog(true);
          }}
          printRefillPlanning={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.onEvent({
                  message: "RunSequence",
                  params: {
                    sequence: "DrugOrderAction",
                    action: "PRINT_PLAN",
                    last_edited: moment().format(),
                    card: `${CARD_DRUG_ORDER_REFILL}_PRINT`,
                    buttonLoadKey: `${CARD_DRUG_ORDER_REFILL}_PRINT`,
                  },
                });
              }}
              // data
              paramKey={`${CARD_DRUG_ORDER_REFILL}_PRINT`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_REFILL}_PRINT`]}
              // config
              name="PRINT"
              size="tiny"
              // color="green"
              style={{ marginLeft: "15px" }}
              title="PRINT"
            />
          }
          saveRefillPlanning={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                setButtonLoadKey(
                  props.drugOrder?.status === "REFILL_PLANNED"
                    ? `${CARD_DRUG_ORDER_REFILL}_EDIT`
                    : `${CARD_DRUG_ORDER_REFILL}_SAVE`
                );
                props.onEvent({
                  message: "RunSequence",
                  params: {
                    sequence: "DrugOrderAction",
                    action: "saveRefill",
                    card: CARD_DRUG_ORDER_REFILL,
                    buttonLoadKey:
                      props.drugOrder?.status === "REFILL_PLANNED"
                        ? `${CARD_DRUG_ORDER_REFILL}_EDIT`
                        : `${CARD_DRUG_ORDER_REFILL}_SAVE`,
                  },
                });
              }}
              // data
              paramKey={
                props.drugOrder?.status === "REFILL_PLANNED"
                  ? `${CARD_DRUG_ORDER_REFILL}_EDIT`
                  : `${CARD_DRUG_ORDER_REFILL}_SAVE`
              }
              buttonLoadCheck={
                props.drugOrder?.status === "REFILL_PLANNED"
                  ? props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_REFILL}_EDIT`]
                  : props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_REFILL}_SAVE`]
              }
              // config
              name="SAVE"
              size="tiny"
              color={props.drugOrder?.status === "REFILL_PLANNED" ? "yellow" : "green"}
              style={{ marginLeft: "15px" }}
              title={props.drugOrder?.status === "REFILL_PLANNED" ? "EDIT" : "SAVE"}
            />
          }
          cancelRefillPlanning={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handlebackwardAction}
              // data
              paramKey={`${CARD_DRUG_ORDER_REFILL}_CANCEL`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_REFILL}_CANCEL`]}
              // config
              color="red"
              name="CANCEL"
              size="tiny"
              style={{ marginLeft: "15px" }}
              title="CANCEL"
            />
          }
          inputRefill={
            <div style={{ display: "flex" }}>
              {(props.DrugRefillSequence?.appointments || []).map((item: any, index: number) => {
                return (
                  <div style={{ marginRight: "20px" }}>
                    <div style={{}}>
                      <DateTextBox
                        value={item.start_date}
                        onChange={(date: any) => {
                          props.setProp(`DrugRefillSequence.appointments.${index}`, {
                            ...props.DrugRefillSequence.appointments[index],
                            end_date: date,
                            start_date: date,
                          });
                        }}
                        disabled={
                          props.drugOrder?.status === "DIVIDED" || item?.refill === null
                            ? false
                            : true
                        }
                      />
                    </div>
                    {(props.DrugRefillSequence?.order_items || []).map(
                      (_data: any, number: number) => {
                        return (
                          <div
                            style={{
                              marginTop: "10px",
                              width: "210px",
                              textAlign: "right",
                            }}
                          >
                            <Input
                              type="number"
                              fluid={true}
                              value={item?.plannings[number].quantity}
                              onChange={(_e: any, { value }: any) =>
                                handleCalculatorRefill(index, number, value)
                              }
                              disabled={
                                props.drugOrder?.status === "DIVIDED" || item?.refill === null
                                  ? false
                                  : true
                              }
                            />
                          </div>
                        );
                      }
                    )}
                    <div style={{ margin: "10px 20px 10px 0px", width: "210px" }}>
                      {props.drugOrder?.status === "DIVIDED" ? (
                        <Button
                          onClick={() => handleRemoveRefill(index)}
                          color="red"
                          icon="trash alternate"
                          fluid={true}
                        >
                          Remove
                        </Button>
                      ) : (
                        <ButtonLoadCheck
                          // function
                          setProp={props.setProp}
                          onClick={() => {
                            if (item?.refill === null) {
                              props.setProp(`DrugRefillSequence.selectedRefill`, index);
                              setOpenModRefill(true);
                            }
                          }} // data
                          paramKey={`${CARD_DRUG_ORDER_REFILL}_REFILLPRINT`}
                          buttonLoadCheck={
                            props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_REFILL}_REFILLPRINT`]
                          }
                          // config
                          fluid={true}
                          color={item?.refill === null ? "blue" : "yellow"}
                          name="REFILLPRINT"
                          size="tiny"
                          title={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Image
                                width={20}
                                height={20}
                                src={item?.refill === null ? IMAGES.refill : IMAGES.view}
                              />
                              <div style={{ marginLeft: "10px" }}>
                                {item?.refill === null ? "REFILL" : "VIEW"}
                              </div>
                            </div>
                          }
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          }
          languageUX={props.languageUX}
        />
      </Modal>

      <ModConfirm
        openModal={openModRefill}
        titleName={intl.formatMessage({ id: "ข้อความแจ้งเตือน" })}
        size="mini"
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ไม่ใช่" })}
        approveButtonColor="green"
        approveButtonText={intl.formatMessage({ id: "ใช่" })}
        content={
          props.drugOrder?.status === "REFILL_PLANNED"
            ? "ยืนยันการสร้างรายการจากการเติมยา"
            : "ยืนยันการแบ่งรายการยาเพื่อ Refill"
        }
        onApprove={() => {
          handleRefillAction();
        }}
        onDeny={() => {
          setOpenModRefill(false);
        }}
      />

      <Modal open={openModRefillLog} size="large" onClose={() => setOpenModRefillLog(false)}>
        <div style={{ padding: "10px" }}>
          {/* @ts-ignore */}
          <Header>{intl.formatMessage({ id: "ประวัติการทำงาน" })}</Header>
          <Table
            data={drugOrderLog}
            headers="User,Action,Date-Time"
            keys="name,action,datetime"
            minRows="10"
            showPagination={false}
            style={{ height: "300px" }}
          ></Table>
        </div>
      </Modal>

      <Modal
        open={props.errorMessage?.[`${CARD_DRUG_ORDER_REFILL}_saveRefill`] != null}
        size="mini"
      >
        <Modal.Header as="h5" style={{ backgroundColor: "red", color: "white", fontSize: "16px" }}>
          ข้อความแจ้งเตือน
        </Modal.Header>
        <Modal.Content>
          <div>{intl.formatMessage({ id: "ไม่อนุญาติให้บันทึกแผน Refill" })}</div>
          <div>{intl.formatMessage({ id: "เนื่องจากจำนวนเติม ไม่เท่ากับ จำนวนสั่ง" })}</div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              color="green"
              content={intl.formatMessage({ id: "ตกลง" })}
              onClick={() => {
                props.setProp(`errorMessage.${`${CARD_DRUG_ORDER_REFILL}_saveRefill`}`, null);
              }}
            />
          </div>
        </Modal.Content>
      </Modal>

      <Modal
        open={openModHomeMedUnPaid}
        size="mini"
        onClose={() => {
          setOpenModHomeMedUnPaid(false);
        }}
      >
        <Modal.Header as="h5" style={{ backgroundColor: "red", color: "white", fontSize: "16px" }}>
          ใบยายังไม่ได้รับชำระเงิน
        </Modal.Header>
        <Modal.Content style={{ textAlign: "center" }}>
          <Button
            inverted
            color="red"
            content={intl.formatMessage({ id: "ตกลง" })}
            onClick={() => {
              setOpenModHomeMedUnPaid(false);
            }}
          />
        </Modal.Content>
      </Modal>

      {/* drug-lab interaction */}
      <Modal open={props.modDrugLabInteraction?.open} closeOnDimmerClick={false}>
        <ModDrugLabInteraction
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugLabInteraction={props.modDrugLabInteraction}
          currentDoctor={props.currentDoctor}
          languageUX={props.languageUX}
          masterOptions={props.masterOptions}
        />
      </Modal>

      {/* drug-disease */}
      <Modal open={props.modDrugDisease?.open} closeOnDimmerClick={false}>
        <ModDrugDisease
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugDisease={props.modDrugDisease}
          currentDoctor={props.currentDoctor}
          languageUX={props.languageUX}
          masterOptions={props.masterOptions}
        />
      </Modal>

      {/* drug-interaction */}
      <Modal open={props.modDrugInteraction?.open} closeOnDimmerClick={false}>
        <ModDrugInteraction
          onEvent={props.onEvent}
          setProp={props.setProp}
          // main function
          runSequence={props.runSequence}
          currentDoctor={props.currentDoctor}
          languageUX={props.languageUX}
          // data
          modDrugInteraction={props.modDrugInteraction}
          masterOptions={props.masterOptions}
        />
      </Modal>

      {/* drug-recommendation */}
      <ModDrugRecommendation
        onEvent={props.onEvent}
        modDrugRecommendation={props.modDrugRecommendation}
        languageUX={props.languageUX}
      />

      <ModLotNoExp
        onEvent={props.onEvent}
        setProp={props.setProp}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
        // data
        open={openModLotNo}
        data={selectedRow}
        lotNoExpList={props.lotNoExpList}
        orderPerformDiv={props.drugOrder?.order_perform_div || ""}
        // config
        readOnly={selectedRow?.order_status !== "REQUESTED"}
        // callback
        onClose={handleCloseModLotNo}
        languageUX={props.languageUX}
      />

      <ModDescriptionUD
        open={!!modDrugDesc.open}
        description={modDrugDesc.desc}
        // callback
        onClose={handleCloseModDesc}
        languageUX={props.languageUX}
      />

      <ModInfo
        open={!!props.errorMessage?.[`${CARD_DRUG_ORDER_WORKING}_LAST_EDITED`]}
        titleName={intl.formatMessage({ id: "ข้อความแจ้งเตือน" })}
        titleColor="yellow"
        size="mini"
        buttonColor="green"
        centered={true}
        closeOnDimmerClick={true}
        onApprove={handleCloseModLastEdited}
        onClose={handleCloseModLastEdited}
      >
        <div
          style={{
            margin: "1.25rem 0rem 0.5rem",
            lineHeight: 2,
            fontWeight: "bold",
          }}
        >
          <div>{intl.formatMessage({ id: "ใบสั่งยานี้มีการแก้ไข" })}</div>
          <div>{intl.formatMessage({ id: "กรุณาตรวจสอบรายละเอียดในใบสั่งยาอีกครั้ง" })}</div>
        </div>
      </ModInfo>

      <ModInfo
        open={openModAlreadyOffHold}
        titleName={intl.formatMessage({ id: "ข้อความแจ้งเตือน" })}
        titleColor="red"
        buttonColor="green"
        centered={true}
        style={{ minWidth: "max-content" }}
        closeOnDimmerClick={true}
        onApprove={handleApproveModAlreadyOffHold}
        onClose={handleCloseModAlreadyOffHold}
      >
        <div style={{ margin: "1.25rem 0rem 0.5rem", textAlign: "left" }}>
          {[
            {
              title: "มีรายการยาที่มีคำสั่ง OFF",
              items: props.drugOrder?.already_off_items as any[],
            },
            {
              title: "มีรายการยาที่มีคำสั่ง HOLD",
              items: props.drugOrder?.already_hold_items as any[],
            },
          ].map(
            (item) =>
              item.items?.length > 0 && (
                <div key={item.title}>
                  <strong>
                    <span>{item.title}</span>
                  </strong>
                  <ul style={{ margin: "0.5rem 0 1rem -1rem" }}>
                    {item.items.map((id: number) => {
                      const drug = drugOrderItems.find((item) => item.id === id);

                      return <li key={id}>{drug?.drugName || ""}</li>;
                    })}
                  </ul>
                </div>
              )
          )}

          <strong>
            {intl.formatMessage({
              id: "ระบบจะนำรายการยาออกจากใบสั่งยานี้ให้อัตโนมัติ เมื่อเข้าสู่ขั้นตอนถัดไป",
            })}
          </strong>
        </div>
      </ModInfo>

      <ModCoverageDrugDiscount
        setProp={props.setProp}
        // data
        open={!!props.DrugOrderActionSequence?.modDiscount?.action}
        encounterId={props.selectedEncounter?.id}
        orderId={props.drugOrder?.pk}
        items={props.DrugOrderActionSequence?.modDiscount?.items || []}
        action={props.DrugOrderActionSequence?.modDiscount?.action}
        // callback
        onEditDiscount={handleEditDiscount}
        onClose={handleCloseModDiscount}
      />

      <ModLockExpense
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
      />
    </div>
  );
};

/* ------------------------------------------------------ */
/*                    DropdownDelivery;                   */
/* ------------------------------------------------------ */
const DropdownDeliver = (props: any) => {
  const intl = useIntl();
  return (
    <div style={{ display: "flex", margin: "0 0 auto", alignItems: "center" }}>
      <label style={{ marginRight: "0.5rem" }}>
        <strong>{intl.formatMessage({ id: "การรับยา" })}</strong>
      </label>
      <Dropdown
        id="cb-deliver"
        value={props.value}
        options={props.options}
        selection={true}
        disabled={props.disabled}
        fluid={true}
        style={{ width: "165px" }}
        onChange={(_e: any, d: any) => {
          props.onChange?.(d.value || null);
        }}
      />
    </div>
  );
};

export default CardDrugOrderWorking;
