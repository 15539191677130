import React, { CSSProperties, Fragment, useCallback, useMemo, useRef, useState } from "react";

import { Popup } from "semantic-ui-react";

import htmlParse from "html-react-parser";

import LoadingIcon from "../HCU/LoadingIcon";

import { extractMedicationInfo } from "react-lib/apps/HISV3/PHV/PHVInterface";

// Types
type SubDrugNameFormatProps = {
  onEvent: (e: any) => Promise<Record<string, any> | null>;
  name?: string;
  product?: string;
  status?: string;
  summary?: string;
};

type Styles = Record<"dimmer" | "icon" | "popup", CSSProperties>;

// Constants
const styles: Styles = {
  dimmer: { margin: "0 0 0 5px", width: "fit-content" },
  icon: { marginLeft: "0.2rem" },
  popup: { border: "1px solid #81b7db", minWidth: "12rem" },
};

const TITLE_REGEX = /<[^>]+>.*?:\d+<\/[^>]+>/;  // /.*?:\d+/;

const POPUP_OFFSET: [number, number] = [-7.5, 0];

const SubDrugNameFormat = (props: SubDrugNameFormatProps) => {
  const [open, setOpen] = useState<string>("");
  const [loading, setLoading] = useState<string>("");

  const [drugDetail, setDrugDetail] = useState<Record<string, any> | null>(null);

  const abortController = useRef<AbortController>(null);

  // Callback
  const handleOpen = useCallback(
    (name: string) => async () => {
      if (loading) {
        return;
      }

      setLoading(name);

      const result = await props.onEvent({
        message: "GetDrugDetailByNameOrId",
        params: { id: props.product, abortController, name },
      });

      if (!result) {
        handleClose();

        return;
      }

      setDrugDetail(result);

      setOpen(name);
      setLoading("");
    },
    [loading, open]
  );

  const handleClose = useCallback(() => {
    setOpen("");
    setDrugDetail(null);
    setLoading("");

    abortController.current?.abort();
  }, []);

  const icon = useCallback(
    (name: string) => (
      <span>
        <LoadingIcon
          color="grey"
          dimmerStyle={styles.dimmer}
          inverted={false}
          loading={name === loading}
          name="info circle"
          style={styles.icon}
        />
      </span>
    ),
    [loading]
  );

  // Memo
  const medicationDetails = useMemo(
    () =>
      props.name
        ? [{ full_name: props.name, htmlLabel: "" }]
        : extractMedicationInfo(props.summary || ""),
    [props.name, props.summary]
  );

  const title = useMemo(
    () => (medicationDetails.length > 0 ? TITLE_REGEX.exec(props.summary || "")?.[0] || "" : ""),
    [medicationDetails, props.summary]
  );

  const content = useMemo(() => {
    const ingredients: Record<string, any>[] = drugDetail?.drug_ingredients || [];

    return drugDetail ? (
      <>
        {ingredients.length > 0 ? (
          <>
            <b>Ingredient</b>
            <br />
            <ul style={{ margin: "0.25rem 0 0 -1rem" }}>
              {ingredients.map((ingredient) => (
                <li key={`${ingredient.ingredient_name}-${ingredient.strength}`}>
                  {ingredient.ingredient_name} {ingredient.strength}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>No ingredients found</>
        )}
      </>
    ) : null;
  }, [drugDetail]);

  return (
    <div>
      {!!title && (
        <>
          {htmlParse(title)}
          <br />
        </>
      )}
      {medicationDetails.length === 0 && htmlParse(props.summary || "")}
      {medicationDetails.map((item) => (
        <Fragment key={item.full_name}>
          {props.status === "CANCELED" ? (
            <s>
              <b>{item.full_name}</b>
            </s>
          ) : (
            <b>{item.full_name}</b>
          )}
          <Popup
            content={content}
            offset={POPUP_OFFSET}
            open={open === item.full_name}
            position="bottom left"
            style={styles.popup}
            trigger={icon(item.full_name)}
            onClose={handleClose}
            onOpen={handleOpen(item.full_name)}
          />
          <br />
          {htmlParse(props.status === "CANCELED" ? `<s>${item.htmlLabel}</s>` : item.htmlLabel)}
        </Fragment>
      ))}
    </div>
  );
};

SubDrugNameFormat.displayName = "SubDrugNameFormat";

export default React.memo(SubDrugNameFormat);
