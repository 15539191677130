import React, { useState, useMemo } from "react";

// Utils
import { Button, Form, FormGroup, Dropdown, Radio } from "semantic-ui-react";
import moment from "moment";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

// Const
const CURRENT_YEAR = Number(moment().format("YYYY")) + 543;

const CardSapPaymentStatReportFilter = (props: any) => {
  const intl = useIntl();
  console.log("Props Card Sap Payment Stat: ", props);

  // useMemo
  const stationOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "ทั้งหมด" },
      ...(props.masterOptions?.bilStation || []),
    ];
  }, [props.masterOptions?.bilStation]);

  const fiscalYearOptions = useMemo(() => {
    const years = Array(51)
      .fill("")
      .map((_, index) => CURRENT_YEAR - 30 + index);

    return years.map((year) => ({ key: year, value: year.toString(), text: year.toString() }));
  }, []);

  const monthYearOptions = useMemo(() => {
    const months = Array.from({ length: 12 }, (_, i) => {
      return moment(`1997-${i + 1}-1`)
        .locale("th")
        .format("MMMM");
    });

    // เดือน + ปีที่เลือก
    return months.map((month, index) => ({
      key: month,
      value: `${index + 1} ${
        props.SapAccountingReportSequence?.sapPaymentStatReport?.selectedFiscalYear
      }`,
      text: `${month} ${props.SapAccountingReportSequence?.sapPaymentStatReport?.selectedFiscalYear}`,
    }));

    // เดือนเท่านั้น + ปีปัจจุบัน
    // return months.map((month, index) => ({
    //   key: month,
    //   value: `${index + 1} ${CURRENT_YEAR}`,
    //   text: `${month} ${CURRENT_YEAR}`,
    // }));
  }, [props.SapAccountingReportSequence?.sapPaymentStatReport?.selectedFiscalYear]);

  const setStar = (condition: boolean) => {
    if (!condition) {
      return null;
    }
    return <span style={{ color: "red" }}>*</span>;
  };

  const handleDisable = (condition: boolean) => {
    if (!condition) {
      return true; // true คือ disable
    }
  };

  const handleSelectRadio = (optionSelected: any) => {
    switch (optionSelected) {
      case "daily":
      case "monthly":
      case "yearly":
        return (
          props.SapAccountingReportSequence?.sapPaymentStatReport?.reportType ===
          `${optionSelected}`
        );
      default:
        console.warn("Option Doesn't Exist!!");
        break;
    }
  };

  const handleChangeRadio = (e: any, { value }: { value: string }) => {
    props.setProp(`SapAccountingReportSequence.sapPaymentStatReport.reportType`, value);
  };

  const handleExportButton = () => {
    props.runSequence({
      sequence: "SapAccountingReport",
      action: "EXPORT_REPORT",
      menu: "รายงานสรุปสถิติการรับชำระเงิน",
    });
  };

  return (
    <Form>
      <FormGroup className="ui form" style={{ gap: "1em", padding: "0em 0em 0em 1em" }}>
        <label style={{ display: "flex", alignItems: "center" }}>
          ประเภทรายงาน<span style={{ color: "red" }}>*</span>
        </label>
        <Radio
          label={intl.formatMessage({ id: "ประจำวัน" })}
          checked={handleSelectRadio("daily")}
          onChange={handleChangeRadio}
          value="daily"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <Radio
          label={intl.formatMessage({ id: "จ่ายประจำเดือน" })}
          checked={handleSelectRadio("monthly")}
          onChange={handleChangeRadio}
          value="monthly"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <Radio
          label={intl.formatMessage({ id: "ประจำปี" })}
          checked={handleSelectRadio("yearly")}
          onChange={handleChangeRadio}
          value="yearly"
          style={{ display: "flex", alignItems: "center" }}
        ></Radio>
        <label style={{ display: "flex", alignItems: "center", padding: "0em 0em 0em 4em" }}>
          จุดเก็บเงิน
        </label>
        <Dropdown
          selection={true}
          placeholder={intl.formatMessage({ id: "เลือกจุดเก็บเงิน" })}
          options={stationOptions}
          style={{ minWidth: "15em" }}
          onChange={(e: any, data: any) => {
            props.setProp(
              `SapAccountingReportSequence.sapPaymentStatReport.selectedStation`,
              data.value
            );
          }}
          value={props.SapAccountingReportSequence?.sapPaymentStatReport?.selectedStation || ""}
        ></Dropdown>
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1em", padding: "0em 0em 0em 1em" }}>
        <label style={{ display: "flex", alignItems: "center", padding: "0em 0.3em 0em 0em" }}>
          ปีงบประมาณ
          {setStar(
            props.SapAccountingReportSequence?.sapPaymentStatReport?.reportType === "yearly" ||
              props.SapAccountingReportSequence?.sapPaymentStatReport?.reportType === "monthly"
          )}
        </label>
        <Dropdown
          disabled={handleDisable(
            props.SapAccountingReportSequence?.sapPaymentStatReport?.reportType === "yearly" ||
              props.SapAccountingReportSequence?.sapPaymentStatReport?.reportType === "monthly"
          )}
          selection={true}
          placeholder={CURRENT_YEAR.toString()}
          options={fiscalYearOptions}
          style={{ minWidth: "11em" }}
          onChange={(e: any, data: any) => {
            props.setProp(
              `SapAccountingReportSequence.sapPaymentStatReport.selectedFiscalYear`,
              data.value
            );
          }}
          value={props.SapAccountingReportSequence?.sapPaymentStatReport?.selectedFiscalYear}
        ></Dropdown>
        <label style={{ display: "flex", alignItems: "center", padding: "0em 1.21em 0em 13.3em" }}>
          เดือน
          {setStar(
            props.SapAccountingReportSequence?.sapPaymentStatReport?.reportType === "monthly"
          )}
        </label>
        <Dropdown
          disabled={handleDisable(
            props.SapAccountingReportSequence?.sapPaymentStatReport?.reportType === "monthly"
          )}
          selection={true}
          placeholder={`มกราคม ${CURRENT_YEAR}`}
          options={monthYearOptions}
          style={{ minWidth: "15em" }}
          onChange={(e: any, data: any) => {
            props.setProp(
              `SapAccountingReportSequence.sapPaymentStatReport.selectedMonth`,
              data.value
            );
          }}
          value={props.SapAccountingReportSequence?.sapPaymentStatReport?.selectedMonth || ""}
        ></Dropdown>
      </FormGroup>
      <FormGroup className="ui form" style={{ gap: "1em", padding: "0em 0em 0em 1em" }}>
        <label style={{ display: "flex", alignItems: "center" }}>
          วันที่นำส่งเงิน
          {setStar(props.SapAccountingReportSequence?.sapPaymentStatReport?.reportType === "daily")}
        </label>
        <DateTextBox
          disabled={handleDisable(
            props.SapAccountingReportSequence?.sapPaymentStatReport?.reportType === "daily"
          )}
          onChange={(date: string) => {
            props.setProp(`SapAccountingReportSequence.sapPaymentStatReport.sendMoneyDate`, date);
          }}
          value={props.SapAccountingReportSequence?.sapPaymentStatReport?.sendMoneyDate || ""}
          style={{ width: "11em" }}
        ></DateTextBox>
        <label
          style={{ display: "flex", alignItems: "center", padding: "0em 44em 0em 0em" }}
        ></label>
        <ButtonLoadCheck
          setProp={props.setProp}
          onClick={handleExportButton}
          paramKey={`SapAccountingReport_EXPORT_REPORT`}
          buttonLoadCheck={props.buttonLoadCheck?.[`SapAccountingReport_EXPORT_REPORT`]}
          color="blue"
          style={{ width: "9em" }}
          title="Export"
        />
      </FormGroup>
    </Form>
  );
};

export default CardSapPaymentStatReportFilter;
