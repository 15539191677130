import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  TextArea,
  Divider,
  Dropdown,
  Button
} from 'semantic-ui-react'

const CardRehabExaminationUX = (props: any) => {
    return(
      <div
        style={{margin: "10px 6px"}}>
        <div
          style={{fontWeight: "bolder",fontSize:"large"}}>
          Rehabilitation treatment plan
        </div>
        <Divider>
        </Divider>
        <Form>
          <div
            style={{display:"flex", gap:"20px"}}>
            
            <div
              style={{width: "50%"}}>
              
              <div
                style={{margin: "0px 0px 10px 0px", fontWeight: "bolder",fontSize:"larger"}}>
                Problem
              </div>
              <TextArea
                onChange={props.onChangeProblem}
                value={props.problem}>
              </TextArea>
            </div>
            <div
              style={{width: "50%"}}>
              
              <div
                style={{margin: "0px 0px 10px 0px",fontWeight: "bolder",fontSize:"larger"}}>
                Rehabilitation goal
              </div>
              <TextArea
                onChange={props.onChangeGoal}
                value={props.goal}>
              </TextArea>
            </div>
          </div>
          <div>
            
            <div
              style={{margin: "20px 0px 10px 0px",fontWeight: "bolder",fontSize:"larger"}}>
              Precaution
            </div>
            <Dropdown
              fluid={true}
              multiple={true}
              onChange={props.onChangePrecaution}
              options={props.precautionOptions}
              selection={true}
              value={props.precaution}>
            </Dropdown>
          </div>
        </Form>
        <div
          style={{margin: "40px 0px 10px 0px",fontWeight: "bolder",fontSize:"larger"}}>
          Treatment Order
        </div>
        <div
          style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
          
          <div
            style={{display:"flex",alignItems:"center",alignSelf:"center",width:"50%",gap:"10px", margin: "0px 20px 0px 10px"}}>
            
            <div
              style={{marginRight:"10px"}}>
              {<> Search Order <span style={{color: "red"}}>*</span></>}
            </div>
            <div
              style={{flexGrow: 1}}>
              {props.searchOrder}
            </div>
          </div>
          <div
            style={{display:"flex",alignItems:"center",alignSelf:"center",width:"50%",gap:"10px", margin:"0px 20px 0px 0px"}}>
            
            <div>
              {<> to Department <span style={{color: "red"}}>*</span></>}
            </div>
            <div
              style={{flexGrow:1}}>
              
              <Dropdown
                fluid={true}
                onChange={props.onChangeDepartment}
                options={props.departmentOptions}
                selection={true}
                value={props.department}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{margin: "0px 20px 0px 0px"}}>
            
            <Button
              color="blue"
              disabled={props.disabledAddBtn}
              onClick={props.onClickAdd}>
              Add
            </Button>
          </div>
        </div>
        <div
          style={{ margin: "20px 10px" }}>
          
          <div>
            {props.table}
          </div>
        </div>
        <div
          style={{display:"flex",justifyContent:"flex-end", margin: "20px 10px" }}>
          
          <Button
            color="green"
            disabled={props.disableSave}
            loading={props.loadingSave}
            onClick={props.onSave}>
            SAVE
          </Button>
        </div>
      </div>
    )
}


export default CardRehabExaminationUX

export const screenPropsDefault = {}

/* Date Time : Fri Jan 03 2025 15:38:17 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px 6px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Rehabilitation treatment plan"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\",fontSize:\"large\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"50%\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"50%\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Problem"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 0px 10px 0px\", fontWeight: \"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Rehabilitation goal"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 0px 10px 0px\",fontWeight: \"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "TextArea",
      "parent": 6,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeProblem"
        },
        "value": {
          "type": "code",
          "value": "props.problem"
        }
      },
      "seq": 10,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "TextArea",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeGoal"
        },
        "value": {
          "type": "code",
          "value": "props.goal"
        }
      },
      "seq": 11,
      "void": true
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", gap:\"20px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Precaution"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"20px 0px 10px 0px\",fontWeight: \"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent:\"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",alignSelf:\"center\",width:\"50%\",gap:\"10px\", margin: \"0px 20px 0px 10px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.table"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "<> Search Order <span style={{color: \"red\"}}>*</span></>"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Divider",
      "parent": 0,
      "props": {
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment Order"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"40px 0px 10px 0px\",fontWeight: \"bolder\",fontSize:\"larger\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"20px 10px\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",alignSelf:\"center\",width:\"50%\",gap:\"10px\", margin:\"0px 20px 0px 0px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "<> to Department <span style={{color: \"red\"}}>*</span></>"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDepartment"
        },
        "options": {
          "type": "code",
          "value": "props.departmentOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.department"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\", margin: \"20px 10px\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Button",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableSave"
        },
        "icon": {
          "type": "code",
          "value": ""
        },
        "loading": {
          "type": "code",
          "value": "props.loadingSave"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        },
        "size": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePrecaution"
        },
        "options": {
          "type": "code",
          "value": "props.precautionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.precaution"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchOrder"
        },
        "style": {
          "type": "code",
          "value": "{flexGrow: 1}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 20px 0px 0px\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "Add"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAddBtn"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAdd"
        },
        "size": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flexGrow:1}"
        }
      },
      "seq": 36,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "name": "CardRehabExaminationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
