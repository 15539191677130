import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const CardSettingDivisionUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>

        <div
          style={{display:"flex",marginBottom:"10px",justifyContent:"space-between", alignItems:"center"}}>
          {}
          <div
            style={{fontSize: "larger",fontWeight: "bold",margin: "0px 10px"}}>
            กำหนดแผนกที่สามารถเข้าใช้
          </div>
          <div
            style={{display:"flex",gap:"10px",alignItems:"center"}}>

            <Button
              color="blue"
              onClick={props.onOpenDivision}>
              {props.titleAddDivision}
            </Button>
          </div>
        </div>
        <div
          style={{display:props.showTable ? "":"none"}}>

          <Table
            data={props.dataDivision}
            getTdProps={props.getTdProps}
            getTrProps={props.getTrProps}
            headers={props.headers ? props.headers : "รหัส,ชื่อคลินิก,สิทธิการใช้,Active,Delete"}
            keys={props.keys ? props.keys : "code,name,extraDiv,activeDiv,delete"}
            minRows="8"
            showPagination={false}
            style={{height: "400px"}}
            widths="100,auto,400,200,200">
          </Table>
        </div>
        <div>
          {props.divider}
        </div>
        <div
          style={{fontSize: "larger",fontWeight: "bold",margin: "20px 10px",textAlign:"center"}}>
          {props.titleTableAdminScreen}
        </div>
        <div
          style={{margin:"20px 0px",display:"flex",justifyContent:"center"}}>
          {props.tableAdminScreen}
        </div>
        <div
          style={{display:"flex",justifyContent:"flex-end"}}>

          <div
            style={{marginRight:"10px"}}>
            {props.buttonSave}
          </div>
        </div>
      </div>
    )
}


export default CardSettingDivisionUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Fri Dec 06 2024 14:10:27 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginBottom:\"10px\",justifyContent:\"space-between\", alignItems:\"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.dataDivision"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "code",
          "value": "props.headers ? props.headers : \"รหัส,ชื่อคลินิก,สิทธิการใช้,Active,Delete\""
        },
        "keys": {
          "type": "code",
          "value": "props.keys ? props.keys : \"code,name,extraDiv,activeDiv,delete\""
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        },
        "widths": {
          "type": "value",
          "value": "100,auto,400,200,200"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.showTable ? \"\":\"none\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.titleAddDivision"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenDivision"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "กำหนดแผนกที่สามารถเข้าใช้"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"larger\",fontWeight: \"bold\",margin: \"0px 10px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tableAdminScreen"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"20px 0px\",display:\"flex\",justifyContent:\"center\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"10px\",alignItems:\"center\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.titleTableAdminScreen"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"larger\",fontWeight: \"bold\",margin: \"20px 10px\",textAlign:\"center\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divider"
        }
      },
      "seq": 21,
      "void": false
      },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 30,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSettingDivisionUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 80
}

*********************************************************************************** */
