import WasmController from "../../../../frameworks/WasmController";
// APIs
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import NurseNoteTemplate from "issara-sdk/apis/NurseNoteTemplate_apps_ORM";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import PreOperationDefaultDataView from "issara-sdk/apis/PreOperationDefaultDataView_apps_ORM";
import UserTokenizeView from "issara-sdk/apis/UserTokenizeView_users";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
// PDF
import FormPreOperation from "react-lib/apps/HISV3/ORM//FormPreOperation";
// Common & Utils
import getPdfMake from "react-lib/appcon/common/pdfMake";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  selectedPatient?: any;
  django?: any;
  masterOptions?: any;

  // ORQueueI
  selectedOrOrder?: any;

  // sequence
  PreOperationSequence?: {
    sequenceIndex?: string | null;

    preOPVisitData?: {
      data: {
        date_of_visit?: string;
        location?: number | null;
        has_no_visit_reason?: boolean;
        no_visit_reason?: number | null;
        history_illness?: string;
        status_of_consciousness?: number | null;
        psychological_status?: number | null;
        respiratory_status?: number | null;
        limitation?: number | null;
        remark?: string;
      };
    };

    orRegistrationAreaData?: {
      data: {
        transfer_by?: string;
        type_surgeon?: string;
        is_verbally?: boolean;
        is_name_band?: boolean;
        is_chart?: boolean;
        mark_site?: string;
        mark_site_confirm?: string;
        is_procedure_confirm?: boolean;
        is_consent_form?: boolean;
        is_npo?: boolean;
        is_valuable?: boolean;
        valuable?: string;
        is_impant?: boolean;
        implant?: boolean;
        lab_remark?: string;
        blood?: string;
        imaging?: string;
        remark?: string;
      };
    };

    holdingRoomData?: {
      data: {
        verbally?: boolean;
        name_band?: boolean;
        chart?: boolean;
        history_illness?: string;
        procedure_confirm?: boolean;
        procedure_confirm_detail?: string;
        mark_site_confirm?: string;
        mark_site_confirm_detail?: string;
        confirm_surgical?: string;
        anti_coagulation_drug?: boolean;
        dm_drug?: boolean;
        anti_hypertensive_drug?: boolean;
        valuable?: boolean;
        valuable_detail?: any;
        implant?: boolean;
        implant_detail?: string;
        limitation?: string;
        status_of_conscious?: string;
        psychological_status?: string;
        respiratory_status?: string;
        lab_remark?: string;
        blood?: string;
        remark?: string;
        oxygen?: string;
        plan_and_implementation?: string;
        evaluation?: string;
        evaluation_check?: boolean;
        evaluation_detail?: string;
        medication_item?: any[];
        medication_other_item?: any[];
      };
    };
  } | null;
};

export const StateInitial: State = {
  PreOperationSequence: {
    sequenceIndex: null,

    preOPVisitData: {
      data: {
        date_of_visit: "",
        location: null,
        has_no_visit_reason: false,
        no_visit_reason: null,
        history_illness: "",
        status_of_consciousness: null,
        psychological_status: null,
        respiratory_status: null,
        limitation: null,
        remark: "",
      },
    },

    orRegistrationAreaData: {
      data: {
        transfer_by: "",
        type_surgeon: "",
        is_verbally: false,
        is_name_band: false,
        is_chart: false,
        mark_site: "",
        mark_site_confirm: "yes",
        is_procedure_confirm: false,
        is_consent_form: false,
        is_npo: false,
        is_valuable: false,
        valuable: "",
        is_impant: false,
        implant: false,
        lab_remark: "",
        blood: "",
        imaging: "",
        remark: "",
      },
    },

    holdingRoomData: {
      data: {
        verbally: false,
        name_band: false,
        chart: false,
        history_illness: "",
        procedure_confirm: false,
        procedure_confirm_detail: "",
        mark_site_confirm: "yes",
        mark_site_confirm_detail: "",
        confirm_surgical: "yes",
        anti_coagulation_drug: false,
        dm_drug: false,
        anti_hypertensive_drug: false,
        valuable: false,
        valuable_detail: [],
        implant: false,
        implant_detail: "",
        limitation: "",
        status_of_conscious: "",
        psychological_status: "",
        respiratory_status: "",
        lab_remark: "",
        blood: "",
        remark: "",
        oxygen: "",
        plan_and_implementation: "",
        evaluation: "",
        evaluation_check: false,
        evaluation_detail: "",
        medication_item: [],
        medication_other_item: [],
      },
    },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: Record<string, unknown> };

export type Data = {
  division?: number;
  masterData: {
    [key: string]: Record<string, any>[] | undefined;
    prenameEn: any[];
    prenameTh: any[];
  };
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const FORM_DATA_ACTION: any = {
  SAVE: "SAVE",
  CONFIRM: "CONFIRM",
  UNCONFIRM: "UNCONFIRM",
};

export const GetMaster: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["arriveStatus", {}],
        ["division", {}],
        ["limitation", {}],
        ["noVisitReason", {}],
        ["orMedication", {}],
        ["orValuable", {}],
        ["planAndImpleHr", {}],
        ["psychologicalStatus", {}],
        ["respiratoryStatus", {}],
        ["route", {}],
        ["statusOfConscious", {}],
        ["surgeonType", {}],
      ],
    },
  });

  controller.setState(
    {
      PreOperationSequence: { sequenceIndex: "Action" },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH_FORM_DATA_LATEST") {
    const [formResp, formErr, formNet] = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedOrOrder?.encounter,
        form_code: params.formCode,
        form_version: params.formVersion,
        operating_order_id: state.selectedOrOrder?.id,
      },
    });

    let result: any = {
      ...state.PreOperationSequence,
      [params.cardKey]: { data: {} },
    };

    if (formErr) {
      if (params.cardKey === "orRegistrationAreaData") {
        result[params.cardKey].data.mark_site_confirm = "yes";
      } else if (params.cardKey === "holdingRoomData") {
        await getDefaultHoldingRoom(controller, params);

        const state = controller.getState();
        result = { ...state.PreOperationSequence };
      }
    } else {
      result[params.cardKey] = formResp;
    }

    controller.setState({ PreOperationSequence: result });

    if (params.cardKey === "preOPVisitData") {
      getDefaultPreOperation(controller, params.cardKey);
    } else if (
      ["orRegistrationAreaData", "holdingRoomData"].includes(params.cardKey)
    ) {
      getOperatingDetail(controller, {
        cardKey: params.cardKey,
        formData: formResp,
      });
    }
  } else if (params?.action === "SET_DATA") {
    let data = {
      ...(state.PreOperationSequence?.[params.cardKey]?.data || {}),
      [params.key]: params.value,
    };

    if (params.key === "procedure_confirm" && !params.value){
      data["procedure_confirm_detail"] = ""
    }

    controller.setState({
      PreOperationSequence: {
        ...state.PreOperationSequence,
        [params.cardKey]: {
          ...state.PreOperationSequence[params.cardKey],
          data: data,
        },
      },
    });
  } else if (params?.action === "SAVE") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let saveResp = null;
    let saveErr = null;
    let saveNet = null;
    if (state.PreOperationSequence[params.cardKey]?.id) {
      let data: any = {
        ...state.PreOperationSequence[params.cardKey],
        action: params.action,
        doctor_order: state.selectedOrOrder?.id,
      };
      if (!data?.emr) {
        delete data.emr;
      }
      if (!data?.doctor) {
        delete data.doctor;
      }
      [saveResp, saveErr, saveNet] = await FormDataDetail.update({
        apiToken: controller.apiToken,
        pk: state.PreOperationSequence[params.cardKey]?.id,
        data: data,
      });
    } else {
      [saveResp, saveErr, saveNet] = await FormDataList.create({
        apiToken: controller.apiToken,
        data: {
          action: params.action,
          encounter: state.selectedOrOrder?.encounter,
          form_code: params.formCode,
          form_name: params.formName,
          form_version: params.formVersion,
          doctor_order: state.selectedOrOrder?.id,
          data: {
            ...state.PreOperationSequence[params.cardKey]?.data,
            operating_order_id: state.selectedOrOrder?.id,
          },
        },
        extra: { division: controller.data.division },
      });
    }
    if (saveErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
      });
      Action(controller, { ...params, action: "FETCH_FORM_DATA_LATEST" });
    }
  } else if (params?.action === "CONFIRM") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.PreOperationSequence[params.cardKey]?.id) {
      let data: any = {
        ...state.PreOperationSequence[params.cardKey],
        action: params.action,
        doctor_order: state.selectedOrOrder?.id,
      };
      if (!data?.emr) {
        delete data.emr;
      }
      if (!data?.doctor) {
        delete data.doctor;
      }
      const [confirmResp, confirmErr, confirmNet] = await FormDataDetail.update(
        {
          apiToken: controller.apiToken,
          pk: state.PreOperationSequence[params.cardKey]?.id,
          data: data,
        }
      );
      if (confirmErr) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: confirmErr },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "FETCH_FORM_DATA_LATEST" });
      }
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: "no ID." },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "UNCONFIRM") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.PreOperationSequence[params.cardKey]?.id) {
      let data: any = {
        ...state.PreOperationSequence[params.cardKey],
        action: params.action,
        doctor_order: state.selectedOrOrder?.id,
      };
      if (!data?.emr) {
        delete data.emr;
      }
      if (!data?.doctor) {
        delete data.doctor;
      }
      const [unconfirmResp, unconfirmErr, unconfirmNet] =
        await FormDataDetail.update({
          apiToken: controller.apiToken,
          pk: state.PreOperationSequence[params.cardKey]?.id,
          data: data,
        });
      if (unconfirmErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: unconfirmErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "FETCH_FORM_DATA_LATEST" });
      }
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: "no ID." },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "USER_TOKENIZE") {
    const [response, error, network] = await UserTokenizeView.post({
      apiToken: controller.apiToken,
      data: { code: params.code },
    });

    let data = {
      ...(state.PreOperationSequence[params.cardKey]?.data || {}),
    };

    if (error) {
      data[params.key][params.index] = {
        ...data[params.key][params.index],
        code: "",
      };
    } else {
      data[params.key][params.index] = {
        ...data[params.key][params.index],
        code: response?.token || "",
      };
    }

    controller.setState({
      PreOperationSequence: {
        ...state.PreOperationSequence,
        [params.cardKey]: {
          ...state.PreOperationSequence[params.cardKey],
          data: data,
        },
      },
    });
  } else if (params?.action === "PRINT_DATA") {
    const state = controller.getState();

    await controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["diagnosisType", {}],
          ["prenameTh", {}],
          ["prenameEn", {}],
        ],
      },
    });

    const { headerDetail } = await
      GetFormHeaderDetail(controller, {
      encounterId: state.selectedOrOrder?.encounter,
      patientData: state.selectedPatient,
    });

    const { preOpVisitData, orRegisAreaData, holdingRoomData } = await GetLatestDataPrinting(
      controller,
      {
        encounterId: state.selectedOrOrder?.encounter,
        operatingId: state.selectedOrOrder?.id,
      }
    );

    const data = {
      detail: headerDetail,
      userFullName: state.django?.user?.full_name,
      preOpVisitData: preOpVisitData,
      orRegisAreaData: orRegisAreaData,
      holdingRoomData: holdingRoomData,
    };

    let docDef = await FormPreOperation({ ...data });

    return (await getPdfMake(true)).createPdf(docDef).open();
  }
};

/** ====================================================  */
/**                        APIS                           */
/** ====================================================  */
const getDefaultPreOperation = async (controller: any, cardKey: string) => {
  const state = controller.getState();

  const [defaultResp, defaultErr, defaultNet] =
    await PreOperationDefaultDataView.retrieve({
      apiToken: controller.apiToken,
      pk: state.selectedOrOrder?.id,
    });

  if (!defaultErr) {
    let result: any = { ...state.PreOperationSequence };

    // #if (!result[cardKey]?.data?.previous_operation) {
    //   result[cardKey].data.previous_operation = ""//defaultResp.previous_operation;
    // }
    if (!result[cardKey]?.data?.location) {
      result[cardKey].data.location = defaultResp.division;
    }
    // if (!result[cardKey]?.data?.pre_implant) {
    //   result[cardKey].data.pre_implant = defaultResp.pre_implant;
    // }

    controller.setState({ PreOperationSequence: result });
  }
};

const getOperatingDetail = async (controller: any, params: any) => {
  const state = controller.getState();

  const [detailResp, detailErr, detailNet] =
    await OperatingOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state.selectedOrOrder?.id,
    });

  if (!detailErr) {
    let result: any = { ...state.PreOperationSequence };

    result[params.cardKey].data.order_type_label = detailResp.order_type_label;
    result[params.cardKey].data.lab_data = detailResp.lab_data;

    if (!params.formData?.data?.type_surgeon) {
      result[params.cardKey].data.type_surgeon = detailResp.procedure_type;
    }

    if (!params.formData?.data?.order_type_label) {
      const caseDetail = detailResp.case || {};
      let operatingCase = "";

      if (caseDetail.is_ipd_case) {
        operatingCase = "IPD";
      } else if (caseDetail.is_one_day_case) {
        operatingCase = "One day";
      } else if (caseDetail.is_opd_case) {
        operatingCase = "OPD";
      }

      result[params.cardKey].data.order_type_label = operatingCase;
    }

    controller.setState({ PreOperationSequence: result });
  }
};

const getDefaultHoldingRoom = async (controller: any, params: any) => {
  const state = controller.getState();

  const [defaultResp, defaultErr, defaultNet] = await NurseNoteTemplate.get({
    apiToken: controller.apiToken,
    params: {
      operating_order_id: state.selectedOrOrder?.id,
      form_code: params.formCode,
      nurse_note_temp_code: "nurse_note_hr",
    },
  });

  let result: any = {
    ...state.PreOperationSequence,
    [params.cardKey]: { ...StateInitial.PreOperationSequence?.holdingRoomData },
  };
  if (!defaultErr) {
    // #result[params.cardKey].data.previous_operation =
    //   defaultResp.previous_operation;
    result[params.cardKey].data.nursing_diagnosis =
      defaultResp.nursing_diagnosis;
    result[params.cardKey].data.goal = defaultResp.goal;
    result[params.cardKey].data.evaluation = defaultResp.evaluation;
  }

  result[params.cardKey].data.medication_item = [
    { medicine_name: "", rount: "", time: "", code: "", codeText: "" },
  ];
  result[params.cardKey].data.medication_other_item = [
    { medicine_name: "", rount: "", time: "", code: "", codeText: "" },
  ];

  controller.setState({ PreOperationSequence: result });
};

export const GetFormHeaderDetail: Handler = async (controller, params) => {
  const { encounterId, patientData } = params;
  console.log("test_encounterId",encounterId,patientData)

  // ดึงข้อมูล Encounter เพื่อใช้ในการเรียก API อื่นๆ
  const [enDetail] = await EncounterDetail.retrieve({
    apiToken: controller.apiToken,
    pk: encounterId,
  });

  const [doctorDetail] = await DoctorDetail.retrieve({
    apiToken: controller.apiToken,
    pk: enDetail.doctor,
  });

  const { masterData } = controller.data;

  const division = masterData.division?.find((item) => item.id === enDetail.division) || {};

  const doctor = formatDoctorName(doctorDetail, masterData);

  const presentAddress = patientData.present_address;

  return {
    doctorDetail,
    enDetail,
    headerDetail: {
      address: formatThaiAddress(presentAddress),
      citizen_no: patientData.citizen_no,
      citizen_passport: patientData.citizen_passport,
      divisionName: division.name_en || division.name,
      doctorName: doctor.standardFormat,
      hn: enDetail.hn,
      number: enDetail.number,
      patient_age: enDetail.patient_age,
      patient_birthdate: enDetail.patient_birthdate,
      patient_gender_name: enDetail.patient_gender_name,
      patientName: patientData.full_name_en || patientData.full_name_th || enDetail.patient_name,
      profileImage: patientData.profile_image?.image,
      started: enDetail.started,
      telMobile: presentAddress?.tel_mobile || "",
    },
  };
};

const GetLatestDataPrinting: Handler = async (controller, params) => {
  const state = controller.getState();
  let formattedPreOpVisit: any = null;
  let formattedOrRegisterArea: any = null;
  let formattedHoldingRoom: any = null;

  // get Pre-OP Visit Data
  const [preOpVisitResp, preOpVisitErr] = await FormDataLatest.retrieve({
    apiToken: controller.apiToken,
    params: {
      encounter: params?.encounterId,
      form_code: "CardPreOPVisit",
      form_version: "0.1",
      operating_order_id: params?.operatingId,
    },
  });

  if (preOpVisitResp?.data) {
    const data = preOpVisitResp?.data;

    formattedPreOpVisit = {
      ...data,
      location:
        state.masterOptions?.division?.find((val: any) => val.value === data?.location)?.text ||
        data?.location ||
        "-",
      status_of_consciousness:
        state.masterOptions?.statusOfConscious?.find(
          (val: any) => val.value === data?.status_of_consciousness
        )?.text ||
        data?.status_of_consciousness ||
        "-",
      psychological_status:
        state.masterOptions?.psychologicalStatus?.find(
          (val: any) => val.value === data?.psychological_status
        )?.text ||
        data?.psychological_status ||
        "-",
      respiratory_status:
        state.masterOptions?.respiratoryStatus?.find(
          (val: any) => val.value === data?.respiratory_status
        )?.text ||
        data?.respiratory_status ||
        "-",
      limitation:
        state.masterOptions?.limitation?.find((val: any) => val.value === data?.limitation)?.text ||
        data?.limitation ||
        "-",
    };
  }

  // get OR Registration Area Data
  const [orRegisResp, orRegisErr] = await FormDataLatest.retrieve({
    apiToken: controller.apiToken,
    params: {
      encounter: params?.encounterId,
      form_code: "CardORRegistrationArea",
      form_version: "1.0",
      operating_order_id: params?.operatingId,
    },
  });

  if (orRegisResp?.data) {
    const data = orRegisResp?.data;

    formattedOrRegisterArea = {
      ...data,
      transfer_by:
        state.masterOptions?.arriveStatus?.find((val: any) => val.value === data?.transfer_by)
          ?.text ||
        data?.transfer_by ||
        "-",
      type_surgeon:
        state.masterOptions?.orProcedureType?.find((val: any) => val.value === data?.type_surgeon)
          ?.text ||
        data?.type_surgeon ||
        "-",
      valuable:
        state?.masterOptions?.orValuable
          ?.filter((item: any) => data?.valuable?.includes(item.value))
          .map((item: any) => item.text) ||
        data?.valuable ||
        [],
      mark_site_confirm: data?.mark_site_confirm === "yes" ? "Yes" : "No", //*
      is_procedure_confirm: data?.is_procedure_confirm ? "Yes" : "No",
      is_consent_form: data?.is_consent_form ? "Yes" : "No",
      is_npo: data?.is_npo ? "Yes" : "No",
    };
  }

  // get Holding Room Data
  const [holdingRoomResp, holdingRoomErr] = await FormDataLatest.retrieve({
    apiToken: controller.apiToken,
    params: {
      encounter: params?.encounterId,
      form_code: "CardHoldingRoom",
      form_version: "0.1",
      operating_order_id: params?.operatingId,
    },
  });

  if (holdingRoomResp?.data) {
    const data = holdingRoomResp?.data;

    const mappedMedication = data?.medication_item?.map((item: any) => {
      const medicineText =
        state.masterOptions?.orMedication?.find((val: any) => val.value === item.medicine_name)
          ?.text ||
        item.medicine_name ||
        "-";
      const routeText =
        state.masterOptions?.route?.find((val: any) => val.value === item.route)?.text ||
        item.route ||
        "-";
      return {
        ...item,
        medicine_name: medicineText,
        route: routeText,
      };
    });

    const mappedOther = data?.medication_other_item?.map((item: any) => {
      const routeText =
        state.masterOptions?.route?.find((val: any) => val.value === item.route)?.text ||
        item.route ||
        "-";
      return {
        ...item,
        route: routeText,
      };
    });

    formattedHoldingRoom = {
      ...data,
      confirm_surgical: data?.confirm_surgical === "yes" ? "Yes" : "No",
      limitation:
        state.masterOptions?.limitation?.find((val: any) => val.value === data?.limitation)?.text ||
        data?.limitation ||
        "-",
      mark_site_confirm: data?.mark_site_confirm === "yes" ? "Yes" : "No",
      medication_item: mappedMedication || data?.medication_item || [],
      medication_other_item: mappedOther || data?.medication_other_item || [],
      psychological_status:
        state.masterOptions?.psychologicalStatus?.find(
          (val: any) => val.value === data?.psychological_status
        )?.text ||
        data?.psychological_status ||
        "-",
      respiratory_status:
        state.masterOptions?.respiratoryStatus?.find(
          (val: any) => val.value === data?.respiratory_status
        )?.text ||
        data?.respiratory_status ||
        "-",
      status_of_conscious:
        state.masterOptions?.statusOfConscious?.find(
          (val: any) => val.value === data?.status_of_conscious
        )?.text ||
        data?.status_of_conscious ||
        "-",
      type_surgeon:
        state.masterOptions?.orProcedureType?.find((val: any) => val.value === data?.type_surgeon)
          ?.text ||
        data?.type_surgeon ||
        "-",
      valuable_detail:
        state?.masterOptions?.orValuable
          ?.filter((item: any) => data?.valuable_detail?.includes(item.value))
          .map((item: any) => item.text) ||
        data?.valuable_detail ||
        [],
    };
  }

  return {
    preOpVisitData: preOpVisitErr ? {} : formattedPreOpVisit,
    orRegisAreaData: orRegisErr ? {} : formattedOrRegisterArea,
    holdingRoomData: holdingRoomErr ? {} : formattedHoldingRoom,
  };
};

/** ====================================================  */
/**                        Format                         */
/** ====================================================  */
const formatDoctorName = (
  doctorDetail: Record<string, any>,
  masterData: { prenameEn?: any[]; prenameTh?: any[] }
) => {
  const isEnglish = masterData.prenameEn?.some((item) => item.id === doctorDetail.pre_name_en);
  const prename = isEnglish
    ? masterData.prenameEn?.find((item) => item.id === doctorDetail.pre_name_en)
    : masterData.prenameTh?.find((item) => item.id === doctorDetail.pre_name);

  const name = [
    isEnglish ? doctorDetail.first_name_en : doctorDetail.first_name,
    isEnglish ? doctorDetail.middle_name_en : doctorDetail.middle_name,
    isEnglish ? doctorDetail.last_name_en : doctorDetail.last_name,
  ]
    .filter(Boolean)
    .join(" ");

  let certificateNo: string = doctorDetail.certificate_no;

  certificateNo = certificateNo ? `(${certificateNo.replace("ว.", "")})` : "";

  return {
    standardFormat: `${name}, ${prename?.name || ""} ${certificateNo}`,
    thaiFormat: `${prename?.name || ""}${name} ${certificateNo}`,
  };
};

const formatThaiAddress = (addressData: Record<string, any> = {}) => {
  const town = addressData?.town ? `หมู่ ${addressData.town}` : "";
  const road = addressData?.road ? `ถ.${addressData.road}` : "";
  const soi = addressData?.street ? `ซอย${addressData.street}` : "";
  const city = addressData?.city_label ? `แขวง${addressData.city_label}` : "";
  const district = addressData?.district_label;

  return [
    addressData?.no,
    addressData?.name,
    town,
    soi,
    road,
    city,
    district,
    addressData?.province_label,
    addressData?.zipcode,
  ]
    .filter(Boolean)
    .join(" ");
};