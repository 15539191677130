import React, { useEffect, useMemo, useState } from "react";

// UX
import CardDentistCertificateUX from "./CardDentistCertificateUX";

// Interface
import { State } from "./sequence/DoctorCertificate";

// Type
type CardDentistCertificateProps = {
  // Function
  setProp: any;

  // Options
  masterOptions?: any;

  // Sequence
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
};

const CardDentistCertificate = (props: CardDentistCertificateProps) => {

  // useMemo
  const selectedDoctor = useMemo(() => {
    const doctorValue = props.DoctorCertificateSequence?.dentistReport?.doctor;
    return props.DoctorCertificateSequence?.doctorList?.find((item: any) => item.value === doctorValue) || {};
  }, [props.DoctorCertificateSequence?.dentistReport?.doctor, props.DoctorCertificateSequence?.doctorList]);

  const selectedPediatric = useMemo(() => {
    const pediatricValue = props.DoctorCertificateSequence?.dentistReport?.pediatric_dentists;
    return props.DoctorCertificateSequence?.doctorList?.find((item: any) => item.value === pediatricValue) || {};
  }, [props.DoctorCertificateSequence?.dentistReport?.pediatric_dentists, props.DoctorCertificateSequence?.doctorList]);
  
  // Call Sequence ( Medical Certificate CU Dent )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "FETCH_CERTIFICATE",
      fetchType: "MedicalCertificateCUDent",
    });
  }, []);

  useEffect(() => {
    if (props.DoctorCertificateSequence?.dentistReport?.doctor) {
      const { name_th, name_en, certificate_no } = selectedDoctor;
      props.setProp(`DoctorCertificateSequence.dentistReport`, {
        ...props.DoctorCertificateSequence?.dentistReport,
        doctor_name_th: name_th || null,
        doctor_name_en: name_en || null,
        doctor_certificate_no: certificate_no || " ",
      })
    }
  }, [props.DoctorCertificateSequence?.dentistReport?.doctor, selectedDoctor]);

  useEffect(() => {
    if (props.DoctorCertificateSequence?.dentistReport?.is_pediatric_dentists === false) {
      props.setProp(`DoctorCertificateSequence.dentistReport`, {
        ...props.DoctorCertificateSequence?.dentistReport,
        pediatric_dentists: null,
        pediatric_dentists_name_th: null,
        pediatric_dentists_name_en: null,
      });
    }
  }, [props.DoctorCertificateSequence?.dentistReport?.is_pediatric_dentists]);

  useEffect(() => {
    if (props.DoctorCertificateSequence?.dentistReport?.pediatric_dentists) {
      const { name_th, name_en } = selectedPediatric;
      props.setProp(`DoctorCertificateSequence.dentistReport`, {
        ...props.DoctorCertificateSequence?.dentistReport,
        pediatric_dentists_name_th: name_th || null,
        pediatric_dentists_name_en: name_en || null,
      });
    }
  }, [props.DoctorCertificateSequence?.dentistReport?.pediatric_dentists, selectedPediatric]);

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`DoctorCertificateSequence.dentistReport.${data.name}`, data.checked);
    } else {
      props.setProp(`DoctorCertificateSequence.dentistReport.${data.name}`, data.value);
    }
  };

  return (
    <CardDentistCertificateUX
      // data
      dentistReport={props.DoctorCertificateSequence?.dentistReport}
      doctorList={props.DoctorCertificateSequence?.doctorList}
      // onChange
      onChangeData={handleChangeData}
      languageUX={props.languageUX}
    />
  )
};

export default CardDentistCertificate;