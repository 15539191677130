import WasmController from 'react-lib/frameworks/WasmController';

import * as CommonInterface from "react-lib/apps/HISV3/common/CommonInterface";
import CommonSequencePattern from "react-lib/apps/HISV3/common/sequence/SequencePattern"
import * as ADMInterface from "react-lib/apps/HISV3/ADM/ADMInterface";
import * as IMEInterface from "react-lib/apps/HISV3/IME/IMEInterface";
import IMESequencePattern from "react-lib/apps/HISV3/IME/sequence/SequencePattern";
import ADMSequencePattern from "react-lib/apps/HISV3/ADM/sequence/SequencePattern";
import * as APPInterface from "react-lib/apps/HISV3/APP/APPInterface";
import * as StaffChatInterface from "react-lib/apps/MSG/StaffChatInterface";
import APPSequencePattern from "react-lib/apps/HISV3/APP/sequence/SequencePattern";
import * as REGInterface from "react-lib/apps/HISV3/REG/REGInterface";
import REGSequencePattern from "react-lib/apps/HISV3/REG/sequence/SequencePattern";
import * as PTMInterface from "react-lib/apps/HISV3/PTM/PTMInterface";
import * as MedAdminInterface from "react-lib/apps/HISV3/PTM/MedAdminInterface";
import PTMSequencePattern from "react-lib/apps/HISV3/PTM/sequence/SequencePattern";
import * as VitalSignInterface from "react-lib/apps/HISV3/PTM/VitalSignInterface";
import * as IsHealthInterface from "HIS/IsHealth/IsHealthInterface";
import * as DPIInterface from "react-lib/apps/HISV3/DPI/DPIInterface";
import * as DPOInterface from "react-lib/apps/HISV3/DPO/DPOInterface";
import DPOSequencePattern from "react-lib/apps/HISV3/DPO/sequence/SequencePattern"
import * as CardPatientListI from "react-lib/apps/HISV3/common/CardPatientListInterface";
import * as TPDInterface from "react-lib/apps/HISV3/TPD/TPDInterface";
import TPDSequencePattern from "react-lib/apps/HISV3/TPD/sequence/SequencePattern";
import * as HCUInterface from "react-lib/apps/HISV3/HCU/HCUInterface";
import HCUSequencePattern from "react-lib/apps/HISV3/HCU/sequence/SequencePattern";
import * as FODInterface from "react-lib/apps/HISV3/FOD/FODInterface";
import FODSequencePattern from "react-lib/apps/HISV3/FOD/sequence/SequencePattern";
import * as BILInterface from "react-lib/apps/HISV3/BIL/BILInterface";
import DFCSequencePattern from "react-lib/apps/HISV3/DFC/sequence/SequencePattern";
import * as DFCInterface from "react-lib/apps/HISV3/DFC/DFCInterface";
import BILSequencePattern from "react-lib/apps/HISV3/BIL/sequence/SequencePattern"
import * as LabInterface from "react-lib/apps/HISV3/LAB/LabInterface";
import LabSequencePattern from "react-lib/apps/HISV3/LAB/sequence/SequencePattern"
import * as MSDInterface from "react-lib/apps/HISV3/MSD/MSDInterface";
import MSDSequencePattern from "react-lib/apps/HISV3/MSD/sequence/SequencePattern"
import * as DENInterface from "react-lib/apps/HISV3/DEN/DENInterface";
import DENSequencePattern from "react-lib/apps/HISV3/DEN/sequence/SequencePattern";
import * as ORMInterface from "react-lib/apps/HISV3/ORM/ORMInterface";
import * as ANEInterface from "react-lib/apps/HISV3/ANE/ANEInterface";
import ORMSequencePattern from "react-lib/apps/HISV3/ORM/sequence/SequencePattern";
import ANESequencePattern from "react-lib/apps/HISV3/ANE/sequence/SequencePattern";
import CLMSequencePattern from "react-lib/apps/HISV3/CLM/sequence/SequencePattern";
import PHRSequencePattern from "react-lib/apps/HISV3/PHR/sequence/SequencePattern";
import DPISequencePattern from "react-lib/apps/HISV3/DPI/sequence/SequencePattern";
import * as SegmentInterface from "react-lib/apps/segment/SegmentInterface";
import SegmentSequencePattern from "react-lib/apps/segment/sequence/SequencePattern";
import * as USERInterface from "react-lib/apps/USER/USERInterface";
import * as RHBInterface from "react-lib/apps/HISV3/RHB/RHBInterface";


import * as VaccineUploadI from "HIS/VaccineUpload/VaccineUploadInterface";
import * as ModPlanningI from "react-lib/apps/HISV3/common/ModPlanningInterface";
import * as ModSolventSelectionI from "react-lib/apps/HISV3/TPD/ModSolventSelectionInterface";
import * as SchedulingI from "react-lib/apps/Scheduling/SchedulingInterface";
import * as DispensingOrderSupplyI from "react-lib/apps/HISV3/MSD/sequence/DispensingOrderSupply";
import * as SupplyDeliveryI from "react-lib/apps/HISV3/MSD/sequence/SupplyDelivery";
import * as RefillDivisionPrintI from "react-lib/apps/HISV3/MSD/sequence/RefillDivisionPrint";
import * as PatientSearchCUI from "react-lib/apps/common/CU/PatientSearchCUInterface";
import * as QueueForPatientI from "react-lib/apps/IsHealth/Dashboard/QueueInterface";
import * as CLMInterface from "react-lib/apps/HISV3/CLM/CLMInterface";
import * as PHRInterface from "react-lib/apps/HISV3/PHR/PHRInterface";
import * as DentalRecordI from "react-lib/apps/common/CUDENT/DentalRecordInterface";
import * as StaffSettingI from "react-lib/apps/USER/StaffSettingInterface"
import * as PHVInterface from "react-lib/apps/HISV3/PHV/PHVInterface";

import DivisionDetail from 'issara-sdk/apis/DivisionDetail_core';
import DeviceList from "issara-sdk/apis/DeviceList_core"
import { ResetSequence } from "react-lib/apps/HISV3/MainHISResetSequence";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import ShippingOrderPerformedDetail from "issara-sdk/apis/ShippingOrderPerformedDetail_apps_PRX"
import LockerActionLogList from "issara-sdk/apis/LockerActionLogList_core";
import UserLoginView from "issara-sdk/apis/UserLoginView_core";

import { getDuckDBCDN, getDuckDBStatic, getDuckDBStaticFromStorage, getDuckDBConnection } from "react-lib/frameworks/Duckdb";

import { GetOrgan } from "react-lib/apps/HISV3/IME/sequence/ImagingHandler";

// Generated skeleton + manual handler implementation
import * as ADMI from "./Interface/ADMI";
import * as ADRI from "./Interface/ADRI";
import * as ANSI from "./Interface/ANSI";
import * as APPI from "./Interface/APPI";
import * as BILI from "./Interface/BILI";
import * as BLBI from "./Interface/BLBI";
import * as CoreI from "./Interface/CoreI";
import * as DFCI from "./Interface/DFCI";
import * as DPII from "./Interface/DPII";
import * as DPOI from "./Interface/DPOI";
import * as HRMI from "./Interface/HRMI";
import * as ICUI from "./Interface/ICUI";
import * as IMEI from "./Interface/IMEI";
import * as INFI from "./Interface/INFI";
import * as LABI from "./Interface/LABI";
import * as LRMI from "./Interface/LRMI";
import * as MixInI from "./Interface/MixInI";
import * as MSGI from "./Interface/MSGI";
import * as ORMI from "./Interface/ORMI";
import * as PRXI from "./Interface/PRXI";
import * as PTMI from "./Interface/PTMI";
import * as QUEI from "./Interface/QUEI";
import * as REGI from "./Interface/REGI";
import * as TECI from "./Interface/TECI";
import * as TPDI from "./Interface/TPDI";
import * as TRTI from "./Interface/TRTI";
import * as UserI from "./Interface/UserI";

import CONFIG from "config/config";
import axios from "axios";
import Cookies from 'js-cookie';
import moment from 'moment';
import { AsyncDuckDB, AsyncDuckDBConnection } from '@duckdb/duckdb-wasm';

export type State = Partial<
  {
    debugInfo?: any;
    selectedRecordViewIndex?: number;
    patientQueueEncounter?: any;
    QAChatTable?: any;
    goToCreateAppointment?: any;
    selectedTabIndex?: number;
    notificationMessageList?: Record<string, any>[];
    billLockNotiMessageList?: Record<string, any>[];
    selectedTabPatientList?: string;
    transferOrderCount?: number;
    duckDBLoaded?: boolean;
    duckDB?: any;
    duckDBConn?: AsyncDuckDBConnection;
  } & TPDInterface.State &
    CardPatientListI.State &
    VitalSignInterface.State &
    DPIInterface.State &
    DPOInterface.State &
    LabInterface.State &
    MSDInterface.State &
    DENInterface.State &
    ADMInterface.State &
    IMEInterface.State &
    FODInterface.State &
    PTMInterface.State &
    MedAdminInterface.State &
    DFCInterface.State &
    BILInterface.State &
    IsHealthInterface.State &
    CommonInterface.State &
    REGInterface.State &
    APPInterface.State &
    StaffChatInterface.State &
    VaccineUploadI.State &
    SchedulingI.State &
    // & SchedulingIV3.State
    ORMInterface.State &
    ANEInterface.State &
    ADMI.State &
    ADRI.State &
    ANSI.State &
    APPI.State &
    BILI.State &
    BLBI.State &
    CoreI.State &
    DFCI.State &
    DPII.State &
    DPOI.State &
    HRMI.State &
    ICUI.State &
    IMEI.State &
    INFI.State &
    LABI.State &
    LRMI.State &
    MixInI.State &
    MSGI.State &
    ORMI.State &
    PRXI.State &
    PTMI.State &
    QUEI.State &
    REGI.State &
    TECI.State &
    TPDI.State &
    TRTI.State &
    UserI.State &
    ModPlanningI.State &
    ModSolventSelectionI.State &
    DispensingOrderSupplyI.State &
    SupplyDeliveryI.State &
    RefillDivisionPrintI.State &
    QueueForPatientI.State &
    CLMInterface.State &
    PHRInterface.State &
    HCUInterface.State &
    DentalRecordI.State &
    StaffSettingI.State &
    PHVInterface.State &
    SegmentInterface.State &
    USERInterface.State
>;

export const StateInitial: State = {
  debugInfo: {},
  selectedRecordViewIndex: 0,
  goToCreateAppointment: false,
  patientQueueEncounter: null,
  ...TPDInterface.StateInitial,
  ...CardPatientListI.StateInitial,
  ...VitalSignInterface.StateInitial,
  ...DPIInterface.StateInitial,
  ...DPOInterface.StateInitial,
  ...LabInterface.StateInitial,
  ...MSDInterface.StateInitial,
  ...DENInterface.StateInitial,
  ...ADMInterface.StateInitial,
  ...IMEInterface.StateInitial,
  ...FODInterface.StateInitial,
  ...PTMInterface.StateInitial,
  ...MedAdminInterface.StateInitial,
  ...DFCInterface.StateInitial,
  ...BILInterface.StateInitial,
  ...IsHealthInterface.StateInitial,
  ...CommonInterface.StateInitial,
  ...REGInterface.StateInitial,
  ...APPInterface.StateInitial,
  ...StaffChatInterface.StateInitial,
  ...VaccineUploadI.StateInitial,
  ...SchedulingI.StateInitial,
  ...ORMInterface.StateInitial,
  ...ANEInterface.StateInitial,
  ...ADMI.StateInitial,
  ...ADRI.StateInitial,
  ...ANSI.StateInitial,
  ...APPI.StateInitial,
  ...BILI.StateInitial,
  ...BLBI.StateInitial,
  ...CoreI.StateInitial,
  ...DFCI.StateInitial,
  ...DPII.StateInitial,
  ...DPOI.StateInitial,
  ...HRMI.StateInitial,
  ...ICUI.StateInitial,
  ...IMEI.StateInitial,
  ...INFI.StateInitial,
  ...LABI.StateInitial,
  ...LRMI.StateInitial,
  ...MixInI.StateInitial,
  ...MSGI.StateInitial,
  ...ORMI.StateInitial,
  ...PRXI.StateInitial,
  ...PTMI.StateInitial,
  ...QUEI.StateInitial,
  ...REGI.StateInitial,
  ...TECI.StateInitial,
  ...TPDI.StateInitial,
  ...TRTI.StateInitial,
  ...UserI.StateInitial,
  ...ModPlanningI.StateInitial,
  ...QueueForPatientI.StateInitial,
  ...ModSolventSelectionI.StateInitial,
  ...CLMInterface.StateInitial,
  ...PHRInterface.StateInitial,
  ...HCUInterface.StateInitial,
  ...DentalRecordI.StateInitial,
  ...StaffSettingI.StateInitial,
  ...PHVInterface.StateInitial,
  ...SegmentInterface.StateInitial,
  ...USERInterface.StateInitial
};

export type Event =
  | { message: "RunSequence"; params: any }
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  | { message: "ChangeDivision"; params: { divisionId: any } }
  | { message: "ChangeDevice"; params: { deviceId: any } }
  | { message: "RefreshDivision"; params: { divisionId: number } }
  | { message: "GetDebugInfo"; params: {} }
  | { message: "GetEncounterInfo"; params: { encounterId: any; goToMenu?: string | null } }
  | {
      message: "HandleUpdateNotiMessage";
      params: { data: any; update?: boolean };
    }
  | {
      message: "HandleUpdateBillLockNotiMessage";
      params: { data: any };
    }
  | { message: "HandleDidMountEncounter"; params: {} }
  | { message: "HandleUnmountEncounter"; params: {} }
  | { message: "HandleSelectTabIndex"; params: { tab: string } }
  | { message: "CheckUserCredentials"; params: {} }
  | TPDInterface.Event
  | CardPatientListI.Event
  | VitalSignInterface.Event
  | DPIInterface.Event
  | DPOInterface.Event
  | LabInterface.Event
  | MSDInterface.Event
  | DENInterface.Event
  | ADMInterface.Event
  | IMEInterface.Event
  | FODInterface.Event
  | PTMInterface.Event
  | MedAdminInterface.Event
  | DFCInterface.Event
  | BILInterface.Event
  | IsHealthInterface.Event
  | CommonInterface.Event
  | REGInterface.Event
  | APPInterface.Event
  | StaffChatInterface.Event
  | VaccineUploadI.Event
  | SchedulingI.Event
  | ORMInterface.Event
  | ANEInterface.Event
  | ADMI.Event
  | ADRI.Event
  | ANSI.Event
  | APPI.Event
  | BILI.Event
  | BLBI.Event
  | CoreI.Event
  | DFCI.Event
  | DPII.Event
  | DPOI.Event
  | HRMI.Event
  | ICUI.Event
  | IMEI.Event
  | INFI.Event
  | LABI.Event
  | LRMI.Event
  | MixInI.Event
  | MSGI.Event
  | ORMI.Event
  | PRXI.Event
  | PTMI.Event
  | QUEI.Event
  | REGI.Event
  | TECI.Event
  | TPDI.Event
  | TRTI.Event
  | UserI.Event
  | ModPlanningI.Event
  | ModSolventSelectionI.Event
  | DispensingOrderSupplyI.Event
  | PatientSearchCUI.Event
  | QueueForPatientI.Event
  | CLMInterface.Event
  | PHRInterface.Event
  | HCUInterface.Event
  | DentalRecordI.Event
  | StaffSettingI.Event
  | PHVInterface.Event
  | SegmentInterface.Event
  | USERInterface.Event;

export type Data =
  {
    duckDB: AsyncDuckDB;
    duckDBConn: AsyncDuckDBConnection;
  }
  & TPDInterface.Data
  & CardPatientListI.Data
  & VitalSignInterface.Data
  & DPIInterface.Data
  & DPOInterface.Data
  & LabInterface.Data
  & MSDInterface.Data
  & DENInterface.Data
  & ADMInterface.Data
  & IMEInterface.Data
  & FODInterface.Data
  & PTMInterface.Data
  & DFCInterface.Data
  & BILInterface.Data
  & IsHealthInterface.Data
  & CommonInterface.Data
  & REGInterface.Data
  & APPInterface.Data
  & StaffChatInterface.Data
  & VaccineUploadI.Data
  & SchedulingI.Data
  & ORMInterface.Data
  & ANEInterface.Data
  & ADMI.Data
  & ADRI.Data
  & ANSI.Data
  & APPI.Data
  & BILI.Data
  & BLBI.Data
  & CoreI.Data
  & DFCI.Data
  & DPII.Data
  & DPOI.Data
  & HRMI.Data
  & ICUI.Data
  & IMEI.Data
  & INFI.Data
  & LABI.Data
  & LRMI.Data
  & MixInI.Data
  & MSGI.Data
  & ORMI.Data
  & PRXI.Data
  & PTMI.Data
  & QUEI.Data
  & REGI.Data
  & TECI.Data
  & TPDI.Data
  & TRTI.Data
  & UserI.Data
  & ModPlanningI.Data
  & ModSolventSelectionI.Data
  & QueueForPatientI.Data
  & CLMInterface.Data
  & PHRInterface.Data
  & HCUInterface.Data
  & IMEInterface.Data
  & SegmentInterface.Data
  & USERInterface.Data

export const DataInitial =
{
  estimateResult: {},
  ...TPDInterface.DataInitial,
  ...CardPatientListI.DataInitial,
  ...VitalSignInterface.DataInitial,
  ...DPIInterface.DataInitial,
  ...DPOInterface.DataInitial,
  ...LabInterface.DataInitial,
  ...MSDInterface.DataInitial,
  ...DENInterface.DataInitial,
  ...ADMInterface.DataInitial,
  ...IMEInterface.DataInitial,
  ...FODInterface.DataInitial,
  ...PTMInterface.DataInitial,
  ...DFCInterface.DataInitial,
  ...BILInterface.DataInitial,
  ...IsHealthInterface.DataInitial,
  ...CommonInterface.DataInitial,
  ...REGInterface.DataInitial,
  ...APPInterface.DataInitial,
  ...StaffChatInterface.DataInitial,
  ...VaccineUploadI.DataInitial,
  ...SchedulingI.DataInitial,
  ...ORMInterface.DataInitial,
  ...ANEInterface.DataInitial,
  ...ADMI.DataInitial,
  ...ADRI.DataInitial,
  ...ANSI.DataInitial,
  ...APPI.DataInitial,
  ...BILI.DataInitial,
  ...BLBI.DataInitial,
  ...CoreI.DataInitial,
  ...DFCI.DataInitial,
  ...DPII.DataInitial,
  ...DPOI.DataInitial,
  ...HRMI.DataInitial,
  ...ICUI.DataInitial,
  ...IMEI.DataInitial,
  ...INFI.DataInitial,
  ...LABI.DataInitial,
  ...LRMI.DataInitial,
  ...MixInI.DataInitial,
  ...MSGI.DataInitial,
  ...ORMI.DataInitial,
  ...PRXI.DataInitial,
  ...PTMI.DataInitial,
  ...QUEI.DataInitial,
  ...REGI.DataInitial,
  ...TECI.DataInitial,
  ...TPDI.DataInitial,
  ...TRTI.DataInitial,
  ...UserI.DataInitial,
  ...ModPlanningI.DataInitial,
  ...ModSolventSelectionI.DataInitial,
  ...HCUInterface.DataInitial,
  ...SegmentInterface.DataInitial,
  ...USERInterface.DataInitial
}

const SequencePattern: { [sequenceName: string]: any } = {
  ...CommonSequencePattern,
  ...REGSequencePattern,
  ...TPDSequencePattern,
  ...FODSequencePattern,
  ...BILSequencePattern,
  ...APPSequencePattern,
  ...PTMSequencePattern,
  ...DFCSequencePattern,
  ...DPOSequencePattern,
  ...DPISequencePattern,
  ...LabSequencePattern,
  ...MSDSequencePattern,
  ...DENSequencePattern,
  ...ADMSequencePattern,
  ...ORMSequencePattern,
  ...ANESequencePattern,
  ...CLMSequencePattern,
  ...PHRSequencePattern,
  ...HCUSequencePattern,
  ...IMESequencePattern,
  ...SegmentSequencePattern
};

export const BACK_OFFICE_ADMIN_TAB_LIST = [
  "Upload ข้อมูลผู้ใช้สิทธิ",
  "ตั้งค่ากฏการเบิก",
  "ออกใบแจ้งหนี้ / ตั้งงวด",
  "เตรียมข้อมูล E-Claim",
  "AIPN Send Claim",
  "รับชำระเงินต้นสังกัด",
  "รายงานทางการเงิน",
  "รายงานประจำคลินิก",
  "พิมพ์เอกสารภาษี",
  "สรุปค่าตอบแทนแพทย์",
  "รายงานบัญชี",
  "ศูนย์ต้นทุนรายใบเสร็จ",
  "import ค่า Lab สำหรับคำนวณรายได้แพทย์",
  "บันทึกไฟล์ตอบกลับ",
  "ตรวจสอบรายการสินค้าและบริการ",
];

export const BACK_OFFICE_REGISTRATION_TAB_LIST = [
  "Patient History Viewer",
  "รายการสถานะผู้ป่วย",
  "เอกสารทั้งหมด",
  "Medical_Bill_Summary",
  "Print_List_Register",
  "Scanned_Document_Register",
  "Scanning Document",
  "รายการรอรับชำระและคืนเงิน",
];

export const BILLING_REGISTRATION_TAB_LIST = [
  "รายการรอรับชำระและคืนเงิน",
  "รายการสถานะผู้ป่วย",
  "Medical_Bill_Summary",
  "เอกสารทั้งหมด",
  "กำหนดวงเงินรายครั้ง",
  "วงเงินคงเหลือ",
  "สิทธิการรักษา",
  "Package",
  "Purchase History",
  "Apply Policy Package",
  "รับชำระเงินมัดจำ",
  "ประวัติการใช้เงินมัดจำ",
  "แจ้งคืนค่าบริการ",
  "Print_List_Register",
  "Scanned_Document_Register",
  "Scanning Document",
  "Purchase list",
  "รับรองสิทธิ",
];

export const IMAGING_ENCOUNTER_TAB_LIST = [
  "Imaging Result",
  "Imaging Order",
  "Doctor Fee",
];

export const SUPPLY_ENCOUNTER_TAB_LIST = ["Supply Order", "Supply Workflow"];

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const RunSequence: Handler = async (controller, params) => {

  console.groupCollapsed("RunSequence: ", `${params.sequence}Sequence`);
  console.trace();
  // This will get called repeatedly with sequenceIndex
  // to determine which branch to execute
  if (!params?.sequence) return console.error("Sequence name not specified.")

  const sequenceState = `${params.sequence}Sequence`;
  const state: { [key: string]: any } = controller.getState();
  console.debug(`RunSequence state?.[${sequenceState}]: `, state?.[sequenceState]);
  console.log('RunSequence params?.restart: ', params?.restart);
  if (!state?.[sequenceState] || params?.restart) {
    // First run or restart
    console.debug("RunSequence first Run or Restart sequenceState: ", sequenceState)
    console.groupEnd();
    if (params?.restart) {
      delete params.restart
    }
    controller.setState({
      [sequenceState]: {
        sequenceIndex: "START",
      }
    }, () => {
      RunSequence(controller, params);
    });

    return
  } else if (params?.clear) {
    console.debug("RunSequence clear ")
    console.groupEnd();
    // Clear state
    controller.setState({
      [sequenceState]: null
    });

    return
  } else {
    console.groupEnd();
  }

  // Subsequent run
  if (!state?.[sequenceState]?.sequenceIndex) {
    return console.error(`RunSequence state.${sequenceState}?.sequenceIndex not set.`)
  }

  // console.debug("RunSequence Running passing params: ",params) ;
  // console.debug("RunSequence Running: ",params.sequence, " at index:", state[sequenceState].sequenceIndex, " SequencePattern: ", SequencePattern);
  const SEQUENCE = SequencePattern[params.sequence][CONFIG.SEQUENCE?.[params.sequence] || "default"];
  // console.debug('RunSequence prepare SEQUENCE: ', SEQUENCE);
  // console.debug('RunSequence prepare state[sequenceState].sequenceIndex: ', state[sequenceState].sequenceIndex);
  // console.debug('RunSequence prepare controller: ', controller);
  // console.debug('RunSequence prepare params: ', params);
  // console.debug("RunSequence Call SEQUENCE[state[sequenceState].sequenceIndex](controller, params) ")
  SEQUENCE[state[sequenceState].sequenceIndex](controller, params);
}

// Move this to AppInitialize Sequence
export const DidMount: Handler = async (controller, params) => {
  // Check CONFIG
  // Initiallize duck

  if (CONFIG.ENABLE_DUCKDB) {
    let duckDB = await getDuckDBCDN();
    let duckDBConn = await getDuckDBConnection(duckDB);

    controller.data.duckDB = duckDB
    controller.data.duckDBConn = duckDBConn
    controller.setState({
      duckDBLoaded: true,
    })
  }

  GetOrgan(controller, {});
}

export const DidUpdate: Handler = async (controller, params) => {
}

export const ChangeDivision: Handler = async (controller, params) => {
  const state = controller.getState();
  const divisionType = controller.data.divisionDict?.[params?.divisionId || 0]?.type_label || null;
  if (!params?.divisionId) return;
  if (!controller.data.divisionDict?.[params.divisionId] && !params?.isRefresh) return
  try {
    const divisionId = parseInt(params.divisionId);
    Cookies.set("division_id", divisionId.toString());
    controller.data.division = divisionId;

    const { selectedLocation, ...filter } = JSON.parse(controller.cookies.get("filterDrugOrderQueue") || "{}");
    controller.cookies.set("filterDrugOrderQueue", JSON.stringify(filter));

    console.log(" !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  Change Division !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  ")
    const divisionDetail = DivisionDetail.retrieve({ pk: divisionId, apiToken: controller.apiToken })
    controller.data.divisionDetail = !divisionDetail[1] ? divisionDetail[0] : {}

    const resetLayout = state.mode === "patient" ? !state.resetLayout : state.resetLayout;

    await controller.setState({
      django: {
        ...state.django,
        division: divisionId,
      },
      selectedDivision: controller.data.divisionDict[divisionId],
      divisionType: divisionType,
      selectedTabPatientList: params.tabPatientList || "",
      ...(!params.noDefaultTabIndex && {
        selectedTabIndex: CommonInterface.getSideMenuIndex({ divisionType }),
      }),
      // mode: "patient",
      resetLayout: params.resetLayout ?? resetLayout,
      ...ResetSequence,
    });

    RefreshDevice(controller, params)

    controller.handleEvent({ message: "FilterSchedule", params: { divisionId } });
  } catch (e: any) { console.log(e.toString()); }
}


export const RefreshDevice: Handler = async (controller, params) => {
  // Handle division-related data and state ========================================
  const deviceList = await DeviceList.list({
    params: { division: params.divisionId },
    apiToken: controller.apiToken
  });

  const deviceOptions = (deviceList[0] || []).items
    ?.map((item: any) => (
      {
        key: item.id,
        value: item.id,
        text: item.computer_name,
      }
    ));

  const deviceDict = Object.fromEntries(
    (deviceList[0]?.items || []).map((item: any) => ([item.id, item]))
  );

  // Set selectedDevice to in controller.data (from cookies) or first in the list
  const selectedDevice = deviceList[0]?.items[0] || null

  // Set data  and state ============================================================
  if (selectedDevice?.id) {
    controller.data = {
      ...controller.data,
      device: selectedDevice?.id || 0,
      deviceDict: deviceDict,
    };

    controller.setState({
      deviceOptions: deviceOptions,
      selectedDevice: selectedDevice,
      selectedOrOrder: null
    });
  } else {
    controller.data = {
      ...controller.data,
      device: 0,
      deviceDict: deviceDict,
    };

    controller.setState({
      deviceOptions: deviceOptions,
      selectedDevice: { id: "" },
      selectedOrOrder: null
    });
  }
}

export const ChangeDevice: Handler = async (controller, params) => {
  if (!params?.deviceId) return;
  if (!controller.data.deviceDict?.[params.deviceId]) return
  try {
    const deviceId = parseInt(params.deviceId);
    Cookies.set("device_id", deviceId.toString());
    controller.data.device = deviceId;
    controller.setState({
      selectedDevice: controller.data.deviceDict[deviceId],
    });
  } catch (e: any) { console.log(e.toString()); }
}

export const RefreshDivision: Handler = async (controller, params) => {
  if (typeof params.divisionId === "undefined" || params.divisionId === null || params.divisionId === 0) return;
  console.log(params.divisionId);
  Cookies.set("division_id", params.divisionId.toString());
  controller.data.division = params.divisionId;
  // Start as if app just initialize, refresh all data including division
  controller.handleEvent({ message: "RunSequence", params: { sequence: "AppInitialize" } })
}

export const GetDebugInfo: Handler = (controller, params) => {
  controller.setState({
    debugInfo: {}
  })
  axios
    .get("http://localhost:8080/api/analyze-encounter/", {
      params: {
        hn: "65000016"
      },
      headers: {
        "Authorization": `Token ${controller.apiToken}`
      }
    })
    .then((res) => {
      console.log(res);
      controller.setState({
        debugInfo: res.data
      });
    });
}

export const GetEncounterInfo: Handler = async (controller, params) => {

  const [r, e, n] = await EncounterDetail.retrieve({
    pk: params.encounterId,
    apiToken: controller.apiToken,
  });
  if (e) {
    console.log(e, n);
    return;
  }

  controller.handleEvent({
    message: "SelectEncounter" as any,
    params: { encounter: r, goToMenu: params.goToMenu },
  });

};

export const HandleUpdateNotiMessage: Handler = async (controller, params) => {
  const state = controller.getState()

  if ((params.data?.organizationId != state.selectedDivision?.organization?.id) &&
      params.data?.type === "SHP") {
        if (params.data?.organizationId != state.selectedDivision?.organization?.id) {
          console.log("HandleUpdateNotiMessage : don't update Organization not match")
        }
        return;
  }

  let notificationList = state.notificationMessageList || [];
  const isUpdate = notificationList.some(
    (item: any) => item.id === params.data.id
  );

  if (params.data.id.includes("SHIPPING_UPDATE")) {
    const pk = params.data.id.split("-")?.[1];

    const [result] = await ShippingOrderPerformedDetail.retrieve({
      apiToken: controller.apiToken,
      pk
    });

    params.data.title = `หมายเลขจัดส่ง ${result?.tracking_number || "-"}`
  }

  if (isUpdate && params.update) {
    notificationList = notificationList.map((item: any) =>
      item.id === params.data.id
        ? {
          ...params.data,
          time: moment().toISOString(),
          update: item.time,
        }
        : item
    );
  } else {
    notificationList.push({
      ...params.data,
      time: moment().toISOString(),
      update: "",
    });
  }

  controller.setState({ notificationMessageList: [...notificationList] });
}

export const HandleUpdateBillLockNotiMessage: Handler = async (controller, params) => {
  const state = controller.getState();

  let notificationList = state.billLockNotiMessageList || [];

  const isUpdate = notificationList.some((item) => item.id === params.data.id);

  const [result] = await LockerActionLogList.list({
    apiToken: controller.apiToken,
    params: {
      actions: "UNLOCK_REQUEST",
      lock_level: params.data.lock_level,
      lock_type: params.data.lock_type,
      performed: false,
      only_locked_by: controller.data.user,
    },
  });

  const items: Record<string, any>[] = result?.items || [];
  const data = items.slice(-1)[0] || {};

  if (isUpdate) {
    notificationList = notificationList.map((item) =>
      item.id === params.data.id
        ? {
            ...data,
            id: params.data.id,
            time: moment().toISOString(),
            update: item.time,
          }
        : item
    );
  } else {
    notificationList.push({
      ...data,
      id: params.data.id,
      time: moment().toISOString(),
      update: ""
    });
  }

  controller.setState({ billLockNotiMessageList: [...notificationList] });
};

export const HandleDidMountEncounter: Handler = (controller, params) => {
  console.log('HandleDidMountEncounter: ');
  const state = controller.getState();

  const filterKeys = Object.entries(state).flatMap(([key, value]) =>
    value ? [key] : []
  );

  controller.setState({
    keysBeforeEncounter: filterKeys,
    AllergySequence: null // แสดงหน้า patient panel บางครั้งอาจโหลดมาช้าแต่ออกจากหน้า Encounter ไปแล้ว
  });
};

export const HandleUnmountEncounter: Handler = (controller, params) => {
  const state = controller.getState();

  const abortController = state.abortControllerEncounter || {};
  const stateKeys = Object.keys(state);

  const notResetKeys = new Set([
    "patientQueueEncounter",
    "goToCreateAppointment", // กดปุ่ใทำนัดหมายแล้วไปหน้า Patient appointment
    "goBackEncounterState", // ใช้เพื่อ กลับมา Encounter เดิม จากนัดหมาย
    "goBackAppointmentState", // ใช้เพื่อ กลับมา Appointment เดิม encounter
    ...(params.notResetKeys || []),
  ]);

  const resetKeys = [
    "hasAdmitOrder",
    "selectedShippingOrder",
    "selectedEncounter",
    "selectedEmr",
    "selectedImaging",
    "selectedPatient",
    "patientADR",
    "patientData",
    "patientAssessment",
    "weightInfo",
    "heightInfo",
    "chatDetail",
    "vitalSignsInfo",
    "problemListDPO",
    "LabResult",
    "selectedProgressCycle",
    "isDrugWorkingQueueRefreshed",
  ];

  const filterKeys = [...stateKeys
    .filter((key) => !state.keysBeforeEncounter?.includes(key)), ...resetKeys]
    .filter((key) => !notResetKeys.has(key)) // white list


  console.debug("HandleUnmountEncounter keyAfter selected encounter: ", filterKeys)

  const stateInit = Object.assign(
    {},
    ...filterKeys.map((key) => ({ [key]: (StateInitial as any)[key] }))
  );

  for (const key of Object.keys(abortController)) {
    for (const ac of abortController[key]) {
      ac.abort();
    }
  }

  controller.setState(
    {
      ...stateInit,
      goToMenu: "",
      goToTab: "",
      keysBeforeEncounter: [],
      selectedMainOrOrder: null,
      selectedOrOrder: null,
    },
    () => {
      params.onUnmounted?.();
    }
  );
};

export const HandleSelectTabIndex: Handler = (controller, params) => {
  const index = CommonInterface.getSideMenuIndex({ tab: params.tab });

  if (index >= 0) {
    controller.setState({ selectedTabIndex: index });
  }
};

export const CheckUserCredentials: Handler = async (controller, params) => {
  const state = controller.getState();

  const [res, error] = await UserLoginView.post({
    apiToken: controller.apiToken,
    data: {
      password: params.password,
      username: params.username ?? controller.data.userProfile?.username,
    },
    extra: { division: controller.data.division },
  });

  const message = params.message || "กรุณาระบุรหัสผ่านให้ถูกต้อง";

  if (!res) {
    controller.setState({
      ...(params.btnKey && {
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnKey]: "ERROR" },
      }),
      ...(params.cardKey && {
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: params.isError ? { error: message } : message,
        },
      }),
    });

    return { error, message, userId: null };
  }

  return { error, message, userId: res.user as number };
};