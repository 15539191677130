import WasmController from "react-lib/frameworks/WasmController";
import moment from "moment";
// APIs
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import OperatingLocationList from "issara-sdk/apis/OperatingLocationList_apps_ORM";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import OperatingOrderList from "issara-sdk/apis/OperatingOrderList_apps_ORM";
import OperatingRoomList from "issara-sdk/apis/OperatingRoomList_apps_ORM";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import DoctorList from "issara-sdk/apis/DoctorList_core";
// Utils
import { formatDate } from "../../../../utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";

// PDF
import FormOperativeSchedule from "react-lib/appcon/common/FormOperativeSchedule";
import FormQrCodeDrugDelivery from "react-lib/appcon/common/FormQrCodeDrugDelivery";

export type State = {
  // CommonInterface
  django?: any;
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  masterOptions?: any;

  // sequence
  ORQueueSequence?: {
    sequenceIndex?: string | null;

    permission?: any;

    orFilter?: {
      emr__encounter__patient__hn?: string;
      patientFullName?: string;
      chief_surgeon?: any | null;
      start_date?: string;
      end_date?: string;
      created?: string;
      operating_detail__operating_room__location_id?: number | null;
      operating_detail__operating_room__id?: number | null;
      no_date_no_room?: boolean;
      status?: any;
      anesMethod?: boolean;
      typesCases?: string;
      types?: any[];
      cases?: any[];
    };

    locationOptions?: any[];
    roomOptions?: any[];
    roomZoneOptions?: any[];

    orOrders?: { items: any[]; next: null; previous: null; total: 0 };
  } | null;

  //
  selectedOrOrder?: any;
  selectedMainOrOrder?: any;
};

export const StateInitial: State = {
  // sequence
  ORQueueSequence: {
    sequenceIndex: null,

    permission: {},

    orFilter: {
      emr__encounter__patient__hn: "",
      patientFullName: "",
      chief_surgeon: null,
      start_date: "",
      end_date: "",
      created: "",
      operating_detail__operating_room__location_id: null,
      operating_detail__operating_room__id: null,
      no_date_no_room: false,
      status: "",
      anesMethod: false,
      typesCases: "",
      types: [],
      cases: [],
    },

    locationOptions: [],
    roomOptions: [],
    roomZoneOptions: [],

    orOrders: { items: [], next: null, previous: null, total: 0 },
  },

  //
  selectedOrOrder: null,
  selectedMainOrOrder: null,
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  masterData?: { [name: string]: any };
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const CARD_OR_QUEUE_ID: string = "CardORQueue";

export const GetMaster: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [
        ["operatingType", {}],
        ["operatingStatus", {}],
        ["specialEquipment", {}],
      ],
    },
  });

  const getPermission = UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_ORM_ZONE_HOLDING_ROOM: "",
      config_ORM_ZONE_OPERATING_ROOM: "",
      config_ORM_ZONE_PACU: "",
    },
  });

  const getLocation = OperatingLocationList.list({
    apiToken: controller.apiToken,
    params: { limit: 100 },
  });

  const [permission, location] = await Promise.all([getPermission, getLocation]);

  let startDate = formatDate(moment());
  let endDate = formatDate(moment());

  if (params.is_drug_queue) {
    endDate = formatDate(moment().add(2, "weeks"));
  }
  if (params.is_ward_queue) {
    endDate = formatDate(moment().add(1, "weeks"));
  }
  controller.setState(
    {
      ORQueueSequence: {
        sequenceIndex: "Action",
        permission: permission[0],
        orFilter: {
          start_date: startDate,
          end_date: endDate,
        },
        locationOptions: mapOptions(location[0]?.items || []),
        roomOptions: [],
      },
    },
    () => {
      Action(controller, {
        action: "FETCH",
        is_drug_queue: params.is_drug_queue,
        is_ward_queue: params.is_ward_queue,
      });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  let state = controller.getState();

  if (params?.action === "FETCH") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "LOADING",
      },
    });

    let queryParams: any = {
      ...state.ORQueueSequence?.orFilter,
      limit: 100,
      is_drug_queue: params.is_drug_queue || false,
      is_ward_queue: params.is_ward_queue || false,
    };
    delete queryParams.selectedDoctor;

    const [resp, error, network] = await OperatingOrderList.list({
      apiToken: controller.apiToken,
      params: queryParams,
    });

    state = controller.getState();

    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "ERROR",
        },
        ORQueueSequence: {
          ...state.ORQueueSequence,
          orOrders: { items: [], next: null, previous: null, total: 0 },
        },
      });
    } else {
      // const operatingType = controller.data.masterData?.operatingType || [];
      // const result = resp.items?.map((item: any) => {
      //   const orType = operatingType.filter((t: any) => {
      //     return t.value === item?.type;
      //   });
      //   return { ...item, typeLabel: orType?.[0]?.label };
      // });
      const result = resp.items?.map((item: any) => {
        let typeLabel: string = "";
        if (["ER", "EL_NOT_APP"].includes(item?.type)) {
          // นัดหมายผ่าตัด EN เดิมมา + case OPD หรือ One day >> แสดง "OPD (EN เดิม)"
          if (item?.case?.is_opd_case || item?.case?.is_one_day_case) {
            typeLabel = "OPD (EN เดิม)";
          }
          // นัดหมายผ่าตัด EN เดิมมา + case IPD >> แสดง "IPD (EN เดิม)"
          else {
            typeLabel = "IPD (EN เดิม)";
          }
        } else {
          // นัดหมายผ่าตัด EN ใหม่ + case OPD หรือ One day >> แสดง "OPD (EN ใหม่)"
          if (item?.case?.is_opd_case || item?.case?.is_one_day_case) {
            typeLabel = "OPD (EN ใหม่)";
          }
          // นัดหมายผ่าตัด EN ใหม่ + case IPD >> แสดง "IPD (EN ใหม่)"
          else {
            typeLabel = "IPD (EN ใหม่)";
          }
        }
        return { ...item, typeLabel: typeLabel };
      });

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "SUCCESS",
        },
        ORQueueSequence: {
          ...state.ORQueueSequence,
          orOrders: { ...resp, items: result },
        },
      });
    }
  } else if (params?.action === "FILTER_LOCATION_AND_FETCH_ROOM_ITEM") {
    const [roomResp, roomErr, roomNet] = await OperatingRoomList.list({
      apiToken: controller.apiToken,
      params: { location: params.location, limit: 100 },
    });
    controller.setState({
      ORQueueSequence: {
        ...state.ORQueueSequence,
        orFilter: {
          ...state.ORQueueSequence?.orFilter,
          operating_detail__operating_room__location_id: params.location,
          operating_detail__operating_room__id: null,
        },
        roomOptions: roomErr ? [] : mapOptions(roomResp.items, "id", "room_no"),
      },
    });
  } else if (params?.action === "ZONE_FETCH_ROOM") {
    const [roomResp, roomErr, roomNet] = await OperatingRoomList.list({
      apiToken: controller.apiToken,
      params: { location: params.location, limit: 100 },
    });
    controller.setState({
      ORQueueSequence: {
        ...state.ORQueueSequence,
        roomZoneOptions: roomErr ? [] : mapOptions(roomResp.items, "id", "room_no"),
      },
    });
  } else if (params?.action === "SET_FILTER") {
    controller.setState({
      ORQueueSequence: {
        ...state.ORQueueSequence,
        orFilter: { ...state.ORQueueSequence?.orFilter, ...params.data },
      },
    });
  } else if (params?.action === "SELECTED_ORDER") {
    controller.setState({ selectedOrOrder: params.order, selectedMainOrOrder: params.order });
  } else if (params?.action === "SCHEDULE") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "LOADING",
      },
    });

    const [scheduleResp, scheduleErr, scheduleNet] = await OperatingOrderDetail.update({
      apiToken: controller.apiToken,
      pk: params.order?.id,
      data: { action: params.action },
    });

    if (scheduleErr) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [CARD_OR_QUEUE_ID]: scheduleErr,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "SUCCESS",
        },
      });
      Action(controller, { action: "FETCH" });
    }
  } else if (params?.action === "CANCEL_SCHEDULE") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "LOADING",
      },
    });

    const [unscheduleResp, unscheduleErr, unscheduleNet] = await OperatingOrderDetail.update({
      apiToken: controller.apiToken,
      pk: params.order?.id,
      data: { action: params.action, cancel_reason: params.cancel_reason },
    });

    if (unscheduleErr) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [CARD_OR_QUEUE_ID]: unscheduleErr,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "SUCCESS",
        },
      });
      Action(controller, { action: "FETCH" });
    }
  } else if (params?.action === "CHECK_IN") {
    const state = controller.getState();
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "LOADING",
      },
    });

    if (params.order?.order_status === "APPOINTMENT") {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [CARD_OR_QUEUE_ID]: "ไม่สามารถรับเข้าได้ เนื่องจากยังไม่ได้เปิด OPD Encounter",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "ERROR",
        },
      });
    } else {
      const setCheckinOR = await OperatingOrderDetail.update({
        apiToken: controller.apiToken,
        pk: params.order?.id,
        data: { action: params.action },
      });

      if (setCheckinOR[1]) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [CARD_OR_QUEUE_ID]: setCheckinOR[1],
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "ERROR",
          },
        });
      } else {
        const [enResp, enErr, enNetw] = await EncounterDetail.update({
          apiToken: controller.apiToken,
          pk: params.order?.encounter,
          data: { action: "TRANSFER_OR" },
        });

        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "SUCCESS",
          },
        });
        Action(controller, { action: "FETCH" });
      }
    }
  } else if (params?.action === "CANCEL_CHECK_IN") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "LOADING",
      },
    });

    const [uncheckinResp, uncheckinErr, uncheckinNet] = await OperatingOrderDetail.update({
      apiToken: controller.apiToken,
      pk: params.order?.id,
      data: { action: params.action, cancel_reason: params.cancel_reason },
    });
    if (uncheckinErr) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [CARD_OR_QUEUE_ID]: uncheckinErr,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "SUCCESS",
        },
      });
      Action(controller, { action: "FETCH" });
    }
  } else if (params?.action === "ZONE") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "LOADING",
      },
    });

    if (params.order?.order_status === "APPOINTMENT") {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [CARD_OR_QUEUE_ID]: "ไม่สามารถรับเข้าได้ เนื่องจากยังไม่ได้เปิด OPD Encounter",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "ERROR",
        },
      });
    } else {
      let data: any = { validateSlot: false, action: params.zone };
      if (params.zone === "ZONE_OPERATING_ROOM") {
        data.operating_room = params.room;
      }

      const [zoneResp, zoneErr, zoneNet] = await OperatingOrderDetail.update({
        apiToken: controller.apiToken,
        pk: params.order?.id,
        data: data,
      });

      if (zoneErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [CARD_OR_QUEUE_ID]: zoneErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "SUCCESS",
          },
        });
        Action(controller, { action: "FETCH" });
      }
    }
  } else if (params?.action === "PRINT") {
    let state = controller.getState();
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "LOADING",
      },
    });

    // console.log(" Should see me !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
    const [res, error] = await OperatingOrderList.list({
      extra: {
        division: controller.data.division,
      },
      params: {
        start_date: state.ORQueueSequence?.orFilter?.start_date,
        end_date: state.ORQueueSequence?.orFilter?.end_date,
        is_drug_queue: params.is_drug_queue || false,
        is_ward_queue: params.is_ward_queue || false,
      },
      apiToken: controller.apiToken,
    });
    console.log(" OperatingOrderList res: ", res, ", error: ", error);
    if (res) {
      console.log("res ", res);

      // let data = {
      //  data: res?.items,
      //  startDate: state.ORQueueSequence?.orFilter?.start_date,
      // endDate: state.ORQueueSequence?.orFilter?.end_date,
      //};

      let doctorList = controller.data.masterData.doctor;

      if (!doctorList) {
        const doctor = await DoctorList.list({
          params: { limit: 9999 },
          apiToken: controller.apiToken,
        });
        doctorList = doctor[0]?.items || [];
      }

      let operatingData = res?.items
        ?.sort((a: any, b: any) => (a.start_date < b.start_date ? -1 : 1))
        ?.map((item: any) => {
          let anesthesiaDoctor = doctorList.filter(
            (doctorData: any) => doctorData?.id === item?.anesthesia_teams?.[0]?.anesthesiologist
          )?.[0]?.full_name;

          let typeLabel: string = "";
          if (item?.case?.is_opd_case || item?.case?.is_one_day_case) {
            typeLabel = "OPD";
          } else {
            typeLabel = "IPD";
          }

          return {
            ...item,
            typeLabel: typeLabel,
            anesthesiologist: anesthesiaDoctor || "",
          };
        });

      const data = Object.assign({
        data: operatingData,
        id: params.id,
        startDate: state.ORQueueSequence?.orFilter?.start_date,
        endDate: state.ORQueueSequence?.orFilter?.end_date,
        user: state.django?.user?.full_name || "",
      });

      // await createPDFLabRequest(data);
      const docDef: any = await FormOperativeSchedule(data);
      const pdfMake = await getPdfMake(true);
      const pdf = pdfMake.createPdf(docDef);
      pdf.open();

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "SUCCESS",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${CARD_OR_QUEUE_ID}_${params?.action}`]: "SUCCESS",
        },
      });
    }
  }
};

// utilities
const mapOptions = (list: any[], valueKey = "id", textKey = "name") => {
  return list?.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item[textKey],
  }));
};
