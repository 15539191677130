import DrugDetail from "issara-sdk/apis/DrugDetail_apps_TPD";
import TreatmentList from "issara-sdk/apis/TreatmentList_apps_TRT";
import icd9cmKeyUp from "issara-sdk/apis/icd9cmKeyUp_core";
import WasmController from "../../../../frameworks/WasmController";
import SupplyDetail from "issara-sdk/apis/SupplyDetail_apps_MSD";

export type State = {
  // sequence
  OrderSetTemplateSequence?: {
    sequenceIndex?: string | null;

    templateList?: any;
    selectTemplate?: any;
    selectDetail?: any;
    searchIcd9?: any;
    drugDetail?: any;
  } | null;
};

export const StateInitial: State = {
  // sequence
  OrderSetTemplateSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        // drug
        ["unit", {}],
        ["route", {}],
        ["site", {}],
        ["frequency", {}],
        ["method", {}],
        // treatment
        ["claimImaging", {}],
      ],
    },
  });

  controller.setState(
    {
      OrderSetTemplateSequence: {
        sequenceIndex: "SearchAndAction",
      },
    },
    () => SearchAndAction(controller, { action: "search", ...params })
  );
};

export const SearchAndAction: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "search") {
    controller.setState({
      OrderSetTemplateSequence: {
        ...state.OrderSetTemplateSequence,
      },
    });
  } else if (params.action === "getDetailData") {
    const state = controller.getState();

    let templateData = state.OrderSetTemplateSequence?.selectTemplate?.items?.filter(
      (item: any) => item.id === params?.data?.id
    )?.[0];

    if (params?.type === "Medication") {
      const [drugDetailRes, drugDetailErr, drugDetailNet] = await DrugDetail.retrieve({
        pk: params?.data?.id,
        apiToken: controller.apiToken,
      });

      if (drugDetailRes) {
        controller.setState({
          OrderSetTemplateSequence: {
            ...state.OrderSetTemplateSequence,
            selectDetail: {
              ...drugDetailRes,
              dose: drugDetailRes?.default_unit_dose,
              drugUnits: drugDetailRes?.default_dosage_unit,
              route: drugDetailRes?.default_route,
              site: drugDetailRes?.default_site,
              frequency: drugDetailRes?.default_frequency,
              method: drugDetailRes?.default_method,
              prn: drugDetailRes?.default_prn,
              ...templateData,
            },
          },
        });
      } else {
        controller.setState({
          OrderSetTemplateSequence: {
            ...state.OrderSetTemplateSequence,
            selectDetail: {
              ...templateData,
            },
          },
        });
      }
    } else if (params?.type === "Treatment") {
      const [treatmentListRes, treatmentListErr, treatmentListNet] = await TreatmentList.list({
        params: {
          code: params?.data?.code,
        },
        apiToken: controller.apiToken,
      });

      if (treatmentListRes) {
        controller.setState({
          OrderSetTemplateSequence: {
            ...state.OrderSetTemplateSequence,
            selectDetail: {
              ...treatmentListRes,
              ...templateData,
            },
          },
        });
      } else {
        controller.setState({
          OrderSetTemplateSequence: {
            ...state.OrderSetTemplateSequence,
            selectDetail: {
              ...templateData,
            },
          },
        });
      }
    } else if (params?.type === "Supply") {
      const [supplyDetailRes, supplyDetailErr, supplyDetailNet] = await SupplyDetail.retrieve({
        pk: params?.data?.id,
        apiToken: controller.apiToken,
      });

      if (supplyDetailRes) {
        controller.setState({
          OrderSetTemplateSequence: {
            ...state.OrderSetTemplateSequence,
            selectDetail: {
              ...supplyDetailRes,
              mode: supplyDetailRes?.mode,

              ...templateData,
            },
          },
        });
      } else {
        controller.setState({
          OrderSetTemplateSequence: {
            ...state.OrderSetTemplateSequence,
            selectDetail: {
              ...templateData,
            },
          },
        });
      }
    } else if (params?.type === "Lab") {

      controller.setState({
        OrderSetTemplateSequence: {
          ...state.OrderSetTemplateSequence,
          selectDetail: {
            specimen_name: "EDTA-blood",
            lab_type_label: "In lab",
            ...templateData,
          },
        },
      });
    } else if (params?.type === "X-ray") {
      controller.setState({
        OrderSetTemplateSequence: {
          ...state.OrderSetTemplateSequence,
          selectDetail: {
            xrayGroup: "General X-ray Group",
            ...templateData,
          },
        },
      });
    }
  } else if (params.action === "searchIcd9") {
    const state = controller.getState();
    let icd9Key = state.OrderSetTemplateSequence?.selectDetail?.icd9Name || "";

    if (icd9Key.length >= 3) {
      const [
        [icdcodeRes, icdcodeErr, icdcodeNet],
        [medtermRes, medtermErr, medtermNet],
        [icdtermRes, icdtermErr, icdtermNet],
      ] = await Promise.all([
        icd9cmKeyUp.post({
          method: "icdcode",
          data: {
            icdcode: icd9Key,
          },
          apiToken: controller.apiToken,
        }),
        icd9cmKeyUp.post({
          method: "medterm",
          data: {
            medterm: icd9Key,
          },
          apiToken: controller.apiToken,
        }),
        icd9cmKeyUp.post({
          method: "icdterm",
          data: {
            icdterm: icd9Key,
          },
          apiToken: controller.apiToken,
        }),
      ]);

      controller.setState({
        OrderSetTemplateSequence: {
          ...state.OrderSetTemplateSequence,
          searchIcd9: [...icdcodeRes?.response, ...medtermRes?.response, ...icdtermRes?.response],
        },
      });
    }
  } else if (params.action === "editOrderDetail") {
    const state = controller.getState();

    let newTemplate = state.OrderSetTemplateSequence?.selectTemplate?.items?.map((item: any) => {
      if (item?.id === params?.selectedId) {
        let selectDetail = state.OrderSetTemplateSequence?.selectDetail;
        if (item?.p_type_code === "DRUG") {
          return {
            ...item,
            dose: selectDetail?.dose,
            drugUnits: selectDetail?.drugUnits,
            route: selectDetail?.route,
            site: selectDetail?.site,
            frequency: selectDetail?.frequency,
            method: selectDetail?.method,
            prn: selectDetail?.prn,
            description: selectDetail?.description,
            qty: selectDetail?.qty,
          };
        } else if (item?.p_type_code === "PROC") {
          return {
            ...item,
            qty: selectDetail?.qty,
            method: selectDetail?.method,
            icd9Name: selectDetail?.icd9Name,
            icd9: selectDetail?.icd9,
            toBePerformer: selectDetail?.toBePerformer,
            orderDetail: selectDetail?.orderDetail,
            equipmentDetail: selectDetail?.equipmentDetail,
            remarkDetail: selectDetail?.remarkDetail,
          };
        } else if (item?.p_type_code === "SUPPLY") {
          return {
            ...item,
            mode: selectDetail?.mode,
            qty: selectDetail?.qty,
            type: selectDetail?.type,
            eligibility_type: selectDetail?.eligibility_type,
            remarkDetail: selectDetail?.remarkDetail,
          };
        } else if (item?.p_type_code === "LAB") {
          return {
            ...item,
            specimen_name: "EDTA-blood",
            specimen_time: selectDetail?.specimen_time,
            urgency: selectDetail?.urgency,
            remarkDetail: selectDetail?.remarkDetail,
          };
        } else if (item?.p_type_code === "XRAY") {
          return {
            ...item,
            xrayGroup: "General X-ray Group",
            eligibility_type: selectDetail?.eligibility_type,
            urgency: selectDetail?.urgency,
            remarkDetail: selectDetail?.remarkDetail,
            qty: selectDetail?.qty,
          };
        } else {
          return item;
        }
      } else {
        return item;
      }
    });

    controller.setState({
      OrderSetTemplateSequence: {
        ...state.OrderSetTemplateSequence,
        selectTemplate: {
          ...state.OrderSetTemplateSequence?.selectTemplate,
          items: newTemplate,
        },
        selectDetail: {},
      },
    });

    params.callback?.(null);
  }
};
