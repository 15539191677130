import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Icon, Checkbox, Modal, Input } from "semantic-ui-react";

import withFixedColumns from "react-table-hoc-fixed-columns";
import ReactTable from "react-table-6";
import "react-lib/css/ReactTableFixedColumns.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

// UI
import ModLabReport from "./ModLabReport";
import CardLabReportUX from "./CardLabReportUX";
import ModAttachFile from "./ModAttachFile";
import { DisplayResult } from "./CardOPDLabSummary";
import ButtonEditRemark from "../TPD/ButtonEditRemark";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModAuthen from "react-lib/apps/common/ModAuthen"
import SnackMessage from "react-lib/apps/common/SnackMessage";
import PasswordRecheck from "react-lib/apps/common/PasswordRecheck";

// Interface
import { State, RunSequence } from "./sequence/LabReport";
import { getRefValue, getStatus } from "./sequence/OPDLabSummary";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";

// Config
import CONFIG from "config/config";
import { useIntl } from "react-intl";

// Types
type CardLabReportProps = {
  setProp: (key: string, value: any) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  LabReportSequence?: State["LabReportSequence"];
  // data
  selectedLabOrderWorking: any;
  permissionLabResultConfidential?: boolean;
  patientInfo?: any;
  titleName?: string;
  // CommonInterface
  buttonLoadCheck?: any;
  errorMessage?: any;
  searchedItemListWithKey?: Record<string, any>;
  successMessage?: any;
  // options
  masterOptions?: Record<string, any>;
  // config
  showPatientInfo?: boolean;
  // callback
  onClose?: (changed: boolean) => any;
};

// Const
const ReactTableFixedColumns = withFixedColumns(ReactTable);

// Styles
const tableStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 8px",
  margin: 0,
};

// Const
const LAB_STATUS = {
  ORDERED: "ร้องขอทำ Lab",
  SPECIMEN_COLLECTING: "พิมพ์สติ๊กเกอร์แล้ว",
  SPECIMEN_COLLECTED: "เก็บ specimen เสร็จสิ้น",
  SPECIMEN_SENT: "Specimen Center ได้รับ Specimen",
  SPECIMEN_CHECKED_OUT: "ห้อง Lab ได้รับ Specimen",
  PROCESSING: "กำลังทำ Lab",
  FINISHED: "เสร็จสิ้น",
  REPORTED: "รายงานผล",
  CANCELED: "ยกเลิก",
  SPECIMEN_REJECTED: "Specimen ถูกปฏิเสธ",
  PENDING_CONFIRM: "รอยืนยัน",
};

const IMAGES = {
  notebookBlue: "/static/images/notebook-regular-blue.png",
  notebookGrey: "/static/images/notebook-regular-grey.png",
};

const CARD_LAB_REPORT = "CardLabReport";
const MOD_ATTACH_FILE = "ModAttachfile";

const CardLabReport = (props: CardLabReportProps) => {
  const intl = useIntl();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [selectItemExpand, setSelectItemExpand] = useState<any>({});

  const [openModAttachFile, setOpenModAttachFile] = useState<boolean>(false);
  const [modLabReportData, setModLabReportData] = useState<any>({
    openLabReport: false,
    openAuthen: false,
    lab_order_item_id: null,
  });

  const [labOrderItems, setLabOrderItems] = useState<any[]>([]);
  const [isChangedStatus, setIsChangedStatus] = useState(false);

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "LabReport", restart: true });

    return () => {
      props.runSequence({ sequence: "LabReport", clear: true });
    };
  }, []);

  useEffect(() => {
    const paymentStatus = props.selectedLabOrderWorking?.payment_status_name;

    let temp: any[] = [];
    let items = JSON.parse(
      JSON.stringify([
        ...(props.LabReportSequence?.centralLabResult?.items || []),
      ])
    );

    items.forEach((it: any, parent_idx: any) => {
      it["chk"] = false;
      it["value"] = it.central_lab_result_value;
      it["allow_editing"] =
        paymentStatus === "PAID"
          ? it.central_lab_order_item_status !== LAB_STATUS.SPECIMEN_REJECTED
          : true;

      it["children"] = it.children.map((child: any) => {
        return {
          ...child,
          chk: false,
          parent_idx: parent_idx,
          value: child.central_lab_result_value,
          allow_editing:
            paymentStatus === "PAID"
              ? child.central_lab_order_item_status !==
                LAB_STATUS.SPECIMEN_REJECTED
              : true,
        };
      });

      temp.push(it);
    });

    setLabOrderItems(temp);
  }, [
    props.LabReportSequence?.centralLabResult,
    props.selectedLabOrderWorking?.payment_status_name,
  ]);

  useEffect(() => {
    if (props.successMessage?.[MOD_ATTACH_FILE]) {
      setOpenModAttachFile(false);
      props.setProp(`successMessage.${MOD_ATTACH_FILE}`, null);
    }
  }, [props.successMessage?.[MOD_ATTACH_FILE]]);

  useEffect(() => {
    if (props.successMessage?.[CARD_LAB_REPORT]) {
      setUsername("");
      setPassword("");
    }
  }, [props.successMessage?.[CARD_LAB_REPORT]]);

  useEffect(() => {
    if (
      modLabReportData.openAuthen &&
      !modLabReportData.openLabReport &&
      props.permissionLabResultConfidential
    )
      setModLabReportData({
        ...modLabReportData,
        openLabReport: true,
        openAuthen: false,
      });
  }, [props.permissionLabResultConfidential]);

  const handleSaveComment = (original: Record<string, any>) => (data: Record<string, any>) => {
    props.runSequence({
      sequence: "LabReport",
      action: "SAVE_COMMENT",
      data: original,
      ...data,
    });
  };

  // Callback
  const handleCheckboxSelected = useCallback(
    (item: any) => (e: any, data: any) => {
      let items = [...labOrderItems];
      const patientIndex = item["parent_idx"];

      //* เมื่อ checked ตัว children
      if ("parent_idx" in item) {
        items?.forEach((it: any) => {
          it?.children?.forEach((ch: any) => {
            if (ch.central_lab_order_item_id === item.central_lab_order_item_id) {
              ch.chk = data.checked;
            }
          });
        });

        // items[patientIndex]?.children?.forEach((it: any) => {
        //   if (it.central_lab_order_item_id === item.central_lab_order_item_id) {
        //     it.chk = data.checked;
        //   }
        // });

        const children: any[] = items[patientIndex]?.children || [];
        const isCheckAll: boolean = children.every((acc: any) => acc.chk);

        items[patientIndex].chk = isCheckAll;
      }
      // เมื่อ checked parent
      else {
        items = items.map((acc) =>
          acc.central_lab_order_item_id === item.central_lab_order_item_id
            ? {
                ...acc,
                chk: data.checked,
                children: acc.children.map((o: any) => ({
                  ...o,
                  chk: data.checked,
                })),
              }
            : acc
        );
      }

      setLabOrderItems([...items]);
    },
    [labOrderItems]
  );

  const handleChangeValue = useCallback(
    (item: any) => (e: any, v: any) => {
      let items = [...labOrderItems];

      if ("parent_idx" in item) {
        items[item["parent_idx"]]?.children?.forEach((it: any) => {
          if (it.central_lab_order_item_id === item.central_lab_order_item_id) {
            it["central_lab_result_value"] = v.value;
          }
        });
      } else {
        items = items.map((acc) =>
          acc.central_lab_order_item_id === item.central_lab_order_item_id
            ? { ...acc, central_lab_result_value: v.value }
            : acc
        );

        item.central_lab_result_value = v.value;
      }

      setLabOrderItems(items);
    },
    [labOrderItems]
  );

  const handelCheckedAll = useCallback(
    (e: any, v: any) => {
      let items: any[] = [...labOrderItems];

      items = items.map((item) => ({
        ...item,
        chk: item.allow_editing ? v.checked : false,
        children: item.children.map((acc: any) => ({
          ...acc,
          chk: acc.allow_editing ? v.checked : false,
        })),
      }));

      setLabOrderItems(items);
    },
    [labOrderItems]
  );

  const renderCheckboxCell = useCallback(
    ({ original, index }: any) => {
      return (
        original.allow_editing && (
          <div>
            <Checkbox
              id={`CardLabReport-Checkbox-Selected-${index}`}
              checked={original.chk}
              onClick={handleCheckboxSelected(original)}
            />
          </div>
        )
      );
    },
    [labOrderItems]
  );

  const renderFileCell = useCallback(({ original }: any) => {
    return (
      <div
        style={{
          display: original.children?.length > 0 ? "none" : "flex",
          alignItems: "center",
        }}
      >
        <Icon
          name="file pdf outline"
          color="blue"
          size="large"
          style={{
            display:
              original.summary_file_result?.filter((item: any) => item.active)
                ?.length === 0
                ? "none"
                : "",
            cursor: "pointer",
          }}
          onClick={() => {
            props.setProp("permissionLabResultConfidential", false);

            setModLabReportData({
              openLabReport: !original.is_confidential,
              openAuthen: original.is_confidential,
              lab_order_item_id: original?.central_lab_order_item_id,
            });
          }}
        ></Icon>
      </div>
    );
  }, []);

  const renderResultCell = useCallback(
    ({ original, index }: any) => {
      const handleRef = (ref: any) => {
        if (ref) {
          ref.inputRef.current.style.backgroundColor =
            original?.central_lab_order_item_status === LAB_STATUS.REPORTED ? "transparent" : "";
        }
      };

      return (
        <div
          style={{
            display: original.children?.length > 0 ? "none" : "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Input
            id={`CardLabReport-Input-Value-${index}`}
            ref={handleRef}
            value={original.central_lab_result_value}
            fluid={true}
            disabled={!original.allow_editing}
            style={{ width: "67.5%", opacity: 1 }}
            onChange={handleChangeValue(original)}
          ></Input>
          <ButtonEditRemark
            onEvent={props.onEvent}
            setProp={props.setProp}
            id={original.id}
            label="ระบุข้อความ"
            value={original.comment}
            buttonLoadCheck={props.buttonLoadCheck}
            errorMessage={props.errorMessage}
            onSave={handleSaveComment(original)}
            trigger={
              <img
                alt="Notebook regular icon"
                src={original.comment ? IMAGES.notebookBlue : IMAGES.notebookGrey}
                style={{ cursor: "pointer", margin: "0 -3px -3px 3px", width: "20px" }}
              />
            }
          />
          <DisplayResult
            status={original.result_status}
            critical={original.result_critical}
            paddingStyle={0}
            labelStyle={{ fontSize: "0.525rem", marginTop: "-2px" }}
            statusStyle={{ marginLeft: "-0.45rem" }}
          />
        </div>
      );
    },
    [labOrderItems, props.buttonLoadCheck, props.errorMessage]
  );

  const renderNameExpander = useCallback(({ isExpanded, ...rest }: any) => {
    return <ExpanderName isExpanded={isExpanded} original={rest.original} />;
  }, []);

  const renderReferenceCell = useCallback(
    ({ original }: any) => {
      return (
        <div style={{ display: original.children?.length > 0 ? "none" : "" }}>
          {getRefValue(original.ref_value_txt, props.patientInfo?.gender_name)}
        </div>
      );
    },
    [props.patientInfo]
  );

  const renderUnitCell = useCallback(({ original }: any) => {
    return (
      <div style={{ display: original.children?.length > 0 ? "none" : "" }}>
        {original.unit}
      </div>
    );
  }, []);

  const renderStatusCell = useCallback(({ original }: any) => {
    const status =
      original.central_lab_order_item_status === LAB_STATUS.REPORTED &&
      !original.central_lab_result_is_authorized
        ? LAB_STATUS.PENDING_CONFIRM
        : original.central_lab_order_item_status;

    return (
      <div
        style={{
          display: original.children?.length > 0 ? "none" : "",
          color: status === LAB_STATUS.SPECIMEN_REJECTED ? "red" : "",
        }}
      >
        {status}
      </div>
    );
  }, []);

  const renderReporterCell = useCallback(({ original }: any) => {
    const reporter = original.central_lab_result_report_user_fullname;
    return (
      <div
      >
        {reporter}
      </div>
    );
  }, []);

  const renderApproveCell = useCallback(({ original }: any) => {
    const authorize_user = original.central_lab_result_authorize_user_fullname;
    return (
      <div
      >
        {authorize_user}
      </div>
    );
  }, []);

  // Memo
  const disabledAttachFile = useMemo(() => {
    const filterItems = labOrderItems
      .flatMap((item: any) => (item.children.length ? item.children : [item]))
      .filter((item: any) => item.chk);

    return !filterItems?.length;
  }, [labOrderItems]);

  const isCheckedAll = useMemo(() => {
    const editableItems = labOrderItems
      .flatMap((item: any) => (item.children.length ? item.children : [item]))
      .filter((item) => item.allow_editing);

    return (
      !!editableItems.length && editableItems.every((item: any) => item.chk)
    );
  }, [labOrderItems]);

  const formattedLabOrderItems = useMemo(() => {
    const getOrderStatus = (data: any = {}) => {
      return data?.central_lab_order_item_status === LAB_STATUS.REPORTED &&
        !data?.central_lab_result_is_authorized
        ? LAB_STATUS.PENDING_CONFIRM
        : data?.central_lab_order_item_status;
    };

    const settledStatus = (data: any, status: any) => {
      const refValue = getRefValue(data);

      let result = status;

      if (data.children?.length) {
        result = data.children.every(
          (child: any) => getOrderStatus(child) === status
        )
          ? status
          : "";
      }

      data.status = result;
      data.result_status = refValue.status;
      data.result_critical = refValue.is_critical;
    };

    const getRefValue = (item: any) => {
      console.log(item.critical_value_min, item.critical_value_max);
      return getStatus({
        refValue: item.ref_value_txt,
        criticalValue: {
          max: item.critical_value_max,
          min: item.critical_value_min,
        },
        value: item.value,
        genderName: props.patientInfo?.gender_name,
      });
    };

    return labOrderItems.map((item) => {
      let status = getOrderStatus(item);

      settledStatus(item, status);

      item.children = item.children?.map((acc: any) => {
        let status = getOrderStatus(acc);

        settledStatus(acc, status);

        return { ...acc };
      });

      return { ...item };
    });
  }, [labOrderItems]);

  const labOrdersReported = useMemo(() => {
    return [...formattedLabOrderItems]
      .map((item) => {
        const hasReported = item.children?.some(
          (acc: any) => acc.status === LAB_STATUS.REPORTED && acc.chk
        );

        if (hasReported) {
          item.children = item.children.filter((acc: any) => acc.status === LAB_STATUS.REPORTED);
        } else {
          item = item.status === LAB_STATUS.REPORTED && item.chk ? item : null;
        }

        return item;
      })
      .filter(Boolean);
  }, [formattedLabOrderItems]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }: any) => {
          return (
            original.allow_editing && (
              <div>
                <Checkbox
                  id={`CardLabReport-Checkbox-Selected-${original.central_lab_order_item_id}`}
                  checked={original.chk}
                  onClick={handleCheckboxSelected(original)}
                />
              </div>
            )
          );
        },
        // renderCheckboxCell,
        Header: <Checkbox checked={isCheckedAll} onClick={handelCheckedAll} />,
        sortable: false,
        width: 30,

        style: tableStyle,
        resizable: false,
      },
      {
        Header: "Lab Test",
        minWidth: 180,
        resizable: false,
        expander: true,
        style: { whiteSpace: "unset" }, // WordWrap

        Expander: renderNameExpander,
        getProps: (state: any, rowInfo: any) => {
          if (rowInfo) {
            if (
              !rowInfo.original.children ||
              (rowInfo.original.children && rowInfo.original.children.length === 0)
            ) {
              return { onClick: () => {} };
            }
          }

          return { className: "show-pointer" };
        },
      },
      {
        id: "file",
        accessor: "",
        Cell: renderFileCell,
        Header: "File",
        sortable: false,
        width: 60,

        style: tableStyle,
        resizable: false,
      },
      {
        id: "value",
        accessor: "",
        Cell: renderResultCell,
        Header: "Value",
        sortable: false,
        width: 180,

        style: tableStyle,
        resizable: false,
      },
      {
        id: "reference",
        accessor: "",
        Cell: renderReferenceCell,
        Header: "Reference",
        sortable: false,
        width: 120,

        style: { display: "flex", padding: "0 8px", margin: 0 },
        resizable: false,
      },
      {
        id: "unit",
        accessor: "",
        Cell: renderUnitCell,
        Header: "Unit",
        sortable: false,
        width: 120,

        style: { display: "flex", padding: "0 8px", margin: 0 },
        resizable: false,
      },
      {
        id: "status",
        accessor: "",
        Cell: renderStatusCell,
        Header: "Status",
        sortable: false,
        minWidth: 150,

        style: { display: "flex", padding: "0 8px", margin: 0 },
        resizable: false,
      },
      {
        id: "report_user",
        accessor: "",
        Cell: renderReporterCell,
        Header: "ผู้รายงานผล",
        sortable: false,
        minWidth: 140,

        style: { display: "flex", padding: "0 8px", margin: 0 },
        resizable: false,
      },
      {
        id: "authorize_user",
        accessor: "",
        Cell: renderApproveCell,
        Header: "ผู้ตรวจสอบผล",
        sortable: false,
        minWidth: 140,

        style: { display: "flex", padding: "0 8px", margin: 0 },
        resizable: false,
      },
    ];
  }, [isCheckedAll, labOrderItems, props.patientInfo, renderResultCell]);

  // Handler
  const handleCloseModLabReport = () => {
    props.setProp("permissionLabResultConfidential", false);

    props.setProp("LabReportSequence.selectedLabReport", null);

    setModLabReportData({
      openLabReport: false,
      openAuthen: false,
      lab_order_item_id: null,
    });
  };

  const handleOnAddFile = (data: any) => {
    props.runSequence({
      sequence: "LabReport",
      action: "attach_file",
      card: MOD_ATTACH_FILE,
      buttonLoadKey: `${MOD_ATTACH_FILE}_SAVE`,
      ...data,
    });
  };

  const handleRemoveItem = (index: number) => {
    let tmp = [...labOrderItems];
    tmp[index] = {
      ...tmp[index],
      chk: false,
    };
    tmp[index].children?.forEach((it: any) => {
      it["chk"] = false;
    });

    setLabOrderItems(tmp);
  };

  const handleEnterToken = (code: string) => {
    props.runSequence({
      sequence: "LabReport",
      action: "token",
      code: code,
    });
  };

  const handleCloseModAttachFile = () => {
    setOpenModAttachFile(false);
  };

  const handleEdit = () => {
    props.runSequence({
      sequence: "LabReport",
      action: "EDIT",
      card: CARD_LAB_REPORT,
      buttonLoadKey: `${CARD_LAB_REPORT}_SAVE`,
      labOrderItems: labOrderItems,
      // username: username,
      password: password,
      onUpdated: () => setIsChangedStatus(true),
    });
  };

  const handleAuthorize = () => {
    props.runSequence({
      sequence: "LabReport",
      action: "AUTHORIZE",
      card: CARD_LAB_REPORT,
      buttonLoadKey: `${CARD_LAB_REPORT}_AUTHORIZE`,
      labOrderItems: labOrderItems,
      // username: username,
      password: password,
      onUpdated: () => setIsChangedStatus(true),
    });
  };

  const handlePrintLabReport = () => {
    props.runSequence({
      sequence: "LabReport",
      action: "PRINT_LAB_REPORT",
      data: labOrdersReported,
    });
  };

  const handlePrintLabResult = () => {
    props.runSequence({
      sequence: "LabReport",
      action: "PRINT_LAB_RESULT",
      data: labOrdersReported,
      card: CARD_LAB_REPORT,
    });
  };

  const handleClose = () => {
    props.onClose?.(isChangedStatus);
  };

  console.log("CardLabReport props: ", props, labOrderItems, isChangedStatus);

  return (
    <div style={{ height: "94vh" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_LAB_REPORT}`, null);
        }}
        error={
          "error" in (props.errorMessage?.[CARD_LAB_REPORT] || {})
            ? props.errorMessage?.[CARD_LAB_REPORT]?.error
            : props.errorMessage?.[CARD_LAB_REPORT]
        }
        success={null}
        languageUX={props.languageUX}
      />

      <CardLabReportUX
        // Data
        patientInfo={props.patientInfo}
        selectedLabOrderWorking={props.selectedLabOrderWorking}
        arriveTime={formatDatetime(props.selectedLabOrderWorking?.arrive_time_iso, true)}
        username={username}
        password={password}
        titleName={props.titleName}
        // config
        disabledAttachFile={disabledAttachFile}
        disabledLabReport={!labOrdersReported?.length}
        hideButtonLabReport={!CONFIG.RAKSTHAI_LAB_REPORT_BUTTON}
        showPatientInfo={props.showPatientInfo}
        // Callback
        onAttachFile={() => setOpenModAttachFile(true)}
        changePassword={(e: any) => setPassword(e.target.value)}
        changeUsername={(e: any) => setUsername(e.target.value)}
        onLabReport={handlePrintLabReport}
        onClose={handleClose}
        // Element
        tableReport={
          <div style={{ width: "100%" }}>
            <ExpandingTable
              data={formattedLabOrderItems || []}
              columns={tableColumns}
              selectItemExpand={selectItemExpand}
              setSelectItemExpand={setSelectItemExpand}
            />
          </div>
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEdit}
            // data
            paramKey={`${CARD_LAB_REPORT}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_LAB_REPORT}_SAVE`]}
            // config
            disabled={!password}
            size="medium"
            color="green"
            name="SAVE"
            style={{ width: "100%" }}
            title="Save"
          />
        }
        buttonAuthorize={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleAuthorize}
            // data
            paramKey={`${CARD_LAB_REPORT}_AUTHORIZE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_LAB_REPORT}_AUTHORIZE`]}
            // config
            disabled={!password}
            size="medium"
            name="AUTHORIZE"
            color="yellow"
            style={{ width: "100%" }}
            title="Authorize"
          />
        }
        buttonPrintLabResult={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrintLabResult}
            // data
            paramKey={`${CARD_LAB_REPORT}_PRINT_LAB_RESULT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_LAB_REPORT}_PRINT_LAB_RESULT`]}
            // config
            disabled={!labOrdersReported?.length}
            size="medium"
            name="PRINT_LAB_RESULT"
            color="purple"
            style={{ width: "100%" }}
            title={intl.formatMessage({ id: "พิมพ์ผลแลป" })}
          />
        }
        inputPassword={
          <PasswordRecheck
            password={password}
            setPassword={setPassword}
            placeholder={intl.formatMessage({ id: "ระบุรหัสผ่าน" })}
          />
        }
        languageUX={props.languageUX}
      />

      <ModAttachFile
        setProp={props.setProp}
        // data
        open={openModAttachFile}
        labOrderItems={labOrderItems}
        errorMessage={props.errorMessage}
        buttonLoadCheck={props.buttonLoadCheck}
        labReportToken={props.LabReportSequence?.LabReportToken || {}}
        // callback
        onRemoveItem={handleRemoveItem}
        onEnterToken={handleEnterToken}
        onClose={handleCloseModAttachFile}
        onSave={handleOnAddFile}
        languageUX={props.languageUX}
      />

      <ModAuthen
        titleName={intl.formatMessage({ id: "อนุญาตให้เข้าถึงผลการทดสอบทางห้องปฏิบัติการ เฉพาะบุคคลที่มีสิทธิ์เท่านั้น" })}
        titleColor="blue"
        open={modLabReportData?.openAuthen}
        onDeny={handleCloseModLabReport}
        onApprove={({ username, password }: any) => {
          props.onEvent({
            message: "CheckPermissionLabConfidential",
            params: {
              username: username,
              password: password,
              cardKey: CARD_LAB_REPORT,
            },
          });
        }}
      />

      <Modal
        open={modLabReportData?.openLabReport}
        style={{
          width: "80%",
          padding: "1rem 1rem",
          margin: "1rem 0 0",
          height: "95vh",
          overflowY: "auto",
        }}
        onClose={handleCloseModLabReport}
      >
        <ModLabReport
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          LabReportSequence={props.LabReportSequence}
          // options
          masterOptions={props.masterOptions}
          // data
          permissionLabResultConfidential={props.permissionLabResultConfidential}
          cardKey={CARD_LAB_REPORT}
          // CommonInterface
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          patientInfo={props.patientInfo}
          selectedLabOrderWorking={props.selectedLabOrderWorking}
          selectedLabOrderItem={modLabReportData?.lab_order_item_id}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // callback
          onModLabReport={handleCloseModLabReport}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      NameExpander;                     */

/* ------------------------------------------------------ */
const ExpanderName = (props: any) => {
  // test your condition for Sub-Component here
  // I am using the presence of no comments
  const expanderStyle = {
    color:
      props.original.central_lab_order_item_status ===
      LAB_STATUS.SPECIMEN_REJECTED
        ? "red"
        : "",
  };

  if (!props.original.hasOwnProperty("children")) {
    return (
      <div style={expanderStyle}>&nbsp;&nbsp;&nbsp;{props.original.name}</div>
    );
  } else if (
    props.original.hasOwnProperty("children") &&
    props.original.children.length === 0
  ) {
    return <div style={expanderStyle}>{props.original.name}</div>;
  } else {
    return (
      <div>
        {props.isExpanded ? (
          <>
            <span
              style={{
                display: "inline-block",
                transform: "rotate(90deg)",
                ...expanderStyle,
              }}
            >
              &#10148;
            </span>
            <span style={expanderStyle}>{props.original.name}</span>
          </>
        ) : (
          <span style={expanderStyle}>&#10148; {props.original.name}</span>
        )}
      </div>
    );
  }
};

/* ------------------------------------------------------ */

/*                     ExpandingTable                     */

/* ------------------------------------------------------ */
const ExpandingTable = (props: any) => {
  const handleGetTdProps = (state: any, rowInfo: any) => {
    const original = rowInfo?.original || {};

    return {
      style: {
        backgroundColor:
          original.status === LAB_STATUS.REPORTED
            ? "rgba(62, 247, 92, 0.5)"
            : "",
      },
    };
  };

  return (
    <ReactTableFixedColumns
      // @ts-ignore
      data={props.data}
      columns={props.columns}
      expanded={props.selectItemExpand}
      expanderDefaults={{
        sortable: false,
        resizable: true,
        filterable: false,
      }}
      onExpandedChange={(expanded: any) => {
        let childCount = 0;
        // Adjust PageSize and setHeight
        Object.keys(expanded).forEach(function (key) {
          if (expanded[key]) {
            childCount += props.data[key].children?.length;
          }
        });

        props.setSelectItemExpand(expanded);
      }}
      pageSize={props.data?.length ?? 20}
      style={{ maxHeight: "700px" }}
      subRowsKey={"children"}
      showPagination={false}
      getNoDataProps={() => {
        return { style: { display: "none" } };
      }}
      getTdProps={handleGetTdProps}
      minRows={4}
    />
  );
};

export default React.memo(CardLabReport);
